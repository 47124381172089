import * as React from 'react'
import { Reservation } from '@models/reservation'
import { CheckOutBanner } from '@modules/reservations/pending/check-out-banner'
import { CheckOutBandsReturnBanner } from '@modules/reservations/pending/check-out-bands-return-banner'
import { CheckOutUnavailableBanner } from '@modules/reservations/pending/check-out-unavailable-banner'
import { isAfterDepartureTime, isToCheckOut } from '@helpers/reservation'

interface Props {
  reservation: Reservation
}

export const PendingReservationBanner = ({ reservation }: Props): JSX.Element => {
  if (isAfterDepartureTime(reservation)) {
    return <CheckOutUnavailableBanner reservation={reservation} />
  }

  const canCheckOut = isToCheckOut(reservation)

  if (canCheckOut && reservation.has_deposit_creditcard_preauth) {
    return (
      <CheckOutUnavailableBanner
        reservation={reservation}
        title="Wymeldowanie online nie jest możliwe."
        subtitle="Kaucja opłacona preautoryzacją. Zapraszamy do Recepcji Ośrodka"
      />
    )
  }

  return (
    <div>
      {canCheckOut && <CheckOutBanner reservation={reservation} />}
      {reservation.checked_out_online && <CheckOutBandsReturnBanner reservation={reservation} />}
    </div>
  )
}
