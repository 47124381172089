import * as React from 'react'
import { formatPriceShort } from '@helpers/price-helper'
import { selectClientDetails } from '@store/selectors/client-selectors'
import { useAppSelector } from '@store/index'
import { reservationsSelector } from '@store/slices/reservations-slice'
import { selectCouponTypes } from '@store/selectors/app-data-selectors'
import { CouponType } from '@models/coupons'

export const CouponBaseInformation = (): JSX.Element => {
  const clientDetails = useAppSelector(selectClientDetails)
  const reservations = useAppSelector(reservationsSelector)
  const couponTypes = useAppSelector(selectCouponTypes)

  const checkedInBooking = reservations.find(reservation => reservation.checked_in)

  const availableTypes = couponTypes.filter(
    couponType => couponType.configuration.resort === checkedInBooking?.resort_id,
  )

  const { bistroCoupon, cafeCoupon } = availableTypes.reduce(
    (acc, couponType) => {
      if (couponType.configuration.point_of_sale === 'bistro') {
        return { ...acc, bistroCoupon: couponType }
      }

      if (couponType.configuration.point_of_sale === 'cafe') {
        return { ...acc, cafeCoupon: couponType }
      }

      return acc
    },
    { bistroCoupon: null, cafeCoupon: null },
  )

  return (
    <div className="text-white text-center">
      <h1 className="fw-bold font-size-33 mt-4">
        {clientDetails.first_name}, skorzystaj <br /> z nowej promocji
      </h1>
      <strong className="font-size-xl">
        Wydaj {formatPriceShort(5)} i otrzymaj kupon <br /> o wartości {formatPriceShort(10)}{' '}
      </strong>
      {checkedInBooking && availableTypes.length && (
        <div>
          <span className="d-block font-size-xl">do wykorzystania</span>
          {bistroCoupon && <UsageHours coupon={bistroCoupon} name="w bistro" />}
          {cafeCoupon && <UsageHours coupon={cafeCoupon} name="w kawiarni" />}
        </div>
      )}
    </div>
  )
}

const UsageHours = ({ coupon, name }: { coupon: CouponType; name: string }) => (
  <div className="fw-normal font-size-md">
    {name} ({coupon.configuration.usage_hour_from} - {coupon.configuration.usage_hour_to})
  </div>
)
