import * as React from 'react'
import { SpinInput } from '@components/controls/spin-input'
import declination from '@helpers/declination'
import { formatPriceShort } from '@helpers/price-helper'
import { useFormContext, useWatch } from 'react-hook-form'
import { CouponItemFormInputs } from '@modules/coupons/coupon-checked-in-reservation'
import { CouponTypeSelect } from '@modules/coupons/coupon-type-select'
import { useCouponSelectedTypeConfiguration } from '@modules/coupons/use-coupon-selected-type-configuration'
import { FocusEvent } from 'react'
import Decimal from 'decimal.js'
import { isInRange } from '@helpers/utils'

const MAXIMUM_AMOUNT = 1000
const MINIMUM_AMOUNT = 5
const STEP = 5

interface Props {
  bonusAmount: string
  resortId: number
}

export const CouponConfiguration = ({ bonusAmount, resortId }: Props): JSX.Element => {
  const { control, setValue } = useFormContext<CouponItemFormInputs>()

  const { configuration } = useCouponSelectedTypeConfiguration()

  const amount = useWatch({ control, name: 'amount' })

  const handleAmountChange = (value: number) => {
    setValue('amount', value)
  }

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    const value = event.target.value

    if (Number.isNaN(value) || !value) {
      setValue('amount', MINIMUM_AMOUNT)
    } else {
      setValue('amount', Math.max(Math.ceil(parseInt(value) / STEP) * STEP, MINIMUM_AMOUNT))
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value

    if (inputValue === '') {
      setValue('amount', '')
      return
    }

    const value = new Decimal(inputValue).toNumber()

    if (isInRange(value, 0, MAXIMUM_AMOUNT)) {
      handleAmountChange(value)
      setValue('amount', value)
    }
  }

  return (
    <div className="coupons-view__info-box pt-4 pb-2">
      <p className="text-primary font-size-xxl text-center col-10 mx-auto fw-bold">Wybierz kwotę do wpłaty</p>
      <CouponTypeSelect resortId={resortId} />
      <div className="row col-11 mx-auto">
        <div className="col-7">
          <div className="coupons-view__spin-input">
            <SpinInput
              inputWrapperClass="d-flex flex-column align-items-center justify-content-center h-100 py-2"
              name="amount"
              min={MINIMUM_AMOUNT}
              max={MAXIMUM_AMOUNT}
              step={STEP}
              value={amount}
              onIncrement={handleAmountChange}
              onDecrement={handleAmountChange}
              onBlur={handleBlur}
              onChange={handleInputChange}
            >
              <strong className="lh-1">{declination.pln(amount)}</strong>
            </SpinInput>
          </div>
        </div>
        <div className="col-5">
          <span className="text-nowrap text-muted">Voucher</span>
          <strong className="font-size-33 lh-1 d-block text-primary">{formatPriceShort(bonusAmount)}</strong>
          <div className="text-muted font-size-sm" style={{ lineHeight: '13px' }}>
            <span>do wykorzystania</span>
            <br />
            <strong>{configuration?.point_of_sale === 'bistro' ? 'w bistro' : 'w kawiarni'}</strong> <br />
            <span>w oznaczonych</span> <br />
            <span>godzinach promocji</span>
          </div>
        </div>
      </div>
    </div>
  )
}
