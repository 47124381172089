import axios from 'axios'
import {
  CalculatorItem,
  FeedingTypes,
  Guest,
  ParkingKind,
  ReservationDetails,
  ReservationMetersState,
  ReservationWallet,
} from '@models/reservation'
import { formatDate } from '@helpers/date-helper'
import { isParking } from '@helpers/improvement-helper'
import { commonObjectPost } from '@api/basic-requests'

export async function assignGastroCard(url: string, gastro_card: string): Promise<ReservationDetails> {
  const { data } = await axios.post(url, { gastro_card })
  return data
}

export async function getReservationWallet(
  reservationDetails: ReservationDetails,
  created?: string,
): Promise<ReservationWallet> {
  return getPurchaseHistory<ReservationWallet>(reservationDetails.urls.wallet, created)
}

export async function getPurchaseHistory<T>(url: string, created?: string): Promise<T> {
  const { data } = await axios.get(url, { params: { created } })
  return data
}

export async function getReservationByTokenDetails(token: string): Promise<ReservationDetails> {
  const { data } = await axios.get(`/api/reservation/reservation/${token}/`)
  return data
}

export async function getParkingPrices(url: string, dateFrom: string, dateTo: string): Promise<[number, number]> {
  const {
    data: { improvements },
  } = await axios.get(url, { params: { date_from: formatDate(dateFrom), date_to: formatDate(dateTo) } })

  const parkingImprovement = improvements.find(isParking)

  if (parkingImprovement) {
    return [parkingImprovement.price_brutto_first_item, parkingImprovement.price_brutto]
  }

  return [0, 0]
}

export interface ParkingCalculation {
  items: CalculatorItem[]
  total_price: number
}

export async function getParkingCalculation(
  url: string,
  amount: number,
  code: ParkingKind,
): Promise<ParkingCalculation> {
  return await commonObjectPost<ParkingCalculation>(url, [{ code, amount }])
}

export interface AddReservationGuestPayload {
  name: string
  birthday: string
  group_id: number
  type: string
  feeding_type: FeedingTypes
}

export async function addReservationGuest(
  url: string,
  payload: AddReservationGuestPayload,
): Promise<ReservationDetails> {
  const { data } = await axios.post(url, payload)

  return data
}

export async function deleteReservationGuest(guest: Guest): Promise<ReservationDetails> {
  const { data } = await axios.delete(guest.urls.details)

  return data
}

export async function updateReservationGuest(guest: Guest, payload) {
  const { data } = await axios.patch(guest.urls.details, payload)

  return data
}

export async function getReservationMeters(url: string): Promise<ReservationMetersState> {
  const { data } = await axios.get(url)

  return data
}

interface UpdateReservationCheckInDataPayload {
  id_card: string
}
export async function updateReservationCheckInData(
  url: string,
  payload: UpdateReservationCheckInDataPayload,
): Promise<ReservationDetails> {
  const { data } = await axios.patch(url, payload)

  return data
}
