import { useApiRequest } from '@hooks/use-api-request'
import { updateReservationDetails } from '@store/slices/reservations-slice'
import { commonObjectPatch } from '@api/basic-requests'
import { useAppDispatch } from '@store/index'
import { ArrivalTimeStepFormInputs } from '@modules/reservations/check-in/steps/arrival-time/arrival-time-step'
import { ReservationDetails } from '@models/reservation'
import { createCombinedDateWithTime, formatDate } from '@helpers/date-helper'
import { isEqual } from 'date-fns'

interface Response {
  update: (payload: ArrivalTimeStepFormInputs) => Promise<void>
  isLoading: boolean
}

export const useArrivalTimeUpdate = (reservation: ReservationDetails | undefined, onClose: () => void): Response => {
  const dispatch = useAppDispatch()

  const { action: update, isLoading } = useApiRequest(async (payload: ArrivalTimeStepFormInputs) => {
    const { omit_arrival_time, hour, minute } = payload

    if (!reservation) return

    const declaredTime = `${hour}:${minute}`
    const now = formatDate(new Date())

    const isSameAsArrivalTime = isEqual(
      createCombinedDateWithTime(now, reservation.declared_arrival_time ?? reservation.arrival_time),
      createCombinedDateWithTime(now, declaredTime),
    )

    if (!omit_arrival_time && isSameAsArrivalTime) {
      onClose()
      return
    }

    if (omit_arrival_time && !reservation.declared_arrival_time) {
      onClose()
      return
    }

    dispatch(
      updateReservationDetails(
        await commonObjectPatch(reservation.urls.update, {
          declared_arrival_time: reservation.declared_arrival_time && omit_arrival_time ? null : declaredTime,
        }),
      ),
    )
    onClose()
  })

  return {
    update,
    isLoading,
  }
}
