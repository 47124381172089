import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  handleClose: () => void
}

export const ReservationReceiptModalFooter = ({ handleClose }: Props): JSX.Element => (
  <div className="d-flex justify-content-between p-3 border-top">
    <IconWithText
      text="Zamknij"
      iconClassName="uil-times font-size-icon-18 me-2 fw-bold text-muted"
      className="fw-bold text-muted cursor-pointer"
      onClick={handleClose}
    />
  </div>
)
