import * as React from 'react'
import { SectionTitle } from '@components/section-title'
import { CheckInPaymentSummaryBill } from '@modules/reservations/check-in/bill/check-in-payment-summary-bill'
import { ReservationDetails } from '@models/reservation'
import { FeedingAgesGroupChangedAlert } from '@modules/reservations/check-in/bill/alerts/feeding-ages-group-changed-alert'

interface Props {
  reservationDetails: ReservationDetails
  children?: React.ReactNode
}

export const PaymentSummaryStep = ({ reservationDetails, children }: Props): JSX.Element => (
  <>
    <SectionTitle title="Dokonaj płatności" subtitle="Opłać koszty rezerwacji" className="text-center text-md-start" />
    {!!reservationDetails.messages.length && (
      <FeedingAgesGroupChangedAlert content={reservationDetails.messages[0].content} />
    )}
    <CheckInPaymentSummaryBill reservationDetails={reservationDetails} />
    {children}
  </>
)
