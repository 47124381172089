import * as React from 'react'
import { FormGroup, FormLabel } from 'react-bootstrap'
import { FormLabelProps } from 'react-bootstrap/FormLabel'
import * as clsx from 'clsx'
import { FormGroupProps } from 'react-bootstrap/FormGroup'
import { FormControlWithStatus } from '@components/form-controls/form-control-with-status'

export interface FormControlWithLabelProps {
  label?: string
  inputName: string
  formLabelProps?: FormLabelProps
  formGroupProps?: FormGroupProps
  isLoading?: boolean
  isSucceed?: boolean
  isFailed?: boolean
  inputSuffix?: React.ReactNode
  children: React.ReactNode
}

export const FormControlWithLabel = ({
  label,
  inputName,
  formLabelProps,
  formGroupProps,
  isLoading,
  isSucceed,
  isFailed,
  inputSuffix,
  children,
}: FormControlWithLabelProps): React.ReactElement => (
  <FormGroup {...formGroupProps} className={clsx('mt-2', formGroupProps?.className)} controlId={inputName}>
    {label && (
      <FormLabel {...formLabelProps} className={clsx('text-darker-gray mb-0 d-flex', formLabelProps?.className)}>
        {label}
      </FormLabel>
    )}
    <FormControlWithStatus
      inputName={inputName}
      isLoadingVisible={!!isLoading}
      isFailed={!isLoading && isFailed}
      isSucceed={!isLoading && isSucceed}
      inputSuffix={inputSuffix}
    >
      {children}
    </FormControlWithStatus>
  </FormGroup>
)
