import * as React from 'react'
import { Spinner } from '@components/loaders/spinner'
import { TrapezeButton, TrapezeButtonProps } from '@components/controls/trapeze-button'

interface Props extends TrapezeButtonProps {
  loadingLabel?: string
  isLoading?: boolean
}

export const LoadingTrapezoidButton = ({ isLoading, loadingLabel, children, ...props }: Props): React.ReactElement => (
  <TrapezeButton {...props}>
    {isLoading ? (
      <div className="d-flex align-items-center">
        <Spinner className="me-2 spinner--md" color="white" />
        {loadingLabel || 'Zapisywanie...'}
      </div>
    ) : (
      children
    )}
  </TrapezeButton>
)
