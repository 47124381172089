import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import { ItemChangeConfirmationModalContent } from '@components/item-change-confirmation-modal-content'

interface Props {
  header: string
  subtitle: string
  handleClose: () => void
  content: React.ReactNode
}

export const InformationModal = ({ handleClose, header, subtitle, content }: Props): JSX.Element => (
  <ModalBody className="modal-scrollable">
    <ItemChangeConfirmationModalContent text={header} underlineText={subtitle} customImage={null}>
      {content}
      <button className="btn btn-outline-secondary mt-4 px-5 py-3 responsiveness-button" onClick={handleClose}>
        <span className="font-weight-semibold">Rozumiem</span>
      </button>
    </ItemChangeConfirmationModalContent>
  </ModalBody>
)
