import * as React from 'react'
import { DataProcessingActions } from '@modules/data-processing/data-processing-actions'
import { FormProvider, useForm } from 'react-hook-form'
import { StaticRules } from '@helpers/rules'
import { ButtonLoading } from '@components/controls/button-loading'
import { useApiRequest } from '@hooks/use-api-request'
import { commonObjectPut } from '@api/basic-requests'
import { BookingGuestAgreement } from '@models/reservation'
import { useAppDispatch } from '@store/index'
import { updateReservationBookingGuestAgreement } from '@store/slices/reservations-slice'

interface FormInputs {
  rodo_phone: string
  rodo_email: string
}

interface Props {
  details: BookingGuestAgreement
  onSuccess: () => void
}

export const DataProcessingConsents = ({ details, onSuccess }: Props): JSX.Element => {
  const dispatch = useAppDispatch()

  const methods = useForm<FormInputs>({
    defaultValues: {
      rodo_phone: details.rodo_phone.toString() ?? 'false',
      rodo_email: details.rodo_email.toString() ?? 'false',
    },
  })

  const { action: submit, isLoading } = useApiRequest(async () => {
    dispatch(
      updateReservationBookingGuestAgreement(
        await commonObjectPut<BookingGuestAgreement>(details.urls.details, methods.getValues()),
      ),
    )
    onSuccess()
  })

  return (
    <FormProvider {...methods}>
      <h5 className="text-primary mb-4">Dobrowolne zgody Klienta na przetwarzanie danych osobowych:</h5>
      <div>
        <p className="mb-1">{StaticRules.email_rules}</p>
        <DataProcessingActions name="rodo_email" />
      </div>
      <div className="mt-4">
        <p className="mb-1">{StaticRules.sms_rules}</p>
        <DataProcessingActions name="rodo_phone" />
      </div>

      <ButtonLoading
        isLoading={isLoading}
        onClick={submit}
        loadingLabel="Potwierdzanie..."
        className="btn btn-primary responsiveness-button py-3 font-weight-semibold text-white font-size-lg mt-5 w-fit px-5 m-auto"
      >
        Potwierdź zmiany
      </ButtonLoading>
    </FormProvider>
  )
}
