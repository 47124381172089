import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { RemoveItemModal } from './remove-item-modal'
import { TextWithSpinner } from '@components/text-with-spinner'
import { useApiRequest } from '@hooks/use-api-request'
import { BookingClientWallet } from '@models/reservation'
import { commonObjectDelete } from '@api/basic-requests'
import { useDispatch } from 'react-redux'
import { setReservationDetails } from '@store/slices/reservations-slice'

interface Props {
  handleClose: () => void
  wallet: BookingClientWallet
}

export const PendingReservationRemoveVoucherModal = ({ handleClose, wallet }: Props): JSX.Element => {
  const dispatch = useDispatch()
  const { isLoading: isDeleting, action: handleRemove } = useApiRequest(async () => {
    dispatch(setReservationDetails(await commonObjectDelete(wallet.urls.details)))
    handleClose()
  })

  return (
    <RemoveItemModal
      image={require('@assets/images/gastro-voucher-delete-bg.webp')}
      title={
        <span className="text-light-black font-size-xxl text-center p-3 mt-3 d-block">
          Na pewno odpiąć
          <br />
          <strong className="position-relative mx-auto">
            <span className="underline-shadow"> {wallet.name}</span>
          </strong>
        </span>
      }
      onUpperAction={handleClose}
      upperAction={
        <IconWithText
          text="Pozostaw podpięty"
          iconClassName="uil-check me-2 font-size-xxl"
          className="fw-bold font-14"
        />
      }
      bottomAction={
        <TextWithSpinner
          isLoading={isDeleting}
          text="Odepnij voucher"
          loadingText="Odpinanie"
          spinnerColor="danger"
          className="cursor-pointer fw-bold font-size-14"
          onClick={handleRemove}
        />
      }
    />
  )
}
