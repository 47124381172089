import * as React from 'react'
import { ReservationDataCompletionAddedGuest } from '@modules/reservations/pending/data-completion-modal/guests/added-guest'
import { FeedingType, Guest, ReservationDetails } from '@models/reservation'
import { BackdropLoader } from '@components/loaders/backdrop-loader'
import { useApiRequest } from '@hooks/use-api-request'
import { getFeedingType } from '@modules/reservations/pending/data-completion-modal/guests/utils'
import { deleteReservationGuest, updateReservationGuest } from '@api/reservation'
import { getSelectableImprovements, isFeeding } from '@helpers/improvement-helper'
import { useDispatch, useSelector } from 'react-redux'
import { setReservationDetails } from '@store/slices/reservations-slice'
import { addFeeding, removeFeeding } from '@api/improvements'
import { selectResortDetails } from '@store/selectors/reservations-selectors'
import { useModal } from '@components/modals/use-modal'
import { useGuestChangeCheck } from '@hooks/use-guest-change-check'

interface Props {
  reservationDetails: ReservationDetails
  onGuestEdit: (guest: Guest) => void
  editedGuest: Guest | null
}

export const ReservationDataCompletionAddedGuests = ({
  reservationDetails,
  onGuestEdit,
  editedGuest,
}: Props): JSX.Element => {
  const feedingType = getFeedingType(reservationDetails.feeding_options) ?? FeedingType.BREAKFAST

  const dispatch = useDispatch()
  const resortDetails = useSelector(selectResortDetails)

  const isFeedingAdded = React.useCallback(
    (guest: Guest) =>
      reservationDetails.feeding_options
        .filter(({ guest_id }) => guest_id === guest.id)
        .some(({ dinner, breakfast }) => dinner || breakfast),
    [reservationDetails.feeding_options],
  )

  const isFeedingForAllGuestsAdded = React.useMemo(
    () => reservationDetails.guests.every(isFeedingAdded),
    [reservationDetails.guests],
  )

  const { isLoading: isDeleting, action: deleteGuest } = useApiRequest(async (guest: Guest) => {
    dispatch(setReservationDetails(await deleteReservationGuest(guest)))
  })

  const { isLoading: isTogglingFeeding, action: toggleFeeding } = useApiRequest(
    async (isFeeding: boolean, guest: Guest) => {
      dispatch(
        setReservationDetails(
          await updateReservationGuest(guest, {
            feeding_type: isFeeding ? feedingType : FeedingType.NONE,
          }),
        ),
      )
    },
  )

  const { isLoading: isAddingFeedingForAllGuests, action: addFeedingForAllGuests } = useApiRequest(async () => {
    dispatch(
      setReservationDetails(
        await addFeeding(reservationDetails.urls.feeding, {
          feeding_type: feedingType,
          guests: reservationDetails.guests.map((guest: Guest) => guest.id),
        }),
      ),
    )
  })

  const { isLoading: isRemovingFeedingForAllGuest, action: removeFeedingForAllGuests } = useApiRequest(async () => {
    dispatch(setReservationDetails(await removeFeeding(reservationDetails.urls.feeding, feedingType)))
  })

  const handleFeedingChange = async () => {
    if (isFeedingForAllGuestsAdded) {
      showFeedingChangeConfirmation(null, { onConfirm: removeFeedingForAllGuests, guestText: 'wszystkich gości' })
      return
    }

    await addFeedingForAllGuests()
  }

  const isFeedingAvailable = getSelectableImprovements(resortDetails?.improvements || [], reservationDetails).some(
    isFeeding,
  )

  const { canEditGuest } = useGuestChangeCheck()

  const isFeedingChangeDisabled = (guest: Guest) => canEditGuest(reservationDetails, guest)

  const canToggleAllAtOnce = !reservationDetails.guests.some(isFeedingChangeDisabled)

  const [showFeedingChangeConfirmation] = useModal('GuestFeedingChangeConfirmationModal')

  const handleGuestFeedingChangeCheck = React.useCallback((shouldSelectFeeding: boolean, guest: Guest) => {
    shouldSelectFeeding
      ? toggleFeeding(shouldSelectFeeding, guest)
      : showFeedingChangeConfirmation(null, {
          onConfirm: () => toggleFeeding(shouldSelectFeeding, guest),
          guestText: `gościa ${guest.name}`,
        })
  }, [])

  const { canRemoveGuest } = useGuestChangeCheck()

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="fw-bold text-darker-gray">Osoby dodane do rezerwacji:</div>
        {isFeedingAvailable && !!reservationDetails.guests.length && canToggleAllAtOnce && (
          <button className="btn text-muted fw-bold shadow-none" onClick={handleFeedingChange}>
            {isFeedingForAllGuestsAdded ? 'odznacz wszystkim' : 'zaznacz wszystkim'}
          </button>
        )}
      </div>
      {!reservationDetails.guests.length && <p>Brak dodanych osób do rezerwacji</p>}
      <div className="d-flex flex-column position-relative">
        {reservationDetails.guests.map(guest => (
          <ReservationDataCompletionAddedGuest
            onDelete={deleteGuest}
            onCheck={handleGuestFeedingChangeCheck}
            onEdit={onGuestEdit}
            key={guest.id}
            guest={guest}
            isFeedingAdded={isFeedingAdded(guest)}
            isFeedingAvailable={isFeedingAvailable}
            isFeedingChangeDisabled={isFeedingChangeDisabled(guest)}
            isEditing={editedGuest?.id === guest.id}
            isGuestRemovingEnabled={canRemoveGuest(reservationDetails, guest)}
          />
        ))}
        <BackdropLoader
          isLoading={isDeleting || isTogglingFeeding || isAddingFeedingForAllGuests || isRemovingFeedingForAllGuest}
        />
      </div>
    </>
  )
}
