import { createApi } from '@reduxjs/toolkit/query/react'
import { apiQueryFunction } from './base'
import { RootState } from '@store/index'
import { provideListTags } from '@api/query-helper'
import { noop } from '@hooks/use-payment-modal'
import { CouponItem } from '@models/coupons'
import { CouponItemFormInputs } from '@modules/coupons/coupon-checked-in-reservation'

export const COUPON_ITEMS_API_TAG = 'COUPON_ITEMS'
export const COUPON_ITEMS_API_TAG_IDS = {
  LIST: 'LIST',
}

export const couponsApi = createApi({
  baseQuery: noop as any,
  reducerPath: 'couponsApi',
  tagTypes: [COUPON_ITEMS_API_TAG],
  endpoints: builder => ({
    getCouponItems: builder.query<CouponItem[], void>({
      queryFn: apiQueryFunction((state: RootState) => ({
        method: 'GET',
        url: state.appDataState.appData.urls.clients.coupons,
      })),
      providesTags: provideListTags(COUPON_ITEMS_API_TAG, COUPON_ITEMS_API_TAG_IDS.LIST),
    }),
    createCouponItem: builder.mutation<CouponItem, CouponItemFormInputs>({
      invalidatesTags: [COUPON_ITEMS_API_TAG],
      queryFn: apiQueryFunction((state: RootState, data: CouponItemFormInputs) => ({
        data,
        method: 'POST',
        url: state.appDataState.appData.urls.clients.coupons,
      })),
    }),
  }),
})

export const { useGetCouponItemsQuery, useCreateCouponItemMutation } = couponsApi
