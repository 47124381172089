import { AppData } from '@models/app-data'
import { AppDataActionsTypes, AppDataActionTypesKeys } from '@store/action-types/app-data-action-types'

export interface AppDataState {
  appData: AppData
}

const initialState: AppDataState = {
  appData: {
    coupon_types: [],
    settings: { coupons_enabled: false },
    payment_methods: [],
    company_info: {
      call_center_email: '',
      call_center_phone_number: {
        pl: '',
        en: '',
        de: '',
      },
      call_center_working_hours: {},
    },
    rules: [],
    resorts: [],
    accommodation_types: [],
    languages: [],
    countries: [],
    urls: {
      account: {
        change_password: '',
        register: '',
        login: '',
        logout: '',
        logout_url: '',
        reset_password_link: '',
        reset_password: '',
        user_details: '',
      },
      clients: {
        coupons: '',
        details: '',
        devices: '',
        homepage: '',
        profile_update: '',
      },
      other: {
        nip_search: '',
        reservation_url: '',
      },
    },
    package_vip_improvements_in_price: {
      package_vip: [],
      package_vip_winter: [],
    },
  },
}

const appDataState = (state: AppDataState = initialState, action: AppDataActionsTypes): AppDataState => {
  switch (action.type) {
    case AppDataActionTypesKeys.SET_APP_DATA:
      return { ...state, appData: action.payload }
    default:
      return state
  }
}

export default appDataState
