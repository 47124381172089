import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { TrapezeButton, TrapezeButtonVariant } from '@components/controls/trapeze-button'
import { useDeviceSize } from '@hooks/use-device-size'
import { useModal } from '@components/modals/use-modal'
import { ReservationDetails } from '@models/reservation'
import { NavigationPath } from '@models/routes'
import { useHistory } from 'react-router'
import { isAfterDepartureTime } from '@helpers/reservation'
import { CheckedInReservationFooterMapOption } from '@modules/reservations/checked-in/checked-in-reservation-footer-map-option'

interface Params {
  reservationDetails: ReservationDetails
  withFloatingCheckOutButton?: boolean
}

export const CheckedInReservationFooter = ({
  reservationDetails,
  withFloatingCheckOutButton = false,
}: Params): JSX.Element => {
  const { isDesktop } = useDeviceSize()

  const [handleGastroVoucher] = useModal('AddGastroCardModal', {
    url: reservationDetails.urls.gastro_card,
  })

  const history = useHistory()

  const [showCheckoutModal] = useModal('CheckOutModal', { reservation: reservationDetails })

  const handleCheckOut = () => {
    isDesktop
      ? showCheckoutModal()
      : history.replace(NavigationPath.ReservationDetails.replaceToken(`${reservationDetails.token}/check-out`))
  }

  const [handleFaultReportModal] = useModal('ReportFaultModal', {
    title: 'Zgłoś usterkę',
    reservationDetails,
  })

  return (
    <div className="d-flex flex-column flex-md-row position-relative mb-2 mb-lg-0 align-items-center">
      <div
        className={clsx(
          'pending-reservation__time-counters col-12 col-md-2 p-3',
          !isDesktop ? 'text-navy fw-bold text-center font-size-20' : 'text-darker-gray fw-normal font-size-11',
        )}
      >
        Opcje dodatkowe:
      </div>
      <div className="d-flex flex-column py-0 py-lg-3">
        <CheckedInReservationFooterMapOption resortId={reservationDetails.resort_id} />
        <IconWithText
          text={
            reservationDetails.wallets.length === 0
              ? 'Chcę skorzystać z vouchera gastronomicznego'
              : 'Chcę dodać kolejny voucher gastronomiczny'
          }
          textClassName="text-start"
          iconClassName="uil-tag-alt me-2 font-size-icon-18"
          className="me-2 fw-bold text-muted checked-in-reservation__footer-option"
          onClick={handleGastroVoucher}
        />

        <IconWithText
          text="Chcę zgłosić usterkę"
          iconClassName="uil-wrench me-2 font-size-icon-18"
          className="me-2 fw-bold text-muted checked-in-reservation__footer-option"
          onClick={handleFaultReportModal}
        />

        {isDesktop && !isAfterDepartureTime(reservationDetails) && reservationDetails.can_check_out && (
          <TrapezeButton
            variant={TrapezeButtonVariant.red}
            className="position-absolute end-0 bottom-0"
            onClick={handleCheckOut}
          >
            <IconWithText text="Wymelduj on-line" iconClassName="uil-check font-size-icon-22 me-2" />
          </TrapezeButton>
        )}
      </div>
      {!isDesktop &&
        !isAfterDepartureTime(reservationDetails) &&
        reservationDetails.can_check_out &&
        withFloatingCheckOutButton && (
          <div
            className="pending-reservation-mobile__check-in-button bg-danger w-100 p-3 position-fixed bottom-0 start-0 end-0  d-flex justify-content-center"
            onClick={handleCheckOut}
          >
            <IconWithText
              text="wymelduj on-line"
              iconClassName="uil-check me-2 font-size-icon-22"
              className="text-white fw-bold"
            />
          </div>
        )}
    </div>
  )
}
