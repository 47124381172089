import * as React from 'react'
import { ReservationDetails, Rule } from '@models/reservation'
import { RuleRow } from '@modules/user/gdpr-data/rule-row'
import { RulesNames } from '@helpers/rules'
import { useApiRequest } from '@hooks/use-api-request'
import { commonObjectPatch } from '@api/basic-requests'
import { useDispatch, useSelector } from 'react-redux'
import { selectClientProfileRules } from '@store/selectors/client-selectors'
import { setReservationDetails } from '@store/slices/reservations-slice'
import { AvailableRuleKeyword } from '@models/app-data'
import { useRulesContent } from '@hooks/use-rules-content'

interface Props {
  reservationDetails: ReservationDetails
}

export const ReservationRules = ({ reservationDetails }: Props): JSX.Element => {
  const clientProfileRules = useSelector(selectClientProfileRules)
  const content = useRulesContent()

  const clientProfileRulesKeywords: AvailableRuleKeyword[] = React.useMemo(
    () => clientProfileRules.map(rule => rule.keyword),
    [clientProfileRules],
  )

  const dispatch = useDispatch()

  const { action: updateRule } = useApiRequest(async payload => {
    dispatch(setReservationDetails(await commonObjectPatch<ReservationDetails>(reservationDetails.urls.rules, payload)))
  })

  const reservationRules = reservationDetails.rules.filter(
    (rule: Rule<AvailableRuleKeyword>) => rule.required && !clientProfileRulesKeywords.includes(rule.keyword),
  )
  return (
    <div>
      {reservationRules.map((rule: Rule<AvailableRuleKeyword>) => (
        <RuleRow key={rule.keyword} title={RulesNames[rule.keyword]} rule={rule} onUpdate={updateRule}>
          <span className="font-size-sm fw-light">{content[rule.keyword]}</span>
        </RuleRow>
      ))}
    </div>
  )
}
