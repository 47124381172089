import { ThunkAction } from 'redux-thunk'
import { RootState } from '@store/index'
import axios from 'axios'
import {
  ClientActionTypesKeys,
  SetClientDetailsAction,
  UpdateClientGastroCardAction,
  UpdateClientSubscriptionContractAction,
} from '@store/action-types/client-action-types'
import {
  ClientDetails,
  ClientProfileGuest,
  FlatClientDetails,
  GastroCard,
  SubscriptionContractDetails,
} from '@models/client'
import { updateAccountNumberRequest, updateClientDetailsRequest } from '@api/client-details'
import {
  CreateClientProfileGuestPayload,
  createClientProfileGuestRequest,
  deleteClientProfileGuestRequest,
  UpdateClientProfileGuestPayload,
  updateClientProfileGuestRequest,
} from '@api/client-profile-guest'

export function fetchClientDetails(): ThunkAction<Promise<ClientDetails>, RootState, null, SetClientDetailsAction> {
  return async (dispatch, getState) => {
    const url = getState().appDataState.appData.urls.clients.details
    const { data } = await axios.get(url)
    dispatch(setClientDetails(data))

    return data
  }
}

export function setClientDetails(details: ClientDetails): SetClientDetailsAction {
  return {
    type: ClientActionTypesKeys.SET_CLIENT_DETAILS,
    payload: details,
  }
}

export function updateClientGastroCard(gastroCard: GastroCard): UpdateClientGastroCardAction {
  return {
    type: ClientActionTypesKeys.UPDATE_CLIENT_GASTRO_CARD,
    payload: gastroCard,
  }
}

export function updateClientSubscriptionContract(
  subscriptionContract: SubscriptionContractDetails,
): UpdateClientSubscriptionContractAction {
  return {
    type: ClientActionTypesKeys.UPDATE_CLIENT_SUBSCRIPTION_CONTRACT,
    payload: subscriptionContract,
  }
}

export function updateClientDetails(
  fieldsToUpdate: Partial<FlatClientDetails>,
): ThunkAction<Promise<void>, RootState, null, SetClientDetailsAction> {
  return async (dispatch, getState) => {
    const response = await updateClientDetailsRequest(getState(), fieldsToUpdate)
    await dispatch(setClientDetails(response))
  }
}

export function createClientProfileGuest(
  payload: CreateClientProfileGuestPayload,
): ThunkAction<Promise<void>, RootState, null, SetClientDetailsAction> {
  return async (dispatch, getState) => {
    const response = await createClientProfileGuestRequest(getState(), payload)
    dispatch(setClientDetails(response))
  }
}

export function updateClientProfileGuest(
  guest: ClientProfileGuest,
  payload: UpdateClientProfileGuestPayload,
): ThunkAction<Promise<void>, RootState, null, SetClientDetailsAction> {
  return async dispatch => {
    const response = await updateClientProfileGuestRequest(guest, payload)
    dispatch(setClientDetails(response))
  }
}

export function deleteClientProfileGuest(
  guest: ClientProfileGuest,
): ThunkAction<Promise<void>, RootState, null, SetClientDetailsAction> {
  return async dispatch => {
    const response = await deleteClientProfileGuestRequest(guest)
    dispatch(setClientDetails(response))
  }
}

export function updateAccountNumber(
  account_number: string,
  code: string,
): ThunkAction<Promise<void>, RootState, null, SetClientDetailsAction> {
  return async (dispatch, getState) => {
    const response = await updateAccountNumberRequest(getState(), account_number, code)
    dispatch(setClientDetails(response))
  }
}
