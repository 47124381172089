import * as React from 'react'
import { formatPrice } from '@helpers/price-helper'
import { PriceVariant } from '@modules/reservations/modals/reservation-receipt-modal-row'
import * as clsx from 'clsx'

interface Props {
  children: React.ReactNode
  title: string
  total: string | number
  variant?: PriceVariant
}

export const ReservationReceiptModalBox = ({ children, title, total, variant }: Props): JSX.Element | null => {
  if (React.Children.count(children) === 0) return null

  return (
    <div className="px-3 py-2 bg-light d-flex flex-column border-bottom">
      <div className="fw-bold text-muted pb-2">{title}</div>
      {children}
      <div className="align-self-end pt-2">
        <span className="font-size-sm text-muted pe-4">razem</span>
        <span
          className={clsx(
            'fw-bold font-size-sm',
            { 'text-success': variant === 'positive' },
            { 'text-danger': variant === 'negative' },
          )}
        >
          {formatPrice(total)}
        </span>
      </div>
    </div>
  )
}
