import * as React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface Props {
  handleClose: () => void
  onMoveToTheNextStep: () => void
}

export const GuestSetupNotDetectedModal = ({ handleClose, onMoveToTheNextStep }: Props): JSX.Element => {
  const handleMoveToTheNextStep = () => {
    onMoveToTheNextStep()
    handleClose()
  }

  return (
    <Modal.Body>
      <div className="p-2 d-flex flex-column justify-content-center">
        <div className="position-relative mx-auto mt-4 mb-5">
          <div
            className="position-absolute start-50 top-50 translate-middle rounded-circle border border-2"
            style={{ width: '70px', height: '70px' }}
          />
          <i className="font-size-3xl mt-2 mb-4 uil-user" />
        </div>
        <p className="fw-bold font-size-lg col-8 mx-auto text-center">
          Czy jesteś pewien, że lista gości jest uzupełniona prawidłowo?
        </p>
        <p className="col-8 mx-auto text-center">
          Nie uzupełniając listy prawidłowo, będziesz musiał poświęcić czas swoich wakacji na zrobienie tego w recepcji
          ośrodka.
        </p>
        <div className="d-flex justify-content-center">
          <Button
            variant="secondary"
            onClick={handleMoveToTheNextStep}
            className="text-white px-5 btn-tall mt-3 font-weight-semibold font-10 me-1 col-6"
          >
            Dalej
          </Button>
          <Button
            variant="primary"
            onClick={handleClose}
            className="text-white px-5 btn-tall mt-3 font-weight-semibold font-10 ms-1 col-6"
          >
            Uzupełnij
          </Button>
        </div>
      </div>
    </Modal.Body>
  )
}
