import * as React from 'react'
import { ModalFooter } from '@components/modals/modal-footer'
import { ModalBody } from 'react-bootstrap'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { TrapezeButtonVariant } from '@components/controls/trapeze-button'
import { CustomReactSelect, ReactSelectOption } from '@components/custom-react-select'
import { Reservation } from '@models/reservation'
import { selectAppData } from '@store/selectors/app-data-selectors'
import { useAppSelector } from '@store/index'
import { Resort } from '@models/app-data'

interface Props {
  url: string
  reservations: Reservation[]
  handleClose: () => void
  onReservationSelect: (reservation: Reservation) => void
}

interface FormInputs {
  reservation: ReactSelectOption | null
}

export const CouponsBookingSelectionModal = ({
  handleClose,
  reservations,
  onReservationSelect,
}: Props): JSX.Element => {
  const { resorts } = useAppSelector(selectAppData)
  const methods = useForm<FormInputs>({ defaultValues: { reservation: null } })
  const selectedReservation = useWatch({ control: methods.control, name: 'reservation' })

  const onSubmit = async ({ reservation }: FormInputs) => {
    if (!reservation) return
    onReservationSelect(reservations.find(res => res.id === reservation.value) as Reservation)
    handleClose()
  }

  const formatOptionLabel = ({ label, reservation }) => (
    <div className="text-darker-gray">
      {label} - {resorts.find((resort: Resort) => resort.id === reservation.resort_id)?.name}
    </div>
  )
  const options = reservations.map(reservation => ({
    label: reservation.reservation_number,
    reservation,
    value: reservation.id,
  }))

  return (
    <>
      <ModalBody className="gastro-card__modal-body">
        <FormProvider {...methods}>
          <h4 className="text-secondary">Wybierz pobyt</h4>
          <p className="text-muted font-size-sm">
            Kupony są dostępne w kilku rezerwacjach. Wskaż, w której rezerwacji chcesz skorzystać z promocji.
          </p>
          <CustomReactSelect
            inputName="reservation"
            options={options}
            placeholder="Wybierz rezerwację"
            isSearchable={false}
            formatOptionLabel={formatOptionLabel}
          />
        </FormProvider>
      </ModalBody>
      <ModalFooter
        submitLabel="Przejdź do kuponów"
        onSubmit={methods.handleSubmit(onSubmit)}
        buttonDisabled={!selectedReservation}
        onClose={handleClose}
        saveButtonVariant={TrapezeButtonVariant.secondary}
      />
    </>
  )
}
