import * as React from 'react'
import { useSelector } from 'react-redux'
import { FeedingTypes, ImprovementCode } from '@models/reservation'
import { selectReservationDetailsUrls } from '@store/selectors/reservation-details-selectors'
import { useApiRequest } from '@hooks/use-api-request'
import axios from 'axios'
import { ImprovementPriceCalculationResponse } from '@api/improvements'

export interface ImprovementPriceCalculation {
  improvementCalculation: ImprovementPriceCalculationResponse | null
  isLoading: boolean
}

interface Props {
  amount: number
  improvementCode: ImprovementCode | FeedingTypes | null
  improvementId: number | null
  shouldCalculate: boolean
}

export const useImprovementPriceCalculation = ({
  amount,
  improvementId,
  improvementCode,
  shouldCalculate,
}: Props): ImprovementPriceCalculation => {
  const [improvementCalculation, setImprovementCalculation] =
    React.useState<ImprovementPriceCalculationResponse | null>(null)
  const urls = useSelector(selectReservationDetailsUrls)

  const { action: calculatePrices, isLoading } = useApiRequest(async () => {
    if (!urls?.improvement_calculator || !improvementCode) return

    const payload = { code: improvementCode, amount, ...(improvementId && { id: improvementId }) }
    const { data } = await axios.post(urls.improvement_calculator, [payload])
    setImprovementCalculation(data)
  })

  React.useEffect(() => {
    if (shouldCalculate) {
      calculatePrices()
    }
  }, [urls?.improvement_calculator, amount, improvementCode, shouldCalculate])

  return { improvementCalculation, isLoading }
}
