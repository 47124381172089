import * as React from 'react'
import * as clsx from 'clsx'
import { ModalBody } from 'react-bootstrap'
import { useDeviceSize } from '@hooks/use-device-size'
import { useApiRequest } from '@hooks/use-api-request'
import { formatPrice } from '@helpers/price-helper'
import { ModalFooter } from '@components/modals/modal-footer'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { ReservationWallet } from '@models/reservation'
import { CustomReactSelect, ReactSelectOption, selectCustomStyles } from '@components/custom-react-select'
import { formatDate, getEachDayOfInterval, toDefaultDateFormat, toTextDayFormat } from '@helpers/date-helper'
import { getPurchaseHistory } from '@api/reservation'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import { useSelector } from 'react-redux'
import { ContentLoader } from '@components/loaders/content-loader'
import { PurchaseHistoryTable } from '@modules/reservations/purchase-history-modal/purchase-history-table'

interface FormInputs {
  selected_date: ReactSelectOption
}

interface Props {
  handleClose: () => void
  titleMobile: string
  purchaseHistoryUrl: string
}

export const PurchaseHistoryModal = ({ handleClose, titleMobile, purchaseHistoryUrl }: Props): JSX.Element => {
  const [paymentHistory, setPaymentHistory] = React.useState<ReservationWallet | null>(null)

  const { isMobile } = useDeviceSize()
  const reservationDetails = useSelector(selectReservationDetails)

  const methods = useForm<FormInputs>({
    defaultValues: { selected_date: selectAllOptions },
  })

  const selectedDate = useWatch({ control: methods.control, name: 'selected_date' })

  const { isLoading, action: getPaymentHistory } = useApiRequest(async created => {
    setPaymentHistory(await getPurchaseHistory(purchaseHistoryUrl, created.value))
  })

  const dateSelectOptions = React.useMemo(() => {
    if (!reservationDetails) return []
    const { date_from, date_to } = reservationDetails

    const selectOptions = getEachDayOfInterval(date_from, date_to).map(date => ({
      value: formatDate(date),
      label: (
        <span className="text-nowrap">
          <strong>{toDefaultDateFormat(date)}</strong> {toTextDayFormat(date)}
        </span>
      ),
    }))

    return [selectAllOptions, ...selectOptions]
  }, [reservationDetails])

  React.useEffect(() => {
    getPaymentHistory(selectedDate)
  }, [selectedDate, purchaseHistoryUrl])

  return (
    <>
      <ModalBody className="modal-scrollable">
        {isMobile && (
          <h4 className="text-navy mb-3">
            {titleMobile}
            <span className="fw-light d-block">(rezerwacja {reservationDetails?.reservation_number})</span>
          </h4>
        )}
        <div className="d-flex align-items-center">
          <FormProvider {...methods}>
            <CustomReactSelect
              inputName="selected_date"
              options={dateSelectOptions}
              placeholder="Pokaż wszystkie dni"
              customStyles={getPurchaseHistoryFilterSelectStyle(isMobile)}
              isSearchable={false}
            />
          </FormProvider>
        </div>
        <ContentLoader isLoading={isLoading}>
          <PurchaseHistoryTable items={paymentHistory?.items || []} isReservationNumberShowed={false} />
        </ContentLoader>
        <div
          className={clsx('d-flex justify-content-end align-items-center flex-nowrap text-navy pt-3 fw-bold', {
            'font-size-xl justify-content-between pt-0': isMobile,
          })}
        >
          <p className="font-size-lg">Zakupy łącznie:</p>
          <p className="ps-5 font-size-xl pe-4">{formatPrice(paymentHistory?.items_total_brutto || 0)}</p>
        </div>
      </ModalBody>
      <ModalFooter withCancelButton={true} withSubmit={false} onClose={handleClose} cancelLabel="Zamknij" />
    </>
  )
}

const selectAllOptions = {
  label: 'Pokaż wszystkie dni',
  value: '',
}

export const getPurchaseHistoryFilterSelectStyle = (isMobile: boolean) => ({
  ...selectCustomStyles(isMobile),
  option: {
    ...selectCustomStyles(isMobile).option,
    justifyContent: 'flex-start',
    textAlign: 'left',
    color: '#576067',
    fontSize: '12px',
    fontWeight: '500',
    whiteSpace: 'nowrap',
  },
  control: {
    ...selectCustomStyles(isMobile).control,
    borderWidth: isMobile ? 1 : 0,
    height: 40,
  },
  container: {
    width: isMobile && '100%',
    maxWidth: isMobile ? '100%' : '35%',
  },
  valueContainer: {
    color: '#576067',
  },
  menu: {
    width: isMobile ? '100%' : 'fit-content',
  },
  menuList: {
    maxHeight: '220px',
    width: isMobile ? '100%' : 'fit-content',
    '::-webkit-scrollbar': {
      width: '3px',
      height: '0px',
    },
    '::-webkit-scrollbar-track': {
      background: '#E0E0E0',
      borderRadius: '1.5px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#A5A5A5',
      borderRadius: '1.5px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
})
