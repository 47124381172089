import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { SubscriptionContract, SubscriptionContractPremiumPlans } from '@models/client'
import { StatusVariant } from '@modules/special-products/special-product-status'
import { SpecialProductRowStatus } from '@modules/special-products/special-product-row-status'
import { DateFormats, formatDate } from '@helpers/date-helper'
import { SubscriptionContractPaymentButton } from '@modules/special-products/subscription-contracts/subscription-contract-payment-button'
import { useModal } from '@components/modals/use-modal'
import formatIban from '@helpers/format-iban'

interface Props {
  subscriptionContract: SubscriptionContract
}

export const SubscriptionContractExpandableRowHeader = ({ subscriptionContract }: Props): JSX.Element => {
  const [showPaymentCardNumberChangeModal] = useModal('SubscriptionContractPaymentCardChangeConfirmationModal', {
    subscriptionContract,
  })

  const canEditPaymentCard = subscriptionContract.status === 'confirmed'

  return (
    <div
      className={clsx(
        'py-3 px-2 px-sm-0 d-flex flex-column flex-sm-row align-items-sm-center justify-content-between',
        {
          'opacity-50': subscriptionContract.status === 'completed',
        },
      )}
    >
      <SpecialProductRowStatus
        wrapperClassName="d-block d-md-none"
        productStatus={StatusVariant[subscriptionContract.status]}
        productStatusText={subscriptionContract.status_display}
        title="Status subskrypcji:"
      />
      <div className="px-2 mb-3 mb-sm-0 d-lg-flex flex-wrap">
        <IconWithText
          className={clsx(subscriptionContract.main_payment_card ? 'col-lg-6' : 'col-12')}
          iconClassName="uil-check-square me-2 pt-1 font-size-lg lh-1 align-self-start"
          text={
            <span>
              Numer subskrypcji : <strong className="font-size-sm">{subscriptionContract.number}</strong>
            </span>
          }
        />
        {subscriptionContract.status !== 'cancelled' && (
          <IconWithText
            className={clsx('mt-1 mt-lg-0', subscriptionContract.main_payment_card ? 'col-lg-6' : 'col-12')}
            iconClassName="uil-clock me-2 pt-1 font-size-lg lh-1 align-self-start"
            text={
              <span>
                Data rozpoczęcia:{' '}
                <strong className="me-1 font-size-sm">
                  {subscriptionContract.subscription_started
                    ? formatDate(
                        subscriptionContract.subscription_started,
                        DateFormats.MONTH_WITH_FULL_WORD_MONTH_WITH_YEAR,
                      )
                    : 'Nie rozpoczęto'}
                </strong>
              </span>
            }
          />
        )}
        <IconWithText
          className={clsx('col-lg-6', { 'mt-1': subscriptionContract.status !== 'initial' })}
          iconClassName="uil-award-alt me-2 font-size-xl lh-1 align-self-start"
          text={
            <div>
              Plan subskrypcji:{' '}
              <strong className="font-size-sm">
                {subscriptionContract.plan_display}{' '}
                {SubscriptionContractPremiumPlans.includes(subscriptionContract.plan) ? 'Premium' : ''}
              </strong>
            </div>
          }
        />
        {subscriptionContract.main_payment_card && (
          <IconWithText
            className="col-lg-6 mt-1"
            iconClassName="uil-card-atm me-2 pt-1 font-size-xl lh-1 align-self-start"
            text={
              <span>
                Numer karty:{' '}
                <strong className="font-size-sm">
                  {formatIban(subscriptionContract.main_payment_card.masked_number)}
                </strong>
                {canEditPaymentCard && (
                  <span
                    onClick={showPaymentCardNumberChangeModal}
                    className="text-primary font-size-xs ms-2 cursor-pointer d-lg-inline d-none"
                  >
                    (zmień)
                  </span>
                )}
              </span>
            }
          />
        )}
      </div>
      {subscriptionContract.status === 'initial' && (
        <SubscriptionContractPaymentButton subscriptionContract={subscriptionContract} />
      )}
    </div>
  )
}
