import * as React from 'react'
import { WalletBalance } from '@modules/wallet/wallet-balance'
import { WalletHistory } from '@modules/wallet/wallet-history'
import { WalletHeader } from '@modules/wallet/wallet-header'
import { WalletAccount } from '@modules/wallet/wallet-account'

export const WalletView = (): JSX.Element => (
  <div className="wallet card mt-3 p-0 p-xl-4 border-0">
    <WalletHeader />
    <WalletBalance />
    <hr className="d-xl-none my-3 mx-xl-n4 section-divider" />
    <WalletHistory />
    <hr className="d-xl-none my-3 mx-xl-n4 section-divider" />
    <WalletAccount />
  </div>
)
