import * as React from 'react'
import SignaturePad from 'signature_pad'

const getResizeCanvasHandler = (canvas: HTMLCanvasElement, control: SignaturePad) => () => {
  const ratio = Math.max(window.devicePixelRatio || 1, 1)
  canvas.width = canvas.offsetWidth * ratio
  canvas.height = canvas.offsetHeight * ratio
  canvas.getContext('2d')?.scale(ratio, ratio)
  control.clear()
}

interface Response {
  save: (handler) => void
  clear: () => void
  SignaturePad: () => JSX.Element
}

const createSignaturePad = ref => new SignaturePad(ref.current)

export const useSignaturePad = (): Response => {
  const [signatureControl, setSignatureControl] = React.useState<SignaturePad | null>(null)
  const ref = React.useRef<HTMLCanvasElement>(null)

  React.useEffect(() => {
    if (ref.current) {
      const control = createSignaturePad(ref)
      setSignatureControl(control)
      const resizeHandler = getResizeCanvasHandler(ref.current, control)
      window.addEventListener('resize', resizeHandler)
      resizeHandler()

      return () => {
        window.removeEventListener('resize', resizeHandler)
      }
    }
  }, [ref.current])

  const save = handler => {
    if (signatureControl && !signatureControl?.isEmpty()) {
      handler(signatureControl.toDataURL('image/png'))
    }
  }

  const clear = () => {
    if (signatureControl) {
      signatureControl.clear()
    }
  }

  const SignaturePad = React.useCallback(
    () => <canvas ref={ref} className="signature-pad__canvas bg-light-blue" />,
    [ref],
  )

  return {
    save,
    clear,
    SignaturePad,
  }
}
