import { NativeMessageTypes } from '@models/native'

interface Response {
  openLink: (url: string | undefined) => void
}

export const useLink = (): Response => {
  const openLink = (url: string | undefined) => {
    if (!url) return

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type: NativeMessageTypes.OPEN_EXTERNAL_LINK, payload: url }),
      )
    } else {
      window.open(url, '_blank')?.focus()
    }
  }

  return {
    openLink,
  }
}
