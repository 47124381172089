import * as React from 'react'
import * as clsx from 'clsx'
import { SpecialProductRowStatus } from '@modules/special-products/special-product-row-status'
import { StatusVariant } from '@modules/special-products/special-product-status'
import { ExpandableRowCard } from '@components/expandable-row-card'
import { CashbackVoucher } from '@models/client'
import { useDeviceSize } from '@hooks/use-device-size'
import { NavigationPath } from '@models/routes'
import { useHistory } from 'react-router-dom'
import { CashbackVoucherExpandableHeader } from '@modules/special-products/cashback-vouchers/expandable-row-header/cashback-voucher-expandable-header'
import { useLazyGetCashbackVoucherDetailsQuery } from '@api/cashback-vouchers'
import { CashbackVoucherExpandableRowContent } from '@modules/special-products/cashback-vouchers/cashback-voucher-expandable-row-content'

interface Props {
  voucher: CashbackVoucher
}

export const SingleCashbackVoucher = ({ voucher }: Props): JSX.Element => {
  const [fetchDetails, { data: voucherDetails }] = useLazyGetCashbackVoucherDetailsQuery(voucher.urls.details)

  const [isOpen, setIsOpen] = React.useState(false)
  const { isMobile, isDesktop } = useDeviceSize()

  const history = useHistory()

  const handleToggleOpen = async () => {
    if (!isDesktop) {
      moveToCashbackVoucherDetailsView()
      return
    }

    await fetchDetails(voucher.urls.details)

    setIsOpen(state => !state)
  }

  const aside = !isMobile
    ? () => (
        <SpecialProductRowStatus
          title="Status vouchera:"
          productStatus={StatusVariant[voucher.status]}
          productStatusText={voucher.status_display}
        />
      )
    : undefined

  const moveToCashbackVoucherDetailsView = () => {
    history.push(NavigationPath.CashbackVoucherDetails.replaceId(voucher.id))
  }

  const handleCardClick = () => {
    if (!isDesktop) moveToCashbackVoucherDetailsView()
  }

  return (
    <div onClick={handleCardClick} className="mb-3">
      <ExpandableRowCard
        aside={aside}
        header={<CashbackVoucherExpandableHeader voucher={voucher} />}
        isOpen={isOpen}
        onOpen={handleToggleOpen}
        isExpandDisabled={false}
        className={clsx('cashback-voucher__expandable-row', { 'is-generating': voucher.status === 'generate' })}
      >
        {voucherDetails && <CashbackVoucherExpandableRowContent voucherDetails={voucherDetails} />}
      </ExpandableRowCard>
    </div>
  )
}
