import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import { ModalFooter } from '@components/modals/modal-footer'

interface Props {
  handleClose: () => void
}

export const OpenDoorNfcModalDesktop = ({ handleClose }: Props): JSX.Element => (
  <>
    <ModalBody>
      <p className="text-muted fw-bold text-center font-size-xxl">
        Drzwi do lokalu otworzysz
        <br /> z poziomu aplikacji mobilnej.
      </p>
      <p className="text-muted text-center px-5 pb-5">
        Pobierz aplikację na swój telefon <br /> i otwórz nią drzwi do swojego domku.
      </p>
      <div className="d-flex justify-content-center mb-n3">
        <img src={require('@assets/images/downloadapp.png')} />
      </div>
    </ModalBody>
    <ModalFooter withCancelButton onClose={handleClose} withSubmit={false} />
  </>
)
