import * as React from 'react'
import { SubscriptionContract, SubscriptionContractPremiumPlans } from '@models/client'
import { ReservationDetailsMobileRow } from '@modules/reservations/pending/mobile/reservation-details-mobile-row'
import { SpecialProductStatus, StatusVariant } from '@modules/special-products/special-product-status'
import { DateFormats, formatDate } from '@helpers/date-helper'
import { useModal } from '@components/modals/use-modal'
import formatIban from '@helpers/format-iban'

interface Props {
  subscriptionContract: SubscriptionContract
}

export const SubscriptionContractDetailsBaseInfo = ({ subscriptionContract }: Props): JSX.Element => {
  const [showPaymentCardNumberChangeModal] = useModal('SubscriptionContractPaymentCardChangeConfirmationModal', {
    subscriptionContract,
  })

  const canEditPaymentCard = subscriptionContract.status === 'confirmed'

  const handlePaymentCardChange = () => {
    if (!canEditPaymentCard) return
    showPaymentCardNumberChangeModal()
  }

  return (
    <div className="mx-n2 border-bottom">
      <ReservationDetailsMobileRow icon="ps-4" title="Status subskrypcji:">
        <SpecialProductStatus
          variant={StatusVariant[subscriptionContract.status]}
          text={subscriptionContract.status_display}
        />
      </ReservationDetailsMobileRow>

      <ReservationDetailsMobileRow icon="uil-award-alt" title="Plan subskrypcji:">
        <span>
          {subscriptionContract.plan_display}{' '}
          {SubscriptionContractPremiumPlans.includes(subscriptionContract.plan) ? 'Premium' : ''}
        </span>
      </ReservationDetailsMobileRow>

      <ReservationDetailsMobileRow icon="uil-clock" title="Data rozpoczęcia subskrypcji:">
        {subscriptionContract.subscription_started
          ? formatDate(subscriptionContract.subscription_started, DateFormats.MONTH_WITH_FULL_WORD_MONTH_WITH_YEAR)
          : 'Nie rozpoczęto'}
      </ReservationDetailsMobileRow>
      <ReservationDetailsMobileRow icon="uil-calendar-alt" title="Termin kolejnej płatności:">
        {subscriptionContract.repayment_date
          ? formatDate(subscriptionContract.repayment_date, DateFormats.MONTH_WITH_FULL_WORD_MONTH_WITH_YEAR)
          : '-'}
      </ReservationDetailsMobileRow>
      {subscriptionContract.main_payment_card && (
        <ReservationDetailsMobileRow icon="uil-card-atm" title="Numer karty:">
          <span onClick={handlePaymentCardChange}>
            {formatIban(subscriptionContract.main_payment_card.masked_number)}
            {canEditPaymentCard && <i className="uil-edit-alt ms-2" data-testid="paymentCardChange" />}
          </span>
        </ReservationDetailsMobileRow>
      )}
    </div>
  )
}
