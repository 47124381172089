import { useModal } from '@components/modals/use-modal'
import { PaymentModalAgreements } from '@modules/wallet/modal/payment-method-modal'
import * as React from 'react'

export const noop = (): void => undefined

type Source =
  | 'client-panel-app-reservation'
  | 'client-panel-app-wallet-top-up'
  | 'client-panel-app-check-out'
  | 'client-panel-app-check-in'
  | 'client-panel-app'

interface RedirectFetchUrlPayload {
  amount: string | number
  target?: string
  source: Source
}

interface UsePaymentModalParams {
  paymentUrl?: string
  rulesUrl?: string
  payload?: RedirectFetchUrlPayload
  onClose?: () => void
  agreements?: (params: PaymentModalAgreements) => React.ReactNode
  rulesCheckAction?: () => Promise<void>
}

export const usePaymentModal = ({
  paymentUrl,
  rulesUrl,
  payload,
  onClose = noop,
  agreements,
  rulesCheckAction,
}: UsePaymentModalParams) => {
  const [openModal] = useModal('PaymentMethodModal', { title: 'Wybierz formę płatności' }, { persist: true })

  return (dynamicPayload?: RedirectFetchUrlPayload, dynamicPaymentUrl?: string) => {
    openModal(null, {
      paymentUrl: dynamicPaymentUrl ?? paymentUrl,
      rulesUrl,
      payload: dynamicPayload ?? payload,
      agreements,
      rulesCheckAction,
    })
    onClose()
  }
}
