import * as React from 'react'

const context = React.createContext<(fieldName: string) => boolean>(() => false)

export const FieldIsSavingContextProvider = context.Provider

export const useFieldIsSaving = (isLoading: boolean, dirtyKeys: string[]) =>
  React.useCallback(
    (fieldName: string) => {
      if (isLoading) {
        return dirtyKeys.some(dirtyKey => dirtyKey === fieldName)
      }

      return false
    },
    [isLoading, dirtyKeys],
  )

export const useFieldIsSavingContext = () => React.useContext(context)
