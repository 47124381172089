import * as React from 'react'
import { Col, Collapse, Row } from 'react-bootstrap'
import { ReservationDataCompletionAddGuest } from '@modules/reservations/pending/data-completion-modal/guests/add-guest'
import { ReservationDataCompletionAddedGuests } from '@modules/reservations/pending/data-completion-modal/guests/added-guests'
import { ReservationDataCompletionGuestsFeeding } from '@modules/reservations/pending/data-completion-modal/guests/feeding'
import { ReservationDataCompletionGuestsHeader } from '@modules/reservations/pending/data-completion-modal/guests/header'
import { Guest, ReservationDetails } from '@models/reservation'
import { IconWithText } from '@components/icon-with-text'
import { useSelector } from 'react-redux'
import { AccommodationType } from '@models/app-data'
import { accommodationTypeSelector } from '@store/selectors/reservations'
import { EditGuest } from '@modules/reservations/pending/data-completion-modal/guests/edit-guest'
import { useGuestChangeCheck } from '@hooks/use-guest-change-check'

interface Props {
  reservationDetails: ReservationDetails
  onModalClose: () => void
}
export const ReservationDataCompletionGuests = ({ reservationDetails, onModalClose }: Props): JSX.Element => {
  const [isOpen, setOpen] = React.useState(false)
  const [guestToEdit, setGuestToEdit] = React.useState<Guest | null>(null)

  const handleOpen = React.useCallback(() => setOpen(true), [])
  const handleClose = React.useCallback(() => setOpen(false), [])

  const accommodationType: AccommodationType = useSelector(accommodationTypeSelector)(
    reservationDetails.accommodation_type_id,
  )

  const canAddGuest = accommodationType.max_guests_with_infant > reservationDetails.guests.length

  const handleGuestEdit = (guest: Guest) => {
    setGuestToEdit(guest)
    handleOpen()
  }

  const handleCloseEditGuest = () => {
    handleClose()
    setGuestToEdit(null)
  }

  const { canEditGuest } = useGuestChangeCheck()

  return (
    <div className="px-0 px-lg-4 pt-4">
      <Row>
        <Col xl={5}>
          <ReservationDataCompletionGuestsHeader />
        </Col>
        <Col xl={7}>
          <ReservationDataCompletionAddedGuests
            reservationDetails={reservationDetails}
            onGuestEdit={handleGuestEdit}
            editedGuest={guestToEdit}
          />
        </Col>
      </Row>
      <Collapse in={isOpen}>
        <div className="body-width mb-n3">
          {guestToEdit ? (
            <EditGuest
              guest={guestToEdit}
              onClose={handleCloseEditGuest}
              isGuestKindChangeDisabled={canEditGuest(reservationDetails, guestToEdit)}
            />
          ) : (
            <ReservationDataCompletionAddGuest reservationDetails={reservationDetails} onClose={handleClose} />
          )}
        </div>
      </Collapse>
      {!isOpen && (
        <Row>
          <Col />
          <Col xl={7}>
            <div className="d-flex align-items-center justify-content-between mb-3">
              {canAddGuest && (
                <IconWithText
                  className="text-darker-gray pt-3"
                  text="Dodaj osobę towarzyszącą"
                  iconClassName="font-size-xxl uil-plus me-2"
                  textClassName="fw-bold"
                  onClick={handleOpen}
                />
              )}
            </div>
          </Col>
        </Row>
      )}
      {!isOpen && (
        <ReservationDataCompletionGuestsFeeding reservationDetails={reservationDetails} onModalClose={onModalClose} />
      )}
    </div>
  )
}
