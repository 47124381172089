import * as React from 'react'
import * as clsx from 'clsx'
import { Spinner } from '@components/loaders/spinner'
import { useFormContext } from 'react-hook-form'
import { FormError } from '@components/form-controls/form-error'

interface Props {
  inputName: string
  isLoadingVisible: boolean
  isSucceed?: boolean
  isFailed?: boolean
  inputSuffix?: React.ReactNode
  children: React.ReactNode
}

export const FormControlWithStatus = ({
  inputName,
  isLoadingVisible,
  isSucceed,
  isFailed,
  inputSuffix,
  children,
}: Props): React.ReactElement => {
  const {
    formState: { errors },
  } = useFormContext()

  const hasError = errors && !!errors[inputName]

  return (
    <div
      className={clsx('form-control-with-status__container', {
        'form-control-with-status--active': isLoadingVisible && !hasError,
        'form-control-with-status--succeed': isSucceed && !hasError,
        'form-control-with-status--failed': isFailed || hasError,
      })}
      data-testid="form-control-with-status-wrapper"
    >
      {children}
      {!!inputSuffix && !hasError && (
        <div className="form-control__suffix h-100 d-flex align-items-center">{inputSuffix}</div>
      )}
      <Spinner className="text-primary form-control-with-status__spinner" />
      <i className="uil-check font-size-xxl text-success form-control-with-status__succeed" />
      <i className="uil-times-circle font-size-xxl text-danger form-control-with-status__failed" />
      <FormError inputName={inputName} className="mt-1" />
    </div>
  )
}
