import { ClientDetails, FlatClientDetails } from '@models/client'
import { RootState } from '@store/index'
import axios from 'axios'

export const updateClientDetailsRequest = async (
  state: RootState,
  payload: Partial<FlatClientDetails>,
): Promise<ClientDetails> => {
  const url = state.clientState.details.urls.update
  const { data } = await axios.patch(url, payload)
  return data
}

export const updateAccountNumberRequest = async (
  state: RootState,
  account_number: string,
  code: string,
): Promise<ClientDetails> => {
  const url = state.clientState.details.urls.account_number_update

  const { data } = await axios.put(url, { account_number }, { headers: { 'x-otp': code }, params: { account_number } })
  return data
}
