import { NavigationPath } from '@models/routes'
import { RootState } from '@store/index'
import * as clsx from 'clsx'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { IconWithText } from './icon-with-text'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { pl } from 'date-fns/locale'
import { SubscriptionContractBanner } from '@modules/special-products/subscription-contracts/subscription-contract-banner'

export const AppSideNav = (): JSX.Element => {
  const user = useSelector((state: RootState) => state.clientState.details)

  const { pathname } = useLocation()
  const { push } = useHistory()

  const lastLoginTime = React.useMemo(
    () => (user.last_login ? formatDistanceToNow(parseISO(user.last_login), { locale: pl }) : null),
    [user.last_login],
  )

  const handleRedirect = React.useCallback(
    (path: NavigationPath) => () => {
      push(path)
    },
    [push],
  )

  const renderNavItem = React.useCallback(
    (icon: string, label: string, path: NavigationPath) => (
      <IconWithText
        className={clsx('side-nav__nav-item align-items-center', { active: pathname.match(path) && label !== 'Start' })}
        iconClassName={clsx('side-nav-icon px-4', icon)}
        textClassName="side-nav-text"
        text={label}
        onClick={handleRedirect(path)}
      />
    ),
    [handleRedirect, pathname],
  )

  return (
    <div className="side-nav__wrapper mt-3">
      <div className="card py-5 me-3 bg-darker-grey">
        <div className="px-4 pb-3">
          <div className="side-nav__user font-size-20">
            {user.first_name} {user.last_name}
          </div>
          {lastLoginTime && (
            <div className="side-nav__last-login font-size-10 fw-light text-white">
              Ostatnie logowanie {lastLoginTime}
            </div>
          )}
        </div>
        {renderNavItem('uil-apps', 'Start', NavigationPath.Root)}
        {renderNavItem('uil-user', 'Moje dane', NavigationPath.User)}
        {renderNavItem('uil-location-point', 'Rezerwacje', NavigationPath.Reservations)}
        {renderNavItem('uil-shopping-bag', 'Produkty specjalne', NavigationPath.SpecialProducts)}
      </div>
      <SubscriptionContractBanner />
    </div>
  )
}
