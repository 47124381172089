import { ModalBody } from 'react-bootstrap'
import { ModalFooter } from '@components/modals/modal-footer'
import * as React from 'react'
import { TrapezeButtonVariant } from '@components/controls/trapeze-button'
import { UserRulesContent } from '@modules/user/gdpr-data/user-rules-content'
import { FormProvider, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/selectors/client-selectors'
import { getRuleValues } from '@modules/reservations/pending/utils'
import { useAppUsageRulesCheck } from '@hooks/use-app-usage-rules-check'

interface Props {
  handleClose: () => void
}

export const ClientDataCheckModal = ({ handleClose }: Props): JSX.Element => {
  const clientDetails = useSelector(selectClientDetails)

  const methods = useForm({ defaultValues: getRuleValues(clientDetails.profile.rules, 'general-rules-modal') })
  const { requiredRulesAccepted } = useAppUsageRulesCheck()

  React.useEffect(() => {
    methods.reset(getRuleValues(clientDetails.profile.rules, 'general-rules-modal'))
  }, [clientDetails])

  return (
    <>
      <ModalBody>
        <FormProvider {...methods}>
          <span>Zanim przejdziesz dalej musisz zaakceptować wymagane regulaminy.</span>
          <UserRulesContent clientDetails={clientDetails} context="general-rules-modal" />
        </FormProvider>
      </ModalBody>
      <ModalFooter
        onSubmit={handleClose}
        saveButtonVariant={TrapezeButtonVariant.secondary}
        submitLabel="Przechodzę dalej"
        buttonDisabled={!requiredRulesAccepted}
      />
    </>
  )
}
