import * as React from 'react'
import { SpecialProductCard } from '@modules/special-products/special-product-card'
import { SubscriptionContract } from '@models/client'
import { sortObjectListByDate } from '@helpers/utils'
import { SingleSubscriptionContract } from '@modules/special-products/subscription-contracts/single-subscription-contract'
import { useSubscriptionContractQueryActions } from '@modules/special-products/subscription-contracts/use-subscription-contract-query-actions'
import { SubscriptionContractStatusesToShow } from '@modules/special-products/subscription-contracts/consts'

interface GroupedSubscriptions {
  initial: SubscriptionContract[]
  initial_resume: SubscriptionContract[]
  completed: SubscriptionContract[]
  confirmed: SubscriptionContract[]
  cancelling: SubscriptionContract[]
  cancelled: SubscriptionContract[]
}

interface Props {
  subscriptionContracts: SubscriptionContract[]
}

export const SubscriptionContracts = ({ subscriptionContracts }: Props): JSX.Element => {
  const actionForSubscription = useSubscriptionContractQueryActions(subscriptionContracts)
  const groupedSubscriptions = groupSubscription(subscriptionContracts)

  const sorted = [
    ...sortObjectListByDate(groupedSubscriptions.confirmed, 'created'),
    ...sortObjectListByDate(groupedSubscriptions.initial, 'created'),
    ...groupedSubscriptions.initial_resume,
    ...groupedSubscriptions.cancelling,
    ...groupedSubscriptions.completed,
    ...groupedSubscriptions.cancelled,
  ]

  return (
    <SpecialProductCard title="Twoje subskrypcje">
      {sorted.map((subscriptionContract: SubscriptionContract) => (
        <SingleSubscriptionContract
          key={subscriptionContract.id}
          subscriptionContract={subscriptionContract}
          isDefaultOpen={subscriptionContract.id === actionForSubscription?.id}
        />
      ))}
    </SpecialProductCard>
  )
}

const groupSubscription = (subscriptionContracts: SubscriptionContract[]): GroupedSubscriptions =>
  subscriptionContracts.reduce(
    (acc, subscriptionContract) => {
      if (SubscriptionContractStatusesToShow.includes(subscriptionContract.status)) {
        return { ...acc, [subscriptionContract.status]: [...acc[subscriptionContract.status], subscriptionContract] }
      }

      return acc
    },
    { confirmed: [], initial: [], completed: [], cancelling: [], cancelled: [], initial_resume: [] },
  )
