import * as React from 'react'
import { Form, FormCheckProps } from 'react-bootstrap'
import * as clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { FormError } from '@components/form-controls/form-error'

interface Props extends FormCheckProps {
  name: string
  wrapperClassName?: string
  indeterminate?: boolean
}

export const Checkbox = ({ name, wrapperClassName, indeterminate, ...props }: Props): React.ReactElement => {
  const { register } = useFormContext()

  const inputRef = React.useRef<HTMLInputElement>()

  const { ref, ...rest } = register(name)

  React.useEffect(() => {
    if (inputRef.current && indeterminate !== undefined) {
      inputRef.current.indeterminate = indeterminate
    }
  }, [indeterminate])

  return (
    <div className={clsx(wrapperClassName)}>
      <Form.Check
        {...rest}
        {...props}
        id={name}
        ref={(input: HTMLInputElement) => {
          ref(input)
          inputRef.current = input
        }}
        type="checkbox"
        className={clsx('checkbox cursor-pointer', props.className)}
      />
      <FormError inputName={name} className="mt-2" />
    </div>
  )
}
