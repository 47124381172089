import { LabeledRow } from '@components/labeled-row'
import * as React from 'react'
import { useToggle } from 'rooks'
import { ReactSelectOption } from '@components/custom-react-select'
import { useDeviceSize } from '@hooks/use-device-size'
import { useModal } from '@components/modals/use-modal'
import { WalletHistoryListWrapper } from '@modules/wallet/wallet-history/list-wrapper'
import { Col, FormLabel } from 'react-bootstrap'

export interface WalletHistoryFilterInterface {
  created_before?: ReactSelectOption
  created_after?: ReactSelectOption
  operation?: ReactSelectOption
}

export const WalletHistory = (): JSX.Element => {
  const [isFullList, setIsFullList] = useToggle(false)
  const { isMobile } = useDeviceSize()
  const [handleModal] = useModal('WalletHistoryModal', { title: 'Historia rachunku' })

  const handleFullList = () => {
    if (isMobile) {
      handleModal()
    } else {
      setIsFullList(!isFullList)
    }
  }

  React.useEffect(() => {
    setIsFullList(false)
  }, [isMobile])

  return (
    <LabeledRow hideLabelOnWrap label="Historia rachunku:">
      <FormLabel className="text-muted font-size-xl font-weight-semibold mb-0 mt-2 d-flex">
        Historia rachunku:
      </FormLabel>
      <Col xl={12} xxl={9}>
        <WalletHistoryListWrapper isFullList={isFullList} handleFullList={handleFullList} />
      </Col>
    </LabeledRow>
  )
}
