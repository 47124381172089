import { CheckCard } from '@components/controls/check-card'
import * as React from 'react'
import { useController, useFormContext } from 'react-hook-form'

const noop = () => undefined

interface Props {
  fieldName: string
  radioValue?: string
  className?: string
  content: (active: boolean) => JSX.Element
  disabled?: boolean
  preventDeselect?: boolean
  onChange?: (value: string | boolean | undefined) => void
}
export const CheckCardFormControl = ({
  fieldName,
  radioValue,
  className,
  content,
  disabled,
  preventDeselect = true,
  onChange = noop,
}: Props): JSX.Element => {
  const formControl = useFormContext()
  const {
    field: { onChange: formOnChange, value },
  } = useController({ name: fieldName, control: formControl.control })

  const changeValue = (value: string | boolean | undefined) => {
    formOnChange(value)
    onChange(value)
  }

  const active = radioValue ? radioValue === value : value
  const handleChange = (value: boolean) => {
    if (radioValue) {
      changeValue(value ? radioValue : preventDeselect && radioValue)
    } else {
      changeValue(value)
    }
  }

  return (
    <CheckCard
      data-testid={`${fieldName}-form-control`}
      className={className}
      value={active}
      onChange={handleChange}
      disabled={disabled}
    >
      {content(active)}
    </CheckCard>
  )
}
