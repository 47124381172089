import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/selectors/client-selectors'

export const SubscriptionContractBanner = (): JSX.Element | null => {
  const clientDetails = useSelector(selectClientDetails)

  const [showSubscriptionDescriptionModal] = useModal('SubscriptionContractDescriptionModal', { title: 'Subskrypcja' })

  const hasActiveSubscription = !!clientDetails.subscription_contracts.length

  if (hasActiveSubscription) return null

  return (
    <div className="subscription-contract__banner card mt-3 me-3 px-4">
      <strong className="subscription-contract__banner__title">Subskrypcja</strong>
      <p className="text-success fw-bold font-size-xl lh-initial">
        Z subskrypcją zyskujesz dodatkowe atrakcyjne rabaty
      </p>
      <strong className="mb-1">W ramach prezentów otrzymasz:</strong>
      <Benefit>
        <strong className="text-primary">Powiększony rabat </strong>wyjazdowy
      </Benefit>
      <Benefit>
        co rok <strong className="text-primary">darmowy 5-dniowy wyjazd</strong>
      </Benefit>
      <Benefit>
        rabaty <strong className="text-primary">na wyżywienie</strong>
      </Benefit>
      <IconWithText
        onClick={showSubscriptionDescriptionModal}
        text="Zobacz wszystkie benefity"
        iconClassName="uil-plus font-size-lg me-1"
        textClassName="fw-semibold font-size-sm z-index-1"
        className="mt-3"
      />

      <img
        className="subscription-contract__banner__percent-watermark"
        src={require('@assets/images/percent-watermark.svg')}
        alt="Subskrypcja holidaypark"
      />
    </div>
  )
}

const Benefit = ({ children }) => (
  <IconWithText
    iconClassName="text-primary uil-check font-size-xl me-1 lh-1"
    text={<span className="font-size-sm">{children}</span>}
  />
)
