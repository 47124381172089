import { LabeledRow } from '@components/labeled-row'
import * as React from 'react'
import { Button, Col, FormLabel } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { RootState } from '@store/index'
import { IconWithText } from '@components/icon-with-text'
import { ClientAccountEdit } from '@modules/wallet/wallet-account/client-account-edit'
import { AccountReadOnly } from '@modules/wallet/wallet-account/account-read-only'
import { useToggle } from 'rooks'

export const WalletAccount = (): JSX.Element => {
  const account_number = useSelector((state: RootState) => state.clientState.details.profile.account_number)
  const [isEdit, setIsEdit] = useToggle(false)

  return (
    <LabeledRow
      hideLabelOnWrap
      label={<span className="text-darker-gray">Numer konta do zwrotów:</span>}
      className="mb-3"
    >
      <FormLabel className="text-navy font-size-xl font-weight-semibold mb-0 mt-2 d-flex">
        Numer konta do zwrotów:
      </FormLabel>
      <Col xl={12} xxl={9}>
        {!isEdit ? (
          account_number ? (
            <AccountReadOnly handleEdit={setIsEdit} />
          ) : (
            <Button variant="transparent" onClick={setIsEdit} className="p-0">
              <IconWithText
                text="Dodaj numer konta"
                iconClassName="uil-plus font-size-icon-18 me-2"
                className="fw-bold text-darker-gray"
              />
            </Button>
          )
        ) : (
          <ClientAccountEdit handleEdit={setIsEdit} />
        )}
      </Col>
    </LabeledRow>
  )
}
