import * as React from 'react'
import { SpecialProductBooking } from '@models/client'
import { useRedirectToReservation } from '@modules/special-products/gastro-vouchers/use-redirect-to-reservation'
import * as clsx from 'clsx'

interface Code {
  bookings: SpecialProductBooking[]
}

interface Props {
  code: Code
  className: string
}

export const SpecialProductUsageReservation = ({ code, className }: Props) => {
  const { redirectToReservation } = useRedirectToReservation()

  const handleMoveToReservationDetails = (booking: SpecialProductBooking) => () => {
    redirectToReservation({ reservation_number: booking.reservation_number })
  }

  if (!code.bookings.length) return null

  return (
    <ul className={clsx('font-size-xxs opacity-75 ps-0 mb-0', className)}>
      {code.bookings.map((booking: SpecialProductBooking) => (
        <li key={booking.id}>
          {booking.resort} / Nr. rez.: {booking.reservation_number}{' '}
          <i
            className="uil-external-link-alt ms-2 font-size-md cursor-pointer"
            onClick={handleMoveToReservationDetails(booking)}
          />
        </li>
      ))}
    </ul>
  )
}
