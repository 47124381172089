import * as React from 'react'
import * as clsx from 'clsx'
import { ClientWalletItem } from '@models/wallet'
import { useDeviceSize } from '@hooks/use-device-size'
import { LoadMoreButton } from '@components/load-more-list/load-more-button'

export interface CommonLoadMoreProps {
  customShowMoreLabel?: string
  withCount?: boolean
}

interface LoadMoreListProps extends CommonLoadMoreProps {
  limit?: number
  className?: string
  options?: ClientWalletItem[]
  children: (row) => JSX.Element
  emptyListLabel?: string
  customLabel?: string | JSX.Element | false
  emptyListLabelClassNames?: string
}

export const LoadMoreList = ({
  limit = 5,
  options = [],
  children,
  className,
  emptyListLabel = 'Lista jest pusta',
  customLabel,
  customShowMoreLabel,
  withCount = true,
  emptyListLabelClassNames,
}: LoadMoreListProps): JSX.Element => {
  const [offset, setOffset] = React.useState(limit)

  const { isMobile } = useDeviceSize()

  const scrollToRef = React.useRef<HTMLDivElement>(null)
  const elementsToShow = React.useMemo(() => options.slice(0, offset), [options, offset, limit])
  const leftToBeShown =
    options.length - elementsToShow.length > limit ? limit : Math.max(options.length - elementsToShow.length, 0)
  const handleShowMore = () => {
    if (leftToBeShown <= 0) return
    setOffset(offset + limit)
  }

  React.useEffect(() => {
    setOffset(limit)
  }, [limit])

  React.useEffect(() => {
    if (offset === limit) return
    scrollToRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }, [leftToBeShown])

  return (
    <div className={clsx('overflow-auto', className)}>
      {elementsToShow && !!elementsToShow.length ? (
        elementsToShow.map(row => children(row))
      ) : (
        <p className={clsx('py-3 px-2 mb-0', emptyListLabelClassNames)}>{emptyListLabel || 'Lista jest pusta'}</p>
      )}
      {customLabel ||
        (!!leftToBeShown && (
          <LoadMoreButton
            isMobile={isMobile}
            customShowMoreLabel={customShowMoreLabel}
            withCount={withCount}
            handleShowMore={handleShowMore}
            leftToBeShown={leftToBeShown}
          />
        ))}
      <div ref={scrollToRef} />
    </div>
  )
}
