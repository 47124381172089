import { Reservation } from '@models/reservation'
import * as React from 'react'
import { CheckInBanner } from '@modules/reservations/pending/check-in-banner'
import { ReservationRow } from '@modules/reservations/reservation-row'
import { ToCheckInBanner } from '@modules/reservations/check-in/to-check-in-banner'

interface Props {
  reservations: Reservation[]
}

export const PendingReservationsList = ({ reservations }: Props): JSX.Element => {
  const reservationToCheckIn = React.useMemo(
    () => reservations.find(reservation => reservation.can_check_in),
    [reservations],
  )

  return (
    <>
      {reservationToCheckIn && <CheckInBanner reservation={reservationToCheckIn} />}
      <div className="d-flex flex-column">
        {reservations.map(reservation => (
          <div key={reservation.id} className="position-relative mb-3">
            <ReservationRow reservation={reservation}>
              {isLoaded => isLoaded && reservation.checked_in_online && <ToCheckInBanner reservation={reservation} />}
            </ReservationRow>
          </div>
        ))}
      </div>
    </>
  )
}
