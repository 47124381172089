import * as React from 'react'
import * as clsx from 'clsx'

interface Props extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'title'> {
  title: string | JSX.Element
  description: string
  isActive: boolean
}

export const CheckButton = ({ title, description, isActive, ...props }: Props): React.ReactElement => (
  <button
    {...props}
    className={clsx(
      'd-flex align-items-center check-button',
      { 'is-active': isActive, 'is-disabled': props.disabled },
      props.className,
    )}
  >
    <div className="check-button__icon-wrapper me-4">
      <i className="uil-check check-button__icon" />
    </div>
    <div className="text-start">
      <strong className="d-block">{title}</strong>
      <span className="font-size-sm">{description}</span>
    </div>
  </button>
)
