import * as React from 'react'
import { CloseReservationsList } from '@modules/reservations/close/list'
import { useSelector } from 'react-redux'
import { closeReservationsSelector } from '@store/selectors/reservations'

export const CloseReservations = (): JSX.Element | null => {
  const reservations = useSelector(closeReservationsSelector)

  if (!reservations.length) return null

  return (
    <div className="card border-0 p-0 p-xl-3 mb-3">
      <div className="font-size-20 text-lighter-gray fw-bold mb-3">Zakończone rezerwacje</div>
      <CloseReservationsList reservations={reservations} />
    </div>
  )
}
