import { ProgressWithIcon } from '@components/progress-with-icon'
import { formatPrice } from '@helpers/price-helper'
import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useDeviceSize } from '@hooks/use-device-size'
import { Button } from 'react-bootstrap'
import { useModal } from '@components/modals/use-modal'
import { ReservationDetails } from '@models/reservation'
import Decimal from 'decimal.js'

interface Props {
  reservationDetails: ReservationDetails
}

export const CheckedInReservationDepositState = ({ reservationDetails }: Props): JSX.Element => {
  const remaining = new Decimal(reservationDetails.prices.payments_summary.paid.deposit)
    .minus(reservationDetails.prices.deposit_charge)
    .toString()
  const progress = (parseFloat(remaining) / parseFloat(reservationDetails.prices.payments_summary.paid.deposit)) * 100

  const lowBalance = progress <= 14

  const [handleAddDeposit] = useModal('AddDepositModal', {
    title: `Doładuj kaucję ${reservationDetails.reservation_number}`,
  })

  const [handleOpenPurchaseHistory] = useModal('PurchasePaymentHistoryModal', {
    title: `Historia zakupów (Rezerwacja ${reservationDetails?.reservation_number})`,
    titleMobile: 'Historia zakupów',
    purchaseHistoryUrl: reservationDetails.urls.wallet,
  })

  const { isMobile } = useDeviceSize()

  return (
    <div
      className="card bg-secondary-light-blue ps-3 py-3 my-3 pe-sm-5 pe-4 d-flex flex-column gap-2"
      data-testid="checked-in-reservation-deposit-state"
    >
      <ProgressWithIcon
        progress={progress}
        color={lowBalance ? 'danger' : 'primary'}
        icon="font-size-xxl me-2 uil-file-check-alt text-muted"
        label={
          <div className="py-1 text-muted d-flex justify-content-between">
            <div className="fw-bold">Kaucja do wykorzystania</div>
            {!isMobile && (
              <div className="text-muted">
                <span className="fw-bold"> {formatPrice(remaining)} </span>z{' '}
                {formatPrice(reservationDetails.prices.payments_summary.paid.deposit)}
              </div>
            )}
          </div>
        }
      >
        {isMobile && (
          <div className="text-muted pt-2">
            <span className="fw-bold"> {formatPrice(remaining)} </span>z{' '}
            {formatPrice(reservationDetails.prices.deposit_amount)}
          </div>
        )}
        <div className="d-flex gap-3 pt-2 flex-column-reverse align-items-center align-items-md-start flex-md-row justify-content-between">
          <IconWithText
            text="Historia zakupów"
            iconClassName="uil-receipt me-2 font-size-icon-18"
            className="text-muted fw-bold cursor-pointer opacity-75 mt-3 mt-sm-0"
            onClick={handleOpenPurchaseHistory}
          />
          {isMobile ? (
            <Button variant="danger" className="w-100 d-flex justify-content-center" onClick={handleAddDeposit}>
              <IconWithText
                text="Doładuj"
                iconClassName="uil-money-bill me-2 font-size-icon-18"
                className="text-white cursor-pointer py-2"
              />
            </Button>
          ) : (
            <IconWithText
              text="Doładuj"
              iconClassName="uil-money-bill me-2 font-size-icon-18"
              className="text-danger fw-bold cursor-pointer"
              onClick={handleAddDeposit}
            />
          )}
        </div>
      </ProgressWithIcon>
    </div>
  )
}
