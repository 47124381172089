import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setExpanded } from '@store/slices/expanded-reservation-slice'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import { useApiRequest } from '@hooks/use-api-request'
import { setReservationDetails } from '@store/slices/reservations-slice'
import { commonObjectGet } from '@api/basic-requests'
import { ReservationDetails } from '@models/reservation'
import { checkedInReservationsSelector, pendingReservationsSelector } from '@store/selectors/reservations'
import { useDeviceSize } from '@hooks/use-device-size'
import { useQueryParams } from '@hooks/use-query-params'
import { useResortDetails } from '@hooks/use-resort-details'
import { useForwardProfileRulesToReservation } from '@hooks/use-forward-profile-rules-to-reservation'
import { sortObjectListByDate } from '@helpers/utils'

interface Response {
  isLoading: boolean
}

export const useDefaultExpandedReservation = (): Response => {
  const { isDesktop } = useDeviceSize()
  const query = useQueryParams()
  const token = query.get('token')

  const reservationDetails = useSelector(selectReservationDetails)
  const { forwardRules } = useForwardProfileRulesToReservation()

  const checkedInReservations = useSelector(checkedInReservationsSelector)
  const pendingReservations = useSelector(pendingReservationsSelector)

  useResortDetails()

  const reservations = React.useMemo(
    () => [...sortObjectListByDate(checkedInReservations, 'date_from'), ...pendingReservations],
    [checkedInReservations, pendingReservations],
  )

  const dispatch = useDispatch()

  const { action: fetchReservationDetails, isLoading } = useApiRequest(async (url?: string) => {
    if (!reservations[0] || !isDesktop) return
    const details = await commonObjectGet<ReservationDetails>(url ?? reservations[0].urls.details)
    dispatch(setReservationDetails(details))
    dispatch(setExpanded(details.token))
    forwardRules(details)
  })

  React.useEffect(() => {
    if (!reservationDetails && !!reservations.length && !token) {
      fetchReservationDetails()
    }
  }, [reservations, isDesktop])

  const reservationToExpand = React.useMemo(
    () => reservations.find(reservation => reservation.token === token),
    [reservations, token],
  )

  React.useEffect(() => {
    if (token) {
      if (reservationToExpand && reservationDetails?.token !== token) {
        fetchReservationDetails(reservationToExpand.urls.details)
      }
    }
  }, [token, reservationToExpand, isDesktop])

  return { isLoading }
}
