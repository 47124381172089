import * as React from 'react'
import { AccountSetupLayout } from '@modules/account/account-setup-layout'
import { Refund } from '@modules/refund/refund-form'
import { useHistory, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '@store/index'
import { useApiRequest } from '@hooks/use-api-request'
import { getReservationRefundDetails } from '@store/actions/reservations-action'
import { ContentLoader } from '@components/loaders/content-loader'
import { reservationRefundSelector } from '@store/slices/reservations-slice'
import { NavigationPath } from '@models/routes'

interface Params {
  token: string
}

export const RefundView = (): JSX.Element => {
  const { token } = useParams<Params>()
  const dispatch = useAppDispatch()
  const reservationRefund = useAppSelector(reservationRefundSelector)

  const { replace } = useHistory()

  const { isLoading, action: fetch } = useApiRequest(async () => {
    try {
      if (token) await dispatch(getReservationRefundDetails(token))
    } catch (e) {
      replace(NavigationPath.Error404)
    }
  })

  React.useEffect(() => {
    fetch()
  }, [token])

  return (
    <ContentLoader isLoading={isLoading} className="content-loader--full-screen">
      <AccountSetupLayout>
        <div className="authorization-layout__content-container is-refund">{!!reservationRefund && <Refund />}</div>
      </AccountSetupLayout>
    </ContentLoader>
  )
}
