import * as React from 'react'
import { AccountSetupLayout } from '@modules/account/account-setup-layout'
import { useHistory, useParams } from 'react-router-dom'
import { useApiRequest } from '@hooks/use-api-request'
import { ContentLoader } from '@components/loaders/content-loader'
import { NavigationPath } from '@models/routes'
import { DataProcessingContent } from '@modules/data-processing/data-processing-content'
import { DataProcessingClientData } from '@modules/data-processing/data-processing-client-data'
import { DataProcessingConsents } from '@modules/data-processing/data-processing-consents'
import { useAppDispatch, useAppSelector } from '@store/index'
import { getReservationBookingGuestAgreement } from '@store/actions/reservations-action'
import { bookingGuestAgreementSelector } from '@store/slices/reservations-slice'
import { DataProcessingChangeResult } from '@modules/data-processing/data-processing-change-success'

interface Params {
  token: string
}

export const DataProcessingView = (): JSX.Element => {
  const [isSaved, setIsSaved] = React.useState(false)
  const { token } = useParams<Params>()
  const dispatch = useAppDispatch()
  const bookingGuestAgreement = useAppSelector(bookingGuestAgreementSelector)

  const { replace } = useHistory()

  const { isLoading, action: fetch } = useApiRequest(async () => {
    try {
      await dispatch(getReservationBookingGuestAgreement(token))
    } catch (e) {
      replace(NavigationPath.Error404)
    }
  })

  React.useEffect(() => {
    fetch()
  }, [token])

  const handleDataSave = () => setIsSaved(true)

  return (
    <ContentLoader isLoading={isLoading} className="content-loader--full-screen">
      {isSaved ? (
        <DataProcessingChangeResult />
      ) : (
        <AccountSetupLayout withLogo={false}>
          {!!bookingGuestAgreement && (
            <div className="authorization-layout__content-container is-data-processing card shadow p-5 my-xl-5 my-3">
              <h2 className="text-center text-primary mb-5">Przetwarzanie danych osobowych</h2>
              <DataProcessingContent />
              <DataProcessingClientData details={bookingGuestAgreement} />
              <DataProcessingConsents details={bookingGuestAgreement} onSuccess={handleDataSave} />
            </div>
          )}
        </AccountSetupLayout>
      )}
    </ContentLoader>
  )
}
