import * as React from 'react'
import * as clsx from 'clsx'
import { TrapezeButton, TrapezeButtonVariant } from '@components/controls/trapeze-button'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  onRightActionClick: () => void
  leftAction?: JSX.Element
  title: React.ReactNode
  image: string
  children?: JSX.Element | JSX.Element[]
  cardClassName?: string
  cardImageClassName?: string
}

export const ImprovementCard = ({
  title,
  image,
  onRightActionClick,
  leftAction,
  children,
  cardClassName,
  cardImageClassName,
}: Props): JSX.Element => (
  <div className={clsx('improvements__card card', cardClassName)}>
    <img
      src={image || require('@assets/images/image-fallback.webp')}
      alt="Holiday Park & Resort ulepszenia"
      className={clsx('improvements__card__image', cardImageClassName)}
    />
    <div className="text-muted improvements__card__content">
      <div className="px-3 py-4">
        <div className="fw-bold text-start text-secondary font-size-lg improvements__card__title mb-2">{title}</div>
        {children}
      </div>

      <div className="d-flex align-items-center justify-content-between">
        <div className="ps-3">{leftAction}</div>
        <TrapezeButton variant={TrapezeButtonVariant.red} onClick={onRightActionClick}>
          <IconWithText text="Dodaj" iconClassName="uil-plus me-2 font-size-xxl" className="text-white fw-bold" />
        </TrapezeButton>
      </div>
    </div>
  </div>
)
