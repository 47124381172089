import * as React from 'react'
import { Button, Row } from 'react-bootstrap'
import { ModalFooterProps } from '@components/modals/modal-footer'
import { IconWithText } from '@components/icon-with-text'
import { ButtonLoading } from '@components/controls/button-loading'
import * as clsx from 'clsx'

export const ModalFooterMobile = ({
  onClose,
  onSubmit,
  withCancelButton,
  submitLabel = 'Zapisz',
  submitIcon,
  submitClassName,
  cancelLabel = 'Anuluj',
  buttonDisabled,
  isLoading,
  withSubmit,
}: ModalFooterProps): JSX.Element => (
  <Row className="modal-mobile-footer gx-1 no-wrap">
    {withCancelButton && (
      <Button
        data-testid="closing-button"
        onClick={onClose}
        className={clsx('w-25 modal-mobile-footer__cancel-button text-white fw-bold p-3', {
          'w-25': withSubmit,
          'w-100': !withSubmit,
        })}
      >
        {cancelLabel}
      </Button>
    )}
    {withSubmit && (
      <ButtonLoading
        className={clsx(
          'modal-mobile-footer__submit-button text-white fw-bold p-3',
          {
            'w-75': withCancelButton,
            'w-100': !withCancelButton,
          },
          submitClassName,
        )}
        onClick={onSubmit}
        disabled={buttonDisabled || isLoading}
        isLoading={isLoading}
        type="submit"
      >
        <IconWithText
          text={submitLabel}
          iconClassName={clsx(submitIcon ?? 'me-2 font-size-xl')}
          className="justify-content-center align-items-center flex-row-reverse"
        />
      </ButtonLoading>
    )}
  </Row>
)
