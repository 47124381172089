import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { SubscriptionDetails } from '@models/client'
import * as clsx from 'clsx'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionExpandableRowContentAdditionalOptions = ({ subscriptionDetails }: Props): JSX.Element => (
  <>
    <IconWithText
      className="mt-n3 px-2 pt-1 mb-3"
      iconClassName="me-2 font-size-lg lh-1 uil-user-check"
      text={
        <div className="d-flex">
          <span className="me-1">Opcja zmiany danych:</span>
          <IconWithText
            text={subscriptionDetails.allow_change_client_data ? 'tak' : 'nie'}
            iconClassName={clsx(
              'text-darker-gray me-1 font-size-icon-18 lh-initial',
              subscriptionDetails.allow_change_client_data ? 'uil-check' : 'uil-times',
            )}
          />
        </div>
      }
    />
    <IconWithText
      className="mt-n3 px-2 pt-1 mb-3"
      iconClassName="me-2 font-size-lg lh-1 uil-map-marker-edit"
      text={
        <div className="d-flex">
          <span className="me-1">Opcja zmiany lokalizacji:</span>
          <IconWithText
            text={`${subscriptionDetails.allow_subscription_code_localization_convert ? 'tak' : 'nie'} ${
              subscriptionDetails.allowed_subscription_code_localization_convert <= 0 ? '(wykorzystana)' : ''
            }`}
            iconClassName={clsx(
              'text-darker-gray me-1 font-size-icon-18 lh-initial',
              subscriptionDetails.allow_subscription_code_localization_convert ? 'uil-check' : 'uil-times',
            )}
          />
        </div>
      }
    />
  </>
)
