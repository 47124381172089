import { ReservationDetails } from '@models/reservation'
import { ReservationBill } from '@models/bill'
import * as React from 'react'
import { useApiRequest } from '@hooks/use-api-request'
import { commonObjectGet } from '@api/basic-requests'

interface Response {
  bill: ReservationBill | null
  isLoading: boolean
  refetchBill: () => Promise<void>
}

export const useBillData = (reservationDetails: ReservationDetails | null): Response => {
  const [bill, setBill] = React.useState<ReservationBill | null>(null)

  const fetchBill = async () => {
    if (!reservationDetails) return
    setBill(await commonObjectGet(reservationDetails.urls.bill))
  }

  const { isLoading, action: fetchReservationBill } = useApiRequest(fetchBill)
  const { action: refetchReservationBill } = useApiRequest(fetchBill)

  React.useEffect(() => {
    fetchReservationBill()
  }, [reservationDetails?.urls.bill])

  return { bill, isLoading, refetchBill: refetchReservationBill }
}
