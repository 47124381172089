import * as React from 'react'

interface Response {
  appendEntry: (entry: string) => void
  removeEntry: () => void
  hasPreviousEntry: boolean
  entries: string[]
}

export const useHistoryEntries = (): Response => {
  const [entries, setEntries] = React.useState<string[]>([])

  const appendEntry = (entry: string) => {
    const isEntryAdded = entries.at(-1) === entry
    if (!isEntryAdded) {
      setEntries(state => [...state, entry])
    }
  }

  const removeEntry = () => {
    setEntries(state => state.slice(0, -1))
  }

  const hasPreviousEntry = entries.length > 1

  return {
    appendEntry,
    removeEntry,
    hasPreviousEntry,
    entries,
  }
}
