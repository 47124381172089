import { NavigationPath } from '@models/routes'

export enum RedirectPoint {
  check_in_payment = '#check_in_payment',
  check_in_agreements = '#check_in_agreements',
  check_out_summary = '#check_out_summary',
  check_out_payment = '#check_out_payment',
}

export enum OperationResult {
  success = 'success',
  error = 'error',
  Processing = 'processing',
}

export enum OperationType {
  payment = 'payment',
  refund = 'refund',
  wallet = 'wallet',
  none = 'none',
}

export enum RedirectType {
  user = 'user',
  reservations = 'reservations',
  wallet = 'wallet',
  none = 'none',
  check_in = 'check_in',
  check_out = 'check_out',
  coupons = 'coupons',
  subscription = 'subscription',
  subscription_contract = 'subscription_contract',
  gastro_voucher = 'gastro_voucher',
  gastro_coupon = 'gastro_coupon',
  gastro_card_boost = 'gastro_card_boost',
  package_vip = 'package_vip',
}

export const getOperationTitle = (type: OperationType, result: OperationResult) => {
  if (result === OperationResult.success) {
    if (type === OperationType.payment) {
      return 'Dziękujemy, płatność zakończona sukcesem'
    }
    if (type === OperationType.wallet) {
      return 'Przelew środków z portfela zakończony sukcesem'
    }
    if (type === OperationType.refund) {
      return 'Zwrot środków został zlecony na Twoje konto'
    }

    return 'Operacja powiodła się'
  }

  if (type === OperationType.payment) {
    return 'Płatność nieudana!'
  }
  if (type === OperationType.wallet) {
    return 'Przelew środków z portfela nie powiódł się'
  }
  if (type === OperationType.refund) {
    return 'Niestety nie udało się zlecić zwrotu środków'
  }

  return 'Operacja nie powiodła się'
}

export const getOperationSubtitle = (type: OperationType, result: OperationResult) => {
  if (result === OperationResult.success) {
    if (type === OperationType.payment) {
      return 'Szybko poszło! Cały proces przebiegł poprawnie i płatność została zrealizowana'
    }
    if (type === OperationType.wallet) {
      return 'Przelew środków z portfela nie powiódł się'
    }
    if (type === OperationType.refund) {
      return 'Środki znajdą się na Twoim koncie w ciągu 3 dni roboczych'
    }

    return 'Twoja płatność została potwierdzona.'
  }

  if (type === OperationType.payment) {
    return 'Twoja płatność nie została potwierdzona. Skontaktuj się z BOK w celu wyjaśnienia sytuacji.'
  }
  if (type === OperationType.wallet) {
    return 'Twoja płatność saldem portfela nie powiodła się. Skontaktuj się z BOK w celu wyjaśnienia sytuacji.'
  }
  if (type === OperationType.refund) {
    return 'Zlecenie zwrotu środków nie powiodło się. Skontaktuj się z BOK w celu wyjaśnienia sytuacji.'
  }

  return 'Wystąpił błąd skontaktuj się z BOK w celu wyjaśnienia sytuacji.'
}

interface RedirectParams {
  type: RedirectType
  token: string
  isDesktop: boolean
  result: OperationResult
  id: string
}

export const getRedirect = ({ type, token, isDesktop, result, id }: RedirectParams) => {
  if (type === RedirectType.reservations) {
    return { href: NavigationPath.Reservations, label: 'Wróć do listy rezerwacji' }
  }

  if (type === RedirectType.user) {
    return { href: NavigationPath.User, label: 'Wróć do Konta Gościa' }
  }

  if (type === RedirectType.wallet) {
    return { href: NavigationPath.Wallet, label: 'Wróć do portfela' }
  }

  if (type === RedirectType.check_in) {
    const checkInRedirectPoint =
      result === OperationResult.success ? RedirectPoint.check_in_agreements : RedirectPoint.check_in_payment

    return createResponsiveRedirect({
      isDesktop,
      desktopPath: `${NavigationPath.Reservations}${checkInRedirectPoint}?token=${token}`,
      mobilePath: `${NavigationPath.CheckIn.replaceToken(token)}${checkInRedirectPoint}`,
      label: 'Wróć do meldowania',
    })
  }

  if (type === RedirectType.check_out) {
    const checkOutRedirectPoint =
      result === OperationResult.success ? RedirectPoint.check_out_summary : RedirectPoint.check_out_payment
    return createResponsiveRedirect({
      isDesktop,
      desktopPath: `${NavigationPath.Reservations}${checkOutRedirectPoint}?token=${token}`,
      mobilePath: `${NavigationPath.CheckOut.replaceToken(token)}${checkOutRedirectPoint}`,
      label: 'Wróć do wymeldowania',
    })
  }

  if (type === RedirectType.subscription) {
    return createResponsiveRedirect({
      isDesktop,
      result,
      desktopPath: NavigationPath.SpecialProducts,
      mobilePath: `${NavigationPath.SubscriptionDetails.replaceId(id)}`,
      label: 'Wróć do pakietu prywatnego',
    })
  }

  if (type === RedirectType.subscription_contract) {
    return createResponsiveRedirect({
      isDesktop,
      result,
      desktopPath: NavigationPath.SpecialProducts,
      mobilePath: `${NavigationPath.SubscriptionContractDetails.replaceId(id)}`,
      label: 'Wróć do subskrypcji',
    })
  }

  if (type === RedirectType.gastro_voucher) {
    return createResponsiveRedirect({
      isDesktop,
      result,
      desktopPath: NavigationPath.SpecialProducts,
      mobilePath: `${NavigationPath.GastroVoucherDetails.replaceId(id)}`,
      label: 'Wróć do vouchera Podwójna Radość',
    })
  }

  if (type === RedirectType.gastro_coupon) {
    return createResponsiveRedirect({
      isDesktop,
      result,
      desktopPath: NavigationPath.SpecialProducts,
      mobilePath: `${NavigationPath.GastroVoucherDetails.replaceId(id)}`,
      label: 'Wróć do vouchera Strzał w Dziesiątkę',
    })
  }

  if (type === RedirectType.gastro_card_boost) {
    return createResponsiveRedirect({
      isDesktop,
      result,
      desktopPath: NavigationPath.SpecialProducts,
      mobilePath: `${NavigationPath.GastroVoucherDetails.replaceId(id)}`,
      label: 'Wróć do vouchera Doładowanie Smaku',
    })
  }

  if (type === RedirectType.package_vip) {
    return createResponsiveRedirect({
      isDesktop,
      result,
      desktopPath: NavigationPath.SpecialProducts,
      mobilePath: `${NavigationPath.PackageVipDetails.replaceId(id)}`,
      label: 'Wróć do Pakietu VIP',
    })
  }

  if (type === RedirectType.coupons) {
    return createResponsiveRedirect({
      isDesktop,
      result,
      desktopPath: NavigationPath.Reservations,
      mobilePath: NavigationPath.Coupons,
      label: 'Przejdź do kuponów',
    })
  }

  return null
}

interface ResponsiveRedirect {
  desktopPath: string
  mobilePath: string
  isDesktop: boolean
  label: string
  result?: OperationResult
}

const createResponsiveRedirect = ({ desktopPath, mobilePath, isDesktop, label, result }: ResponsiveRedirect) => {
  if (result === OperationResult.error)
    return {
      href: NavigationPath.Root,
      label: 'Wróć do swojego konta',
    }

  return { href: isDesktop ? desktopPath : mobilePath, label }
}
