import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import { ModalFooter } from '@components/modals/modal-footer'
import { TrapezeButtonVariant } from '@components/controls/trapeze-button'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { FormProvider, useForm } from 'react-hook-form'
import { CustomTextarea } from '@components/custom-textarea'
import { useFormRequest } from '@hooks/use-api-request'
import { ReservationDetails } from '@models/reservation'
import { commonObjectPost } from '@api/basic-requests'
import { useConfirmation } from '@hooks/use-confirmation'

interface Props {
  handleClose: () => void
  reservationDetails: ReservationDetails
}

interface FormInputs {
  description: string
  name: string
}

export const ReportFaultModal = ({ handleClose, reservationDetails }: Props): JSX.Element => {
  const methods = useForm<FormInputs>({
    defaultValues: {
      name: '',
      description: '',
    },
  })

  const { showSuccess } = useConfirmation()

  const { isLoading, action: onSubmit } = useFormRequest(async () => {
    await commonObjectPost(reservationDetails.urls.issue_order, methods.getValues())
    handleClose()
    showSuccess({
      title: 'Twoje zgłoszenie zostało zarejestrowane',
      subtitle:
        'Zgłoszenie trafiło na listę rzeczy do naprawy, zajmiemy się problemem w najbliższym możliwym terminie.',
    })
  }, methods.setError)

  return (
    <>
      <ModalBody className="pb-5">
        <div className="text-muted fw-bold font-size-lg pb-3">Zgłoś usterkę dotyczącą Twojego lokalu:</div>
        <FormProvider {...methods}>
          <div className="col-md-10 pb-3">
            <CustomFormGroup
              label="Nazwa usterki"
              inputName="name"
              formControlProps={{ type: 'text', placeholder: 'np. problem z prysznicem' }}
            />
          </div>
          <CustomTextarea
            name="description"
            label="Opis usterki"
            placeholder="Opisz szczegołowo na czym polega problem"
          />
        </FormProvider>
      </ModalBody>
      <ModalFooter
        isLoading={isLoading}
        submitLabel="Zgłoś usterkę"
        onSubmit={onSubmit}
        withCancelButton={true}
        cancelLabel="Zamknij"
        onClose={handleClose}
        saveButtonVariant={TrapezeButtonVariant.default}
      />
    </>
  )
}
