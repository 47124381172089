import * as React from 'react'
import { AccountSetupLayout } from '@modules/account/account-setup-layout'
import { AccountActionResult } from '@modules/account/account-action-result'
import { OperationResult } from '@modules/payment-result/utils'
import { AccountActivationSuccess } from '@modules/account/account-creation/account-activation-success'

export const AccountActivationView = (): JSX.Element => (
  <AccountSetupLayout>
    <div className="col-sm-10 col-md-5 col-lg-3">
      <AccountActionResult result={OperationResult.success} successComponent={<AccountActivationSuccess />} />
    </div>
  </AccountSetupLayout>
)
