import { FeedingImprovement, Guest, ReservationDetails, ReservationImprovement } from '@models/reservation'
import { useSelector } from 'react-redux'
import { selectInitialFeedingReservationImprovements } from '@store/slices/initial-feedings-improvement-slice'
import { FEEDING_FREEZE_DAYS, IMPROVEMENT_FREEZE_DAYS, useEditableCheck } from '@hooks/use-editable-check'
import { getSelectedFeedingKey, isFeeding, isVip } from '@helpers/improvement-helper'
import { useAppSelector } from '@store/index'
import { selectClientDetails } from '@store/selectors/client-selectors'
import { ClientDetails } from '@models/client'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'

interface EntireImprovementRemoveProps {
  improvement: ReservationImprovement | FeedingImprovement | undefined
  dateFrom?: Date
  reservation?: string
}

interface IsAllowedToRemoveProps {
  dateFrom?: Date
  freezeDays?: number
}

interface FeedingRemoveProps {
  improvement: FeedingImprovement | undefined
  dateFrom?: Date
  reservation?: string
  guest: Guest
}

export const useImprovementRemovingCheck = () => {
  const reservationDetails = useAppSelector(selectReservationDetails)
  const clientDetails = useAppSelector(selectClientDetails)
  const initialFeedingsImprovement = useSelector(selectInitialFeedingReservationImprovements)

  const { isInEditAllowedWindow, isFreshlyAdded } = useEditableCheck()

  const isAllowedToRemove = ({ dateFrom, freezeDays = undefined }: IsAllowedToRemoveProps) => {
    if (!dateFrom) return true

    return isInEditAllowedWindow(dateFrom, freezeDays)
  }

  const canRemoveEntireImprovement = ({ improvement, reservation, dateFrom }: EntireImprovementRemoveProps) => {
    if (!improvement) return true

    if (!improvement.can_remove || isImprovedAddedAsStandaloneProduct(improvement, clientDetails, reservationDetails))
      return false

    const canRemove = isAllowedToRemove({
      dateFrom,
      freezeDays: isFeeding(improvement) ? FEEDING_FREEZE_DAYS : IMPROVEMENT_FREEZE_DAYS,
    })

    return canRemove || (isFeeding(improvement) ? canRemoveFeeding(reservation) : isFreshlyAdded(improvement))
  }

  const canRemoveFeeding = (reservation: string | undefined) => {
    const reservationInitials = initialFeedingsImprovement.filter(el => el.reservation === reservation)
    if (!reservationDetails || !reservationInitials.length) return true

    const initialSelectedFeeding = getSelectedFeedingKey(reservationInitials)

    return initialSelectedFeeding === 'none'
  }

  const canRemoveFeedingForGuest = ({ improvement, dateFrom, reservation, guest }: FeedingRemoveProps) => {
    if (!improvement) return true

    if (!improvement.can_remove) return false

    if (isAllowedToRemove({ dateFrom })) return true

    return !initialFeedingsImprovement.some(
      initial => initial.guest_id === guest.id && initial.reservation === reservation,
    )
  }

  return {
    canRemoveEntireImprovement,
    canRemoveFeedingForGuest,
    canRemoveFeeding,
  }
}

const isImprovedAddedAsStandaloneProduct = (
  improvement: ReservationImprovement | FeedingImprovement,
  clientDetails: ClientDetails,
  reservationDetails: ReservationDetails | undefined,
) => {
  if (isVip(improvement as ReservationImprovement) && reservationDetails) {
    return clientDetails.packages_vip.some(
      vip => vip.booking?.reservation_number === reservationDetails.reservation_number,
    )
  }

  return false
}
