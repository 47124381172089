import { ReservationDetailsMobileHeader } from '@modules/reservations/pending/mobile/reservation-details-mobile-header'
import { PendingReservationPayment } from '@modules/reservations/pending/payment'
import { ReservationUpgrades } from '@modules/reservations/reservation-upgrades'
import { PendingReservationTimeSavedIndicator } from '@modules/reservations/pending/time-saved-indicator'
import { PendingReservationFooter } from '@modules/reservations/pending/footer'
import * as React from 'react'
import { ReservationDetails } from '@models/reservation'
import { Resort } from '@models/app-data'
import { ReservationDetailsMobileBasicInfo } from '@modules/reservations/pending/mobile/reservation-details-mobile-basic-info'
import { ReservationVouchers } from '@modules/reservations/reservation-vouchers'
import { CheckInBanner } from '@modules/reservations/pending/check-in-banner'
import { useForwardProfileRulesToReservation } from '@hooks/use-forward-profile-rules-to-reservation'

interface Props {
  reservationDetails: ReservationDetails
  resort: Resort
}

export const PendingReservationContentMobile = ({ reservationDetails, resort }: Props): JSX.Element => {
  const { forwardRules } = useForwardProfileRulesToReservation()

  React.useEffect(() => {
    forwardRules(reservationDetails)
  }, [])

  return (
    <>
      <ReservationDetailsMobileHeader reservationDetails={reservationDetails} resort={resort} />
      {reservationDetails.can_check_in && <CheckInBanner reservation={reservationDetails} />}
      <ReservationDetailsMobileBasicInfo reservationDetails={reservationDetails} resort={resort} />
      <div className="text-navy font-size-xxl fw-bold text-center mt-4">Rozliczenie pobytu</div>
      <PendingReservationPayment reservationDetails={reservationDetails} />
      <ReservationVouchers reservationDetails={reservationDetails} />
      <ReservationUpgrades reservationDetails={reservationDetails} />
      <PendingReservationTimeSavedIndicator reservationDetails={reservationDetails} />
      <PendingReservationFooter reservationDetails={reservationDetails} />
    </>
  )
}
