import * as React from 'react'

interface UseOnScreenResult {
  isVisible: boolean
}

export const useInVisibleOnScreen = <T extends HTMLElement>(ref: React.RefObject<T>): UseOnScreenResult => {
  const [isIntersecting, setIntersecting] = React.useState(false)

  const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

  React.useEffect(() => {
    if (!ref.current) return
    observer.observe(ref.current)

    return () => {
      observer.disconnect()
    }
  }, [ref.current])

  return { isVisible: isIntersecting }
}
