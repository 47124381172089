import * as React from 'react'
import { AppTopbar } from '@components/topbar'

interface Props {
  children: React.ReactNode
  action?: 'back' | 'close'
  onAction: () => void
}

export const FullScreenModalLayout = React.forwardRef(
  ({ children, action = 'back', onAction }: Props, ref: React.ForwardedRef<HTMLDivElement>): JSX.Element => (
    <div className="layout__wrapper layout__wrapper--with-mobile-button">
      <AppTopbar action={action} onAction={onAction} hideMenuIcon />
      <div className="layout__content-wrapper" ref={ref}>
        <div className="layout__container container-xxl d-flex align-items-start">
          <div className="layout__content">{children}</div>
        </div>
      </div>
    </div>
  ),
)
