import * as React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import * as NumberFormat from 'react-number-format'
import * as clsx from 'clsx'

interface Props {
  name: string
  className?: string
  format: string
  mask?: string
  allowEmptyFormatting?: boolean
}

export const MaskedFormControl = ({
  name,
  className,
  format,
  mask = '_',
  allowEmptyFormatting,
}: Props): JSX.Element => {
  const { control } = useFormContext()

  return (
    <Controller
      render={({ field: { onChange, value } }) => (
        <NumberFormat
          className={clsx(
            'form-control shadow-none form-control-height-lg font-size-12 text-darker-gray fw-bold form-control-with-status',
            { 'no-value': !value },
            className,
          )}
          format={format}
          mask={mask}
          value={value}
          onValueChange={({ value, formattedValue }) =>
            onChange(formattedValue.includes(mask) ? value : formattedValue)
          }
          allowEmptyFormatting={allowEmptyFormatting}
          id={name}
          data-testid={`${name}-form-control`}
        />
      )}
      control={control}
      name={name}
    />
  )
}
