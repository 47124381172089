import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { Reservation } from '@models/reservation'
import { useRemainingTime } from '@hooks/use-remaining-time'
import { createCombinedDateWithTime, parseISODate } from '@helpers/date-helper'
import { useLink } from '@hooks/use-link'
import { selectAppData } from '@store/selectors/app-data-selectors'
import { useAppSelector } from '@store/index'
import { isToday } from 'date-fns'

interface Props {
  upcomingReservation: Reservation | undefined
}

export const CouponUncheckedReservationBanner = ({ upcomingReservation }: Props): JSX.Element => {
  const { urls } = useAppSelector(selectAppData)

  const { openLink } = useLink()

  const openReservationWebsite = () => {
    openLink(urls.other.reservation_url)
  }

  const reservationStartsToday = React.useMemo(
    () => upcomingReservation && isToday(parseISODate(upcomingReservation.date_from) as Date),
    [upcomingReservation?.date_from],
  )

  return (
    <div className="col-12 col-md-8 mx-auto">
      <div className="coupons-view__info-box pt-4 pb-2">
        <i className="uil-clock-three font-size-3xl text-primary text-center mt-2 mb-3 d-block" />
        <p className="text-primary font-size-xxl text-center col-10 mx-auto fw-bold">
          Zakup kuponów dostępny tylko dla zameldowanych Gości
        </p>
      </div>
      <div className="coupons-view__action-box__wrapper">
        <div className="coupons-view__action-box__content">
          {reservationStartsToday ? (
            <IconWithText
              text="Zamelduj się i korzystaj z kuponów!"
              iconClassName="uil-smile-beam font-size-lg me-2 lh-1"
              className="font-size-sm justify-content-center mt-n1"
            />
          ) : upcomingReservation ? (
            <UpcomingReservationCountdown upcomingReservation={upcomingReservation} />
          ) : (
            <IconWithText
              onClick={openReservationWebsite}
              text="Zarezerwuj nowy pobyt, aby skorzystać"
              iconClassName="uil-store font-size-lg me-2 lh-1"
              className="font-size-sm justify-content-center mt-n1"
            />
          )}
        </div>
      </div>
    </div>
  )
}

const UpcomingReservationCountdown = ({ upcomingReservation }: { upcomingReservation: Reservation }) => {
  const { remainingTime, isLoading: isRemainingTimeCalculating } = useRemainingTime(
    createCombinedDateWithTime(upcomingReservation.date_from, upcomingReservation.arrival_time),
  )
  return (
    <>
      Twoja najbliższa rezerwacja rozpocznie się za:
      <IconWithText
        text={isRemainingTimeCalculating ? <p className="font-size-sm mb-0">Ładowanie...</p> : remainingTime}
        iconClassName="uil-clock-five font-size-lg me-1"
        className="font-size-sm justify-content-center"
      />
    </>
  )
}
