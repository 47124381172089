import { ClientDetails, GastroCard, SubscriptionContractDetails } from '@models/client'

export enum ClientActionTypesKeys {
  SET_CLIENT_DETAILS = 'SET_CLIENT_DETAILS',
  UPDATE_CLIENT_GASTRO_CARD = 'UPDATE_CLIENT_GASTRO_CARD',
  UPDATE_CLIENT_SUBSCRIPTION_CONTRACT = 'UPDATE_CLIENT_SUBSCRIPTION_CONTRACT',
}

export interface SetClientDetailsAction {
  type: ClientActionTypesKeys.SET_CLIENT_DETAILS
  payload: ClientDetails
}

export interface UpdateClientGastroCardAction {
  type: ClientActionTypesKeys.UPDATE_CLIENT_GASTRO_CARD
  payload: GastroCard
}

export interface UpdateClientSubscriptionContractAction {
  type: ClientActionTypesKeys.UPDATE_CLIENT_SUBSCRIPTION_CONTRACT
  payload: SubscriptionContractDetails
}

export type ClientActionsTypes =
  | SetClientDetailsAction
  | UpdateClientGastroCardAction
  | UpdateClientSubscriptionContractAction
