import * as React from 'react'
import { OperationResult } from '@modules/payment-result/utils'

interface Props {
  result: OperationResult
  title: string
  subtitle: React.ReactNode
}
export const OperationHeader = ({ result, title, subtitle }: Props): JSX.Element => (
  <>
    <div className="mb-4">
      <img src={require('@assets/images/logo_holiday_color.svg')} height="38" />
    </div>
    <div className="circle d-flex align-items-center justify-content-center my-2">
      <img src={require(`@assets/images/${result}.svg`)} />
    </div>
    <div className="text-primary font-size-icon-sm text-center fw-bold">{title}</div>
    <div className="text-muted text-center">{subtitle}</div>
  </>
)
