import * as React from 'react'
import { AccountSetupLayout } from '@modules/account/account-setup-layout'
import { AccountActionResult } from '@modules/account/account-action-result'
import { OperationResult } from '@modules/payment-result/utils'

export const DataProcessingChangeResult = (): JSX.Element => {
  const handleRedirect = () => {
    window.location.href = 'https://holidaypark.pl/'
  }

  return (
    <AccountSetupLayout>
      <div className="col-sm-10 col-md-5 col-lg-3">
        <AccountActionResult
          actionButtonText="Wróć na stronę główną"
          action={handleRedirect}
          result={OperationResult.success}
          successComponent={<SuccessComponent />}
        />
      </div>
    </AccountSetupLayout>
  )
}

const SuccessComponent = () => (
  <>
    <h4 className="text-secondary fw-bold text-center mt-5 mb-2">Gotowe!</h4>
    <p className="mb-4 mt-3 text-center font-size-14">Twoje zmiany zostały zapisane.</p>
  </>
)
