const declination = {
  person: (num: number): string => (num > 1 ? 'osób' : 'osoby'),
  day: (num: number): string => (num > 1 ? 'dni' : 'dzień'),
  stayDays: (num: number) => (num > 1 ? (num < 5 ? 'doby' : 'dób') : 'doba'),
  pln: (num: number | string) => {
    const val = typeof num === 'string' ? parseInt(num) : num
    if (val === 1) {
      return 'złoty'
    } else if (val % 10 >= 2 && val % 10 <= 4 && (val % 100 < 10 || val % 100 >= 20)) {
      return 'złote'
    } else {
      return 'złotych'
    }
  },
}

export default declination
