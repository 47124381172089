import * as React from 'react'
import { ProgressColor, ProgressWithIcon } from '@components/progress-with-icon'
import { SpecialProductRowStatus } from '@modules/special-products/special-product-row-status'
import { StatusVariant } from '@modules/special-products/special-product-status'
import { SpecialProductVoucherCode } from '@modules/special-products/special-product-voucher-code'
import { CashbackVoucher } from '@models/client'

interface Props {
  voucher: CashbackVoucher
  progressValue: number
  progressColor: ProgressColor
  voucherAmount: ({ className, voucher }: { className: string; voucher: CashbackVoucher }) => JSX.Element
  withStatus?: boolean
}

export const CashbackVoucherExpandableBaseHeader = ({
  voucher,
  progressColor,
  voucherAmount,
  progressValue,
  withStatus = true,
}: Props) => {
  const VoucherAmountComponent = voucherAmount

  return (
    <div className="p-3 d-flex px-sm-0 flex-column flex-sm-row justify-content-between align-items-sm-center w-100">
      {withStatus && (
        <SpecialProductRowStatus
          wrapperClassName="d-block d-md-none"
          title="Status vouchera:"
          productStatus={StatusVariant[voucher.status]}
          productStatusText={voucher.status_display}
        />
      )}

      <ProgressWithIcon
        progress={progressValue}
        color={progressColor}
        icon="font-size-xxl px-1 uil-tag-alt text-muted"
        label={
          <div className="d-sm-flex d-inline-flex justify-content-between">
            <SpecialProductVoucherCode code={voucher.number} text="Numer vouchera:" />
            <VoucherAmountComponent className="d-sm-flex mb-1 d-none" voucher={voucher} />
          </div>
        }
      />
      <VoucherAmountComponent className="d-flex d-sm-none mt-2 ms-4" voucher={voucher} />
    </div>
  )
}
