import * as React from 'react'
import { SubscriptionContract } from '@models/client'
import { useQueryParams } from '@hooks/use-query-params'
import { useModal } from '@components/modals/use-modal'
import { useHistory } from 'react-router'
import { useDeviceSize } from '@hooks/use-device-size'
import { NavigationPath } from '@models/routes'
import { useDidUpdate } from 'rooks'
import { SubscriptionContractStatusesWithDetailsAvailable } from '@modules/special-products/subscription-contracts/consts'

enum SubscriptionContractQueryActions {
  PAYMENT_CARD_CHANGE = 'subscription-contract-payment-card-change',
}

export const useSubscriptionContractQueryActions = (subscriptionContracts: SubscriptionContract[]) => {
  const { isDeviceDetected, isDesktop } = useDeviceSize()
  const [showSubscriptionPaymentCardChangeModal] = useModal('SubscriptionContractPaymentCardChangeConfirmationModal')
  const query = useQueryParams()

  const action = query.get('action')
  const subscriptionId = query.get('id')

  const history = useHistory()

  const [activeSubscriptionContract, setActiveSubscriptionContract] = React.useState<SubscriptionContract | undefined>(
    undefined,
  )

  React.useEffect(() => {
    if (!action || !subscriptionId) return

    setActiveSubscriptionContract(
      subscriptionContracts.find(
        (subscriptionContract: SubscriptionContract) => subscriptionContract.id === parseInt(subscriptionId),
      ),
    )
  }, [])

  React.useEffect(() => {
    if (action?.startsWith(SubscriptionContractQueryActions.PAYMENT_CARD_CHANGE) && activeSubscriptionContract) {
      showSubscriptionPaymentCardChangeModal(null, { subscriptionContract: activeSubscriptionContract })
      query.delete('action')
      query.delete('id')
      history.replace({ search: query.toString() })
    }
  }, [action, activeSubscriptionContract])

  useDidUpdate(() => {
    if (!isDeviceDetected) return

    if (
      !isDesktop &&
      activeSubscriptionContract &&
      SubscriptionContractStatusesWithDetailsAvailable.includes(activeSubscriptionContract.status)
    ) {
      history.push(NavigationPath.SubscriptionContractDetails.replaceId(activeSubscriptionContract.id))
    }

    setActiveSubscriptionContract(undefined)
  }, [isDeviceDetected])

  return activeSubscriptionContract
}
