import * as React from 'react'
import { FormCheckProps } from 'react-bootstrap'
import * as clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { Checkbox } from '@components/controls/checkbox'
import { extractInnerRef } from '@helpers/utils'

interface Props extends FormCheckProps {
  name: string
  children?: React.ReactNode
}

export const CheckboxWithDescription = ({ name, children, ...props }: Props): JSX.Element => {
  const { register } = useFormContext()

  return (
    <div>
      <Checkbox
        {...props}
        id={name}
        type="checkbox"
        className={clsx('checkbox cursor-pointer', props.className)}
        {...extractInnerRef(register(name))}
      />
      <div className="checkbox__description">{children}</div>
    </div>
  )
}
