import * as React from 'react'
import { SubscriptionDetails } from '@models/client'
import { ReservationDetailsMobileRow } from '@modules/reservations/pending/mobile/reservation-details-mobile-row'
import { SpecialProductStatus, StatusVariant } from '@modules/special-products/special-product-status'
import { IconWithText } from '@components/icon-with-text'
import { DateFormats, formatDate } from '@helpers/date-helper'
import * as clsx from 'clsx'

interface Props {
  subscriptionDetails: SubscriptionDetails
}

export const SubscriptionDetailsBaseInfo = ({ subscriptionDetails }: Props): JSX.Element => (
  <div className="mx-n2 border-bottom">
    <ReservationDetailsMobileRow icon="ps-4" title="Status pakietu:">
      <SpecialProductStatus
        variant={StatusVariant[subscriptionDetails.status]}
        text={subscriptionDetails.status_display}
      />
    </ReservationDetailsMobileRow>
    <ReservationDetailsMobileRow icon="uil-tag-alt" title="Aktywnych kodów:">
      <span className={clsx({ 'text-success': subscriptionDetails.status !== 'initial' })}>
        {subscriptionDetails.status === 'initial' ? 0 : subscriptionDetails.codes_available}
      </span>{' '}
      / <span>{subscriptionDetails.codes_total}</span>
    </ReservationDetailsMobileRow>
    <ReservationDetailsMobileRow icon="uil-calender" title="Kupiony:">
      <IconWithText
        text={formatDate(subscriptionDetails.created, DateFormats.MONTH_WITH_FULL_WORD_MONTH_WITH_YEAR)}
        iconClassName="uil-info-circle text-primary me-2 font-size-icon-18"
      />
    </ReservationDetailsMobileRow>
    {subscriptionDetails.status === 'confirmed' &&
      (subscriptionDetails.allow_subscription_code_localization_convert ||
        subscriptionDetails.allow_change_client_data) && (
        <>
          <ReservationDetailsMobileRow icon="uil-user-check" title="Opcja zmiany danych osobowych:">
            <IconWithText
              text={subscriptionDetails.allow_change_client_data ? 'tak' : 'nie'}
              iconClassName={clsx(
                'text-darker-gray me-1 font-size-icon-18 lh-initial',
                subscriptionDetails.allow_change_client_data ? 'uil-check' : 'uil-times',
              )}
            />
          </ReservationDetailsMobileRow>
          <ReservationDetailsMobileRow icon="uil-map-marker-edit" title="Opcja zmiany lokalizacji:">
            <IconWithText
              text={`${subscriptionDetails.allow_subscription_code_localization_convert ? 'tak' : 'nie'} ${
                subscriptionDetails.allowed_subscription_code_localization_convert <= 0 ? '(wykorzystana)' : ''
              }`}
              iconClassName={clsx(
                'text-darker-gray me-1 font-size-icon-18 lh-initial',
                subscriptionDetails.allow_subscription_code_localization_convert ? 'uil-check' : 'uil-times',
              )}
            />
          </ReservationDetailsMobileRow>
        </>
      )}
  </div>
)
