import * as React from 'react'
import { CashbackVoucherDetailsWalletItem } from '@models/client'
import { toDefaultDateFormat, toDefaultTimeFormat } from '@helpers/date-helper'
import { formatPrice } from '@helpers/price-helper'

interface Props {
  items: CashbackVoucherDetailsWalletItem[]
}

export const CashbackVoucherPurchaseHistoryDesktopTable = ({ items }: Props): JSX.Element => (
  <table className="table w-100 reservation-payment-history__table mb-0">
    <colgroup>
      <col width="5%" />
      <col width="10%" />
      <col width="40%" />
      <col width="25%" />
      <col width="10%" />
    </colgroup>
    <thead className="bg-darker-grey text-white fw-bold">
      <tr>
        <th className="ps-3" />
        <th>Data</th>
        <th>Produkt</th>
        <th className="text-nowrap ps-4">Zakupione przez</th>
        <th className="text-nowrap text-end">Wartość razem</th>
      </tr>
    </thead>
    <tbody>
      {items.map((item, index) => (
        <tr key={item.id}>
          <td className="reservation-payment-history__cell--first-column vertical-align-middle text-center ps-4">
            <strong>{index + 1}.</strong>
          </td>
          <td className="reservation-payment-history__cell vertical-align-middle">
            <strong> {toDefaultDateFormat(item.created)}</strong>
            <br />
            <span>({toDefaultTimeFormat(item.created)})</span>
          </td>
          <td className="reservation-payment-history__cell--name vertical-align-middle">
            <strong className="d-block">{item.description}</strong>
          </td>
          <td className="reservation-payment-history__cell--quantity vertical-align-middle text-nowrap ps-4">
            {item.booking_guest}
          </td>
          <td className="reservation-payment-history__cell vertical-align-middle text-end">
            <strong>{formatPrice(item.amount)}</strong>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
)
