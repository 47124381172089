import * as React from 'react'
import { ReservationDetails, ReservationImprovement } from '@models/reservation'
import { ImprovementSummaryItem } from '@modules/reservations/improvements/summary/improvement-summary-item'
import { formatPrice } from '@helpers/price-helper'
import { Accordion } from 'react-bootstrap'
import { useToggle } from 'rooks'
import { createSingleFeedingImprovement, isPaidByVip, sortImprovementByPosition } from '@helpers/improvement-helper'
import { createCombinedDateWithTime } from '@helpers/date-helper'
import Decimal from 'decimal.js'

interface Props {
  reservationDetails: ReservationDetails
}

export const ImprovementsSummary = ({ reservationDetails }: Props): JSX.Element => {
  const [isCollapsed, toggleIsCollapsed] = useToggle()

  const improvements = sortImprovementByPosition([
    ...reservationDetails.prices.improvements.items.filter(improvement => !isPaidByVip(improvement)),
    ...(reservationDetails.prices.feeding.items.length
      ? [createSingleFeedingImprovement(reservationDetails.prices.feeding)]
      : []),
  ])

  const totalPrice = new Decimal(reservationDetails.prices.feeding.total_price_brutto)
    .plus(reservationDetails.prices.improvements.total_price_brutto)
    .toString()

  return (
    <div className="improvements__summary">
      <Accordion className="mt-2 b-0">
        <Accordion.Item eventKey="0">
          <Accordion.Header
            onClick={toggleIsCollapsed}
            className="d-flex align-item-center justify-content-between w-100"
          >
            <div className="d-flex justify-content-between align-items-center w-100">
              <strong className="d-block">Dodane ulepszenia ({improvements.length})</strong>
              <span className="pe-2 font-size-sm text-muted lh-initial d-sm-block d-none">
                {isCollapsed ? 'zwiń' : 'rozwiń'}
              </span>
            </div>
          </Accordion.Header>
          <Accordion.Body className="p-0">
            {improvements.map((improvement: ReservationImprovement) => (
              <ImprovementSummaryItem
                key={improvement.id}
                reservationImprovement={improvement}
                reservationStartDate={createCombinedDateWithTime(
                  reservationDetails.date_from,
                  reservationDetails.arrival_time,
                )}
                reservationToken={reservationDetails.token}
              />
            ))}
            <div className="d-flex justify-content-end border-top bg-white">
              <div className="d-flex ps-2 ps-xl-4 py-3 border border-top-0 round-border-bl round-border-br bg-light-blue improvements__summary__final-price">
                <strong>Koszt ulepszeń łącznie:</strong>
                <strong className="ms-3">{formatPrice(totalPrice)}</strong>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}
