import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { CustomReactSelect } from '@components/custom-react-select'
import { ModalFooter } from '@components/modals/modal-footer'
import { useApiRequest, useFormRequest } from '@hooks/use-api-request'
import { getReservations } from '@store/actions/reservations-action'
import { useDispatch, useSelector } from 'react-redux'
import { reservationsSelector } from '@store/slices/reservations-slice'
import { Reservation, ReservationStatus } from '@models/reservation'
import { commonObjectPost } from '@api/basic-requests'
import { PackageVip } from '@models/client'
import { setClientDetails } from '@store/actions/client-actions'

interface Props {
  handleClose: () => void
  packageVip: PackageVip
}

export const PackageVipAssignmentModal = ({ handleClose, packageVip }: Props): JSX.Element => {
  const dispatch = useDispatch()
  const reservations = useSelector(reservationsSelector)

  const filteredReservations = React.useMemo(
    () =>
      reservations.filter((reservation: Reservation) =>
        [ReservationStatus.initial, ReservationStatus.confirmed].includes(reservation.status),
      ),
    [reservations],
  )

  const methods = useForm({
    defaultValues: {
      booking: {
        label: `Rezerwacja nr. ${filteredReservations[0]?.reservation_number}`,
        value: filteredReservations[0]?.id,
      },
    },
  })

  const { action: addPackage, isLoading } = useFormRequest(
    async () => {
      dispatch(
        setClientDetails(
          await commonObjectPost(packageVip.urls.assignment, { booking: methods.getValues('booking').value }),
        ),
      )
      handleClose()
    },
    methods.setError,
    { nonFieldErrorsAs: 'booking' },
  )

  const { action: fetchReservations } = useApiRequest(async () => dispatch(getReservations()))

  React.useEffect(() => {
    if (!reservations.length) {
      fetchReservations()
    }
  }, [])

  const reservationOptions = filteredReservations.map((reservation: Reservation) => ({
    value: reservation.id,
    label: `Rezerwacja nr. ${reservation.reservation_number}`,
  }))

  return (
    <>
      <ModalBody className="py-4">
        <p className="fw-medium">Wybierz z listy rezerwację do której ma zostać podpięty pakiet VIP</p>

        {filteredReservations.length ? (
          <FormProvider {...methods}>
            <CustomReactSelect
              menuPlacement="auto"
              inputName="booking"
              options={reservationOptions}
              isSearchable={false}
            />
          </FormProvider>
        ) : (
          <span className="opacity-75 my-4">Niestety nie masz rezerwacji do której można podpiąć Pakiet VIP</span>
        )}
      </ModalBody>
      <ModalFooter
        withCancelButton
        withSubmit={!!reservationOptions.length}
        cancelLabel="Zamknij"
        onClose={handleClose}
        onSubmit={addPackage}
        submitLabel="Dodaj"
        submitIcon=""
        isLoading={isLoading}
        savingLabel="Dodawanie..."
      />
    </>
  )
}
