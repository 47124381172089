import * as React from 'react'
import { SpecialProductStatus, StatusVariant } from '@modules/special-products/special-product-status'
import { PackageVip } from '@models/client'
import { ReservationDetailsMobileRow } from '@modules/reservations/pending/mobile/reservation-details-mobile-row'
import { IconWithText } from '@components/icon-with-text'
import { DateFormats, formatDate } from '@helpers/date-helper'
import { useModal } from '@components/modals/use-modal'
import { ModalFooter } from '@components/modals/modal-footer'

interface Props {
  packageVip: PackageVip
}

export const PackageVipDetailsBaseInfo = ({ packageVip }: Props): JSX.Element => {
  const handleVipDetailsModalClose = () => {
    hideVipDetailsModal()
  }

  const [showVipDetailsModal, hideVipDetailsModal] = useModal('VipDetailListModal', {
    title: 'Pakiet VIP',
    packageVipType: packageVip.type,
    footer: (
      <ModalFooter withSubmit={false} cancelLabel="Zamknij" withCancelButton onClose={handleVipDetailsModalClose} />
    ),
  })

  return (
    <div className="mx-n2 border-bottom">
      <ReservationDetailsMobileRow icon="ps-4" title="Status pakietu:">
        <SpecialProductStatus variant={StatusVariant[packageVip.status]} text={packageVip.status_display} />
      </ReservationDetailsMobileRow>

      {packageVip.status === 'initial' && (
        <ReservationDetailsMobileRow
          icon="uil-plus"
          title="Co dostaję w pakiecie?"
          titleClassName="fw-bold font-size-md text-darker-gray"
          onClick={showVipDetailsModal}
        />
      )}

      {packageVip.status !== 'initial' && (
        <ReservationDetailsMobileRow icon="uil-calender" title="Kupiony:">
          <IconWithText
            text={formatDate(packageVip.created, DateFormats.MONTH_WITH_FULL_WORD_MONTH_WITH_YEAR)}
            iconClassName="uil-info-circle text-primary me-2 font-size-icon-18"
          />
        </ReservationDetailsMobileRow>
      )}

      {packageVip.booking && (
        <>
          <ReservationDetailsMobileRow icon="uil-check" title="Wykorzystany:">
            <span>{formatDate(packageVip.created)}</span>
          </ReservationDetailsMobileRow>
          <ReservationDetailsMobileRow icon="uil-location-point" title="Ośrodek:">
            <strong>{packageVip.booking.resort}</strong>
          </ReservationDetailsMobileRow>
          <ReservationDetailsMobileRow icon="uil-bookmark" title="Numer rezerwacji:">
            <strong>{packageVip.booking.reservation_number}</strong>
          </ReservationDetailsMobileRow>
        </>
      )}
    </div>
  )
}
