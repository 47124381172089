import * as React from 'react'
import { useParams } from 'react-router-dom'
import { CashbackVoucher } from '@models/client'
import { SpecialProductDetailsHeader } from '@modules/special-products/special-product-details-header'
import { selectClientDetails } from '@store/selectors/client-selectors'
import { ContentLoader } from '@components/loaders/content-loader'
import { useGetCashbackVouchersQuery, useLazyGetCashbackVoucherDetailsQuery } from '@api/cashback-vouchers'
import { useAppSelector } from '@store/index'
import { CashbackVoucherDetailsBaseInfo } from '@modules/special-products/cashback-vouchers/details/cashback-voucher-details-base-info'
import { CashbackVoucherInGenerationDetails } from '@modules/special-products/cashback-vouchers/details/cashback-voucher-in-generation-details'
import { CashbackVoucherExpandableHeader } from '@modules/special-products/cashback-vouchers/expandable-row-header/cashback-voucher-expandable-header'

interface Params {
  id: string
}

export const CashbackVoucherDetailsView: React.FC = () => {
  const { id } = useParams<Params>()
  const { urls } = useAppSelector(selectClientDetails)

  const { data: cashbackVouchers = [] } = useGetCashbackVouchersQuery(urls.cashback_vouchers)
  const [fetchDetails, { data: voucherDetails, isLoading }] = useLazyGetCashbackVoucherDetailsQuery()

  React.useEffect(() => {
    const cashbackVoucher = cashbackVouchers.find((voucher: CashbackVoucher) => voucher.id === parseInt(id, 10))
    if (!cashbackVoucher) return

    fetchDetails(cashbackVoucher.urls.details)
  }, [cashbackVouchers])

  return (
    <ContentLoader isLoading={isLoading}>
      {voucherDetails && (
        <>
          <SpecialProductDetailsHeader
            title="Twoje vouchery rePLAY!"
            productName="Voucher rePLAY!"
            productNumber={voucherDetails.number}
          />
          <CashbackVoucherDetailsBaseInfo voucher={voucherDetails} />

          {voucherDetails.status === 'generate' ? (
            <CashbackVoucherInGenerationDetails voucherDetails={voucherDetails} />
          ) : (
            <div className="border rounded mt-5 bg-light-blue">
              <CashbackVoucherExpandableHeader voucher={voucherDetails} withStatus={false} />
            </div>
          )}
        </>
      )}
    </ContentLoader>
  )
}
