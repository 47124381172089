import * as React from 'react'
import * as clsx from 'clsx'
import { AccountCreate } from '@modules/account/account-creation/account-create'
import { AccountSetupLayout } from '@modules/account/account-setup-layout'
import { useHistory } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { DocumentMeta } from '@components/document-meta'

export const AccountCreationView = (): JSX.Element => {
  const history = useHistory()

  const handleBackToLogin = () => {
    history.push(NavigationPath.Login)
  }

  return (
    <>
      <DocumentMeta title="Utwórz konto gościa — Holiday Park & Resort" />
      <AccountSetupLayout>
        <div className={clsx('authorization-layout__content-container is-account-creation')}>
          <AccountCreate onBackToLogin={handleBackToLogin} />
        </div>
      </AccountSetupLayout>
    </>
  )
}
