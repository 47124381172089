import * as React from 'react'
import { useParams } from 'react-router-dom'
import { ContentLoader } from '@components/loaders/content-loader'
import declination from '@helpers/declination'
import { useGetCouponItemsQuery } from '@api/coupons'
import { TypedQueryResult } from '@api/base'
import { CouponItem } from '@models/coupons'
import { QrCodeBox } from '@components/qr-code-box'
import { DateFormats, formatDate } from '@helpers/date-helper'
import { IconWithText } from '@components/icon-with-text'

interface Params {
  id: string
}

export const CouponDetailsView = (): JSX.Element => {
  const { id } = useParams<Params>()
  const { data: coupons = [], isLoading } = useGetCouponItemsQuery() as TypedQueryResult<CouponItem[]>

  const coupon = coupons.find(coupon => coupon.id === parseInt(id, 10)) as CouponItem

  return (
    <ContentLoader isLoading={isLoading}>
      {coupon && (
        <div className="coupons-view__wrapper text-white px-3">
          <div>
            <h1 className="text-center font-size-23 fw-bold mt-5">
              Twój kupon <br />
              do {coupon.coupon_type_configuration.point_of_sale === 'bistro' ? 'bistro' : 'kawiarni'} <br />
              umożliwiający <br />
              zakupy na kwotę
            </h1>
            <strong className="coupons-view__details__amount">{parseInt(coupon.value, 10)}</strong>
            <span className="text-center font-size-23 d-block lh-1">{declination.pln(coupon.value)}</span>
          </div>

          <div className="flex-1 align-items-center d-flex flex-column">
            <p className="text-center mt-4 fw-light mb-2">
              Wykorzystaj do {formatDate(coupon.expiration_date, DateFormats.MONTH_WITH_FULL_WORD_MONTH_WITH_YEAR)}
            </p>
            <QrCodeBox text={coupon.code} />
          </div>

          <div className="coupons-view__details__code-box">
            {coupon.use_date ? (
              <IconWithText
                text="Kupon wykorzystany"
                iconClassName="uil-check font-size-3xl me-2"
                className="text-success"
                textClassName="font-size-23 font-weight-semibold"
              />
            ) : (
              <>
                <span className="text-darker-gray font-size-xxl">Kupon:</span>
                <strong className="font-size-33 text-primary ms-3">{coupon.code}</strong>
              </>
            )}
          </div>
        </div>
      )}
    </ContentLoader>
  )
}
