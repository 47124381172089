import * as React from 'react'
import { LabeledRow } from '@components/labeled-row'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { Button, Col, FormLabel, Row } from 'react-bootstrap'
import { CustomReactSelect } from '@components/custom-react-select'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import { useFieldIsSavingContext } from '@hooks/use-field-is-saving'
import { useSelector } from 'react-redux'
import { RootState } from '@store/index'
import { IconSolo } from '@components/icon-solo'

export const PersonalData = (): JSX.Element => {
  const countries = useSelector((state: RootState) => state.appDataState.appData.countries)
  const languages = useSelector((state: RootState) => state.appDataState.appData.languages)
  const [handleChangePasswordModal] = useModal('ChangePasswordModal', { title: 'Zmień hasło do konta' })
  const isFieldSaving = useFieldIsSavingContext()
  const guest = useSelector((state: RootState) => state.clientState.details)

  return (
    <>
      <LabeledRow
        className="align-items-center"
        label={<div className="text-primary fw-bold font-size-20">Dane osobowe</div>}
      >
        <div className="font-size-12 fw-light text-darker-gray text-center text-xl-start">
          Sprawdź czy poniższe dane są aktualne
        </div>
      </LabeledRow>
      <LabeledRow hideLabelOnWrap className="align-items-center mt-2 mt-xl-0" label="Imię i nazwisko:">
        <Row className="gx-2 mt-2">
          <Col xl={6} xxl={4}>
            <CustomFormGroup
              label="Imię"
              inputName="first_name"
              formControlProps={{ type: 'text' }}
              formGroupProps={{ className: 'mt-0' }}
              isLoading={isFieldSaving('first_name')}
              isSucceed={!!guest.first_name}
            />
          </Col>
          <Col xl={6} xxl={4}>
            <CustomFormGroup
              label="Nazwisko"
              inputName="last_name"
              formControlProps={{ type: 'text' }}
              formGroupProps={{ className: 'mt-0' }}
              isLoading={isFieldSaving('last_name')}
              isSucceed={!!guest.last_name}
            />
          </Col>
        </Row>
      </LabeledRow>
      <LabeledRow hideLabelOnWrap className="align-items-center mt-2 mt-xl-0" label="Numer telefonu:">
        <Row className="gx-2">
          <Col xl={12} xxl={8}>
            <CustomFormGroup
              label="Numer telefonu:"
              inputName="phone"
              formControlProps={{ placeholder: '(+48) 123-456-789' }}
              isLoading={isFieldSaving('phone')}
              isSucceed={!!guest.phone}
            />
          </Col>
        </Row>
      </LabeledRow>
      <LabeledRow hideLabelOnWrap className="align-items-center my-3" label="Adres e-mail:">
        <FormLabel className="text-muted font-size-sm mb-0 mt-2 d-flex">Adres e-mail</FormLabel>
        <div className="d-flex justify-content-between align-items-center col-8">
          <div className="font-size-12 fw-bold">{guest.email}</div>
          <IconSolo iconClassName="uil-check font-size-xxl text-success me-2 pe-2" />
        </div>
      </LabeledRow>
      <LabeledRow hideLabelOnWrap label={<div className="mt-4">Adres zamieszkania:</div>}>
        <Row className="gx-2">
          <Col xl={12} xxl={8}>
            <CustomFormGroup
              label="Adres zamieszkania:"
              inputName="street"
              formControlProps={{ type: 'text', placeholder: 'Wpisz ulicę i numer lokalu' }}
              isLoading={isFieldSaving('street')}
              isSucceed={!!guest.profile.street}
            />
          </Col>
        </Row>
        <Row className="gx-2">
          <Col xs={4} xxl={3}>
            <CustomFormGroup
              inputName="postcode"
              formControlProps={{ placeholder: '33-333' }}
              isLoading={isFieldSaving('postcode')}
              isSucceed={!!guest.profile.postcode}
            />
          </Col>
          <Col xs={8} xl={8} xxl={5}>
            <CustomFormGroup
              inputName="city"
              formControlProps={{ type: 'text', placeholder: 'Miejscowość' }}
              isLoading={isFieldSaving('city')}
              isSucceed={!!guest.profile.city}
            />
          </Col>
        </Row>
        <Row className="gx-2">
          <Col xxl={5}>
            <CustomReactSelect inputName="country" className="mt-2" options={countries} />
          </Col>
        </Row>
      </LabeledRow>
      <LabeledRow hideLabelOnWrap className="align-items-center mt-2 mt-xl-0" label="Język korespondencji:">
        <Row className="gx-2">
          <Col xxl={5} className="me-3">
            <FormLabel className="text-muted font-size-sm mb-0 mt-2 d-flex">Język korespondencji</FormLabel>
            <CustomReactSelect isSearchable={false} inputName="language" className="mt-0 mt-xl-2" options={languages} />
          </Col>
        </Row>
      </LabeledRow>
      <LabeledRow hideLabelOnWrap label="Hasło do konta:" className="align-items-center">
        <Button
          className="form-control-height-lg bg-dark-gray-gradient d-flex align-items-center justify-content-center text-white border-0 mt-2 px-5 responsiveness-button"
          onClick={handleChangePasswordModal}
        >
          <IconWithText
            className="align-items-center font-size-14 fw-bold"
            text="Zmień hasło"
            iconClassName="uil-edit-alt me-2 font-size-icon-22"
          />
        </Button>
      </LabeledRow>
      <Row className="d-xl-none mt-2 mt-xl-0">
        <Col>
          <a href="/change-pin/" className="text-muted text-decoration-none">
            <IconWithText
              className="text-muted fw-bold font-size-xs align-items-center mt-2 justify-content-center justify-content-xl-start"
              text="Zmień PIN"
              iconClassName="uil-dialpad me-1 font-size-xl"
            />
          </a>
        </Col>
      </Row>
    </>
  )
}
