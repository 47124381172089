import * as React from 'react'
import * as clsx from 'clsx'
import { formatPrice } from '@helpers/price-helper'
import { CashbackVoucher } from '@models/client'
import { CashbackVoucherExpandableBaseHeader } from '@modules/special-products/cashback-vouchers/expandable-row-header/cashback-voucher-expandable-base-header'
import { useRedirectToReservation } from '@modules/special-products/gastro-vouchers/use-redirect-to-reservation'

interface Props {
  voucher: CashbackVoucher
  withStatus?: boolean
  withReservationLink?: boolean
}

export const CashbackVoucherInGenerationExpandableHeader = ({
  voucher,
  withStatus,
  withReservationLink = true,
}: Props): JSX.Element => {
  const { redirectToReservation } = useRedirectToReservation()

  const handleMoveToReservationDetails = (event: React.MouseEvent) => {
    event.preventDefault()
    redirectToReservation({ reservation_number: voucher.source_booking })
  }

  const VoucherAmount = ({ voucher, className }: { voucher: CashbackVoucher; className: string }) => (
    <span className={clsx('font-size-sm align-items-end text-nowrap', className)}>
      Zebrane środki: <strong className="ms-3 me-1">{formatPrice(voucher.promotion_amount)}</strong>
    </span>
  )

  return (
    <>
      <CashbackVoucherExpandableBaseHeader
        voucher={voucher}
        progressValue={75}
        progressColor="success"
        voucherAmount={VoucherAmount}
        withStatus={withStatus}
      />
      <div className="fw-normal font-size-sm pb-3 ps-4 d-lg-block d-none">
        Voucher rePLAY! generuje się podczas Twojego pobytu w ramach rezerwacji{' '}
        <strong>{voucher.source_booking}</strong>
        {withReservationLink && (
          <i
            className="uil-external-link-alt ms-2 font-size-md cursor-pointer"
            onClick={handleMoveToReservationDetails}
          />
        )}
      </div>
    </>
  )
}
