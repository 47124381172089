import * as React from 'react'
import { Error500 } from '@modules/errors/error-500'
import { Error403 } from '@modules/errors/error-403'

export type LoadFailureCode = '403' | '500'

interface Props {
  loadFailureCode: LoadFailureCode
}

export const ErrorPageView = ({ loadFailureCode }: Props): JSX.Element => codeComponent[loadFailureCode]

const codeComponent = {
  500: <Error500 />,
  403: <Error403 />,
}
