import * as React from 'react'

interface Props {
  progress: number
  className?: string
}
export const CircleProgress = ({ progress, className }: Props): JSX.Element => (
  <div className={className}>
    <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
      <circle
        className="circle-progress__tray"
        cx="100"
        cy="100"
        r="93"
        fill="transparent"
        stroke="#97979733"
        strokeLinecap="round"
        strokeWidth="14"
      />
      <circle
        className="circle-progress__stroke"
        cx="100"
        cy="100"
        r="93"
        fill="transparent"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="14"
        strokeDasharray="600"
        strokeDashoffset={`calc(600 - 600 * ${progress})`}
        transform="rotate(-90 100 100)"
      />
    </svg>
  </div>
)
