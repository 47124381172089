import * as React from 'react'
import { Ref } from 'react'

interface Props {
  title: string
  children: React.ReactNode
}

export const ScrollableSection = React.forwardRef(
  ({ title, children }: Props, ref: Ref<HTMLDivElement>): JSX.Element => (
    <div ref={ref} className="mb-5">
      <h4 className="fw-bold text-start mb-3 scrollable-section__title">{title}</h4>
      {children}
    </div>
  ),
)
