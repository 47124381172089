import * as React from 'react'
import * as clsx from 'clsx'

type AlertVariant = 'info'

interface Props {
  variant: AlertVariant
  children: React.ReactNode
  className?: string
  iconClassName?: string
  showIcon?: boolean
}

export const Alert = ({ children, variant, className, iconClassName, showIcon = true }: Props): JSX.Element => (
  <div role="alert" className={clsx(className, `custom-alert custom-alert__${variant}`)}>
    {showIcon && <i className={clsx('d-block', iconClassName, ALERT_ICON[variant])} />}
    {children}
  </div>
)

const ALERT_ICON: Record<AlertVariant, string> = {
  info: 'uil-info-circle custom-alert__info-icon',
}
