import * as React from 'react'
import { differenceInDays } from 'date-fns'
import { formatDate, parseISODate, toDefaultDateFormat } from '@helpers/date-helper'
import declination from '@helpers/declination'
import { Reservation } from '@models/reservation'

interface Props {
  reservation: Reservation
}

export const ClosedReservationPeriod = ({ reservation }: Props): JSX.Element => {
  const daysDiff = differenceInDays(
    parseISODate(reservation.date_to) as Date,
    parseISODate(reservation.date_from) as Date,
  )
  return (
    <span>
      <span className="me-2 text-nowrap">
        <span>{formatDate(reservation.date_from, 'dd.MM')}</span> -{' '}
        <span>{toDefaultDateFormat(reservation.date_to)}</span>
      </span>
      <span className="text-nowrap fw-light">
        ({daysDiff} {declination.stayDays(daysDiff)})
      </span>
    </span>
  )
}
