import { IconWithText } from '@components/icon-with-text'
import { ClientProfileGuest } from '@models/client'
import { Guest } from '@models/reservation'
import { RootState } from '@store/index'
import * as React from 'react'
import { useSelector } from 'react-redux'

interface Props {
  onAdd: (guest: ClientProfileGuest) => void
  addedGuests: Guest[]
  error: string | null
}
export const ReservationDataCompletionAddGuestList = ({ onAdd, addedGuests, error }: Props): JSX.Element => {
  const guests = useSelector((state: RootState) => state.clientState.details.profile.guests)
  const filteredGuests = React.useMemo(
    () =>
      guests.filter(
        ({ name, birthday }) =>
          !addedGuests.some(addedGuest => addedGuest.name === name.trim() && birthday === addedGuest.birthday),
      ),
    [addedGuests],
  )

  return (
    <div className="border-top bg-light-blue body-padding py-3">
      <label className="text-primary font-size-xl fw-bold">Dodaj osobę towarzyszącą z listy poniżej:</label>
      <div className="overflow-y-auto" style={{ maxHeight: '13rem' }}>
        {filteredGuests.map(guest => (
          <div key={guest.id} className="d-flex align-items-center justify-content-between pt-2">
            <div className="d-flex align-items-center">
              <div className="text-primary fw-bold pe-2">{guest.name}</div>
              <div className="font-size-sm">{guest.type_display}</div>
            </div>
            <IconWithText
              className="text-darker-gray pe-3"
              text="Dodaj"
              iconClassName="font-size-xxl uil-plus me-2"
              textClassName="fw-bold"
              onClick={() => onAdd(guest)}
            />
          </div>
        ))}
      </div>
      {error && <div className="text-danger font-size-sm">{error}</div>}
    </div>
  )
}
