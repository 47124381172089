import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { NavigationPath } from '@models/routes'
import { logOutUser } from '@store/actions/auth-actions'

export const useLogout = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  return async () => {
    await dispatch(logOutUser())
    history.push(NavigationPath.Login)
  }
}
