import * as React from 'react'
import * as clsx from 'clsx'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  text: string | JSX.Element
  className?: string
  iconClassName?: string
  textClassName?: string
  onIconClick?: (event?: React.MouseEvent<HTMLElement>) => void
}

export const IconWithText = ({
  text,
  textClassName,
  iconClassName,
  className,
  onIconClick,
  ...props
}: Props): JSX.Element => (
  <div
    className={clsx('icon-with-text d-flex align-items-center', { 'cursor-pointer': !!props.onClick }, className)}
    {...props}
  >
    <i
      className={clsx('icon-with-text__icon', { 'cursor-pointer': !!onIconClick }, iconClassName)}
      onClick={onIconClick}
    />
    <span className={clsx('icon-with-text__text', textClassName)}>{text}</span>
  </div>
)
