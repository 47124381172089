import * as React from 'react'
import { CircleOutlineRadioButton } from '@components/controls/circle-outline-radio-button'

interface Props {
  name: string
}

export const DataProcessingActions = ({ name }: Props): JSX.Element => (
  <div className="row px-3 py-2">
    <div className="col-sm-6 col-12 mb-3 mb-sm-0">
      <CircleOutlineRadioButton id={`${name}-accept`} name={name} label="Wyrażam zgodę" value="true" />
    </div>
    <div className="col-sm-6 col-12">
      <CircleOutlineRadioButton id={`${name}-reject`} name={name} label="Nie wyrażam zgody" value="false" />
    </div>
  </div>
)
