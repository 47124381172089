import * as React from 'react'
import * as clsx from 'clsx'
import { CustomTooltip } from '@components/custom-tooltip'
import { IconWithText } from '@components/icon-with-text'
import { useTimeoutWhen } from 'rooks'
import { NativeMessageTypes } from '@models/native'

interface CopiableProps {
  children: React.ReactNode
  copyText?: string
  className?: string
}

interface Response {
  copy: (text: string) => void
  Copiable: ({ children, className, copyText }: CopiableProps) => JSX.Element
}

export const useCopy = (): Response => {
  const [isTooltipVisible, setIsTooltipVisible] = React.useState(false)

  const hideTooltip = () => setIsTooltipVisible(false)

  const copy = async (text: string) => {
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(text)
      }
    } catch (e) {
      console.error('Copy functionality is unsupported.')
    }

    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(JSON.stringify({ type: NativeMessageTypes.COPY, payload: text }))
    }

    setIsTooltipVisible(true)
  }

  useTimeoutWhen(hideTooltip, 1500, isTooltipVisible)

  const Copiable = ({ children, copyText = 'Link skopiowany', className = '' }) => (
    <CustomTooltip
      isShowed={isTooltipVisible}
      onClose={hideTooltip}
      tooltipClassName={clsx('no-pointer is-right', className)}
      content={
        <IconWithText
          className="px-3 py-2"
          text={copyText}
          textClassName="text-nowrap fw-bold"
          iconClassName="uil-check-circle font-size-xxl me-2 lh-1"
        />
      }
    >
      {children}
    </CustomTooltip>
  )

  return { copy, Copiable }
}
