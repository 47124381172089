import { CustomReactSelect } from '@components/custom-react-select'
import { CustomFormDatePicker } from '@components/form-controls/custom-form-date-picker'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { formatISO, subYears } from 'date-fns'
import * as React from 'react'
import { Col, FormLabel, Row } from 'react-bootstrap'
import { ClientProfileGuestModalFormInputs, defaultWalletScopes, guestTypeOptions } from '@modules/user/guests/modal'
import { useFormContext, useWatch } from 'react-hook-form'
import { useDidUpdate } from 'rooks'

export const ClientProfileGuestForm = (): JSX.Element => {
  const { control, setValue } = useFormContext<ClientProfileGuestModalFormInputs>()

  const guestType = useWatch({ control, name: 'type' })

  useDidUpdate(() => {
    setValue('wallet_limits', defaultWalletScopes[guestType.value])
  }, [guestType])

  return (
    <Row>
      <Col md={6} sm={12}>
        <CustomFormGroup label="Imię i nazwisko" inputName="name" />
      </Col>
      <Col md={6} sm={12}>
        <div className="mt-2">
          <FormLabel className="text-darker-gray mb-0 d-flex">Wiek</FormLabel>
          <CustomReactSelect inputName="type" options={guestTypeOptions} aria-label="Wiek" />
        </div>
      </Col>
      {guestType.value === 'adult' ? (
        <>
          <Col md={6} sm={12}>
            <CustomFormGroup label="Adres e-mail" inputName="email" formControlProps={{ type: 'email' }} />
          </Col>
          <Col md={6} sm={12}>
            <CustomFormGroup
              label="Numer telefonu"
              inputName="phone"
              formatOptions={{
                format: '(+48) ###-###-###',
                allowEmptyFormatting: true,
              }}
            />
          </Col>
        </>
      ) : (
        <Col md={6} sm={12}>
          <CustomFormDatePicker
            label="Data urodzenia"
            inputName="birthday"
            max={formatISO(new Date(), { representation: 'date' })}
            min={formatISO(subYears(new Date(), 120), { representation: 'date' })}
          />
        </Col>
      )}
    </Row>
  )
}
