import * as React from 'react'
import { Button } from 'react-bootstrap'
import { IconWithText } from '@components/icon-with-text'
import { useSelector } from 'react-redux'
import { selectAppData } from '@store/selectors/app-data-selectors'
import { useLink } from '@hooks/use-link'

export const NoUpcomingReservations = (): JSX.Element => {
  const { urls } = useSelector(selectAppData)
  const { openLink } = useLink()

  const handleOpenLink = () => {
    openLink(urls.other.reservation_url)
  }

  return (
    <div className="d-flex flex-column align-items-center mx-auto px-sm-5 px-2">
      <div className="text-primary font-size-20 fw-bold text-center mt-4">
        Wygląda na to, że nie masz <br /> żadnych nadchodzących rezerwacji
      </div>
      <div className="fw-light font-size-12 text-center mt-1">
        W Holiday Park & Resort wakacje trwają cały rok. <br />
        Nie czekaj i dodaj nową rezerwację, zanim wszystko będzie zajęte.
      </div>
      <Button variant="danger" className="mt-4 mb-5 px-sm-5 px-2 py-2" onClick={handleOpenLink}>
        <IconWithText
          text="Dodaj nową rezerwację"
          iconClassName="uil-plus font-size-icon-18 me-2 lh-inherit"
          className="font-size-14 fw-bold px-4"
        />
      </Button>
    </div>
  )
}
