import { getCompanyDataFromGus } from '@api/invoice'
import { CompanyDataFromGus } from '@models/invoice'
import { RootState } from '@store/index'
import { AxiosError } from 'axios'
import { ErrorOption } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { useApiRequest } from './use-api-request'

type SetCompanyDataCallback = (companyData: CompanyDataFromGus) => void
type OnErrorCallback = (error: ErrorOption) => void
type UseGusCompanyDataHookReturn = {
  isLoading: boolean
  handleGus: () => void
}
type UseGusCompanyDataHook = (
  setCompanyData: SetCompanyDataCallback,
  getNip: string,
  onError?: OnErrorCallback,
) => UseGusCompanyDataHookReturn
export const useGusCompanyData: UseGusCompanyDataHook = (setCompanyData, nip, onError) => {
  const state = useSelector<RootState, RootState>(state => state)
  const { isLoading, action: handleGus } = useApiRequest(
    async () => {
      setCompanyData(await getCompanyDataFromGus(state, nip))
    },
    (error: AxiosError<any>) => {
      if (error.response?.status === 400) {
        onError?.({ type: 'server', message: Object.values<any>(error.response.data)[0][0] })
      } else {
        onError?.({ type: 'server', message: 'Błąd serwera' })
      }
    },
  )

  return { isLoading, handleGus }
}
