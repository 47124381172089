import { TimeRemainingCounter } from '@components/time-remaining-counter'
import * as clsx from 'clsx'
import * as React from 'react'
import { Reservation } from '@models/reservation'
import { isPendingReservation } from '@helpers/reservation'
import { createCombinedDateWithTime } from '@helpers/date-helper'

interface Props {
  headerHeight: number
  isOpen: boolean
  reservation: Reservation
}

export const ReservationTimeCounter = ({ headerHeight, isOpen, reservation }: Props): JSX.Element => {
  const counterEndDate = isPendingReservation(reservation) ? reservation.date_from : reservation.date_to
  const counterEndTime = isPendingReservation(reservation) ? reservation.arrival_time : reservation.departure_time

  const counterText = isPendingReservation(reservation)
    ? 'Do rozpoczęcia pobytu pozostało:'
    : 'Do końca pobytu pozostało:'

  const end = createCombinedDateWithTime(counterEndDate, counterEndTime)
  const start = Date.now()

  const style = isOpen
    ? undefined
    : {
        marginTop: headerHeight / 2,
        transform: 'translate(0, -50%)',
      }
  const size = isOpen ? 'lg' : 'sm'

  return (
    <div
      className={clsx('pending-reservation__time-counter px-3 d-flex', isOpen && 'flex-column align-items-center')}
      style={style}
    >
      {isOpen && <div className="text-darker-gray font-size-11 text-center mt-3 mb-3">{counterText}</div>}
      <TimeRemainingCounter end={end} start={start} size={size} pending={isPendingReservation(reservation)} />
    </div>
  )
}
