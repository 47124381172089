import * as React from 'react'
import { formatPrice } from '@helpers/price-helper'
import { toDefaultDateFormat, toDefaultTimeFormat } from '@helpers/date-helper'
import { PurchaseItem } from '@models/reservation'

export interface PurchasePaymentHistoryRow {
  row: PurchaseItem
  index: number
  isReservationNumberShowed?: boolean
}

export const PurchaseHistoryTableRow = ({
  row,
  index,
  isReservationNumberShowed = true,
}: PurchasePaymentHistoryRow): JSX.Element => (
  <>
    <td className="reservation-payment-history__cell--first-column vertical-align-middle text-center ps-4">
      <strong>{index}.</strong>
    </td>
    <td className="reservation-payment-history__cell vertical-align-middle">
      <strong>{toDefaultDateFormat(row.created_at)}</strong> ({toDefaultTimeFormat(row.created_at)})
      <span className="d-block">{row.source_display}</span>
    </td>
    <td className="reservation-payment-history__cell--name vertical-align-middle">
      <strong className="d-block">{row.name}</strong>({formatPrice(row.single_price_brutto)})
    </td>
    <td className="reservation-payment-history__cell--quantity vertical-align-middle text-end text-nowrap">
      x{row.quantity}
    </td>
    <td className="reservation-payment-history__cell vertical-align-middle text-end">
      <strong>{formatPrice(row.price_brutto)}</strong>
    </td>
    <td className="reservation-payment-history__cell text-end pe-4 vertical-align-middle">
      <strong>{row.guest_name}</strong>
      {isReservationNumberShowed && (
        <span className="d-block font-size-sm">
          <span className="text-nowrap">Rezerwacja nr.:</span> {row.reservation_number}
        </span>
      )}
    </td>
  </>
)
