import { ExpandableRowCard } from '@components/expandable-row-card'
import { Reservation, ReservationDetails } from '@models/reservation'
import * as React from 'react'
import { ReservationBasicInfo } from '@modules/reservations/basic-info'
import { ReservationTimeCounter } from '@modules/reservations/time-counter'
import { useApiRequest } from '@hooks/use-api-request'
import { useDeviceSize } from '@hooks/use-device-size'
import { NavigationPath } from '@models/routes'
import { useHistory } from 'react-router'
import { ContentLoader } from '@components/loaders/content-loader'
import { ReservationRowContent } from '@modules/reservations/reservation-row-content'
import { ReservationRowFooter } from '@modules/reservations/reservation-row-footer'
import { commonObjectGet } from '@api/basic-requests'
import { useForwardProfileRulesToReservation } from '@hooks/use-forward-profile-rules-to-reservation'
import { useDispatch, useSelector } from 'react-redux'
import { setReservationDetails } from '@store/slices/reservations-slice'
import { selectExpandedReservation, setExpanded } from '@store/slices/expanded-reservation-slice'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import { useDebouncedValue } from 'rooks'
import { selectResortDetails } from '@store/selectors/reservations-selectors'

interface Props {
  reservation: Reservation
  children?: (isLoading: boolean) => React.ReactNode
}

export const ReservationRow = ({ reservation, children }: Props): JSX.Element => {
  const reservationDetails = useSelector(selectReservationDetails)
  const resortDetails = useSelector(selectResortDetails)

  const dispatch = useDispatch()
  const history = useHistory()

  const { isDesktop } = useDeviceSize()
  const { forwardRules } = useForwardProfileRulesToReservation()

  const expandedReservation = useSelector(selectExpandedReservation)
  const isOpen = expandedReservation === reservation?.token

  const { isLoading, action: fetchDetails } = useApiRequest(async () => {
    dispatch(setReservationDetails(undefined))
    const reservationDetails = await commonObjectGet<ReservationDetails>(reservation.urls.details)
    dispatch(setReservationDetails(reservationDetails))
    forwardRules(reservationDetails)
  })

  const handleExpand = () => {
    dispatch(setExpanded(isOpen ? null : reservation.token))
  }

  const handleOpen = React.useCallback(() => {
    window.history.replaceState(null, '', window.location.pathname)

    if (!reservationDetails || reservationDetails.token !== reservation.token) {
      fetchDetails()
    }

    isDesktop ? handleExpand() : history.push(NavigationPath.ReservationDetails.replaceToken(reservation.token))
  }, [reservationDetails, fetchDetails])

  const [isTotallyLoaded] =
    useDebouncedValue(
      (!isLoading && !isDesktop) ||
        (!isLoading && isDesktop && !!reservationDetails && reservationDetails.resort_id === resortDetails?.id),
      100,
    ) || false

  const handleRowClick = () => {
    if (isDesktop) return
    history.push(NavigationPath.ReservationDetails.replaceToken(reservation.token))
  }

  return (
    <div onClick={handleRowClick}>
      <ExpandableRowCard
        isOpen={isOpen}
        onOpen={handleOpen}
        header={<ReservationBasicInfo reservation={reservation} />}
        aside={({ headerHeight }) => (
          <ReservationTimeCounter isOpen={isOpen} reservation={reservation} headerHeight={headerHeight} />
        )}
        footer={
          reservationDetails && isTotallyLoaded && <ReservationRowFooter reservationDetails={reservationDetails} />
        }
        data-testid="reservation-row"
      >
        <ContentLoader isLoading={!isTotallyLoaded} className="expandable-row-card__loader">
          {isTotallyLoaded && reservationDetails && <ReservationRowContent reservationDetails={reservationDetails} />}
        </ContentLoader>
      </ExpandableRowCard>
      {children?.(!!isTotallyLoaded)}
    </div>
  )
}
