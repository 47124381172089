import * as React from 'react'
import { OperationHeader } from '@modules/payment-result/header'
import { Col, Container } from 'react-bootstrap'
import { OperationResult } from '@modules/payment-result/utils'
import { useApiRequest } from '@hooks/use-api-request'
import { commonObjectGet } from '@api/basic-requests'
import { useQueryParams } from '@hooks/use-query-params'
import { ContentLoader } from '@components/loaders/content-loader'
import { PaymentRequestPayload } from '@modules/payment-result/use-payment-redirect'
import { ButtonLoading } from '@components/controls/button-loading'
import { useThrottle } from 'rooks'

const CHECKED_MESSAGE = (
  <p className="text-center">
    Nadal trwa przetwarzanie płatności, proszę czekać <br />
    lub wywołać ponownie sprawdzenie statusu.
  </p>
)

export const PaymentProcessingView = (): JSX.Element => {
  const query = useQueryParams()
  const token = query.get('token')
  const [checkedMessage, setCheckedMessage] = React.useState<React.ReactNode>(null)

  const { action: checkPaymentStatus, isLoading } = useApiRequest(async () => {
    const response = await commonObjectGet<PaymentRequestPayload>(`/api/payment/payment-requests/${token}/`)

    if (response && response.status !== 'initial') {
      window.location.href = response.status === 'success' ? response.success_url : response.error_url
    }
  })

  React.useEffect(() => {
    checkPaymentStatus()
  }, [])

  const handleCheckStatus = async () => {
    setCheckedMessage(null)
    await checkPaymentStatus()
    setCheckedMessage(CHECKED_MESSAGE)
  }

  const [throttledCheck] = useThrottle(handleCheckStatus, 1000)

  return (
    <ContentLoader>
      <Container className="d-flex justify-content-center">
        <Col lg={4} className="d-flex flex-column align-items-center mt-5 gap-3">
          <OperationHeader
            result={OperationResult.Processing}
            title="Przetwarzanie płatności"
            subtitle={
              <span>
                Twoja płatność nie została jeszcze przetworzona. <br /> Status płatności za chwilę powinien się zmienić.
              </span>
            }
          />
          <ButtonLoading
            className="my-3 px-5 py-3"
            variant="secondary"
            onClick={throttledCheck}
            isLoading={isLoading}
            loadingClassName="fw-bold"
            loadingLabel="Sprawdznie"
          >
            <strong className="text-white">Sprawdź stan płatności</strong>
          </ButtonLoading>
          <p className="font-size-sm">{checkedMessage}</p>
        </Col>
      </Container>
    </ContentLoader>
  )
}
