import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { ParkingKind, PlateInput } from '@models/reservation'

interface Props {
  plates: PlateInput[]
  onClick: () => void
  kind: ParkingKind
  wrapperClassName?: string
  disabled: boolean
}

export const ReservationDataCompletionParkingAdd = ({
  plates,
  onClick,
  kind,
  wrapperClassName,
  disabled,
}: Props): JSX.Element | null => {
  const text = {
    parking2: plates.length ? 'Dodaj kolejny parking' : 'Dodaj parking',
    parking_large: plates.length ? 'Dodaj kolejny parking dla dużych pojazdów' : 'Dodaj parking dla dużych pojazdów',
  }[kind]

  return (
    <div className={clsx('my-1', wrapperClassName)}>
      <IconWithText
        className={clsx('text-darker-gray d-inline-flex', { 'opacity-50 cursor-default': disabled })}
        onClick={disabled ? undefined : onClick}
        text={text}
        iconClassName="font-size-xxl uil-plus me-2"
        textClassName="fw-bold"
      />
    </div>
  )
}
