import { BaseUrl, PurchaseItem, Rule } from '@models/reservation'
import { AvailableRuleKeyword } from '@models/app-data'

export enum ClientProfileGuestWalletScope {
  cafe = 'cafe',
  bistro = 'bistro',
  vending_machines = 'vending_machines',
  shop = 'shop',
}

export interface ClientProfileGuestWalletLimit {
  id: number
  scope: ClientProfileGuestWalletScope
  limit: string
}

export type ClientProfileGuestType = 'children_5_12' | 'child' | 'adult' | 'baby' | 'children_3_4'

export interface ClientProfileGuest {
  birthday: string
  urls: BaseUrl
  email: string
  name: string
  id: number
  phone: string
  rodo_email: boolean
  type: ClientProfileGuestType
  type_display: string
  rodo_email_accepted_datetime: string
  rodo_phone: boolean
  rodo_phone_accepted_datetime: string
  wallet_limits: ClientProfileGuestWalletLimit[]
}

export interface ClientDetailsUrls {
  account_number_update: string
  client_wallet_items: string
  guests: string
  reservations: string
  update: string
  delete: string
  wallet_return: string
  wallet_top_up: string
  rules: string
  cashback_vouchers: string
}

export type ClientDetailsProfileRuleKeyword =
  | 'privacy_policy_rules'
  | 'email_rules'
  | 'sms_rules'
  | 'phone_call_rules'
  | 'services_rules'

export type InvoiceType = 'individual' | 'company'

export interface ClientDetailsProfile {
  account_number: string
  city: string
  country: string
  guests: ClientProfileGuest[]
  id: number
  invoice: boolean
  invoice_city: string
  invoice_company: string
  invoice_country: string
  invoice_nip: string
  invoice_postcode: string
  invoice_street: string
  invoice_type: InvoiceType
  notifications_arrival: boolean
  notifications_news: boolean
  notifications_promotion: boolean
  postcode: string
  rules: Rule<ClientDetailsProfileRuleKeyword>[]
  skip_service_charge: boolean
  street: string
}

interface ClientDetailsWallet {
  available_amount: string
  date: string
}

interface GastroCardUrls extends BaseUrl {
  payment: string
  rules: string
}

export interface GastroCardAssignment {
  booking: string
  created_at: string
  id: number
  is_active: boolean
}

export interface GastroCardDetails extends GastroCard {
  assignments: GastroCardAssignment[]
  items: PurchaseItem[]
}

export const AvailableGastroProductKind = [
  'gastro_coupon',
  'gastro_card_boost',
  'gastro_card_2024',
  'gastro_card_2025',
  'gastro_card_boost_wholesale',
  'gastro_card_2024_wholesale',
  'gastro_card_2025_wholesale',
] as const

export type GastroGenericProductKind = (typeof AvailableGastroProductKind)[number]

export type GastroKind = 'normal' | 'wholesale' | GastroGenericProductKind
type GastroPaymentMethods = 'online' | 'cash_card'

export interface GastroCard {
  active_after: string
  available_amount: string
  code: string
  declared_sell_price: string
  expire_after: string
  has_rules_to_accept: boolean
  id: number
  kind: GastroKind
  optional_rules: AvailableRuleKeyword[]
  payment_method: GastroPaymentMethods
  promotion_multiplier: string
  required_payment: string
  required_rules: AvailableRuleKeyword[]
  sell_date: string
  sell_price: string
  start_amount: string
  status: 'removed' | 'confirmed' | 'initial' | 'completed'
  status_display: string
  urls: GastroCardUrls
}

export interface SpecialProductBooking {
  id: number
  reservation_number: string
  created_at: string
  resort: string
}

export interface SubscriptionCode {
  bookings: SpecialProductBooking[]
  code: string
  days: number
  id: number
  is_splitted: boolean
  is_used: boolean
  localization_kind: string
  localization_kind_display: string
  expire_after: string
}

export interface SubscriptionDetails extends Subscription {
  codes: SubscriptionCode[]
  expire_after: string
  allow_change_client_data: boolean
  allow_subscription_code_localization_convert: boolean
  allowed_subscription_code_localization_convert: number
}

export interface SubscriptionUrls extends BaseUrl {
  payment: string
  rules: string
}

export type SubscriptionKind = 'gold' | 'silver' | 'brown' | 'gray'

export interface Subscription {
  accommodation_type_id: number
  allow_change_client_data: boolean
  codes_available: number
  codes_total: number
  created: string
  kind: SubscriptionKind
  expire_after: string
  id: number
  number: string
  optional_rules: AvailableRuleKeyword[]
  product_display: string
  product_id: number
  required_payment: string
  required_rules: AvailableRuleKeyword[]
  status: 'initial' | 'confirmed' | 'completed'
  status_display: string
  total_price_brutto: string
  urls: SubscriptionUrls
}

export interface PackageVipUrls extends BaseUrl {
  payment: string
  assignment: string
  rules: string
}

export type PackageVipType = 'standard' | 'winter'

export interface PackageVip {
  booking: SpecialProductBooking | null
  created: string
  expire_after: string
  id: number
  type: PackageVipType
  number: string
  optional_rules: AvailableRuleKeyword[]
  required_payment: string
  required_rules: AvailableRuleKeyword[]
  status: 'confirmed' | 'initial' | 'completed' | 'removed' | 'initial_canceled'
  status_display: string
  urls: PackageVipUrls
}

type SubscriptionContractPeriodStatus = 'unpaid' | 'paid' | 'expired'
type SubscriptionContractPeriodPaymentStatus = 'unknown' | 'ordered' | 'completed' | 'rejected'

interface SubscriptionContractPeriodUrls {
  payment: string
}

export type SubscriptionContractStatus = 'initial' | 'confirmed' | 'removed' | 'completed' | 'cancelling' | 'cancelled'

export interface SubscriptionContractPeriod {
  payment_status_display: string
  payment_status: SubscriptionContractPeriodPaymentStatus
  id: number
  payment_date: string
  status: SubscriptionContractPeriodStatus
  status_display: string
  urls: SubscriptionContractPeriodUrls
}

interface SubscriptionContractUrls extends BaseUrl {
  payment: string
  rules: string
}

export interface PaymentCard {
  card_type: string
  expiration_date: string
  id: number
  masked_number: string
}

export interface SubscriptionContractCode {
  bookings: SpecialProductBooking[]
  code: string
  expire_after: string | null
  id: number
  is_active: boolean
  is_used: boolean
  resorts: number[]
}

export interface SubscriptionContractDetails extends SubscriptionContract {
  codes: SubscriptionContractCode[]
}

export const SubscriptionContractPremiumPlans = ['plan_3', 'plan_4']
export const AvailableSubscriptionContractPlan = ['plan_1', 'plan_2', ...SubscriptionContractPremiumPlans] as const
export type SubscriptionContractPlan = (typeof AvailableSubscriptionContractPlan)[number]

export interface SubscriptionContract {
  id: number
  main_payment_card: PaymentCard | null
  number: string
  optional_rules: AvailableRuleKeyword[]
  periods: SubscriptionContractPeriod[]
  plan: SubscriptionContractPlan
  plan_display: string
  repayment_date: string | null
  repayment_day: string | null
  required_rules: AvailableRuleKeyword[]
  single_price_brutto: string
  status: SubscriptionContractStatus
  status_display: string
  subscription_started: null
  urls: SubscriptionContractUrls
}

export interface ClientDetails {
  client_wallet: ClientDetailsWallet
  email: string
  first_name: string
  id: number
  gastro_cards: GastroCard[]
  subscriptions: Subscription[]
  subscription_contracts: SubscriptionContract[]
  packages_vip: PackageVip[]
  last_name: string
  phone: string
  profile: ClientDetailsProfile
  urls: ClientDetailsUrls
  last_login: string
  language: string
}

export interface FlatClientDetails {
  city: string
  country: string
  first_name: string
  invoice: boolean
  invoice_city: string
  invoice_company: string
  invoice_country: string
  invoice_nip: string
  invoice_postcode: string
  invoice_street: string
  language: string
  last_name: string
  notifications_arrival: boolean
  notifications_news: boolean
  notifications_promotion: boolean
  phone: string
  postcode: string
  street: string
}

export type CashbackVoucherStatus = 'generate' | 'active' | 'active_assign' | 'used' | 'cancelled'

export interface CashbackVoucher {
  available_amount: string
  expire_after: string
  id: number
  number: string
  promotion_amount: string
  source_booking: string
  status: CashbackVoucherStatus
  status_display: string
  voucher: CashbackVoucherDetailsVoucher | null
  urls: BaseUrl
}

export interface CashbackVoucherDetailsWalletItem {
  id: number
  amount: string
  description: string
  booking_guest: string
  created: string
}

export interface CashbackVoucherAssignBooking {
  date_from: string
  date_to: string
  id: number
  reservation_number: string
}

export interface CashbackVoucherDetailsWallet {
  available_amount: string
  date: string
  items: CashbackVoucherDetailsWalletItem[]
}

export interface CashbackVoucherDetailsVoucher {
  id: number
  available_amount: string
}

export interface CashbackVoucherDetails extends CashbackVoucher {
  assign_booking: CashbackVoucherAssignBooking | null
  wallet: CashbackVoucherDetailsWallet
  id: number
}
