import * as React from 'react'
import * as clsx from 'clsx'
import { Reservation } from '@models/reservation'
import { useSelector } from 'react-redux'
import { selectExpandedReservation } from '@store/slices/expanded-reservation-slice'
import { useModal } from '@components/modals/use-modal'
import { useDeviceSize } from '@hooks/use-device-size'
import { useHistory } from 'react-router'
import { NavigationPath } from '@models/routes'
import { ReservationRowBanner } from '@modules/reservations/reservation-row-banner'

interface Props {
  reservation: Reservation
}

export const CheckOutBanner = ({ reservation }: Props): JSX.Element => {
  const { isDesktop } = useDeviceSize()
  const expandedReservation = useSelector(selectExpandedReservation)

  const { push } = useHistory()

  const [showCheckOutModal] = useModal('CheckOutModal', { reservation })

  const handleCheckoutClick = () => {
    isDesktop ? showCheckOutModal() : push(NavigationPath.CheckOut.replaceToken(reservation.token))
  }

  const isCollapsed = expandedReservation !== reservation.token

  return (
    <ReservationRowBanner reservation={reservation}>
      <p className="reservation-row-banner__text">
        <span className={clsx({ 'd-sm-block': !isCollapsed })}>O nie!</span> Twoje wakacje dobiegły już końca
      </p>

      <p className="font-size-sm text-muted mb-sm-4 mt-sm-2 mb-2">
        Do zobaczenia w <span className="text-nowrap">Holiday Park & Resort</span>
      </p>
      <button className="btn btn-danger reservation-row-banner__button" onClick={handleCheckoutClick}>
        Wymelduj się on-line
      </button>
    </ReservationRowBanner>
  )
}
