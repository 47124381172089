import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import { ModalFooter } from '@components/modals/modal-footer'
import { ContentLoader } from '@components/loaders/content-loader'
import { useForm, FormProvider } from 'react-hook-form'

interface Props {
  handleClose: () => void
  onSubmit: (params) => Promise<void>
  children: ({ onAgreementAccept }) => React.ReactNode
}

export const SpecialProductRulesModal = ({ handleClose, onSubmit, children }: Props): JSX.Element => {
  const [requiredAgreementsAccepted, setRequiredAgreementsAccepted] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const methods = useForm()

  const handleSubmit = async () => {
    setIsLoading(true)
    await onSubmit(methods.getValues())
  }

  return (
    <>
      <ModalBody className="modal-scrollable text-muted pb-5">
        <FormProvider {...methods}>
          <ContentLoader>{children({ onAgreementAccept: setRequiredAgreementsAccepted })}</ContentLoader>
        </FormProvider>
      </ModalBody>
      <ModalFooter
        isLoading={isLoading}
        buttonDisabled={!requiredAgreementsAccepted}
        withCancelButton
        onSubmit={handleSubmit}
        onClose={handleClose}
        submitLabel="Kontynuuj"
        savingLabel="Proszę czekać..."
      />
    </>
  )
}
