import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { Reservation } from '@models/reservation'
import { AccommodationType, Resort } from '@models/app-data'
import { useSelector } from 'react-redux'
import { useDeviceSize } from '@hooks/use-device-size'
import { TimeLeftToNow } from '@modules/reservations/close/time-left-to-now'
import { accommodationTypeSelector, resortTypeSelector } from '@store/selectors/reservations'
import { ClosedReservationPeriod } from '@modules/reservations/close/closed-reservation-period'
import { CloseReservationsListRowReceiptButton } from '@modules/reservations/close/receipt-button'
import { addDays, isBefore } from 'date-fns'
import { parseISODate } from '@helpers/date-helper'

const MAXIMUM_DAYS_RECEIPT_VISIBILITY = 21

interface Props {
  reservation: Reservation
  accommodation?: AccommodationType
  location?: Resort
}

export const CloseReservationsListRow = ({ reservation }: Props): JSX.Element => {
  const { isMobile } = useDeviceSize()

  const accommodationType = useSelector(accommodationTypeSelector)(reservation.accommodation_type_id)
  const resort = useSelector(resortTypeSelector)(reservation.resort_id)

  const accommodationComponent = (
    <span>
      {reservation.accommodation_type_name} {accommodationType?.size}m²
    </span>
  )

  const canViewReceipt = isBefore(
    new Date(),
    addDays(parseISODate(reservation.date_from) as Date, MAXIMUM_DAYS_RECEIPT_VISIBILITY + 1),
  )

  return (
    <div className="text-darker-gray p-0 ps-0 ps-md-3 mb-3 card d-flex flex-row align-items-center py-2 py-sm-0">
      <div className="col-3 col-lg-2">
        <p className="me-2 mb-0 py-md-3">
          <TimeLeftToNow dateTo={reservation.date_to} />
        </p>
      </div>
      <div
        className={clsx('d-flex gap-md-5 justify-content-between', {
          'flex-row': !isMobile,
          'flex-column': isMobile,
        })}
      >
        <IconWithText
          className="align-items-center"
          iconClassName="me-2 font-size-xl uil-calender"
          text={<ClosedReservationPeriod reservation={reservation} />}
        />
        <IconWithText
          className="align-items-center "
          iconClassName="me-2 font-size-xl uil-location-point"
          text={resort?.name}
        />
        <IconWithText
          className="align-items-center"
          iconClassName="me-2 font-size-xl uil-pathfinder-unite"
          text={accommodationComponent}
        />
      </div>
      {canViewReceipt && <CloseReservationsListRowReceiptButton reservation={reservation} />}
    </div>
  )
}
