import * as React from 'react'
import { ParkingKind, ReservationDetails, ReservationImprovement } from '@models/reservation'
import { IconWithText } from '@components/icon-with-text'
import { useDispatch, useSelector } from 'react-redux'
import { useApiRequest } from '@hooks/use-api-request'
import { isFeeding, isParking } from '@helpers/improvement-helper'
import { removeFeeding, removeImprovement } from '@api/improvements'
import { setReservationDetails } from '@store/slices/reservations-slice'
import { selectReservationDetailsUrls } from '@store/selectors/reservation-details-selectors'
import { TextWithSpinner } from '@components/text-with-spinner'
import { ModalBody } from 'react-bootstrap'
import { ItemChangeConfirmationModalContent } from '@components/item-change-confirmation-modal-content'
import { useImprovementDetailsParkingPlates } from '@modules/reservations/improvements/kinds/base-improvements/use-improvement-details-parkings'

interface Props {
  improvement: ReservationImprovement
  onEdit: () => void
  onRemove?: (reservationDetails: ReservationDetails) => void
  handleClose: () => void
}

export const ImprovementDeleteModal = ({ improvement, onEdit, handleClose, onRemove }: Props): JSX.Element => {
  const dispatch = useDispatch()

  const urls = useSelector(selectReservationDetailsUrls)
  const { getPlates } = useImprovementDetailsParkingPlates(improvement.code as ParkingKind)

  const { action: handleDelete, isLoading } = useApiRequest(async () => {
    const getReservationDetails = async () => {
      if (isFeeding(improvement) && urls?.feeding) {
        return await removeFeeding(urls?.feeding)
      }

      if (isParking(improvement)) {
        const plates = getPlates(improvement, improvement.amount, improvement.code as ParkingKind)
        const bookingCarsIds = plates?.platesFromRegisteredCars
          ? plates?.platesFromRegisteredCars.reduce(
              (previous, current) => (current.carId ? [...previous, current.carId] : previous),
              [],
            )
          : null

        return await removeImprovement(improvement.urls.details, bookingCarsIds?.length ? bookingCarsIds : null)
      }

      return await removeImprovement(improvement.urls.details)
    }

    const reservationDetails = await getReservationDetails()

    await dispatch(setReservationDetails(reservationDetails))

    onRemove?.(reservationDetails)
    handleClose()
  })

  return (
    <ModalBody className="modal-scrollable">
      <ItemChangeConfirmationModalContent
        text="Na pewno usunąć"
        underlineText={`ulepszenie ${improvement.name.toLowerCase()}?`}
      >
        <button className="btn btn-outline-secondary mt-3 px-5 py-3" onClick={onEdit}>
          <IconWithText
            text="Edytuj ulepszenie"
            iconClassName="uil-edit-alt me-2 font-size-xxl"
            className="fw-medium"
          />
        </button>
        <TextWithSpinner
          onClick={handleDelete}
          isLoading={isLoading}
          text="Usuń ulepszenie"
          loadingText="Usuwanie..."
          spinnerColor="danger"
          iconClassName="uil-trash-alt font-size-xxl me-2"
          className="text-danger mt-3 fw-medium"
        />
      </ItemChangeConfirmationModalContent>
    </ModalBody>
  )
}
