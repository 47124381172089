import { ProgressColor, ProgressWithIcon } from '@components/progress-with-icon'
import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  progress: number
  label: string | JSX.Element
  color?: ProgressColor
  className?: string
}

export const ProgressBar = ({ progress, label, color = 'navy', className }: Props): JSX.Element => (
  <div className={clsx('card bg-light-blue p-4', className)}>
    <ProgressWithIcon progress={progress} color={color} label={label} />
  </div>
)
