import * as React from 'react'

export const DataProcessingContent = (): JSX.Element => (
  <>
    <p>
      Zgodnie z art. 13 ust. 1 i 2 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016
      r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu
      takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych - Dziennik Urzędowy UE L
      119 - dalej: RODO) przyjmuję do wiadomości, że:
    </p>
    <ol style={{ listStyle: 'decimal' }}>
      <li>
        administratorem dotyczących mnie danych osobowych przetwarzanych w celach związanych z marketingiem bezpośrednim
        własnych produktów lub usług administratora danych (art. 6 ust. 1 pkt f RODO) jest Holiday Park&Resort spółka z
        ograniczoną odpowiedzialnością spółka komandytowa - siedziba: 63-100 Śrem, ul. Fryderyka Chopina 1J;
      </li>
      <li>
        dane osobowe zbierane są poprzez dobrowolne wypełnienie przez Klienta przez podanie wymaganych w formularzu
        danych w bezpośrednim kontakcie obsłudze recepcji hotelu lub za pośrednictwem stron internetowych. Podanie
        danych osobowych ma charakter dobrowolny.
      </li>
      <li>
        dane osobowe Klienta nie podlegają zautomatyzowanemu podejmowaniu decyzji przez Administratora Danych, w tym
        profilowaniu;
      </li>
      <li>
        <span>przysługują mi następujące uprawnienia:</span>
        <ul className="mt-2" style={{ listStyle: 'disc' }}>
          <li>dostępu do dotyczących mnie danych oraz otrzymania ich kopii,</li>
          <li>sprostowania (poprawiania) danych,</li>
          <li>usunięcia danych (zgodnie z uwarunkowaniami określonymi w art. 17 RODO),</li>
          <li>do ograniczenia przetwarzania danych,</li>
          <li>
            wniesienia w dowolnym momencie sprzeciwu wobec przetwarzania danych, jeżeli podstawą przetwarzania jest
            przesłanka prawnie uzasadnionego interesu Holiday Park&Resort spółka z ograniczoną odpowiedzialnością spółka
            komandytowa, w tym profilowania,
          </li>
          <li>wniesienia skargi do organu nadzorczego, o którym mowa w art. 51 RODO,</li>
          <li>
            wycofania zgody w każdym czasie, w dowolnej formie, w tym na piśmie lub e-mailem na adres
            kontakt@holidaypark.pl, względnie osobiście w siedzibie Holiday Park&Resort sp. z o.o. sp. k.. Ewentualne
            wycofanie zgody na przetwarzanie danych nie wpływa na zgodność z prawem przetwarzania, którego administrator
            danych dokonywał na podstawie zgody przed jej wycofaniem
          </li>
        </ul>
      </li>
      <li>
        przechowywanie danych przez Administratora trwało będzie do momentu wycofania tej zgody przez Klienta. Po
        odwołaniu przez Klienta zgody na przetwarzanie danych osobowych jego dane zostaną usunięte z zasobów
        informacyjnych Holiday Park&Resort z wyjątkiem danych, których obowiązek przechowywania wynika z przepisów
        szczególnych.
      </li>
      <li>
        <span>dane osobowe Klienta mogą zostać udostępnione:</span>
        <ul className="mt-2" style={{ listStyle: 'disc' }}>
          <li>
            podwykonawcom związanym z Holiday Park&Resort umowami powierzenia przetwarzania danych osobowych, np.
            obsługa kadrowa i księgowa, obsługa prawna, firmy informatyczne, agencje ochrony osób i mienia. Wykaz
            podmiotów tej kategorii dostępny jest od dnia 25 maja 2018 r. na stronie internetowej Holiday Park&Resort,
            dostępnej pod adresem https://www.holidaypark.pl w zakładce „ZASADY OCHRONY DANYCH OSOBOWYCH”.
          </li>
          <li>
            Holiday Park&Resort deklaruje, że nie przekazuje i nie zamierza przekazywać danych osobowych Klienta do
            państwa trzeciego.
          </li>
        </ul>
      </li>
      <li>
        Wszelkie zmiany w treści wyżej zamieszczonych obowiązków informacyjnych Administratora Danych Osobowych są
        dostępne na naszej stronie internetowej o adresie https://www.holidaypark.pl w zakładce „Zasady ochrony danych
        osobowych w Holiday Park&Resort” oraz w materiałach informacyjnych dostępnych w siedzibie Spółki oraz w
        recepcjach poszczególnych ośrodków wczasowych. Holiday Park&Resort spółka z ograniczoną odpowiedzialnością
        spółka komandytowa wyznaczyła Koordynatora Ochrony Danych, z którym można skontaktować się pod adresem e-mail:
        inspektor@holidaypark.pl
      </li>
    </ol>
  </>
)
