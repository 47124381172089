import * as React from 'react'
import { Button, ButtonProps } from 'react-bootstrap'
import { Spinner } from '@components/loaders/spinner'

interface ButtonLoadingProps extends ButtonProps {
  isLoading?: boolean
  loadingLabel?: string
  children: React.ReactNode
  loadingClassName?: string
}

export const ButtonLoading = ({
  isLoading,
  children,
  loadingClassName,
  loadingLabel,
  ...props
}: ButtonLoadingProps): JSX.Element => (
  <Button {...props} disabled={isLoading || props.disabled}>
    {isLoading ? (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner className="button-loading-spinner me-2" color="white" />
        <span className={loadingClassName}>{loadingLabel || 'Zapisywanie...'}</span>
      </div>
    ) : (
      children
    )}
  </Button>
)
