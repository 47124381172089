import * as React from 'react'
import { ModalFooter } from '@components/modals/modal-footer'
import { ModalBody } from 'react-bootstrap'
import { PackageVipType } from '@models/client'
import { VipDetailList, VipImprovementBenefit } from '@modules/reservations/improvements/kinds/vip/vip-detail-list'

interface Props {
  onSelection: () => void
  handleClose: () => void
  packageVipType: PackageVipType
  footer?: JSX.Element
}

export const VipDetailListModal = ({ onSelection, handleClose, footer, packageVipType }: Props): JSX.Element => (
  <>
    <ModalBody className="improvements__modal__body modal-scrollable">
      {packageVipType === 'standard' && (
        <VipDetailList
          title="Co zyskujesz kupując pakiet VIP?"
          subtitle="Kupując pakiet VIP otrzymasz:"
          improvements={vipStandardImprovements}
          footerText="* usługa dostępna wyłącznie w Mielnie - w okresie otwarcia ośrodka"
        />
      )}
      {packageVipType === 'winter' && (
        <VipDetailList
          title="Co zyskujesz kupując zimowy pakiet VIP?"
          subtitle="Kupując zimowy pakiet VIP otrzymasz:"
          improvements={vipWinterImprovements}
          footerText="* usługa dostępna wyłącznie w Mielnie i Ustroniu Morskim - w okresie otwarcia ośrodków"
        />
      )}
    </ModalBody>
    {footer ?? (
      <ModalFooter
        onSubmit={onSelection}
        onClose={handleClose}
        submitLabel="Wybieram"
        cancelLabel="Anuluj"
        withCancelButton
      />
    )}
  </>
)

const vipStandardImprovements: VipImprovementBenefit[] = [
  { title: 'Bezpłatna możliwość wybrania domku' },
  { title: 'Możliwość późniejszego wymeldowania - do godz. 12.00' },
  { title: 'Możliwość wcześniejszego zameldowania - od godz. 13.00' },
  { title: 'Grill elektryczny' },
  { title: 'Mikrofalówka' },
  { title: '4 ręczniki plażowe VIP' },
  { title: 'Zestaw ekskluzywnych kosmetyków' },
  { title: 'Wino czerwone premium' },
  { title: 'Owoce - banany, pomarańcze, kiwi, owoce sezonowe' },
  { title: 'Oznaczenie domku flagą VIP' },
  { title: 'Dziecko jako animator przez 3 godziny' },
  { title: 'Wynajem rowerów' },
  { title: 'Rabat w bistro HPR w godzinach 11:00 – 12:30' },
  { title: 'Warsztaty kulinarne dla dzieci 5x', discount: '(rabat 50%)' },
  { title: 'Okulary VR (5 godz.) *', discount: '(rabat 50%)' },
  { title: 'Krówki VIP' },
  { title: 'Opaski na rękę VIP' },
  { title: 'Pamiątkowy magnes' },
  { title: 'Ekspres do kawy z zestawem 20 kapsułek' },
  { title: 'Loża VIP', discount: '(rabat 50%)' },
  { title: 'Parking VIP po opłaceniu standardowego miejsca' },
]

const vipWinterImprovements: VipImprovementBenefit[] = [
  { title: 'Bezpłatna możliwość wyboru lokalu' },
  { title: 'Możliwość wcześniejszego zameldowania - od godz. 13.00' },
  { title: 'Możliwość późniejszego wymeldowania - do godz. 12.00' },
  { title: 'Grill elektryczny' },
  { title: 'Mikrofalówka' },
  { title: 'Ekspres do kawy z zestawem 20 kapsułek' },
  { title: 'Wynajem rowerów (10 wypożyczeń, w miarę dostępności)' },
  { title: 'Wino czerwone premium' },
  { title: 'Owoce – banany, pomarańcze, owoce sezonowe' },
  { title: 'Zestaw ekskluzywnych kosmetyków' },
  { title: 'Ręczniki VIP' },
  { title: 'Oznaczenie domku flagą VIP' },
  { title: 'Krówki VIP' },
  { title: 'Opaski na rękę VIP' },
  { title: 'Pamiątkowy magnes' },
  { title: 'Dziecko jako animator przez 3 godziny' },
  { title: 'Warsztaty kulinarne dla dzieci 5x', discount: '(rabat 50%)' },
  { title: 'Strefa VIP w kawiarni (w miarę dostępności)' },
  { title: '2 miejsca parkingowe VIP (w miarę dostępności)' },
  { title: 'Loża VIP w kawiarni (rabat 50%, raz na pobyt)' },
  { title: 'Rabat -50% w bistro HPR przez pierwsze 1,5h od otwarcia (w miarę dostępności, max. 150 zł)' },
  { title: 'Rabat -20% w sklepiku HPR (max. 300 zł)' },
  { title: 'Recepcja VIP poza standardową kolejką*' },
]
