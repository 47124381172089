import * as React from 'react'
import Select, { Props } from 'react-select'
import { Controller, useFormContext } from 'react-hook-form'
import { FormError } from './form-controls/form-error'

const selectCommonStyles = {
  control: { fontWeight: 700, color: '#9099A1', userSelect: 'none', borderColor: '#9099A1' },
  singleValue: { fontWeight: 700, color: '#9099A1' },
  indicatorContainer: { padding: 0 },
  option: {
    justifyContent: 'flex-end',
    textAlign: 'right',
    minHeight: '32px',
    color: '#576067',
    fontWeight: 700,
    fontSize: '12px',
    '& + &': {
      borderTop: '1px solid transparent',
    },
  },
}

export const selectCustomStyles = isMobile => ({
  ...selectCommonStyles,
  ...(isMobile && { control: { height: '38', ...selectCommonStyles.control } }),
})

export interface ReactSelectOption {
  value: string | number
  label: string
}

interface CustomReactSelectProps extends Props {
  inputName: string
  isSearchable?: boolean
  borderless?: boolean
  customStyles?: object
}

export const CustomReactSelect = ({
  borderless,
  inputName,
  customStyles,
  isSearchable = true,
  ...props
}: CustomReactSelectProps): React.ReactElement => {
  const {
    control,
    formState: { errors },
  } = useFormContext()
  const isError = !!errors[inputName]

  return (
    <Controller
      name={inputName}
      control={control}
      render={({ field }) => (
        <>
          <Select
            styles={selectStyles(borderless, customStyles, isError)}
            isSearchable={isSearchable}
            noOptionsMessage={noOptionMessage}
            menuPortalTarget={document.body}
            {...props}
            {...field}
          />
          <FormError inputName={inputName} className="mt-2" />
        </>
      )}
    />
  )
}

const noOptionMessage = () => 'Brak danych'

const selectStyles = (borderless, customStyles, isError) => ({
  menu: provided => ({ ...provided, zIndex: 6, left: 0, ...customStyles?.menu }),
  menuPortal: provided => ({ ...provided, zIndex: 1056 }),
  menuList: provided => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    borderRadius: '3px',
    ...customStyles?.menuList,
  }),
  control: provided => ({
    ...provided,
    fontWeight: 700,
    border: (borderless && 'none') || provided.border,
    borderColor: isError ? '#da001a' : '#9099a1',
    boxShadow: 'none',
    height: '48px',
    cursor: 'pointer',
    '&:focus': { border: 'none' },
    '&:hover': { borderColor: '#b99153' },
    ...customStyles?.control,
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
    ...customStyles?.indicatorSeparator,
  }),
  singleValue: provided => ({
    ...provided,
    fontSize: '.875rem',
    display: 'contents',
    whiteSpace: 'normal',
    ...customStyles?.singleValue,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#e9ecef' : 'white',
    color: '#6c757d',
    minHeight: '48px',
    display: 'flex',
    alignItems: 'center',
    '& + &': {
      borderTop: '1px solid #6c757d45',
    },
    cursor: 'pointer',
    '&:active': { backgroundColor: '#e9ecef' },
    '&:hover': { backgroundColor: '#e9ecef' },
    ...customStyles?.option,
  }),
  container: provided => ({
    ...provided,
    ...customStyles?.container,
  }),
  valueContainer: provided => ({
    ...provided,
    lineHeight: 'normal',
    display: 'flex',
    flexWrap: 'no-wrap',
    paddingLeft: (borderless && '0') || provided.paddingLeft,
    ...customStyles?.valueContainer,
  }),
  placeholder: provided => ({ ...provided, position: 'absolute' }),
})
