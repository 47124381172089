import * as React from 'react'
import { SpecialProductRowStatus } from '@modules/special-products/special-product-row-status'
import { StatusVariant } from '@modules/special-products/special-product-status'
import { ExpandableRowCard } from '@components/expandable-row-card'
import { GastroVoucherExpandableHeader } from '@modules/special-products/gastro-vouchers/gastro-voucher-expandable-header'
import { GastroVoucherExpandableRowContent } from '@modules/special-products/gastro-vouchers/gastro-voucher-expandable-row-content'
import { GastroCard, GastroCardDetails } from '@models/client'
import { useDeviceSize } from '@hooks/use-device-size'
import { NavigationPath } from '@models/routes'
import { useHistory } from 'react-router-dom'
import { useApiRequest } from '@hooks/use-api-request'
import { commonObjectGet } from '@api/basic-requests'

interface Props {
  voucher: GastroCard
}

export const SingleGastroVoucher = ({ voucher }: Props): JSX.Element => {
  const [voucherDetails, setVoucherDetails] = React.useState<GastroCardDetails | null>(null)

  const [isOpen, setIsOpen] = React.useState(false)
  const { isMobile, isDesktop } = useDeviceSize()

  const { action: fetchDetails } = useApiRequest(async () => {
    setVoucherDetails(await commonObjectGet(voucher.urls.details))
  })

  const history = useHistory()

  const handleToggleOpen = async () => {
    if (!isDesktop) {
      moveToGastroVoucherDetailsView()
      return
    }

    if (!voucherDetails) {
      await fetchDetails()
    }

    setIsOpen(state => !state)
  }

  const aside = !isMobile
    ? () => (
        <SpecialProductRowStatus
          title="Status vouchera:"
          productStatus={StatusVariant[voucher.status]}
          productStatusText={voucher.status_display}
        />
      )
    : undefined

  const moveToGastroVoucherDetailsView = () => {
    history.push(NavigationPath.GastroVoucherDetails.replaceId(voucher.id))
  }

  const handleCardClick = () => {
    if (!isDesktop) moveToGastroVoucherDetailsView()
  }

  return (
    <div onClick={handleCardClick} className="mb-3">
      <ExpandableRowCard
        aside={aside}
        header={<GastroVoucherExpandableHeader voucher={voucher} />}
        isOpen={isOpen}
        onOpen={handleToggleOpen}
        isExpandDisabled={voucher.status === 'initial'}
      >
        {voucherDetails && <GastroVoucherExpandableRowContent voucherDetails={voucherDetails} />}
      </ExpandableRowCard>
    </div>
  )
}
