import { RootState } from '@store/index'
import {
  BookingCar,
  Guest,
  ReservationDetails,
  ReservationDetailsUrls,
  ReservationImprovement,
} from '@models/reservation'

export const selectReservationDetails = (state: RootState): ReservationDetails | undefined =>
  state.reservationsState.reservationDetails

export const selectReservationDetailsCars = (state: RootState): BookingCar[] | undefined =>
  selectReservationDetails(state)?.cars

export const selectReservationDetailsImprovements = (state: RootState): ReservationImprovement[] | undefined =>
  selectReservationDetails(state)?.prices.improvements.items

export const selectReservationDetailsGuests = (state: RootState): Guest[] | undefined =>
  selectReservationDetails(state)?.guests

export const selectReservationDetailsUrls = (state: RootState): ReservationDetailsUrls | undefined =>
  selectReservationDetails(state)?.urls
