import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useSelector } from 'react-redux'
import { useModal } from '@components/modals/use-modal'
import { selectAccountUrls } from '@store/selectors/app-data-selectors'

export const MobileActions = (): JSX.Element => {
  const { logout_url } = useSelector(selectAccountUrls)

  const [showDeleteAccountConfirmationModal] = useModal('AccountDeleteConfirmationModal')

  return (
    <>
      <hr className="section-divider mt-5 mb-0" />
      <a
        href={logout_url}
        className="bg-transparent border-0 d-flex justify-content-center text-decoration-none"
        role="mobile-logout-button"
      >
        <IconWithText
          iconClassName="uil-sign-out-alt me-1 font-size-xl"
          className="py-4 text-darker-gray fw-bold align-items-center"
          text="Wyloguj"
        />
      </a>
      <hr className="section-divider my-0" />
      <IconWithText
        iconClassName="uil-trash-alt me-1 font-size-xl"
        className="py-4 text-danger fw-bold align-items-center mx-auto"
        text="Usuń konto"
        onClick={showDeleteAccountConfirmationModal}
      />
    </>
  )
}
