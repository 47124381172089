import * as React from 'react'
import { ReservationDetails } from '@models/reservation'
import { SectionTitle } from '@components/section-title'
import { Checkbox } from '@components/controls/checkbox'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { ArrivalTimeSelection } from '@modules/reservations/check-in/steps/arrival-time/arrival-time-selection'
import { FormTimeSpinInput } from '@components/form-controls/form-time-spin-input'

export interface ArrivalTimeStepFormInputs {
  omit_arrival_time: boolean
  hour: string
  minute: string
}

interface Props {
  reservation: ReservationDetails
  children?: (formValues: ArrivalTimeStepFormInputs) => React.ReactNode
  onDataChange?: (formValues: ArrivalTimeStepFormInputs) => void
}

export const ArrivalTimeStep = ({ reservation, children, onDataChange }: Props): JSX.Element => {
  const [initialHour, initialMinute] = (reservation.declared_arrival_time ?? reservation.arrival_time).split(':')

  const methods = useForm<ArrivalTimeStepFormInputs>({
    defaultValues: {
      omit_arrival_time: false,
      hour: initialHour,
      minute: initialMinute,
    },
  })

  const formValue = useWatch({ control: methods.control }) as ArrivalTimeStepFormInputs

  React.useEffect(() => {
    onDataChange?.(formValue)
  }, [formValue])

  React.useEffect(() => {
    methods.setValue('hour', initialHour)
    methods.setValue('minute', initialMinute)
  }, [reservation.declared_arrival_time, reservation.arrival_time])

  return (
    <FormProvider {...methods}>
      <div className="px-3 px-md-0">
        <div className="d-block d-md-flex align-items-center">
          <SectionTitle
            title="Orientacyjna godzina przyjazdu"
            subtitle={<Subtitle />}
            className="text-center text-md-start"
          />
          <div className="mb-5 text-center d-block d-md-none">
            <ArrivalTimeSelection reservation={reservation} />
          </div>
          <div className="col-6 d-none d-md-block mt-5">
            <FormTimeSpinInput
              className="justify-content-center w-100"
              hourMin={parseInt(reservation.arrival_time.split(':')[0], 10)}
            />
          </div>
        </div>

        <Checkbox
          name="omit_arrival_time"
          label="Nie chcę określać godziny"
          wrapperClassName="w-100 d-flex justify-content-center justify-content-md-start mb-5"
        />
        {children?.(formValue)}
      </div>
    </FormProvider>
  )
}

const Subtitle = () => (
  <>
    <p>
      Podpowiedz nam, o której godzinie planujesz się pojawić, a my zadbamy o resztę. Informacja ma charakter
      orientacyjny. Dokładamy wszelkich starań, aby Twój lokal był gotowy najszybciej jak to możliwe.
    </p>
    <span>
      Dlatego prosimy o wskazanie przybliżonej godziny przyjazdu lub pominięcie kroku, jeśli nie jesteś w stanie
      sprecyzować.
    </span>
  </>
)
