import * as React from 'react'
import { formatRemainingTime, parseISODate } from '@helpers/date-helper'
import { addDays, formatDuration, isBefore, startOfToday } from 'date-fns'
import { pl } from 'date-fns/locale'

interface UseTimeElapseResponse {
  remainingTime: string
  isLoading: boolean
}

export const useRemainingTime = (
  date: string | Date,
  remainingTimeFormatter?: ({ years, months, days, hours, minutes, seconds }: Duration) => string,
): UseTimeElapseResponse => {
  const [remainingTime, setRemainingTime] = React.useState('')

  React.useEffect(() => {
    if (isBefore(addDays(startOfToday(), 8), parseISODate(date) as Date)) {
      setRemainingTime(
        formatRemainingTime(date, duration =>
          formatDuration(duration, { format: ['years', 'months', 'days'], locale: pl }),
        ),
      )
      return
    }

    const timer = setInterval(() => {
      setRemainingTime(formatRemainingTime(date, remainingTimeFormatter))
    }, 1000)

    return () => {
      clearInterval(timer)
    }
  }, [date, remainingTimeFormatter])

  return {
    remainingTime,
    isLoading: remainingTime === '',
  }
}
