import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import { NotificationEvents } from '@models/websocket'

export interface WebSocketState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload: any
  type: NotificationEvents | undefined
}

const initialState: WebSocketState = {
  payload: null,
  type: undefined,
}

const websocketSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    setWebSocketData(state, action: PayloadAction<WebSocketState>) {
      state.payload = action.payload.payload
      state.type = action.payload.type
    },
  },
})

export const { setWebSocketData } = websocketSlice.actions

export const selectWebsockets = (state: RootState): WebSocketState => state.webSocketState

export default websocketSlice.reducer
