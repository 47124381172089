import * as React from 'react'
import { ReservationDetails } from '@models/reservation'
import { CheckedInReservationRowContent } from '@modules/reservations/checked-in/checked-in-reservation-row-content'
import { PendingReservationRowContent } from '@modules/reservations/pending/pending-reservation-row-content'
import { isCheckedInReservation, isPendingReservation } from '@helpers/reservation'

interface Props {
  reservationDetails: ReservationDetails
}

export const ReservationRowContent = ({ reservationDetails }: Props): JSX.Element => (
  <>
    {isCheckedInReservation(reservationDetails) && (
      <CheckedInReservationRowContent reservationDetails={reservationDetails} />
    )}
    {isPendingReservation(reservationDetails) && (
      <PendingReservationRowContent reservationDetails={reservationDetails} />
    )}
  </>
)
