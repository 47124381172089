import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { useFormContext } from 'react-hook-form'

interface Props {
  inputName: string
  className?: string
}

export const FormError = ({ inputName, className }: Props): React.ReactElement | null => {
  const {
    formState: { errors },
  } = useFormContext()

  const hasError = errors && !!errors[inputName]

  return hasError ? (
    <IconWithText
      className={clsx('text-danger align-items-center', className)}
      textClassName="font-size-xs"
      iconClassName="uil-info-circle me-2"
      text={String(errors[inputName]?.message) || ''}
    />
  ) : null
}
