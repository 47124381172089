import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  title: string | JSX.Element
  subtitle?: string | JSX.Element
  className?: string
  navy?: boolean
  leftAlign?: boolean
}

export const SectionTitle = ({ title, subtitle, className, navy, leftAlign = false }: Props): JSX.Element => (
  <div
    className={clsx(
      'py-3',
      {
        'text-primary': !navy,
        'text-navy': navy,
        'text-start': leftAlign,
        'text-center': !leftAlign,
      },
      className,
    )}
  >
    <div className="font-size-xxl fw-bold">{title}</div>
    <div className="font-size-sm text-muted">{subtitle}</div>
  </div>
)
