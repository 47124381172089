import { CircleIndicator } from '@components/circle-indicator'
import { IconWithText } from '@components/icon-with-text'
import { getMinutesLabel } from '@helpers/date-helper'
import { ReservationDetails } from '@models/reservation'
import * as React from 'react'
import { TimeSavedIndicatorText } from '@modules/reservations/pending/time-saved-indicator-text'
import { useModal } from '@components/modals/use-modal'
import {
  checkGuestsFilled,
  checkParkingFilled,
  isEachRulesRequiredForReservationAccepted,
} from '@modules/reservations/pending/utils'
import { useSelector } from 'react-redux'
import { selectClientProfileRules } from '@store/selectors/client-selectors'

const calculateTimeSaved = (isGuestsFilled: boolean, isGdprFilled: boolean, isParkingFilled: boolean): number => {
  let timeSaved = 0

  if (isGuestsFilled) {
    timeSaved += 10
  }

  if (isGdprFilled) {
    timeSaved += 10
  }

  if (isParkingFilled) {
    timeSaved += 10
  }

  return timeSaved
}

interface Props {
  reservationDetails: ReservationDetails
}

export const PendingReservationTimeSavedIndicator = ({ reservationDetails }: Props): JSX.Element => {
  const clientProfileRules = useSelector(selectClientProfileRules)

  const [toggleCompletionModal] = useModal('ReservationDataCompletionModal', {
    title: 'Uzupełnianie danych',
  })

  const isGuestsFilled = checkGuestsFilled(reservationDetails)
  const isGdprFilled = isEachRulesRequiredForReservationAccepted(reservationDetails.rules, clientProfileRules)
  const isParkingFilled = checkParkingFilled(reservationDetails)
  const timeSaved = calculateTimeSaved(isGuestsFilled, isGdprFilled, isParkingFilled)

  return (
    <div className="card d-flex flex-column flex-lg-row  mb-4" data-testid="pending-reservation-time-saved-indicator">
      <div className="bg-danger text-white d-flex flex-column align-items-center justify-content-center p-2 overlap-border-top-1 overlap-border-left-1 overlap-border-bottom-1 round-border-tl round-border-bl">
        <div className="d-flex flex-row flex-lg-column gap-1 align-items-center justify-content-center">
          <strong className="font-size-23 lh-sm">{timeSaved}</strong>
          <span className="font-size-9 lh-sm">{getMinutesLabel(30)}</span>
        </div>
        <TimeSavedIndicatorText className="d-flex d-md-none" />
      </div>
      <div className="d-flex flex-column flex-lg-row justify-content-between w-100 align-items-center py-2 py-lg-0">
        <TimeSavedIndicatorText className="d-none d-md-flex" />
        <div className="font-size-xl text-darker-gray d-flex align-items-center px-3 py-2">
          <CircleIndicator checked={isGuestsFilled}>
            <i className="uil-smile" />
          </CircleIndicator>
          <CircleIndicator checked={isGdprFilled}>
            <i className="uil-file-check-alt" />
          </CircleIndicator>
          <CircleIndicator checked={isParkingFilled}>
            <i className="uil-parking-square" />
          </CircleIndicator>
        </div>
        <IconWithText
          className="text-darker-gray pe-0 pe-lg-5"
          iconClassName="uil-edit-alt font-size-xl me-2"
          text={<strong>Uzupełnij dane teraz</strong>}
          onClick={toggleCompletionModal}
        />
      </div>
    </div>
  )
}
