import { ClientProfileGuest } from '@models/client'
import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import { AddGuestButton } from '@modules/user/guests/add'
import { ClientProfileGuestBox } from '@modules/user/guests/box'
import * as clsx from 'clsx'

interface Props {
  guests: ClientProfileGuest[]
}

export const ClientProfileGuestsList = ({ guests }: Props): JSX.Element => (
  <div className="client-profile-guests-list__wrapper">
    {guests.map((guest, index) => (
      <Row key={guest.id} className={clsx({ 'mt-2': index > 0 })}>
        <Col md={9} sm={12}>
          <ClientProfileGuestBox guest={guest} />
        </Col>
      </Row>
    ))}
    <AddGuestButton />
  </div>
)
