import * as React from 'react'
import { ExpandableRowCard } from '@components/expandable-row-card'
import { SubscriptionExpandableRowAside } from '@modules/special-products/subscriptions/expandable/subscription-expandable-row-aside'
import { SubscriptionExpandableRowHeader } from '@modules/special-products/subscriptions/expandable/subscription-expandable-row-header'
import { SubscriptionExpandableRowContent } from '@modules/special-products/subscriptions/expandable/subscription-expandable-row-content'
import { Subscription } from '@models/client'
import { useApiRequest } from '@hooks/use-api-request'
import { commonObjectGet } from '@api/basic-requests'
import { ContentLoader } from '@components/loaders/content-loader'
import { useDeviceSize } from '@hooks/use-device-size'
import { useHistory } from 'react-router-dom'
import { NavigationPath } from '@models/routes'

interface Props {
  subscription: Subscription
}

export const SingleSubscription = ({ subscription }: Props): JSX.Element => {
  const { isMobile, isDesktop } = useDeviceSize()
  const [isOpen, setIsOpen] = React.useState(false)
  const [subscriptionDetails, setSubscriptionDetails] = React.useState(null)

  const { action: fetchDetails, isLoading } = useApiRequest(async () => {
    setSubscriptionDetails(await commonObjectGet(subscription.urls.details))
  })

  const history = useHistory()

  const handleToggleOpen = () => {
    if (isDesktop) {
      if (!subscriptionDetails) fetchDetails()

      setIsOpen(state => !state)
      return
    }
    moveToDetailsView()
  }

  const moveToDetailsView = () => {
    history.push(NavigationPath.SubscriptionDetails.replaceId(subscription.id))
  }

  const handleCardClick = () => {
    if (!isDesktop) moveToDetailsView()
  }

  const aside = !isMobile
    ? () => <SubscriptionExpandableRowAside subscription={subscription} isExpanded={isOpen} />
    : undefined

  return (
    <div onClick={handleCardClick} className="mb-3">
      <ExpandableRowCard
        aside={aside}
        header={<SubscriptionExpandableRowHeader subscription={subscription} />}
        isOpen={isOpen}
        onOpen={handleToggleOpen}
        isExpandDisabled={subscription.status === 'initial'}
      >
        <ContentLoader isLoading={isLoading} className="expandable-row-card__loader">
          {subscriptionDetails && <SubscriptionExpandableRowContent subscriptionDetails={subscriptionDetails} />}
        </ContentLoader>
      </ExpandableRowCard>
    </div>
  )
}
