import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import { ModalFooter } from '@components/modals/modal-footer'
import { NfcOpenAnimation } from './nfc-open-animation'

interface Props {
  handleClose: () => void
}

export const OpenDoorNfcModalMobile = ({ handleClose }: Props): JSX.Element => (
  <>
    <ModalBody>
      <NfcOpenAnimation />
      <p className="text-primary fw-bold text-center font-size-xl mt-3">Klucz mobilny</p>
      <p className="text-muted text-center">
        Przyłóż teraz telefon do zamka Salto, <br />w miejscu oznaczonym białą ramkę...
      </p>
    </ModalBody>
    <ModalFooter onClose={handleClose} submitLabel="Zamknij" onSubmit={handleClose} />
  </>
)
