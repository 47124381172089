import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ModalBody } from 'react-bootstrap'
import { ItemChangeConfirmationModalContent } from '@components/item-change-confirmation-modal-content'

interface Props {
  onConfirm: () => void
  guestText: string
  handleClose: () => void
}

export const GuestFeedingChangeConfirmationModal = ({ handleClose, onConfirm, guestText }: Props): JSX.Element => {
  const handleConfirm = () => {
    onConfirm()
    handleClose()
  }

  return (
    <ModalBody className="modal-scrollable">
      <ItemChangeConfirmationModalContent text="Czy na pewno chcesz usunąć" underlineText={`wyżywienie ${guestText}?`}>
        <button className="btn btn-outline-secondary mt-3 px-5 py-2" onClick={handleClose}>
          <IconWithText text="Anuluj" iconClassName="uil-times me-2 font-size-xxl" className="fw-medium" />
        </button>
        <IconWithText
          onClick={handleConfirm}
          text="Usuwam wyżywienie"
          iconClassName="uil-trash-alt text-danger font-size-xxl me-2"
          textClassName="text-danger"
          className="mt-3 fw-medium"
        />
      </ItemChangeConfirmationModalContent>
    </ModalBody>
  )
}
