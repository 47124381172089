import { LabeledRow } from '@components/labeled-row'
import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'

export const WalletHeader = (): JSX.Element => (
  <LabeledRow className="align-items-center" label={<div className=" font-size-20 text-primary fw-bold">Portfel</div>}>
    <IconWithText
      className="align-items-center"
      iconClassName="uil-info-circle text-primary me-3 font-size-icon-18"
      text={
        <span className="text-darker-gray font-size-11">
          Co to jest <strong>Portfel</strong> i dlaczego to takie wygodne?
        </span>
      }
    />
  </LabeledRow>
)
