import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { Spinner } from '@components/loaders/spinner'

export interface TextWithSpinnerProps {
  isLoading: boolean
  text?: string
  iconClassName?: string
  loadingText?: string
  spinnerColor?: string
  className?: string
  textWrapperClassName?: string
  onClick?: () => void
}

export const TextWithSpinner = ({
  isLoading,
  text = 'Zapisz',
  loadingText = 'Zapisywanie...',
  iconClassName,
  spinnerColor = 'white',
  className,
  textWrapperClassName,
  onClick,
}: TextWithSpinnerProps): JSX.Element => (
  <div className={clsx('d-flex justify-content-center align-items-center', className)}>
    {isLoading && <Spinner className="text-primary spinner--small me-3" color={spinnerColor} data-testId="loader" />}
    <IconWithText
      text={isLoading ? loadingText : text}
      iconClassName={clsx(!isLoading && iconClassName)}
      onClick={onClick}
      className={textWrapperClassName}
    />
  </div>
)
