import * as clsx from 'clsx'
import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'

interface Tab {
  label: string
  value: number
}

interface Props {
  activeTab: number
  completedTabs: number[]
  tabs: Tab[]
  onClick: (value: number) => void
}
export const CompletionTabs = ({ activeTab, completedTabs, tabs, onClick }: Props): JSX.Element => {
  React.useEffect(() => {
    document.querySelector('.completion-tabs__tab--active')?.scrollIntoView()
  }, [activeTab])

  return (
    <div className="d-flex mx-n3 overflow-y-auto scroll-hidden">
      {tabs.map(tab => (
        <button
          key={tab.value}
          className={clsx('completion-tabs__tab flex-1 d-flex align-items-center justify-content-center', {
            'completion-tabs__tab--active': activeTab === tab.value,
            'completion-tabs__tab--complete': completedTabs.includes(tab.value),
          })}
          onClick={() => onClick(tab.value)}
        >
          <IconWithText
            text={<strong>{tab.label}</strong>}
            className="align-items-center font-size-lg px-2 text-nowrap"
            iconClassName={clsx('font-size-icon-18 me-1', {
              'uil-times': !completedTabs.includes(tab.value),
              'uil-check': completedTabs.includes(tab.value),
            })}
          />
        </button>
      ))}
    </div>
  )
}
