export const SubscriptionContractStatusesToShow = [
  'initial',
  'completed',
  'confirmed',
  'cancelling',
  'cancelled',
  'initial_resume',
]

export const SubscriptionContractExpandableStatuses = ['confirmed', 'cancelling', 'completed']
export const SubscriptionContractStatusesWithDetailsAvailable = [...SubscriptionContractExpandableStatuses, 'initial']
