import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import * as React from 'react'

export const AddGuestButton = (): JSX.Element => {
  const [handleModal] = useModal('ClientProfileGuestModal', { title: 'Dodaj osobę' })

  return (
    <IconWithText
      className="fw-bold text-darker-gray mt-3"
      iconClassName="uil-plus me-1"
      text="Dodaj osobę towarzyszącą"
      onClick={handleModal}
    />
  )
}
