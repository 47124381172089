import * as clsx from 'clsx'
import * as React from 'react'
import { useHistory } from 'react-router'
import { NavigationPath } from '@models/routes'

interface Props {
  children?: React.ReactNode
  hideMenuIcon?: boolean
  action?: 'back' | 'close'
  onAction?: () => void
}

export const AppTopbar: React.FC<Props> = ({ children, hideMenuIcon, action = 'back', onAction }) => {
  const { push } = useHistory()

  const handleLogoClick = () => {
    push(NavigationPath.Reservations)
  }

  return (
    <div className="topbar__wrapper position-relative">
      <div className="container-xxl d-flex align-items-center justify-content-between">
        {onAction && (
          <i
            className={clsx('topbar__icon', {
              'uil-arrow-left topbar__icon--back': action === 'back',
              'uil-times topbar__icon--close': action === 'close',
            })}
            onClick={onAction}
          />
        )}
        <div className="topbar__logo">
          <img
            src={require('@assets/images/logo_holiday_color.svg')}
            height="38"
            onClick={handleLogoClick}
            className="cursor-pointer"
          />
        </div>
        {!hideMenuIcon && (
          <i className={clsx('uil-apps topbar__icon topbar__icon--menu', { invisible: hideMenuIcon })} />
        )}
        <div className="topbar__menu-wrapper">{children}</div>
      </div>
    </div>
  )
}
