import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { useDeviceSize } from '@hooks/use-device-size'
import { Reservation, ReservationDetails } from '@models/reservation'
import { CheckInBannerTimeLeft } from '@modules/reservations/pending/check-in-banner-time-left'
import { useApiRequest } from '@hooks/use-api-request'
import { NavigationPath } from '@models/routes'
import { useHistory } from 'react-router'
import { useModal } from '@components/modals/use-modal'
import { commonObjectPost } from '@api/basic-requests'
import { useForwardProfileRulesToReservation } from '@hooks/use-forward-profile-rules-to-reservation'
import { useDispatch } from 'react-redux'
import { setReservationDetails } from '@store/slices/reservations-slice'
import { setExpanded } from '@store/slices/expanded-reservation-slice'
import { ButtonLoading } from '@components/controls/button-loading'
import { isAfter } from 'date-fns'
import { createCombinedDateWithTime } from '@helpers/date-helper'

interface Props {
  reservation: Reservation
}

export const CheckInBanner = ({ reservation }: Props): JSX.Element => {
  const { isMobile, isDesktop } = useDeviceSize()

  const [showCheckInModal] = useModal('CheckInModal')

  const history = useHistory()
  const dispatch = useDispatch()

  const { forwardRules, isForwarding } = useForwardProfileRulesToReservation()

  const { isLoading, action: handleCheckIn } = useApiRequest(async () => {
    const reservationDetails = await commonObjectPost<ReservationDetails>(reservation.urls.online_checkin_start)
    dispatch(setReservationDetails(reservationDetails))

    if (isDesktop) dispatch(setExpanded(reservationDetails.token))

    await forwardRules(reservationDetails)

    if (isDesktop) {
      showCheckInModal(null, { reservation, processStarted: true })
      return
    }

    history.replace(NavigationPath.ReservationDetails.replaceToken(`${reservation.token}/check-in`))
  })

  const arrivalTimePassed = isAfter(
    new Date(),
    createCombinedDateWithTime(reservation.date_from, reservation.arrival_time),
  )

  return (
    <div className="card mb-4 check-in-banner border-0">
      <div className="row mx-0">
        <CheckInBannerTimeLeft reservation={reservation} />
        <div className="col-9 col-lg-10 bg-primary px-4 py-3 d-flex flex-column flex-lg-row align-items-baseline align-items-lg-center justify-content-between round-border-br round-border-tr text-white">
          <div className="mb-3 mb-lg-0">
            <div className="fw-bold">
              {arrivalTimePassed ? (
                <span>
                  Spóźniony na wakacje?{' '}
                  <span className="d-block d-md-inline">
                    Zamelduj się szybko
                    <span className="text-nowrap"> on-line</span>
                  </span>
                </span>
              ) : (
                <span>
                  Odblokowaliśmy dla Ciebie możliwość zameldowania <span className="text-nowrap">on-line</span>
                </span>
              )}
            </div>
            <div className="font-size-11 fw-light">
              Omiń kolejki w recepcji i rozpocznij swój pobyt
              <span className="fw-bold"> nawet o 25 minut szybciej.</span>
            </div>
          </div>
          <ButtonLoading
            loadingLabel="Ładowanie danych..."
            loadingClassName="fw-bold"
            isLoading={isLoading || isForwarding}
            className={clsx('text-white py-2', isMobile && 'bg-dark-gray-gradient')}
            onClick={handleCheckIn}
          >
            <IconWithText
              text="Zamelduj on-line teraz"
              iconClassName="uil-check font-size-icon-18 me-2"
              className="font-size-md fw-bold"
            />
          </ButtonLoading>
        </div>
      </div>
    </div>
  )
}
