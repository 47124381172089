import * as React from 'react'
import { useDeviceSize } from '@hooks/use-device-size'
import { TrapezeButton, TrapezeButtonVariant } from '@components/controls/trapeze-button'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import { Reservation } from '@models/reservation'

interface Props {
  reservation: Reservation
}

export const CloseReservationsListRowReceiptButton = ({ reservation }: Props): JSX.Element => {
  const { isMobile } = useDeviceSize()

  const [handleReservationReceipt] = useModal('ReservationReceiptModal', {
    title: (
      <span>
        Rachunek końcowy{' '}
        <span className="receipt__header__reservation-number">rezerwacji {reservation.reservation_number}</span>
      </span>
    ),
    reservation,
  })

  if (isMobile)
    return (
      <div className="h-100 ms-auto pe-4" onClick={handleReservationReceipt}>
        <i className="font-size-icon-22 uil-file-check-alt" />
      </div>
    )

  return (
    <TrapezeButton className="mt-2 ms-auto" variant={TrapezeButtonVariant.gray} onClick={handleReservationReceipt}>
      <IconWithText
        className="align-items-center"
        iconClassName="me-2 font-size-xl uil-file-check-alt"
        text="Rachunek"
      />
    </TrapezeButton>
  )
}
