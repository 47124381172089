import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { Button } from 'react-bootstrap'

interface Props {
  onNext?: () => void
  onPrev?: () => void
}
export const ModalFooterMobileNavigation = ({ onPrev, onNext }: Props): JSX.Element => (
  <div className="modal-mobile-footer d-flex">
    {onPrev && (
      <Button
        variant="light"
        className={clsx('btn-light-gray flex-fill d-flex justify-content-center align-items-center', {
          'border-end border-white': onNext,
        })}
        onClick={onPrev}
      >
        <IconWithText
          text="Poprzedni"
          className="text-white"
          iconClassName="font-size-icon-sm me-2 uil-angle-left-b"
          textClassName="fw-bold font-size-xl"
        />
      </Button>
    )}

    {onNext && (
      <Button
        variant="secondary"
        className={clsx('flex-fill d-flex justify-content-center align-items-center', {
          'border-start border-white': onPrev,
        })}
        onClick={onNext}
      >
        <IconWithText
          text="Następny"
          className="flex-row-reverse text-white"
          iconClassName="font-size-icon-sm ms-2 uil-angle-right-b"
          textClassName="fw-bold font-size-xl"
        />
      </Button>
    )}
  </div>
)
