import * as React from 'react'
import { ContentLoader } from '@components/loaders/content-loader'
import { ModalBody } from 'react-bootstrap'
import { ModalFooter } from '@components/modals/modal-footer'
import { MetersStateTable } from '@modules/reservations/meters-state-modal/table'
import { IconWithText } from '@components/icon-with-text'
import { useApiRequest } from '@hooks/use-api-request'
import { getReservationMeters } from '@api/reservation'
import { ReservationMetersState } from '@models/reservation'

interface Props {
  handleClose: () => void
  url: string
}
export const MetersStateModal = ({ handleClose, url }: Props): JSX.Element => {
  const [metersState, setMetersState] = React.useState<ReservationMetersState>()

  const { action: fetchMeters, isLoading } = useApiRequest(async () => {
    setMetersState(await getReservationMeters(url))
  })

  React.useEffect(() => {
    fetchMeters()
  }, [])

  return (
    <ContentLoader isLoading={false}>
      <ModalBody className="modal-scrollable">
        <p className="text-darker-gray">
          Stan mediów możesz sprawdzić w dowolnym momencie. Jeśli masz podejrzenie, że licznik nie działa poprawnie
          zgłoś ten problem.
        </p>
        <ContentLoader isLoading={isLoading}>
          {metersState ? (
            <MetersStateTable metersState={metersState} />
          ) : (
            <div className="text-danger font-size-sm">Nie udało się pobrać stanu liczników.</div>
          )}
        </ContentLoader>
        <div className="d-flex justify-content-center mt-3">
          <button className="btn">
            <IconWithText
              className="text-darker-gray"
              iconClassName="uil-wrench font-size-xxl me-2"
              textClassName="fw-bold"
              text="Chcę zgłosić usterkę licznika"
            />
          </button>
        </div>
      </ModalBody>
      <ModalFooter withCancelButton={true} withSubmit={false} cancelLabel="Zamknij" onClose={handleClose} />
    </ContentLoader>
  )
}
