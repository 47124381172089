import * as clsx from 'clsx'
import * as React from 'react'
import { FormControl } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'

export const CustomFormControl = ({ inputName, formControlProps }) => {
  const { register, watch } = useFormContext()
  const value = watch(inputName)

  return (
    <FormControl
      {...register(inputName)}
      {...formControlProps}
      className={clsx(
        'shadow-none form-control-height-lg font-size-12 form-control-with-status fw-bold text-darker-gray',
        { 'no-value': !value },
        formControlProps?.className,
      )}
      data-testid={`${inputName}-form-control`}
    />
  )
}
