import { ThunkAction } from 'redux-thunk'
import { RootState } from '@store/index'
import axios from 'axios'
import { SetClientDetailsAction } from '@store/action-types/client-action-types'

export interface ChangePasswordForm {
  password: string
  new_password: string
  confirmed_password: string
}

export function changePassword(
  payload: ChangePasswordForm,
): ThunkAction<void, RootState, null, SetClientDetailsAction> {
  return async (dispatch, getState) => {
    await axios.post(getState().appDataState.appData.urls.account.change_password, payload)
  }
}
