import { LabeledRow } from '@components/labeled-row'
import { FormLabel } from 'react-bootstrap'
import { RuleAcceptanceOptions } from '@modules/user/gdpr-data/rule-acceptance-options'
import * as React from 'react'
import { Rule } from '@models/reservation'
import { AvailableRuleKeyword } from '@models/app-data'

interface Props {
  title: React.ReactNode
  rule: Rule<AvailableRuleKeyword>
  children: React.ReactElement
  onUpdate: (value) => void
  context?: string
  disabled?: boolean
  updatingRules?: string[]
}

export const RuleRow = ({
  title,
  rule,
  children,
  onUpdate,
  context = '',
  updatingRules,
  disabled,
}: Props): JSX.Element => (
  <LabeledRow hideLabelOnWrap className="mt-3 mt-lg-5" label={title}>
    <FormLabel className="text-muted fw-bold font-size-sm mb-0 mt-2 d-flex">{title}</FormLabel>
    {children}
    <RuleAcceptanceOptions
      rule={rule}
      context={context}
      onUpdate={onUpdate}
      disabled={disabled}
      updatingRules={updatingRules}
    />
  </LabeledRow>
)
