import { LabeledRow } from '@components/labeled-row'
import { CheckboxWithDescription } from '@components/controls/checkbox-with-description'
import * as React from 'react'
import { Form } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'

interface FormInputs {
  notifications_news: boolean
  notifications_promotion: boolean
  notifications_arrival: boolean
}

type FormInputKey = keyof FormInputs

export const UserNotificationsSettings = (): JSX.Element => {
  const { watch, setValue } = useFormContext<FormInputs>()
  const isAllSelected = watch(['notifications_news', 'notifications_promotion', 'notifications_arrival']).every(Boolean)
  const handleSelectAll: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(event => {
    const {
      target: { checked },
    } = event
    const fieldsToUpdate: FormInputKey[] = ['notifications_news', 'notifications_promotion', 'notifications_arrival']

    fieldsToUpdate.forEach(fieldName => setValue(fieldName, checked, { shouldDirty: true }))
  }, [])

  return (
    <div className="user-notifications-settings__wrapper">
      <LabeledRow
        className="align-items-center"
        label={<div className="text-primary font-size-20 fw-bold pb-xl-4">Ustawienia powiadomień</div>}
      />
      <LabeledRow>
        <Form.Check
          onChange={handleSelectAll}
          type="checkbox"
          id="select-all"
          className="checkbox cursor-pointer"
          label="Zaznacz wszystkie"
          checked={isAllSelected}
        />
        <CheckboxWithDescription className="mt-3" name="notifications_news" label="Powiadom mnie o nowościach">
          <div className="fw-light d-inline-block">
            Ta zgoda pozwoli nam informować Cię o najnowszych udogodnieniach,
            <br />
            które przygotowalismy specjalnie dla naszych Gości.
          </div>
        </CheckboxWithDescription>
        <CheckboxWithDescription className="mt-3" name="notifications_promotion" label="Powiadom mnie o promocjach">
          <div className="fw-light d-inline-block">
            Ta zgoda pozwoli nam informować Cię o najnowszych promocjach,
            <br />
            które przygotowaliśmy specjalnie dla naszych Gości.
          </div>
        </CheckboxWithDescription>
        <CheckboxWithDescription
          className="mt-3"
          name="notifications_arrival"
          label="Powiadom mnie 3 dni przed przyjazdem"
        >
          <div className="fw-light d-inline-block">
            Ta zgoda pozwoli nam informować Cię 3 dni przed planowanym przyjazdem.
          </div>
        </CheckboxWithDescription>
      </LabeledRow>
    </div>
  )
}
