import * as clsx from 'clsx'
import * as React from 'react'

interface Props {
  isLoading?: boolean
}
export const BackdropLoader = ({ isLoading }: Props): JSX.Element => (
  <div
    className={clsx('backdrop-loader__backdrop position-absolute w-100 h-100 top-0 left-0 bg-transparent', {
      visible: isLoading,
      invisible: !isLoading,
    })}
  >
    <img
      role="loader"
      src={require('@assets/loader.svg')}
      alt="Holiday park loader"
      className="backdrop-loader__loader"
    />
  </div>
)
