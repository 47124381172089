import * as React from 'react'
import { ParkingFormInputs, ReservationDetails } from '@models/reservation'
import { FieldArrayWithId } from 'react-hook-form'
import { PlateField, PlatesFormArrayInput } from '@modules/reservations/plates-form-array/input'

function defaultRenderLabel() {
  return 'Wpisz numer rejestracji (opcjonalnie)'
}

interface Props {
  fields: FieldArrayWithId<ParkingFormInputs, 'plates', 'id'>[]
  reservationDetails: ReservationDetails
  renderLabel?: (index: number) => string
  onSave?: (id: string, value: string, carId: number | null) => void
  wrapperClassName?: string
  isLoading: (field: PlateField) => boolean
}

export const PlatesFormArray = ({
  fields,
  reservationDetails,
  renderLabel = defaultRenderLabel,
  onSave,
  wrapperClassName,
  isLoading,
}: Props): JSX.Element => (
  <>
    {fields.map((field, index) => (
      <PlatesFormArrayInput
        isLoading={isLoading}
        wrapperClassName={wrapperClassName}
        key={field.id}
        field={field}
        index={index}
        reservationDetails={reservationDetails}
        renderLabel={renderLabel}
        onSave={onSave}
      />
    ))}
  </>
)
