import * as React from 'react'
import { ParkingKind, ReservationImprovement, ResortImprovement } from '@models/reservation'
import { useDispatch, useSelector } from 'react-redux'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import { getImprovementLimit, isParking, isParkingCarPlatesUpdate } from '@helpers/improvement-helper'
import {
  CarPlate,
  ImprovementCarPlatesField,
} from '@modules/reservations/improvements/kinds/base-improvements/improvement-car-plates-field'
import { ImprovementSummaryModalPriceRow } from '@modules/reservations/improvements/summary/improvement-summary-modal-price-row'
import { ImprovementSummaryModalRow } from '@modules/reservations/improvements/summary/improvement-summary-modal-row'
import { ImprovementModalWrapper } from '../../improvement-modal-wrapper'
import { addImprovement, AddImprovementPayload, updateCarPlates, updateImprovement } from '@api/improvements'
import { createArrFromNumber, createHash } from '@helpers/utils'
import { useImprovementPriceCalculation } from '@hooks/use-improvement-price-calculation'
import { useFormRequest } from '@hooks/use-api-request'
import { ImprovementPrice } from '@modules/reservations/improvements/kinds/base-improvements/improvement-price'
import { setReservationDetails } from '@store/slices/reservations-slice'
import { useImprovementRemovingCheck } from '@hooks/use-improvement-removing-check'
import { createCombinedDateWithTime } from '@helpers/date-helper'
import { useImprovementDetailsParkingPlates } from '@modules/reservations/improvements/kinds/base-improvements/use-improvement-details-parkings'

export interface ImprovementDetailsFormInputs {
  amount: number
  plates: CarPlate[]
  maxAmount: number
  minAmount: number
}

interface Props {
  resortImprovement?: ResortImprovement
  reservationImprovement?: ReservationImprovement
  handleClose: () => void
}

export const BaseImprovementDetailsModal = ({
  resortImprovement,
  reservationImprovement,
  handleClose,
}: Props): JSX.Element => {
  const [summaryItems, setSummaryItems] = React.useState<string[]>([])
  const dispatch = useDispatch()

  const reservationDetails = useSelector(selectReservationDetails)

  const { canRemoveEntireImprovement } = useImprovementRemovingCheck()
  const { getRemovedPlate } = useImprovementDetailsParkingPlates(reservationImprovement?.code as ParkingKind)

  const methods = useForm<ImprovementDetailsFormInputs>({
    defaultValues: {
      amount: reservationImprovement?.amount || 1,
      minAmount: 1,
      plates: [],
    },
  })

  React.useEffect(() => {
    methods.setValue('amount', reservationImprovement?.amount || 1)
  }, [reservationImprovement])

  const amount = useWatch({ control: methods.control, name: 'amount' })

  const improvementCode = reservationImprovement?.code ?? resortImprovement?.code ?? null

  const { improvementCalculation, isLoading: isCalculating } = useImprovementPriceCalculation({
    amount,
    improvementCode,
    improvementId: reservationImprovement?.id ?? null,
    shouldCalculate: true,
  })

  const { action: handleImprovementUpdate, isLoading: isImprovementUpdating } = useFormRequest(
    async (improvement: ReservationImprovement) => {
      let payload = createImprovementPayload(improvement)

      if (reservationDetails && isParkingCarPlatesUpdate(improvement, payload)) {
        await dispatch(updateCarPlates(reservationDetails, methods.getValues('plates')))
      } else {
        if (
          improvement &&
          'amount' in improvement &&
          isParking(improvement) &&
          payload.amount &&
          payload.amount < improvement.amount
        ) {
          const removedPlate = getRemovedPlate(improvement, methods.getValues('plates'))

          if (removedPlate?.carId) {
            payload = { ...payload, booking_car_ids: [removedPlate.carId] }
          }
        }

        await dispatch(setReservationDetails(await updateImprovement(improvement.urls.details, [payload])))
      }

      handleClose()
    },
    methods.setError,
  )

  const { action: handleImprovementAdd, isLoading: isImprovementAdding } = useFormRequest(async () => {
    const url = reservationDetails?.urls.improvements
    if (!url || !resortImprovement) return

    await dispatch(setReservationDetails(await addImprovement(url, [createImprovementPayload(resortImprovement)])))

    handleClose()
  }, methods.setError)

  const handleDelete = id => () => {
    setSummaryItems(summaryItems.filter(summaryItemId => summaryItemId !== id))
    methods.setValue('amount', amount - 1)
  }

  const createImprovementPayload = (improvement: ResortImprovement | ReservationImprovement): AddImprovementPayload => {
    const plates = methods.getValues('plates')

    return {
      code: improvement.code,
      amount,
      ...(isParking(improvement) && {
        context: { register_car_numbers: plates.map(plate => plate.register_number).filter(String) },
      }),
    }
  }

  const handleAddImprovement = () => {
    reservationImprovement ? handleImprovementUpdate(reservationImprovement) : handleImprovementAdd()
  }

  React.useEffect(() => {
    if (hasOwnSummaryList(resortImprovement)) return
    const difference = amount - summaryItems.length

    if (amount > summaryItems.length) {
      setSummaryItems([...summaryItems, ...createArrFromNumber(difference).map(createHash)])
    }

    if (amount < summaryItems.length) {
      setSummaryItems(summaryItems.slice(0, difference))
    }
  }, [amount])

  React.useEffect(() => {
    if (hasOwnSummaryList(resortImprovement)) return
    if (reservationImprovement?.amount) setSummaryItems(createArrFromNumber(amount).map(createHash))
  }, [])

  React.useEffect(() => {
    const limit = resortImprovement ? getImprovementLimit(resortImprovement, reservationDetails) : 0

    methods.setValue('maxAmount', reservationImprovement ? reservationImprovement.amount : limit)
  }, [improvementCalculation, reservationImprovement?.amount])

  const hasOwnSummaryList = improvement => isParking(improvement)

  const isRemovingAllowed = () => {
    if (!reservationDetails || !reservationImprovement) return true

    return canRemoveEntireImprovement({
      improvement: reservationImprovement,
      reservation: reservationDetails.token,
      dateFrom: createCombinedDateWithTime(reservationDetails.date_from, reservationDetails.arrival_time),
    })
  }

  const isParkingImprovement =
    (resortImprovement && isParking(resortImprovement)) || (reservationImprovement && isParking(reservationImprovement))

  return (
    <FormProvider {...methods}>
      <ImprovementModalWrapper
        contentTitle={resortImprovement?.name || resortImprovement?.name || ''}
        image={resortImprovement?.image.md ?? null}
        onSubmit={handleAddImprovement}
        isMostPopular
        isEditMode={!!reservationImprovement}
        isLoading={isImprovementUpdating || isImprovementAdding}
        onClose={handleClose}
        hasAmountChange={resortImprovement?.can_change_amount && !reservationImprovement}
        description={
          <>
            {resortImprovement?.description && <p className="text-muted my-1">{resortImprovement.description}</p>}{' '}
            {resortImprovement && <ImprovementPrice resortImprovement={resortImprovement} />}
          </>
        }
      >
        {isParkingImprovement && (
          <ImprovementCarPlatesField
            parkingKind={(resortImprovement?.code ?? reservationImprovement?.code) as ParkingKind}
            parkingName={resortImprovement?.name ?? reservationImprovement?.name ?? 'Parking'}
            calculatedImprovements={improvementCalculation?.items}
            reservationImprovement={reservationImprovement}
            isPriceCalculating={isCalculating}
            isDeleteEnabled={isRemovingAllowed()}
          />
        )}
        {!hasOwnSummaryList(reservationImprovement) &&
          summaryItems.map((id, index) => (
            <ImprovementSummaryModalRow
              key={id}
              text={resortImprovement?.name ?? reservationImprovement?.name ?? ''}
              price={improvementCalculation?.items[index]?.price || 0}
              onDelete={handleDelete(id)}
              isPriceCalculating={isCalculating}
              isDeleteEnabled={!!index && isRemovingAllowed()}
            />
          ))}
        <ImprovementSummaryModalPriceRow
          text="Koszt ulepszenia łącznie:"
          price={improvementCalculation?.total_price || 0}
          isCalculating={false}
        />
      </ImprovementModalWrapper>
    </FormProvider>
  )
}
