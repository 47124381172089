import { SectionTitle } from '@components/section-title'
import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ReservationDataCompletionParkingAdd } from '@modules/reservations/pending/data-completion-modal/parking/add'
import { PlatesFormArray } from '@modules/reservations/plates-form-array'
import { useSelector } from 'react-redux'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import { ParkingFormInputs } from '@models/reservation'
import { getPlatesInitialValue, useParkingPlatesUpdate } from '@hooks/use-parking-actions'
import { ReservationDataCompletionParkingList } from '@modules/reservations/pending/data-completion-modal/parking/list'

export const CheckInParkingForm = () => {
  const reservationDetails = useSelector(selectReservationDetails)

  const methods = useForm<ParkingFormInputs>({
    defaultValues: {
      plates: getPlatesInitialValue(reservationDetails),
    },
  })

  const { handleAppend, handleUpdate, handleRemove, isLoading, actions, fields } = useParkingPlatesUpdate(
    reservationDetails,
    methods,
  )

  const isProcessing = [
    actions.parkingLargeActions.isLoading,
    actions.parkingVipActions.isLoading,
    actions.parkingActions.isLoading,
    actions.isLoading,
  ].some(Boolean)

  return (
    <FormProvider {...methods}>
      <SectionTitle
        title="Twoje pojazdy"
        subtitle="Wpisz numery rejestracyjne dodanych pojazdów"
        className="text-center text-md-start"
      />
      <div className="row gx-2">
        {reservationDetails && (
          <PlatesFormArray
            isLoading={isLoading}
            wrapperClassName="check-in-modal__parking"
            onSave={handleUpdate}
            fields={fields}
            reservationDetails={reservationDetails}
            renderLabel={index => `Numery rejestracyjne pojazdu #${index + 1}`}
          />
        )}

        {!fields.length && (
          <strong className="text-center text-md-start col-md-8 ms-md-auto ps-md-3 pb-3 pt-2">
            Brak dodanych pojazdów
          </strong>
        )}

        {actions.parkingActions.canAdd() && (
          <ReservationDataCompletionParkingAdd
            plates={actions.platesForKind('parking2')}
            onClick={handleAppend('parking2')}
            disabled={isProcessing}
            kind="parking2"
            wrapperClassName="mr-3 check-in-modal__parking__add-button"
          />
        )}

        {actions.parkingLargeActions.canAdd() && (
          <ReservationDataCompletionParkingAdd
            plates={actions.platesForKind('parking_large')}
            onClick={handleAppend('parking_large')}
            disabled={isProcessing}
            kind="parking_large"
            wrapperClassName="mr-3 check-in-modal__parking__add-button"
          />
        )}
      </div>

      {!!actions.parkingList.length && <hr className="mb-1 mt-3 mx-xl-n4 container-xxl-full-width section-divider" />}

      <ReservationDataCompletionParkingList
        parkingItems={actions.parkingList}
        onRemove={handleRemove}
        isCalculatingPrice={actions.isCalculatingPrice}
        isRemoving={isProcessing}
        isRemoveAllowed={actions.isRemoveAllowed}
        isPriceHidden
      />
    </FormProvider>
  )
}
