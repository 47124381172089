import * as React from 'react'
import * as R from 'ramda'
import { ArrivalTimeSelectionItem } from '@modules/reservations/check-in/steps/arrival-time/arrival-time-selection-item'
import { useDebounce, useDidUpdate } from 'rooks'
import { ReservationDetails } from '@models/reservation'
import { useFormContext } from 'react-hook-form'

const minutesRange = ['', ...R.range(0, 60), '']
const minutes = minutesRange.map(el => (el !== '' ? (el < 10 ? `0${el}` : el) : el))

interface Props {
  reservation: ReservationDetails
}

export const ArrivalTimeSelection = ({ reservation }: Props) => {
  const { setValue } = useFormContext()
  const [selectedHour, setSelectedHour] = React.useState('16')
  const [selectedMinute, setSelectedMinute] = React.useState('00')
  const [availableHours, setAvailableHours] = React.useState<string[]>([])

  const debouncedHoursChange = useDebounce(setSelectedHour, 200)
  const debouncedMinutesChange = useDebounce(setSelectedMinute, 200)

  React.useEffect(() => {
    const [arrivalHour, arrivalMinutes] = reservation.arrival_time.split(':')
    const hours = ['', ...R.range(parseInt(arrivalHour, 10), 24), '']
    setAvailableHours(hours)
    setSelectedHour(arrivalHour)
    setSelectedMinute(arrivalMinutes)
  }, [reservation.arrival_time])

  React.useEffect(() => {
    if (!reservation.declared_arrival_time) return
    const [arrivalHour, arrivalMinutes] = reservation.declared_arrival_time.split(':')
    setSelectedHour(arrivalHour)
    setSelectedMinute(arrivalMinutes)
  }, [reservation.declared_arrival_time])

  useDidUpdate(() => {
    setValue('hour', selectedHour)
    setValue('minute', selectedMinute)
  }, [selectedHour, selectedMinute])

  if (!availableHours.length) return null

  return (
    <div className="d-flex justify-content-center mt-n3 mb-5">
      <ArrivalTimeSelectionItem
        value={selectedHour}
        id="hours"
        onValueChange={debouncedHoursChange}
        range={availableHours}
        className="me-1"
      />
      <ArrivalTimeSelectionItem
        value={selectedMinute}
        id="minutes"
        onValueChange={debouncedMinutesChange}
        range={minutes}
        className="ms-1"
      />
    </div>
  )
}
