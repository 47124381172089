import * as React from 'react'
import { ResortImprovement } from '@models/reservation'
import { isFeeding, isStayOption, isVip } from '@helpers/improvement-helper'
import { BaseImprovementCard } from '@modules/reservations/improvements/kinds/base-improvements/base-improvement-card'

interface Props {
  resortImprovements: ResortImprovement[]
}

export const BaseImprovements = ({ resortImprovements }: Props): JSX.Element => {
  const filteredImprovements = resortImprovements.filter(
    (improvement: ResortImprovement) => !isFeeding(improvement) && !isVip(improvement) && !isStayOption(improvement),
  )

  return (
    <div className="d-flex flex-column flex-sm-row gap-4 gap-sm-0 gy-4 row">
      {filteredImprovements.map((improvement: ResortImprovement) => (
        <BaseImprovementCard key={improvement.id} improvement={improvement} />
      ))}
    </div>
  )
}
