export type EventData<T = unknown> = {
  event: string
  pagePath?: string
  userID?: number | string
} & T

export enum GTMEvents {
  PageView = 'pageview',
  Login = 'login',
  Register = 'registration',
}
