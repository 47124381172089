import * as React from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { ClientProfileGuestForm } from '@modules/user/guests/modal/form'
import { ClientProfileGuest, ClientProfileGuestWalletLimit, ClientProfileGuestWalletScope } from '@models/client'
import { useFormRequest } from '@hooks/use-api-request'
import { useDispatch } from 'react-redux'
import { createClientProfileGuest, updateClientProfileGuest } from '@store/actions/client-actions'
import { ClientProfileGuestWalletLimits } from '@modules/user/guests/modal/limits'
import { ModalBody } from 'react-bootstrap'
import { TrapezeButtonVariant } from '@components/controls/trapeze-button'
import { ModalFooter } from '@components/modals/modal-footer'
import * as R from 'ramda'

export interface GuestTypeSelectOption {
  label: string
  value: string
}

export const guestTypeOptions = [
  { label: 'Dorosły', value: 'adult' },
  { label: 'Dziecko', value: 'child' },
]

export const defaultWalletScopes = {
  adult: [
    { scope: ClientProfileGuestWalletScope.cafe, limit: '' },
    { scope: ClientProfileGuestWalletScope.bistro, limit: '' },
    { scope: ClientProfileGuestWalletScope.vending_machines, limit: '' },
    { scope: ClientProfileGuestWalletScope.shop, limit: '' },
  ],
  child: [],
}

const getDefaultValues = (guest: ClientProfileGuest | undefined) => {
  const isAdult = (guest?.type ?? 'adult') === 'adult'

  return {
    name: guest?.name ?? '',
    type: isAdult ? { label: 'Dorosły', value: 'adult' } : { label: 'Dziecko', value: 'child' },
    wallet_limits: guest?.wallet_limits ?? (isAdult ? defaultWalletScopes.adult : defaultWalletScopes.child),
    ...(guest?.birthday && { birthday: guest.birthday }),
    ...(isAdult && { phone: guest?.phone ?? '', email: guest?.email ?? '' }),
  }
}

export interface ClientProfileGuestModalFormInputs {
  name: string
  birthday: string
  type: GuestTypeSelectOption
  email: string
  phone: string
  wallet_limits: ClientProfileGuestWalletLimit[]
}

interface Props {
  guest?: ClientProfileGuest
  handleClose: () => void
}

export const ClientProfileGuestModal = ({ guest, handleClose }: Props): JSX.Element => {
  const methods = useForm<ClientProfileGuestModalFormInputs>({
    defaultValues: getDefaultValues(guest),
  })

  const dispatch = useDispatch()

  const name = useWatch({ control: methods.control, name: 'name' })
  const walletLimits = useWatch({ control: methods.control, name: 'wallet_limits' })

  const { isLoading, action: onSubmit } = useFormRequest(async () => {
    const values = methods.getValues()

    const adultPayload = { ...values, type: values.type.value }
    const childPayload = { ...R.pick(['name', 'birthday', 'wallet_limits'], values), phone: '', email: '' }

    const payload = values.type.value === 'adult' ? adultPayload : childPayload

    methods.clearErrors()
    guest ? await dispatch(updateClientProfileGuest(guest, payload)) : await dispatch(createClientProfileGuest(payload))
    handleClose()
  }, methods.setError)

  const handleLimitChange = React.useCallback(limits => {
    methods.setValue('wallet_limits', limits)
  }, [])

  return (
    <>
      <ModalBody className="modal-scrollable">
        <FormProvider {...methods}>
          <ClientProfileGuestForm />
        </FormProvider>
        <ClientProfileGuestWalletLimits name={name} walletLimits={walletLimits} onChange={handleLimitChange} />
      </ModalBody>
      <ModalFooter
        withCancelButton
        onSubmit={onSubmit}
        isLoading={isLoading}
        submitLabel="Zapisz informację"
        onClose={handleClose}
        buttonDisabled={isLoading}
        saveButtonVariant={TrapezeButtonVariant.secondary}
      />
    </>
  )
}
