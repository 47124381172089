import * as React from 'react'
import { ExpandableRowCard } from '@components/expandable-row-card'
import { SubscriptionContract, SubscriptionContractDetails } from '@models/client'
import { ContentLoader } from '@components/loaders/content-loader'
import { useDeviceSize } from '@hooks/use-device-size'
import { useHistory } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { SubscriptionContractExpandableRowHeader } from '@modules/special-products/subscription-contracts/expandable/subscription-contract-expandable-row-header'
import { SubscriptionContractExpandableRowAside } from '@modules/special-products/subscription-contracts/expandable/subscription-contract-expandable-row-aside'
import { SubscriptionContractExpandableRowContent } from '@modules/special-products/subscription-contracts/expandable/subscription-contract-expandable-row-content'
import { useApiRequest } from '@hooks/use-api-request'
import { commonObjectGet } from '@api/basic-requests'
import { SubscriptionContractExpandableStatuses } from '@modules/special-products/subscription-contracts/consts'

interface Props {
  subscriptionContract: SubscriptionContract
  isDefaultOpen?: boolean
}

export const SingleSubscriptionContract = ({ subscriptionContract, isDefaultOpen }: Props): JSX.Element => {
  const { isMobile, isDesktop, isDeviceDetected } = useDeviceSize()
  const [isOpen, setIsOpen] = React.useState(false)
  const [subscriptionContractDetails, setSubscriptionContractDetails] =
    React.useState<SubscriptionContractDetails | null>(null)

  const history = useHistory()

  const canViewDetails = SubscriptionContractExpandableStatuses.includes(subscriptionContract.status)

  const { action: fetchDetails, isLoading } = useApiRequest(async () => {
    setSubscriptionContractDetails(await commonObjectGet(subscriptionContract.urls.details))
  })

  const handleToggleOpen = () => {
    if (isDesktop) {
      if (!isOpen) fetchDetails()
      setIsOpen(state => !state)
      return
    }

    if (canViewDetails) {
      moveToDetailsView()
    }
  }

  const moveToDetailsView = () => {
    history.push(NavigationPath.SubscriptionContractDetails.replaceId(subscriptionContract.id))
  }

  const handleCardClick = () => {
    if (!isDesktop) {
      if (!canViewDetails) return
      moveToDetailsView()
    }
  }

  const aside = !isMobile
    ? () => <SubscriptionContractExpandableRowAside subscriptionContract={subscriptionContract} />
    : undefined

  React.useEffect(() => {
    if (isDefaultOpen && isDeviceDetected && canViewDetails) handleToggleOpen()
  }, [isDeviceDetected])

  return (
    <div onClick={handleCardClick} className="mb-3">
      <ExpandableRowCard
        aside={aside}
        header={<SubscriptionContractExpandableRowHeader subscriptionContract={subscriptionContract} />}
        isOpen={isOpen}
        onOpen={handleToggleOpen}
        isExpandDisabled={!canViewDetails}
      >
        <ContentLoader isLoading={isLoading} className="expandable-row-card__loader">
          {subscriptionContractDetails && (
            <SubscriptionContractExpandableRowContent
              subscriptionContractDetails={subscriptionContractDetails}
              onSubscriptionContractChange={setSubscriptionContractDetails}
            />
          )}
        </ContentLoader>
      </ExpandableRowCard>
    </div>
  )
}
