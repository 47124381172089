import * as React from 'react'
import { getParkingCalculation, ParkingCalculation } from '@api/reservation'
import { useDispatch, useSelector } from 'react-redux'
import { useFieldArray, UseFormReturn } from 'react-hook-form'
import {
  ParkingFormInputs,
  ParkingKind,
  PlateInput,
  ReservationDetails,
  ReservationImprovement,
} from '@models/reservation'
import { useApiRequest } from '@hooks/use-api-request'
import { setReservationDetails } from '@store/slices/reservations-slice'
import { addImprovement, removeImprovement, updateCarPlates, updateImprovement } from '@api/improvements'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import Decimal from 'decimal.js'
import { getImprovementLimit, isParking } from '@helpers/improvement-helper'
import { createArrFromNumber } from '@helpers/utils'
import { useImprovementRemovingCheck } from '@hooks/use-improvement-removing-check'
import { useDidUpdate } from 'rooks'
import { PlateField } from '@modules/reservations/plates-form-array/input'
import { getFieldIndex } from '@modules/reservations/pending/data-completion-modal/parking'
import { createCombinedDateWithTime } from '@helpers/date-helper'
import { selectResortDetails } from '@store/selectors/reservations-selectors'

interface ReservationImprovementUpdatePayload extends ReservationImprovement {
  booking_car_ids?: number[]
}

interface Response {
  parkingCalculations: ParkingCalculation | undefined
  updateParkingPlates: (registerCarNumbers: PlateInput[]) => Promise<void>
  addParkingImprovement: () => Promise<void>
  removeParkingImprovement: (improvement: ReservationImprovement, carId: number | null) => Promise<void>
  updateParkingImprovement: (improvement: ReservationImprovementUpdatePayload) => Promise<void>
  canAdd: () => boolean
  isLoading: boolean
  isCalculatingPrice: boolean
}

const useParkingActions = (
  methods: UseFormReturn<ParkingFormInputs>,
  kind: ParkingKind,
  fields: PlateField[],
): Response => {
  const [parkingCalculations, setParkingCalculations] = React.useState<ParkingCalculation>()

  const reservationDetails = useSelector(selectReservationDetails)
  const resortDetails = useSelector(selectResortDetails)

  const dispatch = useDispatch()

  const plates = React.useMemo(() => fields.filter(plate => plate.kind === kind), [fields, kind])

  const { action: calculate, isLoading: isCalculatingPrice } = useApiRequest(async (parkingAmount: number, kind) => {
    if (!reservationDetails) return

    const calculation = await getParkingCalculation(reservationDetails.urls.improvement_calculator, parkingAmount, kind)
    setParkingCalculations(calculation)
  })

  const { action: updateParkingPlates, isLoading: isSaving } = useApiRequest(async (plateInputs: PlateInput[]) => {
    if (!reservationDetails) return

    await dispatch(updateCarPlates(reservationDetails, plateInputs))
  })

  const { action: updateParkingImprovement, isLoading: isUpdating } = useApiRequest(
    async (improvement: ReservationImprovementUpdatePayload) => {
      if (!reservationDetails) return

      dispatch(dispatch(setReservationDetails(await updateImprovement(improvement.urls.details, [improvement]))))
    },
  )

  const { action: addParkingImprovement, isLoading: isAdding } = useApiRequest(async () => {
    if (!reservationDetails) return

    dispatch(
      setReservationDetails(await addImprovement(reservationDetails.urls.improvements, [{ code: kind, amount: 1 }])),
    )
  })

  const { action: removeParkingImprovement, isLoading: isRemoving } = useApiRequest(
    async (improvement: ReservationImprovement, carId: number | null) => {
      dispatch(setReservationDetails(await removeImprovement(improvement.urls.details, carId ? [carId] : null)))
    },
  )

  const canAdd = () => {
    if (!resortDetails) return false
    const resortImprovement = resortDetails.improvements.find(improvement => improvement.code === kind)

    if (!resortImprovement) return false

    return !!getImprovementLimit(resortImprovement, reservationDetails)
  }

  React.useEffect(() => {
    if (plates.length) {
      calculate(plates.length, kind)
    } else {
      setParkingCalculations({ items: [], total_price: 0 })
    }
  }, [plates.length, kind])

  return {
    parkingCalculations,
    canAdd,
    updateParkingPlates,
    updateParkingImprovement,
    addParkingImprovement,
    removeParkingImprovement,
    isLoading: isSaving || isRemoving || isUpdating || isAdding,
    isCalculatingPrice,
  }
}

const useParkingKindsAction = (fields, methods) => {
  const reservationDetails = useSelector(selectReservationDetails)
  const parkingLargeActions = useParkingActions(methods, 'parking_large', fields)
  const parkingVipActions = useParkingActions(methods, 'parking_vip', fields)
  const parkingActions = useParkingActions(methods, 'parking2', fields)

  const platesForKind = React.useCallback((kind: ParkingKind) => fields.filter(plate => plate.kind === kind), [fields])

  const registerNumbers = methods.getValues('plates')

  const getPlatesForKind = (kind: ParkingKind): PlateInput[] => registerNumbers.filter(plate => plate.kind === kind)

  const parkingAmountForKind = (kind: ParkingKind) => platesForKind(kind).length

  const actionForKind = (kind: ParkingKind) =>
    ({ parking2: parkingActions, parking_large: parkingLargeActions, parking_vip: parkingVipActions }[kind])

  const parkingList = React.useMemo(
    () =>
      fields.map(({ register_number: name, kind, id, carId, improvement }) => ({ name, kind, id, carId, improvement })),
    [fields],
  )

  const totalPrice = React.useMemo(
    () =>
      new Decimal(parkingActions.parkingCalculations?.total_price ?? 0)
        .plus(parkingVipActions.parkingCalculations?.total_price ?? 0)
        .plus(parkingLargeActions.parkingCalculations?.total_price ?? 0)
        .toString(),

    [
      parkingActions.parkingCalculations?.total_price,
      parkingVipActions.parkingCalculations?.total_price,
      parkingLargeActions.parkingCalculations?.total_price,
    ],
  )

  const isCalculatingPrice = [
    parkingActions.isCalculatingPrice,
    parkingVipActions.isCalculatingPrice,
    parkingLargeActions.isCalculatingPrice,
  ].some(Boolean)

  const isLoading = [parkingActions.isLoading, parkingVipActions.isLoading, parkingLargeActions.isLoading].some(Boolean)

  const { canRemoveEntireImprovement } = useImprovementRemovingCheck()

  const isRemoveAllowed = (reservationImprovement: ReservationImprovement | undefined) => {
    if (!reservationDetails || !reservationImprovement) return true

    return canRemoveEntireImprovement({
      improvement: reservationImprovement,
      reservation: reservationDetails.token,
      dateFrom: createCombinedDateWithTime(reservationDetails.date_from, reservationDetails.arrival_time),
    })
  }

  return {
    platesForKind,
    parkingList,
    registerNumbers,
    getPlatesForKind,
    parkingAmountForKind,
    actionForKind,
    parkingActions,
    parkingVipActions,
    parkingLargeActions,
    totalPrice,
    isCalculatingPrice,
    isLoading,
    isRemoveAllowed,
  }
}

interface ParkingPlatesUpdateResponse {
  isLoading: (field: PlateField) => boolean
  handleUpdate: (fieldId: string, register_number: string, carId: number | null) => Promise<void>
  handleAppend: (kind: ParkingKind) => () => Promise<void>
  handleRemove: (id: string, improvement: ReservationImprovement) => Promise<void>
  actions: ReturnType<typeof useParkingKindsAction>
  fields: PlateField[]
}

export const useParkingPlatesUpdate = (
  reservationDetails: ReservationDetails | undefined,
  methods,
): ParkingPlatesUpdateResponse => {
  const resortDetails = useSelector(selectResortDetails)

  const [updatingFields, setUpdatingFields] = React.useState<(number | string | null)[]>([])

  const { fields, append, remove, update } = useFieldArray<ParkingFormInputs>({
    control: methods.control,
    name: 'plates',
  })

  const actions = useParkingKindsAction(fields, methods)

  useDidUpdate(() => {
    methods.setValue('plates', getPlatesInitialValue(reservationDetails))
  }, [reservationDetails?.cars, reservationDetails?.prices.improvements])

  const updateParkingData = async (kind: ParkingKind, action: () => Promise<void>, carId: number | null) => {
    setUpdatingFields(state => [...state, carId])
    await action()
    setUpdatingFields(state => state.filter(updatingField => updatingField !== carId))
  }

  const handleAppend = (kind: ParkingKind) => async () => {
    if (!reservationDetails || !resortDetails) return

    append({ register_number: '', kind, carId: null, improvement: null })
    await updateParkingData(kind, actions.actionForKind(kind).addParkingImprovement, null)
  }

  const isLoading = (field: PlateField) => updatingFields.includes(field.carId)

  const handleUpdate = async (fieldId: string, register_number: string, carId: number | null) => {
    const plateIndex = getFieldIndex(fields, fieldId)
    if (plateIndex < 0) return

    const kind = fields[plateIndex].kind

    const parkingData = { kind, register_number, carId, improvement: null }
    update(plateIndex, parkingData)

    const carsFilled = actions.platesForKind(kind).every(plate => !!plate.carId)

    const action = async () => {
      await actions
        .actionForKind(kind)
        .updateParkingPlates(
          carsFilled
            ? [parkingData]
            : actions.platesForKind(kind).map(plate => (plate.id === fieldId ? { ...plate, ...parkingData } : plate)),
        )
    }

    await updateParkingData(kind, action, carId)
  }

  const handleRemove = async (id: string, improvement: ReservationImprovement) => {
    const plateIndex = getFieldIndex(fields, id)
    if (plateIndex < 0) return

    const parking = fields[plateIndex]
    remove(plateIndex)

    if (improvement.amount === 1) {
      await actions.actionForKind(parking.kind).removeParkingImprovement(improvement, parking.carId)

      return
    }

    const action = async () => {
      await actions.actionForKind(parking.kind).updateParkingImprovement({
        ...improvement,
        amount: improvement.amount - 1,
        ...(parking.carId && { booking_car_ids: [parking.carId] }),
      })
    }

    await updateParkingData(parking.kind, action, fields[plateIndex].carId)
  }

  return {
    isLoading,
    handleUpdate,
    handleAppend,
    handleRemove,
    actions,
    fields,
  }
}

export const getPlatesInitialValue = (reservationDetails: ReservationDetails | undefined): PlateInput[] => {
  if (!reservationDetails) return []

  const { prices, cars } = reservationDetails

  const parkingImprovements = prices.improvements.items.filter(isParking)
  const parkingAmount = parkingImprovements.reduce((sum, { amount }) => sum + amount, 0)

  if (!parkingAmount) return []

  const plates: PlateInput[] = parkingImprovements.reduce(
    (elements, improvement) => [
      ...elements,
      ...createArrFromNumber(improvement.amount).map(() => ({
        register_number: '',
        kind: improvement.code as ParkingKind,
        carId: null,
        improvement,
      })),
    ],
    [],
  )

  let carsToAssign = [...cars]

  return plates.map(plate => {
    const carElement = carsToAssign.find(carToAssign => carToAssign.kind === plate.kind)

    if (carElement) {
      carsToAssign = carsToAssign.filter(carToAssign => carToAssign.id !== carElement.id)
      return { ...plate, register_number: carElement.register_number, kind: carElement.kind, carId: carElement.id }
    }

    return plate
  })
}
