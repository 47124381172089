import * as React from 'react'

interface Props {
  title: string
  children: React.ReactNode
}

export const SpecialProductCard = ({ title, children }: Props): JSX.Element => (
  <div className="card border-0 px-3 py-4">
    <h4 className="text-primary mb-4 fw-bold text-center text-xl-start">{title}</h4>
    <div className="d-flex flex-column">{children}</div>
  </div>
)
