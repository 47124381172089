import { FormProvider, useForm } from 'react-hook-form'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { Button } from 'react-bootstrap'
import { IconWithText } from '@components/icon-with-text'
import * as React from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '@store/index'
import formatIban from '@helpers/format-iban'
import { useModal } from '@components/modals/use-modal'
import { useFormRequest } from '@hooks/use-api-request'
import { getSmsCode } from '@api/authorization'
import { getState } from '@store/index'

interface FormInputs {
  account_number: string
}

export const ClientAccountEdit = ({ handleEdit }): JSX.Element => {
  const phoneNumber = useSelector((state: RootState) => state.clientState.details.phone)

  const account_number = useSelector((state: RootState) => state.clientState.details.profile.account_number)

  const methods = useForm<FormInputs>({
    defaultValues: {
      account_number: formatIban(account_number),
    },
  })

  const [handleModal] = useModal('ConfirmAccountChangeModal', {
    title: 'Potwierdź zmianę konta',
    account: methods.getValues('account_number'),
    handleEdit,
  })

  const { action: onSubmit } = useFormRequest(async () => {
    await getSmsCode(getState(), methods.getValues('account_number'))
    handleModal()
  }, methods.setError)

  return (
    <div className="pb-5">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CustomFormGroup
            inputName="account_number"
            formControlProps={{ type: 'text' }}
            formGroupProps={{ className: 'w-75' }}
          />
          <div className="text-darker-gray fw-light mt-3">
            Wpisz numer konta do zwrotu, dla Twojego bezpieczeństwa <br /> poprosimy Cię następnie o potwierdzenie
            zmiany poprzez wpisanie jednorazowego kodu, który otrzymasz na Twój numer telefonu {phoneNumber}
          </div>
          <div className="d-flex mt-4">
            <Button className="px-4 py-2" size="lg" variant="secondary" type="submit" data-testid="save-button">
              <IconWithText
                text="Zapisz"
                iconClassName="uil-check me-2 font-size-22"
                className="font-size-14 fw-bold px-2"
              />
            </Button>
            <Button variant="transparent" onClick={handleEdit}>
              <IconWithText
                text="Anuluj"
                iconClassName="uil-times me-2 font-size-icon-22"
                className="font-size-14 fw-bold text-darker-gray"
              />
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  )
}
