import { Subscription } from '@models/client'

export const SubscriptionKindName = {
  gold: 'złoty',
  silver: 'srebrny',
  brown: 'brązowy',
  gray: 'szary',
}

export const getSubscriptionName = (subscription: Subscription): string =>
  `Pakiet prywatny ${SubscriptionKindName[subscription.kind]}`
