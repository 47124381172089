import * as React from 'react'
import { ReservationRowBanner } from '@modules/reservations/reservation-row-banner'
import { Reservation } from '@models/reservation'

interface Props {
  reservation: Reservation
}

export const CheckOutBandsReturnBanner = ({ reservation }: Props): JSX.Element => (
  <ReservationRowBanner reservation={reservation}>
    <p className="reservation-row-banner__text">Aby dokończyć wymeldowanie udaj się do recepcji i zwróć opaski.</p>
    <p className="font-size-sm text-muted mb-sm-4 mt-sm-2 mb-1">
      Do zobaczenia w <span className="text-nowrap">Holiday Park & Resort</span>
    </p>
  </ReservationRowBanner>
)
