import * as React from 'react'
import { Checkbox } from '@components/controls/checkbox'
import { useFormContext, useWatch } from 'react-hook-form'
import { ClientDetailsProfileRuleKeyword } from '@models/client'
import { StaticRules } from '@helpers/rules'
import { ExpandableText } from '@components/expandable-text'

export const MARKETING_RULES_KEYWORDS = [
  'email_rules',
  'sms_rules',
  'phone_call_rules',
] as ClientDetailsProfileRuleKeyword[]

export const MarketingAgreementSection = (): JSX.Element | null => {
  const { control, setValue } = useFormContext()

  const formMarketingRules = useWatch({ control, name: MARKETING_RULES_KEYWORDS })
  const selectedMarketingRules = formMarketingRules.filter(Boolean)
  const isEachSelected = formMarketingRules.every(Boolean)

  const isAnyMarketingRuleSelected =
    selectedMarketingRules.length > 0 && selectedMarketingRules.length < MARKETING_RULES_KEYWORDS.length

  const handleAllSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked

    MARKETING_RULES_KEYWORDS.forEach(key => setValue(key, newValue))
  }

  React.useEffect(() => {
    setValue('allMarketingRulesSelected', isEachSelected)
  }, [isEachSelected])

  return (
    <>
      <Checkbox
        className="checkbox--small font-size-sm is-muted-label align-items-start"
        name="allMarketingRulesSelected"
        id="allMarketingRulesSelected"
        onChange={handleAllSelection}
        indeterminate={isAnyMarketingRuleSelected}
        label={
          <div className="mt-1">
            Zgody marketingowe <span className="font-size-xs">(zaznacz wszystkie)</span>
            <small className="d-block mt-2 col-12 col-md-7">{StaticRules.marketing_rules}</small>
          </div>
        }
      />

      <div className="ms-3 mt-1">
        <MarketingRule name="email_rules" expandedContent={StaticRules.email_rules}>
          Zgoda na dostarczanie powiadomień i ofert poprzez pocztę elektroniczną.
        </MarketingRule>
        <MarketingRule name="sms_rules" expandedContent={StaticRules.sms_rules}>
          Zgoda na dostarczanie powiadomień i ofert poprzez SMS.
        </MarketingRule>
        <MarketingRule name="phone_call_rules" expandedContent={StaticRules.phone_call_rules}>
          Zgoda na przedstawianie ofert poprzez kontakt telefoniczny.
        </MarketingRule>
      </div>
    </>
  )
}

const MarketingRule = ({
  name,
  expandedContent,
  children,
}: {
  name: ClientDetailsProfileRuleKeyword
  expandedContent: string
  children: React.ReactNode
}) => (
  <Checkbox
    name={name}
    id={name}
    className="checkbox--small font-size-sm fw-normal font-size-sm is-muted-label align-items-start"
    label={
      <div className="mt-1 col-12 col-sm-8">
        <div className="pb-1">{children}</div>
        <div className="font-size-xxs">
          <ExpandableText text={expandedContent} initialHeight={0} />
        </div>
      </div>
    }
  />
)
