import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { IconWithText } from '@components/icon-with-text'
import { ButtonLoading } from '@components/controls/button-loading'
import { useFormRequest } from '@hooks/use-api-request'
import { useSelector } from 'react-redux'
import { selectAccountUrls } from '@store/selectors/app-data-selectors'
import { commonObjectPost } from '@api/basic-requests'
import { useHistory } from 'react-router-dom'
import { NavigationPath } from '@models/routes'

interface FormInputs {
  email: string
}

interface Props {
  onBackToLogin: () => void
}

export const PasswordReset = ({ onBackToLogin }: Props): JSX.Element => {
  const { reset_password_link } = useSelector(selectAccountUrls)

  const [isLinkSent, setLinkIsSent] = React.useState(false)
  const methods = useForm<FormInputs>({ defaultValues: { email: '' } })

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: FormInputs) => {
    await commonObjectPost(reset_password_link, payload)
    setLinkIsSent(true)
  }, methods.setError)

  const history = useHistory()

  const handleBackToRoot = () => {
    history.replace(NavigationPath.Root)
  }

  return (
    <div>
      <h4 className="text-secondary fw-bold text-center mt-5 mb-2">Zapomniałeś hasła?</h4>
      {isLinkSent ? (
        <div>
          <p className="text-center mb-0 font-size-12">Link do resetu hasła został wysłany na podany adres.</p>
          <p className="text-center mb-0 font-size-12">Sprawdź swoją pocztę i podążaj za instrukcjami.</p>

          <span className="text-darker-gray" onClick={handleBackToRoot}>
            <strong className="font-size-sm text-center mt-5 d-block cursor-pointer">Wróć na stronę główną</strong>
          </span>
        </div>
      ) : (
        <>
          <p className="text-center mb-0 font-size-12">Spokojnie, zdarza się najlepszym.</p>
          <p className="text-center mb-4 font-size-12">
            Wpisz adres e-mail, na który mamy wysłać link do resetu hasła.
          </p>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <CustomFormGroup
                label="Wpisz swój adres e-mail:"
                inputName="email"
                formControlProps={{ placeholder: 'Wpisz e-mail', type: 'email' }}
              />

              <ButtonLoading
                className="btn-primary authorization-layout__button"
                type="submit"
                isLoading={isLoading}
                loadingLabel="Wysyłanie..."
              >
                <strong>Wyślij link do resetu hasła</strong>
              </ButtonLoading>
            </form>
            <IconWithText
              onClick={onBackToLogin}
              text="Wróć do logowania"
              iconClassName="uil-user me-2 font-size-lg"
              className="my-4 fw-bold justify-content-center"
            />
          </FormProvider>
        </>
      )}
    </div>
  )
}
