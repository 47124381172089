import { ProgressWithIcon } from '@components/progress-with-icon'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { formatPrice } from '@helpers/price-helper'
import * as clsx from 'clsx'
import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import Decimal from 'decimal.js'

interface PaymentState {
  deadline: string | null
  remaining: string | number
}

interface Props {
  title: string
  payments: PaymentState[]
  total: string
  paid: string
  className?: string
  afterProgress?: React.ReactNode
}

export const PendingReservationPaymentState = ({
  title,
  payments,
  total,
  paid,
  className,
  afterProgress,
}: Props): JSX.Element => {
  const progress = new Decimal(paid).dividedBy(total).times(100).toNumber()
  const progressDisplay = Math.floor(progress)
  const isPaid = progress >= 100

  return (
    <div className={className}>
      <ProgressWithIcon
        progress={progress}
        color={isPaid ? 'success' : 'danger'}
        label={
          <div className={clsx('py-1 d-flex', { 'text-danger': !isPaid, 'text-success': isPaid })}>
            <strong>{title}</strong>
            <span className="ms-1">({progressDisplay}%)</span>
            {afterProgress}
          </div>
        }
        icon={clsx('font-size-xxl me-2', {
          'text-danger uil-times': !isPaid,
          'text-success uil-check': isPaid,
        })}
      >
        <Row className="font-size-11 fw-light mt-1 text-darker-gray ">
          <Col xs={3} className="text-nowrap">
            Wpłacone:
          </Col>
          <Col xs={9}>
            {formatPrice(paid.toString())} z {formatPrice(total)}
          </Col>
          {payments.map((payment, index) => (
            <React.Fragment key={index}>
              {new Decimal(payment.remaining).gt(0) && (
                <>
                  <Col xs={3}>Do zapłaty:</Col>
                  <Col xs={9}>
                    <span className="fw-bold">{formatPrice(payment.remaining)}</span>{' '}
                    {payment.deadline && <span>(do {toDefaultDateFormat(payment.deadline)})</span>}
                  </Col>
                </>
              )}
            </React.Fragment>
          ))}
        </Row>
      </ProgressWithIcon>
    </div>
  )
}
