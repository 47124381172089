import { PaginationResponse } from '@models/index'
import { ClientWalletItem } from '@models/wallet'

export enum WalletActionTypesKeys {
  SET_WALLET_ITEMS = 'SET_WALLET_ITEMS',
}

export interface SetWalletHistoryItemsAction {
  type: WalletActionTypesKeys.SET_WALLET_ITEMS
  payload: PaginationResponse<ClientWalletItem>
}

export type WalletActionsTypes = SetWalletHistoryItemsAction
