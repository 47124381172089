import * as React from 'react'
import { InputHTMLAttributes } from 'react'
import * as clsx from 'clsx'

interface Props extends Partial<InputHTMLAttributes<HTMLInputElement>> {
  onIncrement?: (newValue: number) => void
  onDecrement?: (newValue: number) => void
  children?: React.ReactNode
  inputWrapperClass?: string
}

const inMaxRange = (newValue, props) => props.max === undefined || newValue <= props.max
const inMinRange = (newValue, props) => props.min === undefined || newValue >= props.min
const getIntValue = (val: string | number) => {
  if (typeof val === 'number') return val
  return parseInt(val, 10)
}

export const SpinInput = ({ onIncrement, onDecrement, children, inputWrapperClass, ...props }: Props): JSX.Element => {
  const [value, setValue] = React.useState<number | string>(0)
  const step = props.step ? getIntValue(props.step) : 1

  React.useEffect(() => {
    if (typeof props.value === 'number') {
      setValue(props.value)
    }
  }, [props.value])

  const handleIncrement = () => {
    const newValue = getIntValue(value) + step
    if (!inMaxRange(newValue, props)) return
    if (onIncrement) {
      onIncrement(newValue)
      return
    }

    setValue(newValue)
  }

  const handleDecrement = () => {
    const newValue = getIntValue(value) - step
    if (!inMinRange(newValue, props)) return
    if (onDecrement) {
      onDecrement(newValue)
      return
    }

    setValue(newValue)
  }

  const handleChange = e => {
    const val = e.target.value
    if (val === '') {
      setValue('')
      return
    }

    const newValue = parseInt(val, 10)
    if (!inMinRange(newValue, props) || !inMaxRange(newValue, props)) return
    setValue(newValue)
  }

  return (
    <div className="spin-input__container">
      <i
        role="spinbutton-decrement"
        className={clsx('uil-minus spin-input__minus', {
          'is-disabled': !inMinRange(getIntValue(value) - step, props),
        })}
        onClick={handleDecrement}
      />
      <div className={inputWrapperClass}>
        <input
          type="number"
          value={value}
          onChange={handleChange}
          inputMode="numeric"
          {...props}
          className={clsx('spin-input', props?.className)}
        />
        {children}
      </div>

      <i
        role="spinbutton-increment"
        className={clsx('uil-plus spin-input__plus', { 'is-disabled': !inMaxRange(getIntValue(value) + step, props) })}
        onClick={handleIncrement}
      />
    </div>
  )
}
