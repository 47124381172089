import { toDefaultDateFormat } from '@helpers/date-helper'
import * as clsx from 'clsx'
import * as React from 'react'

interface Props {
  title: string
  subtitle: string | JSX.Element
  date?: string | null
  active?: boolean
}

export const TargetOption = ({ title, subtitle, date, active }: Props): JSX.Element => (
  <div className="d-flex flex-sm-row flex-column justify-content-between">
    <div className="my-3">
      <div className="font-size-xl">
        <strong>{title}</strong>
      </div>
      <div>{subtitle}</div>
    </div>
    {date && (
      <div className="d-flex align-items-center mb-3 mb-sm-0 mt-n2">
        <div className="d-flex flex-sm-column align-items-end font-size-xs">
          <div>Termin płatności </div>
          <div className="fw-bold">do {toDefaultDateFormat(date)}</div>
        </div>
        <div>
          <i className={clsx('uil-info-circle ms-2 me-3', { 'text-primary': !active })} />
        </div>
      </div>
    )}
  </div>
)
