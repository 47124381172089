import * as React from 'react'
import { createArrFromNumber, createHash } from '@helpers/utils'
import { BookingCar, ParkingKind, ReservationImprovement } from '@models/reservation'
import { useSelector } from 'react-redux'
import { selectReservationDetails, selectReservationDetailsCars } from '@store/selectors/reservation-details-selectors'
import { CarPlate } from '@modules/reservations/improvements/kinds/base-improvements/improvement-car-plates-field'

export const useImprovementDetailsParkingPlates = (parkingKind: ParkingKind) => {
  const reservationDetails = useSelector(selectReservationDetails)
  const cars = useSelector(selectReservationDetailsCars)

  const carsForKind = React.useMemo(
    () => cars?.filter((car: BookingCar) => car.kind === parkingKind),
    [cars, parkingKind],
  )

  const getPlates = (
    reservationImprovement: ReservationImprovement | undefined,
    amount: number,
    parkingKind: ParkingKind,
  ) => {
    const parkingImprovements = reservationDetails?.prices.improvements.items.filter(
      item => item.code === reservationImprovement?.code,
    )

    if (!parkingImprovements?.length) return

    const improvementIndex = parkingImprovements.findIndex(
      parkingImprovement => parkingImprovement.id === reservationImprovement?.id,
    )

    if (improvementIndex === -1 || !carsForKind) return

    const platesForImprovement = carsForKind.slice(
      improvementIndex,
      improvementIndex + parkingImprovements[improvementIndex].amount,
    )

    const difference = amount - platesForImprovement.length

    const idsFromDifference = createArrFromNumber(difference).map(createHash)

    const platesFromCars = platesForImprovement.map(car => ({
      id: car.id.toString(),
      register_number: car.register_number,
      isAdded: true,
      kind: parkingKind,
      carId: car.id,
    }))

    const platesFromRegisteredCars = [
      ...platesFromCars,
      ...idsFromDifference.map(id => ({
        id,
        register_number: '',
        isAdded: true,
        kind: parkingKind,
        carId: null,
      })),
    ]

    return {
      platesFromRegisteredCars,
      plateInputs: [...platesForImprovement.map((car: BookingCar) => car.id.toString()), ...idsFromDifference],
    }
  }

  const getRemovedPlate = (improvement: ReservationImprovement, currentPlates: CarPlate[]) => {
    if (!improvement) return null

    const initialPlates = getPlates(improvement, improvement.amount, improvement.code as ParkingKind)

    return initialPlates?.platesFromRegisteredCars.find(
      initial => !currentPlates.some(current => current.carId === initial.carId),
    )
  }

  return {
    getPlates,
    carsForKind,
    getRemovedPlate,
  }
}
