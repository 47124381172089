import { CashbackVoucherDetails } from '@models/client'
import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { formatPriceShort } from '@helpers/price-helper'
import { toDefaultDateFormat } from '@helpers/date-helper'

interface Props {
  voucherDetails: CashbackVoucherDetails
}

export const CashbackVoucherUsedExpandableContentRow = ({ voucherDetails }: Props) => (
  <div className="ps-4 pb-4 col-12 col-sm-8 mt-2">
    <div className="d-flex">
      <IconWithText
        className="col-6"
        iconClassName="uil-bookmark me-2 pt-1 font-size-lg lh-1 align-self-start"
        text={
          <span className="fw-normal">
            Utworzony w rez.: <strong>{voucherDetails.source_booking}</strong>
          </span>
        }
      />
      <IconWithText
        className="col-6"
        iconClassName="uil-bookmark me-2 pt-1 font-size-lg lh-1 align-self-start"
        text={
          <span className="fw-normal d-flex">
            Przypisany do rez.: <strong className="ms-1">{voucherDetails.assign_booking?.reservation_number}</strong>
          </span>
        }
      />
    </div>

    <div className="d-flex mt-1">
      <IconWithText
        className="col-6"
        iconClassName="uil-usd-circle me-2 pt-1 font-size-lg lh-1 align-self-start"
        text={
          <span className="fw-normal">
            Wartość vouchera: <strong>{formatPriceShort(voucherDetails.promotion_amount)}</strong>
          </span>
        }
      />
      <IconWithText
        className="col-6"
        iconClassName="uil-check me-2 pt-1 font-size-lg lh-1 align-self-start"
        text={
          <span className="fw-normal">
            Zamknięty: <strong>{toDefaultDateFormat(voucherDetails.assign_booking?.date_to)}</strong>
          </span>
        }
      />
    </div>
  </div>
)
