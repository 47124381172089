import { formatDate } from '@helpers/date-helper'
import { startOfMonth, startOfWeek, sub } from 'date-fns'

export const dateOptions = [
  {
    value: formatDate(startOfWeek(new Date())),
    label: 'Ostatni tydzień',
  },
  {
    value: formatDate(startOfMonth(new Date())),
    label: 'Ostatni miesiąc',
  },
  {
    value: formatDate(sub(new Date(), { months: 3 })),
    label: 'Ostatnie 3 miesiące',
  },
  {
    value: formatDate(sub(new Date(), { months: 12 })),
    label: 'Ostatnie 12 miesięcy',
  },
  {
    value: 0,
    label: 'Od początku',
  },
]

//operation
export const transferOptions = [
  {
    value: 0,
    label: 'Wszystkie',
  },
  {
    value: 'credit',
    label: 'Pobrania z konta',
  },
  {
    value: 'debit',
    label: 'Wpłaty',
  },
]
