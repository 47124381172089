import { useSelector } from 'react-redux'
import { isAfter, isBefore, isWeekend } from 'date-fns'
import { getCurrentLanguage } from '@i18n/__mocks__/i18n'
import { RootState } from '@store/index'
import { formatDate, getHoursFromDate, parseISODate } from '@helpers/date-helper'

interface CallCenterData {
  isOpen: boolean
  phoneNumber: string
  trimmedPhoneNumber: string
  todayFromHour: string
  todayToHour: string
  saturdayFrom: string
  saturdayTo: string
  sundayFrom: string
  sundayTo: string
  email: string
}

export const useCallCenterData = (): CallCenterData => {
  const companyInfo = useSelector((state: RootState) => state.appDataState.appData.company_info)

  const now = formatDate(new Date())

  const [todayFromDate, todayToDate] = companyInfo.call_center_working_hours[now]
  const [todayFromHour, todayToHour] = [getHoursFromDate(todayFromDate), getHoursFromDate(todayToDate)]

  const [[saturdayFrom, saturdayTo], [sundayFrom, sundayTo]] = Object.values(
    companyInfo.call_center_working_hours,
  ).filter((value: string) => isWeekend(parseISODate(value[0]) || 0)) as [[string, string], [string, string]]

  const phoneNumber = companyInfo.call_center_phone_number[getCurrentLanguage()]
  const trimmedPhoneNumber = phoneNumber.replace(/\s/g, '')
  const email = companyInfo.call_center_email

  const isOpen =
    isAfter(new Date(), parseISODate(todayFromDate) || 0) && isBefore(new Date(), parseISODate(todayToDate) || 0)

  return {
    isOpen,
    phoneNumber,
    todayFromHour,
    todayToHour,
    saturdayFrom,
    saturdayTo,
    sundayFrom,
    sundayTo,
    trimmedPhoneNumber,
    email,
  }
}
