import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import { CustomReactSelect, selectCustomStyles } from '@components/custom-react-select'
import { FormProvider, useForm } from 'react-hook-form'
import * as clsx from 'clsx'
import { useHandleTableFiltersChange } from '@hooks/use-handle-table-filters-change'
import { dateOptions, transferOptions } from './filter-options'
import { WalletHistoryFilterInterface } from '@modules/wallet/wallet-history/index'
import { useDeviceSize } from '@hooks/use-device-size'

interface WalletHistoryFilterProps {
  isFull: boolean
  defaultFilters: WalletHistoryFilterInterface
  filters: WalletHistoryFilterInterface
  setFilters: (props: WalletHistoryFilterInterface) => void
}

export const WalletHistoryFilter = ({
  isFull = false,
  setFilters,
  filters,
  defaultFilters,
}: WalletHistoryFilterProps): JSX.Element => {
  const methods = useForm<WalletHistoryFilterInterface>({
    defaultValues: { ...defaultFilters },
  })
  const { isMobile } = useDeviceSize()

  const handleFilter = payload => setFilters({ ...filters, ...payload })

  useHandleTableFiltersChange(methods.watch, filters, handleFilter)

  return (
    <FormProvider {...methods}>
      <Row className="align-items-center">
        <Col md={4} className="pe-0">
          <p
            className={clsx('text-darker-gray fw-bold font-size-11 mt-2', {
              'mb-0': isFull && !isMobile,
              'mb-1': !isFull || isMobile,
            })}
          >
            {isFull ? 'Transakcje w przedziale:' : 'Najnowsze transakcje'}
          </p>
        </Col>
        {isFull && (
          <>
            <Col md={4} className={clsx({ 'mb-2': isMobile })}>
              <CustomReactSelect
                inputName="created_after"
                options={dateOptions}
                borderless={!isMobile}
                customStyles={selectCustomStyles(isMobile)}
                isSearchable={false}
              />
            </Col>
            <Col md={4} className={clsx({ 'mb-4': isMobile })}>
              <CustomReactSelect
                inputName="operation"
                options={transferOptions}
                borderless={!isMobile}
                isSearchable={false}
                customStyles={selectCustomStyles(isMobile)}
              />
            </Col>
          </>
        )}
      </Row>
    </FormProvider>
  )
}
