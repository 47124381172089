import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { Col, Row } from 'react-bootstrap'
import { PriceWithSpinner } from '@components/price-with-spinner'
import { ParkingKind, ReservationImprovement } from '@models/reservation'

interface ParkingListItem {
  name: string
  price: string
  kind: ParkingKind
  id: string
  improvement: ReservationImprovement
}

interface Props {
  parkingItems: ParkingListItem[]
  onRemove: (id: string, improvement: ReservationImprovement) => void
  isCalculatingPrice: boolean
  isRemoving: boolean
  isRemoveAllowed?: (improvement: ReservationImprovement) => boolean
  isPriceHidden?: boolean
}

export const ReservationDataCompletionParkingList = ({
  parkingItems,
  onRemove,
  isRemoving,
  isCalculatingPrice,
  isRemoveAllowed = () => true,
  isPriceHidden,
}: Props): JSX.Element => {
  const parkingTitle = {
    parking2: '',
    parking_vip: 'gwarantowany',
    parking_large: 'dla dużych pojazdów',
  }

  return (
    <>
      {parkingItems.map((parkingItem: ParkingListItem, index: number) => (
        <Row key={index} className="px-1 py-2">
          <Col className="ps-md-0 d-flex text-darker-gray align-items-center">
            <IconWithText
              text={`Parking ${parkingTitle[parkingItem.kind]} ${parkingItem.name.toUpperCase()}`}
              iconClassName="uil-check text-primary font-size-icon-22 me-3"
            />
            {isRemoveAllowed(parkingItem.improvement) && (
              <button
                disabled={isRemoving}
                onClick={() => onRemove(parkingItem.id, parkingItem.improvement)}
                className="btn d-flex align-items-center justify-content-center p-0 ms-2 shadow-none border-0"
              >
                <i className="uil-trash-alt font-size-icon-18 text-darker-gray" />
              </button>
            )}
          </Col>
          {!isPriceHidden && (
            <Col className="d-flex justify-content-end text-darker-gray">
              <strong>
                <PriceWithSpinner pricePrefix="+" price={parkingItem.price} isLoading={isCalculatingPrice} />
              </strong>
            </Col>
          )}
        </Row>
      ))}
    </>
  )
}
