import * as React from 'react'
import { MeterStateRow } from '@modules/reservations/meters-state-modal/row'
import { ReservationMetersState } from '@models/reservation'

interface Props {
  metersState: ReservationMetersState
}
export const MetersStateTable = ({ metersState }: Props): JSX.Element => (
  <div>
    <label className="text-darker-gray fw-bold">Aktualny stan liczników:</label>
    <table className="full-modal-width">
      <MeterStateRow
        icon="uil-tear"
        label="Licznik wody"
        readingValue={metersState.water}
        costs={metersState.water_costs_total}
        readingDate={metersState.water_reading_date}
        unit="m³"
      />
      <MeterStateRow
        icon="uil-raindrops"
        label="Licznik wody ciepłej"
        readingValue={metersState.hot_water}
        costs={metersState.hot_water_costs_total}
        readingDate={metersState.hot_water_reading_date}
        unit="m³"
      />
      <MeterStateRow
        icon="uil-bolt"
        label="Licznik prądu"
        readingValue={metersState.power}
        costs={metersState.power_costs_total}
        readingDate={metersState.power_reading_date}
        unit="kWh"
      />
      <MeterStateRow
        icon="uil-temperature-three-quarter"
        label="Licznik ogrzewania"
        readingValue={metersState.heat}
        costs={metersState.heat_costs_total}
        readingDate={metersState.heat_reading_date}
        unit="GJ"
      />
    </table>
  </div>
)
