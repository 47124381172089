import * as React from 'react'
import { BaseImprovements } from '@modules/reservations/improvements/kinds/base-improvements/base-improvements'
import { getGroupedImprovements, sortImprovementByPosition } from '@helpers/improvement-helper'
import { Vip } from '@modules/reservations/improvements/kinds/vip/vip'
import { ResortImprovement } from '@models/reservation'

interface Props {
  improvements: ResortImprovement[]
}

export const CheckInImprovementsList = ({ improvements }: Props) => {
  const groupedImprovements = React.useMemo(() => getGroupedImprovements(improvements), [improvements])
  const resortImprovements = sortImprovementByPosition(groupedImprovements.restImprovements)

  return (
    <div className="py-3">
      <div className="text-center font-size-xxl text-light-black fw-bold py-3">
        {improvements.length ? 'Wybierz ulepszenia:' : 'Wszystkie możliwe ulepszenia zostały dodane.'}
      </div>
      <div className="d-flex gap-4 flex-column">
        {!!groupedImprovements.vipImprovements.length && (
          <Vip vipImprovement={groupedImprovements.vipImprovements[0]} />
        )}
        {!!resortImprovements.length && <BaseImprovements resortImprovements={resortImprovements} />}
      </div>
    </div>
  )
}
