import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { PackageVip } from '@models/client'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import { getPackageVipName } from '@modules/special-products/packages-vip/utils'

interface Props {
  packageVip: PackageVip
}

export const PackageVipExpandableRowHeaderAdded = ({ packageVip }: Props): JSX.Element => (
  <div className="d-flex flex-wrap flex-column flex-md-row align-items-center px-2">
    <IconWithText
      className="col-12 mb-1 col-md-4"
      iconClassName="uil-check-square me-2 font-size-lg lh-1"
      text={
        <span>
          {getPackageVipName(packageVip)} <span className="fw-light">({packageVip.number})</span>
        </span>
      }
    />
    {packageVip.booking && (
      <>
        <IconWithText
          className="col-6 d-none d-md-block mb-1"
          iconClassName="uil-check me-2 font-size-lg lh-1"
          text={<span>Wykorzystany: {toDefaultDateTimeFormat(packageVip.booking.created_at)}</span>}
        />
        <IconWithText
          className="col-12 col-md-4"
          iconClassName="uil-location-point me-2 font-size-lg lh-1"
          text={packageVip.booking.resort}
        />
        <IconWithText
          iconClassName="me-2 uil-bookmark font-size-lg lh-1"
          className="col-4 d-none d-md-block"
          text={<span>Nr. rez.: {packageVip.booking.reservation_number}</span>}
        />
      </>
    )}
  </div>
)
