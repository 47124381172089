import { createSelector } from 'reselect'
import { RootState } from '@store/index'
import { GastroCardSelectOption, Reservation, ReservationStatus } from '@models/reservation'
import { GastroCard } from '@models/client'
import { AccommodationType, Resort } from '@models/app-data'
import * as memoize from 'lodash.memoize'
import { reservationsSelector } from '@store/slices/reservations-slice'
import { createCombinedDateWithTime, parseISODate } from '@helpers/date-helper'
import { compareAsc, compareDesc } from 'date-fns'

export const closeReservationsSelector = createSelector(reservationsSelector, (reservations: Reservation[]) =>
  reservations
    .filter(reservation => reservation.status === ReservationStatus.close)
    .sort((a, b) => compareDesc(parseISODate(a.date_to) as Date, parseISODate(b.date_to) as Date)),
)

export const pendingReservationsSelector = createSelector(reservationsSelector, (reservations: Reservation[]) =>
  reservations
    .sort((a, b) =>
      compareAsc(
        createCombinedDateWithTime(a.date_from, a.arrival_time),
        createCombinedDateWithTime(b.date_from, b.arrival_time),
      ),
    )
    .filter(
      ({ status, checked_in }) =>
        [ReservationStatus.initial, ReservationStatus.confirmed].includes(status) && !checked_in,
    ),
)

export const checkedInReservationsSelector = createSelector(reservationsSelector, (reservations: Reservation[]) =>
  reservations
    .sort((a, b) =>
      compareAsc(
        createCombinedDateWithTime(a.date_to, a.departure_time),
        createCombinedDateWithTime(b.date_to, b.departure_time),
      ),
    )
    .filter(
      ({ status, checked_in }) =>
        [ReservationStatus.initial, ReservationStatus.confirmed].includes(status) && checked_in,
    ),
)

export const accommodationTypeSelector = createSelector(
  (state: RootState) => state.appDataState.appData.accommodation_types,
  (accommodationTypes: AccommodationType[]) => memoize(id => accommodationTypes.find(item => item.id == id)),
)

export const resortTypeSelector = createSelector(
  (state: RootState) => state.appDataState.appData.resorts,
  (resorts: Resort[]) => memoize(id => resorts.find(item => item.id == id)),
)

export const gastroCardsOptionsSelector = createSelector(
  (state: RootState) => state.clientState.details.gastro_cards,
  (gastroCards: GastroCard[]): GastroCardSelectOption[] =>
    gastroCards.map(card => ({
      available_amount: card.available_amount,
      start_amount: card.start_amount,
      code: card.code,
      value: card.id,
      label: card.code,
      kind: card.kind,
      active_after: card.active_after,
      expire_after: card.expire_after,
    })),
)
