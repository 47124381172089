import * as React from 'react'
import * as clsx from 'clsx'
import { Button, Modal } from 'react-bootstrap'
import { useApiRequest } from '@hooks/use-api-request'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/selectors/client-selectors'
import { TextWithSpinner } from '@components/text-with-spinner'
import { useHistory } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { commonObjectDelete } from '@api/basic-requests'

interface Props {
  handleClose: () => void
}

export const AccountDeleteConfirmationModal = ({ handleClose }: Props): JSX.Element => {
  const [isRemoved, setIsRemoved] = React.useState(false)

  const clientDetails = useSelector(selectClientDetails)
  const history = useHistory()

  const { action: handleDelete, isLoading } = useApiRequest(async () => {
    await commonObjectDelete(clientDetails.urls.delete)
    setIsRemoved(true)
  })

  const moveToMainPage = () => {
    handleClose()
    history.replace(NavigationPath.Login)
  }

  return (
    <Modal.Body>
      <div className="p-2 d-flex flex-column justify-content-center text-white">
        <div className="position-relative mx-auto mt-4 mb-5">
          <div
            className="position-absolute start-50 top-50 translate-middle rounded-circle border border-2 border-white"
            style={{ width: '70px', height: '70px' }}
          />
          <i className={clsx('font-size-3xl mt-2 mb-4', isRemoved ? 'uil-check' : 'uil-times')} />
        </div>
        {isRemoved ? (
          <>
            <p className="fw-bold font-size-lg col-8 mx-auto text-center">Twoje Konto zostało usunięte.</p>
            <p className="col-8 mx-auto text-center mb-0">Mamy nadzieje, że szybko do nas wrócisz :)</p>

            <Button
              variant="primary"
              onClick={moveToMainPage}
              className="mx-auto text-white px-5 btn-tall my-4 font-weight-semibold"
            >
              Przejdź do strony głównej
            </Button>

            <p className="mx-auto text-center col-8">
              Do zobaczenia w <span className="text-nowrap">Holiday Park & Resort</span>
            </p>
            <img
              src={require('@assets/images/logo_holiday_white.svg')}
              alt="Holiday park logo"
              className="mx-auto d-block"
              style={{ maxHeight: '45px' }}
            />
          </>
        ) : (
          <>
            <p className="fw-bold font-size-lg col-8 mx-auto text-center">
              Czy na pewno chcesz usunąć swoje Konto Gościa?
            </p>
            <p className="col-8 mx-auto text-center">Operacja nie może być cofnięta</p>
            <Button
              variant="primary"
              onClick={handleClose}
              className="mx-auto text-white px-5 btn-tall mt-3 font-weight-semibold"
            >
              Anuluj usuwanie konta
            </Button>

            <TextWithSpinner
              isLoading={isLoading}
              loadingText="Usuwanie..."
              spinnerColor="white"
              text="Usuń konto na zawsze"
              iconClassName="uil-trash-alt font-size-xl me-2"
              className="mt-4 justify-content-center"
              onClick={handleDelete}
            />
          </>
        )}
      </div>
    </Modal.Body>
  )
}
