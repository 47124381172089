import * as React from 'react'
import { useGetCashbackVouchersQuery } from '@api/cashback-vouchers'
import { useAppSelector } from '@store/index'
import { selectClientDetails } from '@store/selectors/client-selectors'
import { ReservationDetails } from '@models/reservation'
import { CashbackVoucher } from '@models/client'
import { CashbackVoucherInGenerationExpandableHeader } from '@modules/special-products/cashback-vouchers/expandable-row-header/cashback-voucher-in-generation-expandable-header'
import { NavigationPath } from '@models/routes'
import { useHistory } from 'react-router-dom'
import { useDeviceSize } from '@hooks/use-device-size'

interface Props {
  reservationDetails: ReservationDetails
}

export const ReservationCashbackVoucherInGeneration = ({ reservationDetails }: Props): JSX.Element | null => {
  const { isMobile } = useDeviceSize()
  const { urls } = useAppSelector(selectClientDetails)
  const history = useHistory()

  const { data: cashbackVouchers = [] } = useGetCashbackVouchersQuery(urls.cashback_vouchers)

  const cashbackForReservationInGeneration = cashbackVouchers.find(
    (cashback: CashbackVoucher) =>
      cashback.status === 'generate' && cashback.source_booking === reservationDetails.reservation_number,
  )

  if (!cashbackForReservationInGeneration) return null

  const moveToCashbackVoucherDetailsView = () => {
    if (!isMobile) return
    history.push(NavigationPath.CashbackVoucherDetails.replaceId(cashbackForReservationInGeneration.id))
  }

  return (
    <div
      className="cashback-voucher__expandable-row mt-sm-n4 border rounded is-generating mb-4 ps-lg-2 pe-lg-5 pe-2 d-flex d-sm-block align-items-center pt-3 pt-lg-0 pb-4 pb-lg-0"
      onClick={moveToCashbackVoucherDetailsView}
    >
      <CashbackVoucherInGenerationExpandableHeader
        voucher={cashbackForReservationInGeneration}
        withStatus={false}
        withReservationLink={false}
      />
      <i className="uil-angle-right font-size-icon-22 d-sm-none" />
    </div>
  )
}
