import { ClientDetails, ClientProfileGuest } from '@models/client'
import { RootState } from '@store/index'
import axios from 'axios'

export interface CreateClientProfileGuestPayload {
  birthday?: string
  name: string
  type: string
  email?: string
  phone?: string
}
export const createClientProfileGuestRequest = async (
  state: RootState,
  payload: CreateClientProfileGuestPayload,
): Promise<ClientDetails> => {
  const url = state.clientState.details.urls.guests
  const { data } = await axios.post(url, payload)

  return data
}

export interface UpdateClientProfileGuestPayload {
  birthday?: string
  name: string
  type: string
  email?: string
  phone?: string
}
export const updateClientProfileGuestRequest = async (
  guest: ClientProfileGuest,
  payload: UpdateClientProfileGuestPayload,
): Promise<ClientDetails> => {
  const { data } = await axios.put(guest.urls.details, payload)

  return data
}

export const deleteClientProfileGuestRequest = async (guest: ClientProfileGuest): Promise<ClientDetails> => {
  const { data } = await axios.delete(guest.urls.details)

  return data
}
