import * as React from 'react'
import { CashbackVoucherDetails } from '@models/client'
import { ReservationDetailsMobileRow } from '@modules/reservations/pending/mobile/reservation-details-mobile-row'
import { SpecialProductStatus, StatusVariant } from '@modules/special-products/special-product-status'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { formatPriceShort } from '@helpers/price-helper'

interface Props {
  voucher: CashbackVoucherDetails
}

export const CashbackVoucherDetailsBaseInfo = ({ voucher }: Props): JSX.Element => (
  <div className="mx-n2 border-bottom">
    <ReservationDetailsMobileRow icon="ps-4" title="Status vouchera:">
      <SpecialProductStatus variant={StatusVariant[voucher.status]} text={voucher.status_display} />
    </ReservationDetailsMobileRow>

    {['active', 'active_assign', 'used'].includes(voucher.status) && (
      <>
        <ReservationDetailsMobileRow icon="uil-bookmark" title="Utworzony w rez.:">
          {voucher.source_booking}
        </ReservationDetailsMobileRow>
        <ReservationDetailsMobileRow icon="uil-bookmark" title="Przypisany do rez.:">
          {voucher.assign_booking?.reservation_number ?? 'Brak przypisania'}
        </ReservationDetailsMobileRow>
        <ReservationDetailsMobileRow icon="uil-usd-circle" title="Wartość vouchera:">
          {formatPriceShort(voucher.promotion_amount)}
        </ReservationDetailsMobileRow>
      </>
    )}

    {voucher.status === 'active_assign' && (
      <ReservationDetailsMobileRow icon="uil-calender" title="Ważny do:">
        {toDefaultDateFormat(voucher.assign_booking?.date_to)}
      </ReservationDetailsMobileRow>
    )}

    {voucher.status === 'used' && (
      <ReservationDetailsMobileRow icon="uil-check" title="Zamknięty:">
        {toDefaultDateFormat(voucher.assign_booking?.date_to)}
      </ReservationDetailsMobileRow>
    )}
  </div>
)
