import * as React from 'react'
import { ImprovementCard } from '@modules/reservations/improvements/card/improvement-card'
import { FeedingPricesBlock } from '@modules/reservations/improvements/kinds/feedings/feeding-prices-block'
import { SubscriptionDiscountLabel } from '@components/labels/subscription-discount-label'

interface Props {
  onAction: () => void
}

export const FeedingCard = ({ onAction }: Props): JSX.Element => (
  <ImprovementCard
    onRightActionClick={onAction}
    title={
      <div className="d-flex justify-content-sm-between align-items-sm-center flex-sm-row flex-column">
        <strong className="d-block mb-2 mb-sm-0">Wybierz wyżywienie</strong>
        <SubscriptionDiscountLabel />
      </div>
    }
    image={require('@assets/images/feeding.webp')}
    cardClassName="is-md-full-width"
  >
    <p>
      Restauracja, kawiarnia i bistro w <span className="text-nowrap">Holiday Park &amp; Resort</span> oferuje
      odwiedzającym bogatą ofertę wyżywienia i pełną gamę napojów. Dodatkowo miejsca te cechuje wyjątkowa atmosfera z
      niepowtarzalnym widokiem na cały ośrodek.
    </p>
    <FeedingPricesBlock />
  </ImprovementCard>
)
