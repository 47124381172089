import { CashbackVoucherDetails } from '@models/client'
import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { IconWithText } from '@components/icon-with-text'
import { useCashbackVoucherRules } from '@modules/special-products/cashback-vouchers/use-cashback-voucher-rules'

interface Props {
  voucherDetails: CashbackVoucherDetails
}

export const CashbackVoucherInGenerationExpandableContentRow = ({ voucherDetails }: Props) => {
  const showRule = useCashbackVoucherRules()

  const [showPurchaseHistoryModal] = useModal('CashbackVoucherPurchaseHistoryModal', {
    voucher: voucherDetails,
    title: (
      <span className="fw-normal">
        <strong>Historia zakupów</strong>{' '}
        <span className="d-none d-sm-inline">(rezerwacja {voucherDetails.source_booking})</span>
      </span>
    ),
  })

  return (
    <div className="ps-4 pb-4">
      <div className="fw-normal font-size-sm mt-n3">
        Wartość vouchera będzie wynosić 20% kwoty wydanej na automatach podczas obecnego pobytu.
      </div>
      <div className="d-flex mt-3">
        <IconWithText
          text="Historia zakupów"
          iconClassName="uil-receipt me-2 font-size-icon-18"
          className="text-muted fw-bold cursor-pointer me-5"
          onClick={showPurchaseHistoryModal}
        />

        <IconWithText
          text="Szczegóły oferty dostępne w regulaminie"
          iconClassName="uil-file-check-alt me-2 font-size-icon-18"
          className="text-muted fw-bold cursor-pointer"
          onClick={showRule}
        />
      </div>
    </div>
  )
}
