import * as React from 'react'
import { formatPrice } from '@helpers/price-helper'
import { IconSolo } from '@components/icon-solo'
import * as clsx from 'clsx'
import { Collapse } from 'react-bootstrap'
import { useToggle } from 'rooks'

interface Props {
  titleColumn: string
  priceColumn: string | number
  children: React.ReactNode
  wrapperClassName?: string
}

export const ReservationReceiptModalCollapsableRow = ({
  titleColumn,
  priceColumn,
  children,
  wrapperClassName,
}: Props): JSX.Element => {
  const [isOpen, setIsOpen] = useToggle(false)

  const handleOpen = React.useCallback(() => setIsOpen(state => !state), [])

  return (
    <div className={clsx('row mx-0 font-size-sm bg-light text-light-gray ps-4 py-3', wrapperClassName)}>
      <div className="col-8 d-flex gap-2">
        {titleColumn}
        <IconSolo
          iconClassName={clsx('font-size-icon-18', {
            'uil-angle-up': isOpen,
            'uil-angle-down': !isOpen,
          })}
          onClick={handleOpen}
        />
      </div>
      <div className="col-2" />
      <div className="col-2 d-flex justify-content-end px-0 font-weight-semibold">{formatPrice(priceColumn)}</div>
      <Collapse in={isOpen}>
        <div className="px-0">{children}</div>
      </Collapse>
    </div>
  )
}
