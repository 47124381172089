import { RootState } from '@store/index'
import { createSelector } from 'reselect'
import { AppData, AppDataAccountUrls, Resort } from '@models/app-data'
import { ReservationDetails } from '@models/reservation'
import { CouponType } from '@models/coupons'

export const selectAppData = (state: RootState): AppData => state.appDataState.appData

export const selectResortForReservationDetails = createSelector(
  (state: RootState) => state.appDataState.appData.resorts,
  (state: RootState) => state.reservationsState.reservationDetails,
  (resorts: Resort[], reservationDetails: ReservationDetails | undefined) =>
    resorts.find((resort: Resort) => resort.id === reservationDetails?.resort_id),
)

export const selectAccountUrls = (state: RootState): AppDataAccountUrls => state.appDataState.appData.urls.account
export const selectCouponTypes = (state: RootState): CouponType[] => state.appDataState.appData.coupon_types
