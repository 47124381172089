import { formatDate, formatDaysDuration, toDefaultDateFormat } from '@helpers/date-helper'
import * as React from 'react'
import { ReservationDetails } from '@models/reservation'

interface Props {
  reservationDetails: ReservationDetails
}

export const FormattedDepartureDate = ({ reservationDetails }: Props): JSX.Element => (
  <div className="d-flex">
    <div className="fw-normal">({formatDaysDuration(reservationDetails.date_from, reservationDetails.date_to)})</div>
    <div className="mx-1">{formatDate(reservationDetails.date_to, 'EEEEEE')}</div>
    <div>{toDefaultDateFormat(reservationDetails.date_to)}</div>
  </div>
)
