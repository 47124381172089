import * as React from 'react'
import * as clsx from 'clsx'
import { ModalBody } from 'react-bootstrap'
import { ContentLoader } from '@components/loaders/content-loader'
import { formatPrice } from '@helpers/price-helper'
import { ModalFooter } from '@components/modals/modal-footer'
import { CashbackVoucherDetails } from '@models/client'
import { useDeviceSize } from '@hooks/use-device-size'
import { sumDecimalArray } from '@helpers/utils'
import Decimal from 'decimal.js'
import { CashbackVoucherPurchaseHistoryDesktopTable } from '@modules/special-products/cashback-vouchers/history-modal/cashback-voucher-purchase-history-desktop-table'
import { CashbackVoucherPurchaseHistoryTableMobile } from '@modules/special-products/cashback-vouchers/history-modal/cashback-voucher-purchase-history-table-mobile'
import { ProgressWithIcon } from '@components/progress-with-icon'

interface Props {
  voucher: CashbackVoucherDetails
  handleClose: () => void
}

export const CashbackVoucherPurchaseHistoryModal = ({ voucher, handleClose }: Props): JSX.Element => {
  const { isMobile } = useDeviceSize()

  const totalAmount = sumDecimalArray(voucher.wallet.items.map(item => new Decimal(item.amount))).toString()

  return (
    <>
      <ModalBody className="modal-scrollable">
        <ContentLoader isLoading={false}>
          {isMobile ? (
            <CashbackVoucherPurchaseHistoryTableMobile
              items={voucher.wallet.items}
              sourceBooking={voucher.source_booking}
            />
          ) : (
            <CashbackVoucherPurchaseHistoryDesktopTable items={voucher.wallet.items} />
          )}
        </ContentLoader>
        <div
          className={clsx('d-flex justify-content-end align-items-center flex-nowrap text-navy pt-3 fw-bold', {
            'font-size-xl justify-content-between pt-0': isMobile,
          })}
        >
          <p className="font-size-lg">Zakupy łącznie:</p>
          <p className="ps-5 font-size-xl pe-2">{formatPrice(totalAmount)}</p>
        </div>

        <div className="cashback-voucher__expandable-row mt-2 border rounded is-generating p-3">
          <ProgressWithIcon
            progress={75}
            color="success"
            icon="font-size-xxl px-1 text-muted"
            label={
              <div className="font-size-sm ms-auto mb-1 w-100 text-md-end">
                <span className="d-lg-inline-block d-none pe-1">Dzięki zakupom </span>na{' '}
                <strong>Voucherze rePLAY! {voucher.number}</strong> zebrałeś już{' '}
                <strong className="ps-3 d-block d-md-inline text-end mt-2">
                  {formatPrice(voucher.promotion_amount)}
                </strong>
              </div>
            }
          />
        </div>
      </ModalBody>
      <ModalFooter withCancelButton withSubmit={false} onClose={handleClose} cancelLabel="Zamknij" />
    </>
  )
}
