import * as React from 'react'
import { SectionTitle } from '@components/section-title'
import { CheckCardFormControl } from '@components/form-controls/check-card-form-control'
import { TargetOption } from '@modules/reservations/pending/payment/modal/target-option'
import { FormProvider, useForm } from 'react-hook-form'
import { CheckInImprovements } from '@modules/reservations/check-in/steps/improvements/improvements'
import { CheckInImprovementsList } from '@modules/reservations/check-in/steps/improvements/improvements-list'
import { useSelector } from 'react-redux'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import { selectResortDetails } from '@store/selectors/reservations-selectors'
import {
  createSingleFeedingImprovement,
  getSelectableImprovements,
  isFeeding,
  isPaidByVip,
  sortImprovementByPosition,
} from '@helpers/improvement-helper'
import { useFeeding } from '@hooks/use-feeding'
import { ImprovementStepMode } from '@modules/reservations/check-in/check-in-modal'
import { NextStepButton } from '@components/next-step-button'
import { isFeedingAdded } from '@modules/reservations/pending/data-completion-modal/guests/utils'
import { createCombinedDateWithTime } from '@helpers/date-helper'

interface FormInputs {
  improvements?: 'yes' | 'no'
  feeding?: 'yes' | 'no'
}

interface Props {
  mode: ImprovementStepMode
  onModeChange: (mode: ImprovementStepMode) => void
  onNextStep?: () => void
}
export const ImprovementsStep = ({ mode, onModeChange, onNextStep }: Props): JSX.Element => {
  const methods = useForm<FormInputs>({
    defaultValues: {
      improvements: 'no',
      feeding: 'no',
    },
  })

  const reservationDetails = useSelector(selectReservationDetails)
  const resortDetails = useSelector(selectResortDetails)
  const { isFeedingAvailable, showFeedingDetailsModal } = useFeeding(resortDetails)

  const improvements = React.useMemo(() => {
    if (!reservationDetails) return []
    return sortImprovementByPosition([
      ...reservationDetails.prices.improvements.items.filter(improvement => !isPaidByVip(improvement)),
      ...(reservationDetails.prices.feeding.items.length
        ? [createSingleFeedingImprovement(reservationDetails.prices.feeding)]
        : []),
    ])
  }, [reservationDetails])

  const improvementsToAdd = React.useMemo(() => {
    if (!resortDetails || !reservationDetails) return []

    return getSelectableImprovements(resortDetails.improvements, reservationDetails).filter(
      improvement => !isFeeding(improvement),
    )
  }, [resortDetails?.improvements, reservationDetails?.prices?.improvements])

  const handleAddImprovements = () => {
    onModeChange(ImprovementStepMode.improvements)
  }

  const handleBackToPrompt = () => {
    onModeChange(ImprovementStepMode.prompt)
  }

  const isFeedingAlreadyAdded = isFeedingAdded(reservationDetails)

  React.useEffect(() => {
    if (!isFeedingAlreadyAdded) {
      methods.setValue('feeding', 'no')
    }
  }, [isFeedingAlreadyAdded])

  return (
    <div className="pb-5">
      <SectionTitle
        title="Ulepszenia dla rezerwacji"
        subtitle="Lista ulepszeń dodanych do rezerwacji"
        className="text-center text-md-start"
      />
      {reservationDetails && (
        <CheckInImprovements
          improvements={improvements}
          reservationToken={reservationDetails.token}
          reservationStartDate={createCombinedDateWithTime(
            reservationDetails.date_from,
            reservationDetails.arrival_time,
          )}
        />
      )}
      <FormProvider {...methods}>
        {mode === ImprovementStepMode.prompt && !!improvementsToAdd.length && (
          <>
            <SectionTitle title="Czy chcesz dodać nowe ulepszenia?" navy leftAlign />
            <div className="d-flex flex-column col-md-7">
              <CheckCardFormControl
                fieldName="improvements"
                radioValue="yes"
                content={() => <TargetOption title="Tak, chcę dodać" subtitle="Zobacz listę ulepszeń do wyboru" />}
                preventDeselect
                onChange={handleAddImprovements}
                className="mb-2"
              />
              <CheckCardFormControl
                fieldName="improvements"
                radioValue="no"
                content={() => <TargetOption title="Nie, dziękuję" subtitle="Możesz dodać ulepszenia podczas pobytu" />}
                preventDeselect
              />
            </div>
            {isFeedingAvailable && !isFeedingAlreadyAdded && (
              <>
                <SectionTitle title="Czy chcesz dodać wyżywienie?" navy leftAlign />
                <div className="d-flex flex-column col-md-7">
                  <CheckCardFormControl
                    fieldName="feeding"
                    radioValue="yes"
                    content={() => (
                      <TargetOption title="Tak, chcę dodać" subtitle="Zobacz opcje wyżywienia do wyboru" />
                    )}
                    preventDeselect
                    onChange={showFeedingDetailsModal}
                    className="mb-2"
                  />
                  <CheckCardFormControl
                    fieldName="feeding"
                    radioValue="no"
                    content={() => (
                      <TargetOption title="Nie, dziękuję" subtitle="Możesz dodać wyżywienie podczas pobytu" />
                    )}
                    preventDeselect
                  />
                </div>
              </>
            )}
          </>
        )}
        {mode === ImprovementStepMode.improvements && (
          <>
            <CheckInImprovementsList improvements={improvementsToAdd} />
            <button
              onClick={handleBackToPrompt}
              className="btn rounded-0 w-100 p-3 position-fixed bottom-0 start-0 end-0 d-flex justify-content-center bg-secondary d-md-none"
            >
              <strong className="text-white">Gotowe</strong>
            </button>
          </>
        )}
      </FormProvider>
      {mode === ImprovementStepMode.prompt && !!onNextStep && <NextStepButton onClick={onNextStep} />}
    </div>
  )
}
