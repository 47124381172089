import { ReservationReceiptModalRow } from '@modules/reservations/modals/reservation-receipt-modal-row'
import { ReservationReceiptModalImprovementsRow } from '@modules/reservations/modals/reservation-receipt-modal-improvements-row'
import * as React from 'react'
import { ReservationReceiptModalHeader } from '@modules/reservations/modals/reservation-receipt-modal-header'
import { ExtraCharge, Feeding, Guest, ReservationDetails } from '@models/reservation'
import declination from '@helpers/declination'
import { getStayLength, toDefaultDateFormat, toShortDateFormat } from '@helpers/date-helper'
import { ReservationReceiptModalBox } from '@modules/reservations/modals/reservation-receipt-modal-box'
import { BillMedia, ReservationBill } from '@models/bill'
import Decimal from 'decimal.js'
import { sumDecimalArray } from '@helpers/utils'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/selectors/client-selectors'

interface Props {
  bill: ReservationBill
  reservationDetails: ReservationDetails
}

export const ReservationReceiptModalStaySummary = ({ bill, reservationDetails }: Props): JSX.Element => {
  const { profile: clientProfile } = useSelector(selectClientDetails)

  const totalPrice = new Decimal(bill.media.total_price_brutto)
    .plus(reservationDetails.prices.residence.total_price_brutto)
    .plus(reservationDetails.prices.feeding.total_price_brutto)
    .plus(reservationDetails.prices.warranty_price_brutto)
    .plus(reservationDetails.prices.extra_charges.total_price_brutto)
    .plus(bill.climatic.total_price_brutto)
    .plus(bill.service_charge_brutto)
    .plus(bill.improvements.total_price_brutto)
    .toString()

  const getPriceForGuest = (guest: Guest) =>
    sumDecimalArray(
      reservationDetails.prices.feeding.items
        .filter((feeding: Feeding) => feeding.guest_id === guest.id)
        .map((feeding: Feeding) => new Decimal(feeding.price_brutto)),
    ).toString()

  return (
    <div className="receipt-box py-2">
      <div className="font-size-xl fw-bold text-muted my-2 px-2">1. Pobyt podsumowanie</div>
      <ReservationReceiptModalHeader />

      <ReservationReceiptModalRow
        titleColumn={`Pobyt ${reservationDetails.guests.length} ${declination.person(
          reservationDetails.guests.length,
        )} w dniach  ${toShortDateFormat(reservationDetails.date_from)} - ${toDefaultDateFormat(
          reservationDetails.date_to,
        )} (${getStayLength(reservationDetails.date_from, reservationDetails.date_to)})`}
        dateColumn={reservationDetails.created_at}
        priceColumn={reservationDetails.prices.residence.total_price_brutto}
      />
      <ReservationReceiptModalBox title="Ulepszenia" total={bill.improvements.total_price_brutto}>
        <ReservationReceiptModalImprovementsRow improvements={bill.improvements.items} />
      </ReservationReceiptModalBox>

      {!!reservationDetails.guests.length && !!reservationDetails.prices.feeding.items.length && (
        <ReservationReceiptModalBox title="Wyżywienie" total={reservationDetails.prices.feeding.total_price_brutto}>
          {reservationDetails.guests.map((guest: Guest) => (
            <ReservationReceiptModalRow
              key={guest.id}
              titleColumn={guest.name}
              priceColumn={getPriceForGuest(guest)}
              indent
            />
          ))}
        </ReservationReceiptModalBox>
      )}

      {!!reservationDetails.prices.extra_charges.items.length && (
        <ReservationReceiptModalBox
          title="Dodatkowe opłaty"
          total={reservationDetails.prices.extra_charges.total_price_brutto}
        >
          {reservationDetails.prices.extra_charges.items.map((extraCharge: ExtraCharge) => (
            <ReservationReceiptModalRow
              key={extraCharge.id}
              dateColumn={extraCharge.created}
              titleColumn={extraCharge.name}
              priceColumn={extraCharge.price_brutto}
              indent
            />
          ))}
        </ReservationReceiptModalBox>
      )}

      {reservationDetails.warranty && (
        <ReservationReceiptModalRow
          className="font-size-md fw-bold"
          titleColumn="Opcja rezygnacji"
          priceColumn={reservationDetails.prices.warranty_price_brutto}
        />
      )}

      <ReservationReceiptModalBox
        title={clientProfile.skip_service_charge ? 'Opłaty użytkowe' : 'Opłaty za media'}
        total={bill.media.total_price_brutto}
      >
        {bill.media.items.map((media: BillMedia) => (
          <ReservationReceiptModalRow
            key={media.name}
            titleColumn={media.name}
            priceColumn={media.price_brutto}
            indent
          />
        ))}
      </ReservationReceiptModalBox>
      <ReservationReceiptModalRow titleColumn="Opłata klimatyczna" priceColumn={bill.climatic.total_price_brutto} />
      <ReservationReceiptModalRow
        titleColumn="Opłata eksploatacyjna za części wspólne"
        priceColumn={bill.service_charge_brutto}
      />
      <ReservationReceiptModalRow titleColumn="Naliczenia razem" priceColumn={totalPrice} variant="negative" />
    </div>
  )
}
