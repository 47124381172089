import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Col, ModalBody, Row } from 'react-bootstrap'
import { Checkbox } from '@components/controls/checkbox'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { ModalFooter } from '@components/modals/modal-footer'
import { useSelector } from 'react-redux'
import { walletTopUpUrlSelector } from '@store/selectors/client-selectors'
import { usePaymentModal } from '@hooks/use-payment-modal'
import { useActivePaymentMethods } from '@hooks/use-active-payment-methods'

interface FormInputs {
  amount: string
  agreement: boolean
}

interface Props {
  handleClose: () => void
}

export const TopUpBalanceModal = ({ handleClose }: Props): JSX.Element => {
  const paymentMethods = useActivePaymentMethods()
  const methods = useForm<FormInputs>({
    defaultValues: {
      amount: '',
      agreement: false,
    },
  })
  const walletTopUpUrl = useSelector(walletTopUpUrlSelector)

  const agreementCheckbox = methods.watch('agreement')
  const amount = methods.watch('amount')

  const showPaymentModal = usePaymentModal({
    paymentUrl: walletTopUpUrl,
    payload: { amount, source: 'client-panel-app-wallet-top-up' },
    onClose: handleClose,
  })

  return (
    <>
      <ModalBody className="modal-scrollable text-muted">
        <FormProvider {...methods}>
          <p>
            Wpłać dodatkowe środki do portfela.
            <br /> Korzystamy z bezpiecznych płatności {paymentMethods[0].provider_name}, aby Twoje dane bankowe były
            bezpieczne.
          </p>
          <p className="fw-bold">Jaką kwotę chcesz wpłacić?</p>
          <Row className="align-items-center">
            <Col>
              <CustomFormGroup
                inputName="amount"
                formControlProps={{ type: 'text' }}
                formGroupProps={{ className: 'mt-0' }}
              />
            </Col>
            <Col>
              <img
                src={require(`@assets/images/${paymentMethods[0].provider}.svg`)}
                style={{ maxHeight: '25px' }}
                alt={paymentMethods[0].provider_name}
              />
            </Col>
          </Row>
          <Checkbox className="mt-3" label="Akceptuje regulamin płatności internetowych" name="agreement" />
        </FormProvider>
      </ModalBody>
      <ModalFooter
        buttonDisabled={!agreementCheckbox || amount.length === 0}
        withCancelButton
        onClose={handleClose}
        submitLabel="Przejdź do płatności"
        onSubmit={() => showPaymentModal()}
      />
    </>
  )
}
