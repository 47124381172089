import { useSelector } from 'react-redux'
import { reservationsSelector } from '@store/slices/reservations-slice'
import { useHistory } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { useDeviceSize } from '@hooks/use-device-size'
import { Reservation } from '@models/reservation'
import { RequireAtLeastOne } from '@helpers/utils'

export const useRedirectToReservation = () => {
  const { isDesktop } = useDeviceSize()
  const reservations = useSelector(reservationsSelector)

  const history = useHistory()

  const redirectToReservation = ({
    id,
    reservation_number,
    token,
  }: RequireAtLeastOne<Reservation, 'token' | 'reservation_number' | 'id'>) => {
    const reservation = reservations.find(reservation => {
      if (id) return reservation.id === id
      if (reservation_number) return reservation.reservation_number === reservation_number
      if (token) return reservation.token === token
    })

    if (reservation && !isDesktop) {
      history.push(NavigationPath.ReservationDetails.replaceToken(reservation.token))
      return
    }

    if (reservation && isDesktop) {
      history.push(`${NavigationPath.Reservations}?token=${reservation.token}`)
    }
  }

  return {
    redirectToReservation,
  }
}
