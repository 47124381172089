import * as React from 'react'
import { CompanyDataFromGus } from '@models/invoice'
import { Button } from 'react-bootstrap'
import { useGusCompanyData } from '@hooks/use-gus-company-data'
import { Spinner } from '@components/loaders/spinner'
import * as clsx from 'clsx'
import { ErrorOption } from 'react-hook-form'

interface GusButtonProps {
  nip: string
  setCompanyData: (data: CompanyDataFromGus) => void
  onError?: (error: ErrorOption) => void
  children: React.ReactNode
  className?: string

  [buttonProps: string]: any
}

export const GusButton: React.FC<GusButtonProps> = ({
  nip,
  setCompanyData,
  onError,
  children,
  className,
  ...props
}) => {
  const { isLoading, handleGus } = useGusCompanyData(setCompanyData, nip, onError)

  return (
    <Button
      className={clsx('bg-dark-gray-gradient text-white w-100 border-0', className)}
      onClick={handleGus}
      {...props}
    >
      {isLoading ? (
        <>
          <Spinner className="spinner--small" color="white" />
          <span className="ms-2">Pobieranie</span>
        </>
      ) : (
        children
      )}
    </Button>
  )
}
