import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'

interface ExpandedReservationState {
  token: string | null
}

const initialState: ExpandedReservationState = {
  token: null,
}

const expandedReservationSlice = createSlice({
  name: 'expandedReservation',
  initialState,
  reducers: {
    setExpanded(state: ExpandedReservationState, action: PayloadAction<string | null>) {
      state.token = action.payload
    },
  },
})

export const { setExpanded } = expandedReservationSlice.actions

export const selectExpandedReservation = (state: RootState) => state.expandedReservationState.token

export default expandedReservationSlice.reducer
