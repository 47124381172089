import * as React from 'react'
import { WalletHistoryFilterInterface } from '@modules/wallet/wallet-history/index'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWalletItems } from '@store/actions/wallet-actions'
import { dateOptions, transferOptions } from '@modules/wallet/wallet-history/filter-options'
import { ClientWalletItem } from '@models/wallet'
import { PaginationResponse } from '@models/index'
import { walletHistorySelector } from '@store/selectors/wallet-selectors'

interface ChildrenProps {
  filters: WalletHistoryFilterInterface
  defaultFilters: WalletHistoryFilterInterface
  setFilters: (props: WalletHistoryFilterInterface) => void
  walletHistory: PaginationResponse<ClientWalletItem>
}

interface WalletHistoryFetchListWrapperProps {
  children: (props: ChildrenProps) => JSX.Element
}

const defaultFilters = {
  created_after: dateOptions[0],
  operation: transferOptions[0],
  ordering: undefined,
  page: undefined,
  page_size: undefined,
}

export const WalletHistoryFetchListWrapper = ({ children }: WalletHistoryFetchListWrapperProps): JSX.Element => {
  const [filters, setFilters] = React.useState<WalletHistoryFilterInterface>({
    ...defaultFilters,
  })
  const walletHistory = useSelector(walletHistorySelector)

  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(fetchWalletItems(filters))
  }, [filters])

  return children({ filters, setFilters, walletHistory, defaultFilters })
}
