import * as React from 'react'
import { useApiRequest } from '@hooks/use-api-request'
import { commonObjectGet } from '@api/basic-requests'
import { ContentLoader } from '@components/loaders/content-loader'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/selectors/client-selectors'
import { PackageVip } from '@models/client'
import { SpecialProductDetailsHeader } from '@modules/special-products/special-product-details-header'
import { PackageVipDetailsBaseInfo } from '@modules/special-products/packages-vip/details/package-vip-details-base-info'
import { SpecialProductRowPaymentButton } from '@modules/special-products/special-product-row-payment-button'
import { Button } from 'react-bootstrap'
import { IconWithText } from '@components/icon-with-text'
import Decimal from 'decimal.js'
import { useModal } from '@components/modals/use-modal'
import { PackageVipAgreements } from '@modules/special-products/packages-vip/package-vip-agreements'
import { getPackageVipName } from '@modules/special-products/packages-vip/utils'

interface Params {
  id: string
}

export const PackageVipDetailsView = (): JSX.Element => {
  const { id } = useParams<Params>()

  const [packageVip, setPackageVip] = React.useState<PackageVip | null>(null)

  const { packages_vip } = useSelector(selectClientDetails)

  const [showReservationSelectionModal] = useModal('PackageVipAssignmentModal', {
    title: 'Przypisywanie pakietu VIP',
    packageVip,
  })

  const { action: fetchDetails, isLoading } = useApiRequest(async () => {
    const packageVip = packages_vip.find((packageVip: PackageVip) => packageVip.id === parseInt(id, 10))

    if (!packageVip) return
    setPackageVip(await commonObjectGet(packageVip.urls.details))
  })

  React.useEffect(() => {
    fetchDetails()
  }, [packages_vip])

  const canBePaid = new Decimal(packageVip?.required_payment ?? 0).gt(0) && packageVip?.status === 'initial'
  const canBeAttached = packageVip?.status === 'confirmed' && !packageVip?.booking

  return (
    <ContentLoader isLoading={isLoading}>
      {packageVip && (
        <>
          <SpecialProductDetailsHeader
            title="Twoje pakiety vip"
            productName={getPackageVipName(packageVip)}
            productNumber={packageVip.number}
          />
          <PackageVipDetailsBaseInfo packageVip={packageVip} />
          {canBePaid && (
            <SpecialProductRowPaymentButton
              className="w-100 mt-5"
              amount={packageVip.required_payment}
              productUrls={packageVip.urls}
              agreements={({ paymentMethod, onProductAgreementsChange }) => (
                <PackageVipAgreements
                  packageVip={packageVip}
                  paymentMethod={paymentMethod}
                  onProductAgreementsChange={onProductAgreementsChange}
                />
              )}
            />
          )}

          {canBeAttached && (
            <Button
              variant="primary"
              className="w-100 py-2 my-5 d-flex justify-content-center mx-auto"
              onClick={showReservationSelectionModal}
            >
              <IconWithText
                text="Dodaj do swojej rezerwacji"
                textClassName="font-size-md"
                iconClassName="uil-plus me-2 font-size-xl"
                className="text-white fw-bold"
              />
            </Button>
          )}
        </>
      )}
    </ContentLoader>
  )
}
