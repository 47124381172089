import * as React from 'react'
import { useDeviceSize } from '@hooks/use-device-size'
import { PurchaseHistoryTableMobileRow } from '@modules/reservations/purchase-history-modal/purchase-history-table-mobile-row'
import { PurchaseHistoryTableRow } from '@modules/reservations/purchase-history-modal/purchase-history-table-row'
import { PurchaseItem } from '@models/reservation'

interface Props {
  items: PurchaseItem[]
  noDataText?: string
  isReservationNumberShowed?: boolean
}

export const PurchaseHistoryTable = ({
  items,
  isReservationNumberShowed,
  noDataText = 'Brak historii dla podanego zakresu',
}: Props): JSX.Element => {
  const { isMobile } = useDeviceSize()

  return (
    <table className="table w-100 reservation-payment-history__table mb-0">
      {!isMobile && (
        <thead className="bg-darker-grey text-white fw-bold">
          <tr>
            <th className="ps-4" />
            <th>Data</th>
            <th>Produkt</th>
            <th />
            <th className="text-end">Wartość razem</th>
            <th className="text-end pe-4">Zakupione przez</th>
          </tr>
        </thead>
      )}
      <tbody className="border-top-0 text-muted">
        {items.map((row, index) => (
          <tr key={row.id}>
            {isMobile ? (
              <PurchaseHistoryTableMobileRow index={index + 1} row={row} />
            ) : (
              <PurchaseHistoryTableRow
                isReservationNumberShowed={isReservationNumberShowed}
                index={index + 1}
                row={row}
              />
            )}
          </tr>
        ))}

        {!items.length && (
          <tr>
            <td colSpan={6} className="reservation-payment-history__no-data-indicator">
              {noDataText}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}
