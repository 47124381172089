import * as React from 'react'
import { CashbackVoucher } from '@models/client'
import { CashbackVoucherActiveExpandableHeader } from '@modules/special-products/cashback-vouchers/expandable-row-header/cashback-voucher-active-expandable-header'
import { CashbackVoucherInGenerationExpandableHeader } from '@modules/special-products/cashback-vouchers/expandable-row-header/cashback-voucher-in-generation-expandable-header'
import { CashbackVoucherUsedExpandableHeader } from '@modules/special-products/cashback-vouchers/expandable-row-header/cashback-voucher-used-expandable-header'

interface Props {
  voucher: CashbackVoucher
  withStatus?: boolean
}

export const CashbackVoucherExpandableHeader = ({ voucher, withStatus }: Props): JSX.Element | null => {
  if (voucher.status === 'generate') {
    return <CashbackVoucherInGenerationExpandableHeader voucher={voucher} withStatus={withStatus} />
  }

  if (['active_assign', 'active'].includes(voucher.status)) {
    return <CashbackVoucherActiveExpandableHeader voucher={voucher} withStatus={withStatus} />
  }

  return <CashbackVoucherUsedExpandableHeader voucher={voucher} withStatus={withStatus} />
}
