import * as React from 'react'
import { SubscriptionContractPeriod } from '@models/client'
import { SubscriptionContractPaymentsListRow } from '@modules/special-products/subscription-contracts/payment-list/subscription-contract-payments-list-row'

interface Props {
  periods: SubscriptionContractPeriod[]
  repaymentDate: string | null
}

export const SubscriptionContractPaymentsList = ({ periods, repaymentDate }: Props): JSX.Element => (
  <div className="mt-3">
    <div className="d-flex px-3 text-light-gray mb-2">
      <span className="col-sm-3 col-7">Status płatności:</span>
      <span className="col-sm-4 col-5">Termin płatności:</span>
      <span className="col-sm-3 d-sm-inline d-none">Opis:</span>
      <span className="col-sm-2 d-sm-inline d-none" />
    </div>
    <div className="d-flex flex-column">
      {periods.map((period: SubscriptionContractPeriod, index: number) => (
        <SubscriptionContractPaymentsListRow
          key={period.id}
          period={period}
          isLast={index + 1 === periods.length}
          repaymentDate={repaymentDate}
        />
      ))}
    </div>
  </div>
)
