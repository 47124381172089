import { FeedingOption, FeedingType, ReservationDetails } from '@models/reservation'

export const getFeedingType = (feedingOptions: FeedingOption[]): FeedingType | null => {
  if (feedingOptions.some(row => row.breakfast && row.dinner)) {
    return FeedingType.DINNER_WITH_BREAKFAST
  }
  if (feedingOptions.some(row => !row.breakfast && row.dinner)) {
    return FeedingType.DINNER
  }
  if (feedingOptions.some(row => row.breakfast && !row.dinner)) {
    return FeedingType.BREAKFAST
  }

  return null
}

export const isFeedingAdded = (reservationDetails: ReservationDetails | undefined) => {
  if (!reservationDetails) return false

  return reservationDetails.feeding_options
    .filter(({ guest_id }) => reservationDetails.guests.some(guest => guest.id === guest_id))
    .some(({ dinner, breakfast }) => dinner || breakfast)
}
