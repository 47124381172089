import * as React from 'react'
import * as clsx from 'clsx'
import { formatPrice } from '@helpers/price-helper'
import { toDefaultDateFormat } from '@helpers/date-helper'
import Decimal from 'decimal.js'

export type PriceVariant = 'positive' | 'negative'

interface Props {
  titleColumn: string
  dateColumn?: string | Date
  priceColumn: string | number
  className?: string
  indent?: boolean
  noBorder?: boolean
  variant?: PriceVariant
  fiscalValue?: string | null
  quantity?: string
}

export const ReservationReceiptModalRow = ({
  titleColumn,
  dateColumn,
  priceColumn,
  className,
  indent,
  noBorder,
  variant,
  fiscalValue,
  quantity,
}: Props): JSX.Element | null => {
  if (new Decimal(priceColumn).eq(0)) return null

  return (
    <div
      className={clsx('row mx-0 bg-light border-bottom font-size-sm align-items-center', className, {
        'text-light-gray ps-4 border-bottom-0': indent,
        'p-3': !indent,
        'border-bottom-0': noBorder,
        'border-dark border-top border-bottom': variant,
      })}
    >
      <div className={clsx('p-0 lh-initial', { 'fw-bold text-muted': !indent }, quantity ? 'col-4' : 'col-6')}>
        {titleColumn}
      </div>
      {quantity && <div className="col-2 p-0 d-flex text-light-gray justify-content-end">x{quantity}</div>}
      <div className="col-3 p-0 d-flex text-light-gray justify-content-end">{toDefaultDateFormat(dateColumn)}</div>
      <div
        className={clsx('col-3 p-0 text-end', {
          'fw-bold text-danger': variant === 'negative',
          'fw-bold text-success': variant === 'positive',
          'fw-bold text-muted': !indent && !variant,
        })}
      >
        {formatPrice(priceColumn)}
        {fiscalValue && <div className="font-size-xxs text-primary">(wartość fiskalna {formatPrice(fiscalValue)})</div>}
      </div>
    </div>
  )
}
