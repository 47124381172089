import * as React from 'react'
import { ResortImprovement } from '@models/reservation'
import { useModal } from '@components/modals/use-modal'
import { ImprovementCard } from '@modules/reservations/improvements/card/improvement-card'
import { formatPriceShort } from '@helpers/price-helper'
import Decimal from 'decimal.js'

interface Props {
  improvement: ResortImprovement
}

export const BaseImprovementCard = ({ improvement }: Props): JSX.Element => {
  const [showBaseImprovementDetailsModal] = useModal('BaseImprovementDetailsModal', {
    title: 'Szczegóły ulepszenia:',
    resortImprovement: improvement,
  })

  const improvementPrice = improvement.price_brutto_first_item
    ? Decimal.min(new Decimal(improvement.price_brutto), new Decimal(improvement.price_brutto_first_item)).toString()
    : improvement.price_brutto

  return (
    <div className="col-xl-4 col-sm-6">
      <ImprovementCard
        cardImageClassName="is-small"
        onRightActionClick={showBaseImprovementDetailsModal}
        title={improvement.name}
        image={improvement.image.sm}
        leftAction={
          <strong className="text-darker-gray d-flex align-items-center font-size-sm">
            {formatPriceShort(improvementPrice)} / {improvement.price_type_2_display}
          </strong>
        }
      >
        <p className="improvements__card__description">{improvement.description}</p>
      </ImprovementCard>
    </div>
  )
}
