import { ReservationDetailsMobileHeader } from '@modules/reservations/pending/mobile/reservation-details-mobile-header'
import * as React from 'react'
import { ReservationDetails } from '@models/reservation'
import { Resort } from '@models/app-data'
import { ReservationDetailsMobileBasicInfo } from '@modules/reservations/pending/mobile/reservation-details-mobile-basic-info'
import { TimeRemainingCounter } from '@components/time-remaining-counter'
import { set } from 'date-fns'
import { parseISODate } from '@helpers/date-helper'
import { CheckedInReservationDepositState } from '@modules/reservations/checked-in/checked-in-reservation-deposit-state'
import { ReservationUpgrades } from '@modules/reservations/reservation-upgrades'
import { CheckedInReservationFooter } from '@modules/reservations/checked-in/checked-in-reservation-footer'
import { ReservationVouchers } from '@modules/reservations/reservation-vouchers'
import { ReservationCashbackVoucherInGeneration } from '@modules/reservations/reservation-cashback-voucher-in-generation'
import { CouponCheckedInReservationBanner } from '@modules/coupons/coupon-checked-in-reservation-banner'

interface Props {
  reservationDetails: ReservationDetails
  resort: Resort
}

export const CheckedInReservationContentMobile = ({ reservationDetails, resort }: Props): JSX.Element => {
  const departureTime = reservationDetails.departure_time.split(':')

  const end = set(parseISODate(reservationDetails.date_to) || 0, {
    hours: parseInt(departureTime[0], 10),
    minutes: parseInt(departureTime[1], 10),
  })

  const start = Date.now()

  return (
    <>
      <CouponCheckedInReservationBanner reservationDetails={reservationDetails} />
      <ReservationDetailsMobileHeader reservationDetails={reservationDetails} resort={resort} />
      <div className="card bg-light-blue p-3 d-flex flex-row align-items-center">
        <TimeRemainingCounter end={end} start={start} size="lg" />
        <div className="text-muted fw-bold me-sm-5 me-2 ms-4">Pozostało do końca Twojego pobytu</div>
      </div>
      <ReservationDetailsMobileBasicInfo reservationDetails={reservationDetails} resort={resort} />
      <ReservationVouchers reservationDetails={reservationDetails} />
      <CheckedInReservationDepositState reservationDetails={reservationDetails} />
      <ReservationUpgrades reservationDetails={reservationDetails} />
      <ReservationCashbackVoucherInGeneration reservationDetails={reservationDetails} />
      <CheckedInReservationFooter reservationDetails={reservationDetails} withFloatingCheckOutButton={true} />
    </>
  )
}
