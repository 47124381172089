import * as React from 'react'
import { useModal } from '@components/modals/use-modal'
import { ReservationDetails } from '@models/reservation'
import { AdditionalPaymentsContent } from '@modules/reservations/pending/payment/additional-payments/additional-payments-content'

interface Props {
  reservationDetails: ReservationDetails
}

export const AdditionalPaymentsIcon = ({ reservationDetails }: Props): JSX.Element | null => {
  const [showInformationModal] = useModal('InformationModal', {
    header: 'Do zapłaty podczas meldowania',
    content: <AdditionalPaymentsContent reservationDetails={reservationDetails} />,
  })

  return (
    <i
      className="uil-exclamation-circle text-danger font-size-lg ms-auto"
      onClick={showInformationModal}
      role="additional-payments-information"
    />
  )
}
