import { useFormContext, useWatch } from 'react-hook-form'
import { CouponItemFormInputs } from '@modules/coupons/coupon-checked-in-reservation'
import { useAppSelector } from '@store/index'
import { selectCouponTypes } from '@store/selectors/app-data-selectors'
import { CouponType, CouponTypeConfiguration } from '@models/coupons'

interface Response {
  type: CouponType | null
  configuration: CouponTypeConfiguration | null
}

export const useCouponSelectedTypeConfiguration = (): Response => {
  const couponTypes = useAppSelector(selectCouponTypes)

  const { control } = useFormContext<CouponItemFormInputs>()

  const selectedCouponType = useWatch<CouponItemFormInputs>({ control, name: 'type' })
  const couponType = couponTypes.find(couponType => couponType.id === selectedCouponType)

  return {
    configuration: couponTypes.find(couponType => couponType.id === selectedCouponType)?.configuration ?? null,
    type: couponType ?? null,
  }
}
