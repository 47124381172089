import * as React from 'react'
import { RedirectPoint } from '@modules/payment-result/utils'
import { useLocation } from 'react-router'
import { useDeviceSize } from '@hooks/use-device-size'
import { useSelector } from 'react-redux'
import { reservationsSelector } from '@store/slices/reservations-slice'
import { useModal } from '@components/modals/use-modal'
import { CheckOutStep } from '@modules/reservations/check-out'
import { CheckInSteps } from '@modules/reservations/check-in'

export const useMultistepModalsRedirect = () => {
  const { isDesktop } = useDeviceSize()
  const location = useLocation()

  const reservations = useSelector(reservationsSelector)

  const [showCheckOutModal] = useModal('CheckOutModal')
  const [showCheckInModal] = useModal('CheckInModal')

  const getReservation = () => {
    const token = location.hash.split('?token=')[1]
    return reservations.find(el => el.token === token)
  }

  const handleDesktopCheckout = (step: CheckOutStep) => {
    const reservation = getReservation()
    if (reservation && !reservation.checked_out_online)
      showCheckOutModal(null, { reservation, initialStep: step }, true)
  }

  const handleDesktopCheckIn = async (step: CheckInSteps) => {
    const reservation = getReservation()
    if (reservation && !reservation.checked_in_online) showCheckInModal(null, { initialStep: step, reservation }, true)
  }

  React.useEffect(() => {
    if (location.hash.startsWith(RedirectPoint.check_out_summary) && isDesktop) {
      handleDesktopCheckout(CheckOutStep.summary)
    }

    if (location.hash.startsWith(RedirectPoint.check_out_payment) && isDesktop) {
      handleDesktopCheckout(CheckOutStep.receipt)
    }

    if (location.hash.startsWith(RedirectPoint.check_in_agreements) && isDesktop) {
      handleDesktopCheckIn(CheckInSteps.gdpr)
    }

    if (location.hash.startsWith(RedirectPoint.check_in_payment) && isDesktop) {
      handleDesktopCheckIn(CheckInSteps.paymentSummary)
    }
  }, [location.hash, isDesktop, reservations])
}
