import * as React from 'react'
import * as clsx from 'clsx'

const ITEM_HEIGHT = 100

interface Props {
  range: string[]
  onValueChange: (newValue: string) => void
  className?: string
  id?: string
  value: string
}

export const ArrivalTimeSelectionItem = ({ onValueChange, range, className, id, value }: Props) => {
  const itemWrapperRef = React.useRef<HTMLDivElement>(null)

  const handleScroll = () => {
    if (!itemWrapperRef.current) return
    const index = Math.round(itemWrapperRef.current.scrollTop / ITEM_HEIGHT)

    const newValue = range[index + 1]
    onValueChange(newValue)
  }

  React.useEffect(() => {
    if (itemWrapperRef.current) {
      itemWrapperRef.current.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (itemWrapperRef.current) {
        itemWrapperRef.current.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  React.useEffect(() => {
    if (value && itemWrapperRef.current) {
      const itemIndex = range.findIndex(item => item == value) - 1
      itemWrapperRef.current.scrollTop = ITEM_HEIGHT * itemIndex
    }
  }, [value])

  return (
    <div className={clsx('time-selection__wrapper', className)}>
      <div className="time-selection__content-shadow" />
      <div className="time-selection__content-shadow--pre" />
      <div className="time-selection__item-wrapper" ref={itemWrapperRef}>
        {range.map(item => (
          <div
            className="time-selection__item"
            key={`${id}-${item}`}
            data-active={value == item}
            data-testid={`time-selector-${id}`}
          >
            {item}
          </div>
        ))}
      </div>
      <div className="time-selection__content-shadow--after" />
    </div>
  )
}
