import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { useFormRequest } from '@hooks/use-api-request'
import { ModalFooter } from '@components/modals/modal-footer'
import { changePassword, ChangePasswordForm } from '@store/actions/account'
import { useDispatch } from 'react-redux'
import { PasswordPreviewFormGroup } from '@components/form-controls/password-preview-form-group'
import { TrapezeButtonVariant } from '@components/controls/trapeze-button'

interface ChangePasswordModalProps {
  handleClose: () => void
}

export const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ handleClose }) => {
  const methods = useForm<ChangePasswordForm>({
    defaultValues: {
      password: '',
      new_password: '',
      confirmed_password: '',
    },
  })
  const dispatch = useDispatch()

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: ChangePasswordForm) => {
    await dispatch(changePassword(payload))
    handleClose()
  }, methods.setError)

  return (
    <>
      <ModalBody className="pb-5">
        <FormProvider {...methods}>
          <CustomFormGroup
            label="Twoje aktualne hasło:"
            inputName="password"
            formControlProps={{ type: 'password', placeholder: 'Wpisz obecne hasło' }}
          />
          <p className="mt-1">Wpisz swoje aktualne hasło, żebyśmy wiedzieli, że to Ty.</p>
          <PasswordPreviewFormGroup
            label="Wpisz nowe hasło:"
            inputName="new_password"
            formControlProps={{ placeholder: 'Wpisz nowe hasło' }}
          />
          <p className="mt-1">Hasło powinno składać się z minimum 8 znaków.</p>
          <PasswordPreviewFormGroup
            label="Powtórz nowe hasło:"
            inputName="confirmed_password"
            formControlProps={{
              placeholder: 'Wpisz ponownie nowe hasło',
            }}
          />
        </FormProvider>
      </ModalBody>
      <ModalFooter
        submitLabel="Zmień hasło"
        onSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading}
        withCancelButton
        onClose={handleClose}
        saveButtonVariant={TrapezeButtonVariant.secondary}
      />
    </>
  )
}
