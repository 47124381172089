import * as React from 'react'
import { Alert } from '@components/alert'
import * as clsx from 'clsx'

export type ImprovementKind = 'feeding' | 'improvement'

interface Props {
  kind: ImprovementKind
}

export const ImprovementUpdatesInformationAlert = ({ kind }: Props): JSX.Element => (
  <Alert variant="info" className={clsx('mb-3', { 'col-12 col-sm-9': kind === 'feeding' })} showIcon={false}>
    <p className="text-muted mb-0">
      <strong>Uwaga: </strong>

      {kindText[kind]}
    </p>
  </Alert>
)

const kindText: Record<ImprovementKind, React.ReactNode> = {
  feeding: (
    <span>
      możliwość edycji dodanego wyżywienia jest ograniczona czasowo{' '}
      <span className="text-nowrap">do 14 dni przed meldunkiem.</span>
    </span>
  ),
  improvement: (
    <span>
      możliwość edycji dodanych ulepszeń jest ograniczona czasowo{' '}
      <span className="text-nowrap">do 2 dni przed meldunkiem.</span>
    </span>
  ),
}
