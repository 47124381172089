import * as React from 'react'
import { BillVoucher, ReservationBill } from '@models/bill'
import { ReservationReceiptModalSingleVoucher } from '@modules/reservations/modals/reservation-receipt-modal-single-voucher'
import { Guest } from '@models/reservation'

interface Props {
  bill: ReservationBill
  sectionNumber: number
  guests: Guest[]
}

export const ReservationReceiptModalVouchersSummary = ({ bill, sectionNumber, guests }: Props): JSX.Element => (
  <>
    {bill.vouchers.map((voucher: BillVoucher, index: number) => (
      <ReservationReceiptModalSingleVoucher
        key={voucher.name}
        voucher={voucher}
        index={index + sectionNumber}
        guests={guests}
      />
    ))}
  </>
)
