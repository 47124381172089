import { IconWithText } from '@components/icon-with-text'
import * as React from 'react'
import formatIban from '@helpers/format-iban'
import { useSelector } from 'react-redux'
import { RootState } from '@store/index'

export const AccountReadOnly = ({ handleEdit }): JSX.Element => {
  const account_number = useSelector((state: RootState) => state.clientState.details.profile.account_number)

  return (
    <div className="d-flex justify-content-between text-muted font-size-sm">
      <div className="mt-2 mt-xl-0">{formatIban(account_number)}</div>
      <IconWithText
        text="Zmień"
        iconClassName="uil-edit-alt me-2"
        className="text-muted text-nowrap opacity-50 cursor-pointer font-size-md"
        onClick={handleEdit}
      />
    </div>
  )
}
