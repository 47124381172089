import * as React from 'react'
import * as clsx from 'clsx'
import { usePaymentModal } from '@hooks/use-payment-modal'
import { IconWithText } from '@components/icon-with-text'
import { Button } from 'react-bootstrap'
import { PaymentModalAgreements } from '@modules/wallet/modal/payment-method-modal'

interface ProductUrl {
  payment: string
  rules: string
}

interface Props {
  className?: string
  amount: string
  agreements?: (params: PaymentModalAgreements) => React.ReactNode
  productUrls: ProductUrl
}

export const SpecialProductRowPaymentButton = ({ className, amount, agreements, productUrls }: Props): JSX.Element => {
  const showPaymentModal = usePaymentModal({
    paymentUrl: productUrls.payment,
    rulesUrl: productUrls.rules,
    payload: {
      amount: amount,
      target: '',
      source: 'client-panel-app',
    },
    agreements,
  })

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    showPaymentModal()
  }

  return (
    <Button
      className={clsx('d-flex justify-content-center h-fit-content py-2 px-4 px-sm-5', className)}
      variant="danger"
      onClick={handleClick}
    >
      <IconWithText
        className="text-white"
        iconClassName="uil-money-bill me-2 py-1 font-size-xxl lh-1"
        textClassName="fw-medium font-size-md"
        text="Opłać teraz"
      />
    </Button>
  )
}
