import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { useToggle } from 'rooks'

interface Props {
  text: string | React.ReactElement
  wrapperClassName?: string
  initialHeight?: number
}

export const ExpandableText = ({ text, wrapperClassName, initialHeight }: Props): JSX.Element => {
  const [isExpanded, toggleIsExpended] = useToggle()

  const handleIsExpanded = event => {
    event.preventDefault()
    toggleIsExpended(event)
  }

  return (
    <div className={wrapperClassName}>
      <div className={clsx('expandable-text', { 'is-expanded': isExpanded })} style={{ maxHeight: initialHeight }}>
        {text}
      </div>
      <ExpandIcon isExpanded={isExpanded} onIsExpandedChange={handleIsExpanded} wrapperClassName="d-inline-flex" />
    </div>
  )
}

interface ExpandIconParams {
  isExpanded: boolean
  onIsExpandedChange: (event: React.MouseEvent) => void
  wrapperClassName?: string
}

export const ExpandIcon = ({ isExpanded, onIsExpandedChange, wrapperClassName }: ExpandIconParams): JSX.Element => (
  <IconWithText
    text={isExpanded ? 'zwiń treść' : 'rozwiń treść'}
    iconClassName="uil-angle-down expandable-text__arrow"
    textClassName="fw-bold"
    onClick={onIsExpandedChange}
    className={clsx('expandable-text__action', wrapperClassName, { 'is-expanded': isExpanded })}
  />
)
