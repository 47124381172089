import * as React from 'react'
import { ErrorPage } from '@components/error-page'

export const Error404 = (): JSX.Element => (
  <ErrorPage
    code={404}
    text={
      <span>
        Strona, której szukasz <strong className="error-page__text--bold">nie istnieje</strong>
      </span>
    }
  />
)
