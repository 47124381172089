import { ProgressWithIcon } from '@components/progress-with-icon'
import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { BookingClientWallet } from '@models/reservation'
import { formatPrice } from '@helpers/price-helper'
import Decimal from 'decimal.js'
import { useDeviceSize } from '@hooks/use-device-size'
import { isCheckedInReservation, isPendingReservation } from '@helpers/reservation'
import { useModal } from '@components/modals/use-modal'
import { useSelector } from 'react-redux'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import * as clsx from 'clsx'

interface Props {
  wallet: BookingClientWallet
  wrapperClassName?: string
}

export const ReservationVoucherUsedState = ({ wallet, wrapperClassName }: Props): JSX.Element => {
  const { isMobile } = useDeviceSize()

  const reservationDetails = useSelector(selectReservationDetails)

  const [handleVoucherRemove] = useModal('PendingReservationRemoveVoucherModal', {
    wallet,
  })

  const progress = new Decimal(wallet.available_amount)
    .dividedBy(new Decimal(wallet.start_amount))
    .times(100)
    .toNumber()

  const [showPurchasePaymentHistoryModal] = useModal('PurchasePaymentHistoryModal', {
    title: `Historia zakupów (Rezerwacja ${reservationDetails?.reservation_number})`,
    titleMobile: 'Historia zakupów',
    purchaseHistoryUrl: wallet.urls.details,
  })

  const walletName = wallet.kind === 'cashback' ? 'Voucher rePLAY!' : wallet.name

  return (
    <div
      className={clsx(
        'card bg-secondary-light-blue ps-3 py-3 pe-5 d-flex reservation-voucher-card mb-3 mb-xl-2',
        wrapperClassName,
      )}
    >
      <ProgressWithIcon
        progress={progress}
        color="primary"
        label={
          <div className="py-1 text-darker-gray d-flex justify-content-between">
            <div className="col-sm-8 col-12">
              <strong>{walletName}</strong>
            </div>
            {!isMobile && (
              <div className="text-end">
                <strong>{formatPrice(wallet.available_amount)}</strong>{' '}
                <span className="text-nowrap">z {formatPrice(wallet.start_amount)}</span>
              </div>
            )}
          </div>
        }
        icon="font-size-xxl me-2 uil-tag-alt text-muted"
      >
        {isMobile && (
          <div className="text-muted pt-2">
            <strong>{formatPrice(wallet.available_amount)}</strong> z {formatPrice(wallet.start_amount)}
          </div>
        )}
        <div className="d-flex gap-3 flex-column-reverse align-items-center align-items-md-start flex-md-row justify-content-between">
          {reservationDetails && isPendingReservation(reservationDetails) && wallet.can_remove && (
            <IconWithText
              text="Odepnij voucher"
              iconClassName="uil-trash-alt me-2 font-size-icon-18"
              className="text-darker-gray fw-bold mt-3 opacity-75"
              onClick={handleVoucherRemove}
            />
          )}
          {reservationDetails && isCheckedInReservation(reservationDetails) && (
            <IconWithText
              text="Historia zakupów"
              iconClassName="uil-receipt-alt me-2 font-size-icon-18"
              className="text-muted fw-bold pt-2 opacity-75 mt-3 mt-sm-0"
              onClick={showPurchasePaymentHistoryModal}
            />
          )}
        </div>
      </ProgressWithIcon>
    </div>
  )
}
