import * as React from 'react'
import { useDeviceSize } from '@hooks/use-device-size'
import { differenceInHours, differenceInMinutes, isAfter, set } from 'date-fns'
import { createCombinedDateWithTime, getHoursLabel, getMinutesLabel, parseISODate } from '@helpers/date-helper'
import { Reservation } from '@models/reservation'

interface Props {
  reservation: Reservation
}

export const CheckInBannerTimeLeft = ({ reservation }: Props): JSX.Element => {
  const { isMobile } = useDeviceSize()

  const arrivalTime = reservation.arrival_time.split(':')

  const startDate = set(parseISODate(reservation.date_from) || 0, {
    hours: parseInt(arrivalTime[0], 10),
    minutes: parseInt(arrivalTime[1], 10),
  })
  const hoursLeft = differenceInHours(startDate, new Date())
  const minutesLeft = differenceInMinutes(startDate, new Date())

  const timeLeft = hoursLeft > 0 ? hoursLeft : minutesLeft
  const timeLabel = hoursLeft > 0 ? getHoursLabel : getMinutesLabel

  const arrivalTimePassed = isAfter(
    new Date(),
    createCombinedDateWithTime(reservation.date_from, reservation.arrival_time),
  )

  return (
    <div className="col-3 col-lg-2 justify-content-center bg-danger text-white d-flex flex-column flex-lg-row align-items-center check-in-banner__time-wrapper round-border-bl round-border-tl px-0 px-lg-4">
      {arrivalTimePassed ? (
        <i className="uil-sunset font-size-icon-xl" />
      ) : (
        <>
          {isMobile && <div className="font-size-13 text-center text-lg-start">Pozostało</div>}
          <div className="font-size-33 fw-bold me-0 me-lg-3">{timeLeft}</div>
          <div className="font-size-13 text-center text-lg-start">
            <span className="text-nowrap">{timeLabel(timeLeft)} do</span> przyjazdu
          </div>
        </>
      )}
    </div>
  )
}
