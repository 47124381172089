import axios from 'axios'

interface PaymentRedirectUrlPayload {
  amount?: string | number
  payment_method?: number
  target?: string
  source?: string
}
export async function getPaymentRedirectUrl(url: string, payload: PaymentRedirectUrlPayload): Promise<string> {
  const { data } = await axios.post(url, payload)

  return data.url
}
