import * as React from 'react'
import { StatusVariant } from '@modules/special-products/special-product-status'
import { SpecialProductRowStatus } from '@modules/special-products/special-product-row-status'
import { SubscriptionContract } from '@models/client'

interface Props {
  subscriptionContract: SubscriptionContract
}

export const SubscriptionContractExpandableRowAside = ({ subscriptionContract }: Props) => (
  <SpecialProductRowStatus
    productStatus={StatusVariant[subscriptionContract.status]}
    productStatusText={subscriptionContract.status_display}
    title="Status subskrypcji:"
  />
)
