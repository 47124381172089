import { v4 as uuid } from 'uuid'
import Decimal from 'decimal.js'
import * as React from 'react'
import { compareAsc, compareDesc } from 'date-fns'
import { parseISODate } from '@helpers/date-helper'

export const extractInnerRef = (params: any) => {
  const { ref, ...rest } = params
  return { ...rest }
}

export type RequireAtLeastOne<T, Keys extends keyof T> = Partial<Pick<T, Keys>> &
  { [K in Keys]-?: Required<Pick<T, K>> }[Keys]

enum KeyCodes {
  Enter = 'Enter',
  NumpadEnter = 'NumpadEnter',
}

export const clearEmptySelectFilters = filter => {
  if (!filter) return undefined
  if (!filter.value) return undefined
  return filter.value
}

export const createHash = (): string => uuid()

export const createArrFromNumber = (num: number): number[] => [...Array(num).keys()]

export const isEmptyObject = <T extends {}>(element: T): boolean => !Object.keys(element).length

export const sumDecimalArray = (array: Decimal[]): Decimal =>
  array.reduce((prev: Decimal, current: Decimal) => prev.add(current), new Decimal(0))

export const handleEnter =
  (action: (event) => void) =>
  (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.code === KeyCodes.Enter || event.code === KeyCodes.NumpadEnter) {
      action(event)

      const target = event.target as HTMLInputElement
      target.blur()
    }
  }

export const sortObjectListByDate = function <T>(list: T[], field: string, sorter = compareDesc): T[] {
  return [...list].sort((a: T, b: T) => sorter(parseISODate(a[field]) as Date, parseISODate(b[field]) as Date))
}

export const sortByDate = (list: (string | Date)[]): Date[] => {
  const dates: Date[] = list.map(parseISODate).filter((date): date is Date => Boolean(date))
  return dates.sort(compareAsc)
}

export const createImageFromSignatureBase64 = (signatureBase64: string): Blob => {
  const base64result = signatureBase64.split(',')[1]
  const binarySignature = window.atob(base64result)
  const array = Uint8Array.from(binarySignature, b => b.charCodeAt(0))
  return new Blob([array], { type: 'image/png' })
}

export const isEqualCaseInsensitive = (first: string, second: string) =>
  first.localeCompare(second, undefined, { sensitivity: 'accent' }) === 0

export const isInRange = (value: number, min: number, max: number): boolean => value >= min && value <= max

export const getIntValue = (value: string | number): number => parseInt(String(value), 10)
