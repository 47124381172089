import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { SubscriptionContractPeriod } from '@models/client'
import { DateFormats, formatDate, parseISODate } from '@helpers/date-helper'
import { isSameDay } from 'date-fns'

interface Props {
  period: SubscriptionContractPeriod
  repaymentDate: string | null
  isLast: boolean
}

export const SubscriptionContractPaymentsListRow = ({ period, repaymentDate, isLast }: Props) => {
  const isRepaymentDate = isSameDay(parseISODate(period.payment_date) as Date, parseISODate(repaymentDate) as Date)

  return (
    <div className={clsx('bg-light-blue rounded border d-flex p-2', { 'mb-1': !isLast })}>
      <div className="px-xl-0 px-3 d-flex flex-wrap w-100">
        <span className="col-sm-3 col-4 d-flex align-items-center">
          <IconWithText
            text={period.status_display}
            textClassName={clsx('font-size-sm', { 'text-success': period.status === 'paid' })}
            iconClassName={clsx(
              'me-2 font-size-lg lh-1',
              period.status === 'unpaid' ? 'uil-times' : 'uil-check text-success',
            )}
          />
        </span>
        <strong className="col-sm-4 col-8 d-flex justify-content-center align-items-sm-start align-items-end flex-column">
          <span className="font-weight-semibold font-size-sm">
            {formatDate(period.payment_date, DateFormats.DATE_WITH_FULL_DAY)}
          </span>
        </strong>
        <strong className="col-sm-4 col-12 d-none d-sm-flex justify-content-center align-items-sm-start align-items-end flex-column">
          {repaymentDate && isRepaymentDate && (
            <span className="font-weight-semibold font-size-sm">Termin kolejnej płatności</span>
          )}
          {period.status === 'paid' && (
            <span className="font-weight-semibold font-size-sm">
              Wpłata za {formatDate(period.payment_date, DateFormats.MONTH_WITH_YEAR)}
            </span>
          )}
        </strong>
      </div>
    </div>
  )
}
