import * as clsx from 'clsx'
import * as React from 'react'

interface Props {
  label?: React.ReactNode
  children?: React.ReactNode
  className?: string
  hideLabelOnWrap?: boolean
  container?: string
}

export const LabeledRow = ({
  label,
  children,
  className,
  hideLabelOnWrap,
  container = 'container-sm',
}: Props): JSX.Element => (
  <div className={clsx('labeled-row__wrapper text-darker-gray', className)}>
    <div className={clsx('labeled-row__label', { 'hide-on-wrap': hideLabelOnWrap })}>{label}</div>
    <div
      className={clsx('labeled-row__content px-0', container, {
        'hide-label': hideLabelOnWrap,
      })}
    >
      {children}
    </div>
  </div>
)
