import { ParkingFormInputs, ReservationDetails } from '@models/reservation'
import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { ReservationDataCompletionParkingHeader } from '@modules/reservations/pending/data-completion-modal/parking/header'
import { PlatesFormArray } from '@modules/reservations/plates-form-array'
import { ReservationDataCompletionParkingAdd } from '@modules/reservations/pending/data-completion-modal/parking/add'
import { ReservationDataCompletionParkingList } from '@modules/reservations/pending/data-completion-modal/parking/list'
import { getPlatesInitialValue, useParkingPlatesUpdate } from '@hooks/use-parking-actions'

export const getFieldIndex = (fields, id) => fields.findIndex(el => el.id === id)

interface Props {
  reservationDetails: ReservationDetails
}

export const ReservationDataCompletionParking = ({ reservationDetails }: Props): JSX.Element => {
  const methods = useForm<ParkingFormInputs>({
    defaultValues: {
      plates: getPlatesInitialValue(reservationDetails),
    },
  })

  const { handleAppend, handleUpdate, isLoading, handleRemove, actions, fields } = useParkingPlatesUpdate(
    reservationDetails,
    methods,
  )

  const isProcessing = [
    actions.parkingLargeActions.isLoading,
    actions.parkingVipActions.isLoading,
    actions.parkingActions.isLoading,
    actions.isLoading,
  ].some(Boolean)

  return (
    <>
      <div className="mx-n3 overflow-y-auto">
        <div className="px-4 pt-4">
          <Row className="mb-3">
            <Col xl={6}>
              <ReservationDataCompletionParkingHeader />
            </Col>
            <Col xl={6}>
              <FormProvider {...methods}>
                {fields.length ? (
                  <PlatesFormArray
                    isLoading={isLoading}
                    fields={fields}
                    reservationDetails={reservationDetails}
                    onSave={handleUpdate}
                    wrapperClassName="col-12 col-sm-10"
                  />
                ) : (
                  <p className="mb-3 mt-2">Brak dodanych pojazdów</p>
                )}
              </FormProvider>
              {actions.parkingActions.canAdd() && (
                <ReservationDataCompletionParkingAdd
                  kind="parking2"
                  plates={actions.platesForKind('parking2')}
                  onClick={handleAppend('parking2')}
                  disabled={isProcessing}
                  wrapperClassName="mr-3 mt-3 mt-lg-2"
                />
              )}
              {actions.parkingLargeActions.canAdd() && (
                <ReservationDataCompletionParkingAdd
                  kind="parking_large"
                  plates={actions.platesForKind('parking_large')}
                  onClick={handleAppend('parking_large')}
                  disabled={isProcessing}
                />
              )}
            </Col>
          </Row>
        </div>
      </div>
      <div className="mb-3 px-lg-3 px-2">
        <ReservationDataCompletionParkingList
          parkingItems={actions.parkingList}
          onRemove={handleRemove}
          isCalculatingPrice={actions.isCalculatingPrice}
          isRemoving={isProcessing}
          isRemoveAllowed={actions.isRemoveAllowed}
          isPriceHidden={true}
        />
      </div>

      {/*{!!actions.plates.length && (*/}
      {/*  <ReservationDataCompletionParkingSummary*/}
      {/*    isCalculatingPrice={actions.isCalculatingPrice}*/}
      {/*    className="border-top mx-n3"*/}
      {/*    totalPrice={actions.totalPrice}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  )
}
