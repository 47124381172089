import * as React from 'react'
import { OperationHeader } from '@modules/payment-result/header'
import { Button, Col, Container } from 'react-bootstrap'
import { ContactInfo } from './contact-info'
import { IconWithText } from '@components/icon-with-text'
import {
  getOperationSubtitle,
  getOperationTitle,
  getRedirect,
  OperationResult,
  OperationType,
  RedirectType,
} from '@modules/payment-result/utils'
import { useQueryParams } from '@hooks/use-query-params'
import { useDeviceSize } from '@hooks/use-device-size'
import { useHistory } from 'react-router-dom'
import { ContentLoader } from '@components/loaders/content-loader'

interface Props {
  operationResult: OperationResult
}

export const PaymentResultView = ({ operationResult }: Props): JSX.Element => {
  const query = useQueryParams()
  const { isDesktop } = useDeviceSize()
  const operationType = (query.get('type') as OperationType | null) ?? OperationType.none
  const redirectType = (query.get('redirect') as RedirectType | null) ?? RedirectType.none
  const token = query.get('token') ?? ''
  const id = query.get('id') ?? ''
  const title = getOperationTitle(operationType, operationResult)
  const subtitle = getOperationSubtitle(operationType, operationResult)

  const redirect = getRedirect({
    type: redirectType,
    token,
    isDesktop,
    result: operationResult,
    id,
  })

  const history = useHistory()

  const handleRedirect = () => {
    if (redirect) {
      history.replace(redirect.href)
    }
  }

  return (
    <ContentLoader>
      <Container className="d-flex justify-content-center">
        <Col lg={4} className="d-flex flex-column align-items-center mt-5 gap-3">
          <OperationHeader result={operationResult} title={title} subtitle={subtitle} />
          {operationResult === OperationResult.error && <ContactInfo />}
          {redirect && (
            <Button className="my-3 px-4 py-3" variant="secondary" onClick={handleRedirect}>
              <IconWithText
                className="align-items-center text-white font-size-lg lh-initial"
                text={redirect.label}
                iconClassName="uil-arrow-left me-2 font-size-icon-22"
                textClassName="fw-bold"
              />
            </Button>
          )}
        </Col>
      </Container>
    </ContentLoader>
  )
}
