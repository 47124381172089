import * as React from 'react'
import { Reservation, ReservationDetails } from '@models/reservation'
import { Modal } from 'react-bootstrap'
import { ContentLoader } from '@components/loaders/content-loader'
import { ReservationReceiptModalFooter } from '@modules/reservations/modals/reservation-receipt-modal-footer'
import { ReservationReceipt } from '@modules/reservations/reservation-receipt'
import { useBillData } from '@hooks/use-bill-data'
import { useApiRequest } from '@hooks/use-api-request'
import { commonObjectGet } from '@api/basic-requests'

interface Props {
  reservation: Reservation
  handleClose: () => void
}

export const ReservationReceiptModal = ({ reservation, handleClose }: Props): JSX.Element => {
  const [reservationDetails, setReservationDetails] = React.useState<ReservationDetails | null>(null)

  const { bill, isLoading } = useBillData(reservationDetails)

  const { action: fetchDetails, isLoading: isFetchingReservationDetails } = useApiRequest(async () =>
    setReservationDetails(await commonObjectGet<ReservationDetails>(reservation.urls.details)),
  )

  React.useEffect(() => {
    fetchDetails()
  }, [])

  return (
    <ContentLoader isLoading={isLoading || isFetchingReservationDetails}>
      {bill && reservationDetails && (
        <>
          <Modal.Body className="modal-scrollable">
            <ReservationReceipt
              bill={bill}
              reservationDetails={reservationDetails}
              wrapperClassName="container-xxl-full-width"
            />
          </Modal.Body>
          <ReservationReceiptModalFooter handleClose={handleClose} />
        </>
      )}
    </ContentLoader>
  )
}
