import { Checkbox } from '@components/controls/checkbox'
import { ClientProfileGuestWalletLimit, ClientProfileGuestWalletScope } from '@models/client'
import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'

type WalletLimit = Omit<ClientProfileGuestWalletLimit, 'id'>

interface FormInputs {
  cafe: boolean
  cafe_limit: string
  bistro: boolean
  bistro_limit: string
  vending_machines: boolean
  vending_machines_limit: string
  shop: boolean
  shop_limit: string
}

interface Props {
  name: string
  walletLimits: ClientProfileGuestWalletLimit[]
  onChange: (limits: WalletLimit[]) => void
}

const parseWalletLimits = (walletLimits: ClientProfileGuestWalletLimit[]): FormInputs => {
  const cafe = walletLimits.find(({ scope }) => scope === ClientProfileGuestWalletScope.cafe)
  const bistro = walletLimits.find(({ scope }) => scope === ClientProfileGuestWalletScope.bistro)
  const vending_machines = walletLimits.find(({ scope }) => scope === ClientProfileGuestWalletScope.vending_machines)
  const shop = walletLimits.find(({ scope }) => scope === ClientProfileGuestWalletScope.shop)

  return {
    cafe: !!cafe,
    cafe_limit: cafe?.limit ?? '',
    bistro: !!bistro,
    bistro_limit: bistro?.limit ?? '',
    vending_machines: !!vending_machines,
    vending_machines_limit: vending_machines?.limit ?? '',
    shop: !!shop,
    shop_limit: shop?.limit ?? '',
  }
}

const parseFormInputs = (formInputs: FormInputs): WalletLimit[] =>
  [
    {
      scope: ClientProfileGuestWalletScope.cafe,
      limit: formInputs.cafe_limit,
    },
    {
      scope: ClientProfileGuestWalletScope.bistro,
      limit: formInputs.bistro_limit,
    },
    {
      scope: ClientProfileGuestWalletScope.vending_machines,
      limit: formInputs.vending_machines_limit,
    },
    {
      scope: ClientProfileGuestWalletScope.shop,
      limit: formInputs.shop_limit,
    },
  ].filter(({ scope }) => formInputs[scope])

export const ClientProfileGuestWalletLimits = ({ name, walletLimits, onChange }: Props): JSX.Element => {
  const methods = useForm<FormInputs>({ defaultValues: parseWalletLimits(walletLimits) })

  const { cafe, cafe_limit, bistro, bistro_limit, vending_machines, vending_machines_limit, shop, shop_limit } =
    methods.watch()

  React.useEffect(() => {
    onChange(
      parseFormInputs({
        cafe,
        cafe_limit,
        bistro,
        bistro_limit,
        vending_machines,
        vending_machines_limit,
        shop,
        shop_limit,
      }),
    )
  }, [cafe, cafe_limit, bistro, bistro_limit, vending_machines, vending_machines_limit, shop, shop_limit])

  React.useEffect(() => {
    methods.reset(parseWalletLimits(walletLimits))
  }, [methods, walletLimits])

  return (
    <FormProvider {...methods}>
      <h5 className="fw-bold text-primary mt-5">Czy {name || 'osoba towarzysząca'} ma mieć pełny dostęp do zakupów?</h5>
      <Row className="mb-4">
        <Col md={3}>
          <Checkbox className="mt-1" label="Kawiarnia" name="cafe" />
        </Col>
        <Col md={3}>
          <Checkbox className="mt-1" label="Bistro" name="bistro" />
        </Col>
        <Col md={3}>
          <Checkbox className="mt-1" label="Automaty" name="vending_machines" />
        </Col>
        <Col md={3}>
          <Checkbox className="mt-1" label="Sklep" name="shop" />
        </Col>
      </Row>
    </FormProvider>
  )
}
