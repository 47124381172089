import { SubscriptionCode } from '@models/client'
import { SubscriptionListRow } from '@modules/special-products/subscriptions/subscription-list-row'
import * as React from 'react'

interface Props {
  codes: SubscriptionCode[]
}

export const SubscriptionDetailsCodesList = ({ codes }: Props): JSX.Element => (
  <div className="my-4">
    <p className="font-size-xxl text-secondary text-center fw-bold">Kody pakietowe:</p>
    <div className="bg-light-blue border rounded">
      {codes.map((code: SubscriptionCode, index) => (
        <SubscriptionListRow key={code.id} code={code} borderless={index + 1 === codes.length} />
      ))}
    </div>
  </div>
)
