import * as React from 'react'
import { Button } from 'react-bootstrap'
import { IconWithText } from '@components/icon-with-text'
import { CommonLoadMoreProps } from '@components/load-more-list/index'

interface LoadMoreButtonProps extends CommonLoadMoreProps {
  isMobile: boolean
  handleShowMore: () => void
  leftToBeShown: string | number
}

export const LoadMoreButton = ({
  isMobile,
  handleShowMore,
  leftToBeShown,
  customShowMoreLabel,
  withCount,
}: LoadMoreButtonProps): JSX.Element =>
  isMobile ? (
    <Button className="mt-3 user-select-none w-100" variant="outline-primary" onClick={handleShowMore}>
      <i className="me-1 font-size-xxl uil-plus" />
      <span className="font-weight-semibold">{customShowMoreLabel || 'Pokaż kolejne'} </span>
      <span>{withCount && `(${leftToBeShown})`}</span>
    </Button>
  ) : (
    <IconWithText
      className="text-muted fw-bold font-size-md mt-3 user-select-none"
      text={`${customShowMoreLabel || 'Pokaż kolejne'} ${withCount ? '(+' + leftToBeShown + ')' : ''}`}
      iconClassName="me-1 font-size-xxl uil-plus"
      onClick={handleShowMore}
    />
  )
