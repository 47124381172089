import * as React from 'react'
import { GastroCard } from '@models/client'
import { GastroCardAgreements } from '@modules/special-products/gastro-vouchers/gastro-voucher-agreements'
import { SpecialProductRulesModal } from '@modules/special-products/special-product-rules-modal'

interface Props {
  handleClose: () => void
  onSubmit: (params) => Promise<void>
  voucher: GastroCard
}

export const GastroCouponRulesModal = ({ handleClose, onSubmit, voucher }: Props): JSX.Element => (
  <SpecialProductRulesModal onSubmit={onSubmit} handleClose={handleClose}>
    {({ onAgreementAccept }) => (
      <GastroCardAgreements gastroCard={voucher} onProductAgreementsChange={onAgreementAccept} className="col-lg-10" />
    )}
  </SpecialProductRulesModal>
)
