interface ReactNativeWebView {
  postMessage: (message: string) => void
}

declare global {
  interface Window {
    ReactNativeWebView: ReactNativeWebView
  }
}

export const NativeMessageTypes = {
  COPY: 'COPY',
  OPEN_EXTERNAL_LINK: 'OPEN_EXTERNAL_LINK',
}
