import * as clsx from 'clsx'
import * as React from 'react'

export type ProgressColor = 'primary' | 'success' | 'danger' | 'navy' | 'muted'

interface Props {
  progress: number
  color: ProgressColor
  label: React.ReactNode
  icon?: string
  children?: React.ReactNode
}

const limitProgress = (progress: number) => {
  if (progress < 0) return 0
  if (progress > 100) return 100

  return progress
}

export const ProgressWithIcon = ({ progress, color, label, icon, children }: Props): JSX.Element => (
  <div className="progress-with-icon d-flex w-100">
    <div className="progress-with-icon__icon">
      <i className={icon} />
    </div>
    <div className="progress-with-icon__wrapper">
      <div>{label}</div>
      <div className={clsx(`text-${color}`, 'progress-with-icon__progress-wrapper')}>
        <div className="progress-with-icon__progress" style={{ width: `${limitProgress(progress)}%` }} />
      </div>
      <div>{children}</div>
    </div>
  </div>
)
