import * as React from 'react'
import { SpecialProductRowStatus } from '@modules/special-products/special-product-row-status'
import { StatusVariant } from '@modules/special-products/special-product-status'
import { ExpandableRowCard } from '@components/expandable-row-card'
import { PackageVipExpandableRowHeader } from '@modules/special-products/packages-vip/row-headers/package-vip-expandable-row-header'
import { PackageVip } from '@models/client'
import { useDeviceSize } from '@hooks/use-device-size'
import { NavigationPath } from '@models/routes'
import { useHistory } from 'react-router-dom'

interface Props {
  packageVip: PackageVip
}

export const SinglePackageVip = ({ packageVip }: Props): JSX.Element => {
  const { isMobile, isDesktop } = useDeviceSize()
  const [isOpen, setIsOpen] = React.useState(false)

  const history = useHistory()

  const handleToggleOpen = () => {
    isDesktop ? setIsOpen(state => !state) : moveToPackageDetailsView()
  }

  const aside = !isMobile
    ? () => (
        <SpecialProductRowStatus
          title="Status pakietu:"
          productStatus={StatusVariant[packageVip.status]}
          productStatusText={packageVip.status_display}
        />
      )
    : undefined

  const moveToPackageDetailsView = () => {
    history.push(NavigationPath.PackageVipDetails.replaceId(packageVip.id))
  }

  const handleCardClick = () => {
    if (!isDesktop) moveToPackageDetailsView()
  }

  return (
    <div onClick={handleCardClick} className="mb-3">
      <ExpandableRowCard
        aside={aside}
        header={<PackageVipExpandableRowHeader packageVip={packageVip} />}
        isOpen={isOpen}
        onOpen={handleToggleOpen}
      />
    </div>
  )
}
