import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { useCopy } from '@hooks/use-copy'
import { SubscriptionContractCode } from '@models/client'
import { toTextDateFormat } from '@helpers/date-helper'
import { SpecialProductUsageReservation } from '@modules/special-products/special-product-usage-reservation'

const CIEPLICE_RESORT_ID = 9

interface Props {
  borderless?: boolean
  code: SubscriptionContractCode
}

export const SubscriptionContractCodesListRow = ({ borderless, code }: Props) => {
  const { copy, Copiable } = useCopy()

  const getCodeUsage = () => {
    if (code.resorts.length === 1 && code.resorts[0] === CIEPLICE_RESORT_ID) return 'góry'
    if (code.resorts.length > 1 && code.resorts.includes(CIEPLICE_RESORT_ID)) return 'morze i góry'
    return 'morze'
  }

  return (
    <div className={clsx('d-flex gap-xl-2 py-2', { 'border-bottom': !borderless })}>
      <div className="px-xl-0 px-3 d-flex flex-wrap w-100 align-items-center">
        <span className="col-sm-3 col-4 d-flex">
          <IconWithText
            text={code.is_used ? 'użyty' : 'aktywny'}
            textClassName={clsx('font-size-sm', { 'text-success': !code.is_used })}
            iconClassName={clsx('me-2 font-size-lg lh-1', code.is_used ? 'uil-times' : 'uil-check text-success')}
            className={clsx({ 'opacity-50': code.is_used })}
          />
        </span>
        <strong className="col-sm-4 col-8 d-flex justify-content-center align-items-sm-start align-items-end flex-column">
          <Copiable copyText="Kod skopiowany" className="px-0">
            <IconWithText
              onIconClick={() => copy(code.code)}
              text={code.code}
              iconClassName="uil-copy font-size-lg ms-2"
              textClassName="lh-1 font-size-sm"
              className={clsx('flex-row-reverse justify-content-end subscription__code is-short', {
                'opacity-50': code.is_used,
              })}
            />
          </Copiable>
          <SpecialProductUsageReservation code={code} className="d-sm-block d-none" />
        </strong>

        <SpecialProductUsageReservation code={code} className="d-sm-none ps-lg-4 font-size-sm" />
        {!code.is_used && (
          <>
            <span className="col-sm-3 col-12 d-flex align-items-center justify-content-between font-size-sm mb-1 mb-sm-0">
              <span className="d-sm-none me-2">Do wykorzystania: </span>
              <span>{getCodeUsage()}</span>
            </span>
            <span className="col-sm-2 col-12 font-size-sm mb-1 mb-sm-0 d-flex justify-content-between">
              <span className="d-sm-none">Ważny do: </span>
              {toTextDateFormat(code.expire_after)}
            </span>
          </>
        )}
      </div>
    </div>
  )
}
