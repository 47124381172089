import * as React from 'react'
import { CashbackVoucherDetails } from '@models/client'
import { CashbackVoucherInGenerationExpandableContentRow } from '@modules/special-products/cashback-vouchers/expandable-row-content/cashback-voucher-in-generation-expandable-content-row'
import { CashbackVoucherActiveExpandableContentRow } from '@modules/special-products/cashback-vouchers/expandable-row-content/cashback-voucher-active-expandable-content-row'
import { CashbackVoucherUsedExpandableContentRow } from '@modules/special-products/cashback-vouchers/expandable-row-content/cashback-voucher-used-expandable-content-row'

interface Props {
  voucherDetails: CashbackVoucherDetails
}

export const CashbackVoucherExpandableRowContent = ({ voucherDetails }: Props): JSX.Element | null => {
  if (voucherDetails.status === 'generate') {
    return <CashbackVoucherInGenerationExpandableContentRow voucherDetails={voucherDetails} />
  }

  if (voucherDetails.status === 'used') {
    return <CashbackVoucherUsedExpandableContentRow voucherDetails={voucherDetails} />
  }

  if (['active_assign', 'active'].includes(voucherDetails.status)) {
    return <CashbackVoucherActiveExpandableContentRow voucherDetails={voucherDetails} />
  }

  return null
}
