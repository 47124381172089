import * as React from 'react'
import { Button, ModalBody } from 'react-bootstrap'
import { ModalFooter } from '@components/modals/modal-footer'
import { ContactInfo } from '@modules/payment-result/contact-info'
import { IconWithText } from '@components/icon-with-text'
import { useApiRequest } from '@hooks/use-api-request'
import { SubscriptionContractDetails } from '@models/client'
import { commonObjectDelete } from '@api/basic-requests'
import { useConfirmation } from '@hooks/use-confirmation'
import { useDispatch } from 'react-redux'
import { updateClientSubscriptionContract } from '@store/actions/client-actions'

interface Props {
  handleClose: () => void
  subscriptionContractDetails: SubscriptionContractDetails
  onSubscriptionContractChange: (subscriptionContract: SubscriptionContractDetails) => void
}

export const SubscriptionContractResignationModal = ({
  handleClose,
  subscriptionContractDetails,
  onSubscriptionContractChange,
}: Props): JSX.Element => {
  const dispatch = useDispatch()

  const { showSuccess } = useConfirmation()

  const { action: handleSubscriptionContractResignation, isLoading } = useApiRequest(async () => {
    const subscriptionContract = await commonObjectDelete<SubscriptionContractDetails>(
      subscriptionContractDetails.urls.details,
    )

    onSubscriptionContractChange(subscriptionContract)
    dispatch(updateClientSubscriptionContract(subscriptionContract))

    handleClose()
    showSuccess({
      title: 'Proces anulowania został rozpoczęty',
      subtitle: 'Po zakończeniu procesu status subskrypcji zostanie zaktualizowany.',
      action: ({ onClose }) => (
        <Button
          variant="primary"
          onClick={onClose}
          className="mx-auto text-white px-5 btn-tall mt-5 mb-4 font-weight-semibold"
        >
          Gotowe
        </Button>
      ),
    })
  })

  return (
    <>
      <ModalBody className="modal-scrollable text-muted px-4">
        <p>
          Rezygnacja z subskrypcji spowoduje utratę prezentów. Jeśli posiadasz aktywne rezerwacje z uzyskanymi rabatami
          niezbędne będzie uregulowanie dodatkowych płatności.
        </p>
        <p className="fw-bold">Z ostatnim dniem okresu rozliczeniowego wyłączymy:</p>
        <ul className="ps-0">
          <li className="mb-1">
            <IconWithText
              iconClassName="uil-times text-danger align-self-start font-size-xl lh-0 me-2"
              text="Rabat na wyżywienie we wszystkich założonych do tej pory rezerwacjach."
            />
          </li>
          <li className="mb-1">
            <IconWithText
              iconClassName="uil-times text-danger align-self-start font-size-xl lh-0 me-2"
              text="Rabat w wysokości 10% na pobyt we wszystkich założonych do tej pory rezerwacjach."
            />
          </li>
          <li className="mb-1">
            <IconWithText
              iconClassName="uil-times text-danger align-self-start font-size-xl lh-0 me-2"
              text='Rabat na pobyt z kodami "SUP"'
            />
          </li>
          <li className="mb-1">
            <IconWithText
              iconClassName="uil-times text-danger align-self-start font-size-xl lh-0 me-2"
              text="Rabat z Voucherem wyjazdowym we wszystkich założonych do tej pory rezerwacjach, które obejmował"
            />
          </li>
          <li className="mb-1">
            <IconWithText
              iconClassName="uil-times text-danger align-self-start font-size-xl lh-0 me-2"
              text="Rabat na wyżywienie z voucherem wyjazdowym we wszystkich założonych do tej pory rezerwacjach, które obejmował"
            />
          </li>
        </ul>
        <hr className="mx-n4" />
        <p className="fw-bold">Masz pytania skontaktuj się z nami!</p>
        <ContactInfo />
      </ModalBody>
      <ModalFooter
        withCancelButton
        onClose={handleClose}
        cancelLabel="Anuluj"
        submitLabel="Rezygnuję"
        isLoading={isLoading}
        onSubmit={handleSubscriptionContractResignation}
      />
    </>
  )
}
