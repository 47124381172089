import * as React from 'react'
import { formatPriceShort } from '@helpers/price-helper'
import { ModalFooter } from '@components/modals/modal-footer'
import { ModalBody } from 'react-bootstrap'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  handleClose: () => void
}

export const GastroVoucherDetailsDescriptionModal = ({ handleClose }: Props): JSX.Element => (
  <>
    <ModalBody>
      <p className="text-secondary fw-bold font-size-xl text-center text-sm-start">
        Co zyskujesz, kupując Voucher "Podwójna Radość"?
      </p>
      <p className="font-size-sm text-center text-sm-start">
        W ramach Promocji Podwójna Radość podwajamy wpłaconą przez Ciebie kwotę, którą wykorzystasz w Kawiarni, Bistro
        oraz Restauracji w trakcie pobytu.
      </p>
      <div className="d-flex flex-wrap">
        {gastroCardBenefits.map(benefit => (
          <IconWithText
            key={benefit}
            text={benefit}
            iconClassName="uil-check me-2 text-primary font-size-lg"
            textClassName="font-size-sm"
            className="col-12 col-sm-6 pe-2"
          />
        ))}
      </div>

      <div className="d-flex flex-sm-row flex-column my-3 text-darker-gray">
        <div className="rounded bg-alice-blue px-4 pt-4 pb-5 col-sm-6 col-12 d-flex flex-wrap flex-column align-items-xl-start align-items-center justify-content-center justify-content-xl-between me-xl-1">
          <strong>Wpłacana kwota:</strong>
          <span className="d-block font-weight-semibold font-size-icon-sm text-primary">{formatPriceShort('500')}</span>
          <span className="opacity-75 font-size-sm">
            którą w ramach <br className="d-sm-block d-none" /> promocji <strong>podwajamy</strong>
          </span>
        </div>
        <div className="rounded bg-tropical-blue px-4 pt-5 pb-4 col-6 text-sm-end text-start col-sm-6 col-12 d-flex flex-wrap flex-column align-items-center justify-content-between position-relative ms-xl-1">
          <div className="rounded gastro-card-details__factor">x2</div>
          <strong>Wartość vouchera:</strong>
          <strong className="d-block font-size-icon-sm text-secondary"> = {formatPriceShort('1000')}</strong>
          <span className="opacity-75 font-size-sm">
            do wykorzystania <br className="d-sm-block d-none" /> w <strong>Kawiarni, Bistro i Restauracji</strong>
          </span>
        </div>
      </div>
    </ModalBody>
    <ModalFooter withCancelButton withSubmit={false} onClose={handleClose} cancelLabel="Zamknij" />
  </>
)

const gastroCardBenefits = [
  'Podwajamy Twoją wpłatę',
  'Wykorzystuj w wielu rezerwacjach',
  'Wygodna bezgotówkowa forma płatności',
  'Kontroluj wydatki w aplikacji mobilnej',
]
