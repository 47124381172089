import { ClientProfileGuestModal } from '@modules/user/guests/modal'
import { ChangePasswordModal } from '@modules/user/password/change-password-modal'
import { TopUpBalanceModal } from '@modules/wallet/modal/top-up-balance-modal'
import { ConfirmAccountChangeModal } from '@modules/wallet/modal/confirm-account-change-modal'
import { PaymentMethodModal } from '@modules/wallet/modal/payment-method-modal'
import { ClientWalletReturn } from '@modules/wallet/modal/client-wallet-return'
import { WalletHistoryModal } from '@modules/wallet/wallet-history/modal'
import { ReservationPaymentModal } from '@modules/reservations/pending/payment/modal'
import { AddGastroCardModal } from '@modules/reservations/pending/add-gastro-card-modal'
import { ReservationReceiptModal } from '@modules/reservations/modals/reservation-receipt-modal'
import { ReservationDataCompletionModal } from '@modules/reservations/pending/data-completion-modal'
import { VipDetailListModal } from '@modules/reservations/improvements/kinds/vip/vip-detail-list-modal'
import { VipDetailsModal } from '@modules/reservations/improvements/kinds/vip/vip-details-modal'
import { BaseImprovementDetailsModal } from '@modules/reservations/improvements/kinds/base-improvements/base-improvement-details-modal'
import { StayOptionDetailsModal } from '@modules/reservations/improvements/kinds/stay-options/stay-option-details-modal'
import { ImprovementDeleteModal } from '@modules/reservations/improvements/kinds/base-improvements/improvement-delete-modal'
import { FeedingDetailsModal } from '@modules/reservations/improvements/kinds/feedings/feeding-details-modal'
import { PendingReservationNotPaidModal } from '@modules/reservations/pending/pending-reservation-not-paid-modal'
import { OnlyOnAppModal } from '@modules/reservations/pending/only-on-app-modal'
import { MetersStateModal } from '@modules/reservations/meters-state-modal'
import { OpenDoorWithNfcModal } from '@modules/reservations/checked-in/open-door-with-nfc-modal'
import { ReportFaultModal } from '@modules/reservations/pending/report-fault-modal'
import { AddDepositModal } from '@modules/reservations/checked-in/add-deposit-modal'
import { PendingReservationRemoveVoucherModal } from '@modules/reservations/pending/pending-reservation-remove-voucher-modal'
import { ReservationRequiredRulesModal } from '@modules/user/gdpr-data/reservation-required-rules-modal'
import { ClientDataCheckModal } from '@components/client-data-check-modal'
import { PurchaseHistoryModal } from '@modules/reservations/purchase-history-modal/purchase-history-modal'
import { CheckOutModal } from '@modules/reservations/check-out/check-out-modal'
import { CheckInModal } from '@modules/reservations/check-in/check-in-modal'
import { SignaturePadModal } from '@components/signature-pad-modal'
import { GastroVoucherDetailsDescriptionModal } from '@modules/special-products/gastro-vouchers/details/gastro-voucher-details-description-modal'
import { AccountDeleteConfirmationModal } from '@modules/user/account-delete-confirmation-modal'
import { GastroVoucherPurchaseHistoryModal } from '@modules/special-products/gastro-vouchers/details/gastro-voucher-purchase-history-modal'
import { PackageVipAssignmentModal } from '@modules/special-products/packages-vip/package-vip-assignment-modal'
import { ConfirmationModal } from '@components/modals/modal-confirmation'
import { RefundConfirmationOtpModal } from '@modules/refund/refund-confirmaion-otp-modal'
import { GuestFeedingChangeConfirmationModal } from '@modules/reservations/pending/data-completion-modal/guests/feeding-change-confirmation-modal'
import { SubscriptionContractPaymentRulesModal } from '@modules/special-products/subscription-contracts/subscription-contract-payment-rules-modal'
import { SubscriptionContractResignationModal } from '@modules/special-products/subscription-contracts/modals/subscription-contract-resignation-modal'
import { GuestSetupNotDetectedModal } from '@modules/user/guests-setup-not-detected-modal'
import { SubscriptionContractPaymentCardChangeConfirmationModal } from '@modules/special-products/subscription-contracts/payment-card-modal/subscription-contract-payment-card-change-confirmation-modal'
import { GastroCouponRulesModal } from '@modules/special-products/gastro-vouchers/gastro-coupon-rules-modal'
import { InformationModal } from '@components/modals/information-modal'
import { SubscriptionContractDescriptionModal } from '@modules/special-products/subscription-contracts/modals/subscription-contract-description-modal'
import { SubscriptionContractPaymentListModal } from '@modules/special-products/subscription-contracts/modals/subscription-contract-payment-list-modal'
import { ResortMapModal } from '@modules/reservations/checked-in/resort-map-modal'
import { GastroGenericDetailsDescriptionModal } from '@modules/special-products/gastro-vouchers/details/gastro-generic-details-description-modal'
import { SubscriptionContractResignationCancelModal } from '@modules/special-products/subscription-contracts/modals/subscription-contract-resignation-cancel-modal'
import { CashbackVoucherPurchaseHistoryModal } from '@modules/special-products/cashback-vouchers/history-modal/cashback-voucher-purchase-history-modal'
import { CashbackVoucherCheckoutModal } from '@modules/special-products/cashback-vouchers/cashback-voucher-check-out-modal'
import { CouponPurchaseModal } from '@modules/coupons/modal/coupon-purchase-modal'
import { CouponsBookingSelectionModal } from '@modules/coupons/coupons-booking-selection-modal'

export type ModalParams = any

type ModalComponent = (props: any) => JSX.Element | null

type ModalsMapType = Record<string, [ModalComponent, ModalParams]>

export const ModalsMap: ModalsMapType = {
  ChangePasswordModal: [ChangePasswordModal, {}],
  ClientProfileGuestModal: [ClientProfileGuestModal, { size: 'lg' }],
  ClientWalletReturn: [ClientWalletReturn, {}],
  ConfirmAccountChangeModal: [ConfirmAccountChangeModal, {}],
  PaymentMethodModal: [PaymentMethodModal, { size: 'xl' }],
  TopUpBalanceModal: [TopUpBalanceModal, {}],
  WalletHistoryModal: [WalletHistoryModal, {}],
  AddGastroCardModal: [
    AddGastroCardModal,
    {
      size: 'lg',
      contentClassName: { 'gastro-card__modal-wrapper': true },
      title: 'Dodaj voucher gastronomiczny',
      imageMobile: require('@assets/images/gastro-card-mobile.jpg'),
      image: require('@assets/images/gastro-card-desktop.jpg'),
    },
  ],

  CouponsBookingSelectionModal: [
    CouponsBookingSelectionModal,
    {
      keyboard: false,
      backdrop: 'static',
      size: 'lg',
      title: 'Wybierz pobyt',
      imageMobile: require('@assets/images/coffe-shop.webp'),
      image: require('@assets/images/coffe-shop.webp'),
    },
  ],
  ReservationPaymentModal: [ReservationPaymentModal, { scrollable: true }],
  ReservationReceiptModal: [ReservationReceiptModal, { size: 'lg' }],
  VipDetailListModal: [VipDetailListModal, { size: 'lg', className: 'improvements__modal' }],
  VipDetailsModal: [VipDetailsModal, { size: 'lg', className: 'improvements__modal' }],
  FeedingDetailsModal: [FeedingDetailsModal, { size: 'lg', className: 'improvements__modal' }],
  BaseImprovementDetailsModal: [
    BaseImprovementDetailsModal,
    {
      size: 'lg',
      className: 'improvements__modal',
    },
  ],
  StayOptionDetailsModal: [StayOptionDetailsModal, { size: 'lg', className: 'improvements__modal' }],
  ImprovementDeleteModal: [ImprovementDeleteModal, { className: 'borderless-header' }],
  ReservationDataCompletionModal: [ReservationDataCompletionModal, { size: 'lg' }],
  PurchasePaymentHistoryModal: [PurchaseHistoryModal, { size: 'lg', scrollable: true }],
  GastroVoucherPurchaseHistoryModal: [GastroVoucherPurchaseHistoryModal, { size: 'lg', scrollable: true }],
  CashbackVoucherPurchaseHistoryModal: [CashbackVoucherPurchaseHistoryModal, { size: 'lg', scrollable: true }],
  MetersStateModal: [MetersStateModal, { size: 'md' }],
  OpenDoorWithNfcModal: [OpenDoorWithNfcModal, {}],
  PendingReservationNotPaidModal: [PendingReservationNotPaidModal, {}],
  OnlyOnAppModal: [OnlyOnAppModal, {}],
  ReportFaultModal: [ReportFaultModal, {}],
  AddDepositModal: [AddDepositModal, {}],
  PendingReservationRemoveVoucherModal: [PendingReservationRemoveVoucherModal, { size: 'md' }],
  ReservationRequiredRulesModal: [ReservationRequiredRulesModal, { size: 'lg' }],
  ClientDataCheckModal: [
    ClientDataCheckModal,
    { size: 'lg', backdrop: 'static', keyboard: false, className: 'non-closing-modal', scrollable: true },
  ],
  CheckOutModal: [CheckOutModal, { className: 'no-header', size: 'lg' }],
  CheckInModal: [CheckInModal, { className: 'no-header', size: 'lg' }],
  ModalSignaturePad: [SignaturePadModal, { className: 'no-header', size: 'lg' }],
  GastroVoucherDetailsDescriptionModal: [GastroVoucherDetailsDescriptionModal, { size: 'md', scrollable: true }],
  GastroGenericDetailsDescriptionModal: [GastroGenericDetailsDescriptionModal, { size: 'md', scrollable: true }],
  AccountDeleteConfirmationModal: [
    AccountDeleteConfirmationModal,
    { size: 'xs', className: 'no-header is-red', backdrop: 'static', keyboard: false },
  ],
  PackageVipAssignmentModal: [PackageVipAssignmentModal, { size: 'md' }],
  ConfirmationModal: [ConfirmationModal, { className: 'no-header', size: 'sm' }],
  GuestFeedingChangeConfirmationModal: [GuestFeedingChangeConfirmationModal, { className: 'borderless-header' }],
  InformationModal: [InformationModal, { className: 'borderless-header' }],
  RefundConfirmationOtpModal: [RefundConfirmationOtpModal, { className: 'no-header', size: 'md' }],
  SubscriptionContractPaymentRulesModal: [SubscriptionContractPaymentRulesModal, {}],
  SubscriptionContractDescriptionModal: [
    SubscriptionContractDescriptionModal,
    { scrollable: true, className: ' modal-semi-lg' },
  ],
  SubscriptionContractPaymentCardChangeConfirmationModal: [
    SubscriptionContractPaymentCardChangeConfirmationModal,
    { className: 'borderless-header' },
  ],
  GastroCouponRulesModal: [GastroCouponRulesModal, {}],
  SubscriptionContractResignationModal: [SubscriptionContractResignationModal, {}],
  SubscriptionContractResignationCancelModal: [SubscriptionContractResignationCancelModal, {}],
  SubscriptionContractPaymentListModal: [SubscriptionContractPaymentListModal, { size: 'lg' }],
  GuestSetupNotDetectedModal: [GuestSetupNotDetectedModal, {}],
  CouponPurchaseModal: [CouponPurchaseModal, {}],
  CashbackVoucherCheckoutModal: [CashbackVoucherCheckoutModal, {}],
  ResortMapModal: [ResortMapModal, { size: 'lg', scrollable: true }],
}
export type ModalTypes = keyof typeof ModalsMap

export interface ModalConfig {
  persist?: boolean // nie usuwa modala po wyjściu z widoku
  onClose?: () => void
}
