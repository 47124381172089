import * as React from 'react'
import { useAppSelector } from '@store/index'
import { selectAppData } from '@store/selectors/app-data-selectors'
import { useLink } from '@hooks/use-link'

export const useCashbackVoucherRules = (): ((event: React.MouseEvent) => void) => {
  const { rules } = useAppSelector(selectAppData)
  const { openLink } = useLink()

  return (event: React.MouseEvent) => {
    event.preventDefault()

    const cashbackVoucherRule = rules.find(rule => rule.keyword === 'cashback_voucher_rules')
    if (cashbackVoucherRule) openLink(cashbackVoucherRule.url)
  }
}
