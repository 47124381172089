import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import { useSwipeable } from '@hooks/use-swipeable'
import { ClientProfileGuest } from '@models/client'
import { deleteClientProfileGuest } from '@store/actions/client-actions'
import * as React from 'react'
import { useDispatch } from 'react-redux'

interface Props {
  guest: ClientProfileGuest
}

export const ClientProfileGuestBox = ({ guest }: Props): JSX.Element => {
  const { name, type_display } = guest
  const dispatch = useDispatch()
  const boxRef = React.useRef<HTMLDivElement>(null)
  const handleDelete = async () => {
    await dispatch(deleteClientProfileGuest(guest))
  }
  const [toggleModal] = useModal('ClientProfileGuestModal', { guest, title: 'Informacje o osobie towarzyszącej' })
  const swipeControls = useSwipeable(
    70,
    direction => {
      if (direction === 'left') {
        handleDelete()
      }
    },
    distanceFromStart => {
      if (boxRef.current) {
        const posX = -distanceFromStart
        if (posX > 0) {
          boxRef.current.style.transform = 'translate(0)'
        } else if (posX > -70) {
          boxRef.current.style.transform = `translate(${posX}px)`
        } else {
          boxRef.current.style.transform = 'translate(-70px)'
        }
      }
    },
    () => {
      if (boxRef.current) {
        boxRef.current.style.transform = 'translate(0)'
      }
    },
  )

  return (
    <div className="client-profile-guest-box__wrapper" data-testid="client-profile-guest-box">
      <div className="client-profile-guest-box__swipe-background text-white font-size-sm">usuń</div>
      <div
        ref={boxRef}
        className="client-profile-guest-box__box card p-3 bg-light-blue d-flex flex-row align-items-center font-size-sm"
        {...swipeControls}
      >
        <IconWithText
          className="client-profile-guest-box__companion text-primary align-items-center"
          text={name}
          iconClassName="client-profile-guest-box__companion--icon uil-user user-circle me-3"
          textClassName="client-profile-guest-box__companion--name fw-bold"
          onClick={toggleModal}
        />
        <span className="client-profile-guest-box__companion-kind ms-3 text-muted">{type_display}</span>
        <IconWithText
          className="client-profile-guest-box__delete-button text-light-gray align-items-center ms-auto"
          text="Usuń"
          iconClassName="client-profile-guest-box__delete-button--icon uil-trash-alt me-2"
          textClassName="client-profile-guest-box__delete-button--text fw-bold d-none d-xl-block"
          onClick={handleDelete}
          data-testid="guest-box-delete-button"
        />
      </div>
    </div>
  )
}
