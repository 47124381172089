import { useGetCashbackVouchersQuery } from '@api/cashback-vouchers'
import { TypedQueryResult } from '@api/base'
import { CashbackVoucher } from '@models/client'
import { ReservationDetails } from '@models/reservation'
import { useClientDetails } from '@hooks/use-client-details'

interface Response {
  voucher: CashbackVoucher | null
}

export const useReservationCashbackVoucherInGeneration = (reservationDetails: ReservationDetails | null): Response => {
  const { clientDetails } = useClientDetails()

  const { data: cashbackVouchers = [] } = useGetCashbackVouchersQuery(
    clientDetails.urls.cashback_vouchers,
  ) as TypedQueryResult<CashbackVoucher[]>

  if (!reservationDetails) return { voucher: null }

  const voucher =
    cashbackVouchers.find(
      voucher => voucher.source_booking === reservationDetails.reservation_number && voucher.status === 'generate',
    ) ?? null

  return {
    voucher,
  }
}
