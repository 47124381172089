import { useDispatch, useSelector } from 'react-redux'
import { selectClientDetails } from '@store/selectors/client-selectors'
import { useApiRequest } from '@hooks/use-api-request'
import { fetchClientDetails } from '@store/actions/client-actions'
import * as React from 'react'
import { ClientDetails } from '@models/client'

interface Response {
  clientDetails: ClientDetails
  isLoading: boolean
  fetchClientDetailsData: () => Promise<void>
}

export const useClientDetails = (): Response => {
  const clientDetails = useSelector(selectClientDetails)

  const dispatch = useDispatch()

  const { isLoading, action: fetchClientDetailsData } = useApiRequest(async () => {
    dispatch(fetchClientDetails())
  })

  React.useEffect(() => {
    fetchClientDetailsData()
  }, [])

  return { clientDetails, isLoading, fetchClientDetailsData }
}
