import * as React from 'react'
import { ClientWalletItem } from '@models/wallet'
import { Col, Row } from 'react-bootstrap'
import { toTextDateFormat } from '@helpers/date-helper'
import * as clsx from 'clsx'

export const WalletHistoryListRow = ({ created, description, amount }: ClientWalletItem): JSX.Element => (
  <Row className="p-2 my-1 mx-0 form-text wallet__grey-box font-weight-normal lh-1">
    <Col xs={4} className="d-flex align-items-center">
      {toTextDateFormat(created)}
    </Col>
    <Col xs={6} className="d-flex align-items-center justify-content-end text-end ps-0">
      {description}
    </Col>
    <Col
      xs={2}
      className={clsx('d-flex align-items-center justify-content-center font-weight-semibold', {
        'text-danger': +amount < 0,
        'text-success': +amount > 0,
      })}
    >
      {amount}
    </Col>
  </Row>
)
