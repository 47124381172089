import * as React from 'react'
import { ReservationDetails } from '@models/reservation'
import Decimal from 'decimal.js'
import { formatPrice, formatPriceShort } from '@helpers/price-helper'
import { CustomTooltip } from '@components/custom-tooltip'

interface Props {
  reservationDetails: ReservationDetails
}

export const AdditionalPaymentsContent = ({ reservationDetails }: Props): JSX.Element => {
  const shouldShowServicePrice =
    new Decimal(reservationDetails.prices.single_service_price_brutto_from).gt(0) &&
    !reservationDetails.skip_service_charge

  const lowestClimaticPrice = React.useMemo(() => {
    const prices = reservationDetails.prices.climatic.items.reduce((previousValue, climaticItem) => {
      const price = new Decimal(climaticItem.single_price_brutto)
      return price.gt(0) ? [...previousValue, price] : previousValue
    }, [])

    return formatPrice(Decimal.min(...(prices.length ? prices : [new Decimal(0)])).toString())
  }, [reservationDetails?.prices.climatic.items])

  return (
    <ul className="list-unstyled mb-0">
      <li className="d-flex flex-row">
        <div className="me-2">-</div>
        <div>
          opłata klimatyczna (nie jest zawarta w cenie){' '}
          <CustomTooltip
            tooltipClassName="d-inline px-0"
            showOnHover
            content={
              <ul className="px-3 py-2 mb-0">
                {reservationDetails.prices.climatic.items.map(climaticItem => (
                  <li key={climaticItem.name} className="text-nowrap text-start">
                    {climaticItem.name}: {formatPriceShort(climaticItem.single_price_brutto)}
                  </li>
                ))}
              </ul>
            }
          >
            <span className="text-nowrap fw-medium">od {lowestClimaticPrice} / os. za dzień</span>
          </CustomTooltip>
        </div>
      </li>
      {shouldShowServicePrice && (
        <li className="d-flex flex-row">
          <div className="me-2">-</div>
          <div>
            opłata eksploatacyjna (nie jest zawarta w cenie){' '}
            <span className="text-nowrap">
              {formatPrice(reservationDetails.prices.single_service_price_brutto_from)} / os. za dzień
            </span>
          </div>
        </li>
      )}
    </ul>
  )
}
