import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { ReservationImprovement } from '@models/reservation'
import { formatPriceShort } from '@helpers/price-helper'
import { useModal } from '@components/modals/use-modal'
import { useVipModals } from '@modules/reservations/improvements/kinds/vip/use-vip-modals'
import { isFeeding, isStayOption, isVip } from '@helpers/improvement-helper'
import { useSelector } from 'react-redux'
import { selectResortDetails } from '@store/selectors/reservations-selectors'
import { useImprovementRemovingCheck } from '@hooks/use-improvement-removing-check'

interface Props {
  reservationImprovement: ReservationImprovement
  reservationStartDate: Date
  reservationToken: string
}

export const ImprovementSummaryItem = ({
  reservationImprovement,
  reservationStartDate,
  reservationToken,
}: Props): JSX.Element => {
  const resortDetails = useSelector(selectResortDetails)
  const resortImprovement = resortDetails?.improvements.find(el => el.code === reservationImprovement.code)

  const handleEditSelection = () => {
    hideImprovementDeleteModal()
    showModal()
  }

  const showModal = () => {
    if (!reservationImprovement?.can_update) return

    if (isFeeding(reservationImprovement)) showFeedingDetailsModal()
    else if (isVip(reservationImprovement)) showVipDetailsModal()
    else if (isStayOption(reservationImprovement)) showStayOptionModal()
    else showImprovementDetailsModal()
  }

  const { showVipDetailsModal } = useVipModals(resortImprovement)

  const [showImprovementDeleteModal, hideImprovementDeleteModal] = useModal('ImprovementDeleteModal', {
    improvement: reservationImprovement,
    onEdit: handleEditSelection,
  })

  const [showFeedingDetailsModal] = useModal('FeedingDetailsModal', { title: 'Szczegóły wyżywienia:' })

  const [showImprovementDetailsModal] = useModal('BaseImprovementDetailsModal', {
    title: 'Szczegóły ulepszenia:',
    resortImprovement: resortImprovement,
    reservationImprovement: reservationImprovement,
  })

  const [showStayOptionModal] = useModal('StayOptionDetailsModal', { title: 'Szczegóły ulepszenia', resortImprovement })

  const { canRemoveEntireImprovement } = useImprovementRemovingCheck()

  const isRemovingAllowed = canRemoveEntireImprovement({
    improvement: reservationImprovement,
    dateFrom: reservationStartDate,
    reservation: reservationToken,
  })

  return (
    <div className="row gx-0 text-muted py-2 px-2 px-xl-4 improvements__summary__item">
      <div className="col-7 col-md-8 px-0 pe-2">
        <IconWithText
          text={
            <span
              onClick={showModal}
              className={clsx(reservationImprovement.can_update ? 'cursor-pointer' : 'cursor-default')}
            >
              {reservationImprovement.name}
            </span>
          }
          textClassName="fw-medium"
          iconClassName="uil-check text-primary font-size-xxl me-2"
          className="d-inline-block"
        />
      </div>

      <div className="col-2 d-flex justify-content-center justify-content-lg-end align-items-center">
        x {reservationImprovement.amount}
      </div>
      <div className="col-3 col-md-2 d-flex justify-content-end align-items-center">
        <strong>+{formatPriceShort(reservationImprovement.price_brutto)}</strong>
        <div className="improvements__summary__remove-icon">
          {isRemovingAllowed && (
            <i
              className="uil-trash-alt text-muted cursor-pointer"
              onClick={showImprovementDeleteModal}
              role="remove-improvement"
            />
          )}
        </div>
      </div>
    </div>
  )
}
