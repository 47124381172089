import { FeedingTypes, ImprovementCode, ParkingKind, ReservationDetails } from '@models/reservation'
import axios from 'axios'
import { ThunkAction } from 'redux-thunk'
import { RootState } from '@store/index'
import { Action } from 'redux'
import { setReservationDetails } from '@store/slices/reservations-slice'
import { commonObjectPost, commonObjectPut } from '@api/basic-requests'
import { isEqualCaseInsensitive } from '@helpers/utils'

export interface ImprovementContext {
  register_car_numbers: string[]
}

export interface AddImprovementPayload {
  code: string | ImprovementCode
  amount?: number
  date_from?: string | null
  date_to?: string | null
  context?: ImprovementContext
  booking_car_ids?: number[] | null
}

export interface CalculatedImprovement {
  name: string
  price: number
}

export interface ImprovementPriceCalculationResponse {
  items: CalculatedImprovement[]
  total_price: number
}

export interface FeedingCalculations {
  dates: string[]
  feeding_type: FeedingTypes
  total_price_brutto: number
}

interface UpdateCarPlatesPlates {
  register_number: string
  kind: ParkingKind
  carId: number | null
}

export function updateCarPlates<T extends UpdateCarPlatesPlates>(
  reservationDetails: ReservationDetails,
  plates: T[],
): ThunkAction<Promise<void>, RootState, null, Action> {
  return async dispatch => {
    const platesToUpdate = getPlatesToUpdate(reservationDetails, plates)

    await Promise.all<Promise<ReservationDetails>>(
      platesToUpdate.map(
        async plateToUpdate =>
          await commonObjectPut(plateToUpdate.url, { register_number: plateToUpdate.registerNumber }),
      ),
    )

    const platesToAdd = plates.filter(
      plate =>
        plate.carId === null &&
        !platesToUpdate.some(toUpdate => isEqualCaseInsensitive(toUpdate.registerNumber, plate.register_number)),
    )

    if (platesToAdd.length) {
      const details = await commonObjectPost<ReservationDetails>(
        reservationDetails.urls.cars,
        platesToAdd.map(plateToAdd => ({
          register_number: plateToAdd.register_number.toUpperCase(),
          kind: plateToAdd.kind,
        })),
      )

      dispatch(setReservationDetails(details))
      return
    }

    const cars = reservationDetails.cars.map(car => {
      const updatedPlate = platesToUpdate.find(plateToUpdate => plateToUpdate.url === car.urls.details)
      return updatedPlate ? { ...car, register_number: updatedPlate.registerNumber } : car
    })

    dispatch(setReservationDetails({ ...reservationDetails, cars }))
  }
}

const getPlatesToUpdate = (reservationDetails: ReservationDetails, plates: UpdateCarPlatesPlates[]) => {
  const reservationCars = reservationDetails.cars.filter(car => plates[0].kind === car.kind)

  return reservationCars.reduce((cars, car) => {
    const plate = plates.find(
      plate => plate.carId === car.id && !isEqualCaseInsensitive(plate.register_number, car.register_number),
    )

    return plate
      ? [
          ...cars,
          {
            registerNumber: plate.register_number.toUpperCase(),
            url: car.urls.details,
            id: car.id,
          },
        ]
      : cars
  }, [])
}

export async function addImprovement(url: string, payload: AddImprovementPayload[]): Promise<ReservationDetails> {
  const { data } = await axios.post(url, payload)
  return data
}

export async function updateImprovement(url: string, payload: AddImprovementPayload[]): Promise<ReservationDetails> {
  const { data } = await axios.put(url, payload)
  return data
}

export async function removeImprovement(url: string, booking_car_ids?: number[] | null): Promise<ReservationDetails> {
  const { data } = await axios.delete(url, { data: { ...(booking_car_ids && { booking_car_ids }) } })
  return data
}

interface AddFeedingPayload {
  feeding_type: FeedingTypes
  guests?: number[]
}

export async function addFeeding(url: string, payload: AddFeedingPayload): Promise<ReservationDetails> {
  const { data } = await axios.post(url, payload)
  return data
}

export async function removeFeeding(url: string, feedingType?: FeedingTypes): Promise<ReservationDetails> {
  const payload = feedingType ? { data: { feeding_type: feedingType } } : {}
  const { data } = await axios.delete(url, payload)
  return data
}

export async function calculateFeeding(url: string): Promise<FeedingCalculations[]> {
  const { data } = await axios.get(url)
  return data
}
