import * as React from 'react'
import { Form, FormCheckProps } from 'react-bootstrap'
import * as clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { Spinner } from '@components/loaders/spinner'

interface Props extends FormCheckProps {
  wrapperClassName?: string
  name: string
  isLoading?: boolean
}

export const CircleOutlineRadioButton = ({
  name,
  wrapperClassName,
  isLoading,
  ...props
}: Props): React.ReactElement => {
  const { register } = useFormContext()

  return (
    <div className={clsx('position-relative', wrapperClassName)}>
      <div className="position-relative cursor-pointer d-inline-block">
        <Form.Check
          {...register(name)}
          {...props}
          type="radio"
          className={clsx('circle-outline-radio-button', props.className)}
        />
        <i className="uil-check circle-outline-radio-button__check-icon" />
        <Spinner className={clsx('circle-outline-radio-button__spinner', { 'is-loading': isLoading })} />
      </div>
    </div>
  )
}
