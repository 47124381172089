import * as React from 'react'
import * as clsx from 'clsx'
import { PaymentMethod } from '@models/wallet'
import { ModalBody } from 'react-bootstrap'
import { ModalFooter } from '@components/modals/modal-footer'
import { useApiRequest } from '@hooks/use-api-request'
import { getPaymentRedirectUrl } from '@api/payment'
import { useActivePaymentMethods } from '@hooks/use-active-payment-methods'
import { ContentLoader } from '@components/loaders/content-loader'
import { useForm, FormProvider } from 'react-hook-form'
import { AgreementSection } from '@components/agreement-section'
import { commonObjectPost } from '@api/basic-requests'

export const PAYMENT_TERMS_KEY = 'payment_provider_terms'

export interface PaymentModalAgreements {
  paymentMethod?: PaymentMethod | undefined
  onProductAgreementsChange: (requiredAgreementsAccepted: boolean) => void
}

interface PaymentSelectionFormInputs {
  [PAYMENT_TERMS_KEY]: boolean
}

interface Props {
  handleClose: () => void
  payload: {
    amount: string | number
    target?: string
  }
  paymentUrl: string
  rulesUrl?: string
  agreements?: (params: PaymentModalAgreements) => JSX.Element | null
}

export const PaymentMethodModal = ({
  handleClose,
  payload,
  paymentUrl,
  rulesUrl,
  agreements: Agreements = AgreementSection,
}: Props): JSX.Element => {
  const paymentMethods = useActivePaymentMethods()
  const [selectedMethod, setSelectedMethod] = React.useState<PaymentMethod | undefined>(undefined)
  const [requiredProductAgreementAccepted, setRequiredProductAgreementAccepted] = React.useState(false)

  const [imagesCount, setImagesCount] = React.useState(0)
  const [isEachImageLoaded, setIsEachImageLoaded] = React.useState(false)

  const paymentLogosWrapperRef = React.useRef<HTMLInputElement>(null)

  const methods = useForm<PaymentSelectionFormInputs>({
    defaultValues: {
      [PAYMENT_TERMS_KEY]: false,
    },
  })

  const { isLoading, action: handleRedirect } = useApiRequest(async () => {
    if (!selectedMethod) return

    if (rulesUrl) {
      await commonObjectPost(rulesUrl, methods.getValues())
    }

    document.location.href = await getPaymentRedirectUrl(paymentUrl, {
      payment_method: selectedMethod.id,
      ...payload,
    })
  })

  const activePaymentMethods = React.useMemo(
    () => paymentMethods.filter((method: PaymentMethod) => method.is_active),
    [paymentMethods],
  )

  const handleImagesCountChange = () => setImagesCount(state => state + 1)
  const handleActiveMethodChange = (method: PaymentMethod) => setSelectedMethod(method)

  React.useEffect(() => {
    if (imagesCount === paymentMethods.length) {
      setIsEachImageLoaded(true)
    }
  }, [imagesCount])

  React.useEffect(() => {
    if (selectedMethod && paymentLogosWrapperRef.current) {
      paymentLogosWrapperRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
    }
  }, [selectedMethod])

  return (
    <>
      <ModalBody className="modal-scrollable text-muted py-5">
        <FormProvider {...methods}>
          <ContentLoader isLoading={!isEachImageLoaded}>
            <p className="me-5">Wybierz preferowaną formę płatności</p>
            <div
              className="d-flex flex-wrap justify-content-center justify-content-xl-start"
              ref={paymentLogosWrapperRef}
            >
              {activePaymentMethods.map(method => (
                <div
                  key={method.id}
                  className={clsx('wallet__method__logo-wrapper cursor-pointer', { active: selectedMethod === method })}
                  onClick={() => handleActiveMethodChange(method)}
                >
                  <img
                    src={method.image_url}
                    alt="logo"
                    onLoad={handleImagesCountChange}
                    onError={handleImagesCountChange}
                  />
                </div>
              ))}
              <Agreements
                paymentMethod={selectedMethod}
                onProductAgreementsChange={setRequiredProductAgreementAccepted}
              />
            </div>
          </ContentLoader>
        </FormProvider>
      </ModalBody>
      <ModalFooter
        isLoading={isLoading}
        buttonDisabled={!selectedMethod || !requiredProductAgreementAccepted}
        withCancelButton
        onSubmit={handleRedirect}
        onClose={handleClose}
        submitLabel="Kontynuuj"
      />
    </>
  )
}
