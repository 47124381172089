import { Reservation } from '@models/reservation'
import * as React from 'react'
import { ReservationRow } from '@modules/reservations/reservation-row'
import { PendingReservationBanner } from '@modules/reservations/pending/pending-reservation-banner'

interface Props {
  reservations: Reservation[]
}

export const CheckedInReservationsList = ({ reservations }: Props): JSX.Element => (
  <div className="d-flex flex-column">
    {reservations.map(reservation => (
      <div key={reservation.id} className="position-relative mb-3">
        <ReservationRow key={reservation.id} reservation={reservation}>
          {isLoaded => isLoaded && <PendingReservationBanner reservation={reservation} />}
        </ReservationRow>
      </div>
    ))}
  </div>
)
