import * as React from 'react'
import { ResortImprovement } from '@models/reservation'
import { formatPrice, formatPriceShort } from '@helpers/price-helper'
import { ImprovementCardTextWithPrice } from '@modules/reservations/improvements/card/improvement-card-text-with-price'
import { isWinterVip } from '@helpers/improvement-helper'

interface Props {
  improvement: ResortImprovement
  wrapperClassName?: string
}

export const VipPricesBlock = ({ improvement, wrapperClassName }: Props): JSX.Element => (
  <div className={wrapperClassName}>
    <ImprovementCardTextWithPrice
      text={<strong>{isWinterVip(improvement) ? 'Koszt zimowego pakietu VIP:' : 'Koszt pakietu VIP:'}</strong>}
      price={formatPriceShort(improvement.price_brutto)}
      className="text-primary font-size-xl mb-2"
    />
    <ImprovementCardTextWithPrice
      text="Cena standardowa udogodnień:"
      price={formatPrice('4390.6')}
      className="text-muted"
    />
    <ImprovementCardTextWithPrice
      text={<strong>Oszczędzasz:</strong>}
      price={formatPrice('3391.6')}
      className="text-darker-gray"
    />
  </div>
)
