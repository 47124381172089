import * as React from 'react'
import { SectionTitle } from '@components/section-title'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  children?: React.ReactNode
}

export const ConfirmationStep = ({ children }: Props): JSX.Element => (
  <>
    <SectionTitle
      className="text-center text-md-start"
      title="Potwierdzenie zameldowania"
      subtitle={
        <>
          <p className="mb-md-0">Proces meldowania on-line przebiegł prawidłowo.</p>
          <p>
            Jeszcze tylko odbiór opasek i będziesz mógł cieszyć się wakacjami.
            <span className="d-none d-md-inline"> Po odbiór opasek udaj się do recepcji.</span>
          </p>
        </>
      }
    />
    <div className="text-center font-size-xxl text-navy px-3 m-5 d-md-none">
      <p>Po odbiór opasek udaj się do recepcji.</p>
      <img
        src={require('@assets/images/success.svg')}
        alt="sukces"
        className="w-100 mt-3"
        style={{ maxWidth: '300px' }}
      />
    </div>
    <div className="d-none d-md-flex justify-content-between col-10 gap-5">
      <div className="d-flex flex-column col-6">
        <strong className="d-block mb-2 font-size-xxl">
          Możesz już pobrać aplikację <span className="text-nowrap">Konto Gościa</span>
        </strong>
        <p className="font-size-sm">
          Pobierz aplikację na swój telefon i zarządzaj swoim pobytem łatwiej niż gdziekolwiek.
        </p>
        {mobileAppBenefits.map(benefit => (
          <IconWithText
            key={benefit}
            text={benefit}
            iconClassName="uil-check me-2 text-green font-size-lg"
            textClassName="font-size-sm"
          />
        ))}
      </div>
      <img src={require('@assets/images/downloadapp.png')} alt="Aplikacja Konto Gościa" />
    </div>
    {children}
  </>
)

const mobileAppBenefits = [
  'Dostęp do pełnej historii rezerwacji',
  'Meldowanie i wymeldowanie on-line',
  'Wygodnie zamawiaj kolejne ulepszenia',
  'Kontroluj swoje rachunki podczas wakacji',
]
