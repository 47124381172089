import { Rule } from '@models/reservation'
import { CircleOutlineRadioButton } from '@components/controls/circle-outline-radio-button'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import * as React from 'react'
import { AvailableRuleKeyword } from '@models/app-data'

interface Props {
  rule: Rule<AvailableRuleKeyword>
  context?: string
  onUpdate: (value) => void
  disabled?: boolean
  updatingRules?: string[]
}

export const RuleAcceptanceOptions = ({ rule, context, onUpdate, disabled, updatingRules }: Props): JSX.Element => {
  const handleChange = event => {
    onUpdate({ [rule.keyword]: event.target.value })
  }

  const isLoading = rule => updatingRules?.includes(rule)

  return (
    <>
      <div className="row px-3 py-2">
        <div className="col-sm-6 col-12 mb-3 mb-sm-0">
          <CircleOutlineRadioButton
            id={`${rule.keyword}-${context}-accept`}
            name={`${rule.keyword}-${context}`}
            label="Wyrażam zgodę"
            value="true"
            onChange={handleChange}
            disabled={disabled}
            isLoading={isLoading(createCircleRadioButtonLoaderId(rule.keyword, true))}
          />
        </div>
        <div className="col-sm-6 col-12">
          <CircleOutlineRadioButton
            id={`${rule.keyword}-${context}-reject`}
            name={`${rule.keyword}-${context}`}
            label="Nie wyrażam zgody"
            value="false"
            disabled={(rule.required && rule.accepted) || disabled}
            onChange={handleChange}
            isLoading={isLoading(createCircleRadioButtonLoaderId(rule.keyword, false))}
          />
        </div>
      </div>
      {rule.accepted && <div>Zgoda wyrażona {toDefaultDateTimeFormat(rule.date)}</div>}
    </>
  )
}

export const createCircleRadioButtonLoaderId = (key: AvailableRuleKeyword, value: string | boolean) =>
  JSON.stringify({ [key]: `${value}` })
