import * as React from 'react'
import { ContactInfo } from '@modules/payment-result/contact-info'

export const NoSpecialProducts = (): JSX.Element => (
  <div className="px-2 px-md-0 bg-white">
    <div className="card pb-4 px-3">
      <div className="d-flex flex-column align-items-center mx-auto px-sm-5 px-2 col-md-7">
        <div className="text-primary font-size-20 fw-bold text-center mt-4 mx-auto">
          Wygląda na to, że jeszcze nie korzystałeś z naszych produktów specjalnych
        </div>
        <div className="fw-light text-center my-3">
          <strong>Skontaktuj się z nami i poznaj szczegóły!</strong>
        </div>
        <ContactInfo />
      </div>
    </div>
  </div>
)
