import * as React from 'react'
import { ErrorPage } from '@components/error-page'
import { NavigationPath } from '@models/routes'

export const Error403 = (): JSX.Element => (
  <ErrorPage
    code={403}
    text={
      <span>
        Dostęp do strony <strong>zabroniony</strong>
      </span>
    }
    redirectElement={<RedirectAction />}
  />
)

const RedirectAction = () => {
  const handleRedirect = () => {
    window.location.href = NavigationPath.Login
  }

  return (
    <button className="btn error-page__button" onClick={handleRedirect}>
      Wróć do strony logowania
    </button>
  )
}
