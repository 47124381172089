import { AppDataActionTypesKeys, SetAppDataAction } from '@store/action-types/app-data-action-types'
import { AppData } from '@models/app-data'
import { ThunkAction } from 'redux-thunk'
import { RootState } from '@store/index'
import axios from 'axios'

export function fetchAppData(): ThunkAction<Promise<AppData>, RootState, null, SetAppDataAction> {
  return async dispatch => {
    const { data } = await axios.get('/api/clients/clients-app-data/')
    dispatch(setAppDataStatus(data))

    return data
  }
}

export function setAppDataStatus(appData: AppData): SetAppDataAction {
  return { type: AppDataActionTypesKeys.SET_APP_DATA, payload: appData }
}
