import * as React from 'react'
import { PaymentModalAgreements } from '@modules/wallet/modal/payment-method-modal'
import { Subscription } from '@models/client'
import { DynamicProductAgreements } from '@components/dynamic-product-agreements'

interface Props extends PaymentModalAgreements {
  subscription: Subscription
}

export const SubscriptionAgreements = ({
  paymentMethod,
  subscription,
  onProductAgreementsChange,
}: Props): JSX.Element => (
  <DynamicProductAgreements
    product={subscription}
    className="col-12 col-lg-8"
    paymentMethod={paymentMethod}
    onProductAgreementsChange={onProductAgreementsChange}
  />
)
