import * as React from 'react'
import { useRules } from '@hooks/use-rules'
import { useLink } from '@hooks/use-link'
import { AgreementSection, BASE_RULES } from '@components/agreement-section'
import { AvailableRuleKeyword } from '@models/app-data'
import { Checkbox } from '@components/controls/checkbox'
import { PaymentModalAgreements } from '@modules/wallet/modal/payment-method-modal'

type ProductRules = { optional_rules: AvailableRuleKeyword[]; required_rules: AvailableRuleKeyword[] }

interface Props<T> extends PaymentModalAgreements {
  product: T
  omitPaymentRules?: boolean
  wrapperClassName?: string
  className?: string
}

export const DynamicProductAgreements = <T extends ProductRules>({
  product,
  onProductAgreementsChange,
  omitPaymentRules,
  paymentMethod,
  className,
  wrapperClassName,
}: Props<T>): JSX.Element => {
  const { getRules } = useRules()
  const { openLink } = useLink()

  const rules = [...product.optional_rules, ...product.required_rules]

  const rulesToShow = getRules(rules.filter(rule => !BASE_RULES.includes(rule)))

  const handleOpenLink = (url: string | undefined) => (event: React.MouseEvent) => {
    event.preventDefault()
    openLink(url)
  }

  return (
    <AgreementSection
      className={className}
      wrapperClassName={wrapperClassName}
      requiredProductRules={product.required_rules}
      optionalProductRules={product.optional_rules}
      paymentMethod={paymentMethod}
      onProductAgreementsChange={onProductAgreementsChange}
      omitPaymentRules={omitPaymentRules}
    >
      {rulesToShow.map(rule => (
        <Checkbox
          key={rule?.keyword}
          className="checkbox--small"
          name={rule?.keyword || ''}
          label={
            <span className="font-size-sm">
              Oświadczam, że zapoznałem się z treścią dokumentu
              <span
                className="text-primary text-decoration-none fw-bold mx-1 cursor-pointer"
                onClick={handleOpenLink(rule?.url)}
              >
                {rule?.name}
              </span>
              i akceptuję jego postanowienia.
            </span>
          }
        />
      ))}
    </AgreementSection>
  )
}
