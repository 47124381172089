import * as React from 'react'
import { formatPriceShort } from '@helpers/price-helper'
import { Spinner } from '@components/loaders/spinner'

interface Props {
  price: number | string
  pricePrefix?: string
  priceItemClassName?: string
  isLoading: boolean
}

export const PriceWithSpinner = ({ isLoading, price, priceItemClassName, pricePrefix }: Props): JSX.Element => (
  <div>
    {isLoading ? (
      <Spinner className="text-primary spinner--small mx-2" color="gold" />
    ) : (
      <strong className={priceItemClassName}>
        {pricePrefix}
        {formatPriceShort(price)}
      </strong>
    )}
  </div>
)
