import * as React from 'react'
import { ReservationReceiptModalHeader } from '@modules/reservations/modals/reservation-receipt-modal-header'
import { ReservationReceiptModalRow } from '@modules/reservations/modals/reservation-receipt-modal-row'
import { BillVoucher } from '@models/bill'
import { ReservationReceiptModalRoomPurchases } from '@modules/reservations/modals/reservation-receipt-modal-room-purchases'
import { Guest } from '@models/reservation'

interface Props {
  voucher: BillVoucher
  index: number
  guests: Guest[]
}

export const ReservationReceiptModalSingleVoucher = ({ voucher, index, guests }: Props): JSX.Element => (
  <div className="receipt-box py-2">
    <div className="font-size-xl fw-bold text-muted my-2 px-2">
      {index}. {voucher.name} podsumowanie
    </div>
    <ReservationReceiptModalHeader />
    <ReservationReceiptModalRoomPurchases shopping={voucher.shopping} guests={guests} />
    <ReservationReceiptModalRow
      titleColumn="Wykorzystano"
      priceColumn={voucher.shopping.total_price_brutto}
      fiscalValue={voucher.fiscalization_price_brutto}
      variant="negative"
    />
  </div>
)
