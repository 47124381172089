import * as React from 'react'
import { ModalFooter } from 'react-bootstrap'
import * as clsx from 'clsx'
import { ModalFooterProps } from '@components/modals/modal-footer'
import { IconWithText } from '@components/icon-with-text'
import { LoadingTrapezoidButton } from '@components/controls/loading-trapezoid-button'

export const ModalFooterDesktop = ({
  onClose,
  onSubmit,
  withCancelButton,
  submitLabel = 'Zapisz',
  submitClassName,
  submitIcon,
  savingLabel,
  cancelLabel = 'Anuluj',
  cancelClassName,
  cancelIcon = 'ms-4 me-2 font-size-xl uil-multiply',
  buttonDisabled,
  isLoading,
  saveButtonVariant,
  withSubmit = true,
}: ModalFooterProps): JSX.Element => (
  <ModalFooter
    className={clsx('bg-light p-0 flex-nowrap', {
      'justify-content-between': withCancelButton,
      'justify-end': !withCancelButton,
    })}
  >
    {withCancelButton && (
      <IconWithText
        data-testid="closing-button"
        text={cancelLabel}
        onClick={onClose}
        iconClassName={cancelIcon}
        className={clsx('d-flex align-items-center text-darker-gray py-2', cancelClassName)}
        textClassName="fw-bold font-size-lg"
      />
    )}
    {withSubmit && (
      <LoadingTrapezoidButton
        isLoading={isLoading}
        onClick={onSubmit}
        disabled={buttonDisabled || isLoading}
        variant={saveButtonVariant}
        className="font-size-lg text-white"
        loadingLabel={savingLabel}
      >
        <IconWithText
          text={submitLabel}
          iconClassName={submitIcon}
          className={clsx('d-flex align-items-center text-white', submitClassName)}
          textClassName="fw-bold font-size-lg"
        />
      </LoadingTrapezoidButton>
    )}
  </ModalFooter>
)
