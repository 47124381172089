import * as React from 'react'
import { CashbackVoucherDetails } from '@models/client'
import { CashbackVoucherExpandableHeader } from '@modules/special-products/cashback-vouchers/expandable-row-header/cashback-voucher-expandable-header'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import { useCashbackVoucherRules } from '@modules/special-products/cashback-vouchers/use-cashback-voucher-rules'
import * as clsx from 'clsx'

interface Props {
  voucherDetails: CashbackVoucherDetails
}

export const CashbackVoucherInGenerationDetails = ({ voucherDetails }: Props): JSX.Element => {
  const [showPurchaseHistoryModal] = useModal('CashbackVoucherPurchaseHistoryModal')
  const showRule = useCashbackVoucherRules()

  const handleShowPurchaseHistoryModal = () => {
    showPurchaseHistoryModal(null, {
      voucher: voucherDetails,
      title: (
        <span className="fw-normal">
          <strong>Historia zakupów</strong>{' '}
          <span className="d-none d-sm-inline">(rezerwacja {voucherDetails.source_booking})</span>
        </span>
      ),
    })
  }

  return (
    <>
      <div className="mt-3 col-10 mx-auto font-size-sm text-center fw-normal">
        Voucher rePLAY! generuje się podczas Twojego pobytu w ramach rezerwacji{' '}
        <strong>{voucherDetails.source_booking}</strong>. Wartość vouchera będzie wynosić 20% kwoty wydanej na
        automatach podczas obecnego pobytu.
      </div>

      <IconWithText
        text="Szczegóły oferty dostępne w regulaminie"
        iconClassName="uil-file-check-alt me-2 font-size-icon-18"
        className="text-muted fw-bold cursor-pointer mt-4 text-center justify-content-center"
        textClassName="fw-normal"
        onClick={showRule}
      />

      <p className="text-center text-success font-size-lg mt-4">Zebrane środki:</p>

      <div
        className={clsx('cashback-voucher__expandable-row mt-2 border rounded', {
          'is-generating': voucherDetails.status === 'generate',
        })}
      >
        <CashbackVoucherExpandableHeader voucher={voucherDetails} withStatus={false} />
      </div>

      <IconWithText
        text="Historia zakupów"
        iconClassName="uil-receipt me-2 font-size-icon-18"
        className="text-muted fw-bold cursor-pointer my-3 justify-content-center"
        onClick={handleShowPurchaseHistoryModal}
      />
    </>
  )
}
