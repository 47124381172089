import * as React from 'react'
import { useSelector } from 'react-redux'
import { checkedInReservationsSelector } from '@store/selectors/reservations'
import { CheckedInReservationsList } from '@modules/reservations/checked-in/checked-in-reservation-list'

export const CheckedInReservations = (): JSX.Element => {
  const reservations = useSelector(checkedInReservationsSelector)

  return (
    <div>
      {!!reservations.length && (
        <div className="card border-0 p-0 p-xl-3 mb-3" data-testid="checked-in-reservations-wrapper">
          <h4 className="text-danger fw-bold mb-3">Trwające rezerwacje</h4>
          <CheckedInReservationsList reservations={reservations} />
        </div>
      )}
    </div>
  )
}
