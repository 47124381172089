import { IconWithText } from '@components/icon-with-text'
import { useCallCenterData } from '@hooks/use-call-center-data'
import * as React from 'react'
import { useLink } from '@hooks/use-link'

const ECOMMERCE_URL = 'https://sklep.holidaypark.pl'

export const ContactInfo = (): JSX.Element => {
  const { phoneNumber, todayFromHour, todayToHour, email } = useCallCenterData()

  const { openLink } = useLink()

  const handleEcommerceRedirectClick = () => {
    openLink(ECOMMERCE_URL)
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between w-100 mt-3">
        <IconWithText
          text={
            <>
              <div className="fw-bold">E-Sklep Holiday</div>
              <div className="font-size-sm">
                <span className="fw-normal">sklep.holidaypark.pl</span>
              </div>
            </>
          }
          iconClassName="uil-store-alt me-2 font-size-icon-18"
          className="text-muted align-items-baseline"
        />
        <button
          className="text-muted fw-bold text-decoration-none btn btn-outline-secondary"
          onClick={handleEcommerceRedirectClick}
        >
          Kup teraz
        </button>
      </div>
      <div className="d-flex align-items-center justify-content-between w-100 mt-3">
        <IconWithText
          text={
            <>
              <div className="fw-bold">Kontakt telefoniczny</div>
              <div className="font-size-sm">
                {phoneNumber} ({todayFromHour} - {todayToHour})
              </div>
            </>
          }
          iconClassName="uil-phone me-2 font-size-icon-18"
          className="text-muted align-items-baseline"
        />
        <a className="text-muted fw-bold text-decoration-none" href={`tel:${phoneNumber}`}>
          Zadzwoń
        </a>
      </div>
      <div className="d-flex align-items-center justify-content-between w-100 mt-4">
        <IconWithText
          text={
            <>
              <div className="fw-bold">Kontakt mailowy</div>
              <div className="text-muted font-size-sm">{email}</div>
            </>
          }
          iconClassName="uil-envelope-alt me-2 font-size-icon-18"
          className="text-muted align-items-baseline"
        />
        <a className="text-muted fw-bold text-decoration-none" href={`mailto:${email}`}>
          Wyślij e-mail
        </a>
      </div>
    </>
  )
}
