import * as React from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { ModalBody } from 'react-bootstrap'
import { ModalFooter } from '@components/modals/modal-footer'
import { useFormRequest } from '@hooks/use-api-request'
import { Alert } from '@components/alert'
import { formatPriceShort } from '@helpers/price-helper'
import { Checkbox } from '@components/controls/checkbox'
import { useLink } from '@hooks/use-link'
import { usePaymentModal } from '@hooks/use-payment-modal'
import { useRules } from '@hooks/use-rules'
import { useCreateCouponItemMutation } from '@api/coupons'
import { Reservation } from '@models/reservation'
import { CouponItemFormInputs } from '@modules/coupons/coupon-checked-in-reservation'

interface FormInputs {
  coupons_rules: boolean
}

interface Props {
  handleClose: () => void
  reservation: Reservation
  bonusAmount: number
  couponData: CouponItemFormInputs
}

export const CouponPurchaseModal = ({ handleClose, reservation, bonusAmount, couponData }: Props): JSX.Element => {
  const { getRules } = useRules()
  const { openLink } = useLink()

  const methods = useForm<FormInputs>()
  const showPaymentModal = usePaymentModal({})

  const [couponRules] = getRules(['coupons_rules'])
  const [createCouponItem] = useCreateCouponItemMutation()

  const isRuleAccepted = useWatch({ control: methods.control, name: 'coupons_rules' })

  const { action: onSubmit, isLoading } = useFormRequest(async () => {
    const couponItem = await createCouponItem({
      price: couponData.amount,
      coupon_type: couponData.type,
      value: bonusAmount,
      booking: reservation.id,
    }).unwrap()

    showPaymentModal({ amount: couponData.amount, source: 'client-panel-app' }, couponItem.urls.payment)
  }, methods.setError)

  const handleRulesLinkClick = (event: React.MouseEvent) => {
    event.preventDefault()
    openLink(couponRules?.url)
  }

  return (
    <>
      <ModalBody className="modal-scrollable p-0">
        <FormProvider {...methods}>
          <img
            src={require('@assets/images/coffe-shop.webp')}
            alt="Kawiarnia Holiday Park & Resort"
            className="object-fit-cover"
            width="100%"
            height={200}
          />
          <div className="p-2">
            <Alert variant="info" className="mt-3" iconClassName="align-self-center font-size-xl">
              <p className="text-muted mb-0">Pamiętaj, zakupione kupony są ważne do końca obecnego pobytu.</p>
            </Alert>
            <div className="d-flex align-items-center justify-content-between my-4 font-size-xl">
              <strong className="text-muted">Do zapłaty łącznie:</strong>
              <strong className="text-danger font-size-xxl ">{formatPriceShort(couponData.amount)}</strong>
            </div>
            <Checkbox
              className="checkbox--small mb-4"
              name="coupons_rules"
              id="coupons_rules"
              label={
                <span className="font-size-sm">
                  Oświadczam, że zapoznałem się z treścią dokumentu
                  <span
                    className="text-primary text-decoration-none fw-bold mx-1 cursor-pointer text-nowrap"
                    onClick={handleRulesLinkClick}
                  >
                    {couponRules?.name}
                  </span>
                  i akceptuję jego postanowienia.
                </span>
              }
            />
          </div>
        </FormProvider>
      </ModalBody>
      <ModalFooter
        withCancelButton
        isLoading={isLoading}
        onSubmit={onSubmit}
        cancelLabel="Wstecz"
        submitLabel="Przejdź do płatności"
        buttonDisabled={!isRuleAccepted}
        onClose={handleClose}
        submitClassName="coupons-view__purchase-modal__submit"
      />
    </>
  )
}
