import { ReservationDetails } from '@models/reservation'
import * as React from 'react'
import { ReservationVoucherUsedState } from '@modules/reservations/reservation-voucher-used-state'
import * as clsx from 'clsx'

interface Props {
  reservationDetails: ReservationDetails
}

export const ReservationVouchers = ({ reservationDetails }: Props): JSX.Element => {
  const activeWallets = reservationDetails.wallets.filter(wallet => wallet.is_active)

  return (
    <div className="d-flex flex-wrap">
      {activeWallets.map((wallet, index) => (
        <ReservationVoucherUsedState
          key={index}
          wallet={wallet}
          wrapperClassName={clsx({
            'ms-lg-1': index % 2 === 1 && activeWallets.length > 1,
            'me-lg-1': index % 2 === 0 && activeWallets.length > 1,
          })}
        />
      ))}
    </div>
  )
}
