import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { IconWithText } from '@components/icon-with-text'
import { useSelector } from 'react-redux'
import { RootState, useAppDispatch } from '@store/index'
import { useHistory } from 'react-router'
import { LogInPayload } from '@api/authorization'
import { useFormRequest } from '@hooks/use-api-request'
import { logInUser } from '@store/actions/auth-actions'
import { NavigationPath } from '@models/routes'
import { ButtonLoading } from '@components/controls/button-loading'
import { PasswordPreviewFormGroup } from '@components/form-controls/password-preview-form-group'
import { useQueryParams } from '@hooks/use-query-params'
import { selectClientDetails } from '@store/selectors/client-selectors'
import { useGtmEvents } from '@hooks/use-gtm-events'

interface Props {
  onAccountCreate: () => void
  onPasswordRestore: () => void
}

export const Login = ({ onAccountCreate, onPasswordRestore }: Props): JSX.Element => {
  const params = useQueryParams()

  const clientDetails = useSelector(selectClientDetails)

  const isAuthenticated = useSelector((state: RootState) => state.authState.isAuth)

  const invalidPassword = params.get('invalid_password') as unknown as string
  const nextQuery = params.get('next') as unknown as string
  const nextQueryId = params.get('id')

  const history = useHistory()

  const dispatch = useAppDispatch()
  const { login } = useGtmEvents()

  const methods = useForm<LogInPayload>({
    defaultValues: {
      username: '',
      password: '',
    },
  })

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: LogInPayload) => {
      const clientDetails = await dispatch(logInUser(payload))
      login(clientDetails.id)
    },

    methods.setError,
    { nonFieldErrorsAs: 'username' },
  )

  React.useEffect(() => {
    if (!isAuthenticated) return
    if (nextQuery) {
      history.replace(`${nextQuery}${nextQueryId ? `&id=${nextQueryId}` : ''}`)
      return
    }
    if (clientDetails.profile.rules.length) history.push(NavigationPath.Root)
  }, [isAuthenticated, clientDetails])

  React.useEffect(() => {
    if (invalidPassword && parseInt(invalidPassword, 10) === 1) {
      methods.setError('password', { message: 'Nieprawidłowy login lub hasło.' })
    }
  }, [invalidPassword])

  return (
    <>
      <h4 className="text-secondary fw-bold text-center mt-5 mb-2">Zaloguj się do swojego Konta Gościa</h4>
      <p className="mb-4 text-center font-size-12">
        Zaloguj się do swojego Konta Gościa i zarządzaj swoimi rezerwacjami
      </p>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <CustomFormGroup
            label="Wpisz swój adres e-mail:"
            inputName="username"
            formControlProps={{ placeholder: 'Wpisz e-mail', type: 'email' }}
          />
          <PasswordPreviewFormGroup
            formGroupProps={{ className: 'mt-4' }}
            label="Wpisz hasło do konta:"
            inputName="password"
            formControlProps={{ placeholder: 'Wpisz hasło' }}
          />
          <strong className="text-secondary text-end d-block mt-2 cursor-pointer" onClick={onPasswordRestore}>
            Nie pamiętam hasła
          </strong>

          <ButtonLoading
            isLoading={isLoading}
            loadingLabel="Logowanie..."
            className="btn-primary authorization-layout__button text-white"
            type="submit"
          >
            <strong>Zaloguj się</strong>
          </ButtonLoading>
        </form>
        <IconWithText
          onClick={onAccountCreate}
          text="Nie posiadasz konta? Utwórz je teraz"
          iconClassName="uil-user me-2 font-size-lg"
          className="my-4 fw-bold justify-content-center"
        />
      </FormProvider>
    </>
  )
}
