import { AnyAction, configureStore, ThunkDispatch } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { throwMiddleware } from '@store/throw-middleware'
import appDataState from '@store/reducers/app-data-reducers'
import authState from '@store/slices/auth-slices'
import clientState from '@store/reducers/client-reducers'
import modalState from '@store/slices/modal-slice'
import reservationsState from '@store/slices/reservations-slice'
import expandedReservationState from '@store/slices/expanded-reservation-slice'
import initialFeedingsImprovementState from '@store/slices/initial-feedings-improvement-slice'
import guestsState from '@store/slices/guests-slice'
import walletState from '@store/reducers/wallet-reducers'
import webSocketState from '@store/slices/websocket-slice'
import type {} from 'redux-thunk/extend-redux'
import { cashbackVouchersApi } from '@api/cashback-vouchers'
import { couponsApi } from '@api/coupons'

export const store = configureStore({
  reducer: {
    appDataState,
    authState,
    clientState,
    expandedReservationState,
    initialFeedingsImprovementState,
    guestsState,
    modalState,
    reservationsState,
    walletState,
    webSocketState,
    [couponsApi.reducerPath]: couponsApi.reducer,
    [cashbackVouchersApi.reducerPath]: cashbackVouchersApi.reducer,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      cashbackVouchersApi.middleware,
      couponsApi.middleware,
      throwMiddleware,
    ),
})

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = ThunkDispatch<RootState, any, AnyAction>
export const getState = () => store.getState()

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export default store
