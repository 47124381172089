import * as React from 'react'
import { LabeledRow } from '@components/labeled-row'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { Col, Row } from 'react-bootstrap'
import { Checkbox } from '@components/controls/checkbox'
import { GusButton } from '@components/controls/gus-button'
import { CompanyDataFromGus } from '@models/invoice'
import { ErrorOption, FormProvider, useForm, useWatch } from 'react-hook-form'
import { InvoiceDataForm } from '@modules/user/invoice/form'
import { useDispatch, useSelector } from 'react-redux'
import { useFormRequest } from '@hooks/use-api-request'
import { IconWithText } from '@components/icon-with-text'
import { CircleOutlineRadioButton } from '@components/controls/circle-outline-radio-button'
import { ClientDetailsProfile, InvoiceType } from '@models/client'
import { updateClientDetails } from '@store/actions/client-actions'
import { useDebounce, useDidUpdate } from 'rooks'
import { RootState } from '@store/index'
import { Country } from '@models/app-data'

interface FormInputs {
  invoice: boolean
  invoice_company: string
  invoice_street: string
  invoice_postcode: string
  invoice_city: string
  invoice_country: Country
  invoice_nip: string
  invoice_type: InvoiceType
}

interface Props {
  clientProfile: ClientDetailsProfile
}

export const InvoiceData = ({ clientProfile }: Props): JSX.Element => {
  const [isEdit, setEdit] = React.useState(false)

  const countries = useSelector((state: RootState) => state.appDataState.appData.countries)

  const defaultCountry = countries.find((country: Country) => country.value === clientProfile.invoice_country) || {
    value: 'PL',
    label: 'Polska',
  }

  const invoiceData = React.useMemo(
    () => ({
      invoice: clientProfile.invoice,
      invoice_company: clientProfile.invoice_company,
      invoice_street: clientProfile.invoice_street,
      invoice_postcode: clientProfile.invoice_postcode,
      invoice_city: clientProfile.invoice_city,
      invoice_nip: clientProfile.invoice_nip,
      invoice_type: clientProfile.invoice_type,
      invoice_country: defaultCountry,
    }),
    [
      clientProfile.invoice,
      clientProfile.invoice_company,
      clientProfile.invoice_street,
      clientProfile.invoice_postcode,
      clientProfile.invoice_city,
      clientProfile.invoice_nip,
      clientProfile.invoice_type,
      defaultCountry,
    ],
  )

  const dispatch = useDispatch()

  const methods = useForm<FormInputs>({
    defaultValues: invoiceData,
  })

  const { action: handleSave, isLoading: isSaving } = useFormRequest(async (data: FormInputs, showDisableEditMode) => {
    const payload = { ...data, ...(data.invoice_country && { invoice_country: data.invoice_country.value }) }
    await dispatch(updateClientDetails(payload))
    if (showDisableEditMode) setEdit(false)
  }, methods.setError)

  const { action: handleInvoiceSelection } = useFormRequest(async payload => {
    await dispatch(updateClientDetails(payload))
  }, methods.setError)

  const [isInvoiceChecked, nip, invoiceType, invoiceCountry] = useWatch({
    control: methods.control,
    name: ['invoice', 'invoice_nip', 'invoice_type', 'invoice_country'],
  })

  const isInvoiceForIndividualGuest = React.useMemo(() => invoiceType === 'individual', [invoiceType])

  const isDataEmpty = !(
    clientProfile.invoice_company &&
    clientProfile.invoice_street &&
    clientProfile.invoice_postcode &&
    clientProfile.invoice_city
  )

  const handleCompanyDataUpdate = React.useCallback(
    (companyData: CompanyDataFromGus) => {
      const data = {
        ...methods.getValues(),
        invoice_company: companyData.name,
        invoice_street: companyData.street_address,
        invoice_postcode: companyData.postal_code,
        invoice_city: companyData.city,
      }
      methods.reset(data)
      methods.clearErrors('invoice_nip')
      handleSave(data)
    },
    [invoiceCountry, handleSave, methods.getValues()],
  )

  const handleGusError = React.useCallback(
    (error: ErrorOption) => {
      methods.setError('invoice_nip', error)
    },
    [methods.setError],
  )

  const handleCancel = React.useCallback(() => {
    if (isDataEmpty) {
      methods.reset({ invoice: false })
      return
    }

    setEdit(false)
    methods.reset(invoiceData)
  }, [isDataEmpty, invoiceData])

  const handleEdit = React.useCallback(() => setEdit(true), [])

  const debouncedInvoiceChange = useDebounce(handleInvoiceSelection, 300)
  const memoizedDebouncedInvoiceChange = React.useCallback(debouncedInvoiceChange, [])

  useDidUpdate(() => {
    methods.reset(invoiceData)
  }, [invoiceData])

  useDidUpdate(() => {
    memoizedDebouncedInvoiceChange({ invoice: isInvoiceChecked })
  }, [isInvoiceChecked])

  const handleInvoiceTypeChange = (invoice_type: InvoiceType) => () => {
    const isDataForTypeAlreadyFilled = invoice_type === invoiceData.invoice_type && !isDataEmpty

    methods.reset({
      invoice: true,
      invoice_company: '',
      invoice_street: '',
      invoice_postcode: '',
      invoice_city: '',
      invoice_nip: '',
      invoice_type,
      invoice_country: defaultCountry,
      ...(isDataForTypeAlreadyFilled && invoiceData),
    })

    setEdit(!isDataForTypeAlreadyFilled)
  }

  const handleSubmit = async params => {
    await handleSave(params, true)
  }

  React.useEffect(() => {
    if (invoiceData.invoice_type) {
      setEdit(isDataEmpty)
    }
  }, [invoiceData.invoice_type, isDataEmpty])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <LabeledRow
          className="align-items-center"
          label={<div className="text-primary font-size-20 fw-bold">Dane do faktury</div>}
        >
          <Col md={8}>
            <Checkbox name="invoice" label="Chcę otrzymywać faktury" className="d-inline-flex" />
          </Col>
        </LabeledRow>
        {isInvoiceChecked && (
          <>
            <LabeledRow>
              <div className="d-flex flex-sm-row flex-column">
                <CircleOutlineRadioButton
                  name="invoice_type"
                  label="Faktura na firmę"
                  value="company"
                  id="company"
                  onChange={handleInvoiceTypeChange('company')}
                  wrapperClassName="mb-3 mb-sm-0 me-3"
                />
                <CircleOutlineRadioButton
                  name="invoice_type"
                  label="Faktura na osobę indywidualną"
                  value="individual"
                  id="individual"
                  onChange={handleInvoiceTypeChange('individual')}
                />
              </div>
            </LabeledRow>
            {isEdit && (
              <div className="container-sm">
                <Row>
                  <Col xl={8} className="fw-light font-size-12 ms-auto mt-xl-0 mt-3 px-0 px-sm-2">
                    Wprowadż prawidłowe dane {!isInvoiceForIndividualGuest && <span>firmy</span>}
                  </Col>
                </Row>
              </div>
            )}
            {isInvoiceForIndividualGuest ? (
              isEdit && (
                <LabeledRow className="align-items-center mt-2 mt-xl-0" label="PESEL" hideLabelOnWrap>
                  <Col xxl={8}>
                    <CustomFormGroup
                      inputName="invoice_nip"
                      formatOptions={{
                        format: '###########',
                        allowEmptyFormatting: true,
                      }}
                      label="PESEL"
                    />
                  </Col>
                </LabeledRow>
              )
            ) : (
              <LabeledRow hideLabelOnWrap className="align-items-center mt-2 mt-xl-0" label="Numer NIP">
                <Row className="align-items-start gx-2">
                  <Col xl={6}>
                    <CustomFormGroup
                      formControlProps={{ className: 'letter-spacing-5' }}
                      label="Numer NIP"
                      inputName="invoice_nip"
                      formatOptions={{
                        format: '###-###-##-##',
                        allowEmptyFormatting: true,
                      }}
                    />
                  </Col>
                  <Col xl={4}>
                    <GusButton
                      className="mt-2 form-control-height-lg font-size-14 fw-bold"
                      nip={nip}
                      setCompanyData={handleCompanyDataUpdate}
                      onError={handleGusError}
                    >
                      <strong>Pobierz dane z GUS</strong>
                    </GusButton>
                  </Col>
                </Row>
              </LabeledRow>
            )}
            {!isDataEmpty && (
              <LabeledRow hideLabelOnWrap>
                <div className="font-size-12 fw-light text-center text-xl-start">
                  {!isEdit && (
                    <div className="d-flex flex-column align-items-center align-items-xl-start mt-3">
                      <div>{clientProfile.invoice_company}</div>
                      <div>{clientProfile.invoice_street}</div>
                      {clientProfile.invoice_postcode && clientProfile.invoice_city && (
                        <div>
                          {clientProfile.invoice_postcode}, {clientProfile.invoice_city},{' '}
                          {invoiceData.invoice_country.label}
                        </div>
                      )}

                      <div>
                        {isInvoiceForIndividualGuest && clientProfile.invoice_nip && (
                          <span>PESEL: {clientProfile.invoice_nip}</span>
                        )}
                        {!isInvoiceForIndividualGuest && <span>NIP: {clientProfile.invoice_nip}</span>}
                      </div>
                      <IconWithText
                        className="text-muted align-items-center mt-3"
                        iconClassName="uil-edit-alt font-size-xl me-1"
                        text={<div className="fw-bold">Edytuj dane {!isInvoiceForIndividualGuest && 'firmy'} </div>}
                        onClick={handleEdit}
                        data-testid="invoice-data-edit-button"
                      />
                    </div>
                  )}
                </div>
              </LabeledRow>
            )}
            {!isEdit && isDataEmpty && (
              <LabeledRow>
                <div onClick={handleEdit}>
                  <IconWithText
                    text="Wpisz dane"
                    iconClassName="uil-edit me-2 font-size-icon-18 text-muted"
                    className="py-2 fw-bold text-muted cursor-pointer"
                  />
                </div>
              </LabeledRow>
            )}
            {isEdit && <InvoiceDataForm isSaving={isSaving} onCancel={handleCancel} countries={countries} />}
          </>
        )}
      </form>
    </FormProvider>
  )
}
