import * as React from 'react'
import * as clsx from 'clsx'
import { NavigationPath } from '@models/routes'

interface Props {
  code: number
  text: React.ReactNode
  redirectElement?: React.ReactNode
}

export const ErrorPage = ({ code, text, redirectElement = <DefaultRedirectAction /> }: Props): JSX.Element => (
  <div className="error-page__wrapper">
    <div className="error-page__content">
      <div className="error-page__logo-section">
        <img
          className="error-page__logo"
          src={require('@assets/images/logo_holiday_color.svg')}
          alt="Logo holidaypark"
        />
        <div className="error-page__number-wrapper">
          <strong className={clsx('error-page__number-msg', { 'is-404': code === 404, 'is-500': code === 500 })}>
            BŁĄD
          </strong>
          <strong className="error-page__number">{code}</strong>
          <strong className="error-page__number__shadow">{code}</strong>
        </div>
        <span className="error-page__text">{text}</span>
        {redirectElement}
      </div>
      <img src={require('@assets/images/404-bg.webp')} alt={`Holidaypark ${code}`} className="error-page__image" />
    </div>
  </div>
)

const DefaultRedirectAction = () => {
  const handleRedirect = () => {
    window.location.href = NavigationPath.Root
  }

  return (
    <button className="btn error-page__button" onClick={handleRedirect}>
      Wróć do strony głównej
    </button>
  )
}
