import { NavigationPath } from '@models/routes'
import { ClientEvents } from '@models/websocket'
import { useQueryParams } from '@hooks/use-query-params'

export const useWebsocketActions = () => {
  const query = useQueryParams()

  const processingPaymentAction = (webSocket: WebSocket) => {
    const token = query.get('token')

    if (location.pathname.startsWith(NavigationPath.PaymentProcessing)) {
      webSocket.send(JSON.stringify({ payload: { token }, event: ClientEvents.LOGIN_TOKEN }))
    }
  }

  const openHandler = (websocket: WebSocket) => () => {
    processingPaymentAction(websocket)
  }

  return {
    openHandler,
  }
}
