import { CheckButton } from '@components/controls/check-button'
import { formatPriceShort } from '@helpers/price-helper'
import * as React from 'react'
import { getSelectedFeedingKey } from '@helpers/improvement-helper'
import { FeedingTypes, ReservationDetails } from '@models/reservation'
import { FeedingCalculations } from '@api/improvements'
import { compareAsc, differenceInDays } from 'date-fns'
import { createCombinedDateWithTime, DateFormats, formatDate, parseISODate } from '@helpers/date-helper'
import { FeedingSelection } from '@modules/reservations/improvements/kinds/feedings/feeding-details-modal'
import declination from '@helpers/declination'
import { useEditableCheck } from '@hooks/use-editable-check'
import { useAppSelector } from '@store/index'
import { selectInitialFeedingReservationImprovements } from '@store/slices/initial-feedings-improvement-slice'

export const AvailableFeedingSelections = [
  { title: 'Śniadanie', kind: 'breakfast' },
  { title: 'Obiadokolacja', kind: 'dinner' },
  { title: 'Pełne wyżywienie', kind: 'dinner_with_breakfast' },
]

interface Props {
  selectedFeedingType: FeedingTypes | null
  onFeedingSelectionChange: (feedingType: FeedingTypes) => void
  feedingCalculations: FeedingCalculations[]
  reservationDetails: ReservationDetails | undefined
  guestAmount: number
}

export const FeedingDetailsModalSelection = ({
  selectedFeedingType,
  onFeedingSelectionChange,
  feedingCalculations,
  reservationDetails,
  guestAmount,
}: Props): JSX.Element => {
  const initialFeedingImprovements = useAppSelector(selectInitialFeedingReservationImprovements)
  const { isInEditAllowedWindow } = useEditableCheck()

  const getFeedingCalculation = (feedingSelection: FeedingSelection | null) =>
    feedingCalculations.find(calculations => calculations.feeding_type === feedingSelection?.kind)

  const initialFeeding = React.useMemo((): FeedingTypes => {
    if (!reservationDetails) return 'none'
    return getSelectedFeedingKey(initialFeedingImprovements.filter(el => el.reservation === reservationDetails.token))
  }, [reservationDetails?.token, initialFeedingImprovements])

  React.useEffect(() => {
    if (!reservationDetails?.prices) return
    onFeedingSelectionChange(getSelectedFeedingKey(reservationDetails.prices.feeding.items))
  }, [reservationDetails?.prices])

  const getFeedingButtonDescription = (calculation: FeedingCalculations | undefined) => {
    const price = formatPriceShort(calculation?.total_price_brutto || '0')

    if (!calculation || !reservationDetails) return `${price}`

    if (
      calculation.dates.length ===
      differenceInDays(
        parseISODate(reservationDetails.date_from) as Date,
        parseISODate(reservationDetails.date_to) as Date,
      ) +
        1
    ) {
      return `na wszystkie dni pobytu w cenie ${price}`
    }

    if (calculation.dates.length === 1) {
      return `${formatDate(calculation.dates[0], DateFormats.MONTH_WITH_FULL_WORD_MONTH)} w cenie ${price}`
    }

    const sortedFeedingDates = calculation.dates.sort((a, b) =>
      compareAsc(parseISODate(a) as Date, parseISODate(b) as Date),
    )

    return `od ${formatDate(sortedFeedingDates[0], DateFormats.MONTH_WITH_FULL_WORD_MONTH)} do ${formatDate(
      sortedFeedingDates[sortedFeedingDates.length - 1],
      DateFormats.MONTH_WITH_FULL_WORD_MONTH,
    )} w cenie ${price}`
  }

  const isFeedingOptionDisabled = (feeding: FeedingSelection) => {
    if (
      initialFeeding === 'none' ||
      !reservationDetails ||
      isInEditAllowedWindow(createCombinedDateWithTime(reservationDetails.date_from, reservationDetails.arrival_time))
    )
      return false

    const currentFeedingIndex = AvailableFeedingSelections.findIndex(el => el.kind === initialFeeding)
    const feedingOptionIndex = AvailableFeedingSelections.findIndex(el => el.kind === feeding.kind)

    return feedingOptionIndex < currentFeedingIndex
  }

  return (
    <div className="px-3 px-xl-4 mb-4 container-lg">
      <div className="d-flex flex-column col-sm-9">
        {AvailableFeedingSelections.map((feeding: FeedingSelection) => {
          const calculation = getFeedingCalculation(feeding)
          if (!calculation) return null
          return (
            <CheckButton
              key={feeding.title}
              title={`${feeding.title} dla ${guestAmount} ${declination.person(guestAmount)}`}
              description={getFeedingButtonDescription(calculation)}
              isActive={feeding.kind === selectedFeedingType}
              disabled={isFeedingOptionDisabled(feeding)}
              className="w-100 mb-2"
              onClick={() => onFeedingSelectionChange(feeding.kind)}
            />
          )
        })}
      </div>
    </div>
  )
}
