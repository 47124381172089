import { ClientWalletItem } from '@models/wallet'
import { WalletActionsTypes, WalletActionTypesKeys } from '@store/action-types/wallet-action-types'
import { PaginationResponse } from '@models/index'

export interface WalletState {
  walletHistory: PaginationResponse<ClientWalletItem>
}

export const emptyWallet: PaginationResponse<ClientWalletItem> = {
  count: 0,
  next: '',
  page_link: '',
  previous: '',
  results: [],
}

const initialState: WalletState = {
  walletHistory: emptyWallet,
}

const walletHistoryState = (state: WalletState = initialState, action: WalletActionsTypes): WalletState => {
  switch (action.type) {
    case WalletActionTypesKeys.SET_WALLET_ITEMS:
      return { ...state, walletHistory: action.payload }
    default:
      return state
  }
}

export default walletHistoryState
