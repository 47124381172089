import axios from 'axios'
import store from '@store/index'
import { setAuthStatus } from '@store/slices/auth-slices'

const interceptorService = {
  setup: () => {
    axios.interceptors.response.use(
      response => response,
      async error => {
        if ([401, 403].includes(error.response.status)) {
          store.dispatch(setAuthStatus(false))
        }
        return Promise.reject(error)
      },
    )
  },
}

export default interceptorService
