import { CheckCardFormControl } from '@components/form-controls/check-card-form-control'
import Decimal from 'decimal.js'
import * as React from 'react'
import { useFormContext } from 'react-hook-form'
import { TargetOption } from './target-option'
import { formatPrice } from '@helpers/price-helper'
import { PaymentSummary } from '@models/reservation'
import { getRestToPay, getRestToPayDeposit, getRestToPayInstallment, shouldBePaidAllAtOnce } from '@helpers/reservation'
import { formatDate } from '@helpers/date-helper'
import { sortByDate } from '@helpers/utils'

export enum PaymentTarget {
  stayAndDeposit = 'booking_advance_with_deposit',
  stay = 'booking_advance',
  deposit = 'booking_deposit',
}

interface FormInputs {
  target: PaymentTarget
  amount: number
}

interface Props {
  paymentSummary: PaymentSummary
}

export const ReservationPaymentTargetForm = ({ paymentSummary }: Props): JSX.Element => {
  const { watch, setValue } = useFormContext<FormInputs>()
  const target = watch('target')

  const restToPay = getRestToPay(paymentSummary).toNumber()
  const restToPayDeposit = getRestToPayDeposit(paymentSummary).toNumber()
  const restToPayInstallment = getRestToPayInstallment(paymentSummary).toNumber()

  const isEntirePaymentRequired = shouldBePaidAllAtOnce(paymentSummary)

  const isDepositPaymentDisabled = new Decimal(restToPayDeposit).lte(0) || isEntirePaymentRequired
  const isStayPaymentDisabled = new Decimal(restToPayInstallment).lte(0) || isEntirePaymentRequired

  const isStayAndDepositPaymentDisabled =
    new Decimal(restToPayDeposit).lte(0) || new Decimal(restToPayInstallment).lte(0)

  const stayPaymentDates = sortByDate(
    [paymentSummary.rest.required_date, paymentSummary.required.required_date].filter(Boolean) as string[],
  )
  const earliestStayPaymentDate = formatDate(stayPaymentDates[0])

  const earliestStayAndDepositPaymentDate = formatDate(
    sortByDate([...stayPaymentDates, paymentSummary.rest.required_date_deposit].filter(Boolean) as string[])[0],
  )

  React.useEffect(() => {
    const value = {
      [PaymentTarget.stayAndDeposit]: restToPay,
      [PaymentTarget.stay]: restToPayInstallment,
      [PaymentTarget.deposit]: restToPayDeposit,
    }[target]
    setValue('amount', value ?? 0)
  }, [target, restToPay, restToPayDeposit, restToPayInstallment])

  return (
    <div>
      <div className="font-size-lg my-3">
        <strong>Wybierz z listy co chcesz opłacić:</strong>
      </div>
      <div className="d-flex flex-column">
        <CheckCardFormControl
          className="mb-2"
          fieldName="target"
          radioValue={PaymentTarget.stayAndDeposit}
          preventDeselect
          content={(active: boolean) => (
            <TargetOption
              active={active}
              title="Pobyt + Kaucja"
              subtitle={<span>Do zapłaty pozostało {formatPrice(restToPay)}</span>}
              date={earliestStayAndDepositPaymentDate}
            />
          )}
          disabled={isStayAndDepositPaymentDisabled}
        />
        <CheckCardFormControl
          className="mb-2"
          fieldName="target"
          radioValue={PaymentTarget.stay}
          preventDeselect
          content={(active: boolean) => (
            <TargetOption
              active={active}
              title="Pobyt"
              subtitle={<span>Do zapłaty pozostało {formatPrice(restToPayInstallment)}</span>}
              date={earliestStayPaymentDate}
            />
          )}
          disabled={isStayPaymentDisabled}
        />
        <CheckCardFormControl
          fieldName="target"
          radioValue={PaymentTarget.deposit}
          preventDeselect
          content={(active: boolean) => (
            <TargetOption
              active={active}
              title="Kaucja"
              subtitle={<span>Do zapłaty pozostało {formatPrice(restToPayDeposit)}</span>}
              date={paymentSummary.rest.required_date_deposit}
            />
          )}
          disabled={isDepositPaymentDisabled}
        />
      </div>
    </div>
  )
}
