import * as React from 'react'
import { CouponItem } from '@models/coupons'
import { Reservation } from '@models/reservation'
import * as R from 'ramda'
import { useAppSelector } from '@store/index'
import { selectAppData, selectCouponTypes } from '@store/selectors/app-data-selectors'
import { AnimatePresence, motion } from 'framer-motion'
import { CouponsLisItem } from '@modules/coupons/list/coupons-list-item'
import { CouponListTabs } from '@modules/coupons/list/coupon-list-tabs'

interface Props {
  coupons: CouponItem[]
  reservation: Reservation | undefined
}

export const CouponsList = ({ coupons, reservation }: Props): JSX.Element | null => {
  const { resorts } = useAppSelector(selectAppData)
  const [selectedTab, setSelectedTab] = React.useState('')
  const couponTypes = useAppSelector(selectCouponTypes)

  const bookingCoupons = React.useMemo(() => {
    const { active, used, expired } = coupons.reduce(
      (prev, coupon: CouponItem) => {
        if (reservation?.id !== coupon.booking) return prev
        if (coupon.status === 'active') return { ...prev, active: [...prev.active, coupon] }
        if (coupon.status === 'used') return { ...prev, used: [...prev.used, coupon] }
        if (coupon.status === 'expired') return { ...prev, expired: [...prev.expired, coupon] }
        return prev
      },
      { active: [], used: [], expired: [] },
    )

    return [...active, ...used, ...expired]
  }, [coupons, reservation])

  const grouped = R.groupBy((coupon: CouponItem) => {
    const couponName = couponTypes.find(couponType => couponType.id === coupon.coupon_type)
    return couponName?.configuration.point_of_sale === 'bistro' ? 'bistro' : 'kawiarni'
  })(bookingCoupons)

  React.useEffect(() => {
    if (!selectedTab && Object.keys(grouped).length) {
      setSelectedTab(Object.keys(grouped)[0])
    }
  }, [selectedTab, grouped])

  const tabs = React.useMemo(() => Object.keys(grouped), [grouped])

  if (!bookingCoupons.length) return null

  const resort = resorts.find(resort => resort.id === reservation?.resort_id)

  return (
    <div className="border-top border-white pt-4 col-12 col-md-8 mx-auto">
      <h5 className="fw-strong text-white font-size-xl text-center">Twoje kupony w ośrodku {resort?.name}:</h5>

      <CouponListTabs tabs={tabs} selectedTab={selectedTab} onTabChange={setSelectedTab} />
      <AnimatePresence>
        <motion.div
          key={selectedTab}
          initial={{ y: -10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
          layoutId="coupons-list"
        >
          {selectedTab && (
            <ul className="mt-4 ps-0 coupons-view__list">
              {grouped[selectedTab].map((coupon: CouponItem) => (
                <CouponsLisItem key={coupon.id} coupon={coupon} />
              ))}
            </ul>
          )}
        </motion.div>
      </AnimatePresence>
    </div>
  )
}
