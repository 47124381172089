import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectClientDetails } from '@store/selectors/client-selectors'
import { useModal } from '@components/modals/use-modal'
import { useApiRequest } from '@hooks/use-api-request'
import { fetchClientDetails } from '@store/actions/client-actions'
import { getReservations } from '@store/actions/reservations-action'
import { useAppUsageRulesCheck } from '@hooks/use-app-usage-rules-check'

interface ClientDataCheckResponse {
  isChecking: boolean
}

export const useClientDataCheck = (): ClientDataCheckResponse => {
  const clientDetails = useSelector(selectClientDetails)
  const dispatch = useDispatch()
  const { requiredRulesAccepted } = useAppUsageRulesCheck()

  const clientDetailsFetched = !!clientDetails.profile.rules.length

  const { action: fetchReservations } = useApiRequest(async () => {
    dispatch(getReservations())
  })

  const { isLoading, action: fetchClientDetailsData } = useApiRequest(async () => {
    dispatch(fetchClientDetails())
  })

  const [showRequiredDataRulesModal] = useModal('ClientDataCheckModal', {
    title: 'Akceptacja regulaminów',
  })

  React.useEffect(() => {
    if (clientDetailsFetched && !requiredRulesAccepted) {
      showRequiredDataRulesModal(null, {}, true)
    }
  }, [clientDetails.profile.rules, requiredRulesAccepted])

  React.useEffect(() => {
    fetchClientDetailsData()
  }, [])

  React.useEffect(() => {
    if (clientDetails.id) {
      fetchReservations()
    }
  }, [clientDetails.id])

  return {
    isChecking: isLoading,
  }
}
