import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import { DataCompletionTab } from '@modules/reservations/pending/data-completion-modal'
import declination from '@helpers/declination'
import { SubscriptionDiscountLabel } from '@components/labels/subscription-discount-label'

interface Props {
  guestAmount: number
  onModalClose: () => void
}

export const FeedingDetailsModalContentTitle = ({ guestAmount, onModalClose }: Props): JSX.Element => {
  const [toggleCompletionModal] = useModal('ReservationDataCompletionModal', {
    title: 'Uzupełnianie danych',
    initialTab: DataCompletionTab.guests,
  })

  const handleShowGuestsDataModal = () => {
    toggleCompletionModal()
    onModalClose()
  }

  return (
    <>
      <div className="d-flex flex-column flex-md-row gap-2 mb-1">
        <strong className="d-block">
          Wybierz wyżywienie dla {guestAmount} {declination.person(guestAmount)}
        </strong>
        <IconWithText
          text="Zmień Gości"
          iconClassName="uil-edit-alt me-2"
          className="text-muted opacity-50 ms-md-4"
          textClassName="font-size-sm"
          onClick={handleShowGuestsDataModal}
        />
      </div>
      <div className="d-inline-block mb-2">
        <SubscriptionDiscountLabel />
      </div>
    </>
  )
}
