import * as React from 'react'
import { ResortImprovement } from '@models/reservation'
import { formatPriceShort } from '@helpers/price-helper'
import { isParking } from '@helpers/improvement-helper'

interface Props {
  resortImprovement: ResortImprovement
}

export const ImprovementPrice = ({ resortImprovement }: Props): JSX.Element => (
  <>
    {resortImprovement.price_brutto_first_item && (
      <strong className="d-block text-darker-gray">
        {formatPriceShort(resortImprovement.price_brutto_first_item)} / {resortImprovement.price_type_2_display}
        <span> - {isParking(resortImprovement) ? 'pierwsze miejsce parkingowe' : 'pierwszy'}</span>
      </strong>
    )}
    <strong className="text-darker-gray">
      {formatPriceShort(resortImprovement.price_brutto)} / {resortImprovement.price_type_2_display}
      {resortImprovement.price_brutto_first_item && (
        <span> - {isParking(resortImprovement) ? 'kolejne miejsce parkingowe' : 'kolejny'}</span>
      )}
    </strong>
  </>
)
