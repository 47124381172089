import * as React from 'react'
import { NotificationEvents } from '@models/websocket'
import { useWebSocket } from '@hooks/use-web-socket'
import { ClientDetails } from '@models/client'
import { ReservationDetails } from '@models/reservation'
import { PaymentRequestPayload, usePaymentRedirect } from '@modules/payment-result/use-payment-redirect'
import { COUPON_ITEMS_API_TAG, couponsApi } from '@api/coupons'
import { useAppDispatch } from '@store/index'

export const ClientWebSocketHandler: React.FC = () => {
  const { handlePaymentRequestChange } = usePaymentRedirect()
  const dispatch = useAppDispatch()

  useWebSocket<ClientDetails | ReservationDetails | PaymentRequestPayload, []>((message, payload) => {
    switch (message) {
      case NotificationEvents.CLIENT_CHANGE:
        //TODO Backend resend response for client app requests what makes UI outdated
        // dispatch(setClientDetails(payload as ClientDetails))
        break
      case NotificationEvents.PAYMENT_REQUEST_CHANGE:
        handlePaymentRequestChange(payload as PaymentRequestPayload)
        break
      case NotificationEvents.COUPON_ITEM_CHANGE:
        dispatch(couponsApi.util?.invalidateTags([COUPON_ITEMS_API_TAG]))
        break
    }
  }, [])

  return null
}
