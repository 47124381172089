import * as React from 'react'
import { ReservationDetails } from '@models/reservation'
import { CheckedInReservationFooter } from '@modules/reservations/checked-in/checked-in-reservation-footer'
import { PendingReservationFooter } from '@modules/reservations/pending/footer'
import { isCheckedInReservation, isPendingReservation } from '@helpers/reservation'

interface Props {
  reservationDetails: ReservationDetails
}

export const ReservationRowFooter = ({ reservationDetails }: Props): JSX.Element => (
  <>
    {isCheckedInReservation(reservationDetails) && (
      <CheckedInReservationFooter reservationDetails={reservationDetails} />
    )}
    {isPendingReservation(reservationDetails) && <PendingReservationFooter reservationDetails={reservationDetails} />}
  </>
)
