import * as React from 'react'

interface Props {
  text: string
  underlineText: string
  children: React.ReactNode
  customImage?: React.ReactNode
}

export const ItemChangeConfirmationModalContent = ({
  text,
  underlineText,
  children,
  customImage = (
    <img src={require('@assets/images/remove-improvement-modal-bg.webp')} alt="Usuń ulepszenie" className="my-2" />
  ),
}: Props): JSX.Element => (
  <div className="d-flex align-items-center flex-column py-4">
    <span className="text-dark font-size-xxl text-center px-3 mb-4">
      {text}
      <br />
      <strong className="position-relative mx-auto">
        <span className="underline-shadow"> {underlineText}</span>
      </strong>
    </span>
    {customImage}
    {children}
  </div>
)
