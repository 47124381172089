import * as React from 'react'
import * as clsx from 'clsx'
import { Button, Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { NavigationPath } from '@models/routes'

export interface ConfirmationModalProps {
  title: React.ReactNode
  subtitle: React.ReactNode
  action?: ({ onClose }) => React.ReactNode
  variant: 'success' | 'error'
}

interface Props extends ConfirmationModalProps {
  handleClose: () => void
}

export const ConfirmationModal = ({ handleClose, title, subtitle, variant, action }: Props): JSX.Element => {
  const isSuccess = variant === 'success'

  return (
    <Modal.Body>
      <div className="p-2 d-flex flex-column justify-content-center">
        <div className="position-relative mx-auto mt-4 mb-5 opacity-75">
          <div
            className={clsx(
              'position-absolute start-50 top-50 translate-middle rounded-circle border border-2',
              isSuccess ? 'border-success' : 'border-danger',
            )}
            style={{ width: '70px', height: '70px' }}
          />
          <i className={clsx('font-size-3xl', isSuccess ? 'uil-check text-success' : 'uil-times text-danger')} />
        </div>
        <p className="fw-bold font-size-lg col-10 mx-auto text-center text-secondary">{title}</p>
        <p className="col-10 mx-auto text-center mb-0 font-size-sm">{subtitle}</p>

        {action?.({ onClose: handleClose }) ?? <DefaultAction onClose={handleClose} />}
      </div>
    </Modal.Body>
  )
}

const DefaultAction = ({ onClose }) => {
  const history = useHistory()

  const moveToMainPage = () => {
    onClose()
    history.replace(NavigationPath.Reservations)
  }

  return (
    <Button
      variant="primary"
      onClick={moveToMainPage}
      className="mx-auto text-white px-5 btn-tall mt-5 mb-4 font-weight-semibold"
    >
      Wróć do rezerwacji
    </Button>
  )
}
