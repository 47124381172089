import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import { ModalFooter } from '@components/modals/modal-footer'
import { SubscriptionContractPeriod } from '@models/client'
import { SubscriptionContractPaymentsList } from '@modules/special-products/subscription-contracts/payment-list/subscription-contract-payments-list'
import { sortObjectListByDate } from '@helpers/utils'

interface Props {
  handleClose: () => void
  periods: SubscriptionContractPeriod[]
  repaymentDate: string | null
}

export const SubscriptionContractPaymentListModal = ({ handleClose, repaymentDate, periods }: Props): JSX.Element => {
  const sortedPeriods = sortObjectListByDate(periods, 'payment_date')

  return (
    <>
      <ModalBody className="modal-scrollable text-muted px-4">
        <SubscriptionContractPaymentsList repaymentDate={repaymentDate} periods={sortedPeriods} />
      </ModalBody>
      <ModalFooter withCancelButton withSubmit={false} onClose={handleClose} cancelLabel="Zamknij" />
    </>
  )
}
