import * as React from 'react'
import * as clsx from 'clsx'
import { RefObject } from 'react'
import { useInVisibleOnScreen } from '@hooks/use-in-visible-on-screen'
import { useDidUpdate } from 'rooks'

export type ScrollableSectionTypes = 'demanding' | 'feeding' | 'improvements' | 'stay-options'

export interface ScrollableTab {
  title: string
  key: ScrollableSectionTypes
  ref: RefObject<HTMLDivElement>
}

interface Props {
  tabs: ScrollableTab[]
  crossedItems: ScrollableSectionTypes[]
}

export const ScrollableSectionTabs = ({ tabs, crossedItems }: Props): JSX.Element => {
  const [activeTab, setActiveTab] = React.useState<ScrollableTab>(tabs[0])
  const tabsRef = React.useRef<HTMLDivElement>(null)

  const firstTab = tabs[0]

  const { isVisible: isFirstRefVisible } = useInVisibleOnScreen<HTMLDivElement>(firstTab.ref)

  const isActive = (key: ScrollableSectionTypes) => activeTab.key === key
  const isCrossed = (key: ScrollableSectionTypes) => crossedItems.includes(key)

  const handleTabClick = (tab: ScrollableTab) => () => {
    setActiveTab(tab)
    if (!tab.ref.current) return
    tab.ref.current.scrollIntoView({ behavior: 'smooth' })
  }

  const handleTabSwipe = () => {
    if (!tabsRef.current) return
    const minContentWidth = 420
    const paddingWidth = 25
    const widthDifference = minContentWidth - tabsRef.current.clientWidth - paddingWidth

    if (isActive('improvements') || isActive('stay-options')) {
      tabsRef.current.style.transform = `translateX(-${widthDifference}px)`
      return
    }

    tabsRef.current.style.transform = 'translateX(0)'
  }

  React.useEffect(() => {
    window.addEventListener('resize', handleTabSwipe)
    return () => window.removeEventListener('resize', handleTabSwipe)
  }, [])

  useDidUpdate(() => {
    if (isFirstRefVisible && activeTab.key !== firstTab.key) {
      setActiveTab(firstTab)
    }
  }, [isFirstRefVisible])

  useDidUpdate(() => {
    handleTabSwipe()
  }, [activeTab, tabsRef.current])

  return (
    <div className="scrollable-section-tabs__wrapper mt-xl-5 mb-xl-4">
      <div ref={tabsRef} className="d-inline-flex scrollable-section-tabs mt-4">
        {tabs.map((tab: ScrollableTab) => (
          <span
            key={tab.key}
            className={clsx('pb-2 pb-xl-4 scrollable-section-tabs__single me-4', {
              'is-active': isActive(tab.key) && !isCrossed(tab.key),
              'is-crossed': isCrossed(tab.key),
            })}
            onClick={handleTabClick(tab)}
          >
            {isCrossed(tab.key) && <i className="uil-check me-2 text-primary" />}
            {tab.title}
          </span>
        ))}
      </div>
    </div>
  )
}
