import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ResortImprovement } from '@models/reservation'
import { ImprovementModalWrapper } from '@modules/reservations/improvements/improvement-modal-wrapper'
import { ImprovementSummaryModalPriceRow } from '@modules/reservations/improvements/summary/improvement-summary-modal-price-row'
import { useApiRequest } from '@hooks/use-api-request'
import { addImprovement } from '@api/improvements'
import { selectReservationDetailsUrls } from '@store/selectors/reservation-details-selectors'
import { setReservationDetails } from '@store/slices/reservations-slice'

interface Props {
  title: string
  resortImprovement: ResortImprovement
  handleOpen: () => void
}

export const StayOptionDetailsModal = ({ handleOpen, resortImprovement }: Props): JSX.Element => {
  const dispatch = useDispatch()
  const urls = useSelector(selectReservationDetailsUrls)

  const { action: handleAddImprovement, isLoading } = useApiRequest(async () => {
    const addImprovementUrl = urls?.improvements
    if (!addImprovementUrl) return
    dispatch(setReservationDetails(await addImprovement(addImprovementUrl, [{ code: resortImprovement.code }])))
    handleOpen()
  })

  return (
    <ImprovementModalWrapper
      contentTitle={resortImprovement.name}
      onSubmit={handleAddImprovement}
      image={resortImprovement.image.sm}
      isLoading={isLoading}
      onClose={handleOpen}
    >
      <ImprovementSummaryModalPriceRow text="Koszt ulepszenia łącznie:" price={resortImprovement.price_brutto} />
    </ImprovementModalWrapper>
  )
}
