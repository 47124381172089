import * as React from 'react'
import { ReservationReceiptModalStaySummary } from '@modules/reservations/modals/reservation-receipt-modal-stay-summary'
import { ReservationReceiptModalDepositSummary } from '@modules/reservations/modals/reservation-receipt-modal-deposit-summary'
import { ReservationReceiptModalVouchersSummary } from '@modules/reservations/modals/reservation-receipt-modal-vouchers-summary'
import { ReservationReceiptModalPaymentSummary } from '@modules/reservations/modals/reservation-receipt-modal-payment-summary'
import { ReservationBill } from '@models/bill'
import Decimal from 'decimal.js'
import { ReservationDetails } from '@models/reservation'

interface Props {
  reservationDetails: ReservationDetails
  bill: ReservationBill
  wrapperClassName?: string
  children?: React.ReactNode
}

export const ReservationReceipt = ({ reservationDetails, bill, wrapperClassName, children }: Props): JSX.Element => {
  const [voucherSectionPosition, setVoucherSectionPosition] = React.useState(3)

  const shouldShowDepositSection =
    new Decimal(bill.damages.total_price_brutto).gt(0) || new Decimal(bill.deposit_shopping.total_price_brutto).gt(0)

  React.useEffect(() => {
    if (!shouldShowDepositSection) setVoucherSectionPosition(2)
  }, [shouldShowDepositSection])

  return (
    <div className={wrapperClassName}>
      <ReservationReceiptModalStaySummary bill={bill} reservationDetails={reservationDetails} />
      {shouldShowDepositSection && (
        <ReservationReceiptModalDepositSummary bill={bill} guests={reservationDetails.guests} />
      )}
      <ReservationReceiptModalVouchersSummary
        sectionNumber={voucherSectionPosition}
        bill={bill}
        guests={reservationDetails.guests}
      />
      <ReservationReceiptModalPaymentSummary sectionNumber={voucherSectionPosition + bill.vouchers.length} bill={bill}>
        {children}
      </ReservationReceiptModalPaymentSummary>
    </div>
  )
}
