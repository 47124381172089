import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import { IconWithText } from '@components/icon-with-text'
import { Reservation } from '@models/reservation'
import { getStayLength, toDefaultDateFormat, toShortDateFormat } from '@helpers/date-helper'
import { useSelector } from 'react-redux'
import { accommodationTypeSelector, resortTypeSelector } from '@store/selectors/reservations'

interface Props {
  reservation: Reservation
}

export const ReservationBasicInfo = ({ reservation }: Props): JSX.Element => {
  const accommodationType = useSelector(accommodationTypeSelector)(reservation.accommodation_type_id)
  const resort = useSelector(resortTypeSelector)(reservation.resort_id)

  return (
    <div>
      <Row className="py-3">
        <Col lg={4} className="d-flex flex-column gap-0 gap-lg-2">
          <IconWithText
            className="text-darker-gray"
            iconClassName="uil-calender font-size-icon-18 me-2"
            text={
              <>
                <span className="fw-bold">
                  {toShortDateFormat(reservation.date_from)} - {toDefaultDateFormat(reservation.date_to)}
                </span>{' '}
                <span className="fw-light">({getStayLength(reservation.date_from, reservation.date_to)})</span>
              </>
            }
          />
          <IconWithText
            text={<div className="fw-bold text-darker-gray">{resort?.name}</div>}
            iconClassName="uil-map-marker font-size-icon-18 me-2"
          />
        </Col>
        <Col lg={4} className="d-flex flex-column gap-0 gap-lg-2">
          <IconWithText
            className="text-darker-gray d-none d-lg-block"
            iconClassName="uil-bookmark font-size-icon-18 me-2"
            text={
              <>
                <span className="fw-light">Nr. rez.: </span>
                <span className="fw-bold">{reservation.reservation_number}</span>
              </>
            }
          />
          <IconWithText
            text={
              <strong>
                {reservation.accommodation_type_name} {accommodationType?.size}m²
              </strong>
            }
            iconClassName="uil-pathfinder-unite font-size-icon-18 me-2"
            className="fw-bold text-darker-gray"
          />
        </Col>
        <Col lg={4} className="align-self-end">
          {(reservation.checked_in || reservation.selected_apartment_name) && (
            <IconWithText
              text={<strong>{reservation.apartment_name}</strong>}
              iconClassName="uil-estate font-size-icon-18 me-2"
              className="fw-bold text-darker-gray"
            />
          )}
        </Col>
      </Row>
    </div>
  )
}
