import * as React from 'react'
import QRCode from 'qrcode'

interface Props {
  text: string
}

export const QrCodeBox = ({ text }: Props): JSX.Element => {
  const [qrCodeUrl, setQrCodeUrl] = React.useState('')

  React.useEffect(() => {
    QRCode.toDataURL(text, { errorCorrectionLevel: 'high', width: 200 }, (_, url) => {
      setQrCodeUrl(url)
    })
  }, [text])

  return (
    <div className="coupons-view__details__qr-code">
      {qrCodeUrl && <img className="rounded" src={qrCodeUrl} alt={`Kupon ${text}`} />}
    </div>
  )
}
