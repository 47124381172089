import * as clsx from 'clsx'
import * as React from 'react'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  aside?: (props: { headerHeight: number }) => JSX.Element
  children?: React.ReactNode
  header: React.ReactNode
  footer?: React.ReactNode
  isOpen: boolean
  isExpandDisabled?: boolean
  onOpen: () => void
}

export const ExpandableRowCard = ({
  aside,
  children = null,
  header,
  footer,
  onOpen,
  isOpen,
  isExpandDisabled,
  ...props
}: Props): JSX.Element => {
  const [headerHeight, setHeaderHeight] = React.useState(0)
  const [containerHeight, setContainerHeight] = React.useState(0)
  const [isContentVisible, setIsContentVisible] = React.useState(false)

  const headerRef = React.useRef<HTMLDivElement>(null)
  const containerRef = React.useRef<HTMLDivElement>(null)

  const updateSizes = () => {
    setHeaderHeight(headerRef.current?.clientHeight ?? 0)
    setContainerHeight(containerRef.current?.scrollHeight ?? 0)
  }

  React.useLayoutEffect(() => {
    updateSizes()
  }, [isOpen, header, children, aside, isContentVisible, footer])

  React.useLayoutEffect(() => {
    if (isOpen) setIsContentVisible(true)
  }, [isOpen])

  const handleTransitionEnd = () => {
    if (!isOpen) setIsContentVisible(false)
    updateSizes()
  }

  const minHeaderHeight = Math.max(headerHeight, 73)

  return (
    <div
      {...props}
      ref={containerRef}
      style={{
        minHeight: minHeaderHeight,
        maxHeight: isOpen ? containerHeight : minHeaderHeight,
      }}
      className={clsx(
        'card overflow-hidden transition-max-height',
        { 'bg-secondary-light-blue': !isOpen && !isContentVisible },
        props.className,
      )}
      onTransitionEnd={handleTransitionEnd}
    >
      <div className="d-flex">
        {aside && <div className="col-3 col-lg-2">{aside({ headerHeight: minHeaderHeight })}</div>}
        <div className="flex-fill">
          <div ref={headerRef}>{header}</div>
          {isContentVisible && <div>{children}</div>}
        </div>
        <div className="expandable-row-card__expand-icon px-1 px-lg-3">
          <i
            style={{
              marginTop: minHeaderHeight / 2,
              transform: 'translate(0, -50%)',
            }}
            className={clsx(
              'cursor-pointer d-block text-darker-gray uil-angle-right-b font-size-icon-18 transition-rotate-icon',
              { 'rotate-90': isOpen },
              { 'd-none': isExpandDisabled ?? React.Children.count(children) === 0 },
            )}
            onClick={onOpen}
            data-testid="expandable-row-card-expand-toggle"
          />
        </div>
      </div>
      {footer && <div className="border-top">{footer}</div>}
    </div>
  )
}
