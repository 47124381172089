import { ReactSelectOption } from '@components/custom-react-select'
import { IconWithText } from '@components/icon-with-text'
import { SectionTitle } from '@components/section-title'
import { useFormRequest } from '@hooks/use-api-request'
import { FeedingType, Guest } from '@models/reservation'
import * as React from 'react'
import { Collapse, Form } from 'react-bootstrap'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import { guestTypeOptions } from '@modules/user/guests/modal'
import { useDidUpdate } from 'rooks'
import { ButtonLoading } from '@components/controls/button-loading'
import { setReservationDetails } from '@store/slices/reservations-slice'
import { addReservationGuest, updateReservationGuest } from '@api/reservation'
import { getFeedingGuests, getSelectableImprovements, isFeeding } from '@helpers/improvement-helper'
import { selectResortDetails } from '@store/selectors/reservations-selectors'
import { getFeedingType, isFeedingAdded } from '@modules/reservations/pending/data-completion-modal/guests/utils'
import { ReservationDataCompletionAddGuestForm } from '@modules/reservations/pending/data-completion-modal/guests/add-guest-form'
import { ReservationDataCompletionAddedGuests } from '@modules/reservations/pending/data-completion-modal/guests/added-guests'
import { useGuestChangeCheck } from '@hooks/use-guest-change-check'
import * as clsx from 'clsx'

function validate(data: Partial<FormInputs>) {
  if (!data.type?.value || !data.name?.trim()) return false
  if (data.type.value === 'adult') return true

  return data.birthday
}

interface FormInputs {
  name: string
  type: ReactSelectOption
  birthday?: string
  feeding: boolean
}

interface Props {
  onGuestInteraction: () => void
}

export const CheckInGuestsForm = ({ onGuestInteraction }: Props): JSX.Element => {
  const [isEditFormVisible, setIsEditFormVisible] = React.useState(false)
  const [guestToEdit, setGuestToEdit] = React.useState<Guest | null>(null)
  const [isCollapsed, setIsCollapsed] = React.useState(false)

  const editFormRef = React.useRef<HTMLDivElement>(null)

  const dispatch = useDispatch()
  const reservationDetails = useSelector(selectReservationDetails)
  const resortDetails = useSelector(selectResortDetails)

  const { canEditGuest } = useGuestChangeCheck()

  const defaultValues = {
    name: '',
    type: guestTypeOptions[0],
    birthday: '',
    feeding: isFeedingAdded(reservationDetails),
  }

  const methods = useForm<FormInputs>({ defaultValues })

  const [isFeedingChecked, name, type, birthday] = useWatch({
    control: methods.control,
    name: ['feeding', 'name', 'type', 'birthday'],
  })

  const isDataFilled = validate({ name, type, birthday })
  const isFeedingAvailable = getSelectableImprovements(resortDetails?.improvements || [], reservationDetails).some(
    isFeeding,
  )

  const handleEditFormClose = () => setIsEditFormVisible(false)
  const handleEditFormOpen = () => {
    onGuestInteraction()
    setIsEditFormVisible(true)
  }

  const { action: addGuest, isLoading: isGuestAdding } = useFormRequest(async payload => {
    if (!reservationDetails) return

    dispatch(setReservationDetails(await addReservationGuest(reservationDetails.urls.guests, payload)))
    handleCancel()
  }, methods.setError)

  const { action: updateGuest, isLoading: isGuestUpdating } = useFormRequest(async payload => {
    if (!guestToEdit) return

    dispatch(setReservationDetails(await updateReservationGuest(guestToEdit, payload)))
    handleCancel()
  }, methods.setError)

  const handleSubmit = () => {
    methods.clearErrors()
    if (!reservationDetails) return

    const feedingType = getFeedingType(reservationDetails.feeding_options) ?? FeedingType.DINNER_WITH_BREAKFAST

    const payload = {
      name,
      group_id: reservationDetails.groups[0].id,
      ...(type.value === 'adult' && { type: type.value }),
      ...(type.value !== 'adult' && { birthday }),
      ...(isFeedingAvailable && { feeding_type: isFeedingChecked ? feedingType : FeedingType.NONE }),
    }

    guestToEdit ? updateGuest(payload) : addGuest(payload)
  }

  const handleCancel = () => {
    handleEditFormClose()
    if (guestToEdit) setGuestToEdit(null)
  }

  const handleFeedingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    methods.setValue('feeding', event.currentTarget.checked)
  }

  const handleGuestEdit = (guest: Guest) => {
    setGuestToEdit(guest)
    handleEditFormOpen()
    onGuestInteraction()
  }

  useDidUpdate(() => {
    if (!guestToEdit) return

    const isFeedingForGuestAdded = Object.keys(getFeedingGuests(reservationDetails)).includes(guestToEdit.id.toString())

    methods.reset({
      name: guestToEdit.name,
      type: guestToEdit.type === 'adult' ? guestTypeOptions[0] : guestTypeOptions[1],
      feeding: isFeedingForGuestAdded,
      birthday: guestToEdit.birthday,
    })
  }, [guestToEdit])

  useDidUpdate(() => {
    if (editFormRef.current) {
      editFormRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isCollapsed])

  useDidUpdate(() => {
    if (!reservationDetails?.guests?.some(guest => guest.id === guestToEdit?.id)) {
      handleEditFormClose()
      methods.reset(defaultValues)
    }
  }, [reservationDetails?.guests, guestToEdit])

  const isGuestAndFeedingChangeDisabled = canEditGuest(reservationDetails, guestToEdit)

  return (
    <>
      <SectionTitle
        title="Osoby towarzyszące"
        subtitle="Czy dane osób towarzyszących są prawidłowe?"
        className="text-center text-md-start"
      />
      {reservationDetails && (
        <div className="d-xl-flex">
          <div className="font-size-xl col-xl-4 d-none d-xl-block">
            <strong>Uzupełnij dane Gości</strong>
          </div>
          <div className="col-xl-8">
            <ReservationDataCompletionAddedGuests
              reservationDetails={reservationDetails}
              onGuestEdit={handleGuestEdit}
              editedGuest={guestToEdit}
            />
          </div>
        </div>
      )}
      <Collapse
        timeout={300}
        in={isEditFormVisible}
        onEntered={() => setIsCollapsed(true)}
        onExited={() => setIsCollapsed(false)}
      >
        <div className="my-4" ref={editFormRef}>
          {!guestToEdit && <div className="text-darker-gray my-2 fw-bold">Brakujące dane osób towarzyszących</div>}
          <div className="card bg-light-blue p-3">
            {!isDataFilled && (
              <IconWithText
                iconClassName="uil-exclamation-triangle text-danger me-2 font-size-xxl"
                text="Uzupełnij dane Gościa"
                textClassName="font-size-sm text-darker-gray fw-bold"
              />
            )}
            <FormProvider {...methods}>
              <ReservationDataCompletionAddGuestForm isGuestKindChangeDisabled={isGuestAndFeedingChangeDisabled} />
              {isFeedingAvailable && (
                <div className="d-flex align-items-center my-3">
                  <Form.Check
                    id="feeding"
                    checked={isFeedingChecked}
                    className="checkbox cursor-pointer mt-n1"
                    type="checkbox"
                    onChange={handleFeedingChange}
                    disabled={isGuestAndFeedingChangeDisabled}
                  />
                  <label className="fw-bold ms-2 text-muted font-size-sm cursor-pointer" htmlFor="feeding">
                    Wyżywienie:
                  </label>
                </div>
              )}
            </FormProvider>
            <div className="mt-2 d-flex justify-content-lg-start justify-content-between">
              <button className="btn p-0 border-0" onClick={handleCancel} disabled={isGuestUpdating || isGuestAdding}>
                <IconWithText
                  className="text-muted"
                  iconClassName="uil-times me-1 font-size-xxl"
                  textClassName="fw-bold font-size-sm"
                  text="Anuluj"
                />
              </button>

              <ButtonLoading
                isLoading={isGuestUpdating || isGuestAdding}
                className={clsx(
                  'form-control-height-lg bg-transparent d-flex align-items-center justify-content-center border-0 text-muted',
                  { 'opacity-50': !isDataFilled },
                )}
                onClick={handleSubmit}
                disabled={!isDataFilled}
                loadingClassName="fw-bold font-size-sm"
              >
                <IconWithText
                  className="align-items-center font-size-sm ps-3"
                  text="Zapisz"
                  textClassName="fw-bold"
                  iconClassName="uil-check me-1 font-size-xxl"
                />
              </ButtonLoading>
            </div>
          </div>
        </div>
      </Collapse>
      {!isEditFormVisible && !isCollapsed && (
        <div className="d-flex mt-3 mb-4 justify-content-center justify-content-xl-start col-xl-8 ms-xl-auto">
          <button className="btn ps-md-0" onClick={handleEditFormOpen}>
            <IconWithText
              className="text-darker-gray"
              text="Dodaj kolejnego gościa"
              iconClassName="font-size-xxl uil-plus me-2"
              textClassName="fw-bold"
            />
          </button>
        </div>
      )}
    </>
  )
}
