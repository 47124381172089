import * as React from 'react'
import * as clsx from 'clsx'
import { GastroCard } from '@models/client'
import { PaymentModalAgreements } from '@modules/wallet/modal/payment-method-modal'
import { isGastroGenericProduct } from '@modules/special-products/gastro-vouchers/utils'
import { DynamicProductAgreements } from '@components/dynamic-product-agreements'

interface Props extends PaymentModalAgreements {
  gastroCard: GastroCard
  className?: string
}

export const GastroCardAgreements = ({
  gastroCard,
  paymentMethod,
  onProductAgreementsChange,
  className,
}: Props): JSX.Element => (
  <DynamicProductAgreements
    className={clsx('col-12 col-lg-8', className)}
    product={gastroCard}
    paymentMethod={paymentMethod}
    onProductAgreementsChange={onProductAgreementsChange}
    omitPaymentRules={isGastroGenericProduct(gastroCard.kind) && gastroCard.payment_method === 'cash_card'}
  />
)
