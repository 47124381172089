import * as React from 'react'
import { CloseReservations } from '@modules/reservations/close'
import { PendingReservations } from '@modules/reservations/pending'
import { ContentLoader } from '@components/loaders/content-loader'
import { CheckedInReservations } from '@modules/reservations/checked-in'
import { useDefaultExpandedReservation } from '@hooks/use-default-expanded-reservation'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/selectors/client-selectors'
import { useDebouncedValue } from 'rooks'
import { selectReservationsAreFetched } from '@store/slices/reservations-slice'

export const ReservationsView = (): JSX.Element => {
  const { isLoading } = useDefaultExpandedReservation()
  const clientDetails = useSelector(selectClientDetails)
  const isReservationFetchingFinished = useSelector(selectReservationsAreFetched)

  const [isLoaded] = useDebouncedValue(isLoading || !clientDetails.id || !isReservationFetchingFinished, 100)

  return (
    <div className="mt-3">
      <ContentLoader isLoading={!!isLoaded}>
        <CheckedInReservations />
        <PendingReservations />
        <CloseReservations />
      </ContentLoader>
    </div>
  )
}
