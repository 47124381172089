import { useSelector } from 'react-redux'
import * as React from 'react'
import { NotificationEvents } from '@models/websocket'
import { selectWebsockets } from '@store/slices/websocket-slice'

type WebSocketCallback<T> = (message: NotificationEvents, payload: T) => void

export const useWebSocket = <T, D>(cb: WebSocketCallback<T>, deps: D[]): null => {
  const webSocket = useSelector(selectWebsockets)

  React.useEffect(() => {
    if (webSocket.type) {
      cb(webSocket.type, webSocket.payload)
    }
  }, [webSocket, ...deps])

  return null
}
