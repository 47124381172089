import { SignaturePadComponent } from '@components/signature-pad-component'
import * as clsx from 'clsx'
import * as React from 'react'
import * as ReactDOM from 'react-dom'

interface Props {
  isVisible?: boolean
  onSave: (dataUrl: string) => void
}

export const FullScreenSignaturePad = ({ isVisible, onSave }: Props): React.ReactPortal | null => {
  const wrapper = document.querySelector('body')

  return (
    wrapper &&
    ReactDOM.createPortal(
      <SignaturePadComponent
        className={clsx('signature-pad__full-screen bg-light-blue z-index-1', {
          visible: isVisible,
          invisible: !isVisible,
        })}
        onSave={onSave}
      />,
      wrapper,
    )
  )
}
