import { LogInPayload, logInUserRequest, logOutUserRequest } from '@api/authorization'
import { RootState } from '@store/index'
import { ThunkAction } from 'redux-thunk'
import { fetchClientDetails, setClientDetails } from '@store/actions/client-actions'
import { emptyUser } from '@store/reducers/client-reducers'
import { SetClientDetailsAction } from '@store/action-types/client-action-types'
import { setAuthStatus } from '@store/slices/auth-slices'
import { ClientDetails } from '@models/client'

export const logInUser =
  (payload: LogInPayload): ThunkAction<Promise<ClientDetails>, RootState, null, any> =>
  async (dispatch, getState) => {
    await logInUserRequest(getState(), payload)
    const clientDetails = await dispatch(fetchClientDetails())
    await dispatch(setAuthStatus(true))

    return clientDetails
  }

export const logOutUser =
  (): ThunkAction<Promise<void>, RootState, null, SetClientDetailsAction | any> => async (dispatch, getState) => {
    await logOutUserRequest(getState())
    await dispatch(setClientDetails(emptyUser))
    await dispatch(setAuthStatus(false))
  }
