import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { getPaymentRedirectUrl } from '@api/payment'
import { ButtonLoading } from '@components/controls/button-loading'
import { useModal } from '@components/modals/use-modal'
import { SubscriptionContract } from '@models/client'
import { commonObjectPost } from '@api/basic-requests'
import { useApiRequest } from '@hooks/use-api-request'

interface Props {
  className?: string
  subscriptionContract: SubscriptionContract
}

export const SubscriptionContractPaymentButton = ({ className, subscriptionContract }: Props): JSX.Element => {
  const { action: handleSubmit, isLoading } = useApiRequest(async subscriptionContractAgreements => {
    await commonObjectPost(subscriptionContract.urls.rules, subscriptionContractAgreements)

    document.location.href = await getPaymentRedirectUrl(subscriptionContract.urls.payment, {
      amount: subscriptionContract.single_price_brutto,
      source: 'client-panel-app',
    })
  })

  const [showSubscriptionContractRulesModal] = useModal('SubscriptionContractPaymentRulesModal', {
    title: 'Regulaminy subskrypcji',
    onSubmit: handleSubmit,
    subscriptionContract,
  })

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    showSubscriptionContractRulesModal()
  }

  return (
    <ButtonLoading
      className={clsx('d-flex justify-content-center h-fit-content py-2 px-4 px-sm-5', className)}
      variant="danger"
      onClick={handleClick}
      isLoading={isLoading}
      loadingClassName="fw-medium"
      loadingLabel="Proszę czekać"
    >
      <IconWithText
        className="text-white"
        iconClassName="uil-money-bill me-2 py-1 font-size-xxl lh-1"
        textClassName="fw-medium font-size-md text-nowrap"
        text="Opłać teraz"
      />
    </ButtonLoading>
  )
}
