import * as React from 'react'
import { HandleAxiosErrorsConfiguration, useHandleAxiosFormErrors } from '@hooks/use-handle-axios-errors'
import { FieldValues, UseFormSetError } from 'react-hook-form'
import { AxiosError } from 'axios'

const noop = () => undefined

type state = 'pending' | 'resolved' | 'rejected' | 'idle'
type AsyncCallable<T> = (...params: any) => Promise<T | undefined>
type OnErrorCallback = (error: AxiosError) => void
export const useApiRequest = function <T>(asyncCallable: AsyncCallable<T>, onError: OnErrorCallback = noop) {
  const [state, setState] = React.useState<state>('idle')
  const [data, setData] = React.useState<T | undefined>(undefined)

  const action = React.useCallback(
    async (...params) => {
      setState('pending')
      try {
        const result = await asyncCallable(...params)
        setData(result)
        setState('resolved')
        return result
      } catch (error) {
        onError(error)
        setState('rejected')
      }
    },
    [setState, setData, asyncCallable],
  )

  return {
    state,
    action,
    data,
    isLoading: state === 'pending',
  }
}

export const useFormRequest = function <T, U extends FieldValues = FieldValues>(
  asyncCallable: (...params: any) => Promise<T>,
  setError: UseFormSetError<U>,
  axiosFormErrorHandlerConfig?: HandleAxiosErrorsConfiguration,
  errorCatcher?: (error) => void,
) {
  const handleAxiosFormErrors = useHandleAxiosFormErrors()

  const formRequest = React.useCallback(
    async (...params) => {
      try {
        return await asyncCallable(...params)
      } catch (error) {
        handleAxiosFormErrors(error, setError, { showGlobalError: false, ...axiosFormErrorHandlerConfig })
        if (error.response?.status !== 400 && error.response?.status !== 403) {
          errorCatcher?.(error)
        }
      }
    },
    [asyncCallable, setError],
  )

  return useApiRequest<T>(formRequest)
}
