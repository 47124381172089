import * as React from 'react'
import { AnimatePresence, motion } from 'framer-motion'

interface Props {
  isShowed: boolean
  children: React.ReactNode
  wrapperClassName?: string
}

export const BaseCheckedInReservationBanner = ({ isShowed, children, wrapperClassName }: Props): JSX.Element => (
  <AnimatePresence>
    {isShowed && (
      <motion.div
        className={wrapperClassName}
        transition={{ opacity: { duration: 0.1 }, height: { duration: 0.2 } }}
        initial={{ opacity: 0, y: -10, height: 0 }}
        exit={{ opacity: 0, y: -10, height: 0 }}
        animate={{ opacity: 1, y: 0, height: 'auto' }}
      >
        {children}
      </motion.div>
    )}
  </AnimatePresence>
)
