import * as React from 'react'
import { Checkbox } from '@components/controls/checkbox'
import { AgreementSection, BASE_RULES } from '@components/agreement-section'
import { useRules } from '@hooks/use-rules'
import { SubscriptionContract } from '@models/client'
import { useLink } from '@hooks/use-link'
import { AvailableRule } from '@models/app-data'

interface Props {
  subscriptionContract: SubscriptionContract
  onProductAgreementsChange: (requiredAgreementsAccepted: boolean) => void
}

export const SubscriptionContractAgreements = ({
  subscriptionContract,
  onProductAgreementsChange,
}: Props): JSX.Element => {
  const { getRules } = useRules()
  const { openLink } = useLink()

  const rules = [...subscriptionContract.optional_rules, ...subscriptionContract.required_rules]

  const rulesToShow = getRules(rules.filter(rule => !BASE_RULES.includes(rule))) as AvailableRule[]

  const handleOpenLink = (rule: AvailableRule) => (event: React.MouseEvent) => {
    event.preventDefault()
    openLink(rule?.url)
  }

  return (
    <AgreementSection
      requiredProductRules={subscriptionContract.required_rules}
      optionalProductRules={subscriptionContract.optional_rules}
      onProductAgreementsChange={onProductAgreementsChange}
      className="col-12 col-lg-11"
      wrapperClassName="mt-n4"
      omitPaymentRules={true}
    >
      {rulesToShow.map(rule => (
        <Checkbox
          key={rule.keyword}
          className="checkbox--small"
          name={rule.keyword}
          label={
            <span className="font-size-sm">
              Oświadczam, że zapoznałem się z treścią dokumentu
              <span
                className="text-primary text-decoration-none fw-bold mx-1 cursor-pointer cursor-pointer"
                onClick={handleOpenLink(rule)}
              >
                {rule.name}
              </span>
              i akceptuję jego postanowienia.
            </span>
          }
        />
      ))}
    </AgreementSection>
  )
}
