import * as React from 'react'
import { ModalFooter, ModalFooterProps } from '@components/modals/modal-footer'
import { TrapezeButtonVariant } from '@components/controls/trapeze-button'
import { ImprovementAmountChange } from '@modules/reservations/improvements/kinds/base-improvements/improvement-amount-change'
import { ModalBody } from 'react-bootstrap'
import {
  ImprovementKind,
  ImprovementUpdatesInformationAlert,
} from '@modules/reservations/improvements/alerts/improvement-updates-information-alert'
import { ContentLoader } from '@components/loaders/content-loader'

interface Props extends ModalFooterProps {
  contentTitle: string | React.ReactElement
  children: React.ReactNode
  image: string | null
  imageContent?: JSX.Element
  isMostPopular?: boolean
  isEditMode?: boolean
  hasAmountChange?: boolean
  description?: React.ReactNode
  kind?: ImprovementKind
  isInitializing?: boolean
}

export const ImprovementModalWrapper = ({
  contentTitle,
  image,
  imageContent,
  isMostPopular,
  isEditMode,
  children,
  hasAmountChange,
  description,
  isInitializing,
  kind = 'improvement',
  ...props
}: Props): JSX.Element => (
  <>
    <ContentLoader isLoading={isInitializing}>
      <ModalBody className="improvements__modal__body modal-scrollable">
        <div className="position-relative">
          <img
            className="scrollable-section__card__image"
            src={image || require('@assets/images/image-fallback.webp')}
            alt="Ulepszenie"
          />
          {imageContent}
        </div>
        <div className="px-3 pt-3 pb-0 px-xl-4 container-lg">
          <ImprovementUpdatesInformationAlert kind={kind} />
          <div className="d-flex flex-column flex-sm-row justify-content-between align-items-start my-1 gap-4 w-100">
            <div>
              <strong className="text-darker-gray font-size-lg">{contentTitle}</strong>
              {description}
            </div>
            {hasAmountChange && <ImprovementAmountChange />}
          </div>
        </div>
        {children}
      </ModalBody>
    </ContentLoader>

    <ModalFooter
      saveButtonVariant={TrapezeButtonVariant.red}
      withCancelButton
      submitLabel={isEditMode ? 'Zapisuję' : 'Wybieram'}
      {...props}
    />
  </>
)
