import * as React from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { ModalBody } from 'react-bootstrap'
import { ControlledOtpInput } from '@modules/wallet/controlled-otp-input'
import { ModalFooter } from '@components/modals/modal-footer'
import { useApiRequest } from '@hooks/use-api-request'
import { RefundFormInputs } from '@modules/refund/refund-form'
import { useAppDispatch } from '@store/index'
import { commonObjectPut } from '@api/basic-requests'
import { ReservationRefund } from '@models/reservation'
import { updateReservationRefund } from '@store/slices/reservations-slice'
import { AxiosError } from 'axios'
import { IconWithText } from '@components/icon-with-text'

const CONFIRMATION_CODE_LENGTH = 6

interface FormInputs {
  code: string
}

interface Props {
  handleClose: () => void
  onSuccess: () => void
  onFailure: (error: AxiosError) => void
  refundData: RefundFormInputs
  phone: string
  refundUrl: string
}

export const RefundConfirmationOtpModal = ({
  refundUrl,
  phone,
  handleClose,
  refundData,
  onSuccess,
  onFailure,
}: Props): JSX.Element => {
  const [otpError, setOtpError] = React.useState<string | null>('')

  const methods = useForm<FormInputs>({
    defaultValues: { code: '' },
  })

  const dispatch = useAppDispatch()

  const handleError = (error: AxiosError<{ detail: string }>) => {
    if (error.response?.status === 403) {
      setOtpError(error.response?.data.detail ?? null)
    }

    if (error.response?.status === 400) {
      onFailure(error)
      handleClose()
    }
  }

  const { isLoading, action: handleConfirm } = useApiRequest(async () => {
    setOtpError(null)
    await dispatch(
      updateReservationRefund(
        await commonObjectPut<ReservationRefund>(
          refundUrl,
          {
            ...refundData,
            deposit_return_account_number: refundData.deposit_return_account_number.replace(/\W/gi, ''),
          },
          {
            headers: {
              'x-otp': methods.getValues('code'),
            },
          },
        ),
      ),
    )
    onSuccess()
    handleClose()
  }, handleError)

  const isCodeFilled = useWatch({ control: methods.control, name: 'code' }).length === CONFIRMATION_CODE_LENGTH

  return (
    <>
      <ModalBody className="modal-scrollable text-muted py-5">
        <FormProvider {...methods}>
          <div className="d-flex flex-column align-items-center">
            <h4 className="text-secondary fw-bold text-center">Potwierdzenie Zwrotu Bankowego</h4>
            <p className="w-75 text-center">
              Potwierdzam zlecenie zwrotu środków na konto <br />o numerze:
              <strong className="font-size-lg text-center ps-2">{refundData.deposit_return_account_number}</strong>
            </p>
            <p className="text-center">
              Kod z wiadomości SMS wysłanej na numer <strong>{phone}</strong>
            </p>
            <ControlledOtpInput inputName="code" />
            {otpError && (
              <IconWithText
                className="text-danger align-items-center mt-2"
                textClassName="font-size-xs"
                iconClassName="uil-info-circle me-2"
                text={otpError}
              />
            )}
          </div>
        </FormProvider>
      </ModalBody>
      <ModalFooter
        isLoading={isLoading}
        buttonDisabled={!isCodeFilled}
        withCancelButton
        onSubmit={handleConfirm}
        onClose={handleClose}
        submitLabel="Potwierdzam zwrot"
      />
    </>
  )
}
