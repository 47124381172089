import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { IconWithText } from '@components/icon-with-text'
import * as React from 'react'
import { DownloadAppLinks } from '@components/download-app-links'
import { PasswordsForm } from '@modules/account/passwords-form'
import { useFormRequest } from '@hooks/use-api-request'
import { commonObjectPost } from '@api/basic-requests'
import { useSelector } from 'react-redux'
import { selectAccountUrls } from '@store/selectors/app-data-selectors'
import { AccountActionResult } from '@modules/account/account-action-result'
import { AccountCreateSuccess } from '@modules/account/account-creation/account-create-success'
import { AccountCreateFailure } from '@modules/account/account-creation/account-create-failure'
import { OperationResult } from '@modules/payment-result/utils'
import { Checkbox } from '@components/controls/checkbox'
import { useRules } from '@hooks/use-rules'
import { Row } from 'react-bootstrap'
import { PolicyPrivacyAgreements } from '@components/policy-privacy-agreements'
import { useGtmEvents } from '@hooks/use-gtm-events'
import { ClientDetails } from '@models/client'

interface AccountCreateFormInputs {
  email: string
  password: string
  confirmed_password: string
  privacy_policy_rules: boolean
  information_clause: boolean
  services_rules: boolean
  email_rules: boolean
}

interface Props {
  onBackToLogin: () => void
}

export const AccountCreate = ({ onBackToLogin }: Props): JSX.Element => {
  const [accountCreateResult, setAccountCreateResult] = React.useState<OperationResult | null>(null)
  const { register } = useSelector(selectAccountUrls)
  const { register: callRegisterEvent } = useGtmEvents()

  const methods = useForm<AccountCreateFormInputs>({
    defaultValues: {
      email: '',
      password: '',
      confirmed_password: '',
      services_rules: false,
      information_clause: false,
      privacy_policy_rules: false,
      email_rules: false,
    },
  })

  const email = useWatch({ control: methods.control, name: 'email' })
  const policyPrivacyRules = useWatch({ control: methods.control, name: 'privacy_policy_rules' })

  const { isLoading, action: onSubmit } = useFormRequest(
    async (payload: AccountCreateFormInputs) => {
      const client = await commonObjectPost<Partial<ClientDetails>>(register, payload)
      if (client.id) callRegisterEvent(client.id)
      setAccountCreateResult(OperationResult.success)
    },
    methods.setError,
    { nonFieldErrorsAs: 'confirmed_password' },
    () => setAccountCreateResult(OperationResult.error),
  )

  const { getRules } = useRules()

  const [services_rules, information_clause] = getRules(['services_rules', 'information_clause'])

  React.useEffect(() => {
    if (policyPrivacyRules) methods.setValue('email_rules', true)
  }, [policyPrivacyRules])

  return (
    <div>
      {accountCreateResult ? (
        <AccountActionResult
          result={accountCreateResult}
          successComponent={<AccountCreateSuccess email={email} />}
          failureComponent={<AccountCreateFailure />}
        />
      ) : (
        <>
          <h4 className="text-secondary fw-bold text-center mt-5 mb-2">Utwórz nowe Konto Gościa</h4>
          <p className="text-center mb-4 font-size-12">Tworzenie konta Gościa zajmie tylko chwilę.</p>
          <div className="account-creation">
            <div className="account-creation__inputs">
              <FormProvider {...methods}>
                <Row className="gap-3 gap-md-0">
                  <CustomFormGroup
                    formGroupProps={{ className: 'col-12 col-md-6' }}
                    label="Wpisz imię:"
                    inputName="first_name"
                    formControlProps={{ placeholder: 'Wpisz imię' }}
                  />
                  <CustomFormGroup
                    formGroupProps={{ className: 'col-12 col-md-6' }}
                    label="Wpisz nazwisko:"
                    inputName="last_name"
                    formControlProps={{ placeholder: 'Wpisz nazwisko' }}
                  />
                </Row>
                <CustomFormGroup
                  label="Wpisz swój adres e-mail:"
                  inputName="email"
                  formControlProps={{ placeholder: 'Wpisz e-mail', type: 'email' }}
                />
                <PasswordsForm
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                  submitLoadingText="Tworzenie..."
                  submitText="Utwórz Konto Gościa"
                  passwordField="password"
                >
                  <div className="d-flex flex-column gap-3 mt-3">
                    <Checkbox
                      name="services_rules"
                      id="services_rules"
                      label={
                        <span>
                          Oświadczam, że zapoznałem się z treścią dokumentu
                          <a
                            className="text-primary text-decoration-none fw-bold mx-1 text-nowrap"
                            href={services_rules?.url}
                            target="_blank"
                          >
                            {services_rules?.name}
                          </a>
                          i akceptuję jego postanowienia.
                        </span>
                      }
                      className="checkbox--small font-size-sm align-items-start"
                    />

                    <Checkbox
                      name="information_clause"
                      id="information_clause"
                      label={
                        <span>
                          Oświadczam, że zapoznałem się z treścią dokumentu
                          <a
                            className="text-primary text-decoration-none fw-bold mx-1 text-nowrap"
                            href={information_clause?.url}
                            target="_blank"
                          >
                            {information_clause?.name}
                          </a>
                          i akceptuję jego postanowienia.
                        </span>
                      }
                      className="checkbox--small font-size-sm align-items-start"
                    />

                    <PolicyPrivacyAgreements showEmail={true} showSms={false} />
                  </div>
                </PasswordsForm>
                <IconWithText
                  onClick={onBackToLogin}
                  text="Mam już konto, chcę się zalogować"
                  iconClassName="uil-user me-2 font-size-lg"
                  className="fw-bold justify-content-center mt-2 mb-4"
                />
              </FormProvider>
            </div>
            <div>
              <strong className="text-secondary pt-4 d-block text-md-start text-center">
                Dlaczego warto utworzyć Konto Gościa?
              </strong>
              <div className="d-flex gap-1 flex-column my-3">
                {benefits.map(benefit => (
                  <IconWithText
                    key={benefit.name}
                    text={benefit.name}
                    iconClassName={`${benefit.icon} font-size-lg text-primary me-2 align-self-start`}
                    textClassName="font-size-sm col-sm-9"
                  />
                ))}
              </div>
              <div className="account-creation__download-app-buttons__wrapper">
                <strong className="text-secondary mb-3 d-block text-center text-md-start">
                  Pobierz aplikacją mobilną Konto Gościa
                </strong>
                <DownloadAppLinks className="account-creation__download-app-buttons" />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

interface Benefit {
  name: string
  icon: string
}

export const benefits: Benefit[] = [
  { name: 'Dostęp do pełnej historii rezerwacji', icon: 'uil-invoice' },
  { name: 'Meldowanie on-line ', icon: 'uil-check' },
  { name: 'Otwieranie domku swoim telefonem', icon: 'uil-lock-open-alt' },
  { name: 'Kontrola rachunków podczas wakacji', icon: 'uil-file-check-alt' },
  { name: 'Szybkie doładowanie kaucji', icon: 'uil-money-bill' },
  { name: 'Łatwy dostęp do wykupionych pakietów i kodów promocyjnych', icon: 'uil-pricetag-alt' },
]
