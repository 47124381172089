import * as React from 'react'
import { Button, ModalBody } from 'react-bootstrap'
import { ModalFooter } from '@components/modals/modal-footer'
import { TrapezeButtonVariant } from '@components/controls/trapeze-button'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import * as clsx from 'clsx'
import { usePaymentModal } from '@hooks/use-payment-modal'
import { useSelector } from 'react-redux'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import Decimal from 'decimal.js'
import { useDidUpdate } from 'rooks'

interface Props {
  handleClose: () => void
}

interface FormInputs {
  amount: string
}

interface AmountSelection {
  id: number
  amount: string
}

const amountSelections = [
  { id: 0, amount: '500', displayValue: '+500' },
  { id: 1, amount: '700', displayValue: '+700' },
  { id: 2, amount: '300', displayValue: '+300' },
]

export const AddDepositModal = ({ handleClose }: Props): JSX.Element => {
  const [selectedAmount, setSelectedAmount] = React.useState<AmountSelection | null>(null)

  const reservationDetails = useSelector(selectReservationDetails)

  const methods = useForm<FormInputs>({
    defaultValues: { amount: '' },
  })

  const enteredAmount = useWatch({ control: methods.control, name: 'amount' })

  const handleSelectionChange = (selection: AmountSelection) => () => {
    methods.setValue('amount', '')
    setSelectedAmount(selection)
  }

  const showPaymentModal = usePaymentModal({
    paymentUrl: reservationDetails?.urls.payment || '',
    payload: {
      amount: selectedAmount?.amount ?? enteredAmount,
      target: 'booking_deposit',
      source: 'client-panel-app-reservation',
    },
    onClose: handleClose,
  })

  const isPaymentDisabled = new Decimal((selectedAmount?.amount ?? enteredAmount) || 0).lte(0)

  useDidUpdate(() => {
    if (enteredAmount) {
      setSelectedAmount(null)
    }
  }, [enteredAmount])

  return (
    <>
      <ModalBody>
        <div
          className="reservation-payment-modal__image mt-n3 mx-n3"
          style={{ backgroundImage: `url(${require('@assets/images/doladuj-kaucje.jpg')})` }}
        />
        <div className="font-size-14 fw-lighter pt-3">
          Zasil saldo kaucji, aby korzystać ze środków na terenie ośrodka.
        </div>
        <div className="fw-bold font-14">Jaką kwotę chcesz wpłacić?</div>
        <div className="d-flex pt-3 pb-2 col-12 col-sm-10">
          {amountSelections.map((selection, index) => (
            <Button
              key={selection.amount}
              className={clsx('flex-fill form-control-height-lg border-0 bg-secondary', {
                'bg-gold-gradient': selection.id === selectedAmount?.id && !enteredAmount,
                'mx-3': index === 1,
              })}
              onClick={handleSelectionChange(selection)}
            >
              <span className="fw-bold font-size-16 text-white">{selection.displayValue}</span>
            </Button>
          ))}
        </div>
        <div className="col-12 col-sm-10 pb-3">
          <FormProvider {...methods}>
            <div className="flex-fill">
              <CustomFormGroup
                inputName="amount"
                formControlProps={{ type: 'number', placeholder: 'lub wpisz kwotę' }}
              />
            </div>
          </FormProvider>
        </div>
      </ModalBody>
      <ModalFooter
        withCancelButton
        onClose={handleClose}
        buttonDisabled={isPaymentDisabled}
        onSubmit={() => showPaymentModal()}
        submitLabel="Przejdź do płatności"
        saveButtonVariant={TrapezeButtonVariant.red}
      />
    </>
  )
}
