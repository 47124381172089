import * as React from 'react'
import { CouponBaseInformation } from '@modules/coupons/coupon-base-information'
import { ContentLoader } from '@components/loaders/content-loader'
import { reservationsSelector } from '@store/slices/reservations-slice'
import { CouponItemFormInputs } from '@modules/coupons/coupon-checked-in-reservation'
import { CouponsList } from '@modules/coupons/list/coupons-list'
import { useGetCouponItemsQuery } from '@api/coupons'
import { FormProvider, useForm } from 'react-hook-form'
import { CouponContent } from '@modules/coupons/coupon-content'
import { CouponRules } from '@modules/coupons/coupon-rules'
import { useAppSelector } from '@store/index'
import { selectCouponTypes } from '@store/selectors/app-data-selectors'
import { compareAsc, isAfter, isToday, startOfToday } from 'date-fns'
import { parseISODate } from '@helpers/date-helper'
import { useModal } from '@components/modals/use-modal'
import { Reservation } from '@models/reservation'
import { TypedQueryResult } from '@api/base'
import { CouponItem } from '@models/coupons'
import { sortObjectListByDate } from '@helpers/utils'

const emptyList = []

export const CouponsView = (): JSX.Element => {
  const couponTypes = useAppSelector(selectCouponTypes)
  const reservations = useAppSelector(reservationsSelector)
  const [selectedReservation, setSelectedReservation] = React.useState<Reservation | null>(null)

  const [showBookingSelectionModal] = useModal('CouponsBookingSelectionModal')

  const { data: coupons = emptyList } = useGetCouponItemsQuery() as TypedQueryResult<CouponItem[]>

  const methods = useForm<CouponItemFormInputs>({ defaultValues: { amount: 5 } })

  const checkedInReservation = reservations.find(reservation => reservation.checked_in)

  const checkedOutTodayReservation = reservations.find(
    reservation =>
      !reservation.checked_in &&
      reservation.close_datetime &&
      isToday(parseISODate(reservation.close_datetime) as Date),
  )

  const firstUpcomingReservation = React.useMemo(
    () =>
      sortObjectListByDate(reservations, 'date_from', compareAsc).find(
        reservation =>
          isToday(parseISODate(reservation.date_from) as Date) ||
          isAfter(parseISODate(reservation.date_from) as Date, startOfToday()),
      ),
    [reservations],
  )

  const contentReservation = React.useMemo(() => {
    if (selectedReservation) {
      return selectedReservation.checked_in ? selectedReservation : undefined
    }

    if (checkedOutTodayReservation) return undefined

    return checkedInReservation
  }, [selectedReservation, checkedInReservation, checkedOutTodayReservation])

  React.useEffect(() => {
    if (checkedInReservation && checkedOutTodayReservation) {
      showBookingSelectionModal(null, {
        reservations: [checkedInReservation, checkedOutTodayReservation],
        onReservationSelect: setSelectedReservation,
      })
    }
  }, [checkedOutTodayReservation, checkedInReservation])

  React.useEffect(() => {
    const reservation = contentReservation ?? firstUpcomingReservation
    const reservationInitialCouponType = couponTypes.find(
      couponType => couponType.configuration.resort === reservation?.resort_id,
    )

    if (reservationInitialCouponType) {
      methods.setValue('type', reservationInitialCouponType?.id)
    }
  }, [contentReservation?.resort_id, firstUpcomingReservation?.resort_id])

  return (
    <ContentLoader>
      <div className="coupons-view__wrapper">
        <FormProvider {...methods}>
          <CouponBaseInformation />
          <div className="flex-1 d-flex align-items-center justify-content-center">
            <img
              height={300}
              width={320}
              role="loader"
              src={require('@assets/images/coupons-icon.png')}
              alt="Kupony Holiday Park & Resort"
            />
          </div>
          <CouponContent
            checkedInReservation={contentReservation}
            firstUpcomingReservation={firstUpcomingReservation}
          />
          <CouponRules />
        </FormProvider>
        <CouponsList
          coupons={coupons}
          reservation={selectedReservation ?? checkedInReservation ?? checkedOutTodayReservation}
        />
      </div>
    </ContentLoader>
  )
}
