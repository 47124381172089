import * as React from 'react'
import * as clsx from 'clsx'
import { SectionTitle } from '@components/section-title'
import { ReservationReceipt } from '@modules/reservations/reservation-receipt'
import { ReservationDetails } from '@models/reservation'
import { ReservationBill } from '@models/bill'
import { CheckOutCashbackVoucher } from '@modules/reservations/check-out/check-out-cashback-voucher'

interface Props {
  reservationDetails: ReservationDetails
  receiptWrapperClassName?: string
  bill: ReservationBill
  children?: React.ReactNode
  onRefetchBill: () => Promise<void>
}

export const CheckOutReceiptStep = ({
  reservationDetails,
  receiptWrapperClassName,
  bill,
  children,
  onRefetchBill,
}: Props): JSX.Element => (
  <>
    <SectionTitle
      title={`Podsumowanie rachunku rezerwacji ${reservationDetails.reservation_number}`}
      subtitle="Poniżej znajdziesz swój szczegółowy rachunek za pobyt"
      className="text-sm-start text-center px-3 pb-sm-0"
    />
    <ReservationReceipt
      reservationDetails={reservationDetails}
      bill={bill}
      wrapperClassName={clsx('mb-3 mb-xl-0', receiptWrapperClassName)}
    >
      <CheckOutCashbackVoucher reservationDetails={reservationDetails} onRefetchBill={onRefetchBill} />
    </ReservationReceipt>
    {children}
  </>
)
