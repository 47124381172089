import * as React from 'react'
import { useCopy } from '@hooks/use-copy'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  text: string
  code: string
}

export const SpecialProductVoucherCode = ({ code, text }: Props): JSX.Element => {
  const { copy, Copiable } = useCopy()

  const handleCopy = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()
    copy(code)
  }

  return (
    <span className="mb-2">
      {text}
      <Copiable className="d-inline ms-2" copyText="Kod skopiowany">
        <IconWithText
          onIconClick={handleCopy}
          text={code}
          textClassName="fw-bold mt-1"
          className="flex-row-reverse d-inline-flex"
          iconClassName="uil-copy ms-2 font-size-xl lh-1"
        />
      </Copiable>
    </span>
  )
}
