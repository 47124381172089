import * as React from 'react'
import * as clsx from 'clsx'
import { FullScreenModalLayout } from '@components/full-screen-modal-layout'
import { useHistory, useParams } from 'react-router-dom'
import { ProgressBar } from '@components/progress-bar'
import { ProgressBarLabel } from '@components/progress-bar-label'
import { useApiRequest } from '@hooks/use-api-request'
import { getReservationByTokenDetails } from '@api/reservation'
import { ContentLoader } from '@components/loaders/content-loader'
import { PersonalDataStep } from '@modules/reservations/check-in/steps/personal-data/personal-data-step'
import { ImprovementsStep } from '@modules/reservations/check-in/steps/improvements/improvements-step'
import { PaymentSummaryStep } from '@modules/reservations/check-in/bill/payment-summary-step'
import { GdprStep } from '@modules/reservations/check-in/steps/gdpr/gdpr-step'
import { ConfirmationStep } from '@modules/reservations/check-in/steps/confirmation/confirmation-step'
import { useDispatch, useSelector } from 'react-redux'
import { setReservationDetails } from '@store/slices/reservations-slice'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import { useResortDetails } from '@hooks/use-resort-details'
import { useLocation } from 'react-router'
import { NavigationPath } from '@models/routes'
import { RedirectPoint } from '@modules/payment-result/utils'
import { ImprovementStepMode } from '@modules/reservations/check-in/check-in-modal'
import { NextStepButton } from '@components/next-step-button'
import { usePaymentModal } from '@hooks/use-payment-modal'
import { useGdprStep } from '@modules/reservations/check-in/steps/gdpr/use-gdpr-step'
import { ReservationDetails } from '@models/reservation'
import { commonObjectPost } from '@api/basic-requests'
import { useModal } from '@components/modals/use-modal'
import { ArrivalTimeStep } from '@modules/reservations/check-in/steps/arrival-time/arrival-time-step'
import { useDebouncedValue, useDidUpdate } from 'rooks'
import { useArrivalTimeUpdate } from '@modules/reservations/check-in/steps/arrival-time/use-arrival-time-update'

export enum CheckInSteps {
  personalData = 1,
  improvements,
  paymentSummary,
  gdpr,
  arrivalTime,
  confirmation,
}

export const CheckInStepProgress = {
  [CheckInSteps.personalData]: 10,
  [CheckInSteps.improvements]: 27,
  [CheckInSteps.paymentSummary]: 51,
  [CheckInSteps.gdpr]: 78,
  [CheckInSteps.arrivalTime]: 89,
  [CheckInSteps.confirmation]: 100,
}

interface Params {
  token: string
}

export const ReservationCheckIn = (): JSX.Element => {
  const [step, setStep] = React.useState(CheckInSteps.personalData)
  const [improvementStepMode, setImprovementStepMode] = React.useState(ImprovementStepMode.prompt)
  const [guestFormInteractionOccurred, setGuestFormInteractionOccurred] = React.useState(false)

  const { token } = useParams<Params>()

  const containerWrapperRef = React.useRef<HTMLDivElement>(null)
  const [debouncedStep] = useDebouncedValue(step, 50)

  const dispatch = useDispatch()
  const reservationDetails = useSelector(selectReservationDetails)
  const { isLoading: isFetchingResortDetails } = useResortDetails()

  const location = useLocation()
  const history = useHistory()

  const { finishCheckIn, isFinishing, isDataFilled, clientDetails, GdprStepWrapper } = useGdprStep(reservationDetails)

  React.useEffect(() => {
    if (location.hash === RedirectPoint.check_in_agreements) setStep(CheckInSteps.gdpr)
    if (location.hash === RedirectPoint.check_in_payment) setStep(CheckInSteps.paymentSummary)
  }, [location.hash])

  const { action: fetchDetails, isLoading: isFetching } = useApiRequest(async () => {
    const reservationDetails = await getReservationByTokenDetails(token)
    dispatch(
      setReservationDetails(await commonObjectPost<ReservationDetails>(reservationDetails.urls.online_checkin_start)),
    )
  })

  React.useEffect(() => {
    fetchDetails()
  }, [token])

  useDidUpdate(() => {
    containerWrapperRef.current?.scroll({ behavior: 'smooth', top: 0 })
  }, [debouncedStep])

  const handleAction = () => {
    step === CheckInSteps.personalData ? handleMoveToReservations() : setStep(step - 1)
  }

  const action = step === CheckInSteps.personalData ? 'close' : 'back'

  const handleMoveToReservations = () => {
    history.replace(NavigationPath.Reservations)
  }

  const handleStepChange = (step: CheckInSteps) => () => setStep(step)

  const showPaymentModal = usePaymentModal({
    paymentUrl: reservationDetails?.urls?.payment || '',
    payload: {
      amount: reservationDetails?.prices.rest_to_pay_in_checkin || 0,
      target: 'booking_advance_with_climatic',
      source: 'client-panel-app-check-in',
    },
  })

  const isPaid = reservationDetails?.is_paid_with_deposit_with_climatic

  const handleFinishCheckingIn = async () => {
    await finishCheckIn()
    handleMoveToReservations()
  }

  const handleGuestFormInteraction = React.useCallback(() => {
    setGuestFormInteractionOccurred(true)
  }, [])

  const [showGuestSetupNotDetectedModal] = useModal('GuestSetupNotDetectedModal', {
    onMoveToTheNextStep: handleStepChange(CheckInSteps.improvements),
  })

  const handleMoveToImprovementsStep = () => {
    if (!guestFormInteractionOccurred) {
      showGuestSetupNotDetectedModal()
      return
    }

    handleStepChange(CheckInSteps.improvements)()
  }

  const { update: updateArrivalTime, isLoading: isUpdatingArrivalTime } = useArrivalTimeUpdate(
    reservationDetails,
    handleStepChange(CheckInSteps.confirmation),
  )

  return (
    <FullScreenModalLayout onAction={handleAction} action={action} ref={containerWrapperRef}>
      <ContentLoader isLoading={isFetching || isFetchingResortDetails}>
        {reservationDetails && (
          <>
            <ProgressBar
              color={step === CheckInSteps.confirmation ? 'success' : 'navy'}
              className={clsx('container-xxl-full-width', { 'text-success': step === CheckInSteps.confirmation })}
              progress={CheckInStepProgress[step]}
              label={
                <ProgressBarLabel
                  label="Meldowanie on-line"
                  progress={CheckInStepProgress[step]}
                  className={clsx({ 'text-success': step === CheckInSteps.confirmation })}
                />
              }
            />
            {step === CheckInSteps.personalData && (
              <PersonalDataStep onGuestFormInteraction={handleGuestFormInteraction}>
                <NextStepButton
                  onClick={handleMoveToImprovementsStep}
                  isDisabled={!reservationDetails.check_in_data.id_card}
                />
              </PersonalDataStep>
            )}
            {step === CheckInSteps.improvements && (
              <ImprovementsStep
                mode={improvementStepMode}
                onModeChange={setImprovementStepMode}
                onNextStep={handleStepChange(CheckInSteps.paymentSummary)}
              />
            )}
            {step === CheckInSteps.paymentSummary && (
              <PaymentSummaryStep reservationDetails={reservationDetails}>
                <NextStepButton
                  onClick={isPaid ? handleStepChange(CheckInSteps.gdpr) : () => showPaymentModal()}
                  {...(!isPaid && { variant: 'danger', label: 'Przejdź do płatności' })}
                />
              </PaymentSummaryStep>
            )}

            {step === CheckInSteps.gdpr && (
              <GdprStepWrapper>
                <GdprStep clientDetails={clientDetails} reservationDetails={reservationDetails}>
                  <NextStepButton isDisabled={!isDataFilled} onClick={handleStepChange(CheckInSteps.arrivalTime)} />
                </GdprStep>
              </GdprStepWrapper>
            )}

            {step === CheckInSteps.arrivalTime && (
              <ArrivalTimeStep reservation={reservationDetails}>
                {formData => (
                  <NextStepButton
                    onClick={async () => {
                      await updateArrivalTime(formData)
                      handleStepChange(CheckInSteps.confirmation)()
                    }}
                    isLoading={isUpdatingArrivalTime}
                  />
                )}
              </ArrivalTimeStep>
            )}

            {step === CheckInSteps.confirmation && (
              <ConfirmationStep>
                <NextStepButton label="Zakończ meldowanie" onClick={handleFinishCheckingIn} isLoading={isFinishing} />
              </ConfirmationStep>
            )}
          </>
        )}
      </ContentLoader>
    </FullScreenModalLayout>
  )
}
