import * as React from 'react'
import { SpecialProductRowPaymentButton } from '@modules/special-products/special-product-row-payment-button'
import { IconWithText } from '@components/icon-with-text'
import { PackageVip } from '@models/client'
import { ModalFooter } from '@components/modals/modal-footer'
import { useModal } from '@components/modals/use-modal'
import { PackageVipAgreements } from '@modules/special-products/packages-vip/package-vip-agreements'
import { getPackageVipName } from '@modules/special-products/packages-vip/utils'

interface Props {
  packageVip: PackageVip
}

export const PackageVipExpandableRowHeaderNotPaid = ({ packageVip }: Props): JSX.Element => {
  const handleVipDetailsModalClose = () => {
    hideVipDetailsModal()
  }

  const [showVipDetailsModal, hideVipDetailsModal] = useModal('VipDetailListModal', {
    title: 'Pakiet VIP',
    packageVipType: packageVip.type,
    footer: (
      <ModalFooter withSubmit={false} cancelLabel="Zamknij" withCancelButton onClose={handleVipDetailsModalClose} />
    ),
  })

  const handlePackageDetailsClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    showVipDetailsModal()
  }

  return (
    <div className="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center w-100">
      <div className="mb-3 mb-sm-0">
        <IconWithText iconClassName="uil-check-square me-2 font-size-lg lh-1" text={getPackageVipName(packageVip)} />
        <strong className="cursor-pointer" onClick={handlePackageDetailsClick}>
          Co dostaję w pakiecie?
        </strong>
      </div>
      <SpecialProductRowPaymentButton
        amount={packageVip.required_payment}
        productUrls={packageVip.urls}
        agreements={({ paymentMethod, onProductAgreementsChange }) => (
          <PackageVipAgreements
            packageVip={packageVip}
            paymentMethod={paymentMethod}
            onProductAgreementsChange={onProductAgreementsChange}
          />
        )}
      />
    </div>
  )
}
