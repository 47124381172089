import * as React from 'react'
import { PackageVip } from '@models/client'
import { StatusVariant } from '@modules/special-products/special-product-status'
import { SpecialProductRowStatus } from '@modules/special-products/special-product-row-status'
import Decimal from 'decimal.js'
import { PackageVipExpandableRowHeaderNotPaid } from '@modules/special-products/packages-vip/row-headers/package-vip-expandable-row-header-not-paid'
import { PackageVipExpandableRowHeaderAdded } from '@modules/special-products/packages-vip/row-headers/package-vip-expandable-row-header-added'
import { PackageVipExpandableRowHeaderNotAdded } from '@modules/special-products/packages-vip/row-headers/package-vip-expandable-row-header-not-added'

interface Props {
  packageVip: PackageVip
}

export const PackageVipExpandableRowHeader = ({ packageVip }: Props): JSX.Element => {
  const isToPay = new Decimal(packageVip.required_payment).gt(0) && packageVip.status === 'initial'

  const canBeAttached = packageVip.status === 'confirmed' && !packageVip.booking

  return (
    <div className="py-3 px-2 px-sm-0 d-flex flex-column flex-sm-row align-items-sm-center">
      <SpecialProductRowStatus
        wrapperClassName="d-block d-md-none"
        title="Status pakietu:"
        productStatus={StatusVariant[packageVip.status]}
        productStatusText={packageVip.status_display}
      />

      {isToPay ? (
        <PackageVipExpandableRowHeaderNotPaid packageVip={packageVip} />
      ) : packageVip.booking ? (
        <PackageVipExpandableRowHeaderAdded packageVip={packageVip} />
      ) : (
        canBeAttached && <PackageVipExpandableRowHeaderNotAdded packageVip={packageVip} />
      )}
    </div>
  )
}
