import { IconWithText } from '@components/icon-with-text'
import { toDefaultDateTimeFormat } from '@helpers/date-helper'
import * as React from 'react'
import * as clsx from 'clsx'
import { formatPrice } from '@helpers/price-helper'

function defaultTransform(value: any) {
  return value
}
function formatNullValues(
  value: any | null,
  nullPlaceholder: string,
  transform: (value: any) => string = defaultTransform,
) {
  if (value === null) return nullPlaceholder

  return transform(value)
}

interface Props {
  children?: React.ReactNode
  icon: string
  label: string
  readingDate: string | null
  readingValue: string | null
  costs: string | null
  unit: string
  onRefresh?: () => void
}
export const MeterStateRow = ({
  children,
  icon,
  label,
  readingDate,
  readingValue,
  costs,
  unit,
  onRefresh,
}: Props): JSX.Element => (
  <tr className="bg-white border-bottom">
    <td className="py-3">
      <div className="ms-3">
        <IconWithText
          className="text-darker-gray"
          iconClassName={clsx(icon, 'me-1 font-size-xl')}
          text={label}
          textClassName="fw-bold"
        />
        {children}
      </div>
      <div className="d-flex align-items-center" style={{ marginLeft: '2.5rem' }}>
        <div className="font-size-sm text-muted">
          Odczyt: {formatNullValues(readingDate, '-', toDefaultDateTimeFormat)}
        </div>
        {onRefresh && (
          <button className="btn py-0 px-1 text-muted" onClick={onRefresh}>
            <i className="uil-sync" />
          </button>
        )}
      </div>
    </td>
    <td align="right">
      <div className="me-3">
        <div className="text-darker-gray font-size-xl">
          <strong>{formatNullValues(costs, '- zł', formatPrice)}</strong>
        </div>
        <div className="font-size-sm text-muted">
          {formatNullValues(readingValue && `${readingValue} ${unit}`, 'Problem z pobraniem wartości')}
        </div>
      </div>
    </td>
  </tr>
)
