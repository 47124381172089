import * as React from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useApiRequest, useFormRequest } from '@hooks/use-api-request'
import { createImageFromSignatureBase64 } from '@helpers/utils'
import { commonObjectPost } from '@api/basic-requests'
import { BookingOnlineDocuments, ReservationDetails } from '@models/reservation'
import { fetchClientDetails } from '@store/actions/client-actions'
import { useDispatch, useSelector } from 'react-redux'
import { selectClientDetails } from '@store/selectors/client-selectors'
import { ClientDetails } from '@models/client'
import { getReservations } from '@store/actions/reservations-action'

export interface CheckInGdprStepFormInputs {
  signature: string | null
}

interface GdprStepWrapperProps {
  children?: React.ReactNode
}

interface Response {
  GdprStepWrapper: (props: GdprStepWrapperProps) => JSX.Element
  clientDetails: ClientDetails
  isDataFilled: boolean
  isFinishing: boolean
  finishCheckIn: () => Promise<void>
  isFetchingClientDetails: boolean
}

export const useGdprStep = (reservationDetails: ReservationDetails | undefined): Response => {
  const clientDetails = useSelector(selectClientDetails)

  const areClientDetailsFetched = !!clientDetails.profile.rules.length
  const dispatch = useDispatch()

  const { isLoading: isFetchingClientDetails, action: fetchClientDetailsData } = useApiRequest(async () => {
    if (!areClientDetailsFetched) {
      dispatch(fetchClientDetails())
    }
  })

  React.useEffect(() => {
    fetchClientDetailsData()
  }, [areClientDetailsFetched])

  const methods = useForm<CheckInGdprStepFormInputs>({
    defaultValues: {
      signature: null,
    },
  })

  const { action: finishCheckIn, isLoading: isFinishing } = useFormRequest(async () => {
    const { signature, ...values } = methods.getValues()

    if (!signature || !reservationDetails) return

    const blob = createImageFromSignatureBase64(signature)

    const formData = new FormData()
    formData.append('signature', blob, `${clientDetails.email}.png`)

    Object.entries(values).forEach(([key, value]: [string, string | Blob]) => {
      formData.append(key, value)
    })

    await commonObjectPost(reservationDetails.urls.online_checkin, formData)
    dispatch(getReservations())
  }, methods.setError)

  const dynamicDocumentFields = reservationDetails?.check_in_online_documents.map(
    (checkinDocument: BookingOnlineDocuments) => checkinDocument.keyword,
  )

  const isDataFilled = useWatch<any>({
    control: methods.control,
    name: ['signature', ...(dynamicDocumentFields?.length ? dynamicDocumentFields : [])],
  }).every(Boolean)

  const Wrapper = ({ children }: GdprStepWrapperProps) => <FormProvider {...methods}>{children}</FormProvider>

  return { clientDetails, isDataFilled, isFinishing, finishCheckIn, isFetchingClientDetails, GdprStepWrapper: Wrapper }
}
