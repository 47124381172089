import * as React from 'react'
import { useParams } from 'react-router-dom'
import { GastroCard, GastroCardDetails } from '@models/client'
import { SpecialProductDetailsHeader } from '@modules/special-products/special-product-details-header'
import { GastroVoucherDetailsBaseInfo } from '@modules/special-products/gastro-vouchers/details/gastro-voucher-details-base-info'
import { GastroVoucherExpandableHeader } from '@modules/special-products/gastro-vouchers/gastro-voucher-expandable-header'
import { GastroVoucherDetailsAssignmentList } from '@modules/special-products/gastro-vouchers/details/gastro-voucher-details-assignment-list'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/selectors/client-selectors'
import { useApiRequest } from '@hooks/use-api-request'
import { commonObjectGet } from '@api/basic-requests'
import { ContentLoader } from '@components/loaders/content-loader'
import { useModal } from '@components/modals/use-modal'
import { IconWithText } from '@components/icon-with-text'
import { GastroVoucherPaymentButton } from '@modules/special-products/gastro-vouchers/gastro-voucher-payment-button'
import { getGastroProductName, isGastroGenericProduct } from '@modules/special-products/gastro-vouchers/utils'

interface Params {
  id: string
}

export const GastroVoucherDetailsView = (): JSX.Element => {
  const [gastroCardDetails, setGastroCardDetails] = React.useState<GastroCardDetails | null>(null)

  const { gastro_cards } = useSelector(selectClientDetails)
  const { id } = useParams<Params>()

  const { action: fetchDetails, isLoading } = useApiRequest(async (detailsUrl: string) => {
    setGastroCardDetails(await commonObjectGet(detailsUrl))
  })

  React.useEffect(() => {
    const gastroVoucher = gastro_cards.find((gastroCard: GastroCard) => gastroCard.id === parseInt(id, 10))
    if (!gastroVoucher) return
    fetchDetails(gastroVoucher.urls.details)
  }, [gastro_cards])

  const [showPurchaseHistoryModal] = useModal('GastroVoucherPurchaseHistoryModal', {
    gastroCard: gastroCardDetails,
    title: `Historia zakupów (voucher ${gastroCardDetails?.code})`,
    titleMobile: 'Historia zakupów',
  })

  const isGastroGeneric = isGastroGenericProduct(gastroCardDetails?.kind)

  const isGastroCouponConfirmedButNotPaid =
    gastroCardDetails?.status === 'initial' &&
    isGastroGeneric &&
    gastroCardDetails?.payment_method === 'cash_card' &&
    !gastroCardDetails?.has_rules_to_accept

  const productName = gastroCardDetails && getGastroProductName(gastroCardDetails)

  return (
    <ContentLoader isLoading={isLoading}>
      {gastroCardDetails && (
        <>
          <SpecialProductDetailsHeader
            title={`Twoje vouchery ${productName}`}
            productName={`Voucher ${productName}`}
            productNumber={gastroCardDetails.code}
          />
          <GastroVoucherDetailsBaseInfo gastroCardDetails={gastroCardDetails} />
          {gastroCardDetails.status === 'initial' ? (
            <>
              <GastroVoucherPaymentButton voucher={gastroCardDetails} />

              {isGastroCouponConfirmedButNotPaid && (
                <p className="my-4 text-primary fw-bold text-center">Voucher będzie aktywny po zaksięgowaniu wpłaty.</p>
              )}
            </>
          ) : (
            <>
              {gastroCardDetails.assignments.length ? (
                <GastroVoucherDetailsAssignmentList voucherDetails={gastroCardDetails} />
              ) : (
                <p className="my-4 text-primary fw-bold text-center">
                  Voucher nie został przypisany do żadnej rezerwacji
                </p>
              )}
              <div className="border rounded bg-secondary-light-blue mb-4">
                <GastroVoucherExpandableHeader voucher={gastroCardDetails} withStatus={false} />
              </div>
            </>
          )}
          <IconWithText
            text="Historia zakupów"
            iconClassName="uil-receipt me-2 font-size-icon-18"
            className="text-muted fw-bold cursor-pointer my-3 justify-content-center"
            onClick={showPurchaseHistoryModal}
          />
        </>
      )}
    </ContentLoader>
  )
}
