import { RootState } from '@store/index'
import { Rule } from '@models/reservation'
import { ClientDetails, ClientDetailsProfileRuleKeyword } from '@models/client'

export const walletTopUpUrlSelector = (state: RootState) => state.clientState.details.urls.wallet_top_up

export const selectClientProfileRules = (state: RootState): Rule<ClientDetailsProfileRuleKeyword>[] =>
  state.clientState.details.profile.rules

export const selectClientDetails = (state: RootState): ClientDetails => state.clientState.details
