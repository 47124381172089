import * as React from 'react'
import { Login } from '@modules/account/login/login'
import { AccountSetupLayout } from '@modules/account/account-setup-layout'
import { NavigationPath } from '@models/routes'
import { useHistory } from 'react-router-dom'

export const LoginView = (): JSX.Element => {
  const history = useHistory()

  const handleBackToLogin = (path: NavigationPath) => () => {
    history.push(path)
  }

  return (
    <AccountSetupLayout>
      <div className="authorization-layout__content-container">
        <Login
          onPasswordRestore={handleBackToLogin(NavigationPath.ResetPassword)}
          onAccountCreate={handleBackToLogin(NavigationPath.AccountCreate)}
        />
      </div>
    </AccountSetupLayout>
  )
}
