import * as React from 'react'
import { AccountSetupLayout } from '@modules/account/account-setup-layout'
import { FormProvider, useForm } from 'react-hook-form'
import { useFormRequest } from '@hooks/use-api-request'
import { useSelector } from 'react-redux'
import { selectAccountUrls } from '@store/selectors/app-data-selectors'
import { commonObjectPost } from '@api/basic-requests'
import { useParams } from 'react-router-dom'
import { AccountActionResult } from '@modules/account/account-action-result'
import { PasswordChangeSuccess } from '@modules/account/reset-password/password-change-success'
import { PasswordChangeFailure } from '@modules/account/reset-password/password-change-failure'
import { OperationResult } from '@modules/payment-result/utils'
import { PasswordsForm } from '../passwords-form'
import { DocumentMeta } from '@components/document-meta'

interface Params {
  token: string
}

export const ResetPasswordCredentialsView = (): JSX.Element => {
  const { token } = useParams<Params>()
  const { reset_password } = useSelector(selectAccountUrls)

  const [passwordChangeResult, setPasswordChangeResult] = React.useState<OperationResult | null>(null)
  const methods = useForm({ defaultValues: { confirmed_password: '', new_password: '' } })

  const { isLoading, action: onSubmit } = useFormRequest(
    async payload => {
      await commonObjectPost(reset_password.replace('replace-me', token), payload)
      setPasswordChangeResult(OperationResult.success)
    },
    methods.setError,
    { nonFieldErrorsAs: 'confirmed_password' },
    () => setPasswordChangeResult(OperationResult.error),
  )

  return (
    <>
      <DocumentMeta title="Resetowanie hasła - Holiday Park & Resort" />
      <AccountSetupLayout>
        <div className="authorization-layout__content-container">
          {passwordChangeResult ? (
            <AccountActionResult
              actionButtonText="Przejdź do swojego konta"
              result={passwordChangeResult}
              successComponent={<PasswordChangeSuccess />}
              failureComponent={<PasswordChangeFailure />}
            />
          ) : (
            <>
              <h4 className="text-secondary fw-bold text-center mt-5 mb-2">Zmiana hasła</h4>
              <p className="mb-4 text-center font-size-12">Wprowadź nowe hasło do konta</p>
              <FormProvider {...methods}>
                <PasswordsForm
                  onSubmit={onSubmit}
                  isLoading={isLoading}
                  submitLoadingText="Zmienianie..."
                  submitText="Zmień hasło"
                  passwordField="new_password"
                />
              </FormProvider>
            </>
          )}
        </div>
      </AccountSetupLayout>
    </>
  )
}
