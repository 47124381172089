import * as React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { PendingReservationPaymentState } from '@modules/reservations/pending/payment/state'
import { IconWithText } from '@components/icon-with-text'
import { ReservationDetails } from '@models/reservation'
import { useModal } from '@components/modals/use-modal'
import { RulesNotAcceptedWarning } from '@modules/reservations/pending/payment/rules-not-accepted-warning'
import { useSelector } from 'react-redux'
import { selectClientProfileRules } from '@store/selectors/client-selectors'
import { isEachRulesRequiredForReservationAccepted } from '@modules/reservations/pending/utils'
import { AdditionalPaymentsIcon } from '@modules/reservations/pending/payment/additional-payments/additional-payments-icon'
import { AdditionalPaymentsWarning } from '@modules/reservations/pending/payment/additional-payments/additional-payments-warning'
import { useDeviceSize } from '@hooks/use-device-size'

interface Props {
  reservationDetails: ReservationDetails
}

export const PendingReservationPayment = ({ reservationDetails }: Props): JSX.Element => {
  const { isDesktop } = useDeviceSize()
  const [togglePaymentModal] = useModal('ReservationPaymentModal', {
    reservationDetails,
    title: 'Opłać teraz',
  })

  const [showRulesModal] = useModal('ReservationRequiredRulesModal', {
    title: 'Regulaminy',
    onRulesAccept: togglePaymentModal,
  })

  const handlePayment = () => {
    isEachRuleAccepted ? togglePaymentModal() : showRulesModal()
  }

  const [handleOpenPurchaseHistory] = useModal('PurchasePaymentHistoryModal', {
    title: `Historia zakupów (Rezerwacja ${reservationDetails.reservation_number})`,
    titleMobile: 'Historia zakupów',
  })

  const {
    prices: { deposit_amount, payments_summary, stay_charge_without_climatic },
  } = reservationDetails

  const payments = React.useMemo(
    () => ({
      installment: [
        { deadline: payments_summary.rest.required_date, remaining: payments_summary.rest.installment },
        { deadline: payments_summary.required.required_date, remaining: payments_summary.required.installment },
      ],
      deposit: [
        { deadline: payments_summary.rest.required_date_deposit, remaining: payments_summary.rest.deposit },
        { deadline: payments_summary.rest.required_date_deposit, remaining: payments_summary.required.deposit },
      ],
    }),
    [payments_summary],
  )

  const clientProfileRules = useSelector(selectClientProfileRules)
  const isEachRuleAccepted = isEachRulesRequiredForReservationAccepted(reservationDetails.rules, clientProfileRules)

  return (
    <div className="card bg-secondary-light-blue ps-3 py-4 mb-3 pe-5" data-testid="pending-reservation-payment">
      <Row>
        <Col xl={6}>
          <PendingReservationPaymentState
            title="Opłata za pobyt"
            afterProgress={!isDesktop && <AdditionalPaymentsIcon reservationDetails={reservationDetails} />}
            payments={payments.installment}
            total={stay_charge_without_climatic}
            paid={payments_summary.paid.installment}
          />
        </Col>
        <Col xl={6}>
          <PendingReservationPaymentState
            title="Kaucja"
            total={deposit_amount}
            payments={payments.deposit}
            paid={payments_summary.paid.deposit}
          />
        </Col>
      </Row>
      <Row className="mt-2 ps-4">
        {!reservationDetails.is_paid_with_deposit && (
          <Col xl={3}>
            <Button className="w-100 d-flex justify-content-center" variant="danger" onClick={handlePayment}>
              <IconWithText
                className="text-white"
                iconClassName="uil-plus me-1 py-1 font-size-xxl"
                text={<div className="fw-bold font-size-14">Opłać teraz</div>}
              />
            </Button>
          </Col>
        )}
        <Col xl={3} className="d-flex justify-content-center justify-content-xl-start mt-3 mt-xl-0">
          <button className="btn p-0">
            <IconWithText
              className="text-darker-gray opacity-75"
              iconClassName="uil-receipt me-1 py-1 font-size-xxl lh-initial"
              text={<strong>Pokaż rachunek</strong>}
              onClick={handleOpenPurchaseHistory}
            />
          </button>
        </Col>
      </Row>
      {!isEachRuleAccepted && <RulesNotAcceptedWarning />}
      {isDesktop && <AdditionalPaymentsWarning reservationDetails={reservationDetails} />}
    </div>
  )
}
