import * as React from 'react'
import * as clsx from 'clsx'
import { useAppSelector } from '@store/index'
import { selectCouponTypes } from '@store/selectors/app-data-selectors'
import { useFormContext, useWatch } from 'react-hook-form'
import { CouponItemFormInputs } from '@modules/coupons/coupon-checked-in-reservation'
import { CouponType } from '@models/coupons'

interface Props {
  resortId: number
}

export const CouponTypeSelect = ({ resortId }: Props): JSX.Element | null => {
  const { setValue, control } = useFormContext<CouponItemFormInputs>()
  const couponTypes = useAppSelector(selectCouponTypes)

  const selectedType = useWatch<CouponItemFormInputs>({ control, name: 'type' })
  const availableTypes = couponTypes.filter((couponType: CouponType) => couponType.configuration.resort === resortId)

  const handleTypeChange = (typeId: number) => {
    setValue('type', typeId)
  }

  if (!availableTypes.length) return null

  return (
    <div className="coupons-view__type-toggle">
      {availableTypes.map(type => (
        <button
          type="button"
          key={type.id}
          className={clsx('coupons-view__type-toggle__button', {
            'is-active': selectedType === type.id,
            'w-100': availableTypes.length === 1,
          })}
          onClick={() => handleTypeChange(type.id)}
        >
          {type.configuration.point_of_sale === 'bistro' ? 'Bistro' : 'Kawiarnia'}
        </button>
      ))}
    </div>
  )
}
