import * as React from 'react'
import * as clsx from 'clsx'
import { Reservation } from '@models/reservation'
import { useSelector } from 'react-redux'
import { selectExpandedReservation } from '@store/slices/expanded-reservation-slice'

interface Props {
  reservation: Reservation
  children: React.ReactNode
}

export const ReservationRowBanner = ({ reservation, children }: Props): JSX.Element => {
  const expandedReservation = useSelector(selectExpandedReservation)
  const isCollapsed = expandedReservation !== reservation.token

  const handleClick = event => {
    event.stopPropagation()
  }

  return (
    <div className={clsx('reservation-row-banner', { 'is-collapsed': isCollapsed })} onClick={handleClick}>
      <div className="reservation-row-banner__content">{children}</div>
    </div>
  )
}
