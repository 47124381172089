import { FullScreenModalLayout } from '@components/full-screen-modal-layout'
import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ProgressBar } from '@components/progress-bar'
import { ProgressBarLabel } from '@components/progress-bar-label'
import { ReservationDetails } from '@models/reservation'
import { useApiRequest } from '@hooks/use-api-request'
import { getReservationByTokenDetails } from '@api/reservation'
import { CheckOutSummaryStep } from '@modules/reservations/check-out/check-out-summary-step'
import { ContentLoader } from '@components/loaders/content-loader'
import { useLocation } from 'react-router'
import { RedirectPoint } from '@modules/payment-result/utils'
import { useCheckOut } from '@modules/reservations/check-out/use-check-out'
import { CheckOutReceiptStep } from '@modules/reservations/check-out/check-out-receipt-step'
import { NextStepButton } from '@components/next-step-button'
import { NavigationPath } from '@models/routes'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { CheckOutFormInputs } from '@modules/reservations/check-out/check-out-modal'
import { useReservationCashbackVoucherInGeneration } from '@modules/special-products/cashback-vouchers/use-reservation-cashback-voucher-in-generation'
import { useModal } from '@components/modals/use-modal'

export enum CheckOutStep {
  receipt = 1,
  summary,
}

interface Params {
  token: string
}

export const ReservationCheckOut = (): JSX.Element => {
  const location = useLocation()
  const { token } = useParams<Params>()
  const { push } = useHistory()

  const [reservationDetails, setReservationDetails] = React.useState<ReservationDetails | null>(null)
  const [step, setStep] = React.useState(CheckOutStep.receipt)

  const methods = useForm<CheckOutFormInputs>({ defaultValues: { cashback_voucher_accepted: false } })
  const isCashbackVoucherSelected = useWatch({ control: methods.control, name: 'cashback_voucher_accepted' })
  const { voucher } = useReservationCashbackVoucherInGeneration(reservationDetails)

  const {
    isPaid,
    showPaymentModal,
    finishCheckOut,
    isLoading: isCheckingOut,
    bill,
    isFetchingBillData,
    refetchBill,
  } = useCheckOut(reservationDetails)

  const { action: fetchDetails, isLoading } = useApiRequest(async () =>
    setReservationDetails(await getReservationByTokenDetails(token)),
  )

  React.useEffect(() => {
    if (location.hash === RedirectPoint.check_out_summary) setStep(CheckOutStep.summary)
    if (location.hash === RedirectPoint.check_out_payment) setStep(CheckOutStep.receipt)
  }, [location.hash])

  React.useEffect(() => {
    fetchDetails()
  }, [])

  const handleAction = () =>
    step === CheckOutStep.receipt ? push(NavigationPath.Reservations) : setStep(CheckOutStep.receipt)

  const proceedSubmit = async () => {
    if (isPaid) {
      setStep(CheckOutStep.summary)
      return
    }

    await showPaymentModal()
  }

  const handleNextStepClick = async () => {
    if (step === CheckOutStep.receipt && voucher && !isCashbackVoucherSelected) {
      showCashbackActivationModal()
      return
    }

    proceedSubmit()
  }

  const [showCashbackActivationModal] = useModal('CashbackVoucherCheckoutModal', { onResignation: proceedSubmit })

  const handleCheckOut = async () => {
    await finishCheckOut()
    push(NavigationPath.Reservations)
  }

  const progress = (step / 2) * 100

  return (
    <ContentLoader isLoading={isLoading || isFetchingBillData}>
      {reservationDetails && bill && (
        <FullScreenModalLayout onAction={handleAction} action={step === CheckOutStep.receipt ? 'close' : 'back'}>
          <ProgressBar
            className="container-xxl-full-width"
            progress={progress}
            label={<ProgressBarLabel progress={progress} label="Wymeldowanie on-line" />}
          />
          {step === CheckOutStep.receipt && (
            <FormProvider {...methods}>
              <CheckOutReceiptStep
                bill={bill}
                onRefetchBill={refetchBill}
                reservationDetails={reservationDetails}
                receiptWrapperClassName="container-xxl-full-width"
              >
                <NextStepButton
                  variant={isPaid ? 'secondary' : 'danger'}
                  label={isPaid ? 'Przejdź dalej' : 'Przejdź do płatności'}
                  onClick={handleNextStepClick}
                />
              </CheckOutReceiptStep>
            </FormProvider>
          )}
          {step === CheckOutStep.summary && (
            <CheckOutSummaryStep>
              <NextStepButton
                variant="secondary"
                label="Zakończ wymeldowanie"
                onClick={handleCheckOut}
                isLoading={isCheckingOut}
              />
            </CheckOutSummaryStep>
          )}
        </FullScreenModalLayout>
      )}
    </ContentLoader>
  )
}
