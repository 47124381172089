import * as React from 'react'
import { Checkbox } from '@components/controls/checkbox'
import { AgreementSection } from '@components/agreement-section'
import { useRules } from '@hooks/use-rules'
import { PaymentModalAgreements } from '@modules/wallet/modal/payment-method-modal'
import { PackageVip } from '@models/client'
import { useLink } from '@hooks/use-link'

interface Props extends PaymentModalAgreements {
  packageVip: PackageVip
}

export const PackageVipAgreements = ({ packageVip, onProductAgreementsChange, paymentMethod }: Props): JSX.Element => {
  const { getRules } = useRules()
  const { openLink } = useLink()

  const [packageVipRules] = getRules(['package_vip_rules'])

  const handleOpenLink = (event: React.MouseEvent) => {
    event.preventDefault()
    openLink(packageVipRules?.url)
  }

  return (
    <AgreementSection
      requiredProductRules={packageVip.required_rules}
      optionalProductRules={packageVip.optional_rules}
      onProductAgreementsChange={onProductAgreementsChange}
      className="col-12 col-lg-8"
      paymentMethod={paymentMethod}
    >
      <Checkbox
        className="checkbox--small"
        name="package_vip_rules"
        label={
          <span className="font-size-sm">
            Oświadczam, że zapoznałem się z treścią dokumentu
            <span className="text-primary text-decoration-none fw-bold mx-1 cursor-pointer" onClick={handleOpenLink}>
              {packageVipRules?.name}
            </span>
            i akceptuję jego postanowienia.
          </span>
        }
      />
    </AgreementSection>
  )
}
