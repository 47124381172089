import * as React from 'react'
import { CustomReactSelect } from '@components/custom-react-select'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { LabeledRow } from '@components/labeled-row'
import { Col, Row } from 'react-bootstrap'
import { IconWithText } from '@components/icon-with-text'
import { useFormContext, useWatch } from 'react-hook-form'
import { Country } from '@models/app-data'
import { ButtonLoading } from '@components/controls/button-loading'

interface Props {
  onCancel: () => void
  countries: Country[]
  isSaving: boolean
}

export const InvoiceDataForm = ({ onCancel, countries, isSaving }: Props): JSX.Element => {
  const { control } = useFormContext()

  const isCompanyInvoice = useWatch({ control, name: 'invoice_type' }) === 'company'

  return (
    <>
      <LabeledRow
        hideLabelOnWrap
        className="align-items-center mt-2 mt-xl-0"
        label={isCompanyInvoice ? 'Nazwa firmy:' : 'Imię i nazwisko:'}
      >
        <Row className="gx-2">
          <Col xxl={8}>
            <CustomFormGroup
              label={isCompanyInvoice ? 'Nazwa firmy:' : 'Imię i nazwisko:'}
              inputName="invoice_company"
              formControlProps={{ type: 'text' }}
            />
          </Col>
        </Row>
      </LabeledRow>
      <LabeledRow hideLabelOnWrap label={<div className="mt-4">{isCompanyInvoice ? 'Adres firmy:' : 'Adres:'}</div>}>
        <Row className="gx-2">
          <Col xxl={8}>
            <CustomFormGroup
              label={isCompanyInvoice ? 'Adres firmy:' : 'Adres:'}
              inputName="invoice_street"
              formControlProps={{ type: 'text' }}
            />
          </Col>
        </Row>
        <Row className="gx-2">
          <Col xs={4} xxl={3}>
            <CustomFormGroup
              inputName="invoice_postcode"
              formatOptions={{
                format: '##-###',
                allowEmptyFormatting: true,
              }}
            />
          </Col>
          <Col xs={8} xl={8} xxl={5}>
            <CustomFormGroup inputName="invoice_city" formControlProps={{ type: 'text' }} />
          </Col>
        </Row>
        <Row className="gx-2">
          <Col xxl={5}>
            <CustomReactSelect inputName="invoice_country" className="mt-2" options={countries} />
          </Col>
        </Row>
        <Row className="gx-2 mt-2">
          <Col xxl={3}>
            <ButtonLoading
              isLoading={isSaving}
              loadingClassName="font-weight-semibold"
              variant="secondary"
              className="w-100 form-control-height-lg"
              type="submit"
            >
              <IconWithText
                className="justify-content-center align-items-center fw-bold"
                iconClassName="uil-check me-2 font-size-icon-sm"
                text="Zapisz"
              />
            </ButtonLoading>
          </Col>
          <Col xxl={3}>
            <IconWithText
              className="text-darker-gray w-100 form-control-height-lg align-items-center justify-content-center fw-bold"
              iconClassName="uil-multiply me-2 font-size-xxl"
              text="Anuluj"
              onClick={onCancel}
            />
          </Col>
        </Row>
      </LabeledRow>
    </>
  )
}
