import { IconWithText } from '@components/icon-with-text'
import * as React from 'react'
import { ReservationDetails } from '@models/reservation'
import { AdditionalPaymentsContent } from '@modules/reservations/pending/payment/additional-payments/additional-payments-content'

interface Props {
  reservationDetails: ReservationDetails
}

export const AdditionalPaymentsWarning = ({ reservationDetails }: Props): JSX.Element => (
  <IconWithText
    iconClassName="uil-exclamation-triangle text-danger me-2 font-size-xxl align-self-start"
    textClassName="font-size-sm text-darker-gray"
    className="ps-4 mt-2"
    text={
      <div className="font-size-sm text-darker-gray pt-1">
        Do zapłaty podczas meldowania:
        <AdditionalPaymentsContent reservationDetails={reservationDetails} />
      </div>
    }
  />
)
