import * as React from 'react'
import { useAppSelector } from '@store/index'
import { selectCouponTypes } from '@store/selectors/app-data-selectors'
import { ReservationDetails } from '@models/reservation'
import { IconWithText } from '@components/icon-with-text'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { NavigationPath } from '@models/routes'
import { BaseCheckedInReservationBanner } from '@modules/reservations/pending/mobile/base-checked-in-reservation-banner'
import { useCouponPurchaseHours } from '@modules/coupons/use-coupon-purchase-hours'

interface Props {
  reservationDetails: ReservationDetails
}

export const CouponCheckedInReservationBanner = ({ reservationDetails }: Props): JSX.Element | null => {
  const [isShowed, setIsShowed] = React.useState(true)
  const couponTypes = useAppSelector(selectCouponTypes)

  const history = useHistory()

  const handleRedirectToCoupons = () => {
    history.push(NavigationPath.Coupons)
  }

  const availableCouponTypes = React.useMemo(
    () => couponTypes.filter(couponType => couponType.configuration.resort === reservationDetails.resort_id),
    [couponTypes, reservationDetails.resort_id],
  )

  const { isInHoursRange } = useCouponPurchaseHours(availableCouponTypes.map(couponType => couponType.configuration))

  if (!availableCouponTypes.length || !isInHoursRange) return null

  const hideBanner = () => {
    setIsShowed(false)
  }

  return (
    <BaseCheckedInReservationBanner isShowed={isShowed} wrapperClassName="row mx-2">
      <div className="col-3 bg-danger rounded-start mt-4">
        <img height={100} src={require('@assets/images/tag.svg')} alt="Kupony Holiday Park & Resort" />
      </div>
      <div className="col-9 bg-primary rounded-end text-white pt-3 pb-2 ps-4 position-relative overflow-hidden mt-4">
        <span className="font-weight-semibold">Każdy los wygrywa!</span>
        <span className="d-block fw-light font-size-sm">Kupując kupon o wartości 5 zł -</span>
        <strong>otrzymujesz 10 zł</strong>

        <Button className="text-white font-size-sm d-block p-0 mt-1" onClick={handleRedirectToCoupons}>
          <IconWithText text="Kup kupony" iconClassName="uil-plus font-size-lg me-1" />
        </Button>

        <button className="coupon-banner__close-button" onClick={hideBanner} data-testid="coupon-banner-close">
          <i className="uil-times text-white z-index-1 position-relative font-size-xl lh-1" />
        </button>
      </div>
    </BaseCheckedInReservationBanner>
  )
}
