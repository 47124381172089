import * as React from 'react'
import { PendingReservationPayment } from '@modules/reservations/pending/payment'
import { ReservationVouchers } from '@modules/reservations/reservation-vouchers'
import { ReservationUpgrades } from '@modules/reservations/reservation-upgrades'
import { PendingReservationTimeSavedIndicator } from '@modules/reservations/pending/time-saved-indicator'
import { ReservationDetails } from '@models/reservation'

interface Props {
  reservationDetails: ReservationDetails
}

export const PendingReservationRowContent = ({ reservationDetails }: Props): JSX.Element => (
  <>
    <PendingReservationPayment reservationDetails={reservationDetails} />
    <ReservationVouchers reservationDetails={reservationDetails} />
    <ReservationUpgrades reservationDetails={reservationDetails} />
    <PendingReservationTimeSavedIndicator reservationDetails={reservationDetails} />
  </>
)
