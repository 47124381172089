import * as React from 'react'
import { AppFooter } from './footer'
import { AppTopbar } from './topbar'
import { Button } from 'react-bootstrap'
import { AppSideNav } from './side-nav'
import { TopbarLogout } from '@components/topbar-logout'
import { useHistory } from 'react-router-dom'
import { useClientDataCheck } from '@hooks/use-client-data-check'
import { useMultistepModalsRedirect } from '@modules/reservations/use-multistep-modals-redirect'
import { ContentLoader } from '@components/loaders/content-loader'
import { useSelector } from 'react-redux'
import { selectAppData } from '@store/selectors/app-data-selectors'
import { useLocation } from 'react-router'
import { useHistoryEntries } from '@hooks/use-history-entries'

interface Props {
  children: React.ReactChild
}

export const AppLayout: React.FC<Props> = ({ children }) => {
  const { appendEntry, removeEntry, hasPreviousEntry } = useHistoryEntries()

  const { goBack } = useHistory()
  const location = useLocation()
  const layoutRef = React.useRef<HTMLDivElement>(null)
  const { isChecking } = useClientDataCheck()

  const { urls } = useSelector(selectAppData)

  useMultistepModalsRedirect()

  React.useEffect(() => {
    layoutRef.current?.scrollTo({ top: 0 })

    appendEntry(location.pathname)
  }, [location.pathname])

  const handleBack = () => {
    removeEntry()
    goBack()
  }

  return (
    <ContentLoader isLoading={isChecking}>
      <div className="layout__wrapper">
        <AppTopbar onAction={hasPreviousEntry ? handleBack : undefined} hideMenuIcon={true}>
          <a target="_blank" href="https://holidaypark.pl/lokalizacje">
            Lokalizacje
          </a>
          <a target="_blank" href="https://holidaypark.pl/promocje">
            Promocje
          </a>
          <a target="_blank" href="https://holidaypark.pl/oferta">
            Oferta
          </a>
          <a target="_blank" href="https://holidaypark.pl/cennik">
            Cennik
          </a>
          <a target="_blank" href="https://holidaypark.pl/kontakt">
            Kontakt
          </a>
          <TopbarLogout />
          <a href={urls.other.reservation_url} target="_blank">
            <Button variant="danger">Rezerwuj</Button>
          </a>
        </AppTopbar>
        <div className="layout__content-wrapper" ref={layoutRef}>
          <div className="layout__container container-xxl d-flex align-items-start">
            <AppSideNav />
            <div className="layout__content">
              {children}
              <div className="pt-5 pb-5 d-none d-xl-block">
                <img className="d-block mx-auto" height={31} src={require('@assets/images/logo_holiday_color.svg')} />
              </div>
            </div>
          </div>
        </div>
        <AppFooter />
      </div>
    </ContentLoader>
  )
}
