import { FormProvider, useForm } from 'react-hook-form'
import { ReservationDataCompletionAddGuestForm } from '@modules/reservations/pending/data-completion-modal/guests/add-guest-form'
import { IconWithText } from '@components/icon-with-text'
import { BackdropLoader } from '@components/loaders/backdrop-loader'
import * as React from 'react'
import { guestTypeOptions } from '@modules/user/guests/modal'
import { ReactSelectOption } from '@components/custom-react-select'
import { Guest } from '@models/reservation'
import { useFormRequest } from '@hooks/use-api-request'
import { useDispatch } from 'react-redux'
import { setReservationDetails } from '@store/slices/reservations-slice'
import { updateReservationGuest } from '@api/reservation'

interface FormInputs {
  name: string
  type: ReactSelectOption | null
  birthday?: string
}

interface Props {
  guest: Guest
  onClose: () => void
  isGuestKindChangeDisabled?: boolean
}

export const EditGuest = ({ guest, onClose, isGuestKindChangeDisabled }: Props): JSX.Element => {
  const methods = useForm<FormInputs>({
    defaultValues: getGuestsValues(guest),
  })

  React.useEffect(() => {
    methods.reset(getGuestsValues(guest))
  }, [guest])

  const dispatch = useDispatch()

  const { isLoading, action: handleGuestUpdate } = useFormRequest(async () => {
    const data = methods.getValues()
    dispatch(setReservationDetails(await updateReservationGuest(guest, { ...data, type: data.type?.value })))
    onClose()
  }, methods.setError)

  return (
    <div className="my-3 position-relative">
      <div className="text-darker-gray border-top bg-light-blue body-padding py-3">
        <h5 className="text-primary font-size-lg fw-bold mt-2 mb-3">Aktualizuj dane Gościa</h5>
        <FormProvider {...methods}>
          <ReservationDataCompletionAddGuestForm isGuestKindChangeDisabled={isGuestKindChangeDisabled} />
        </FormProvider>
        <div className="mt-3 mb-3 mb-lg-0 d-flex justify-content-between justify-content-lg-start">
          <IconWithText
            text="Anuluj"
            iconClassName="font-size-xxl me-2 uil-times"
            textClassName="fw-bold"
            onClick={onClose}
          />
          <IconWithText
            className="ms-4"
            text="Zapisz"
            iconClassName="font-size-xxl me-2 uil-check"
            textClassName="fw-bold"
            onClick={handleGuestUpdate}
          />
        </div>
      </div>
      <BackdropLoader isLoading={isLoading} />
    </div>
  )
}

const getGuestsValues = (guest: Guest) => ({
  name: guest.name,
  type: guest.type === 'adult' ? guestTypeOptions[0] : guestTypeOptions[1],
  birthday: guest.birthday,
})
