import { Controller, useFormContext, useWatch } from 'react-hook-form'
import * as React from 'react'
import OtpInput, { OtpInputProps } from 'react-otp-input'

interface Props extends Omit<OtpInputProps, 'onChange' | 'numInputs'> {
  inputName: string
  wrapperClassName?: string
}

export const ControlledOtpInput = ({ inputName, wrapperClassName, ...props }: Props): JSX.Element => {
  const { control } = useFormContext()

  const code = useWatch({ control, name: inputName })

  return (
    <div className={wrapperClassName}>
      <Controller
        control={control}
        name={inputName}
        render={({ field }) => (
          <OtpInput
            value={code}
            numInputs={6}
            isInputNum
            containerStyle={otpInputContainerStyles}
            inputStyle={otpInputsStyles}
            errorStyle={otpInputErrorStyle}
            onChange={field.onChange}
            {...props}
          />
        )}
      />
    </div>
  )
}

const otpInputContainerStyles = {
  width: '100%',
  maxWidth: '500px',
  display: 'flex',
  margin: '0 auto',
  alignItems: 'center',
  gap: '10px',
}

const otpInputsStyles = {
  width: '34px',
  height: '48px',
  backgroundColor: '#EFF3F7',
  borderRadius: '4px',
  border: '1px solid #9099A1',
  fontSize: '16px',
  fontWeight: 'bold',
  outline: 'none',
  color: '#6c757d',
}

const otpInputErrorStyle = {
  border: '1px solid #e43c3c',
}
