import { CheckedInReservationDepositState } from '@modules/reservations/checked-in/checked-in-reservation-deposit-state'
import { ReservationVouchers } from '@modules/reservations/reservation-vouchers'
import { ReservationUpgrades } from '@modules/reservations/reservation-upgrades'
import * as React from 'react'
import { ReservationDetails } from '@models/reservation'
import { ReservationCashbackVoucherInGeneration } from '@modules/reservations/reservation-cashback-voucher-in-generation'

interface Props {
  reservationDetails: ReservationDetails
}

export const CheckedInReservationRowContent = ({ reservationDetails }: Props): JSX.Element => (
  <>
    <CheckedInReservationDepositState reservationDetails={reservationDetails} />
    <ReservationVouchers reservationDetails={reservationDetails} />
    <ReservationUpgrades reservationDetails={reservationDetails} />
    <ReservationCashbackVoucherInGeneration reservationDetails={reservationDetails} />
  </>
)
