import * as React from 'react'
import { GdprData } from '@modules/user/gdpr-data'
import { ReservationDetails } from '@models/reservation'
import { ReservationRules } from '@modules/reservations/pending/data-completion-modal/reservation-rules'
import { FormProvider, useForm } from 'react-hook-form'
import { getRuleValues } from '@modules/reservations/pending/utils'
import { UserRulesContent } from '@modules/user/gdpr-data/user-rules-content'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/selectors/client-selectors'

interface FormInputs {
  [key: string]: boolean
}

interface Props {
  reservationDetails: ReservationDetails
}

export const ReservationDataCompletionGdpr = ({ reservationDetails }: Props): JSX.Element => {
  const clientDetails = useSelector(selectClientDetails)

  const methods = useForm<FormInputs>({
    defaultValues: {
      ...getRuleValues(reservationDetails.rules),
      ...getRuleValues(clientDetails.profile.rules),
    },
  })

  return (
    <div className="p-4">
      <FormProvider {...methods}>
        <GdprData navyTitle>
          <>
            <ReservationRules reservationDetails={reservationDetails} />
            <UserRulesContent clientDetails={clientDetails} />
          </>
        </GdprData>
      </FormProvider>
    </div>
  )
}
