import * as React from 'react'
import { PackageVip } from '@models/client'
import { SpecialProductCard } from '@modules/special-products/special-product-card'
import { SinglePackageVip } from '@modules/special-products/packages-vip/single-package-vip'
import { sortObjectListByDate } from '@helpers/utils'

interface Props {
  packagesVip: PackageVip[]
}

export const PackagesVip = ({ packagesVip }: Props): JSX.Element | null => {
  const groupedPackages = getGroupedPackages(packagesVip)

  const sortedPackages = [
    ...sortObjectListByDate(groupedPackages.confirmed, 'sell_date'),
    ...sortObjectListByDate(groupedPackages.initial, 'sell_date'),
    ...sortObjectListByDate(groupedPackages.used, 'sell_date'),
  ]

  if (!sortedPackages.length) return null

  return (
    <SpecialProductCard title="Twoje pakiety VIP">
      {sortedPackages.map((packageVip: PackageVip) => (
        <SinglePackageVip key={packageVip.id} packageVip={packageVip} />
      ))}
    </SpecialProductCard>
  )
}

const getGroupedPackages = (packagesVip: PackageVip[]) =>
  packagesVip.reduce(
    (acc, packageVip: PackageVip) => {
      if (packageVip.status === 'initial') {
        return { ...acc, initial: [...acc.initial, packageVip] }
      }

      if (packageVip.status === 'confirmed') {
        return { ...acc, confirmed: [...acc.confirmed, packageVip] }
      }

      if (packageVip.status === 'completed') {
        return { ...acc, used: [...acc.used, packageVip] }
      }

      return acc
    },
    { initial: [], used: [], confirmed: [] },
  )
