import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import * as clsx from 'clsx'
import { DepositTransferStatus } from '@models/reservation'

interface Props {
  status: DepositTransferStatus
}

export const RefundStatus = ({ status }: Props): JSX.Element => {
  const isPending = ['transfer_sent', 'p24_pending'].includes(status)
  const hasError = ['p24_error'].includes(status)

  return (
    <div
      className={clsx(
        'border rounded text-white my-4 py-3 font-weight-semibold opacity-75 d-flex justify-content-center',
        { 'bg-success': !isPending },
        { 'bg-warning': isPending },
      )}
    >
      {!isPending && !hasError && <IconWithText text="Zwrot zlecony" iconClassName="uil-check font-size-xl me-2" />}
      {isPending && <IconWithText text="Zwrot w trakcie realizacji" iconClassName="uil-dna font-size-xl me-2" />}
    </div>
  )
}
