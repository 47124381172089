import * as React from 'react'
import { useParams } from 'react-router-dom'
import { IconWithText } from '@components/icon-with-text'
import { useHistory } from 'react-router'
import { Improvements } from '@modules/reservations/improvements/improvements'
import { useDispatch, useSelector } from 'react-redux'
import { getReservations } from '@store/actions/reservations-action'
import { selectReservationByToken } from '@store/slices/reservations-slice'
import { RootState } from '@store/index'
import { selectClientDetails } from '@store/selectors/client-selectors'

interface Params {
  token: string
}

export const ImprovementsView = () => {
  const { token } = useParams<Params>()

  const clientDetails = useSelector(selectClientDetails)

  const reservation = useSelector((state: RootState) => selectReservationByToken(state, token))

  const history = useHistory()
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (!reservation && clientDetails) dispatch(getReservations())
  }, [reservation, clientDetails])

  return (
    <div className="card border-0 p-0 px-xl-3 py-xl-4 mt-3">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="text-darker-gray fw-bold mb-0">Ulepszenia dla rezerwacji {reservation?.reservation_number}</h4>
        <IconWithText
          text="Wróć do listy rezerwacji"
          className="d-none d-xl-flex flex-wrap-reverse text-darker-gray font-size-lg fw-medium"
          iconClassName="uil-angle-left-b me-2 font-size-xxl"
          onClick={history.goBack}
        />
      </div>
      {reservation && <Improvements reservation={reservation} />}
    </div>
  )
}
