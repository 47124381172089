import * as React from 'react'
import { ImprovementCardTextWithPrice } from '@modules/reservations/improvements/card/improvement-card-text-with-price'
import { formatPriceShort } from '@helpers/price-helper'
import { useSelector } from 'react-redux'
import { selectResortDetails } from '@store/selectors/reservations-selectors'

export const FeedingPricesBlock = (): JSX.Element => {
  const resortDetails = useSelector(selectResortDetails)

  return (
    <>
      {resortDetails?.feedings.adult.breakfast_price_brutto && (
        <ImprovementCardTextWithPrice
          text="Dorośli i młodzież"
          price={formatPriceShort(resortDetails.feedings.adult.breakfast_price_brutto)}
          pricePrefix="od"
          className="mt-xl-4"
        />
      )}
      {resortDetails?.feedings.children_5_12.breakfast_price_brutto && (
        <ImprovementCardTextWithPrice
          text="Dzieci od 5 do 12 lat"
          price={formatPriceShort(resortDetails.feedings.children_5_12.breakfast_price_brutto)}
          pricePrefix="od"
        />
      )}
      {resortDetails?.feedings.children_3_4.breakfast_price_brutto && (
        <ImprovementCardTextWithPrice
          text="Dzieci od 2 do 5 lat"
          price={formatPriceShort(resortDetails.feedings.children_3_4.breakfast_price_brutto)}
          pricePrefix="od"
        />
      )}
      {resortDetails?.feedings.baby.breakfast_price_brutto && (
        <ImprovementCardTextWithPrice
          text="Dzieci do 2 lat"
          price={formatPriceShort(resortDetails.feedings.baby.breakfast_price_brutto)}
          pricePrefix="od"
        />
      )}
    </>
  )
}
