import * as React from 'react'
import { ResortImprovement } from '@models/reservation'
import { useModal } from '@components/modals/use-modal'
import { formatPriceShort } from '@helpers/price-helper'
import { ImprovementCard } from '@modules/reservations/improvements/card/improvement-card'

interface Props {
  resortImprovement: ResortImprovement
}

export const StayOptionCard = ({ resortImprovement }: Props): JSX.Element => {
  const [showStayOptionModal] = useModal('StayOptionDetailsModal', {
    title: 'Szczegóły ulepszenia',
    resortImprovement: resortImprovement,
  })

  return (
    <div className="col-xl-4 col-sm-6">
      <ImprovementCard
        onRightActionClick={showStayOptionModal}
        title={resortImprovement.name}
        image={resortImprovement.image.sm}
        cardClassName="is-stay-option"
        cardImageClassName="is-small"
        leftAction={
          <strong className="text-darker-gray d-flex align-items-center">
            {formatPriceShort(resortImprovement.price_brutto)}
          </strong>
        }
      />
    </div>
  )
}
