import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import { ModalFooter } from '@components/modals/modal-footer'
import { TrapezeButtonVariant } from '@components/controls/trapeze-button'

interface Props {
  handleClose: () => void
}

export const OnlyOnAppModal = ({ handleClose }: Props) => (
  <>
    <ModalBody className="pb-5">
      <div className="text-center">
        <p className="fw-medium">Funkcjonalność jest obecnie dostępna tylko z poziomu urządzenia mobilnego.</p>
        <p>Pobierz aplikację i korzystaj jeszcze wygodniej!</p>
        <div className="d-flex justify-content-center mb-n3 mt-4">
          <img src={require('@assets/images/downloadapp.png')} />
        </div>
      </div>
    </ModalBody>
    <ModalFooter
      onSubmit={handleClose}
      submitLabel="Zamknij"
      onClose={handleClose}
      saveButtonVariant={TrapezeButtonVariant.red}
    />
  </>
)
