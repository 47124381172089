import { toDefaultDateFormat, toShortDateFormat } from '@helpers/date-helper'
import * as React from 'react'
import { ReservationDetails } from '@models/reservation'
import { Resort } from '@models/app-data'
import { isCheckedInReservation, isPendingReservation } from '@helpers/reservation'

interface Props {
  reservationDetails: ReservationDetails
  resort: Resort
}

export const ReservationDetailsMobileHeader = ({ reservationDetails, resort }: Props): JSX.Element => (
  <div className="text-center py-3">
    <div className="text-muted fw-bold">
      {isPendingReservation(reservationDetails) && 'Nadchodzące rezerwacje'}
      {isCheckedInReservation(reservationDetails) && 'Trwające rezerwacje'}
    </div>
    <div className="text-primary font-size-xxl fw-bold">Pobyt w {resort.name}</div>
    <div className="text-muted">
      w dniach {toShortDateFormat(reservationDetails.date_from)} - {toDefaultDateFormat(reservationDetails.date_to)}
    </div>
  </div>
)
