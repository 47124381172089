import * as clsx from 'clsx'
import * as React from 'react'

export enum TrapezeButtonVariant {
  default = 'default',
  primary = 'primary',
  secondary = 'secondary',
  green = 'green',
  gray = 'gray',
  red = 'red',
}

export interface TrapezeButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  variant?: TrapezeButtonVariant
  className?: string
  children: React.ReactNode
  disabled?: boolean
}

export const TrapezeButton = ({
  variant = TrapezeButtonVariant.default,
  children,
  className,
  disabled,
  ...props
}: TrapezeButtonProps): JSX.Element => (
  <button
    className={clsx(`btn trapeze-button__wrapper trapeze-button__wrapper--${variant}`, className)}
    disabled={disabled}
    {...props}
  >
    <div className="trapeze-button__content">{children}</div>
  </button>
)
