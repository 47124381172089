import * as React from 'react'
import { SectionTitle } from '@components/section-title'
import { CheckboxWithDescription } from '@components/controls/checkbox-with-description'
import { DoubleColumnRow } from '@components/double-column-row'
import { GdprStepSignature } from './gdpr-signature'
import { BookingOnlineDocuments, ReservationDetails } from '@models/reservation'
import { ClientDetails } from '@models/client'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  reservationDetails: ReservationDetails
  children?: React.ReactNode
  clientDetails: ClientDetails
}

export const GdprStep = ({ reservationDetails, children, clientDetails }: Props): JSX.Element => (
  <>
    <SectionTitle
      title="Obowiązki informacyjne"
      subtitle="Potwierdź niezbędne zgody i podpisz dokumenty"
      className="text-center text-md-start"
    />
    {reservationDetails.check_in_online_documents.map((checkInDocument: BookingOnlineDocuments) => (
      <CheckboxWithDescription
        name={checkInDocument.keyword}
        label={checkInDocument.name}
        key={checkInDocument.keyword}
      >
        {checkInDocument.keyword === 'stay_card' && (
          <div className="mb-1">
            <DoubleColumnRow
              leftColumn="Imię"
              rightColumn={clientDetails.first_name}
              leftColumnClassname="col-7 col-md-5"
            />
            <DoubleColumnRow
              leftColumn="Nazwisko"
              rightColumn={clientDetails.last_name}
              leftColumnClassname="col-7 col-md-5"
            />
            <DoubleColumnRow
              leftColumn="Seria i numer dowodu osobistego"
              rightColumn={reservationDetails.check_in_data.id_card}
              leftColumnClassname="col-7 col-md-5"
            />
          </div>
        )}
        {checkInDocument.url && (
          <a target="_blank" href={checkInDocument.url} className="text-decoration-none">
            <IconWithText iconClassName="uil-eye me-1" text="podgląd dokumentu" textClassName="font-size-11" />
          </a>
        )}
      </CheckboxWithDescription>
    ))}
    <hr className="my-5 my-xl-4 container-xxl-full-width section-divider mx-xl-n4" />
    <GdprStepSignature />
    {children}
  </>
)
