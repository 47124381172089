import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import { useAppSelector } from '@store/index'
import { selectAppData } from '@store/selectors/app-data-selectors'
import { Resort } from '@models/app-data'

interface Props {
  resortId: number
}

export const CheckedInReservationFooterMapOption = ({ resortId }: Props): JSX.Element | null => {
  const { resorts } = useAppSelector(selectAppData)
  const resort = resorts.find((resort: Resort) => resort.id === resortId)

  const [showResortMapModal] = useModal('ResortMapModal', {
    title: resort?.name,
    resort,
  })

  if (!resort?.apartments_map || !resort.apartments_map.endsWith('.svg')) return null

  return (
    <IconWithText
      text="Pokaż mapę ośrodka"
      iconClassName="uil-map me-2 font-size-icon-18"
      className="me-2 fw-bold text-muted checked-in-reservation__footer-option"
      onClick={showResortMapModal}
    />
  )
}
