import { ThunkAction } from 'redux-thunk'
import { RootState } from '@store/index'
import { ClientWalletItem } from '@models/wallet'
import axios from 'axios'
import { SetWalletHistoryItemsAction, WalletActionTypesKeys } from '@store/action-types/wallet-action-types'
import { clearEmptySelectFilters } from '@helpers/utils'
import { PaginationResponse } from '@models/index'
import { WalletHistoryFilterInterface } from '@modules/wallet/wallet-history'

export function fetchWalletItems(
  filters: WalletHistoryFilterInterface,
): ThunkAction<void, RootState, null, SetWalletHistoryItemsAction> {
  return async (dispatch, getState) => {
    const url = getState().clientState.details.urls.client_wallet_items
    const parseFilters = {
      created_after: clearEmptySelectFilters(filters.created_after),
      operation: clearEmptySelectFilters(filters.operation),
    }
    const { data } = await axios.get(url, { params: parseFilters })
    dispatch(setWalletItems(data))
  }
}

export function setWalletItems(wallet: PaginationResponse<ClientWalletItem>): SetWalletHistoryItemsAction {
  return { type: WalletActionTypesKeys.SET_WALLET_ITEMS, payload: wallet }
}
