import * as React from 'react'
import { ReservationDetailsMobileRow } from '@modules/reservations/pending/mobile/reservation-details-mobile-row'
import { useModal } from '@components/modals/use-modal'
import { GastroCard } from '@models/client'
import { getGastroProductName, isGastroGenericProduct } from '@modules/special-products/gastro-vouchers/utils'

interface Props {
  voucher: GastroCard
}

export const GastroVoucherDetailProductOverview = ({ voucher }: Props): JSX.Element => {
  const { showOverview } = useGastroProductOverview(voucher)

  return (
    <ReservationDetailsMobileRow
      icon="uil-plus"
      title="Dlaczego warto?"
      titleClassName="fw-bold font-size-md text-darker-gray"
      onClick={showOverview}
    />
  )
}

export const useGastroProductOverview = (voucher: GastroCard) => {
  const [showGastroGenericDescriptionModal] = useModal('GastroGenericDetailsDescriptionModal', {
    title: `Voucher "${getGastroProductName(voucher)}"`,
    voucher,
  })

  const [showGastroVoucherDetailsModal] = useModal('GastroVoucherDetailsDescriptionModal', {
    title: 'Voucher "Podwójna Radość"',
  })

  const showOverview = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()

    if (isGastroGenericProduct(voucher.kind)) {
      showGastroGenericDescriptionModal()
      return
    }

    showGastroVoucherDetailsModal()
  }

  return {
    showOverview,
  }
}
