import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ClientDetailsFormInputKey, ClientDetailsFormInputs } from '@modules/user'
import * as R from 'ramda'
import { FieldIsSavingContextProvider, useFieldIsSaving } from '@hooks/use-field-is-saving'
import { useDispatch } from 'react-redux'
import { updateClientDetails } from '@store/actions/client-actions'
import { useFormRequest } from '@hooks/use-api-request'
import { useDidUpdate } from 'rooks'

const scopeFormData = (formData: ClientDetailsFormInputs, scope: ClientDetailsFormInputKey[]) => R.pick(scope, formData)

interface Props {
  children: React.ReactNode
  formData: ClientDetailsFormInputs
  scope: ClientDetailsFormInputKey[]
}
export const FormLogicProvider = ({ children, formData, scope }: Props): JSX.Element => {
  const dispatch = useDispatch()
  const formControl = useForm({ defaultValues: scopeFormData(formData, scope) })
  const currentState = formControl.watch()

  const { isLoading, action: handleSave } = useFormRequest(async (partialState: Partial<ClientDetailsFormInputs>) => {
    const state = {
      ...formData,
      ...partialState,
    }
    await dispatch(
      updateClientDetails({
        ...state,
        language: state.language?.value,
        country: state.country?.value,
      }),
    )
  }, formControl.setError)

  const isFieldSaving = useFieldIsSaving(isLoading, Object.keys(formControl.formState.dirtyFields))

  useDidUpdate(() => {
    formControl.reset(scopeFormData(formData, scope))
  }, [formData, scope])

  const handleBlur = () => {
    if (!R.equals(scopeFormData(formData, scope), currentState)) {
      handleSave(formControl.getValues())
    }
  }

  const handleChange: React.FormEventHandler<HTMLFormElement> = event => {
    const input = event.target as HTMLInputElement
    if (input.classList.contains('form-check-input')) {
      handleSave(formControl.getValues())
    }
  }

  return (
    <FieldIsSavingContextProvider value={isFieldSaving}>
      {isLoading && <span data-testid="loader" />}
      <FormProvider {...formControl}>
        <form onBlur={handleBlur} onChange={handleChange}>
          {children}
        </form>
      </FormProvider>
    </FieldIsSavingContextProvider>
  )
}
