import * as React from 'react'
import * as Sentry from '@sentry/browser'
import { fetchAppData } from '@store/actions/app-data-actions'
import { connect } from 'react-redux'
import interceptorService from '@services/interceptor'
import { LoadFailureCode } from '@modules/errors'

interface ReduxProps {
  fetchAppData: () => Promise<void>
}

interface Props extends ReduxProps {
  onLoadFail: (responseCode: LoadFailureCode) => void
}

interface State {
  loading: boolean
}

export class StartupViewComponent extends React.Component<React.PropsWithChildren<Props>, State> {
  public constructor(props) {
    super(props)

    this.state = {
      loading: true,
    }
  }

  public componentDidMount(): void {
    interceptorService.setup()
    this.initializeData()
  }

  public render(): React.ReactNode {
    return this.state.loading ? null : this.props.children
  }

  public componentDidCatch(error: Error): void {
    Sentry.captureException(error)
  }

  public initializeData = async (): Promise<void> => {
    try {
      await this.props.fetchAppData()
    } catch (e) {
      this.props.onLoadFail(e.response.status)
    }
    this.setState({ loading: false })
  }
}

const mapDispatchToProps = dispatch => ({
  fetchAppData: () => dispatch(fetchAppData()),
})

const StartupView = connect(null, mapDispatchToProps)(StartupViewComponent)

export default StartupView
