import * as React from 'react'
import { ReservationReceiptModalCollapsableRow } from './reservation-receipt-modal-collapsable-row'
import { ReservationReceiptModalRow } from './reservation-receipt-modal-row'
import { formatDate } from '@helpers/date-helper'
import { sumDecimalArray } from '@helpers/utils'
import Decimal from 'decimal.js'
import { BillShopping } from '@models/bill'

interface Props {
  shoppingList: BillShopping[]
  guestName: string
}

export const ReservationReceiptModalGuestRow = ({ shoppingList, guestName }: Props): JSX.Element => {
  const totalGuestPurchasePrice = React.useMemo(
    () => sumDecimalArray(shoppingList.map(item => new Decimal(item.price_brutto))).toString(),
    [shoppingList],
  )

  return (
    <ReservationReceiptModalCollapsableRow
      titleColumn={guestName}
      priceColumn={totalGuestPurchasePrice}
      wrapperClassName="border-0 pb-0 pt-2"
    >
      {shoppingList.map(el => (
        <ReservationReceiptModalRow
          key={el.id}
          quantity={el.quantity}
          titleColumn={el.name}
          dateColumn={formatDate(el.created)}
          priceColumn={el.price_brutto}
          fiscalValue={el.fiscalization_price_brutto}
          indent
        />
      ))}
    </ReservationReceiptModalCollapsableRow>
  )
}
