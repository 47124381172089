import * as React from 'react'
import * as clsx from 'clsx'
import { ModalParams } from '@components/modals/types'

export const ModalSidebarImage = ({
  image,
  imageClassName,
  handleCloseModal,
  imageWrapperClassName,
}: Partial<ModalParams>) => (
  <div className={clsx('w-25 overflow-hidden d-flex justify-content-center position-relative', imageWrapperClassName)}>
    <img src={image} className={clsx('object-fit-cover w-100', imageClassName)} />
    <button
      className="btn bg-dark position-absolute top-0 end-0 d-flex align-items-center justify-content-center px-4 py-3 br-0"
      onClick={handleCloseModal}
    >
      <i className="uil uil-times font-size-icon-18 text-white" />
    </button>
  </div>
)
