import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ModalBody } from 'react-bootstrap'
import { SubscriptionContract } from '@models/client'
import { SubscriptionContractPaymentCardChangeBaseContent } from '@modules/special-products/subscription-contracts/payment-card-modal/subscription-contract-payment-card-change-base-content'

interface Props {
  subscriptionContract: SubscriptionContract
  handleClose: () => void
}

export const SubscriptionContractPaymentCardChangeConfirmationModal = ({
  handleClose,
  subscriptionContract,
}: Props): JSX.Element => {
  const hasPaymentCard = !!subscriptionContract.main_payment_card

  return (
    <ModalBody className="modal-scrollable">
      {hasPaymentCard ? (
        <SubscriptionContractPaymentCardChangeBaseContent
          subscriptionContract={subscriptionContract}
          onClose={handleClose}
          title="Czy na pewno chcesz zmienić"
          subtitle="kartę płatniczą?"
          submitText="Zmień dane karty"
        >
          <button className="btn btn-outline-secondary mt-3 px-5 py-2" onClick={handleClose}>
            <IconWithText
              text="Pozostaw obecną kartę"
              iconClassName="uil-check me-2 font-size-xxl"
              className="fw-medium"
            />
          </button>
        </SubscriptionContractPaymentCardChangeBaseContent>
      ) : (
        <SubscriptionContractPaymentCardChangeBaseContent
          subscriptionContract={subscriptionContract}
          onClose={handleClose}
          title="Uzupełnij dane Twojej"
          subtitle="karty płatniczej"
          submitText="Przejdź do uzupełniania danych"
          submitClassName="mt-5 border-danger border rounded py-2 px-3"
        />
      )}
    </ModalBody>
  )
}
