import * as React from 'react'
import * as R from 'ramda'
import { useDebounce } from 'rooks'

export const useHandleTableFiltersChange = (watch, filters, onSubmit, timeout = 250) => {
  const watched = watch()
  const newValues = {
    ...filters,
    ...watched,
    page: filters.page,
    page_size: filters.page_size,
    ordering: filters.ordering,
  }

  const handleDebounce = useDebounce(values => {
    onSubmit(values)
  }, timeout)

  React.useEffect(() => {
    if (!R.equals(newValues, filters)) {
      handleDebounce(newValues)
    }
  }, [newValues])
}
