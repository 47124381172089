import * as React from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useFormRequest } from '@hooks/use-api-request'
import { useDidUpdate } from 'rooks'
import { useModal } from '@components/modals/use-modal'
import { AccountActionResult } from '@modules/account/account-action-result'
import { OperationResult } from '@modules/payment-result/utils'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { ButtonLoading } from '@components/controls/button-loading'
import { LabeledRow } from '@components/labeled-row'
import { Col, Row } from 'react-bootstrap'
import { RefundStatus } from '@modules/refund/refund-status'
import { reservationRefundSelector } from '@store/slices/reservations-slice'
import { useAppSelector } from '@store/index'
import { commonObjectPut } from '@api/basic-requests'
import { ReservationRefund } from '@models/reservation'
import { RequestError, useHandleAxiosFormErrors } from '@hooks/use-handle-axios-errors'

export interface RefundFormInputs {
  deposit_return_name: string
  deposit_return_city: string
  deposit_return_postcode: string
  deposit_return_address: string
  deposit_return_account_number: string
}

export const Refund = (): JSX.Element => {
  const [isRefundSucceed, setIsRefundSucceed] = React.useState(false)
  const reservationRefund = useAppSelector(reservationRefundSelector)
  const handleAxiosFormErrors = useHandleAxiosFormErrors()

  const [showRefundConfirmationModal] = useModal('RefundConfirmationOtpModal')

  const methods = useForm<RefundFormInputs>({ defaultValues: reservationRefund })

  const handleSuccess = () => {
    setIsRefundSucceed(true)
  }

  const handleFailure = (error: RequestError) => {
    handleAxiosFormErrors(error, methods.setError)
  }

  const { isLoading, action: onSubmit } = useFormRequest(async (payload: RefundFormInputs) => {
    if (!reservationRefund) return

    try {
      await commonObjectPut<ReservationRefund>(reservationRefund.urls.details, {
        ...payload,
        deposit_return_account_number: payload.deposit_return_account_number.replace(/\W/gi, ''),
      })
    } catch (error) {
      if (error.response?.data?.detail_code === 'OTP_CODE_REQUIRED') {
        showRefundConfirmationModal(null, {
          refundUrl: reservationRefund.urls.details,
          refundData: payload,
          onSuccess: handleSuccess,
          onFailure: handleFailure,
          phone: error.response.data.otp.phone,
        })
      }
    }
  }, methods.setError)

  const values = useWatch({
    control: methods.control,
    name: [
      'deposit_return_account_number',
      'deposit_return_name',
      'deposit_return_city',
      'deposit_return_postcode',
      'deposit_return_address',
    ],
  })

  useDidUpdate(() => {
    methods.setValue('deposit_return_account_number', values[0].toUpperCase())
  }, [values[0]])

  const isFormFilled = values.every(el => !!el)

  const handleMoveToMainPage = () => {
    window.location.href = 'https://holidaypark.pl/'
  }

  const isReadOnly = !['missing_client_data', 'waiting_for_client_data'].includes(
    reservationRefund?.deposit_transfer_status ?? '',
  )

  return (
    <div>
      {isRefundSucceed ? (
        <AccountActionResult
          action={handleMoveToMainPage}
          actionButtonText="Wróć na stronę główną"
          result={OperationResult.success}
          successComponent={
            <h4 className="text-secondary fw-bold text-center mt-5 mb-2">Środki zostaną zwrócone na podane konto.</h4>
          }
        />
      ) : (
        <>
          <h4 className="text-secondary fw-bold text-center mt-5 mb-2">Zwrot Bankowy</h4>
          <p className="mb-4 text-center font-size-12">
            {!isReadOnly && (
              <span>
                Wypełnij poniższy formularz, abyśmy mogli dokonać zwrotu <br />
                środków na podane przez Ciebie konto.
              </span>
            )}
          </p>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <LabeledRow hideLabelOnWrap className="align-items-center mt-2 mt-xl-0" label="Nazwa odbiorcy:">
                <Row className="gx-2">
                  <Col xl={12}>
                    <CustomFormGroup
                      label="Nazwa odbiorcy:"
                      inputName="deposit_return_name"
                      formControlProps={{ placeholder: 'Nazwa odbiorcy', readOnly: isReadOnly }}
                      isSucceed={isReadOnly}
                    />
                  </Col>
                </Row>
              </LabeledRow>

              <LabeledRow hideLabelOnWrap label={<div className="mt-4">Adres odbiorcy:</div>}>
                <Row className="gx-2">
                  <Col xl={12}>
                    <CustomFormGroup
                      label="Adres odbiorcy:"
                      inputName="deposit_return_address"
                      formControlProps={{ type: 'text', placeholder: 'Ulica odbiorcy', readOnly: isReadOnly }}
                      isSucceed={isReadOnly}
                    />
                  </Col>
                </Row>
                <Row className="gx-2">
                  <Col xs={4}>
                    <CustomFormGroup
                      inputName="deposit_return_postcode"
                      formControlProps={{ placeholder: '00-000', readOnly: isReadOnly }}
                      isSucceed={isReadOnly}
                    />
                  </Col>
                  <Col xs={8} xl={8}>
                    <CustomFormGroup
                      inputName="deposit_return_city"
                      formControlProps={{ type: 'text', placeholder: 'Miejscowość', readOnly: isReadOnly }}
                      isSucceed={isReadOnly}
                    />
                  </Col>
                </Row>
              </LabeledRow>
              <LabeledRow hideLabelOnWrap className="align-items-center mt-2 mt-xl-0" label="Numer konta IBAN:">
                <Row className="gx-2">
                  <Col xl={12}>
                    <CustomFormGroup
                      label="Numer konta IBAN:"
                      inputName="deposit_return_account_number"
                      formControlProps={{ placeholder: 'PL 00 0000 0000 0000 0000 0000 0000', readOnly: isReadOnly }}
                      isSucceed={isReadOnly}
                    />
                  </Col>
                </Row>
              </LabeledRow>
              {isReadOnly && reservationRefund && <RefundStatus status={reservationRefund.deposit_transfer_status} />}
              {!isReadOnly && (
                <ButtonLoading
                  disabled={!isFormFilled}
                  isLoading={isLoading}
                  loadingLabel="Zatwierdzanie..."
                  className="btn-primary authorization-layout__button mb-4"
                  type="submit"
                >
                  <strong>Zatwierdź</strong>
                </ButtonLoading>
              )}
            </form>
          </FormProvider>
        </>
      )}
    </div>
  )
}
