import axios, { AxiosResponse } from 'axios'
import { RootState } from '@store/index'

export interface LogInPayload {
  username: string
  password: string
}

interface LoginResponse {
  next_url: string
  session_key: string
  success_url: string
}

export const logInUserRequest = async (state: RootState, payload: LogInPayload): Promise<LoginResponse> => {
  const url = state.appDataState.appData.urls.account.login
  const { data } = await axios.post<LogInPayload, AxiosResponse<LoginResponse>>(url, payload)
  return data
}

export const logOutUserRequest = async (state: RootState): Promise<void> => {
  const url = state.appDataState.appData.urls.account.logout
  await axios.get(url)
}

export async function getSmsCode(state: RootState, account: string): Promise<void> {
  const url = state.clientState.details.urls.account_number_update

  await axios.get(url, {
    params: { account_number: account },
  })
}
