import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'
import { SubscriptionContractDetails } from '@models/client'

interface Props {
  subscriptionContractDetails: SubscriptionContractDetails
  onSubscriptionContractChange: (subscriptionContract: SubscriptionContractDetails) => void
}

export const SubscriptionContractActions = ({
  subscriptionContractDetails,
  onSubscriptionContractChange,
}: Props): JSX.Element => {
  const [showSubscriptionDescriptionModal] = useModal('SubscriptionContractDescriptionModal', { title: 'Subskrypcja' })

  const [showSubscriptionResignationModal] = useModal('SubscriptionContractResignationModal', {
    title: 'Rezygnacja z subskrypcji',
    subscriptionContractDetails,
    onSubscriptionContractChange,
  })

  const [showSubscriptionResignationCancelModal] = useModal('SubscriptionContractResignationCancelModal', {
    subscriptionContractDetails,
    onSubscriptionContractChange,
  })

  const canResign = subscriptionContractDetails.status === 'confirmed'
  const canCancelResign = subscriptionContractDetails.status === 'cancelling'

  return (
    <div className="d-flex justify-content-between mb-xl-0 mb-3">
      <button className="btn bg-transparent text-darker-gray mt-2 border-0" onClick={showSubscriptionDescriptionModal}>
        <strong className="font-size-sm">Rozwiń opis subskrypcji</strong>
      </button>

      {canResign && (
        <IconWithText
          text="Rezygnacja z subskrypcji"
          textClassName="font-size-sm"
          iconClassName="uil-times font-size-xl lh-initial"
          className="text-danger mt-2 d-inline-flex"
          onClick={showSubscriptionResignationModal}
        />
      )}

      {canCancelResign && (
        <IconWithText
          text="Anuluj rezygnację"
          textClassName="font-size-sm"
          iconClassName="uil-redo font-size-lg lh-initial me-2"
          className="text-primary mt-2 d-inline-flex"
          onClick={showSubscriptionResignationCancelModal}
        />
      )}
    </div>
  )
}
