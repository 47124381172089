import * as React from 'react'
import { formatPrice } from '@helpers/price-helper'
import { ModalFooter } from '@components/modals/modal-footer'
import { ModalBody } from 'react-bootstrap'
import { IconWithText } from '@components/icon-with-text'
import { ContactInfo } from '@modules/payment-result/contact-info'

interface Props {
  handleClose: () => void
}

export const SubscriptionContractDescriptionModal = ({ handleClose }: Props): JSX.Element => (
  <>
    <ModalBody className="modal-scrollable text-muted px-4 pb-0">
      <p className="text-secondary fw-bold font-size-xl">Co zyskujesz kupując Subskrypcję?</p>
      <p className="font-size-sm">
        Już teraz dajemy możliwość skorzystania z naszej nowej usługi w postaci <strong>subskrypcji</strong>, dzięki
        której na starcie otrzymasz wyjątkowe prezenty.
      </p>
      <div>
        {subscriptionContractBenefits.map(benefit => (
          <IconWithText
            key={benefit.title}
            text={
              <span>
                <strong>{benefit.title}</strong>
                <p>{benefit.content}</p>
              </span>
            }
            iconClassName="uil-check me-2 text-primary font-size-lg align-self-start"
            textClassName="font-size-sm"
            className="col-xl-9"
          />
        ))}
      </div>
      <hr className="mx-n4" />
      <ContactInfo />
      <hr className="mx-n4 mb-0" />
      <div className="text-primary bg-light-blue d-flex justify-content-between font-size-xl mx-n4 px-4 py-2">
        <strong>Koszt subskrypcji:</strong>
        <strong>od {formatPrice('49.00')} / miesięcznie</strong>
      </div>
    </ModalBody>
    <ModalFooter withCancelButton withSubmit={false} onClose={handleClose} cancelLabel="Zamknij" />
  </>
)

const subscriptionContractBenefits = [
  {
    title: 'Pobyt w Holiday Park & Resort',
    content: '5-dniowy pobyt w Holiday Park & Resort dla 4 osób w apartamencie, odnawiany co 12 miesięcy',
  },
  {
    title: 'Terminy specjalne w ramach Pakietów',
    content: 'Możliwość rezerwacji pobytu pakietowego w Terminach Specjalnych z mniejszym wykorzystaniem kodów.',
  },
  {
    title: 'Rabaty na wyżywienie',
    content: '10% rabatu na wyżywienie (przy zakupie do 30 dni przed rozpoczęciem pobytu).',
  },
  { title: 'Rabaty w wysokim sezonie', content: 'Ceny pobytów w sezonie tańsze nawet o 50%.' },
  { title: 'Powiększony rabat wyjazdowy', content: 'Do 65% rabatu na pobyty z voucherem wyjazdowym.' },
]
