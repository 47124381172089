import { IconWithText } from '@components/icon-with-text'
import { Guest } from '@models/reservation'
import * as React from 'react'
import { Form } from 'react-bootstrap'
import * as clsx from 'clsx'

interface Props {
  guest: Guest
  isFeedingAdded: boolean
  onDelete: (guest: Guest) => void
  onCheck: (isFeeding: boolean, guest: Guest) => void
  onEdit?: (guest: Guest) => void
  isFeedingAvailable: boolean
  isFeedingChangeDisabled: boolean
  isGuestRemovingEnabled: boolean
  isEditing: boolean
}
export const ReservationDataCompletionAddedGuest = ({
  guest,
  isEditing,
  isFeedingAdded,
  isFeedingAvailable,
  isFeedingChangeDisabled,
  isGuestRemovingEnabled,
  onCheck,
  onDelete,
  onEdit,
}: Props): JSX.Element => {
  const handleCheck = React.useCallback(() => onCheck(!isFeedingAdded, guest), [onCheck, isFeedingAdded, guest])
  const handleDelete = React.useCallback(() => onDelete(guest), [onDelete, guest])

  return (
    <div
      className={clsx(
        'card bg-light-blue d-flex flex-wrap px-4 py-3 justify-content-between align-items-start flex-row-reverse mb-2',
        { 'opacity-75 pe-none': isEditing },
      )}
    >
      {isFeedingAvailable && (
        <div className="d-flex flex-column align-items-end justify-content-between flex-fill">
          <div className="d-flex align-items-center">
            <label className="fw-bold mx-2 text-muted font-size-sm cursor-pointer" htmlFor={`feeding-${guest.id}`}>
              Wyżywienie:
            </label>
            <Form.Check
              id={`feeding-${guest.id}`}
              checked={isFeedingAdded}
              className="checkbox cursor-pointer"
              type="checkbox"
              onChange={handleCheck}
              disabled={isFeedingChangeDisabled}
            />
          </div>
        </div>
      )}
      <div className="d-flex flex-fill">
        <i className="text-primary uil-user user-circle me-2" />
        <div>
          <div className="d-flex pt-1 align-items-center flex-wrap">
            <div className="text-primary fw-bold pe-2">{guest.name}</div>
            <div className="font-size-sm">{guest.type_display}</div>
          </div>
          <div className="d-flex text-muted pt-2">
            <IconWithText
              text="Zmień"
              iconClassName="uil-edit-alt font-size-xl me-2"
              textClassName="fw-bold"
              className="me-4"
              onClick={() => onEdit?.(guest)}
            />
            {isGuestRemovingEnabled && (
              <IconWithText
                text="Usuń"
                iconClassName="uil-trash-alt font-size-xl me-2"
                textClassName="fw-bold"
                onClick={handleDelete}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
