import { ModalBody } from 'react-bootstrap'
import { ModalFooter } from '@components/modals/modal-footer'
import * as React from 'react'
import { GdprData } from '@modules/user/gdpr-data/index'
import { ReservationRules } from '@modules/reservations/pending/data-completion-modal/reservation-rules'
import { FormProvider, useForm } from 'react-hook-form'
import { getRuleValues, isEachRulesRequiredForReservationAccepted } from '@modules/reservations/pending/utils'
import { TrapezeButtonVariant } from '@components/controls/trapeze-button'
import { useSelector } from 'react-redux'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import { selectClientProfileRules } from '@store/selectors/client-selectors'

interface Props {
  handleClose: () => void
  onRulesAccept?: () => void
}

export const ReservationRequiredRulesModal = ({ handleClose, onRulesAccept }: Props) => {
  const reservationDetails = useSelector(selectReservationDetails)
  const clientProfileRules = useSelector(selectClientProfileRules)

  const methods = useForm({ defaultValues: getRuleValues(reservationDetails?.rules || []) })

  const handleConfirm = () => {
    if (!reservationDetails) return
    handleClose()

    if (isEachRulesRequiredForReservationAccepted(reservationDetails.rules, clientProfileRules)) {
      onRulesAccept?.()
    }
  }

  return (
    <>
      <ModalBody>
        <FormProvider {...methods}>
          <GdprData navyTitle>
            {reservationDetails && <ReservationRules reservationDetails={reservationDetails} />}
          </GdprData>
        </FormProvider>
      </ModalBody>
      <ModalFooter submitLabel="Gotowe" saveButtonVariant={TrapezeButtonVariant.secondary} onSubmit={handleConfirm} />
    </>
  )
}
