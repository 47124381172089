import * as React from 'react'
import { Feeding, Guest, ReservationDetails, ReservationImprovement } from '@models/reservation'
import { getFeedingGuests, isFeeding, isPaidByVip } from '@helpers/improvement-helper'
import { formatPrice } from '@helpers/price-helper'
import { CheckInPaymentSummaryBillSection } from '@modules/reservations/check-in/bill/check-in-payment-summary-bill-section'
import {
  CheckInPaymentSummaryBillItem,
  PriceVariant,
} from '@modules/reservations/check-in/bill/check-in-payment-summary-bill-item'
import Decimal from 'decimal.js'
import { sumDecimalArray } from '@helpers/utils'
import { formatDate, toDefaultDateFormat } from '@helpers/date-helper'
import { differenceInDays, parseISO } from 'date-fns'
import declination from '@helpers/declination'

interface Props {
  reservationDetails: ReservationDetails
}

export const CheckInPaymentSummaryBill = ({ reservationDetails }: Props): JSX.Element => {
  const improvements = reservationDetails.prices.improvements.items.filter(
    improvement => !isPaidByVip(improvement) && !isFeeding(improvement),
  )

  const guestsWithFeeding = getFeedingGuests(reservationDetails)

  const getGuestName = (guestId: string) =>
    reservationDetails.guests.find((guest: Guest) => String(guest.id) === guestId)?.name ?? ''

  const getFeedingPrice = React.useCallback(
    (guestId: string) =>
      sumDecimalArray(
        reservationDetails.prices.feeding.items
          .filter((feeding: Feeding) => feeding.guest_id.toString() === guestId)
          .map((feeding: Feeding) => new Decimal(feeding.price_brutto)),
      ).toString(),
    [reservationDetails.prices.feeding.items],
  )

  const daysDiff = differenceInDays(parseISO(reservationDetails.date_to), parseISO(reservationDetails.date_from))

  const requiredDeposit = new Decimal(reservationDetails.prices.payments_summary.required.deposit)
    .plus(reservationDetails.prices.payments_summary.rest.deposit)
    .toString()

  const isDepositPaid = !new Decimal(requiredDeposit).gt(0)

  return (
    <div className="d-flex flex-column bg-light-blue container-xxl-full-width px-3 px-xl-4 pt-3 border-top mx-xl-n4">
      <div className="d-flex justify-content-between align-items-center gap-3 mb-3">
        <strong className="text-darker-gray d-block">
          Pobyt {!!reservationDetails.guests.length && <>{reservationDetails.guests.length} osób</>} w dniach{' '}
          <span className="text-nowrap">
            {formatDate(reservationDetails.date_from)} - {formatDate(reservationDetails.date_to)} ({daysDiff}{' '}
            {declination.stayDays(daysDiff)})
          </span>
        </strong>
        <strong className="text-nowrap">- {formatPrice(reservationDetails.prices.residence.total_price_brutto)}</strong>
      </div>
      <CheckInPaymentSummaryBillSection
        className="mb-3"
        title="Ulepszenia"
        price={reservationDetails.prices.improvements.total_price_brutto}
      >
        {improvements.map((improvement: ReservationImprovement) => (
          <CheckInPaymentSummaryBillItem
            key={improvement.id}
            name={improvement.name}
            amount={improvement.amount}
            price={improvement.price_brutto}
            date={toDefaultDateFormat(improvement.created)}
          />
        ))}
      </CheckInPaymentSummaryBillSection>
      {!!reservationDetails.prices.feeding.items.length && (
        <CheckInPaymentSummaryBillSection
          title="Wyżywienie"
          price={reservationDetails.prices.feeding.total_price_brutto}
          className="border-top pt-3 mb-3 container-xxl-full-width px-3"
        >
          {Object.keys(guestsWithFeeding).map(guestId => (
            <CheckInPaymentSummaryBillItem
              key={guestId}
              name={getGuestName(guestId)}
              price={getFeedingPrice(guestId)}
            />
          ))}
        </CheckInPaymentSummaryBillSection>
      )}
      {reservationDetails.warranty && (
        <CheckInPaymentSummaryBillSection
          title="Opcja rezygnacji"
          price={reservationDetails.prices.warranty_price_brutto}
          className="mb-3"
        />
      )}
      <CheckInPaymentSummaryBillSection
        title="Opłata klimatyczna"
        price={reservationDetails.prices.climatic.total_price_brutto}
        className="mb-3"
      />
      <CheckInPaymentSummaryBillSection
        title="Opłata eksploatacyjna za części wspólne"
        price={reservationDetails.prices.service_charge_brutto}
        className="mb-3"
      />
      <div className="border-top border-lighter-gray container-xxl-full-width px-3 pt-3 mx-xl-n4 mb-3">
        <CheckInPaymentSummaryBillSection title="Kaucja">
          <CheckInPaymentSummaryBillItem
            pricePrefix=""
            name="Wpłacono"
            price={reservationDetails.prices.payments_summary.paid.deposit}
            {...(isDepositPaid && { variant: PriceVariant.positive })}
          />
          <CheckInPaymentSummaryBillItem
            pricePrefix=""
            name="Do wpłaty"
            price={requiredDeposit}
            {...(!isDepositPaid && { variant: PriceVariant.negative })}
          />
        </CheckInPaymentSummaryBillSection>
      </div>

      <div className="border-top border-lighter-gray pt-3 d-flex justify-content-between container-xxl-full-width px-3 mx-xl-n4 mb-3">
        <strong className="font-size-lg">Wpłacono:</strong>
        <strong className="text-success">
          {formatPrice(reservationDetails.prices.payments_summary.paid.installment)}
        </strong>
      </div>
      <div className="border-top border-lighter-gray py-3 d-flex justify-content-between container-xxl-full-width px-3 mx-xl-n4 lh-1 mb-3 mb-xl-0">
        <strong className="font-size-lg">Do zapłaty łącznie:</strong>
        <strong className="text-danger">{formatPrice(reservationDetails.prices.rest_to_pay_in_checkin)}</strong>
      </div>
      <div className="border-top border-lighter-gray container-xxl-full-width px-3 d-xl-none mt-n3" />
    </div>
  )
}
