import * as React from 'react'
import { SubscriptionContractDetails } from '@models/client'
import { SubscriptionContractActions } from '@modules/special-products/subscription-contracts/subscription-contract-actions'
import { SubscriptionContractExpandableRowPaymentsBanner } from '@modules/special-products/subscription-contracts/expandable/subscription-contract-expandable-row-payments-banner'
import { SubscriptionContractsDetailsCodesList } from '@modules/special-products/subscription-contracts/codes/subscription-contract-codes-list'

interface Props {
  subscriptionContractDetails: SubscriptionContractDetails
  onSubscriptionContractChange: (subscriptionContract: SubscriptionContractDetails) => void
}

export const SubscriptionContractExpandableRowContent = ({
  subscriptionContractDetails,
  onSubscriptionContractChange,
}: Props): JSX.Element => (
  <div className="pb-3">
    <SubscriptionContractExpandableRowPaymentsBanner subscriptionContractDetails={subscriptionContractDetails} />
    <SubscriptionContractsDetailsCodesList codes={subscriptionContractDetails.codes} />
    <SubscriptionContractActions
      subscriptionContractDetails={subscriptionContractDetails}
      onSubscriptionContractChange={onSubscriptionContractChange}
    />
  </div>
)
