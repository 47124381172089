import { ReservationDetails } from '@models/reservation'
import { formatDate, toDefaultDateFormat } from '@helpers/date-helper'
import * as React from 'react'

interface Props {
  reservationDetails: ReservationDetails
}

export const FormattedArrivalDate = ({ reservationDetails }: Props): JSX.Element => (
  <div className="d-flex">
    <div className="me-1">{formatDate(reservationDetails.date_from, 'EEEEEE')}</div>
    <div>{toDefaultDateFormat(reservationDetails.date_from)}</div>
  </div>
)
