import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'

export const CouponUnavailableBox = (): JSX.Element => (
  <div className="col-12 col-md-8 mx-auto">
    <div className="coupons-view__info-box pt-4 pb-2">
      <i className="uil-sad-squint font-size-3xl text-primary text-center mt-2 mb-3 d-block" />
      <p className="text-primary font-size-xxl text-center col-10 mx-auto fw-bold">
        Przepraszamy, zakup kuponów jest obecnie niemożliwy.
      </p>
    </div>
    <div className="coupons-view__action-box__wrapper">
      <div className="coupons-view__action-box__content">
        <IconWithText
          text="Spróbuj ponownie później"
          iconClassName="uil-clock font-size-lg me-2 lh-1"
          className="font-size-sm justify-content-center mt-n1"
        />
      </div>
    </div>
  </div>
)
