import { IconWithText } from '@components/icon-with-text'
import * as React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { getFeedingType } from '@modules/reservations/pending/data-completion-modal/guests/utils'
import { FeedingType, ReservationDetails } from '@models/reservation'
import { useModal } from '@components/modals/use-modal'

interface Props {
  reservationDetails: ReservationDetails
  onModalClose: () => void
}
export const ReservationDataCompletionGuestsFeeding = ({
  reservationDetails,
  onModalClose,
}: Props): JSX.Element | null => {
  const feedingType = getFeedingType(reservationDetails.feeding_options)

  const feedingTypeDisplay = {
    [FeedingType.DINNER_WITH_BREAKFAST]: 'Śniadanie i obiadokolacje',
    [FeedingType.DINNER]: 'Obiadokolacje',
    [FeedingType.BREAKFAST]: 'Śniadanie',
  }[feedingType ?? FeedingType.DINNER_WITH_BREAKFAST]

  const [showFeedingDetailsModal] = useModal('FeedingDetailsModal', { title: 'Szczegóły wyżywienia:' })

  const handleShowFeedingDetailsModal = () => {
    onModalClose()
    showFeedingDetailsModal()
  }

  if (!feedingType) return null

  return (
    <div className="body-width body-padding border-top my-3">
      <Row>
        <Col />
        <Col xl={7}>
          <div className="d-flex align-items-center justify-content-between pt-3">
            <div>
              <label className="text-muted fw-bold">Wybrana opcja wyżywienia:</label>
              <IconWithText
                iconClassName="uil-check text-primary font-size-xxl me-2"
                text={feedingTypeDisplay}
                textClassName="fw-bold text-darker-gray"
              />
            </div>
            <Button variant="primary" className="px-4 py-3 text-white" onClick={handleShowFeedingDetailsModal}>
              <strong>Zmień</strong>
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}
