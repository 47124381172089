import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import { ModalFooter } from '@components/modals/modal-footer'
import { ProgressBar } from '@components/progress-bar'
import { ProgressBarLabel } from '@components/progress-bar-label'
import { CheckOutStep } from '@modules/reservations/check-out/index'
import { CheckOutSummaryStep } from '@modules/reservations/check-out/check-out-summary-step'
import { Reservation, ReservationDetails } from '@models/reservation'
import { ContentLoader } from '@components/loaders/content-loader'
import { useApiRequest } from '@hooks/use-api-request'
import { commonObjectGet } from '@api/basic-requests'
import { CheckOutReceiptStep } from '@modules/reservations/check-out/check-out-receipt-step'
import { useCheckOut } from '@modules/reservations/check-out/use-check-out'
import { TrapezeButtonVariant } from '@components/controls/trapeze-button'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useReservationCashbackVoucherInGeneration } from '@modules/special-products/cashback-vouchers/use-reservation-cashback-voucher-in-generation'
import { useModal } from '@components/modals/use-modal'

const stepProgress = {
  [CheckOutStep.receipt]: 45,
  [CheckOutStep.summary]: 95,
}

export interface CheckOutFormInputs {
  cashback_voucher_accepted: boolean
}

interface Props {
  handleClose: () => void
  reservation: Reservation
  initialStep?: CheckOutStep
}

export const CheckOutModal = ({ handleClose, reservation, initialStep = CheckOutStep.receipt }: Props): JSX.Element => {
  const [step, setStep] = React.useState<CheckOutStep>(initialStep)
  const [reservationDetails, setReservationDetails] = React.useState<ReservationDetails | null>(null)

  const methods = useForm<CheckOutFormInputs>({ defaultValues: { cashback_voucher_accepted: false } })
  const isCashbackVoucherSelected = useWatch({ control: methods.control, name: 'cashback_voucher_accepted' })
  const { voucher } = useReservationCashbackVoucherInGeneration(reservationDetails)

  const { isPaid, showPaymentModal, finishCheckOut, isLoading, bill, isFetchingBillData, refetchBill } =
    useCheckOut(reservationDetails)

  const { isLoading: isFetchingReservationDetails, action: fetchReservationDetails } = useApiRequest(async () => {
    setReservationDetails(await commonObjectGet(reservation.urls.details))
  })

  React.useEffect(() => {
    fetchReservationDetails()
  }, [])

  const proceedSubmit = async () => {
    if (step === CheckOutStep.summary) {
      await finishCheckOut(methods.getValues())
      handleClose()
      return
    }

    if (!isPaid) {
      await showPaymentModal()
    } else {
      setStep(CheckOutStep.summary)
    }
  }

  const handleSubmit = async () => {
    if (step === CheckOutStep.receipt && voucher && !isCashbackVoucherSelected) {
      showCashbackActivationModal()
      return
    }

    await proceedSubmit()
  }

  const [showCashbackActivationModal] = useModal('CashbackVoucherCheckoutModal', { onResignation: proceedSubmit })

  React.useEffect(() => {
    window.history.replaceState(null, '', window.location.pathname)
  }, [])

  const receiptStepLabel = isPaid ? 'Przejdź dalej' : 'Przejdź do płatności'

  return (
    <>
      <ModalBody className="pt-0 px-0 modal-scrollable">
        <ProgressBar
          progress={stepProgress[step]}
          label={<ProgressBarLabel progress={stepProgress[step]} label="Wymeldowanie on-line" />}
          className="border-end-0 border-start-0 border-top-0 rounded-0"
        />
        <ContentLoader isLoading={isFetchingReservationDetails || isFetchingBillData}>
          <FormProvider {...methods}>
            {reservationDetails && (
              <>
                {step === CheckOutStep.receipt && bill && (
                  <CheckOutReceiptStep
                    bill={bill}
                    receiptWrapperClassName="px-3"
                    reservationDetails={reservationDetails}
                    onRefetchBill={refetchBill}
                  />
                )}
                {step === CheckOutStep.summary && <CheckOutSummaryStep />}
              </>
            )}
          </FormProvider>
        </ContentLoader>
      </ModalBody>
      <ModalFooter
        saveButtonVariant={
          step === CheckOutStep.receipt && !isPaid ? TrapezeButtonVariant.red : TrapezeButtonVariant.secondary
        }
        cancelLabel="Zamknij"
        withCancelButton={step === CheckOutStep.receipt}
        submitLabel={step === CheckOutStep.receipt ? receiptStepLabel : 'Zakończ wymeldowanie'}
        submitIcon="uil-angle-right-b ms-2"
        submitClassName="d-flex flex-row-reverse"
        onClose={handleClose}
        onSubmit={handleSubmit}
        isLoading={isLoading}
        buttonDisabled={isFetchingBillData || isFetchingReservationDetails}
      />
    </>
  )
}
