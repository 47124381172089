import * as React from 'react'
import { ReservationReceiptModalRow } from '@modules/reservations/modals/reservation-receipt-modal-row'
import { BillImprovement } from '@models/bill'

interface Props {
  improvements: BillImprovement[]
}

export const ReservationReceiptModalImprovementsRow = ({ improvements }: Props): JSX.Element => (
  <>
    {improvements.map((improvement: BillImprovement) => (
      <ReservationReceiptModalRow
        key={improvement.id}
        titleColumn={improvement.name}
        priceColumn={improvement.price_brutto}
        dateColumn={improvement.created}
        indent
      />
    ))}
  </>
)
