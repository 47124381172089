import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import { ModalFooter } from '@components/modals/modal-footer'
import { TrapezeButtonVariant } from '@components/controls/trapeze-button'
import { SectionTitle } from '@components/section-title'
import { useSignaturePad } from '@components/use-signature-pad'

interface Props {
  onSignatureSave: (signatureDataUrl: string) => void
  handleClose: () => void
}

export const SignaturePadModal = ({ onSignatureSave, handleClose }: Props): JSX.Element => {
  const { save, clear, SignaturePad } = useSignaturePad()

  const handleSave = () => {
    handleClose()
    save(onSignatureSave)
  }

  return (
    <>
      <ModalBody>
        <SectionTitle
          title="Dodaj podpis"
          subtitle="Użyj myszy komputera lub gładzika aby wykonać podpis"
          className="text-center text-md-start pt-0"
        />
        <SignaturePad />
      </ModalBody>
      <ModalFooter
        cancelLabel="Popraw podpis"
        cancelIcon="uil-redo font-size-xxl me-2 lh-1 ps-2"
        submitIcon="uil-check me-2 font-size-xxl"
        saveButtonVariant={TrapezeButtonVariant.secondary}
        submitLabel="Podpisuję dokumenty"
        withCancelButton={true}
        onSubmit={handleSave}
        onClose={clear}
      />
    </>
  )
}
