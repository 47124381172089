import { IconWithText } from '@components/icon-with-text'
import { ReservationDetails } from '@models/reservation'
import * as React from 'react'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { NavigationPath } from '@models/routes'
import { isCheckedInReservation } from '@helpers/reservation'
import { isFeedingAdded } from '@modules/reservations/pending/data-completion-modal/guests/utils'
import { useSelector } from 'react-redux'
import { selectResortDetails } from '@store/selectors/reservations-selectors'
import { useFeeding } from '@hooks/use-feeding'
import * as clsx from 'clsx'
import Decimal from 'decimal.js'
import { getFeedingGuests } from '@helpers/improvement-helper'

const getNumberOfGuest = (num: number) => (num >= 5 ? 'osób' : num >= 2 ? 'osoby' : 'osoba')

interface Props {
  reservationDetails: ReservationDetails
}

export const ReservationUpgrades = ({ reservationDetails }: Props): JSX.Element => {
  const history = useHistory()

  const resortDetails = useSelector(selectResortDetails)

  const { isFeedingAvailable, showFeedingDetailsModal } = useFeeding(resortDetails)

  const isCheckedIn = isCheckedInReservation(reservationDetails)

  const moveToImprovementPage = () => {
    history.push(NavigationPath.Improvements.replaceToken(reservationDetails.token))
  }

  const isFeedingAlreadyAdded = isFeedingAdded(reservationDetails)

  const addedImprovementsCount = new Decimal(reservationDetails.prices.improvements.items.length)
    .plus(isFeedingAlreadyAdded ? 1 : 0)
    .toString()

  const addedFeedingCount = Object.keys(getFeedingGuests(reservationDetails)).length

  return (
    <div className="d-flex flex-column flex-xl-row mb-4 mt-3">
      {!isCheckedIn && (
        <>
          <Button onClick={moveToImprovementPage} className="mb-2 mb-xl-0 me-xl-2 me-0 btn-tall">
            <IconWithText
              className="text-white justify-content-center font-size-14 px-3"
              iconClassName="uil-plus me-1 font-size-xxl py-1"
              text={<strong>Ulepszenia ({addedImprovementsCount})</strong>}
            />
          </Button>
          {isFeedingAvailable && (
            <Button onClick={showFeedingDetailsModal} className="btn-tall">
              <IconWithText
                className="text-white justify-content-center font-size-14 px-3 "
                iconClassName={clsx('me-1 py-1', addedFeedingCount ? 'uil-pen' : 'font-size-xxl uil-plus')}
                text={
                  <strong>
                    Wyżywienie{' '}
                    {!!addedFeedingCount && (
                      <span>
                        ({addedFeedingCount} {getNumberOfGuest(addedFeedingCount)})
                      </span>
                    )}
                  </strong>
                }
              />
            </Button>
          )}
        </>
      )}
    </div>
  )
}
