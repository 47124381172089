import * as React from 'react'
import * as clsx from 'clsx'
import { ModalBody } from 'react-bootstrap'
import { ModalFooter } from '@components/modals/modal-footer'
import { ProgressBar } from '@components/progress-bar'
import { ProgressBarLabel } from '@components/progress-bar-label'
import { ContentLoader } from '@components/loaders/content-loader'
import { CheckInStepProgress, CheckInSteps } from '@modules/reservations/check-in/index'
import { PersonalDataStep } from '@modules/reservations/check-in/steps/personal-data/personal-data-step'
import { ImprovementsStep } from '@modules/reservations/check-in/steps/improvements/improvements-step'
import { PaymentSummaryStep } from '@modules/reservations/check-in/bill/payment-summary-step'
import { GdprStep } from '@modules/reservations/check-in/steps/gdpr/gdpr-step'
import { ConfirmationStep } from '@modules/reservations/check-in/steps/confirmation/confirmation-step'
import { TrapezeButtonVariant } from '@components/controls/trapeze-button'
import { useDispatch, useSelector } from 'react-redux'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import { usePaymentModal } from '@hooks/use-payment-modal'
import { useDidUpdate } from 'rooks'
import { Reservation, ReservationDetails } from '@models/reservation'
import { useApiRequest } from '@hooks/use-api-request'
import { setReservationDetails } from '@store/slices/reservations-slice'
import { commonObjectPost } from '@api/basic-requests'
import { useGdprStep } from '@modules/reservations/check-in/steps/gdpr/use-gdpr-step'
import { useModal } from '@components/modals/use-modal'
import {
  ArrivalTimeStep,
  ArrivalTimeStepFormInputs,
} from '@modules/reservations/check-in/steps/arrival-time/arrival-time-step'
import { useArrivalTimeUpdate } from '@modules/reservations/check-in/steps/arrival-time/use-arrival-time-update'

interface ConfirmButton {
  disabledIn: CheckInSteps
  isDisabled: boolean
}

export enum ImprovementStepMode {
  prompt,
  improvements,
}

interface Props {
  handleClose: () => void
  initialStep?: CheckInSteps
  reservation?: Reservation
  processStarted?: boolean
}

export const CheckInModal = ({
  handleClose,
  initialStep = CheckInSteps.personalData,
  reservation,
  processStarted,
}: Props): JSX.Element => {
  const [step, setStep] = React.useState<CheckInSteps>(initialStep)
  const [improvementStepMode, setImprovementStepMode] = React.useState(ImprovementStepMode.prompt)
  const [guestFormInteractionOccurred, setGuestFormInteractionOccurred] = React.useState(false)
  const [arrivalTimeFormValues, setArrivalTimeFormValues] = React.useState<ArrivalTimeStepFormInputs | null>()
  const [confirmButtonState, setConfirmButtonState] = React.useState<ConfirmButton>({
    disabledIn: CheckInSteps.personalData,
    isDisabled: false,
  })

  const reservationDetails = useSelector(selectReservationDetails)
  const dispatch = useDispatch()

  const { clientDetails, finishCheckIn, GdprStepWrapper, isDataFilled, isFinishing } = useGdprStep(reservationDetails)

  const { isLoading: isFetchingReservationDetails, action: fetchReservationDetails } = useApiRequest(
    async reservation => {
      dispatch(setReservationDetails(await commonObjectPost<ReservationDetails>(reservation.urls.online_checkin_start)))
    },
  )

  const isPersonalDataStepActive = step === CheckInSteps.personalData

  const handlePreviousStep = () => (isPersonalDataStepActive ? handleClose() : setStep(step - 1))

  const handleGuestFormInteraction = React.useCallback(() => {
    setGuestFormInteractionOccurred(true)
  }, [])

  const [showGuestSetupNotDetectedModal] = useModal('GuestSetupNotDetectedModal', {
    onMoveToTheNextStep: () => setStep(CheckInSteps.improvements),
  })

  const handleMoveToImprovementsStep = () => {
    if (!guestFormInteractionOccurred) {
      showGuestSetupNotDetectedModal()
      return
    }

    setStep(CheckInSteps.improvements)
  }

  const showPaymentModal = usePaymentModal({
    paymentUrl: reservationDetails?.urls?.payment || '',
    payload: {
      amount: reservationDetails?.prices.rest_to_pay_in_checkin || 0,
      target: 'booking_advance_with_climatic',
      source: 'client-panel-app-check-in',
    },
  })

  const submit = {
    [CheckInSteps.personalData]: () => handleMoveToImprovementsStep(),
    [CheckInSteps.gdpr]: () => setStep(CheckInSteps.arrivalTime),
    [CheckInSteps.arrivalTime]: async () => {
      if (arrivalTimeFormValues) await updateArrivalTime(arrivalTimeFormValues)
    },
    [CheckInSteps.confirmation]: async () => {
      await finishCheckIn()
      handleClose()
    },
    [CheckInSteps.improvements]: () => {
      if (improvementStepMode === ImprovementStepMode.improvements) {
        setImprovementStepMode(ImprovementStepMode.prompt)
        return
      }
      setStep(CheckInSteps.paymentSummary)
    },
    [CheckInSteps.paymentSummary]: () => {
      if (reservationDetails?.is_paid_with_deposit_with_climatic) {
        setStep(CheckInSteps.gdpr)
        return
      }

      showPaymentModal()
    },
  }

  const submitButtonData = () => {
    let variant = TrapezeButtonVariant.secondary
    let label = 'Przejdź dalej'

    if (step === CheckInSteps.improvements && improvementStepMode === ImprovementStepMode.improvements) {
      label = 'Gotowe'
    }

    if (step === CheckInSteps.paymentSummary && !reservationDetails?.is_paid_with_deposit_with_climatic) {
      label = 'Przejdź do płatności'
      variant = TrapezeButtonVariant.red
    }

    if (step === CheckInSteps.confirmation) {
      label = 'Zakończ meldowanie'
    }

    return {
      variant,
      label,
    }
  }

  React.useEffect(() => {
    window.history.replaceState(null, '', window.location.pathname)
  }, [])

  useDidUpdate(() => {
    if (step !== confirmButtonState.disabledIn && confirmButtonState.isDisabled)
      setConfirmButtonState({ disabledIn: step, isDisabled: false })
  }, [step])

  React.useEffect(() => {
    if (step === CheckInSteps.personalData) {
      setConfirmButtonState({
        disabledIn: CheckInSteps.personalData,
        isDisabled: !reservationDetails?.check_in_data.id_card,
      })
    }
  }, [reservationDetails?.check_in_data, step])

  React.useEffect(() => {
    if (step === CheckInSteps.gdpr) {
      setConfirmButtonState({
        disabledIn: CheckInSteps.gdpr,
        isDisabled: !isDataFilled,
      })
    }
  }, [step, isDataFilled])

  React.useEffect(() => {
    if (reservation && !processStarted) {
      fetchReservationDetails(reservation)
    }
  }, [reservation])

  const { isLoading: isUpdatingArrivalTime, update: updateArrivalTime } = useArrivalTimeUpdate(reservationDetails, () =>
    setStep(CheckInSteps.confirmation),
  )

  return (
    <>
      <ModalBody className="py-0 px-0 modal-scrollable">
        <ProgressBar
          progress={CheckInStepProgress[step]}
          color={step === CheckInSteps.confirmation ? 'success' : 'navy'}
          label={
            <ProgressBarLabel
              progress={CheckInStepProgress[step]}
              label="Meldowanie on-line"
              className={clsx({ 'text-success': step === CheckInSteps.confirmation })}
            />
          }
          className="border-end-0 border-start-0 border-top-0 rounded-0"
        />
        <ContentLoader isLoading={isFetchingReservationDetails}>
          <div className="px-lg-4 px-3">
            {step === CheckInSteps.personalData && (
              <PersonalDataStep onGuestFormInteraction={handleGuestFormInteraction} />
            )}
            {step === CheckInSteps.improvements && (
              <ImprovementsStep mode={improvementStepMode} onModeChange={setImprovementStepMode} />
            )}
            {step === CheckInSteps.confirmation && <ConfirmationStep />}
            {step === CheckInSteps.paymentSummary && reservationDetails && (
              <PaymentSummaryStep reservationDetails={reservationDetails} />
            )}
            {step === CheckInSteps.gdpr && reservationDetails && (
              <GdprStepWrapper>
                <GdprStep reservationDetails={reservationDetails} clientDetails={clientDetails} />
              </GdprStepWrapper>
            )}
            {step === CheckInSteps.arrivalTime && reservationDetails && (
              <ArrivalTimeStep reservation={reservationDetails} onDataChange={setArrivalTimeFormValues} />
            )}
          </div>
        </ContentLoader>
      </ModalBody>
      <ModalFooter
        cancelLabel={isPersonalDataStepActive ? 'Zamknij' : 'Cofnij'}
        onClose={handlePreviousStep}
        cancelIcon={clsx('ps-3 me-2 font-size-xxl lh-1', isPersonalDataStepActive ? 'uil-times' : 'uil-angle-left-b')}
        submitIcon="uil-angle-right-b ms-2"
        saveButtonVariant={submitButtonData().variant}
        submitLabel={submitButtonData().label}
        withCancelButton={true}
        isLoading={isFinishing || isUpdatingArrivalTime}
        savingLabel={isFinishing ? 'Meldowanie...' : 'Proszę czekać...'}
        submitClassName="d-flex flex-row-reverse"
        onSubmit={submit[step]}
        buttonDisabled={confirmButtonState.isDisabled}
      />
    </>
  )
}
