import * as React from 'react'
import { useLink } from '@hooks/use-link'
import { useRules } from '@hooks/use-rules'

export const CouponRules = (): JSX.Element => {
  const { openLink } = useLink()
  const { getRules } = useRules()
  const [couponRules] = getRules(['coupons_rules'])

  const handleLinkClick = (event: React.MouseEvent) => {
    event.preventDefault()
    openLink(couponRules?.url)
  }

  return (
    <span className="text-center mx-auto d-block font-size-sm text-white my-3 fw-normal">
      Szczegóły promocji są dostępne w{' '}
      <button className="fw-semibold bg-transparent border-0 px-0" onClick={handleLinkClick}>
        regulaminie
      </button>
    </span>
  )
}
