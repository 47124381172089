import * as React from 'react'
import { BookingGuestAgreement } from '@models/reservation'

interface Props {
  details: BookingGuestAgreement
}

export const DataProcessingClientData = ({ details }: Props): JSX.Element => (
  <div className="my-5">
    <h5 className="text-primary mb-4">Dane kontaktowe Klienta:</h5>
    <div className="d-flex flex-wrap font-size-lg">
      <div className="col-md-3 col-6 fw-bold">
        <p className="mb-1">Numer rezerwacji</p>
        {details.reservation_number}
      </div>
      <div className="col-md-3 col-6 fw-bold">
        <p className="mb-1">Imię i Nazwisko</p>
        {details.name}
      </div>
      <div className="col-md-3 col-6 fw-bold mt-md-0 mt-4">
        <p className="mb-1">Numer telefonu</p>
        {details.phone}
      </div>
      <div className="col-md-3 col-6 fw-bold mt-md-0 mt-4">
        <p className="mb-1">Adres mailowy</p>
        {details.email}
      </div>
    </div>
  </div>
)
