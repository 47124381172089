import { CompanyDataFromGus } from '@models/invoice'
import { RootState } from '@store/index'
import axios from 'axios'

export async function getCompanyDataFromGus(state: RootState, nip: string): Promise<CompanyDataFromGus> {
  const url = state.appDataState.appData.urls.other.nip_search

  const { data } = await axios.get(url, {
    params: {
      nip,
    },
  })

  return data
}
