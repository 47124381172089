import * as React from 'react'

interface Props {
  title: string
  productName: string
  productNumber: string | number
}

export const SpecialProductDetailsHeader = ({ title, productNumber, productName }: Props): JSX.Element => (
  <div className="text-center py-3 col-10 mx-auto">
    <p className="mb-0">{title}</p>
    <strong className="text-primary font-size-xxl">{productName}</strong>
    <p className="text-muted">({productNumber})</p>
  </div>
)
