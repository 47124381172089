import * as React from 'react'
import {
  getGroupedImprovements,
  getSelectableImprovements,
  isVip,
  sortImprovementByPosition,
} from '@helpers/improvement-helper'
import { Feeding, ResortImprovement } from '@models/reservation'
import { useSelector } from 'react-redux'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import { selectAppData } from '@store/selectors/app-data-selectors'

interface AvailableImprovements {
  vipImprovements: ResortImprovement[]
  restImprovements: ResortImprovement[]
  stayOptionsImprovements: ResortImprovement[]
  reservationFeedingImprovements: Feeding[]
}

export const useAvailableImprovements = (resortImprovements: ResortImprovement[]): AvailableImprovements => {
  const reservationDetails = useSelector(selectReservationDetails)
  const appData = useSelector(selectAppData)

  const improvementsToAdd = React.useMemo(() => {
    const reservationImprovements = reservationDetails?.prices.improvements.items ?? []
    const reservationVipImprovement = reservationImprovements.find(isVip)

    const improvements = reservationVipImprovement
      ? resortImprovements.filter(
          (resortImprovement: ResortImprovement) =>
            !appData.package_vip_improvements_in_price[reservationVipImprovement.code]?.includes(
              resortImprovement.code,
            ) && !isVip(resortImprovement),
        )
      : resortImprovements

    const filterByAccommodationType = (resortImprovement: ResortImprovement) =>
      reservationDetails && resortImprovement.available_in.includes(reservationDetails?.accommodation_type_id)

    return getSelectableImprovements(improvements, reservationDetails).filter(filterByAccommodationType)
  }, [resortImprovements, reservationDetails])

  const reservationFeedingImprovements = React.useMemo(
    () => reservationDetails?.prices?.feeding?.items ?? [],
    [reservationDetails?.prices?.improvements],
  )

  const groupedImprovements = React.useMemo(() => getGroupedImprovements(improvementsToAdd), [improvementsToAdd])

  return {
    vipImprovements: groupedImprovements.vipImprovements,
    restImprovements: sortImprovementByPosition(groupedImprovements.restImprovements),
    stayOptionsImprovements: sortImprovementByPosition(groupedImprovements.stayOptionsImprovements),
    reservationFeedingImprovements,
  }
}
