import * as React from 'react'
import * as clsx from 'clsx'

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
  iconClassName?: string
  onClick?: () => void
}

export const IconSolo = ({ iconClassName, className, onClick, ...props }: Props): JSX.Element => (
  <div className={clsx('icon-solo d-flex', { 'cursor-pointer': !!onClick }, className)} onClick={onClick} {...props}>
    <i className={clsx('icon-solo__icon', iconClassName)} />
  </div>
)
