import { useModal } from '@components/modals/use-modal'
import { ConfirmationModalProps } from '@components/modals/modal-confirmation'

export const useConfirmation = () => {
  const [showConfirmationModal] = useModal('ConfirmationModal')

  const showSuccess = ({ ...props }: Partial<ConfirmationModalProps>) => {
    showConfirmationModal(null, { ...props, variant: 'success' })
  }

  const showError = ({ ...props }: Partial<ConfirmationModalProps>) => {
    showConfirmationModal(null, { ...props, variant: 'error' })
  }

  return {
    showSuccess,
    showError,
  }
}
