import * as React from 'react'
import * as clsx from 'clsx'
import { FeedingImprovement, Improvement, ReservationDetails, ReservationImprovement } from '@models/reservation'
import { IconWithText } from '@components/icon-with-text'
import { IconSolo } from '@components/icon-solo'
import { useDispatch, useSelector } from 'react-redux'
import { isFeeding, isStayOption, isVip } from '@helpers/improvement-helper'
import { useModal } from '@components/modals/use-modal'
import { setReservationDetails } from '@store/slices/reservations-slice'
import { selectResortDetails } from '@store/selectors/reservations-selectors'
import { useVipModals } from '@modules/reservations/improvements/kinds/vip/use-vip-modals'
import { useImprovementRemovingCheck } from '@hooks/use-improvement-removing-check'

interface Props {
  improvement: ReservationImprovement | FeedingImprovement
  reservationStartDate: Date
  reservationToken: string
}

export const CheckInImprovementRow = ({ improvement, reservationStartDate, reservationToken }: Props): JSX.Element => {
  const resortDetails = useSelector(selectResortDetails)
  const dispatch = useDispatch()
  const onUpdate = (data: ReservationDetails) => dispatch(setReservationDetails(data))
  const resortImprovement = resortDetails?.improvements.find(el => el.code === improvement.code)
  const { showVipDetailsModal } = useVipModals(resortImprovement)

  const handleEditSelection = () => {
    hideImprovementDeleteModal()
    showModal()
  }

  const [showImprovementDeleteModal, hideImprovementDeleteModal] = useModal('ImprovementDeleteModal', {
    improvement,
    onEdit: handleEditSelection,
    onRemove: onUpdate,
  })

  const [showFeedingDetailsModal] = useModal('FeedingDetailsModal', { title: 'Szczegóły wyżywienia:' })

  const [showImprovementDetailsModal] = useModal('BaseImprovementDetailsModal', {
    title: 'Szczegóły ulepszenia:',
    resortImprovement: resortImprovement,
    reservationImprovement: improvement,
  })

  const [showStayOptionModal] = useModal('StayOptionDetailsModal', { title: 'Szczegóły ulepszenia', resortImprovement })

  const handleRemove = React.useCallback(() => {
    showImprovementDeleteModal()
  }, [showImprovementDeleteModal])

  const showModal = () => {
    if (!improvement?.can_update) return

    if (isFeeding(improvement as Improvement)) showFeedingDetailsModal()
    else if (isVip(improvement as Improvement)) showVipDetailsModal()
    else if (isStayOption(improvement as Improvement)) showStayOptionModal()
    else showImprovementDetailsModal()
  }

  const { canRemoveEntireImprovement } = useImprovementRemovingCheck()

  const isRemovingAllowed = React.useMemo(
    () => canRemoveEntireImprovement({ improvement, reservation: reservationToken, dateFrom: reservationStartDate }),
    [improvement.can_remove, reservationStartDate, reservationToken],
  )

  return (
    <div className="d-flex align-items-center justify-content-between gap-3 border-top border-bottom overlap-border-top-1 py-2 px-3">
      <div className="d-flex">
        <IconWithText
          text={improvement.name}
          iconClassName="uil-check me-2 text-primary "
          className={clsx('font-size-sm text-muted me-3', { 'cursor-default': !improvement.can_update })}
          onClick={showModal}
        />
        {isRemovingAllowed && <IconSolo className="uil-trash-alt text-muted" onClick={handleRemove} />}
      </div>
      <span className="font-size-sm">x {improvement.amount}</span>
    </div>
  )
}
