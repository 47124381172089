import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { PackageVip } from '@models/client'
import { useModal } from '@components/modals/use-modal'
import { getPackageVipName } from '@modules/special-products/packages-vip/utils'

interface Props {
  packageVip: PackageVip
}

export const PackageVipExpandableRowHeaderNotAdded = ({ packageVip }: Props): JSX.Element => {
  const [showReservationSelectionModal] = useModal('PackageVipAssignmentModal', {
    title: 'Przypisywanie pakietu VIP',
    packageVip,
  })

  const handleAddToReservation = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    showReservationSelectionModal()
  }

  return (
    <div className="px-2">
      <IconWithText
        iconClassName="uil-check-square me-2 font-size-lg lh-1"
        text={
          <span>
            {getPackageVipName(packageVip)} <span className="fw-light">({packageVip.number})</span>
          </span>
        }
      />
      <IconWithText
        onClick={handleAddToReservation}
        text="Dodaj do swojej rezerwacji"
        textClassName="font-weight-semibold"
        iconClassName="me-2 uil-plus font-size-lg lh-1"
        className="d-inline-flex"
      />
    </div>
  )
}
