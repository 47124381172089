import * as React from 'react'
import { ChangeEvent } from 'react'
import { ParkingKind, ReservationImprovement, ResortImprovement } from '@models/reservation'
import { useFormContext, useWatch } from 'react-hook-form'
import { createArrFromNumber, createHash, handleEnter } from '@helpers/utils'
import { useSelector } from 'react-redux'
import { ImprovementDetailsFormInputs } from '@modules/reservations/improvements/kinds/base-improvements/base-improvement-details-modal'
import { CalculatedImprovement } from '@api/improvements'
import { ImprovementSummaryModalRow } from '@modules/reservations/improvements/summary/improvement-summary-modal-row'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { IconWithText } from '@components/icon-with-text'
import Decimal from 'decimal.js'
import { selectResortDetails } from '@store/selectors/reservations-selectors'
import { useImprovementDetailsParkingPlates } from '@modules/reservations/improvements/kinds/base-improvements/use-improvement-details-parkings'

const withoutId = id => el => Object.getOwnPropertyDescriptor(el, 'id') ? el.id !== id : el !== id
const withId = id => el => el.id === id
const getAddedPlates = (plates: CarPlate[]): CarPlate[] => plates.filter((plate: CarPlate) => plate.isAdded)

export interface CarPlate {
  id: string
  carId: number | null
  register_number: string
  isAdded: boolean
  kind: ParkingKind
}

interface Props {
  parkingKind: ParkingKind
  parkingName: string
  calculatedImprovements: CalculatedImprovement[] | undefined
  isPriceCalculating: boolean
  isDeleteEnabled: boolean
  reservationImprovement: ReservationImprovement | undefined
}

export const ImprovementCarPlatesField = ({
  calculatedImprovements,
  isPriceCalculating,
  parkingKind,
  parkingName,
  isDeleteEnabled,
  reservationImprovement,
}: Props): JSX.Element => {
  const { control, setValue } = useFormContext<ImprovementDetailsFormInputs>()

  const createEmptyCarPlate = id => ({
    id,
    carId: null,
    register_number: '',
    isAdded: false,
    kind: parkingKind,
  })

  const resortDetails = useSelector(selectResortDetails)

  const { carsForKind, getPlates } = useImprovementDetailsParkingPlates(parkingKind)

  const [plateInputs, setPlateInputs] = React.useState<string[]>([])

  const [amount, plates, maxAmount] = useWatch({ control, name: ['amount', 'plates', 'maxAmount'] })

  React.useEffect(() => {
    if (amount > plateInputs.length) addPlateInput()
    if (amount < plateInputs.length) removePlateInput()
  }, [amount])

  React.useEffect(() => {
    if (!carsForKind?.length) return

    const response = getPlates(reservationImprovement, amount, parkingKind)
    if (!response) return

    setPlateInputs(response.plateInputs)
    setValue('plates', response.platesFromRegisteredCars)
  }, [carsForKind])

  const addPlateInput = () => {
    const amountDifference = amount - plateInputs.length
    const ids = createArrFromNumber(amountDifference).map(createHash)
    setPlateInputs([...plateInputs, ...ids])
    setValue('plates', [...plates, ...ids.map(createEmptyCarPlate)])
  }

  const removePlateInput = () => {
    const idToRemove = plateInputs[plateInputs.length - 1]
    setPlateInputs(plateInputs.filter(withoutId(idToRemove)))
    setValue('plates', plates.filter(withoutId(idToRemove)))
  }

  const handleAddPlate = id => event => {
    setValue(
      'plates',
      plates.map((plate: CarPlate) =>
        plate.id === id ? { ...plate, register_number: event.target.value.toUpperCase(), isAdded: true } : plate,
      ),
    )
  }

  const handleDeletePlate = id => () => {
    const newPlateInputs = plateInputs.filter(withoutId(id))
    if (newPlateInputs.length) {
      setPlateInputs(newPlateInputs)
      setValue('plates', plates.filter(withoutId(id)))
    } else {
      setValue('plates', [{ ...plates[0], register_number: '', isAdded: false }])
    }

    setValue('amount', Math.max(amount - 1, 1))
  }

  const isAdded = id => {
    const plate = plates.find(withId(id))
    return plate?.isAdded && !!plate.register_number
  }

  const handlePlateNumberChange =
    (id: string) =>
    ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
      setValue(
        'plates',
        plates.map((plate: CarPlate) => (plate.id === id ? { ...plate, register_number: value.toUpperCase() } : plate)),
      )
    }

  const isAddingNextPlateAvailable = (id, index) => {
    if (reservationImprovement) return

    const canAddNext = resortDetails?.improvements.some(
      (resortImprovement: ResortImprovement) => resortImprovement.code === parkingKind,
    )

    if (!canAddNext) return

    const addedPlates = getAddedPlates(plates)
    const isPlateAdded = addedPlates.find(plate => plate.id === id)?.isAdded
    return (
      new Decimal(addedPlates.length || 0).plus(1).lte(maxAmount) &&
      isPlateAdded &&
      plateInputs.length - 1 === index &&
      plates.length < maxAmount
    )
  }

  const handleAddPlateAmount = () => {
    const newAmount = amount + 1
    if (amount <= maxAmount) setValue('amount', newAmount)
  }

  return (
    <div>
      <div className="pb-3 container-lg">
        {plateInputs.map((id, index) => (
          <div className="row px-3 px-xl-4 " key={index}>
            <CustomFormGroup
              inputName={`plates.${index}.register_number`}
              formGroupProps={{ className: 'col-12 col-xl-6 px-0 pe-xl-3 mb-3 mb-xl-0' }}
              formLabelProps={{ className: 'font-size-md fw-medium' }}
              formControlProps={{
                onKeyDown: handleEnter(handleAddPlate(id)),
                onChange: handlePlateNumberChange(id),
                onBlur: handleAddPlate(id),
                className: 'font-size-md',
              }}
              isSucceed={isAdded(id)}
              {...(index === 0 && { label: 'Wpisz numer rejestracji (opcjonalnie)' })}
            />
            {isAddingNextPlateAvailable(id, index) && (
              <IconWithText
                text="Dodaj kolejny numer rejestracyjny"
                iconClassName="uil-plus me-2"
                onClick={handleAddPlateAmount}
                className="col-12 col-xl-6 text-darker-gray mt-xl-4"
              />
            )}
          </div>
        ))}
      </div>
      {plates.map((plate: CarPlate, index) => (
        <ImprovementSummaryModalRow
          key={plate.id}
          text={`${parkingName} ${plate.register_number}`}
          price={(Array.isArray(calculatedImprovements) && calculatedImprovements[index]?.price) || 0}
          onDelete={handleDeletePlate(plate.id)}
          isPriceCalculating={isPriceCalculating}
          isDeleteEnabled={((!!plates.length && !!plate.register_number) || plates.length > 1) && isDeleteEnabled}
        />
      ))}
    </div>
  )
}
