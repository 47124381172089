import * as React from 'react'
import { LabeledRow } from '@components/labeled-row'
import { ClientProfileGuestsList } from '@modules/user/guests/list'
import { useSelector } from 'react-redux'
import { RootState } from '@store/index'

export const ClientProfileGuests = (): JSX.Element => {
  const guests = useSelector((state: RootState) => state.clientState.details.profile.guests)

  return (
    <div className="user-companions__wrapper">
      <LabeledRow
        className="align-items-center"
        label={<div className="text-primary font-size-20 fw-bold pb-xl-4">Osoby towarzyszące</div>}
      />
      <LabeledRow
        label={
          <div className="flex-column">
            <div className="d-flex flex-column text-darker-gray text-center text-xl-start justify-content-center">
              <div>
                Lista Gości, którzy mogą <br className="desktop-break" />
                towarzyszyć Ci podczas wakacji
              </div>
            </div>
            <div className="d-flex flex-column mt-2 mb-3 font-size-12 fw-light text-center text-xl-start justify-content-center">
              <div>
                Pamiętaj, że te informacje pomogą
                <br className="desktop-break" /> szybciej uzupełnić <br className="mobile-break" />
                dane w Twoich
                <br className="desktop-break" /> nowych rezerwacjach.
              </div>
            </div>
          </div>
        }
      >
        <ClientProfileGuestsList guests={guests} />
      </LabeledRow>
    </div>
  )
}
