import * as React from 'react'
import { GastroCardDetails } from '@models/client'
import { ReservationDetailsMobileRow } from '@modules/reservations/pending/mobile/reservation-details-mobile-row'
import { SpecialProductStatus, StatusVariant } from '@modules/special-products/special-product-status'
import { IconWithText } from '@components/icon-with-text'
import { DateFormats, formatDate } from '@helpers/date-helper'
import { GastroVoucherDetailProductOverview } from '@modules/special-products/gastro-vouchers/details/gastro-voucher-detail-product-overview'

interface Props {
  gastroCardDetails: GastroCardDetails
}

export const GastroVoucherDetailsBaseInfo = ({ gastroCardDetails }: Props): JSX.Element => (
  <div className="mx-n2 border-bottom">
    <ReservationDetailsMobileRow icon="ps-4" title="Status vouchera:">
      <SpecialProductStatus variant={StatusVariant[gastroCardDetails.status]} text={gastroCardDetails.status_display} />
    </ReservationDetailsMobileRow>

    {gastroCardDetails.status === 'initial' ? (
      <GastroVoucherDetailProductOverview voucher={gastroCardDetails} />
    ) : (
      <ReservationDetailsMobileRow icon="uil-calender" title="Kupiony:">
        <IconWithText
          text={formatDate(gastroCardDetails.sell_date, DateFormats.MONTH_WITH_FULL_WORD_MONTH_WITH_YEAR)}
          iconClassName="uil-info-circle text-primary me-2 font-size-icon-18"
        />
      </ReservationDetailsMobileRow>
    )}
  </div>
)
