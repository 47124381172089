import { ContactInfo } from '@modules/payment-result/contact-info'
import * as React from 'react'

export const AccountCreateFailure = () => (
  <>
    <h4 className="text-secondary fw-bold text-center mt-5 mb-2">Pojawił się problem podczas tworzenia konta</h4>
    <p className="mb-4 mt-3 text-center font-size-12">
      Informacja o problemie została już do nas wysłana.
      <br />
      Skontaktujemy się z Tobą jak najszybciej się da.
    </p>
    <ContactInfo />
  </>
)
