import * as React from 'react'
import { CheckInParkingForm } from '@modules/reservations/check-in/steps/personal-data/parking-form'
import { CheckInIdCardForm } from '@modules/reservations/check-in/steps/personal-data/id-card-form'
import { CheckInGuestsForm } from '@modules/reservations/check-in/steps/personal-data/guests-form'

interface Props {
  children?: React.ReactNode
  onGuestFormInteraction: () => void
}

export const PersonalDataStep = ({ children, onGuestFormInteraction }: Props): JSX.Element => (
  <>
    <CheckInIdCardForm />
    <hr className="my-4 my-md-3 mx-xl-n4 container-xxl-full-width section-divider" />
    <CheckInParkingForm />
    <hr className="my-4 mb-md-3 mt-0 mx-xl-n4 container-xxl-full-width section-divider" />
    <CheckInGuestsForm onGuestInteraction={onGuestFormInteraction} />
    {children}
  </>
)
