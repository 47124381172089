import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  className?: string
  leftColumn: string
  rightColumn: string
  leftColumnClassname?: string
  rightColumnClassname?: string
}

export const DoubleColumnRow = ({
  className,
  leftColumn,
  leftColumnClassname,
  rightColumn,
  rightColumnClassname,
}: Props): JSX.Element => (
  <div className={clsx('d-flex', className)}>
    <div className={clsx('text-light-gray', leftColumnClassname)}>{leftColumn}</div>
    <div className={clsx('text-muted fw-bold', rightColumnClassname)}>{rightColumn}</div>
  </div>
)
