import { BookingGuestAgreement, Reservation, ReservationRefund, ResortDetails } from '@models/reservation'
import { commonObjectGet } from '@api/basic-requests'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { RootState } from '@store/index'

export const getReservations = createAsyncThunk<Reservation[], void, { state: RootState }>(
  'reservations/getReservations',
  async (_, { getState }) => {
    const url = getState().clientState.details.urls.reservations

    return await commonObjectGet<Reservation[]>(url)
  },
)

export const getResortDetails = createAsyncThunk<ResortDetails, [string, string, string]>(
  'reservations/getResortDetails',
  async ([url, date_from, date_to]) =>
    await commonObjectGet<ResortDetails>(url, {
      params: { date_from, date_to },
    }),
)

export const getReservationRefundDetails = createAsyncThunk<ReservationRefund, string>(
  'reservations/getRefundDetails',
  async token => await commonObjectGet<ReservationRefund>(`/api/reservation/reservation/${token}/refund/`),
)

export const getReservationBookingGuestAgreement = createAsyncThunk<BookingGuestAgreement, string>(
  'reservations/getReservationBookingGuestAgreement',
  async token =>
    await commonObjectGet<BookingGuestAgreement>(`/api/reservation/reservation/booking-guest-agreement/${token}/`),
)
