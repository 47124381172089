import * as React from 'react'

export const ReservationDataCompletionGuestsHeader = (): JSX.Element => (
  <div className="text-darker-gray">
    <div className="d-flex justify-content-between align-items-center">
      <div className="font-size-xxl">
        <strong>Uzupełnij dane Gości</strong>
      </div>
    </div>
    <div>
      Pamiętaj, uzupełnienie danych Gości teraz <br />
      może przyspieszyć Wasze meldowanie <br />
      <strong>o nawet 25 minut</strong>!
    </div>
  </div>
)
