import * as React from 'react'
import * as clsx from 'clsx'
import { formatPrice } from '@helpers/price-helper'
import { CashbackVoucher } from '@models/client'
import { CashbackVoucherExpandableBaseHeader } from '@modules/special-products/cashback-vouchers/expandable-row-header/cashback-voucher-expandable-base-header'
import Decimal from 'decimal.js'

interface Props {
  voucher: CashbackVoucher
  withStatus?: boolean
}

export const CashbackVoucherUsedExpandableHeader = ({ voucher, withStatus }: Props): JSX.Element => {
  const usedAmount = new Decimal(voucher.promotion_amount).minus(voucher.voucher?.available_amount ?? 0).toString()

  const VoucherAmount = ({ voucher, className }: { voucher: CashbackVoucher; className: string }) => (
    <span className={clsx('font-size-sm align-items-end text-nowrap', className)}>
      Wykorzystane środki: <strong className="ms-3 me-1">{formatPrice(usedAmount)} </strong> z{' '}
      {formatPrice(voucher.promotion_amount)}
    </span>
  )

  const progressValue = React.useMemo(
    () => new Decimal(usedAmount).dividedBy(new Decimal(voucher.promotion_amount)).times(100).toNumber(),
    [voucher],
  )

  return (
    <CashbackVoucherExpandableBaseHeader
      progressValue={progressValue}
      voucher={voucher}
      progressColor="muted"
      withStatus={withStatus}
      voucherAmount={VoucherAmount}
    />
  )
}
