import * as React from 'react'
import { StatusVariant } from '@modules/special-products/special-product-status'
import { DateFormats, formatDate } from '@helpers/date-helper'
import { SpecialProductRowStatus } from '@modules/special-products/special-product-row-status'
import { Subscription } from '@models/client'

interface Props {
  subscription: Subscription
  isExpanded: boolean
}

export const SubscriptionExpandableRowAside = ({ isExpanded, subscription }: Props) => (
  <SpecialProductRowStatus
    productStatus={StatusVariant[subscription.status]}
    productStatusText={subscription.status_display}
    title="Status pakietu:"
  >
    {isExpanded && (
      <div className="mt-3 ">
        <span className="font-size-xs d-block">Kupiony</span>
        <span className="fw-medium font-size-xs">
          {formatDate(subscription.created, DateFormats.MONTH_WITH_FULL_WORD_MONTH_WITH_YEAR)}
        </span>
      </div>
    )}
  </SpecialProductRowStatus>
)
