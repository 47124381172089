import * as React from 'react'
import { AppTopbar } from '@components/topbar'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { NavigationPath } from '@models/routes'
import { ContentLoader } from '@components/loaders/content-loader'
import { useSelector } from 'react-redux'
import { selectAppData } from '@store/selectors/app-data-selectors'

interface Props {
  children: React.ReactNode
  withLogo?: boolean
}

export const AccountSetupLayout = ({ children, withLogo = true }: Props): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState(true)

  const appData = useSelector(selectAppData)

  React.useEffect(() => {
    if (appData) setIsLoading(false)
  }, [appData])

  const { push } = useHistory()

  const handleMoveToMainPage = () => push(NavigationPath.Root)

  return (
    <ContentLoader isLoading={isLoading} className="content-loader--full-screen">
      <div className="authorization-layout__container">
        <AppTopbar>
          <a target="_blank" href="https://holidaypark.pl/lokalizacje">
            Lokalizacje
          </a>
          <a target="_blank" href="https://holidaypark.pl/pakiety">
            Promocje
          </a>
          <a target="_blank" href="https://holidaypark.pl">
            Oferta
          </a>
          <a target="_blank" href="https://holidaypark.pl/cennik">
            Cennik
          </a>
          <a target="_blank" href="https://holidaypark.pl/kontakt">
            Kontakt
          </a>
          <Button variant="outline-dark" onClick={handleMoveToMainPage}>
            Konto Gościa
          </Button>
          <a href={appData.urls.other.reservation_url}>
            <Button variant="danger">Rezerwuj</Button>
          </a>
        </AppTopbar>
        <div className="container d-flex align-items-center justify-content-center flex-column mt-3">
          {withLogo && (
            <img
              src={require('@assets/images/login-logo.svg')}
              alt="Holiday park logo"
              className="mt-sm-4 mt-3"
              style={{ maxHeight: '200px' }}
            />
          )}
          {children}
        </div>
      </div>
    </ContentLoader>
  )
}
