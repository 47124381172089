type PaymentStatus = 'success' | 'failure' | 'initial'

export interface PaymentRequestPayload {
  error_url: string
  id: number
  status: PaymentStatus
  success_url: string
  token: string
}

export const usePaymentRedirect = () => {
  const handlePaymentRequestChange = (payload: PaymentRequestPayload) => {
    window.location.href = payload.status === 'success' ? payload.success_url : payload.error_url
  }

  return {
    handlePaymentRequestChange,
  }
}
