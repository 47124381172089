import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  image: string
  title: JSX.Element
  upperAction: JSX.Element
  bottomAction: JSX.Element
  onUpperAction: () => void
}

export const RemoveItemModal = ({ image, title, upperAction, bottomAction, onUpperAction }: Props): JSX.Element => (
  <div className="d-flex align-items-center flex-column gap-2 pb-4">
    <span className="text-dark font-size-xxl text-center px-3">{title}</span>
    <img src={image} alt="gastro voucher" className="my-2" />
    <button className="btn btn-outline-secondary mt-3 px-5 py-3" onClick={onUpperAction}>
      {upperAction}
    </button>
    <IconWithText text={bottomAction} iconClassName="uil-trash-alt font-size-xxl me-2" className="text-danger mt-3" />
  </div>
)
