import * as React from 'react'
import { SubscriptionContractCode } from '@models/client'
import { SubscriptionContractCodesListRow } from '@modules/special-products/subscription-contracts/codes/subscription-contract-codes-list-row'

interface Props {
  codes: SubscriptionContractCode[]
}

export const SubscriptionContractsDetailsCodesList = ({ codes }: Props): JSX.Element => (
  <div className="mt-5">
    <div className="d-flex px-3 font-size-sm text-light-gray pb-1">
      <span className="col-sm-3">Status kodu:</span>
      <span className="col-sm-4">Kod promocyjny:</span>
      <span className="col-sm-3">Do wykorzystania:</span>
      <span className="col-sm-2">Ważny do:</span>
    </div>
    <div className="bg-light-blue rounded border px-3 d-flex flex-column">
      {codes.map((code: SubscriptionContractCode, index) => (
        <SubscriptionContractCodesListRow key={code.id} code={code} borderless={index + 1 === codes.length} />
      ))}
    </div>
  </div>
)
