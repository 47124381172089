import * as React from 'react'
import { CloseReservationsListRow } from '@modules/reservations/close/row'
import { Reservation } from '@models/reservation'

interface Props {
  reservations: Reservation[]
}

export const CloseReservationsList = ({ reservations }: Props): JSX.Element => (
  <div className="d-flex flex-column">
    {reservations.map(reservation => (
      <CloseReservationsListRow key={reservation.id} reservation={reservation} />
    ))}
  </div>
)
