import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import { IconWithText } from '@components/icon-with-text'
import { useSelector } from 'react-redux'
import { RootState } from '@store/index'
import { useHistory } from 'react-router'
import { NavigationPath } from '@models/routes'
import { ModalFooter } from '@components/modals/modal-footer'
import { getState } from '@store/index'
import axios from 'axios'
import { useApiRequest } from '@hooks/use-api-request'

interface Props {
  isMobile?: boolean
  handleClose: () => void
}

export const ClientWalletReturn = ({ handleClose }: Props): JSX.Element => {
  const account_number = useSelector((state: RootState) => state.clientState.details.profile.account_number)
  const { push } = useHistory()

  const url = getState().clientState.details.urls.wallet_return

  const { isLoading, action: handleWithdraw } = useApiRequest(async () => {
    await axios.post(url)
    push(NavigationPath.ReturnSuccess)
  })

  return (
    <>
      <ModalBody className="modal-scrollable text-muted mt-3">
        <p>Numer konta, na które ma nastąpić zwrot:</p>
        <div className="wallet__grey-box p-3">{account_number}</div>
        <IconWithText
          className="align-items-start font-size-xs mb-1 mt-2 w-75"
          iconClassName="uil-info-circle text-primary me-3 font-size-icon-18"
          text={
            <span>
              Aby zlecić zwrot na inne konto zamknij to okno i zmień numer konta w sekcji{' '}
              <strong>Numer konta do zwrotu.</strong>
            </span>
          }
        />
        <IconWithText
          className="align-items-start font-size-xs mb-3 mt-1 w-75"
          iconClassName="uil-info-circle text-primary me-3 font-size-icon-18"
          text={
            <span>
              Zwrot trafi do realizacji w ciągu <strong>3 dni roboczych.</strong>
            </span>
          }
        />
      </ModalBody>
      <ModalFooter
        isLoading={isLoading}
        withCancelButton
        onSubmit={handleWithdraw}
        onClose={handleClose}
        submitLabel="Zlecam zwrot"
      />
    </>
  )
}
