import * as React from 'react'
import { Alert } from '@components/alert'
import { useCallCenterData } from '@hooks/use-call-center-data'

export const SkipServiceChargeAlert = (): JSX.Element => {
  const { phoneNumber } = useCallCenterData()

  return (
    <Alert variant="info" className="mt-3">
      <p className="text-muted mb-0">
        Przyjęliśmy sprzeciw dotyczący opłaty eksploatacyjnej. Wszystkie produkty i rezerwacje zakupione przed
        01.01.2023 mają wyłączoną opłatę eksploatacyjną. Opłaty za zużycie mediów dla tych produktów oraz powiązanych z
        nimi rezerwacjami będą rozliczane według regulaminu z dnia 01.09.2022. <br />
        <a className="text-decoration-none text-muted fw-semibold" href={`tel:${phoneNumber}`}>
          W razie pytań skontaktuj się z Biurem Obsługi Klienta.
        </a>
      </p>
    </Alert>
  )
}
