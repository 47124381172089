import { SubscriptionContractCode } from '@models/client'
import * as React from 'react'
import { SubscriptionContractCodesListRow } from '@modules/special-products/subscription-contracts/codes/subscription-contract-codes-list-row'

interface Props {
  codes: SubscriptionContractCode[]
}

export const SubscriptionContractDetailsCodesList = ({ codes }: Props): JSX.Element => (
  <div className="my-4">
    <p className="font-size-xxl text-secondary text-center fw-bold">Kody promocyjne:</p>
    <div className="bg-light-blue border rounded">
      {codes.map((code: SubscriptionContractCode, index) => (
        <SubscriptionContractCodesListRow key={code.id} code={code} borderless={index + 1 === codes.length} />
      ))}
    </div>
  </div>
)
