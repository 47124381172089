import * as React from 'react'
import { SpecialProductCard } from '@modules/special-products/special-product-card'
import { CashbackVoucher } from '@models/client'
import { SingleCashbackVoucher } from '@modules/special-products/cashback-vouchers/single-cashback-voucher'
import { CashbackVoucherStatusesToShow } from '@modules/special-products/cashback-vouchers/consts'

interface Props {
  cashbackVouchers: CashbackVoucher[]
}

export const CashbackVouchers = ({ cashbackVouchers }: Props): JSX.Element | null => {
  const groupedVouchers = getGroupedVouchers(cashbackVouchers)

  const sortedVouchers = React.useMemo(
    () => [
      ...groupedVouchers.generate,
      ...groupedVouchers.active_assign,
      ...groupedVouchers.active,
      ...groupedVouchers.used,
    ],
    [groupedVouchers],
  )

  if (!sortedVouchers.length) return null

  return (
    <SpecialProductCard title="Twoje vouchery rePLAY!">
      {sortedVouchers.map((voucher: CashbackVoucher) => (
        <SingleCashbackVoucher key={voucher.id} voucher={voucher} />
      ))}
    </SpecialProductCard>
  )
}

interface GroupedVouchers {
  active: CashbackVoucher[]
  active_assign: CashbackVoucher[]
  generate: CashbackVoucher[]
  used: CashbackVoucher[]
}

const getGroupedVouchers = (cashbackVouchers: CashbackVoucher[]): GroupedVouchers =>
  cashbackVouchers.reduce(
    (acc, voucher: CashbackVoucher) =>
      CashbackVoucherStatusesToShow.includes(voucher.status)
        ? { ...acc, [voucher.status]: [...acc[voucher.status], voucher] }
        : acc,
    { active_assign: [], active: [], generate: [], used: [] },
  )
