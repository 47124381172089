import * as React from 'react'
import { ClientProfileGuests } from '@modules/user/guests'
import { useSelector } from 'react-redux'
import { ClientDetails, FlatClientDetails } from '@models/client'
import { UserNotificationsSettings } from './notifications'
import { PersonalData } from '@modules/user/personal-data'
import * as R from 'ramda'
import { Country, Language } from '@models/app-data'
import { MobileActions } from '@components/mobile-actions'
import { useDeviceSize } from '@hooks/use-device-size'
import { InvoiceData } from '@modules/user/invoice'
import { FormLogicProvider } from './form-logic-provider'
import { UserRules } from '@modules/user/gdpr-data/user-rules'
import { selectClientDetails } from '@store/selectors/client-selectors'
import { selectAppData } from '@store/selectors/app-data-selectors'
import { ContentLoader } from '@components/loaders/content-loader'

type OmitField = 'language' | 'country' | 'invoice_country'

export interface ClientDetailsFormInputs extends Omit<FlatClientDetails, OmitField> {
  language: Language
  country: Country
}

export type ClientDetailsFormInputKey = keyof ClientDetailsFormInputs

const parseClientDetails = (
  clientDetails: ClientDetails,
  countries: Country[],
  languages: Language[],
): ClientDetailsFormInputs => ({
  ...R.pick<ClientDetailsFormInputs, keyof ClientDetailsFormInputs>(
    [
      'city',
      'first_name',
      'last_name',
      'notifications_arrival',
      'notifications_news',
      'notifications_promotion',
      'phone',
      'postcode',
      'street',
    ],
    {
      ...clientDetails,
      ...clientDetails.profile,
    },
  ),

  country: countries.find(country => country.value === clientDetails.profile.country),
  language: languages.find(language => language.value === clientDetails.language),
})

const personalDataScope: ClientDetailsFormInputKey[] = [
  'first_name',
  'last_name',
  'phone',
  'street',
  'postcode',
  'city',
  'language',
  'country',
]

const notificationsSettingsScope: ClientDetailsFormInputKey[] = [
  'notifications_news',
  'notifications_promotion',
  'notifications_arrival',
]

export const UserView = (): JSX.Element => {
  const { isDesktop } = useDeviceSize()

  const { countries, languages } = useSelector(selectAppData)
  const clientDetails = useSelector(selectClientDetails)

  const formData = React.useMemo(
    () => parseClientDetails(clientDetails, countries, languages),
    [clientDetails, countries, languages],
  )

  return (
    <ContentLoader>
      <div className="card mt-3 p-0 p-xl-4 border-0">
        <FormLogicProvider formData={formData} scope={personalDataScope}>
          <PersonalData />
        </FormLogicProvider>
        <hr className="my-5 mx-xl-n4 section-divider" />
        <InvoiceData clientProfile={clientDetails.profile} />
        <hr className="my-5 mx-xl-n4 section-divider" />
        <ClientProfileGuests />
        <hr className="my-5 mx-xl-n4 section-divider" />
        <FormLogicProvider formData={formData} scope={notificationsSettingsScope}>
          <UserNotificationsSettings />
        </FormLogicProvider>
        <hr className="my-5 mx-xl-n4 section-divider" />
        <UserRules clientDetails={clientDetails} />
        {!isDesktop && <MobileActions />}
      </div>
    </ContentLoader>
  )
}
