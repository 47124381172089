import * as React from 'react'
import { updateReservationCheckInData } from '@api/reservation'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { SectionTitle } from '@components/section-title'
import { useFormRequest } from '@hooks/use-api-request'
import { setReservationDetails } from '@store/slices/reservations-slice'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from 'rooks'
import { LabeledRow } from '@components/labeled-row'

interface FormInputs {
  id_card: string
}

export const CheckInIdCardForm = (): JSX.Element => {
  const dispatch = useDispatch()
  const reservationDetails = useSelector(selectReservationDetails)

  const methods = useForm<FormInputs>({
    defaultValues: { id_card: '' },
  })

  const idCard = useWatch({ control: methods.control, name: 'id_card' })

  const isSucceed = !!idCard && !!reservationDetails && reservationDetails.check_in_data.id_card === idCard

  const { action: savePersonalCardId, isLoading } = useFormRequest(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (!reservationDetails) return
      dispatch(
        setReservationDetails(
          await updateReservationCheckInData(reservationDetails?.urls.service_room_data, {
            id_card: event.target.value,
          }),
        ),
      )
    },
    methods.setError,
  )

  const debouncedSave = useDebounce(savePersonalCardId, 1500)
  const memoizedSave = React.useCallback(debouncedSave, [])

  React.useEffect(() => {
    if (!reservationDetails) return

    methods.reset({ id_card: reservationDetails.check_in_data.id_card })
  }, [reservationDetails?.check_in_data.id_card])

  return (
    <FormProvider {...methods}>
      <SectionTitle
        title="Twoje dane osobowe"
        subtitle="Wpisz dane swojego dokumentu tożsamości"
        className="text-center text-md-start"
      />
      <LabeledRow
        label="Seria i numer dokumentu*"
        className="align-items-center"
        hideLabelOnWrap={true}
        container="container-xl"
      >
        <CustomFormGroup
          label="Seria i numer dokumentu*"
          inputName="id_card"
          formLabelProps={{ className: 'd-xl-none' }}
          formControlProps={{ type: 'text', onChange: memoizedSave }}
          formGroupProps={{ className: 'mt-0 col-xl-7' }}
          isSucceed={isSucceed}
          isLoading={isLoading}
        />
      </LabeledRow>
    </FormProvider>
  )
}
