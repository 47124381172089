import * as React from 'react'
import { ContentLoader } from '@components/loaders/content-loader'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectClientDetails } from '@store/selectors/client-selectors'
import { SpecialProductDetailsHeader } from '@modules/special-products/special-product-details-header'
import { SubscriptionContractDetailsBaseInfo } from '@modules/special-products/subscription-contracts/details/subscription-contract-details-base-info'
import { SubscriptionContractPaymentButton } from '@modules/special-products/subscription-contracts/subscription-contract-payment-button'
import { SubscriptionContractActions } from '@modules/special-products/subscription-contracts/subscription-contract-actions'
import { SubscriptionContractExpandableRowPaymentsBanner } from '@modules/special-products/subscription-contracts/expandable/subscription-contract-expandable-row-payments-banner'
import { SubscriptionContract, SubscriptionContractDetails } from '@models/client'
import { useApiRequest } from '@hooks/use-api-request'
import { commonObjectGet } from '@api/basic-requests'
import { SubscriptionContractDetailsCodesList } from '@modules/special-products/subscription-contracts/codes/subscription-contract-details-codes-list'
import { SubscriptionContractStatusesWithDetailsAvailable } from '@modules/special-products/subscription-contracts/consts'
import { useHistory } from 'react-router'
import { NavigationPath } from '@models/routes'

interface Params {
  id: string
}

export const SubscriptionContractDetailsView = (): JSX.Element => {
  const { id } = useParams<Params>()
  const { push } = useHistory()

  const [subscriptionContractDetails, setSubscriptionContractDetails] =
    React.useState<SubscriptionContractDetails | null>(null)

  const { subscription_contracts } = useSelector(selectClientDetails)

  const subscriptionContract = subscription_contracts.find(
    subscriptionContract => subscriptionContract.id === parseInt(id, 10),
  )

  const { action: fetchDetails, isLoading } = useApiRequest(async (subscriptionContract: SubscriptionContract) => {
    setSubscriptionContractDetails(await commonObjectGet(subscriptionContract.urls.details))
  })

  React.useEffect(() => {
    if (!subscriptionContract) return

    if (!SubscriptionContractStatusesWithDetailsAvailable.includes(subscriptionContract.status)) {
      push(NavigationPath.SpecialProducts)
      return
    }

    fetchDetails(subscriptionContract)
  }, [subscriptionContract])

  return (
    <ContentLoader isLoading={isLoading}>
      {subscriptionContractDetails && (
        <>
          <SpecialProductDetailsHeader
            title="Twoje subskrupcje"
            productName="Subskrypcja"
            productNumber={subscriptionContractDetails.number}
          />

          <SubscriptionContractDetailsBaseInfo subscriptionContract={subscriptionContractDetails} />

          {subscriptionContractDetails?.status === 'initial' ? (
            <SubscriptionContractPaymentButton
              subscriptionContract={subscriptionContractDetails}
              className="w-100 mt-5"
            />
          ) : (
            <>
              <SubscriptionContractExpandableRowPaymentsBanner
                subscriptionContractDetails={subscriptionContractDetails}
              />

              <SubscriptionContractDetailsCodesList codes={subscriptionContractDetails.codes} />
              <SubscriptionContractActions
                subscriptionContractDetails={subscriptionContractDetails}
                onSubscriptionContractChange={setSubscriptionContractDetails}
              />
            </>
          )}
        </>
      )}
    </ContentLoader>
  )
}
