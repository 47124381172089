import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FeedingTypes } from '@models/reservation'
import { ImprovementModalWrapper } from '@modules/reservations/improvements/improvement-modal-wrapper'
import {
  AvailableFeedingSelections,
  FeedingDetailsModalSelection,
} from '@modules/reservations/improvements/kinds/feedings/feeding-details-modal-selection'
import { getFeedingGuests, getSelectedFeedingKey } from '@helpers/improvement-helper'
import { ImprovementSummaryModalPriceRow } from '@modules/reservations/improvements/summary/improvement-summary-modal-price-row'
import { FeedingDetailsModalContentTitle } from '@modules/reservations/improvements/kinds/feedings/feeding-details-modal-content-title'
import { ImprovementSummaryModalRow } from '@modules/reservations/improvements/summary/improvement-summary-modal-row'
import { useApiRequest } from '@hooks/use-api-request'
import { addFeeding, calculateFeeding, FeedingCalculations, removeFeeding } from '@api/improvements'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import { setReservationDetails } from '@store/slices/reservations-slice'

export interface FeedingSelection {
  title: string
  kind: FeedingTypes
}

interface Props {
  handleClose: () => void
}

export const FeedingDetailsModal = ({ handleClose }: Props): JSX.Element => {
  const [selectedFeedingType, setSelectedFeedingType] = React.useState<FeedingTypes>('none')
  const [feedingCalculations, setFeedingCalculations] = React.useState<FeedingCalculations[]>([])

  const dispatch = useDispatch()

  const reservationDetails = useSelector(selectReservationDetails)
  const addedGuests = getFeedingGuests(reservationDetails)

  const { action: handleSubmit, isLoading } = useApiRequest(async () => {
    if (!reservationDetails?.urls?.feeding) return

    const updatedReservationDetails = await addFeeding(reservationDetails.urls.feeding, {
      feeding_type: selectedFeedingType,
    })

    dispatch(setReservationDetails(updatedReservationDetails))
    handleClose()
  })

  const { action: getFeedingsCalculations, isLoading: isPriceCalculating } = useApiRequest(async () => {
    if (!reservationDetails?.urls) return
    const data = await calculateFeeding(reservationDetails.urls.feeding_calculator)
    setFeedingCalculations(data)
  })

  const { isLoading: isDeletingInProgress, action: deleteFeeding } = useApiRequest(async () => {
    if (reservationDetails?.urls.feeding) {
      const updatedReservationDetails = await removeFeeding(reservationDetails?.urls.feeding, selectedFeedingType)
      dispatch(setReservationDetails(updatedReservationDetails))
      handleClose()
    }
  })

  React.useEffect(() => {
    getFeedingsCalculations()
  }, [reservationDetails?.urls?.feeding_calculator])

  React.useEffect(() => {
    if (!reservationDetails?.prices) return
    setSelectedFeedingType(getSelectedFeedingKey(reservationDetails?.prices.feeding.items))
  }, [reservationDetails?.prices])

  const feedingPrice = React.useCallback(
    (feedingType: FeedingTypes | null) =>
      feedingCalculations.find(calculations => calculations.feeding_type === feedingType)?.total_price_brutto || 0,
    [feedingCalculations],
  )

  const guestAmount = React.useMemo(
    () => (Object.keys(addedGuests).length || reservationDetails?.guests.length) ?? 0,
    [addedGuests],
  )

  const feedingSelectionTitle = React.useMemo(
    () => AvailableFeedingSelections.find(selection => selection.kind === selectedFeedingType)?.title,
    [selectedFeedingType],
  )

  return (
    <ImprovementModalWrapper
      image={require('@assets/images/feeding.webp')}
      onSubmit={handleSubmit}
      onClose={handleClose}
      isLoading={isLoading || isDeletingInProgress}
      contentTitle={<FeedingDetailsModalContentTitle guestAmount={guestAmount} onModalClose={handleClose} />}
      kind="feeding"
      buttonDisabled={selectedFeedingType === 'none'}
      isInitializing={isPriceCalculating}
    >
      <FeedingDetailsModalSelection
        selectedFeedingType={selectedFeedingType}
        onFeedingSelectionChange={setSelectedFeedingType}
        feedingCalculations={feedingCalculations}
        reservationDetails={reservationDetails}
        guestAmount={guestAmount}
      />
      {feedingSelectionTitle && (
        <ImprovementSummaryModalRow
          isDeleteEnabled={false}
          text={`Wyżywienie (${feedingSelectionTitle})`}
          price={feedingPrice(selectedFeedingType)}
          onDelete={deleteFeeding}
          isPriceCalculating={isPriceCalculating}
        />
      )}
      <ImprovementSummaryModalPriceRow
        text="Koszt wyżywienia łącznie:"
        price={feedingPrice(selectedFeedingType)}
        isCalculating={isPriceCalculating}
      />
    </ImprovementModalWrapper>
  )
}
