import * as React from 'react'
import * as clsx from 'clsx'
import { ModalBody } from 'react-bootstrap'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { CustomReactSelect, ReactSelectOption } from '@components/custom-react-select'
import { ContentLoader } from '@components/loaders/content-loader'
import { PurchaseHistoryTable } from '@modules/reservations/purchase-history-modal/purchase-history-table'
import { formatPrice } from '@helpers/price-helper'
import { ModalFooter } from '@components/modals/modal-footer'
import { GastroCardAssignment, GastroCardDetails } from '@models/client'
import { useDeviceSize } from '@hooks/use-device-size'
import { getPurchaseHistoryFilterSelectStyle } from '@modules/reservations/purchase-history-modal/purchase-history-modal'
import { sumDecimalArray } from '@helpers/utils'
import Decimal from 'decimal.js'
import { useDidUpdate } from 'rooks'
import { PurchaseItem } from '@models/reservation'

const defaultFilter = { value: 'all', label: 'Wszystkie rezerwacje' }

interface FormInputs {
  reservation: ReactSelectOption
}

interface Props {
  gastroCard: GastroCardDetails
  handleClose: () => void
  titleMobile: string
}

export const GastroVoucherPurchaseHistoryModal = ({ gastroCard, titleMobile, handleClose }: Props): JSX.Element => {
  const [gastroCardItems, setGastroCardItems] = React.useState<PurchaseItem[]>(gastroCard.items)
  const { isMobile } = useDeviceSize()
  const methods = useForm<FormInputs>({
    defaultValues: {
      reservation: defaultFilter,
    },
  })

  const reservationOptions = [
    defaultFilter,
    ...gastroCard.assignments.map((assignment: GastroCardAssignment) => ({
      value: assignment.booking,
      label: `Rezerwacja nr. ${assignment.booking}`,
    })),
  ]

  const reservationFilter = useWatch({ control: methods.control, name: 'reservation' })

  useDidUpdate(() => {
    if (reservationFilter.value === 'all') {
      setGastroCardItems(gastroCard.items)
      return
    }

    setGastroCardItems(
      gastroCard.items.filter((item: PurchaseItem) => item.reservation_number === reservationFilter.value),
    )
  }, [reservationFilter])

  const totalAmount = sumDecimalArray(gastroCardItems.map(item => new Decimal(item.price_brutto))).toString()

  return (
    <>
      <ModalBody className="modal-scrollable">
        {isMobile && (
          <h4 className="text-navy mb-3">
            {titleMobile}
            <span className="fw-light d-block">(voucher {gastroCard.code})</span>
          </h4>
        )}
        <div className="d-flex align-items-center">
          <FormProvider {...methods}>
            <CustomReactSelect
              inputName="reservation"
              options={reservationOptions}
              isSearchable={false}
              customStyles={getPurchaseHistoryFilterSelectStyle(isMobile)}
            />
          </FormProvider>
        </div>
        <ContentLoader isLoading={false}>
          <PurchaseHistoryTable items={gastroCardItems} noDataText="Brak historii dla wybranej rezerwacji" />
        </ContentLoader>
        <div
          className={clsx('d-flex justify-content-end align-items-center flex-nowrap text-navy pt-3 fw-bold', {
            'font-size-xl justify-content-between pt-0': isMobile,
          })}
        >
          <p className="font-size-lg">Zakupy łącznie:</p>
          <p className="ps-5 font-size-xl pe-4">{formatPrice(totalAmount)}</p>
        </div>
      </ModalBody>
      <ModalFooter withCancelButton withSubmit={false} onClose={handleClose} cancelLabel="Zamknij" />
    </>
  )
}
