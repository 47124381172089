import * as React from 'react'
import { ModalFooter } from '@components/modals/modal-footer'
import { ModalBody } from 'react-bootstrap'
import { FormProvider, useForm } from 'react-hook-form'
import { PaymentTarget, ReservationPaymentTargetForm } from '@modules/reservations/pending/payment/modal/payment-target'
import { TrapezeButtonVariant } from '@components/controls/trapeze-button'
import { PaymentSummary, ReservationDetails } from '@models/reservation'
import { usePaymentModal } from '@hooks/use-payment-modal'
import Decimal from 'decimal.js'
import { getRestToPayDeposit, getRestToPayInstallment, shouldBePaidAllAtOnce } from '@helpers/reservation'

const defaultPaymentTarget = (paymentSummary: PaymentSummary) => {
  const isPaid = value => new Decimal(value).lte(0)
  const isDepositPaid = isPaid(getRestToPayDeposit(paymentSummary))
  const isInstallmentPaid = isPaid(getRestToPayInstallment(paymentSummary))

  if ((!isDepositPaid && !isInstallmentPaid) || shouldBePaidAllAtOnce(paymentSummary)) {
    return PaymentTarget.stayAndDeposit
  }

  if (!isDepositPaid) {
    return PaymentTarget.deposit
  }

  if (!isInstallmentPaid) {
    return PaymentTarget.stay
  }
}

interface FormInputs {
  target: PaymentTarget
  amount: number
}

interface Props {
  handleClose: () => void
  reservationDetails: ReservationDetails
}

export const ReservationPaymentModal = ({ handleClose, reservationDetails }: Props): JSX.Element => {
  const methods = useForm<FormInputs>({
    defaultValues: {
      target: defaultPaymentTarget(reservationDetails.prices.payments_summary),
    },
  })

  const showPaymentModal = usePaymentModal({ paymentUrl: reservationDetails.urls.payment })

  const handlePayment = () => {
    showPaymentModal({ source: 'client-panel-app-reservation', ...methods.getValues() })
    handleClose()
  }

  return (
    <>
      <ModalBody>
        <div
          className="reservation-payment-modal__image mt-n3 mx-n3"
          style={{ backgroundImage: `url(${require('@assets/images/domek.jpg')})` }}
        />
        <FormProvider {...methods}>
          <ReservationPaymentTargetForm paymentSummary={reservationDetails.prices.payments_summary} />
        </FormProvider>
      </ModalBody>
      <ModalFooter
        withCancelButton
        onClose={handleClose}
        cancelLabel="Wstecz"
        cancelIcon="font-size-xl me-2 ms-4 uil-angle-left-b"
        onSubmit={handlePayment}
        saveButtonVariant={TrapezeButtonVariant.red}
        submitLabel="Przejdź do płatności"
      />
    </>
  )
}
