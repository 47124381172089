import * as React from 'react'
import * as R from 'ramda'
import { ReservationReceiptModalGuestRow } from './reservation-receipt-modal-guest-row'
import { ReservationReceiptModalBox } from '@modules/reservations/modals/reservation-receipt-modal-box'
import { BillItem, BillShopping } from '@models/bill'
import { formatDate } from '@helpers/date-helper'
import { ReservationReceiptModalRow } from '@modules/reservations/modals/reservation-receipt-modal-row'
import { Guest } from '@models/reservation'

interface GroupedShoppingList {
  [key: string]: BillShopping[]
}

interface Props {
  shopping: BillItem<BillShopping>
  guests: Guest[]
}

export const ReservationReceiptModalRoomPurchases = ({ shopping, guests }: Props): JSX.Element => {
  const shoppingByGuest: GroupedShoppingList = R.groupBy(R.prop('guest_id'))(shopping.items)

  const { itemsNotAssignedToGuest, ...itemsAssignedToGuest } = React.useMemo(
    () =>
      Object.entries(shoppingByGuest).reduce(
        (acc, [guestId, items]) => {
          const reservationGuest = guests.find(guest => guest.id === parseInt(guestId, 10))?.name
          if (reservationGuest) return { ...acc, ...{ [reservationGuest]: items } }
          return { ...acc, ...{ itemsNotAssignedToGuest: items } }
        },
        { itemsNotAssignedToGuest: [] },
      ),
    [guests, shoppingByGuest],
  )

  return (
    <ReservationReceiptModalBox title="Zakupy na pokój" total={shopping.total_price_brutto}>
      <>
        {Object.entries(itemsAssignedToGuest).map(([guestName, shoppingList]: [string, BillShopping[]]) => (
          <ReservationReceiptModalGuestRow guestName={guestName} shoppingList={shoppingList} key={guestName} />
        ))}

        {itemsNotAssignedToGuest.map((item: BillShopping) => (
          <ReservationReceiptModalRow
            key={item.id}
            quantity={item.quantity}
            titleColumn={item.name}
            dateColumn={formatDate(item.created)}
            priceColumn={item.price_brutto}
            indent
          />
        ))}
      </>
    </ReservationReceiptModalBox>
  )
}
