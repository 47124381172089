import { ReactSelectOption } from '@components/custom-react-select'
import { AvailableRuleKeyword } from '@models/app-data'
import { GastroKind } from '@models/client'

export enum ReservationStatus {
  initial = 'initial',
  confirmed = 'confirmed',
  unfinished = 'unfinished',
  canceled = 'unfinished_canceled',
  close = 'close',
}

export interface BookingGuestAgreement {
  phone: string
  email: string
  name: string
  rodo_email: string
  rodo_phone: string
  reservation_number: string
  id: number
  urls: BaseUrl
}

export enum FeedingType {
  DINNER_WITH_BREAKFAST = 'dinner_with_breakfast',
  DINNER = 'dinner',
  BREAKFAST = 'breakfast',
  NONE = 'none',
}

export interface ReservationUrl extends BaseUrl {
  online_checkin_start: string
}

export interface Reservation {
  accommodation_type_id: number
  accommodation_type_name: string
  arrival_time: string
  apartment_id: number
  apartment_name: string | null
  can_check_in: boolean
  can_check_out: boolean
  checked_in: boolean
  checked_in_online: boolean
  close_datetime: string | null
  checked_out_online: boolean
  date_from: string
  date_to: string
  departure_time: string
  id: number
  has_deposit_creditcard_preauth: boolean
  token: string
  reservation_number: string
  resort_id: number
  selected_apartment_name: string | null
  status: ReservationStatus
  status_display: string
  urls: ReservationUrl
}

export interface GastroCardSelectOption extends ReactSelectOption {
  available_amount: string
  start_amount: string
  code: string
  kind: GastroKind
}

export interface BaseUrl {
  details: string
}

export type ParkingKind = 'parking2' | 'parking_vip' | 'parking_large'

export enum ImprovementCode {
  laundry = 'Laundry',
  additionalCleaning = 'additional_cleaning',
  animals = 'animals',
  apartmentSelect = 'apartment_select',
  bathtub = 'bathtub',
  beautySet = 'beauty_set',
  bed = 'bed',
  bike = 'bike',
  chair = 'chair',
  cosmetics = 'cosmetics',
  earlyCheckIn13 = 'early_check_in_13',
  extendStay = 'extend_stay',
  extendStay12 = 'extend_stay_12',
  fridgeMagnet = 'fridge_magnet',
  fruits = 'fruits',
  gameConsole = 'game_console',
  ginger = 'ginger',
  grill = 'grill',
  houseWithGarden = 'house_with_garden',
  microwave = 'microwave',
  packageOption = 'package_option',
  packageVip = 'package_vip',
  packageVipWinter = 'package_vip_winter',
  parking = 'parking2',
  parkingVip = 'parking_vip',
  perfume = 'perfume',
  paidApartmentSelect = 'platny-wybor-lokalu',
  stayPromotion = 'stay_promotion',
  sweets = 'sweets',
  towel = 'towel',
  towelVip = 'towel_vip',
  washing = 'washing',
  washingCapsules = 'washingcapsules',
  wine = 'wine',
}

export type FeedingImprovementCode =
  | 'feeding__breakfast__adult'
  | 'feeding__breakfast__baby'
  | 'feeding__breakfast__children_13_17'
  | 'feeding__breakfast__children_3_4'
  | 'feeding__breakfast__children_5_12'
  | 'feeding__breakfast_with_dinner__adult'
  | 'feeding__breakfast_with_dinner__baby'
  | 'feeding__breakfast_with_dinner__children_13_17'
  | 'feeding__breakfast_with_dinner__children_3_4'
  | 'feeding__breakfast_with_dinner__children_5_12'
  | 'feeding__dinner__adult'
  | 'feeding__dinner__baby'
  | 'feeding__dinner__children_13_17'
  | 'feeding__dinner__children_3_4'
  | 'feeding__dinner__children_5_12'
  | 'feeding__dinner_with_breakfast__adult'
  | 'feeding__dinner_with_breakfast__baby'
  | 'feeding__dinner_with_breakfast__children_13_17'
  | 'feeding__dinner_with_breakfast__children_3_4'
  | 'feeding__dinner_with_breakfast__children_5_12'

export interface PriceItem<T> {
  items: T[]
  total_price_brutto: string
}

export interface FeedingImprovement extends Feeding {
  position: number
  can_remove: boolean
  can_update: boolean
}

export interface Feeding {
  id: number
  amount: number
  created: string
  name: string
  code: FeedingTypes
  price_brutto: string
  guest_id: number
}

export interface BasePaymentSummary {
  deposit: string
  installment: string
}

interface PaymentSummaryWithDate extends BasePaymentSummary {
  required_date: string
}

interface PaymentSummaryWithInstallmentDateAndDepositDate extends BasePaymentSummary {
  required_date_deposit: string | null
  required_date: string | null
}

export interface PaymentSummary {
  paid: BasePaymentSummary
  required: PaymentSummaryWithDate
  rest: PaymentSummaryWithInstallmentDateAndDepositDate
}

export interface Climatic {
  name: string
  price_brutto: string
  single_price_brutto: string
}

export interface Residence {
  discount: string
  discount_percentage: string
  total_price_brutto: string
  total_price_brutto_before_discount: string
}

export interface ExtraCharge {
  created: string
  id: number
  name: string
  price_brutto: string
}

export interface ReservationPrices {
  climatic: PriceItem<Climatic>
  deposit_amount: string
  deposit_charge: string
  extra_charges: PriceItem<ExtraCharge>
  feeding: PriceItem<Feeding>
  improvements: PriceItem<ReservationImprovement>
  payments_summary: PaymentSummary
  residence: Residence
  rest_to_pay_in_checkin: string
  service_charge_brutto: string
  single_service_price_brutto_from: string
  stay_charge: string
  stay_charge_without_climatic: string
  stay_charge_without_discount: string
  stay_charge_without_discount_without_climatic: string
  warranty_price_brutto: string
}

export interface ReservationDetailsUrls extends BaseUrl {
  bill: string
  cars: string
  feeding: string
  feeding_calculator: string
  gastro_card: string
  groups: string
  guests: string
  improvement_calculator: string
  improvements: string
  issue_order: string
  meters: string
  online_checkin: string
  online_checkin_start: string
  online_checkout: string
  payment: string
  rules: string
  service_room_data: string
  online_checkout_cashback_voucher: string
  update: string
  wallet: string
}

export type GuestWalletScopes = 'cafe' | 'bistro' | 'vending_machines' | 'shop' | 'reception' | 'feeding'

export type GuestType = 'adult' | 'child' | 'children_5_12' | 'children_3_4' | 'baby'

export interface GuestWalletLimits {
  limit: number | null
  scope: GuestWalletScopes
}

export interface ReservationWallet {
  available_amount: string
  id: number
  items: PurchaseItem[]
  items_total_brutto: string
  start_amount: string
}

export interface Guest {
  id: number
  name: string
  phone: string
  email: string
  group_id: number
  type: GuestType
  type_display: string
  wallet_limits: GuestWalletLimits[]
  urls: BaseUrl
  rodo_email: boolean
  rodo_phone: boolean
  birthday: string
  date_from: string
  date_to: string
}

export interface GuestGroup {
  id: number
  name: string
  urls: BaseUrl
}

export interface BookingCar {
  group_id: number
  guest_id: number
  id: number
  register_number: string
  kind: ParkingKind
  urls: BaseUrl
}

export interface BookingOnlineCheckinData {
  id_card: string
}

export interface BookingOnlineDocuments {
  keyword: string
  name: string
  url: string | null
}

export interface BookingWalletItem {
  created_at: string
  guest_name: string
  id: number
  name: string
  price_brutto: string
  quantity: string
  single_price_brutto: string
  source: string
  source_display: string
}

export interface BookingWallet {
  available_amount: string
  items: BookingWalletItem[]
  id: number
  start_amount: string
}

export type BookingClientWalletKind = 'cashback'

export interface BookingClientWallet {
  available_amount: string
  is_active: boolean
  name: string
  start_amount: string
  can_remove: boolean
  kind: BookingClientWalletKind
  code: string
  urls: BaseUrl
}

export interface FeedingOption {
  breakfast: boolean
  date: string
  dinner: boolean
  guest_id: number
  id: number
}

export interface Rule<T> {
  required: boolean
  accepted: boolean
  date: null | string
  keyword: T
}

interface ReservationMessage {
  content: string
}

export interface ReservationDetails extends Reservation {
  accommodation_type_id: number
  apartment_id: number
  apartment_name: string
  arrival_time: string
  can_check_in: boolean
  cars: BookingCar[]
  check_in_data: BookingOnlineCheckinData
  check_in_online_documents: BookingOnlineDocuments[]
  city: string
  country: string
  created_at: string
  date_from: string
  date_to: string
  departure_time: string
  declared_arrival_time: string | null
  email: string
  feeding_options: FeedingOption[]
  groups: GuestGroup[]
  guests: Guest[]
  id: number
  invoice: boolean
  invoice_city: string
  invoice_company: string
  invoice_country: string
  invoice_nip: string
  invoice_postcode: string
  invoice_street: string
  is_paid: boolean
  is_paid_with_deposit: boolean
  is_paid_with_deposit_with_climatic: boolean
  messages: ReservationMessage[]
  name: string
  phone: string
  postcode: string
  prices: ReservationPrices
  reservation_number: string
  resort_id: number
  rules: Rule<AvailableRuleKeyword>[]
  selected_apartment_id: number
  selected_apartment_name: string
  skip_service_charge: boolean
  status: ReservationStatus
  status_display: string
  street: string
  urls: ReservationDetailsUrls
  wallets: BookingClientWallet[]
  warranty: boolean
}

export interface ResortFeedingPrices {
  breakfast_price_brutto: string
  dinner_price_brutto: string
  price_brutto_hb: string
}

export interface ResortFeedings {
  adult: Partial<ResortFeedingPrices>
  child: Partial<ResortFeedingPrices>
  children_5_12: Partial<ResortFeedingPrices>
  children_3_4: Partial<ResortFeedingPrices>
  baby: Partial<ResortFeedingPrices>
}

export interface ResortDetails {
  id: number
  improvements: ResortImprovement[]
  feedings: ResortFeedings
}

export interface Improvement {
  code: ImprovementCode | FeedingTypes
  name: string
  description: string
  price_brutto: string
  group_id: number
  id: number
  is_paid_by_package_vip: boolean
  position: number | null
  can_remove: boolean
  can_update: boolean
}

export interface ReservationImprovement extends Improvement {
  amount: number
  is_paid_by_package_vip: boolean
  urls: BaseUrl
  created: string
}

export interface ResortImprovementLimits {
  [key: number]: number
}

export type ResortImprovementSaleChannel = 'reservation_process' | 'client_panel'

export interface ResortImprovement extends Improvement {
  available_in: number[]
  can_change_amount: boolean
  can_change_days: boolean
  can_add: boolean
  image: SizedImage
  excluded_apartments: number[]
  price_brutto_first_item: string | null
  price_type_2_display: string
  reservation_group_name: string
  limits: ResortImprovementLimits
  sales_channels: ResortImprovementSaleChannel[]
}

export interface SizedImage {
  xs: string
  sm: string
  md: string
}

export interface PlateInput {
  register_number: string
  kind: ParkingKind
  carId: number | null
  improvement: ReservationImprovement | null
}

export interface ParkingFormInputs {
  plates: PlateInput[]
}

export interface CalculatorItem {
  name: string
  price: string
}

export interface ReservationMetersState {
  heat: string
  heat_costs: null | string
  heat_costs_total: null | string
  heat_is_up_to_date: null | boolean
  heat_reading_date: null | string
  hot_water: string
  hot_water_costs: string
  hot_water_costs_total: null | string
  hot_water_is_up_to_date: null | boolean
  hot_water_reading_date: null | string
  power: string
  power_costs: string
  power_costs_total: null | string
  power_fake_meter: null | boolean
  power_is_up_to_date: null | boolean
  power_reading_date: null | string
  water: string
  water_costs: string
  water_costs_total: null | string
  water_fake_meter: null | boolean
  water_is_up_to_date: null | boolean
  water_reading_date: null | string
}

export interface PurchaseItem {
  booking_id: number
  reservation_number: string
  created_at: string
  guest_name: string
  id: number
  name: string
  price_brutto: string
  quantity: string
  single_price_brutto: string
  source: GuestWalletScopes
  source_display: string
}

export type DepositTransferStatus =
  | 'missing_client_data'
  | 'waiting_for_client_data'
  | 'client_data_complete'
  | 'transfer_in_package'
  | 'transfer_sent'
  | 'p24_initial'
  | 'p24_pending'
  | 'p24_completed'
  | 'p24_error'
  | 'p24_blocked'

export interface ReservationRefund {
  id: number
  deposit_return_account_number: string
  deposit_return_address: string
  deposit_return_name: string
  deposit_return_city: string
  deposit_return_postcode: string
  deposit_transfer_status: DepositTransferStatus
  urls: BaseUrl
}

export type FeedingTypes = 'breakfast' | 'dinner' | 'breakfast_with_dinner' | 'dinner_with_breakfast' | 'none'
