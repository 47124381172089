import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { FullScreenSignaturePad } from '@components/full-screen-signature-pad'
import { useSelector } from 'react-redux'
import { RootState } from '@store/index'
import { ClientDetails } from '@models/client'
import { useFormContext, useWatch } from 'react-hook-form'
import { useDeviceSize } from '@hooks/use-device-size'
import { useModal } from '@components/modals/use-modal'
import { CheckInGdprStepFormInputs } from '@modules/reservations/check-in/steps/gdpr/use-gdpr-step'

export const GdprStepSignature = () => {
  const [isVisible, setVisible] = React.useState(false)
  const { isDesktop } = useDeviceSize()

  const { setValue, control } = useFormContext<CheckInGdprStepFormInputs>()

  const handleSave = (dataUrl: string) => {
    setVisible(false)
    saveSignature(dataUrl)
  }

  const saveSignature = (dataUrl: string) => {
    setValue('signature', dataUrl)
  }

  const signature = useWatch({ control, name: 'signature' })

  const clientDetails = useSelector<RootState, ClientDetails>(state => state.clientState.details)

  const [showSignaturePadModal] = useModal('ModalSignaturePad', { onSignatureSave: saveSignature })

  const handleShowSignaturePad = () => {
    isDesktop ? showSignaturePadModal() : setVisible(true)
  }

  return (
    <div className="flex-column flex-xl-row mb-xl-3 row">
      <div className="col-xl-5">
        <div className="text-navy font-size-xxl fw-bold mb-3">Podpis gościa</div>
        <p className="text-muted font-size-sm">
          Składając podpis na ekranie swojego urządzenia podpisujesz jednorazowo wszystkie ww dokumenty.
        </p>
        <p className="text-muted font-size-sm">
          Podpisane dokumenty wraz z potwierdzeniem wyślemy do Ciebie na Twój adres mailowy:{' '}
          <strong>{clientDetails.email}</strong>
        </p>
      </div>

      <div className="col-xl-7 mt-xl-4">
        <div
          className="card bg-light-blue overflow-hidden d-flex align-items-center justify-content-center my-2"
          style={{ height: 110 }}
        >
          {signature && <img src={signature} style={{ height: 110, width: 300 }} />}
        </div>
        <IconWithText
          onClick={handleShowSignaturePad}
          text={signature ? 'Zmień podpis' : 'Otwórz okno podpisu'}
          iconClassName="uil-edit-alt me-2 font-size-icon-18"
          className="text-muted fw-bold my-4 justify-content-end"
        />
      </div>
      <FullScreenSignaturePad onSave={handleSave} isVisible={isVisible} />
    </div>
  )
}
