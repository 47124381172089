import * as React from 'react'
import * as clsx from 'clsx'
import { formatPrice } from '@helpers/price-helper'
import { ProgressWithIcon } from '@components/progress-with-icon'
import { GastroCard } from '@models/client'
import Decimal from 'decimal.js'
import { SpecialProductRowStatus } from '@modules/special-products/special-product-row-status'
import { StatusVariant } from '@modules/special-products/special-product-status'
import { GastroVoucherPaymentButton } from '@modules/special-products/gastro-vouchers/gastro-voucher-payment-button'
import { useGastroProductOverview } from '@modules/special-products/gastro-vouchers/details/gastro-voucher-detail-product-overview'
import { SpecialProductVoucherCode } from '@modules/special-products/special-product-voucher-code'

interface Props {
  voucher: GastroCard
  withStatus?: boolean
}

export const GastroVoucherExpandableHeader = ({ voucher, withStatus = true }: Props): JSX.Element => {
  const { showOverview } = useGastroProductOverview(voucher)

  const progress = React.useMemo(
    () => new Decimal(voucher.available_amount).dividedBy(new Decimal(voucher.start_amount)).times(100).toNumber(),
    [voucher],
  )

  return (
    <div className="pt-3 pb-4 d-flex px-3 px-sm-0 flex-column flex-sm-row justify-content-between align-items-sm-center me-3">
      {withStatus && (
        <SpecialProductRowStatus
          wrapperClassName="d-block d-md-none"
          title="Status vouchera:"
          productStatus={StatusVariant[voucher.status]}
          productStatusText={voucher.status_display}
        />
      )}
      {voucher.status === 'initial' ? (
        <div className="d-sm-flex w-100 align-items-sm-center justify-content-between">
          <div>
            <div className="d-flex">
              <i className="uil-tag-alt me-2 text-muted font-size-lg" />
              <SpecialProductVoucherCode text="Dokończ zakup:" code={voucher.code} />
            </div>

            <p className="fw-bold mb-0 ps-4 cursor-pointer" onClick={showOverview}>
              Dlaczego warto?
            </p>
          </div>
          <GastroVoucherPaymentButton voucher={voucher} />
        </div>
      ) : (
        <>
          <ProgressWithIcon
            progress={progress}
            color="primary"
            icon="font-size-lg px-1 uil-tag-alt text-muted"
            label={
              <div className="d-sm-flex d-inline-flex justify-content-between">
                <SpecialProductVoucherCode code={voucher.code} text="Kod vouchera:" />
                <AvailableAmount voucher={voucher} classname="d-sm-flex mb-1 d-none" />
              </div>
            }
          />
          <AvailableAmount voucher={voucher} classname="d-flex d-sm-none mt-2 ms-4" />
        </>
      )}
    </div>
  )
}

const AvailableAmount = ({ voucher, classname }) => (
  <span className={clsx('font-size-sm align-items-end text-nowrap', classname)}>
    Dostępne środki: <strong className="ms-3 me-1">{formatPrice(voucher.available_amount)} </strong> z{' '}
    {formatPrice(voucher.start_amount)}
  </span>
)
