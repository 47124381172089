import { CouponCheckedInReservation } from '@modules/coupons/coupon-checked-in-reservation'
import { CouponUncheckedReservationBanner } from '@modules/coupons/coupon-unchecked-reservation-banner'
import * as React from 'react'
import { Reservation } from '@models/reservation'
import { useCouponSelectedTypeConfiguration } from '@modules/coupons/use-coupon-selected-type-configuration'
import { useCouponPurchaseHours } from '@modules/coupons/use-coupon-purchase-hours'
import { CouponUnavailableBox } from '@modules/coupons/coupons-unavailable-box'

interface Props {
  checkedInReservation: Reservation | undefined
  firstUpcomingReservation: Reservation | undefined
}

export const CouponContent = ({ checkedInReservation, firstUpcomingReservation }: Props) => {
  const { configuration } = useCouponSelectedTypeConfiguration()
  const { isInHoursRange } = useCouponPurchaseHours(configuration ? [configuration] : [])

  const isAvailableForReservation = React.useCallback(
    (reservation: Reservation) => reservation.resort_id === configuration?.resort,
    [configuration?.resort],
  )

  if (checkedInReservation) {
    if (!isAvailableForReservation(checkedInReservation)) return <CouponUnavailableBox />

    return (
      <CouponCheckedInReservation
        reservation={checkedInReservation}
        configuration={configuration}
        isInHoursRange={isInHoursRange}
      />
    )
  }

  return firstUpcomingReservation && !isAvailableForReservation(firstUpcomingReservation) ? (
    <CouponUnavailableBox />
  ) : (
    <CouponUncheckedReservationBanner upcomingReservation={firstUpcomingReservation} />
  )
}
