import * as React from 'react'
import { ContactInfo } from '@modules/payment-result/contact-info'

export const PasswordChangeFailure = () => (
  <>
    <h4 className="text-secondary fw-bold text-center mt-5 mb-2">Pojawił się problem z Twoim kontem</h4>
    <p className="mb-4 mt-3 text-center font-size-12">
      Informacja o problemie została już do nas wysłana.
      <br />
      Skontaktujemy się z Tobą jak najszybciej się da.
    </p>
    <ContactInfo />
  </>
)
