import * as React from 'react'
import { createCombinedDateWithTime, formatDate } from '@helpers/date-helper'
import { isAfter, isBefore } from 'date-fns'
import { CouponTypeConfiguration } from '@models/coupons'

interface Response {
  isInHoursRange: boolean
}

export const useCouponPurchaseHours = (configurations: CouponTypeConfiguration[]): Response => {
  const isInRange = React.useCallback(
    (configuration: CouponTypeConfiguration) => {
      const nowDate = new Date()
      const now = formatDate(nowDate)

      if (!configurations.length) return false

      return (
        isAfter(nowDate, createCombinedDateWithTime(now, configuration.purchase_hour_from)) &&
        isBefore(nowDate, createCombinedDateWithTime(now, configuration.purchase_hour_to))
      )
    },
    [configurations],
  )

  const isInHoursRange = configurations.some(isInRange)

  return {
    isInHoursRange,
  }
}
