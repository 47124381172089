import { ClientDetails, GastroCard, SubscriptionContract } from '@models/client'
import { ClientActionsTypes, ClientActionTypesKeys } from '@store/action-types/client-action-types'

export interface ClientState {
  details: ClientDetails
}

export const emptyUser: ClientDetails = {
  gastro_cards: [],
  subscriptions: [],
  subscription_contracts: [],
  packages_vip: [],
  client_wallet: {
    available_amount: '',
    date: '',
  },
  email: '',
  first_name: '',
  id: 0,
  language: 'pl',
  last_login: '',
  last_name: '',
  phone: '',
  profile: {
    account_number: '',
    city: '',
    country: '',
    guests: [],
    id: 0,
    invoice: false,
    invoice_city: '',
    invoice_company: '',
    invoice_country: '',
    invoice_nip: '',
    invoice_postcode: '',
    invoice_street: '',
    invoice_type: 'company',
    notifications_arrival: false,
    notifications_news: false,
    notifications_promotion: false,
    postcode: '',
    rules: [],
    skip_service_charge: false,
    street: '',
  },
  urls: {
    cashback_vouchers: '',
    account_number_update: '',
    client_wallet_items: '',
    guests: '',
    reservations: '',
    update: '',
    delete: '',
    wallet_return: '',
    wallet_top_up: '',
    rules: '',
  },
}

const initialState: ClientState = {
  details: emptyUser,
}

const clientState = (state: ClientState = initialState, action: ClientActionsTypes): ClientState => {
  switch (action.type) {
    case ClientActionTypesKeys.SET_CLIENT_DETAILS:
      return { ...state, details: action.payload }
    case ClientActionTypesKeys.UPDATE_CLIENT_GASTRO_CARD:
      return {
        ...state,
        details: {
          ...state.details,
          gastro_cards: state.details.gastro_cards.map((gastroCard: GastroCard) =>
            gastroCard.id === action.payload.id ? action.payload : gastroCard,
          ),
        },
      }
    case ClientActionTypesKeys.UPDATE_CLIENT_SUBSCRIPTION_CONTRACT:
      return {
        ...state,
        details: {
          ...state.details,
          subscription_contracts: state.details.subscription_contracts.map(
            (subscriptionContract: SubscriptionContract) =>
              subscriptionContract.id === action.payload.id ? action.payload : subscriptionContract,
          ),
        },
      }
    default:
      return state
  }
}

export default clientState
