export enum NotificationEvents {
  CHANGE_BOOKING = 'CHANGE_BOOKING',
  CLIENT_CHANGE = 'CLIENT_CHANGE',
  PAYMENT_REQUEST_CHANGE = 'PAYMENT_REQUEST_CHANGE',
  COUPON_ITEM_CHANGE = 'COUPON_ITEM_CHANGE',
}

export enum ClientEvents {
  LOGIN_TOKEN = 'LOGIN_TOKEN',
}
