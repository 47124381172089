import * as React from 'react'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router'
import { NavigationPath } from '@models/routes'
import { OperationResult } from '@modules/payment-result/utils'

interface Props {
  result: OperationResult
  successComponent: React.ReactElement
  failureComponent?: React.ReactElement
  actionButtonText?: string
  action?: () => void
}

export const AccountActionResult = ({
  result,
  successComponent,
  failureComponent,
  actionButtonText,
  action,
}: Props): JSX.Element => {
  const { push } = useHistory()

  const handleBackToMainPage = () => push(NavigationPath.Root)

  return (
    <div>
      <img
        src={require(`@assets/images/${result === OperationResult.success ? 'success' : 'error'}.svg`)}
        style={{ maxWidth: '90px' }}
        alt="wynik operacji"
        className="mt-5 d-block mx-auto"
      />
      {result === OperationResult.success ? successComponent : failureComponent}
      <Button className="btn-primary authorization-layout__button" onClick={action ?? handleBackToMainPage}>
        <strong>{actionButtonText || 'Przejdź do logowania'}</strong>
      </Button>
    </div>
  )
}
