import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { useCopy } from '@hooks/use-copy'
import { SubscriptionCode } from '@models/client'
import { SpecialProductUsageReservation } from '@modules/special-products/special-product-usage-reservation'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { CustomTooltip } from '@components/custom-tooltip'

interface Props {
  borderless?: boolean
  code: SubscriptionCode
}

export const SubscriptionListRow = ({ borderless, code }: Props) => {
  const { copy, Copiable } = useCopy()

  const isCodeStillToUse = !code.is_used && (code.is_splitted ? code.bookings.length < 2 : !code.bookings.length)

  return (
    <div className={clsx('d-flex gap-xl-2 py-2', { 'border-bottom': !borderless })}>
      <div className="px-xl-0 px-3 d-flex flex-wrap w-100">
        <span className="col-sm-3 col-4 d-flex flex-column justify-content-center">
          <IconWithText
            text={code.is_used ? 'użyty' : 'aktywny'}
            textClassName={clsx('font-size-sm', { 'text-success': !code.is_used })}
            iconClassName={clsx('me-2 font-size-lg lh-1', code.is_used ? 'uil-times' : 'uil-check text-success')}
            className={clsx({ 'opacity-50': code.is_used })}
          />
          {code.is_splitted && (
            <span className="col-sm-2 col-12 d-flex align-items-center justify-content-between">
              <IconWithText
                text="podzielony"
                className={clsx('text-primary', { 'opacity-50': code.is_used })}
                iconClassName="uil-link-broken font-size-lg me-2"
                textClassName="font-size-sm"
              />
            </span>
          )}
        </span>
        <strong className="col-sm-4 col-8 d-flex justify-content-center align-items-sm-start align-items-end flex-column">
          <Copiable copyText="Kod skopiowany" className="p-0">
            <IconWithText
              onIconClick={() => copy(code.code)}
              text={code.code}
              iconClassName="uil-copy font-size-lg ms-2 lh-1"
              textClassName="lh-1 font-size-sm"
              className={clsx('flex-row-reverse justify-content-end subscription__code', {
                'opacity-50': code.is_used,
              })}
            />
          </Copiable>
          <SpecialProductUsageReservation code={code} className="d-sm-block d-none" />
        </strong>

        <SpecialProductUsageReservation code={code} className="d-sm-none ps-4 font-size-sm" />
        <span
          className={clsx(
            'col-sm-3 col-12 d-flex align-items-center font-size-sm justify-content-between mt-1 mt-ms-0 ps-4 ps-sm-0',
            { 'd-none d-sm-flex': !isCodeStillToUse },
          )}
        >
          <span className="d-sm-none me-2">Do wykorzystania: </span>
          <span>{isCodeStillToUse ? code.localization_kind_display : '-'}</span>
        </span>

        <div
          className={clsx(
            'col-sm-2 col-12 d-flex align-items-center font-size-sm justify-content-between ps-4 ps-sm-0',
            { 'd-none d-sm-flex': !isCodeStillToUse },
          )}
        >
          <span className="d-sm-none me-2">Ważny do:</span>
          <span>
            {isCodeStillToUse ? (
              <div className="d-flex align-items-center">
                {toDefaultDateFormat(code.expire_after)}
                <CustomTooltip
                  tooltipClassName="d-none d-sm-block"
                  showOnHover
                  content={
                    <div className="py-2 px-3 text-nowrap">
                      Termin do kiedy należy <br />
                      przypisać kod do rezerwacji
                    </div>
                  }
                >
                  <i className="uil-info-circle text-primary font-size-lg" />
                </CustomTooltip>
              </div>
            ) : (
              '-'
            )}
          </span>
        </div>
      </div>
    </div>
  )
}
