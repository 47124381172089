import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  progress: number
  label: string
  className?: string
}

export const ProgressBarLabel = ({ progress, label, className }: Props): JSX.Element => (
  <div className={clsx('text-navy font-size-xl', className)}>
    <span className="fw-bold">{label}</span> ({progress}%)
  </div>
)
