import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { CheckButton } from '@components/controls/check-button'
import { useDispatch, useSelector } from 'react-redux'
import { ResortImprovement } from '@models/reservation'
import { isVip, isWinterVip } from '@helpers/improvement-helper'
import { formatPriceShort } from '@helpers/price-helper'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import { VipPricesBlock } from '@modules/reservations/improvements/kinds/vip/vip-prices-block'
import { useApiRequest } from '@hooks/use-api-request'
import { ImprovementModalWrapper } from '@modules/reservations/improvements/improvement-modal-wrapper'
import { addImprovement } from '@api/improvements'
import { setReservationDetails } from '@store/slices/reservations-slice'

interface Props {
  onShowDetailList: () => void
  handleClose: () => void
  resortImprovement: ResortImprovement
}

export const VipDetailsModal = ({ handleClose, onShowDetailList, resortImprovement }: Props): JSX.Element => {
  const dispatch = useDispatch()

  const reservationDetails = useSelector(selectReservationDetails)

  const { action: handleAddImprovement, isLoading } = useApiRequest(async () => {
    if (reservationDetails && !reservationDetails.prices.improvements.items.find(isVip)) {
      dispatch(
        setReservationDetails(
          await addImprovement(reservationDetails.urls.improvements, [{ code: resortImprovement.code }]),
        ),
      )
    }

    handleClose()
  })

  const checkButtonTitle = (
    <span className="font-size-lg">{`Dolicz do rachunku ${formatPriceShort(resortImprovement.price_brutto)}`}</span>
  )

  return (
    <ImprovementModalWrapper
      contentTitle={isWinterVip(resortImprovement) ? 'Zimowy pakiet ulepszeń VIP' : 'Pakiet ulepszeń VIP'}
      isLoading={isLoading}
      onClose={handleClose}
      image={resortImprovement.image.sm}
      onSubmit={handleAddImprovement}
    >
      <div className="px-3 pb-3 px-xl-4 container-lg">
        <p className="text-muted mt-2">{resortImprovement.description}</p>
        <IconWithText
          text="Co dostaję w pakiecie?"
          iconClassName="uil-plus me-2 font-size-lg"
          className="text-darker-gray d-inline-flex fw-semi-bold"
          onClick={onShowDetailList}
        />
        <strong className="d-block text-primary mt-3 mb-2">Pakiet VIP</strong>
        <CheckButton
          className="w-100"
          title={checkButtonTitle}
          description="Dolicz do kwoty rezerwacji"
          isActive={true}
        />
      </div>
      <div className="p-3 bg-light-blue px-xl-4 border-top">
        <div className="container-lg ps-xl-0">
          <VipPricesBlock improvement={resortImprovement} />
        </div>
      </div>
    </ImprovementModalWrapper>
  )
}
