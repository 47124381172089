import { CustomReactSelect } from '@components/custom-react-select'
import { CustomFormDatePicker } from '@components/form-controls/custom-form-date-picker'
import { CustomFormGroup } from '@components/form-controls/custom-form-group'
import { formatDate } from '@helpers/date-helper'
import { subYears } from 'date-fns'
import * as React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { guestTypeOptions } from '@modules/user/guests/modal'

interface Props {
  isGuestKindChangeDisabled?: boolean
}

export const ReservationDataCompletionAddGuestForm = ({ isGuestKindChangeDisabled }: Props): JSX.Element => {
  const { watch, setValue } = useFormContext()
  const type = watch('type')

  React.useEffect(() => {
    if (type?.value === 'adult') {
      setValue('birthday', undefined)
    }
  }, [type])

  return (
    <>
      <Row className="gx-2">
        <Col xl={5}>
          <CustomFormGroup
            formControlProps={{ className: 'bg-white', placeholder: 'Wpisz imię i nazwisko' }}
            inputName="name"
          />
        </Col>
        <Col xl={5}>
          <CustomReactSelect
            inputName="type"
            className="mt-2"
            options={guestTypeOptions}
            placeholder="Wybierz wiek"
            isDisabled={isGuestKindChangeDisabled}
          />
        </Col>
      </Row>
      {type?.value === 'child' && (
        <Row className="gx-2 mt-2">
          <Col xl={5}>
            <CustomFormDatePicker
              inputName="birthday"
              formControlProps={{ className: 'bg-white' }}
              max={formatDate(new Date())}
              min={formatDate(subYears(new Date(), 18))}
              isDisabled={isGuestKindChangeDisabled}
            />
          </Col>
        </Row>
      )}
    </>
  )
}
