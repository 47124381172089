import { IconWithText } from '@components/icon-with-text'
import * as React from 'react'
import { useModal } from '@components/modals/use-modal'

export const RulesNotAcceptedWarning = (): JSX.Element => {
  const [showRulesModal] = useModal('ReservationRequiredRulesModal', {
    title: 'Regulaminy',
  })

  return (
    <IconWithText
      iconClassName="uil-exclamation-triangle text-danger me-2 font-size-xxl"
      textClassName="font-size-sm text-darker-gray"
      className="ps-4 mt-3"
      text={
        <span>
          Aby opłacić pobyt musisz zakceptować zgody. Możesz zrobić to
          <strong className="cursor-pointer ms-1" onClick={showRulesModal}>
            tutaj.
          </strong>
        </span>
      }
    />
  )
}
