import * as React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { ModalBody } from 'react-bootstrap'
import { ControlledOtpInput } from '@modules/wallet/controlled-otp-input'
import { updateAccountNumber } from '@store/actions/client-actions'
import { useDispatch } from 'react-redux'
import { ModalFooter } from '@components/modals/modal-footer'
import { useApiRequest } from '@hooks/use-api-request'

interface FormInputs {
  code: string
}

interface Props {
  account: string
  handleClose: () => void
  handleEdit: () => void
}

export const ConfirmAccountChangeModal = ({ handleClose, account, handleEdit }: Props): JSX.Element => {
  const methods = useForm<FormInputs>({
    defaultValues: {
      code: '',
    },
  })

  const dispatch = useDispatch()
  const { isLoading, action: handleConfirm } = useApiRequest(async () => {
    await dispatch(updateAccountNumber(account, methods.getValues('code')))
    handleClose()
    handleEdit()
  })

  return (
    <>
      <ModalBody className="modal-scrollable text-muted py-5">
        <FormProvider {...methods}>
          <p className="me-5 w-75">Potwierdź zmianę numeru konta wpisując kod bezpieczeństwa poniżej:</p>
          <ControlledOtpInput inputName="code" />
        </FormProvider>
      </ModalBody>
      <ModalFooter
        isLoading={isLoading}
        withCancelButton
        onSubmit={handleConfirm}
        onClose={handleClose}
        submitLabel="Potwierdzam zmianę"
      />
    </>
  )
}
