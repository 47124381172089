import { CustomFormGroup, CustomFormGroupProps } from '@components/form-controls/custom-form-group'
import * as React from 'react'
import { useToggle } from 'rooks'
import * as clsx from 'clsx'

export const PasswordPreviewFormGroup = (props: CustomFormGroupProps): JSX.Element => {
  const [passwordVisible, setPasswordVisible] = useToggle()

  return (
    <CustomFormGroup
      inputSuffix={
        <div className="cursor-pointer d-flex align-items-center px-3 h-100" onClick={setPasswordVisible}>
          <i
            className={clsx('font-size-lg text-muted', {
              'uil-eye-slash': !passwordVisible,
              'uil-eye': passwordVisible,
            })}
          />
        </div>
      }
      {...props}
      formControlProps={{
        type: passwordVisible ? 'text' : 'password',
        ...props.formControlProps,
      }}
    />
  )
}
