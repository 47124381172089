import * as React from 'react'
import { Link } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { CouponItem } from '@models/coupons'
import { DateFormats, formatDate } from '@helpers/date-helper'
import * as clsx from 'clsx'

interface Props {
  coupon: CouponItem
}

export const CouponsLisItem: React.FC<Props> = ({ coupon }) => {
  const isUsed = coupon.status === 'used'
  const isActive = coupon.status === 'active'
  const isExpired = coupon.status === 'expired'

  return (
    <li className="d-flex mb-2">
      <div className={clsx('coupons-view__list__item', { 'is-used': isUsed || isExpired })}>
        <div className="border-end h-100 d-flex align-items-center">
          <i
            className={clsx('font-size-22 px-2', {
              'text-success uil-check': isActive,
              'text-muted uil-times': isUsed,
              'text-muted uil-stopwatch-slash': isExpired,
            })}
          />
        </div>
        <div className="p-2">
          <strong className={clsx('d-block', { 'text-muted': isUsed, 'text-success': isActive })}>
            {coupon.status_display}
          </strong>
          <span className="font-size-sm">
            {isUsed && `Wykorzystano ${formatDate(coupon.use_date, DateFormats.MONTH_WITH_FULL_WORD_MONTH_WITH_YEAR)}`}
            {isActive &&
              `Wykorzystaj do ${formatDate(coupon.expiration_date, DateFormats.MONTH_WITH_FULL_WORD_MONTH_WITH_YEAR)}`}
            {isExpired &&
              `Kupon wygasł ${formatDate(coupon.expiration_date, DateFormats.MONTH_WITH_FULL_WORD_MONTH_WITH_YEAR)}`}
          </span>
        </div>
      </div>
      <div className={clsx('coupons-view__list__item-button py-2', { 'is-used': isUsed || isExpired || !coupon.code })}>
        <Link to={NavigationPath.CouponDetails.replaceId(coupon.id)} className="text-decoration-none text-darker-gray">
          Zobacz kupon
        </Link>
      </div>
    </li>
  )
}
