import * as React from 'react'
import { ReservationReceiptModalRow } from '@modules/reservations/modals/reservation-receipt-modal-row'
import { ReservationReceiptModalHeader } from '@modules/reservations/modals/reservation-receipt-modal-header'
import { ReservationReceiptModalBox } from '@modules/reservations/modals/reservation-receipt-modal-box'
import { BillDamage, ReservationBill } from '@models/bill'
import { ReservationReceiptModalRoomPurchases } from '@modules/reservations/modals/reservation-receipt-modal-room-purchases'
import { Guest } from '@models/reservation'

interface Props {
  bill: ReservationBill
  guests: Guest[]
}

export const ReservationReceiptModalDepositSummary = ({ bill, guests }: Props): JSX.Element => (
  <div className="receipt-box py-2">
    <div className="font-size-xl fw-bold text-muted my-2 px-2">2. Kaucja podsumowanie</div>
    <ReservationReceiptModalHeader />
    <ReservationReceiptModalRoomPurchases shopping={bill.deposit_shopping} guests={guests} />
    <ReservationReceiptModalBox title="Obciążenia" total={bill.damages.total_price_brutto}>
      {bill.damages.items.map((damage: BillDamage) => (
        <ReservationReceiptModalRow
          key={damage.id}
          titleColumn={damage.name}
          priceColumn={damage.price_brutto}
          className="py-0"
          noBorder
          indent
        />
      ))}
    </ReservationReceiptModalBox>

    <ReservationReceiptModalRow
      titleColumn="Naliczenia kaucji razem"
      priceColumn={bill.deposit_shopping.total_price_brutto}
      variant="negative"
    />
  </div>
)
