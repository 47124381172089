import * as React from 'react'
import { ModalConfig, ModalParams, ModalsMap, ModalTypes } from '@components/modals/types'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import * as clsx from 'clsx'
import { useDeviceSize } from '@hooks/use-device-size'
import { ModalSidebarImage } from '@components/modals/modal-sidebar-image'
import { ModalMobileImage } from '@components/modals/modal-mobile-image'
import { modalsSelector, setHideModal, setModalIsOpen } from '@store/slices/modal-slice'
import * as R from 'ramda'

interface ModalComponentRowProps {
  modal: ModalTypes
  params: ModalParams
  config?: ModalConfig
  isOpen: boolean
}

export const ModalComponentRow = ({ modal, isOpen, params, config }: ModalComponentRowProps): JSX.Element => {
  const dispatch = useDispatch()
  const { isMobile } = useDeviceSize()

  const [ModalContent, options] = ModalsMap[modal]

  const onClose = React.useCallback(() => {
    config?.onClose?.()
    dispatch(setHideModal({ modal }))
  }, [modal, config])

  const handleCloseModal = React.useCallback(() => {
    dispatch(setModalIsOpen({ modal, isOpen: false }))
  }, [modal])

  const modalOptions = R.omit(['imageMobile'], options)
  const modalConfig = { ...options, ...params }

  const modals = useSelector(modalsSelector)
  const lastModal = modals[modals.length - 1]

  return (
    <Modal
      show={isOpen}
      onExited={onClose}
      onHide={handleCloseModal}
      role="modal"
      {...modalOptions}
      contentClassName={clsx('d-flex', {
        'flex-row': !isMobile,
        'mh-95': isMobile && modalConfig.scrollable,
        ...options.contentClassName,
      })}
      dialogClassName={clsx({
        'm-0 h-100 d-flex align-items-end': isMobile,
        ...options.dialogClassName,
      })}
      centered={!isMobile}
      data-modal-active={lastModal?.modal === modal && lastModal.isOpen && 'active'}
    >
      <div
        className={clsx({
          'w-75 d-flex flex-column': !isMobile && modalConfig.image,
          'w-100 mw-100': isMobile || !modalConfig.image,
          'd-grid overflow-hidden': (isMobile || !modalConfig.image) && modalConfig.scrollable,
        })}
      >
        <Modal.Header closeButton={!modalConfig?.image} className="p-0 ps-3 overflow-hidden">
          <div
            className={clsx('modal-header__title font-size-20 text-primary fw-bold mb-0 w-100', {
              'py-4 py-md-3': modalConfig?.title || modalConfig?.titleMobile,
            })}
          >
            {isMobile && modalConfig?.titleMobile ? modalConfig?.titleMobile : modalConfig?.title}
          </div>
        </Modal.Header>
        {isMobile && (!!modalConfig.imageMobile || !!modalConfig.image) && (
          <ModalMobileImage
            image={modalConfig.imageMobile || modalConfig.image}
            imageClassName={modalConfig.imageClassName}
            imageWrapperClassName={modalConfig.imageWrapperClassName}
          />
        )}
        <ModalContent {...params} handleClose={handleCloseModal} isMobile={isMobile} />
      </div>
      {!isMobile && !!modalConfig.image && (
        <ModalSidebarImage
          image={modalConfig.image}
          imageClassName={modalConfig.imageClassName}
          imageWrapperClassName={modalConfig.imageWrapperClassName}
          handleCloseModal={handleCloseModal}
        />
      )}
    </Modal>
  )
}
