import * as React from 'react'
import { CashbackVoucherDetailsWalletItem } from '@models/client'
import { toDefaultDateFormat, toDefaultTimeFormat } from '@helpers/date-helper'
import { formatPrice } from '@helpers/price-helper'

interface Props {
  items: CashbackVoucherDetailsWalletItem[]
  sourceBooking: string
}

export const CashbackVoucherPurchaseHistoryTableMobile = ({ items, sourceBooking }: Props): JSX.Element => (
  <>
    <h4 className="text-navy mb-3">
      Historia zakupów
      <span className="fw-light d-block">(rezerwacja {sourceBooking})</span>
    </h4>
    <table className="table w-100 reservation-payment-history__table mb-0">
      <tbody>
        {items.map(item => (
          <tr key={item.id}>
            <td className="reservation-payment-history__cell--first-column vertical-align-middle lh-lg ps-4">
              <strong>{toDefaultDateFormat(item.created)}</strong> ({toDefaultTimeFormat(item.created)})
              <strong className="d-block">{item.description}</strong>
            </td>

            <td className="reservation-payment-history__cell--last-column vertical-align-middle text-end pe-4 lh-lg">
              <strong className="d-block">{item.booking_guest}</strong>
              <strong>{formatPrice(item.amount)}</strong>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </>
)
