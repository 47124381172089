import 'core-js/stable'
import '@assets/styles/app.scss'
import '@iconscout/unicons/css/line.css'

import PrivateRoute from '@components/private-route'
import { NavigationPath } from '@models/routes'
import { LoginView } from '@modules/account/login'
import store from '@store/index'
import * as React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import StartupView from './startup'
import { AppLayoutView } from '@components/layout-view'
import { ModalComponent } from '@components/modals/modal-component'
import axios from 'axios'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import * as smoothscroll from 'smoothscroll-polyfill'
import { PaymentSuccessView } from '@modules/payment-result/payment-success'
import { PaymentFailureView } from '@modules/payment-result/payment-failure'
import { WebSocketHandler } from '@components/web-socket-handler'
import { ClientWebSocketHandler } from '@modules/web-socket-handler'
import { AccountActivationView } from '@modules/account/account-creation/account-activation-view'
import { ResetPasswordCredentialsView } from '@modules/account/reset-password/reset-password-credentials'
import { ResetPasswordView } from '@modules/account/reset-password'
import { AccountCreationView } from '@modules/account/account-creation'
import { Error404 } from '@modules/errors/error-404'
import { ErrorPageView, LoadFailureCode } from '@modules/errors'
import { ReservationCheckIn } from '@modules/reservations/check-in'
import { ReservationCheckOut } from '@modules/reservations/check-out'
import { PaymentProcessingView } from '@modules/payment-result/payment-processing'
import { useLocation } from 'react-router'
import TagManager from 'react-gtm-module'
import { RefundView } from '@modules/refund'
import { DataProcessingView } from '@modules/data-processing'
import { useGtm } from '@hooks/use-gtm'
import { DocumentMeta } from '@components/document-meta'

smoothscroll.polyfill()

axios.defaults.withCredentials = true

if (process.env.SENTRY_CLIENT_PANEL_APP_NEW_DSN) {
  Sentry.init({
    dsn: process.env.SENTRY_CLIENT_PANEL_APP_NEW_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.3,
    environment: process.env.NODE_ENV,
  })
}

if (process.env.GOOGLE_TAG_MANAGER) {
  TagManager.initialize({ gtmId: process.env.GOOGLE_TAG_MANAGER })
}

const AppContainer = (props): JSX.Element => {
  const location = useLocation()
  const { trackPage } = useGtm()

  React.useEffect(() => {
    document.body.scrollTo({ top: 0, behavior: 'smooth' })
    trackPage(location.pathname)
  }, [location.pathname])

  return props.children
}

interface State {
  loadFailErrorCode: LoadFailureCode | null
}

export default class AppView extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props)

    this.state = {
      loadFailErrorCode: null,
    }
  }

  public showErrorPage = (errorCode: LoadFailureCode): void => {
    this.setState({ loadFailErrorCode: errorCode })
  }

  public render(): React.ReactNode {
    const { loadFailErrorCode } = this.state

    return (
      <Provider store={store}>
        <DocumentMeta title="Konto gościa - Holiday Park & Resort" />
        <BrowserRouter basename="/">
          <StartupView onLoadFail={this.showErrorPage}>
            <AppContainer>
              <WebSocketHandler />
              <ClientWebSocketHandler />
              <ModalComponent />
              <Switch>
                <Route path={NavigationPath.Login} component={LoginView} />
                <Route exact path={NavigationPath.ResetPassword} component={ResetPasswordView} />
                <Route path={NavigationPath.ResetPasswordCredentials} component={ResetPasswordCredentialsView} />
                <Route path={NavigationPath.AccountActivation} component={AccountActivationView} />
                <Route path={NavigationPath.AccountCreate} component={AccountCreationView} />
                <Route exact path={NavigationPath.PaymentSuccess} component={PaymentSuccessView} />
                <Route exact path={NavigationPath.PaymentProcessing} component={PaymentProcessingView} />
                <Route exact path={NavigationPath.PaymentFailure} component={PaymentFailureView} />
                <Route exact path={NavigationPath.Refund} component={RefundView} />
                <Route exact path={NavigationPath.DataProcessing} component={DataProcessingView} />
                <Route exact path={NavigationPath.Error404} component={Error404} />
                <PrivateRoute exact path={NavigationPath.CheckIn} component={ReservationCheckIn} />
                <PrivateRoute exact path={NavigationPath.CheckOut} component={ReservationCheckOut} />
                <PrivateRoute path={NavigationPath.Root} component={AppLayoutView} />
                <Redirect from="*" to={NavigationPath.Error404} />
              </Switch>
            </AppContainer>
          </StartupView>
        </BrowserRouter>
        {loadFailErrorCode && <ErrorPageView loadFailureCode={loadFailErrorCode} />}
      </Provider>
    )
  }
}
