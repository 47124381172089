import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { formatPrice, formatPriceShort } from '@helpers/price-helper'
import { Checkbox } from '@components/controls/checkbox'
import { ReservationDetails } from '@models/reservation'
import { useCashbackVoucherRules } from '@modules/special-products/cashback-vouchers/use-cashback-voucher-rules'
import { useReservationCashbackVoucherInGeneration } from '@modules/special-products/cashback-vouchers/use-reservation-cashback-voucher-in-generation'
import { useApiRequest } from '@hooks/use-api-request'
import { commonObjectPost } from '@api/basic-requests'
import { useDidUpdate } from 'rooks'
import { useFormContext, useWatch } from 'react-hook-form'
import { CheckOutFormInputs } from '@modules/reservations/check-out/check-out-modal'

interface Props {
  reservationDetails: ReservationDetails
  onRefetchBill: () => Promise<void>
}

export const CheckOutCashbackVoucher = ({ reservationDetails, onRefetchBill }: Props): JSX.Element | null => {
  const showRule = useCashbackVoucherRules()
  const { control } = useFormContext<CheckOutFormInputs>()

  const isCashbackVoucherSelected = useWatch({ control, name: 'cashback_voucher_accepted' })

  const { voucher } = useReservationCashbackVoucherInGeneration(reservationDetails)

  const { action: updateCashbackVoucher } = useApiRequest(async () => {
    if (!reservationDetails) return
    await commonObjectPost(reservationDetails.urls.online_checkout_cashback_voucher, {
      cashback_voucher_accepted: isCashbackVoucherSelected,
    })

    await onRefetchBill()
  })

  useDidUpdate(() => {
    updateCashbackVoucher()
  }, [isCashbackVoucherSelected])

  if (!voucher) return null

  return (
    <>
      <IconWithText
        text={`Dzięki zakupom na Voucherze rePLAY! ${voucher.number} zebrałeś ${formatPriceShort(
          voucher.promotion_amount,
        )}`}
        iconClassName="uil-info-circle font-size-icon-18 ms-2 me-2 me-xl-0 text-primary"
        textClassName="text-lighter-gray"
        className="font-size-sm d-flex flex-xl-row-reverse mt-2 text-start"
      />

      <Checkbox
        className="mt-3 checkbox--small ms-2 ms-xl-0"
        name="cashback_voucher_accepted"
        label={
          <span className="fw-normal font-size-sm">
            Chcę wykupić voucher rePLAY! za <strong>{formatPrice('0.01')}</strong> i akceptuję
            <button className="shadow-none fw-bold bg-transparent shadow-none border-0" onClick={showRule}>
              regulamin promocji
            </button>
          </span>
        }
      />
    </>
  )
}
