import * as React from 'react'
import { AppLayout } from '@components/layout'
import { NavigationPath } from '@models/routes'
import PrivateRoute from '@components/private-route'
import { Redirect, Switch } from 'react-router'
import { UserView } from '@modules/user'
import { WalletView } from '@modules/wallet'
import { ReservationsView } from '@modules/reservations'
import { ImprovementsView } from '@modules/reservations/improvements'
import { ReservationDetailsMobileView } from '@modules/reservations/pending/mobile/reservation-details-mobile-view'
import { SpecialProductsView } from '@modules/special-products/special-products-view'
import { SubscriptionDetailsView } from '@modules/special-products/subscriptions/details/subscription-details-view'
import { PackageVipDetailsView } from '@modules/special-products/packages-vip/details/package-vip-details-view'
import { GastroVoucherDetailsView } from '@modules/special-products/gastro-vouchers/details/gastro-voucher-details-view'
import { SubscriptionContractDetailsView } from '@modules/special-products/subscription-contracts/details/subscription-contract-details-view'
import { CashbackVoucherDetailsView } from '@modules/special-products/cashback-vouchers/details/cashback-voucher-details-view'
import { CouponsView } from '@modules/coupons'
import { CouponDetailsView } from '@modules/coupons/details/coupon-details-view'

export const AppLayoutView = (): JSX.Element => (
  <AppLayout>
    <Switch>
      <PrivateRoute exact={true} path={NavigationPath.User} component={UserView} />
      <PrivateRoute exact={true} path={NavigationPath.Wallet} component={WalletView} />
      <PrivateRoute exact={true} path={NavigationPath.Coupons} component={CouponsView} />
      <PrivateRoute exact={true} path={NavigationPath.CouponDetails} component={CouponDetailsView} />
      <PrivateRoute exact={true} path={NavigationPath.Reservations} component={ReservationsView} />
      <PrivateRoute exact={true} path={NavigationPath.Improvements} component={ImprovementsView} />
      <PrivateRoute exact={true} path={NavigationPath.ReservationDetails} component={ReservationDetailsMobileView} />
      <PrivateRoute exact={true} path={NavigationPath.SpecialProducts} component={SpecialProductsView} />
      <PrivateRoute exact={true} path={NavigationPath.SubscriptionDetails} component={SubscriptionDetailsView} />
      <PrivateRoute
        exact={true}
        path={NavigationPath.SubscriptionContractDetails}
        component={SubscriptionContractDetailsView}
      />
      <PrivateRoute exact={true} path={NavigationPath.PackageVipDetails} component={PackageVipDetailsView} />
      <PrivateRoute exact={true} path={NavigationPath.GastroVoucherDetails} component={GastroVoucherDetailsView} />
      <PrivateRoute exact={true} path={NavigationPath.CashbackVoucherDetails} component={CashbackVoucherDetailsView} />
      <Redirect from={NavigationPath.Root} to={NavigationPath.Reservations} />
      <Redirect from="*" to={NavigationPath.Error404} />
    </Switch>
  </AppLayout>
)
