import * as React from 'react'
import { SectionTitle } from '@components/section-title'

interface Props {
  children?: React.ReactNode
}

export const CheckOutSummaryStep = ({ children }: Props): JSX.Element => (
  <div className="d-flex flex-column flex-xl-row gap-5 align-items-center">
    <div className="px-sm-4 col-sm-7">
      <SectionTitle title={<Title />} subtitle={<Subtitle />} className="text-center text-xl-start" />
      <SectionTitle title="Do zobaczenia!" className="text-center text-xl-start pt-3 pt-sm-0" />
      {children}
    </div>
    <img
      className="mx-auto d-block"
      height={45}
      src={require('@assets/images/logo_holiday_color.svg')}
      alt="Logo holidaypark"
    />
  </div>
)

const Title = () => (
  <div>
    To już prawie koniec,
    <br className="d-md-none" /> dziękujemy za wspólne wakacje.
  </div>
)

const Subtitle = () => (
  <div className="mt-2">
    <p>Aby dokończyć wymeldowanie konieczny będzie zwrot wszystkich opasek rezerwacji</p>
    <p>
      Aby ominąć kolejki możesz <strong>zwrócić opaski</strong> podchodząc w recepcji do stanowiska{' '}
      <strong>Zwróć opaski.</strong>
    </p>
  </div>
)
