import * as React from 'react'
import { CheckInImprovementRow } from '@modules/reservations/check-in/steps/improvements/improvement-row'
import { FeedingImprovement, ReservationImprovement } from '@models/reservation'
import { sortImprovementByPosition } from '@helpers/improvement-helper'

interface Props {
  improvements: (ReservationImprovement | FeedingImprovement)[]
  reservationStartDate: Date
  reservationToken: string
}
export const CheckInImprovements = ({ improvements, reservationStartDate, reservationToken }: Props): JSX.Element => (
  <>
    {sortImprovementByPosition([...improvements]).map(improvement => (
      <CheckInImprovementRow
        key={improvement.id}
        improvement={improvement}
        reservationStartDate={reservationStartDate}
        reservationToken={reservationToken}
      />
    ))}
  </>
)
