import * as React from 'react'
import * as clsx from 'clsx'
import { TextWithSpinner } from '@components/text-with-spinner'

interface Props {
  variant?: 'danger' | 'secondary'
  label?: string
  onClick: () => void
  isDisabled?: boolean
  isLoading?: boolean
}

export const NextStepButton = ({
  variant = 'secondary',
  label = 'Przejdź dalej',
  onClick,
  isDisabled,
  isLoading,
}: Props): JSX.Element => (
  <button
    disabled={isDisabled}
    onClick={onClick}
    className={clsx(
      'btn rounded-0 w-100 p-3 position-fixed bottom-0 start-0 end-0 d-flex justify-content-center',
      { 'bg-secondary': variant === 'secondary' },
      { 'bg-danger': variant === 'danger' },
    )}
  >
    <TextWithSpinner
      text={label}
      iconClassName="uil-angle-right ms-2 font-size-icon-22"
      textWrapperClassName="text-white fw-bold flex-row-reverse"
      isLoading={!!isLoading}
    />
  </button>
)
