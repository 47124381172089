import { useSelector } from 'react-redux'
import { InitialReservationGuest, selectInitialGuests } from '@store/slices/guests-slice'
import { useEditableCheck } from '@hooks/use-editable-check'
import { createCombinedDateWithTime } from '@helpers/date-helper'
import { Guest, ReservationDetails } from '@models/reservation'
import { useImprovementRemovingCheck } from '@hooks/use-improvement-removing-check'
import { createSingleFeedingImprovement } from '@helpers/improvement-helper'

interface Response {
  canRemoveGuest: (reservationDetails: ReservationDetails, guest: Guest) => boolean
  canEditGuest: (reservationDetails: ReservationDetails | undefined, guest: Guest | null) => boolean
}

export const useGuestChangeCheck = (): Response => {
  const initialGuests = useSelector(selectInitialGuests)

  const { isInEditAllowedWindow } = useEditableCheck()
  const { canRemoveFeedingForGuest } = useImprovementRemovingCheck()

  const canRemoveGuest = (reservationDetails: ReservationDetails, guest: Guest) => {
    const isGuestAddedBefore = initialGuests.some(
      (initialGuest: InitialReservationGuest) =>
        initialGuest.reservation === reservationDetails.token && initialGuest.id === guest.id,
    )

    const hasAddedFeeding = reservationDetails.feeding_options.some(option => option.guest_id === guest.id)

    return (
      !isGuestAddedBefore ||
      (isGuestAddedBefore && !hasAddedFeeding) ||
      isInEditAllowedWindow(createCombinedDateWithTime(reservationDetails.date_from, reservationDetails.arrival_time))
    )
  }

  const canEditGuest = (reservationDetails: ReservationDetails | undefined, guest: Guest | null) => {
    if (!reservationDetails || !guest) return false

    const improvement = reservationDetails.prices.feeding.items.length
      ? createSingleFeedingImprovement(reservationDetails.prices.feeding)
      : undefined

    return !canRemoveFeedingForGuest({
      improvement,
      guest,
      reservation: reservationDetails?.token,
      dateFrom: createCombinedDateWithTime(reservationDetails.date_from, reservationDetails.arrival_time),
    })
  }

  return {
    canRemoveGuest,
    canEditGuest,
  }
}
