import * as clsx from 'clsx'
import * as React from 'react'

interface Props {
  checked: boolean
  children: React.ReactNode
}
export const CircleIndicator = ({ checked, children }: Props): JSX.Element => (
  <div className="circle-indicator__wrapper position-relative mx-1">
    <div
      className={clsx(
        'circle-indicator__indicator d-flex align-items-center justify-content-center position-absolute',
        {
          'circle-indicator__indicator--green': checked,
          'circle-indicator__indicator--gray': !checked,
        },
      )}
    >
      <i className={clsx('circle-indicator__indicator-icon', { 'uil-check': checked, 'uil-times': !checked })} />
    </div>
    <div
      className={clsx('circle-indicator__content d-flex align-items-center justify-content-center', {
        'circle-indicator__content--checked': checked,
      })}
    >
      {children}
    </div>
  </div>
)
