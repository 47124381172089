import {
  addDays,
  addHours,
  addMinutes,
  addYears,
  differenceInDays,
  eachDayOfInterval,
  format,
  formatDistanceStrict,
  formatDistanceToNow,
  formatDuration,
  intervalToDuration,
  isAfter,
  parseISO,
  set,
} from 'date-fns'
import { pl } from 'date-fns/locale'
import declination from '@helpers/declination'

export enum DateFormats {
  DAY_WITH_DATE = 'EE dd.MM.yyyy',
  MONTH_WITH_FULL_WORD_MONTH = 'dd MMMM',
  MONTH_WITH_YEAR = 'LLLL yyyy',
  MONTH_WITH_FULL_WORD_MONTH_WITH_YEAR = 'dd MMMM yyyy',
  DAY_MONTH_YEAR_SEPARATED_BY_DOT = 'dd.MM.yyyy',
  DATE_WITH_FULL_DAY = 'dd.MM.yyy, EEEE',
}

export const formatDate = (date: Date | undefined | string | null, dateFormat = 'yyyy-MM-dd'): string => {
  if (date === null) {
    return ''
  }
  if (typeof date === 'string') {
    return format(parseISO(date), dateFormat, { locale: pl })
  }
  if (typeof date !== 'undefined') {
    return format(date, dateFormat, { locale: pl })
  }
  return date ? date : ''
}

export const parseISODate = (value: string | Date | null | undefined): Date | null => {
  if (!value) {
    return null
  }
  if (typeof value === 'string') {
    return parseISO(value)
  }
  return value
}

export const defaultFrontendDate = 'dd.MM.yyyy'
export const defaultFrontendDateTime = 'dd.MM.yyyy HH:mm'
export const defaultFrontendTime = 'HH:mm'
export const shortFrontendDate = 'dd.MM'

export const toTextDateTimeFormat = (value: string | Date | null) =>
  formatDate(parseISODate(value), 'd LLLL RRRR') + ' godz.: ' + toDefaultTimeFormat(value)

export const toTextDateFormat = (value: string | Date | null) => formatDate(parseISODate(value), 'd LLLL RRRR')

export const toTextDayFormat = (value: string | Date | null) => formatDate(parseISODate(value), 'iiii')

export const toDefaultDateTimeFormat = (value: string | Date | null) =>
  formatDate(parseISODate(value), defaultFrontendDateTime)

export const toDefaultDateFormat = (value: string | Date | null | undefined) =>
  formatDate(parseISODate(value || null), defaultFrontendDate)

export const toShortDateFormat = (value: string | Date | null | undefined) =>
  formatDate(parseISODate(value || null), shortFrontendDate)

export const toDefaultTimeFormat = (value: string | Date | null | undefined) =>
  formatDate(parseISODate(value || null), defaultFrontendTime)

export const formatTimeToNow = (value: string | Date) => {
  const date = parseISODate(value)
  if (!date) return null
  return formatDistanceToNow(date, { locale: pl })
}

export const formatDaysDuration = (startDate: string | Date, endDate: string | Date) => {
  const start = parseISODate(startDate)
  const end = parseISODate(endDate)
  if (!(start && end)) return null
  return formatDuration({ days: differenceInDays(end, start) }, { zero: false, locale: pl })
}

export const getHoursFromDate = (date: Date | string): string => formatDate(date, 'HH:mm')

export const getYearsLabel = (count: number) =>
  formatDistanceStrict(addYears(new Date(), count), Date.now(), {
    locale: pl,
    unit: 'year',
  })
    .replace(/\d/g, '')
    .trim()

export const getDaysLabel = (count: number) =>
  formatDistanceStrict(addDays(new Date(), count), Date.now(), {
    locale: pl,
    unit: 'day',
  })
    .replace(/\d/g, '')
    .trim()

export const getHoursLabel = (count: number) =>
  formatDistanceStrict(addHours(new Date(), count), Date.now(), {
    locale: pl,
    unit: 'hour',
  })
    .replace(/\d/g, '')
    .trim()

export const getMinutesLabel = (count: number) =>
  formatDistanceStrict(addMinutes(new Date(), count), Date.now(), { locale: pl, unit: 'minute' })
    .replace(/\d/g, '')
    .trim()

export const getEachDayOfInterval = (starDate: string | Date | null, endDate: string | Date | null) => {
  const start = parseISODate(starDate)
  const end = parseISODate(endDate)

  if (!(start && end)) return []
  return eachDayOfInterval({ start, end })
}

export const createCombinedDateWithTime = (date: string, time: string): Date => {
  const [hours, minutes] = time.split(':')
  return set(parseISODate(date) || 0, {
    hours: parseInt(hours, 10),
    minutes: parseInt(minutes, 10),
  })
}

export const getStayLength = (dateFrom: string | Date, dateTo: string | Date): string => {
  const parsedDataFrom = parseISODate(dateFrom)
  const parsedDateTo = parseISODate(dateTo)

  let daysDiff = 0

  if (parsedDataFrom && parsedDateTo) {
    daysDiff = differenceInDays(parsedDateTo, parsedDataFrom)
  }

  return `${daysDiff} ${declination.stayDays(daysDiff)}`
}

export const formatRemainingTime = (
  date: string | Date,
  formatter?: ({ years, months, days, hours, minutes, seconds }) => string,
): string => {
  const isoDate = date instanceof Date ? date : (parseISODate(date) as Date)

  const currentDate = new Date()

  if (isAfter(currentDate, isoDate)) {
    return '0m'
  }

  const duration = intervalToDuration({ start: currentDate, end: isoDate })

  const hours = duration.hours ? `${duration.hours}h` : ''
  const days = duration.days ? (duration.days > 1 ? `${duration.days} dni` : `${duration.days} dzień`) : ''

  if (formatter)
    return formatter({
      years: duration.years,
      months: duration.months,
      days: duration.days,
      hours: duration.hours,
      minutes: duration.minutes,
      seconds: duration.seconds,
    })

  const units = ['years', 'months']
  const availableUnits = Object.entries(duration)
    .filter(el => el[1])
    .map(el => el[0])

  const yearsMonths = formatDuration(duration, {
    format: units.filter(i => new Set(availableUnits).has(i)),
    locale: pl,
  })

  return `${yearsMonths} ${days} ${hours} ${duration.minutes}m ${duration.seconds}s`
}
