import { FormControlWithLabel } from '@components/form-controls/form-control-with-label'
import { ParkingFormInputs, ReservationDetails } from '@models/reservation'
import * as clsx from 'clsx'
import * as React from 'react'
import { FormControl } from 'react-bootstrap'
import { FieldArrayWithId, useFormContext, useWatch } from 'react-hook-form'
import { handleEnter, isEqualCaseInsensitive } from '@helpers/utils'

export type PlateField = FieldArrayWithId<ParkingFormInputs, 'plates', 'id'>

interface Props {
  reservationDetails: ReservationDetails
  renderLabel: (index: number) => string
  onRemove?: (id: string) => void
  onSave?: (id: string, value: string, carId: number | null) => void
  wrapperClassName?: string
  field: PlateField
  index: number
  isLoading: (field: PlateField) => boolean
}

export const PlatesFormArrayInput = ({
  reservationDetails,
  renderLabel,
  onSave,
  wrapperClassName,
  index,
  field,
  isLoading,
}: Props): JSX.Element => {
  const inputName = `plates.${index}.register_number` as const

  const { register, control, getValues } = useFormContext<ParkingFormInputs>()

  const value = useWatch({ control, name: inputName })

  const isSucceed =
    !!value && reservationDetails.cars.some(({ register_number }) => isEqualCaseInsensitive(register_number, value))

  const handleSave = () => {
    const newValue = getValues(inputName) ?? ''
    if (field.register_number === newValue && newValue !== '') return
    onSave?.(field.id, newValue ?? '', field.carId)
  }

  return (
    <div className={clsx('d-flex align-items-center', wrapperClassName)}>
      <FormControlWithLabel
        inputName={inputName}
        label={renderLabel(index)}
        isSucceed={isSucceed}
        isFailed={false}
        isLoading={isLoading(field)}
        formGroupProps={{
          className: 'plates-form-array-input__form-group w-100',
        }}
      >
        <FormControl
          {...register(inputName)}
          className={clsx('shadow-none form-control-height-lg font-size-sm form-control-with-status text-uppercase', {
            'no-value': !value,
          })}
          data-testid={`plates_${index}-value-form-control`}
          onBlur={handleSave}
          onKeyDown={handleEnter(event => event.target.blur())}
          autoComplete="off"
          disabled={isLoading(field)}
        />
      </FormControlWithLabel>
    </div>
  )
}
