import * as React from 'react'
import { useDeviceSize } from '@hooks/use-device-size'
import { formatTimeToNow } from '@helpers/date-helper'

interface Props {
  dateTo: string
}

export const TimeLeftToNow = ({ dateTo }: Props): JSX.Element => {
  const { isMobile } = useDeviceSize()

  const timeToNow = React.useMemo(() => formatTimeToNow(dateTo) || '', [dateTo])
  const timeLeft = React.useMemo(() => timeToNow.match(/\d+/), [timeToNow])

  return isMobile ? (
    <span className="d-flex flex-column align-items-center word-spacing-max py-3 text-center">
      {!timeLeft
        ? timeToNow
        : timeToNow
            .split(' ')
            .map((el, index) => (
              <span key={index}>
                {el === timeLeft[0] ? <strong className="font-size-icon-22 py-1"> {timeLeft[0]} </strong> : el}
              </span>
            ))}
      <span>temu</span>
    </span>
  ) : (
    <span>{timeToNow} temu</span>
  )
}
