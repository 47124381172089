import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'
import { useDeviceSize } from '@hooks/use-device-size'
import { TrapezeButtonVariant } from '@components/controls/trapeze-button'
import { useModal } from '@components/modals/use-modal'
import { ReservationDetails } from '@models/reservation'
import { useHistory } from 'react-router'
import { NavigationPath } from '@models/routes'
import { useForwardProfileRulesToReservation } from '@hooks/use-forward-profile-rules-to-reservation'
import { LoadingTrapezoidButton } from '@components/controls/loading-trapezoid-button'

interface Params {
  reservationDetails: ReservationDetails
}

export const PendingReservationFooter = ({ reservationDetails }: Params): JSX.Element => {
  const { isMobile, isDesktop } = useDeviceSize()

  const { forwardRules, isForwarding } = useForwardProfileRulesToReservation()

  const [handleGastroVoucher] = useModal('AddGastroCardModal', {
    url: reservationDetails?.urls.gastro_card,
  })

  const history = useHistory()

  const [showCheckInModal] = useModal('CheckInModal')

  const handleCheckIn = async () => {
    await forwardRules(reservationDetails)

    isDesktop
      ? showCheckInModal(null, { reservation: reservationDetails })
      : history.replace(NavigationPath.CheckIn.replaceToken(reservationDetails.token))
  }

  return (
    <div className="d-flex flex-column flex-md-row align-items-center position-relative mb-2 mb-lg-0">
      <div
        className={clsx(
          'pending-reservation__time-counters col-12 col-md-2 p-3',
          isMobile ? 'text-navy fw-bold text-center font-size-20' : 'text-darker-gray fw-normal font-size-11',
        )}
      >
        Opcje dodatkowe:
      </div>
      <div className="d-flex">
        <IconWithText
          text={
            reservationDetails.wallets.length === 0
              ? 'Chcę skorzystać z vouchera gastronomicznego'
              : 'Chcę dodać kolejny voucher gastronomiczny'
          }
          textClassName="text-start"
          iconClassName="uil-tag-alt me-2 font-size-icon-18"
          className="me-2 fw-bold text-darker-gray checked-in-reservation__footer-option"
          onClick={handleGastroVoucher}
        />
        {reservationDetails.can_check_in && isDesktop && (
          <LoadingTrapezoidButton
            variant={TrapezeButtonVariant.secondary}
            className="position-absolute end-0 bottom-0"
            isLoading={isForwarding}
            loadingLabel="Ładowanie danych.."
            onClick={handleCheckIn}
          >
            <IconWithText text="Zamelduj on-line" iconClassName="uil-check font-size-icon-22 me-2" />
          </LoadingTrapezoidButton>
        )}
      </div>
    </div>
  )
}
