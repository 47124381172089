import { ReservationRowBanner } from '@modules/reservations/reservation-row-banner'
import * as React from 'react'
import { Reservation } from '@models/reservation'

interface Props {
  reservation: Reservation
}

export const ToCheckInBanner = ({ reservation }: Props): JSX.Element => (
  <ReservationRowBanner reservation={reservation}>
    <p className="reservation-row-banner__text">Udaj się do recepcji aby odebrać opaski.</p>
    <p className="font-size-sm text-muted mb-sm-4 mt-sm-2 mb-1">
      Miłego pobytu życzy <span className="text-nowrap">Holiday Park & Resort!</span>
    </p>
  </ReservationRowBanner>
)
