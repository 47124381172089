import * as React from 'react'
import * as clsx from 'clsx'
import { SubscriptionContractDetails } from '@models/client'
import { IconWithText } from '@components/icon-with-text'
import { parseISODate, toTextDateFormat } from '@helpers/date-helper'
import { addDays, isAfter, isBefore, isToday } from 'date-fns'
import { useModal } from '@components/modals/use-modal'

const MAX_ACCOUNT_DEBITING_DAYS = 3

interface Props {
  subscriptionContractDetails: SubscriptionContractDetails
}

export const SubscriptionContractExpandableRowPaymentsBanner = ({
  subscriptionContractDetails,
}: Props): JSX.Element | null => {
  const [showPaymentsList] = useModal('SubscriptionContractPaymentListModal', {
    title: 'Historia wpłat',
    periods: subscriptionContractDetails.periods,
    repaymentDate: subscriptionContractDetails.repayment_date,
  })
  const nextPeriod = subscriptionContractDetails.periods.find(period => period.status === 'unpaid')

  if (!nextPeriod) return null

  const today = new Date()
  const paymentDate = parseISODate(nextPeriod.payment_date) as Date

  const isPaid = isBefore(today, paymentDate)

  const isPaymentProcessing =
    !isPaid &&
    (isToday(paymentDate) ||
      (isAfter(today, paymentDate) && isBefore(today, addDays(paymentDate, MAX_ACCOUNT_DEBITING_DAYS))))

  const isPaymentOverdue = !isPaid && !isPaymentProcessing

  return (
    <div className="bg-light-blue rounded border px-xl-3 px-2 d-flex align-items-center justify-content-between py-2 font-size-sm mt-xl-2 mt-4">
      <IconWithText
        text={isPaid ? 'Subskrypcja opłacona' : isPaymentProcessing ? 'Pobieranie środków' : 'Problem z płatnością'}
        iconClassName={clsx({
          'uil-check font-size-xxl me-1': isPaid,
          'uil-times font-size-xxl me-1': isPaymentOverdue,
          'uil-money-insert font-size-xl me-2': isPaymentProcessing,
        })}
        className={clsx({
          'text-danger': isPaymentOverdue,
          'text-primary': isPaymentProcessing,
          'text-success': isPaid,
        })}
      />
      <span className="d-xl-inline d-none">
        Następna płatność: <span className="fw-semibold">{toTextDateFormat(nextPeriod.payment_date)}</span>
      </span>
      <IconWithText
        text="Pokaż historię wpłat"
        iconClassName="uil-invoice font-size-xxl me-1"
        onClick={showPaymentsList}
      />
    </div>
  )
}
