import * as clsx from 'clsx'
import * as React from 'react'

interface Props extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  value: boolean
  className?: string
  onChange: (value: boolean) => void
  children: React.ReactNode
  disabled?: boolean
}
export const CheckCard = ({ value, onChange, className, children, disabled, ...props }: Props): JSX.Element => {
  const handleClick = React.useCallback(() => {
    if (!disabled) {
      onChange(!value)
    }
  }, [disabled, onChange, value])

  return (
    <div
      className={clsx('card check-card__wrapper d-flex flex-row align-items-center', className, {
        active: !!value,
        disabled,
      })}
      onClick={handleClick}
      {...props}
    >
      <div className="check-card__check">
        <div className="check-card__circle m-3 d-flex align-items-center justify-content-center">
          {value && <i className="uil-check font-size-xxl" />}
        </div>
      </div>
      <div className="check-card__content-wrapper">{children}</div>
    </div>
  )
}
