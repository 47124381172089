import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ButtonLoading } from '@components/controls/button-loading'
import { useFormContext } from 'react-hook-form'
import { PasswordPreviewFormGroup } from '@components/form-controls/password-preview-form-group'

interface Props {
  onSubmit: () => void
  submitText: string
  submitLoadingText: string
  isLoading: boolean
  passwordField: string
  children?: React.ReactElement
}

export const PasswordsForm = ({
  onSubmit,
  isLoading,
  submitText,
  submitLoadingText,
  passwordField,
  children,
}: Props): JSX.Element => {
  const { handleSubmit } = useFormContext()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PasswordPreviewFormGroup
        label="Wpisz hasło:"
        inputName={passwordField}
        formControlProps={{ placeholder: 'Wpisz hasło' }}
      />
      <IconWithText
        text="Hasło powinno składać się z minimum 8 znaków"
        iconClassName="uil-info-circle me-2"
        textClassName="font-size-sm"
        className="mt-1 opacity-75"
      />

      <PasswordPreviewFormGroup
        label="Wpisz ponownie hasło:"
        inputName="confirmed_password"
        formControlProps={{ placeholder: 'Powtórz hasło' }}
      />
      <IconWithText
        text="Oba hasła muszą być jednakowe"
        iconClassName="uil-info-circle me-2"
        textClassName="font-size-sm"
        className="mt-1 opacity-75"
      />

      {children}
      <ButtonLoading
        className="btn-primary authorization-layout__button"
        type="submit"
        loadingLabel={submitLoadingText}
        isLoading={isLoading}
      >
        <strong>{submitText}</strong>
      </ButtonLoading>
    </form>
  )
}
