import * as React from 'react'
import { ReservationRowBanner } from '@modules/reservations/reservation-row-banner'
import { Reservation } from '@models/reservation'

interface Props {
  reservation: Reservation
  title?: string
  subtitle?: string
}

export const CheckOutUnavailableBanner = ({
  reservation,
  title = 'Twój pobyt dobiegł końca.',
  subtitle = 'Wymelduj się w recepcji.',
}: Props): JSX.Element => (
  <ReservationRowBanner reservation={reservation}>
    <p className="reservation-row-banner__text">{title}</p>
    <p className="reservation-row-banner__text font-size-md">{subtitle}</p>
    <p className="font-size-sm text-muted mb-sm-4 mt-sm-2 mb-1">
      Do zobaczenia w <span className="text-nowrap">Holiday Park & Resort</span>
    </p>
  </ReservationRowBanner>
)
