import { CompletionTabs } from '@components/completion-tabs'
import { TrapezeButtonVariant } from '@components/controls/trapeze-button'
import { useDeviceSize } from '@hooks/use-device-size'
import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import { ReservationDataCompletionGdpr } from '@modules/reservations/pending/data-completion-modal/gdpr'
import { ReservationDataCompletionGuests } from '@modules/reservations/pending/data-completion-modal/guests'
import { ReservationDataCompletionParking } from '@modules/reservations/pending/data-completion-modal/parking'
import { ReservationDetails, Rule } from '@models/reservation'
import {
  checkGuestsFilled,
  checkParkingFilled,
  isEachRulesRequiredForReservationAccepted,
} from '@modules/reservations/pending/utils'
import { ModalFooterDesktop } from '@components/modals/modal-footer-desktop'
import { ModalFooterMobileNavigation } from '@components/modals/modal-footer-mobile-navigation'
import { ClientDetailsProfileRuleKeyword } from '@models/client'
import { selectClientProfileRules } from '@store/selectors/client-selectors'
import { useSelector } from 'react-redux'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'

export enum DataCompletionTab {
  parking = 1,
  guests,
  gdpr,
}

const tabs = [
  { label: 'Parking', value: DataCompletionTab.parking },
  { label: 'Goście rezerwacji', value: DataCompletionTab.guests },
  { label: 'Wymagane zgody', value: DataCompletionTab.gdpr },
]

const getCompletedTabs = (
  reservationDetails: ReservationDetails | undefined,
  rules: Rule<ClientDetailsProfileRuleKeyword>[],
): DataCompletionTab[] =>
  Object.entries({
    [DataCompletionTab.parking]: checkParkingFilled(reservationDetails),
    [DataCompletionTab.guests]: checkGuestsFilled(reservationDetails),
    [DataCompletionTab.gdpr]: isEachRulesRequiredForReservationAccepted(reservationDetails?.rules || [], rules),
  })
    .filter(([, value]) => value)
    .map(([key]) => parseInt(key, 10))

interface Props {
  initialTab?: DataCompletionTab
  handleClose: () => void
}

export const ReservationDataCompletionModal = ({
  initialTab = DataCompletionTab.parking,
  handleClose,
}: Props): JSX.Element => {
  const rules = useSelector(selectClientProfileRules)
  const reservationDetails = useSelector(selectReservationDetails)

  const [activeTab, setActiveTab] = React.useState(initialTab)
  const completedTabs = getCompletedTabs(reservationDetails, rules)
  const { isMobile } = useDeviceSize()

  const isDataFilled = completedTabs.length === tabs.length

  const handleNextTab = () => {
    setActiveTab(activeTab < 3 ? activeTab + 1 : 1)
  }

  const handlePreviousTab = () => {
    setActiveTab(activeTab > 1 ? activeTab - 1 : 3)
  }

  return (
    <>
      <ModalBody className="modal-body pb-0 modal-scrollable">
        {reservationDetails && (
          <>
            <CompletionTabs tabs={tabs} activeTab={activeTab} onClick={setActiveTab} completedTabs={completedTabs} />
            {activeTab === DataCompletionTab.parking && (
              <ReservationDataCompletionParking reservationDetails={reservationDetails} />
            )}
            {activeTab === DataCompletionTab.guests && (
              <ReservationDataCompletionGuests reservationDetails={reservationDetails} onModalClose={handleClose} />
            )}
            {activeTab === DataCompletionTab.gdpr && (
              <ReservationDataCompletionGdpr reservationDetails={reservationDetails} />
            )}
          </>
        )}
      </ModalBody>
      {isMobile ? (
        <ModalFooterMobileNavigation
          onNext={activeTab !== DataCompletionTab.gdpr ? handleNextTab : undefined}
          onPrev={activeTab !== DataCompletionTab.parking ? handlePreviousTab : undefined}
        />
      ) : (
        <ModalFooterDesktop
          submitClassName="flex-row-reverse"
          submitLabel="Następny"
          cancelLabel="Poprzedni"
          cancelIcon="font-size-xl me-2 ms-4 uil-angle-left-b"
          onSubmit={handleNextTab}
          withCancelButton={activeTab !== DataCompletionTab.parking}
          withSubmit={activeTab !== DataCompletionTab.gdpr}
          onClose={handlePreviousTab}
          saveButtonVariant={isDataFilled ? TrapezeButtonVariant.green : TrapezeButtonVariant.secondary}
        />
      )}
    </>
  )
}
