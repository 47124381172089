import * as React from 'react'
import { motion } from 'framer-motion'

interface Props {
  tabs: string[]
  onTabChange: React.Dispatch<React.SetStateAction<string>>
  selectedTab: string
}

export const CouponListTabs = ({ tabs, selectedTab, onTabChange }: Props): JSX.Element => (
  <div className="row mx-3 mt-4">
    {tabs.map(tab => (
      <React.Fragment key={tab}>
        <button
          className="col-6 bg-transparent border-0 shadow-none text-white position-relative"
          onClick={() => onTabChange(tab)}
        >
          Kupony do {tab}
          {tab === selectedTab ? <motion.div className="coupons-view__tab-underline" layoutId="underline" /> : null}
          <div className="coupons-view__tab-underline__full" />
        </button>
      </React.Fragment>
    ))}
  </div>
)
