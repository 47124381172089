import { IconWithText } from '@components/icon-with-text'
import { LabeledRow } from '@components/labeled-row'
import * as React from 'react'
import { Button, Col, FormLabel } from 'react-bootstrap'
import { useModal } from '@components/modals/use-modal'
import { useSelector } from 'react-redux'
import { RootState } from '@store/index'

export const WalletBalance = (): JSX.Element => {
  const available_amount =
    useSelector((state: RootState) => state.clientState.details.client_wallet?.available_amount) || ''
  const formattedAmount = available_amount.split('.')

  const [handleTopUpModal] = useModal('TopUpBalanceModal', {
    title: 'Doładuj saldo',
  })

  const [handleWithdrawModal] = useModal('ClientWalletReturn', { title: 'Zwrot środków' })

  return (
    <LabeledRow hideLabelOnWrap className=" mb-4 mt-xl-0" label="Twoje saldo:">
      <FormLabel className="text-navy font-size-xl font-weight-semibold mb-0 mt-2 d-flex">Twoje saldo</FormLabel>
      <Col xl={12} xxl={9}>
        <div className="wallet__grey-box p-4 d-flex flex-column flex-md-row justify-content-between">
          <div className="fw-bold text-primary font-size-44" data-testid="wallet-balance">
            {formattedAmount[0]}
            <span className="font-size-22">,{formattedAmount[1]} zł</span>
          </div>
          <div className="d-flex flex-column align-items-md-center">
            <Button
              variant="danger"
              onClick={handleTopUpModal}
              className="py-3 px-5 d-flex justify-content-center w-100"
            >
              <IconWithText
                text="Doładuj saldo"
                iconClassName="uil-plus me-2 font-size-icon-18"
                className="font-size-14 fw-bold px-3"
              />
            </Button>
            <Button
              disabled={+available_amount <= 0}
              variant="transparent"
              onClick={handleWithdrawModal}
              className="py-3 px-5 d-flex justify-content-center w-100"
              data-testid="return-button"
            >
              <IconWithText
                text="Zwrot środków"
                iconClassName="uil-money-withdraw me-2 font-size-icon-18"
                className="font-size-14 fw-bold text-darker-gray"
              />
            </Button>
          </div>
        </div>
      </Col>
    </LabeledRow>
  )
}
