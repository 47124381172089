import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { ResortImprovement } from '@models/reservation'
import { VipPricesBlock } from '@modules/reservations/improvements/kinds/vip/vip-prices-block'
import { useVipModals } from '@modules/reservations/improvements/kinds/vip/use-vip-modals'
import { ImprovementCard } from '@modules/reservations/improvements/card/improvement-card'
import { isWinterVip } from '@helpers/improvement-helper'

interface Props {
  vipImprovement: ResortImprovement
}

export const Vip = ({ vipImprovement }: Props): JSX.Element => {
  const { showVipDetailsModal, showVipDetailsListModal } = useVipModals(vipImprovement)

  return (
    <ImprovementCard
      onRightActionClick={showVipDetailsModal}
      title={isWinterVip(vipImprovement) ? 'Zimowy pakiet ulepszeń VIP' : 'Pakiet ulepszeń VIP'}
      image={vipImprovement.image.sm}
      cardClassName="is-md-full-width"
      leftAction={
        <IconWithText
          text="Co dostaję w pakiecie?"
          iconClassName="uil-plus me-1"
          onClick={showVipDetailsListModal}
          className="text-darker-gray fw-bold"
        />
      }
    >
      <p className="font-size-sm"> {vipImprovement.description}</p>
      <VipPricesBlock improvement={vipImprovement} wrapperClassName="mt-xl-4" />
    </ImprovementCard>
  )
}
