import * as React from 'react'
import { useApiRequest } from '@hooks/use-api-request'
import { useDispatch, useSelector } from 'react-redux'
import { Reservation, ReservationDetails, ResortDetails } from '@models/reservation'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import { selectResortForReservationDetails } from '@store/selectors/app-data-selectors'
import { selectResortDetails } from '@store/selectors/reservations-selectors'
import { getResortDetails } from '@store/actions/reservations-action'
import { usePreviousImmediate } from 'rooks'
import { selectExpandedReservation } from '@store/slices/expanded-reservation-slice'

interface UseResortDetailsResponse {
  resortDetails: ResortDetails | undefined
  isLoading: boolean
  provideResortDetails: (details?: ReservationDetails) => void
}

export const useResortDetails = (reservation?: Reservation): UseResortDetailsResponse => {
  const dispatch = useDispatch()

  const resortDetails = useSelector(selectResortDetails)
  const reservationDetails = useSelector(selectReservationDetails)
  const resort = useSelector(selectResortForReservationDetails)

  const token = useSelector(selectExpandedReservation)

  const { action: fetchResortDetails, isLoading } = useApiRequest(async () => {
    if (reservationDetails && resort) {
      await dispatch(getResortDetails([resort.urls.details, reservationDetails.date_from, reservationDetails.date_to]))
    }
  })

  const previousDateFrom = usePreviousImmediate(reservationDetails?.date_from)
  const previousDateTo = usePreviousImmediate(reservationDetails?.date_to)

  React.useEffect(() => {
    provideResortDetails()
  }, [reservationDetails, previousDateFrom, previousDateTo, resort, token, resortDetails])

  const provideResortDetails = (newReservationDetails?: ReservationDetails) => {
    const details = newReservationDetails ?? reservationDetails

    if (
      (reservation && reservation?.token !== token) ||
      (isLoading &&
        resort?.id === details?.resort_id &&
        previousDateTo === details?.date_to &&
        previousDateFrom === details?.date_from)
    )
      return

    if (
      !resortDetails ||
      resortDetails.id !== details?.resort_id ||
      (token === details?.token && (previousDateTo !== details?.date_to || previousDateFrom !== details?.date_from))
    ) {
      fetchResortDetails()
    }
  }

  return { resortDetails, isLoading, provideResortDetails }
}
