import * as React from 'react'
import { PendingReservationsList } from '@modules/reservations/pending/list'
import { useSelector } from 'react-redux'
import { pendingReservationsSelector } from '@store/selectors/reservations'
import { NoUpcomingReservations } from '@modules/reservations/pending/no-upcoming-reservations'

export const PendingReservations = (): JSX.Element => {
  const reservations = useSelector(pendingReservationsSelector)

  return (
    <div className="card border-0 p-0 p-xl-3 mb-3">
      {reservations.length === 0 ? (
        <NoUpcomingReservations />
      ) : (
        <>
          <div className="text-primary font-size-20 fw-bold mb-3">Nadchodzące rezerwacje</div>
          <PendingReservationsList reservations={reservations} />
        </>
      )}
    </div>
  )
}
