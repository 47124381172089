import { CashbackVoucherAssignBooking, CashbackVoucherDetails } from '@models/client'
import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { formatPriceShort } from '@helpers/price-helper'
import { toDefaultDateFormat } from '@helpers/date-helper'
import { useRedirectToReservation } from '@modules/special-products/gastro-vouchers/use-redirect-to-reservation'

interface Props {
  voucherDetails: CashbackVoucherDetails
}

export const CashbackVoucherActiveExpandableContentRow = ({ voucherDetails }: Props) => {
  const { redirectToReservation } = useRedirectToReservation()

  const handleMoveToReservationDetails = (booking: CashbackVoucherAssignBooking) => () => {
    redirectToReservation({ reservation_number: booking.reservation_number })
  }

  return (
    <div className="ps-4 pb-4 col-12 col-sm-8 mt-2">
      <div className="d-flex">
        <IconWithText
          className="col-6"
          iconClassName="uil-bookmark me-2 pt-1 font-size-lg lh-1 align-self-start"
          text={
            <span className="fw-normal">
              Utworzony w rez.: <strong>{voucherDetails.source_booking}</strong>
            </span>
          }
        />
        <IconWithText
          className="col-6"
          iconClassName="uil-bookmark me-2 pt-1 font-size-lg lh-1 align-self-start"
          text={
            <span className="fw-normal d-flex">
              {voucherDetails.assign_booking ? (
                <>
                  Przypisany do rez.:{' '}
                  <strong className="ms-1">{voucherDetails.assign_booking.reservation_number}</strong>
                  <i
                    className="uil-external-link-alt ms-2 font-size-md cursor-pointer"
                    onClick={handleMoveToReservationDetails(voucherDetails.assign_booking)}
                  />
                </>
              ) : (
                'Brak przypisania'
              )}
            </span>
          }
        />
      </div>

      <div className="d-flex mt-1">
        <IconWithText
          className="col-6"
          iconClassName="uil-usd-circle me-2 pt-1 font-size-lg lh-1 align-self-start"
          text={
            <span className="fw-normal">
              Wartość vouchera: <strong>{formatPriceShort(voucherDetails.promotion_amount)}</strong>
            </span>
          }
        />
        <IconWithText
          className="col-6"
          iconClassName="uil-calender me-2 pt-1 font-size-lg lh-1 align-self-start"
          text={
            <span className="fw-normal">
              Ważny do: <strong>{toDefaultDateFormat(voucherDetails.expire_after)}</strong>
            </span>
          }
        />
      </div>
    </div>
  )
}
