import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import { useApiRequest } from '@hooks/use-api-request'
import { ImprovementsCardsSection } from '@modules/reservations/improvements/card/improvements-cards-section'
import { ImprovementsSummary } from '@modules/reservations/improvements/summary/improvements-summary'
import { setReservationDetails } from '@store/slices/reservations-slice'
import { Reservation, ReservationDetails } from '@models/reservation'
import { ContentLoader } from '@components/loaders/content-loader'
import { commonObjectGet } from '@api/basic-requests'
import { useResortDetails } from '@hooks/use-resort-details'
import { useDidUpdate } from 'rooks'

interface Props {
  reservation: Reservation
}

export const Improvements = ({ reservation }: Props): JSX.Element => {
  const dispatch = useDispatch()

  const reservationDetails = useSelector(selectReservationDetails)

  const { resortDetails, isLoading: isFetchingResortDetails } = useResortDetails()

  const { action: fetchReservationDetails, isLoading: isFetchingReservationDetails } = useApiRequest(async () => {
    if (reservationDetails?.reservation_number === reservation.reservation_number) return
    dispatch(setReservationDetails(await commonObjectGet<ReservationDetails>(reservation.urls.details)))
  })

  useDidUpdate(() => {
    fetchReservationDetails()
  }, [reservationDetails, reservation])

  return (
    <div>
      <ContentLoader isLoading={isFetchingReservationDetails || isFetchingResortDetails}>
        {resortDetails && reservationDetails && (
          <>
            <ImprovementsSummary reservationDetails={reservationDetails} />
            <ImprovementsCardsSection resortDetails={resortDetails} />
          </>
        )}
      </ContentLoader>
    </div>
  )
}
