import * as React from 'react'
import { ItemChangeConfirmationModalContent } from '@components/item-change-confirmation-modal-content'
import { useApiRequest } from '@hooks/use-api-request'
import { SubscriptionContract } from '@models/client'
import { TextWithSpinner } from '@components/text-with-spinner'
import { getPaymentRedirectUrl } from '@api/payment'
import * as clsx from 'clsx'

interface Props {
  subscriptionContract: SubscriptionContract
  onClose: () => void
  children?: React.ReactNode
  title: string
  subtitle: string
  submitText: string
  submitClassName?: string
}

export const SubscriptionContractPaymentCardChangeBaseContent = ({
  subscriptionContract,
  onClose,
  children,
  title,
  subtitle,
  submitText,
  submitClassName,
}: Props): JSX.Element => {
  const [isLoading, setIsLoading] = React.useState(false)

  const { action: handleConfirm } = useApiRequest(async () => {
    try {
      setIsLoading(true)
      document.location.href = await getPaymentRedirectUrl(subscriptionContract.urls.payment, {
        source: 'client-panel-app',
      })
    } catch {
      setIsLoading(false)
      onClose()
    }
  })

  return (
    <ItemChangeConfirmationModalContent
      text={title}
      underlineText={subtitle}
      customImage={
        <img
          src={require('@assets/images/payment-card-change-bg.webp')}
          alt="Zmień dane karty"
          className="my-2"
          height={240}
        />
      }
    >
      {children}

      <TextWithSpinner
        onClick={handleConfirm}
        isLoading={isLoading}
        text={submitText}
        loadingText="Proszę czekać..."
        spinnerColor="danger"
        iconClassName="uil-edit-alt font-size-xxl me-2"
        className={clsx('text-danger mt-3 fw-medium', submitClassName)}
      />
    </ItemChangeConfirmationModalContent>
  )
}
