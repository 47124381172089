import * as React from 'react'
import { Alert } from '@components/alert'

interface Props {
  content: string
}

export const FeedingAgesGroupChangedAlert = ({ content }: Props): JSX.Element => (
  <Alert variant="info" className="mb-4 py-2 align-items-center px-2 d-flex">
    <p className="text-muted mb-0">{content}</p>
  </Alert>
)
