import * as React from 'react'
import { useDeviceSize } from '@hooks/use-device-size'
import { OpenDoorNfcModalMobile } from '@modules/reservations/checked-in/open-door-with-nfc-modal/mobile'
import { OpenDoorNfcModalDesktop } from '@modules/reservations/checked-in/open-door-with-nfc-modal/desktop'

interface Props {
  handleClose: () => void
}

export const OpenDoorWithNfcModal = ({ handleClose }: Props): JSX.Element => {
  const { isMobile } = useDeviceSize()
  return isMobile ? (
    <OpenDoorNfcModalMobile handleClose={handleClose} />
  ) : (
    <OpenDoorNfcModalDesktop handleClose={handleClose} />
  )
}
