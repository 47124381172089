import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import { ModalFooter } from '@components/modals/modal-footer'
import { ClientWalletItem } from '@models/wallet'
import { WalletHistoryListWrapper } from '@modules/wallet/wallet-history/list-wrapper'

interface WalletHistoryModalProps {
  handleClose: () => void
  walletHistory: ClientWalletItem
}

export const WalletHistoryModal: React.FC<WalletHistoryModalProps> = ({ handleClose }) => (
  <>
    <ModalBody className="pb-4">
      <WalletHistoryListWrapper isFullList />
    </ModalBody>
    <ModalFooter submitLabel="Zamknij historię" onClose={handleClose} />
  </>
)
