import * as React from 'react'
import { formatPrice } from '@helpers/price-helper'
import { toDefaultDateFormat, toDefaultTimeFormat } from '@helpers/date-helper'
import { PurchasePaymentHistoryRow } from '@modules/reservations/purchase-history-modal/purchase-history-table-row'

export const PurchaseHistoryTableMobileRow = ({ row }: PurchasePaymentHistoryRow): JSX.Element => (
  <>
    <td className="reservation-payment-history__cell--first-column vertical-align-middle lh-lg ps-4">
      <strong>{toDefaultDateFormat(row.created_at)}</strong> ({toDefaultTimeFormat(row.created_at)}){' '}
      {row.source_display}
      <span className="d-block">
        <strong>{row.name}</strong> ({formatPrice(row.single_price_brutto)})
      </span>
    </td>
    <td className="reservation-payment-history__cell--quantity vertical-align-middle text-end lh-lg">
      x{row.quantity}
    </td>
    <td className="reservation-payment-history__cell--last-column vertical-align-middle text-end pe-4 lh-lg">
      <strong className="d-block">{row.guest_name}</strong>
      <strong>{formatPrice(row.price_brutto)}</strong>
    </td>
  </>
)
