import * as React from 'react'
import { Button } from 'react-bootstrap'
import { IconWithText } from '@components/icon-with-text'
import { useLogout } from '@hooks/use-logout'

export const TopbarLogout = (): JSX.Element => {
  const handleLogout = useLogout()

  return (
    <Button variant="outline-dark" onClick={handleLogout}>
      <IconWithText iconClassName="uil-sign-out-alt" className="flex-row-reverse" textClassName="me-1" text="Wyloguj" />
    </Button>
  )
}
