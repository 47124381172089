import * as React from 'react'
import { useApiRequest } from '@hooks/use-api-request'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getReservationByTokenDetails } from '@api/reservation'
import { resortTypeSelector } from '@store/selectors/reservations'
import { ContentLoader } from '@components/loaders/content-loader'
import { PendingReservationContentMobile } from '@modules/reservations/pending/mobile/pending-reservation-content-mobile'
import { CheckedInReservationContentMobile } from '@modules/reservations/pending/mobile/checked-in-reservation-content-mobile'
import { isCheckedInReservation, isPendingReservation } from '@helpers/reservation'
import { setReservationDetails } from '@store/slices/reservations-slice'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import { useResortDetails } from '@hooks/use-resort-details'

interface Params {
  token: string
}

export const ReservationDetailsMobileView = (): JSX.Element => {
  const { token } = useParams<Params>()
  const reservationDetails = useSelector(selectReservationDetails)

  const dispatch = useDispatch()

  const { action: fetchDetails, isLoading: isFetchingDetails } = useApiRequest(async () => {
    dispatch(setReservationDetails(await getReservationByTokenDetails(token)))
  })

  React.useEffect(() => {
    fetchDetails()
  }, [])

  const resort = useSelector(resortTypeSelector)(reservationDetails?.resort_id)
  const { isLoading: isFetchingResortDetails } = useResortDetails()

  return (
    <ContentLoader isLoading={isFetchingDetails || isFetchingResortDetails}>
      {reservationDetails && resort && isPendingReservation(reservationDetails) && (
        <PendingReservationContentMobile reservationDetails={reservationDetails} resort={resort} />
      )}
      {reservationDetails && resort && isCheckedInReservation(reservationDetails) && (
        <CheckedInReservationContentMobile reservationDetails={reservationDetails} resort={resort} />
      )}
    </ContentLoader>
  )
}
