import { useGtm } from '@hooks/use-gtm'
import { GTMEvents } from '@models/gtm'

interface Response {
  login: (userId: number) => void
  register: (userId: number) => void
}

export const useGtmEvents = (): Response => {
  const { sendEvent } = useGtm()

  const login = (userId: number) => {
    sendEvent({ event: GTMEvents.Login, userID: userId })
  }

  const register = (userId: number) => {
    sendEvent({ event: GTMEvents.Register, userID: userId })
  }

  return {
    login,
    register,
  }
}
