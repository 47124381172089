import * as React from 'react'
import { PriceWithSpinner } from '@components/price-with-spinner'

interface Props {
  text: string
  price: string | number
  isCalculating?: boolean
}

export const ImprovementSummaryModalPriceRow = ({ price, text, isCalculating }: Props): JSX.Element => (
  <div className="bg-light-blue border-top">
    <div className="p-3 px-xl-4 container-lg">
      <div className="d-flex justify-content-between text-primary align-items-center" role="price-summary">
        <strong className="d-block font-size-lg">{text}</strong>
        <PriceWithSpinner priceItemClassName="d-block font-size-xxl" price={price} isLoading={!!isCalculating} />
      </div>
    </div>
  </div>
)
