export const formatPrice = (value: number | string) =>
  Intl.NumberFormat('pl-PL', {
    style: 'currency',
    currency: 'PLN',
  }).format(typeof value === 'string' ? parseFloat(value) : value)

export const formatPriceShort = (value: number | string, removeCurrency = false): string => {
  const result = formatPrice(value).replace(',00', '')

  if (removeCurrency) {
    return result.replace(' zł', '')
  }

  return result
}
