import * as React from 'react'
import {
  ScrollableSectionTabs,
  ScrollableSectionTypes,
  ScrollableTab,
} from '@components/scrollable-section/scrollable-section-tabs'
import { ResortDetails } from '@models/reservation'
import { ScrollableSection } from '@components/scrollable-section/scrollable-section'
import { Vip } from '@modules/reservations/improvements/kinds/vip/vip'
import { Feeding } from '@modules/reservations/improvements/kinds/feedings/feeding'
import { BaseImprovements } from '@modules/reservations/improvements/kinds/base-improvements/base-improvements'
import { StayOptions } from '@modules/reservations/improvements/kinds/stay-options/stay-options'
import { useAvailableImprovements } from '@modules/reservations/improvements/use-available-improvements'
import { isFeedingAvailableInResort } from '@helpers/improvement-helper'

interface Props {
  resortDetails: ResortDetails
}

export const ImprovementsCardsSection = ({ resortDetails }: Props): JSX.Element => {
  const vipSectionRef = React.useRef<HTMLDivElement>(null)
  const feedingSectionRef = React.useRef<HTMLDivElement>(null)
  const improvementSectionRef = React.useRef<HTMLDivElement>(null)
  const stayOptionsSectionRef = React.useRef<HTMLDivElement>(null)

  const isFeedingAvailable = isFeedingAvailableInResort(resortDetails)

  const tabs = [
    { title: 'Dla wymagających', key: 'demanding', ref: vipSectionRef },
    ...(isFeedingAvailable ? [{ title: 'Wyżywienie', key: 'feeding', ref: feedingSectionRef }] : []),
    { title: 'Ulepszenia', key: 'improvements', ref: improvementSectionRef },
  ]

  const { vipImprovements, restImprovements, stayOptionsImprovements, reservationFeedingImprovements } =
    useAvailableImprovements(resortDetails.improvements)

  const spentItems = [
    !vipImprovements.length && 'demanding',
    !restImprovements.length && 'improvements',
    !stayOptionsImprovements.length && 'stay-options',
    reservationFeedingImprovements.length && 'feeding',
  ].filter(Boolean) as ScrollableSectionTypes[]

  return (
    <div className="scrollable-section">
      <ScrollableSectionTabs tabs={tabs as ScrollableTab[]} crossedItems={spentItems} />
      <div className="mt-4 mb-xl-0 mb-4 d-flex flex-column pb-xl-3">
        {!!vipImprovements.length && (
          <ScrollableSection title="Dla wymagających:" ref={vipSectionRef}>
            <Vip vipImprovement={vipImprovements[0]} />
          </ScrollableSection>
        )}
        {!reservationFeedingImprovements.length && isFeedingAvailable && (
          <ScrollableSection title="Wyżywienie:" ref={feedingSectionRef}>
            <Feeding />
          </ScrollableSection>
        )}
        {!!restImprovements.length && (
          <ScrollableSection title="Wybierz ulepszenia:" ref={improvementSectionRef}>
            <BaseImprovements resortImprovements={restImprovements} />
          </ScrollableSection>
        )}
        {!!stayOptionsImprovements.length && (
          <ScrollableSection title="Opcje pobytu:" ref={stayOptionsSectionRef}>
            <StayOptions resortImprovements={stayOptionsImprovements} />
          </ScrollableSection>
        )}
      </div>
    </div>
  )
}
