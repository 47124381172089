import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import {
  BookingGuestAgreement,
  Reservation,
  ReservationDetails,
  ReservationRefund,
  ResortDetails,
} from '@models/reservation'
import {
  getReservationBookingGuestAgreement,
  getReservationRefundDetails,
  getReservations,
  getResortDetails,
} from '@store/actions/reservations-action'
import { RootState } from '@store/index'

export interface ResortsState {
  reservations: EntityState<Reservation>
  resortDetails: ResortDetails | undefined
  reservationDetails: ReservationDetails | undefined
  reservationRefund: ReservationRefund | undefined
  bookingGuestAgreement: BookingGuestAgreement | undefined
  reservationFetched: boolean
}

const reservationsAdapter = createEntityAdapter<Reservation>({ selectId: reservation => reservation.token })

const initialState: ResortsState = {
  resortDetails: undefined,
  bookingGuestAgreement: undefined,
  reservationRefund: undefined,
  reservationDetails: undefined,
  reservations: reservationsAdapter.getInitialState(),
  reservationFetched: false,
}

export const reservationsSlice = createSlice({
  name: 'resort',
  initialState,
  reducers: {
    setReservationDetails(state, action: PayloadAction<ReservationDetails | undefined>) {
      state.reservationDetails = action.payload
    },
    setResortDetails(state, action: PayloadAction<ResortDetails>) {
      state.resortDetails = action.payload
    },
    updateReservationDetails(state, action: PayloadAction<ReservationDetails>) {
      if (state.reservationDetails?.id === action.payload.id) {
        state.reservationDetails = action.payload
      }
      reservationsAdapter.upsertOne(state.reservations, action.payload)
    },
    updateReservationRefund(state, action: PayloadAction<ReservationRefund>) {
      if (state.reservationRefund?.id === action.payload.id) {
        state.reservationRefund = action.payload
      }
    },
    updateReservationBookingGuestAgreement(state, action: PayloadAction<BookingGuestAgreement>) {
      if (state.bookingGuestAgreement?.id === action.payload.id) {
        state.bookingGuestAgreement = action.payload
      }
    },
  },
  extraReducers: builder => {
    builder.addCase(getReservationBookingGuestAgreement.fulfilled, (state, action) => {
      state.bookingGuestAgreement = action.payload
    })
    builder.addCase(getReservationRefundDetails.fulfilled, (state, action) => {
      state.reservationRefund = action.payload
    })
    builder.addCase(getResortDetails.fulfilled, (state, action) => {
      state.resortDetails = action.payload
    })
    builder.addCase(getReservations.fulfilled, (state, action) => {
      reservationsAdapter.setAll(state.reservations, action.payload)
      state.reservationFetched = true
    })
  },
})

export const {
  updateReservationDetails,
  setReservationDetails,
  setResortDetails,
  updateReservationRefund,
  updateReservationBookingGuestAgreement,
} = reservationsSlice.actions

export const { selectAll: reservationsSelector, selectById: selectReservationByToken } =
  reservationsAdapter.getSelectors((state: RootState) => state.reservationsState.reservations)

export const reservationRefundSelector = (state: RootState) => state.reservationsState.reservationRefund
export const bookingGuestAgreementSelector = (state: RootState) => state.reservationsState.bookingGuestAgreement
export const selectReservationsAreFetched = (state: RootState) => state.reservationsState.reservationFetched

export default reservationsSlice.reducer
