import * as React from 'react'
import { WalletHistoryFetchListWrapper } from '@modules/wallet/wallet-history/wallet-history-fetch-list-wrapper'
import { WalletHistoryFilter } from '@modules/wallet/wallet-history/filter'
import { WalletHistoryList } from '@modules/wallet/wallet-history/list'

interface WalletHistoryListWrapperProps {
  isFullList: boolean
  handleFullList?: (params: boolean) => void
}

export const WalletHistoryListWrapper = ({
  isFullList,
  handleFullList,
}: WalletHistoryListWrapperProps): JSX.Element => (
  <WalletHistoryFetchListWrapper>
    {({ filters, setFilters, walletHistory, defaultFilters }) => (
      <>
        <WalletHistoryFilter
          isFull={isFullList}
          defaultFilters={defaultFilters}
          setFilters={setFilters}
          filters={filters}
        />
        <WalletHistoryList results={walletHistory.results} isFullList={isFullList} setIsFullList={handleFullList} />
      </>
    )}
  </WalletHistoryFetchListWrapper>
)
