import * as React from 'react'
import { FormControlProps } from 'react-bootstrap'
import { FormLabelProps } from 'react-bootstrap/FormLabel'
import { FormGroupProps } from 'react-bootstrap/FormGroup'
import { FormControlWithLabel } from '@components/form-controls/form-control-with-label'
import { CustomFormControl } from '@components/form-controls/form-control'
import { MaskedFormControl } from './masked-form-control'

export interface CustomFormGroupProps {
  label?: string
  inputName: string
  formControlProps?: FormControlProps
  formLabelProps?: FormLabelProps
  formGroupProps?: FormGroupProps
  isLoading?: boolean
  isSucceed?: boolean
  isFailed?: boolean
  inputSuffix?: React.ReactNode
  formatOptions?: {
    format: string
    mask?: string
    allowEmptyFormatting?: boolean
  }
}

export const CustomFormGroup = ({
  label,
  inputName,
  formControlProps,
  formLabelProps,
  formGroupProps,
  isLoading,
  isSucceed,
  isFailed,
  inputSuffix,
  formatOptions,
}: CustomFormGroupProps): React.ReactElement => (
  <FormControlWithLabel
    inputName={inputName}
    label={label}
    formLabelProps={formLabelProps}
    formGroupProps={formGroupProps}
    isLoading={isLoading}
    isSucceed={isSucceed}
    isFailed={isFailed}
    inputSuffix={inputSuffix}
  >
    {formatOptions ? (
      <MaskedFormControl name={inputName} className={formControlProps?.className} {...formatOptions} />
    ) : (
      <CustomFormControl inputName={inputName} formControlProps={formControlProps} />
    )}
  </FormControlWithLabel>
)
