import * as React from 'react'
import { LabeledRow } from '@components/labeled-row'

interface Props {
  navyTitle?: boolean
  children: React.ReactNode
}

export const GdprData = ({ navyTitle, children }: Props): JSX.Element => (
  <>
    {navyTitle ? (
      <div className="text-darker-gray font-size-xxl fw-bold">Uzupełnij brakujące zgody</div>
    ) : (
      <LabeledRow
        className="align-items-center"
        label={<div className="text-primary font-size-20 fw-bold">Wyrażone zgody</div>}
      />
    )}
    {children}
  </>
)
