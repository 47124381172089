import { ResortDetails } from '@models/reservation'
import { useModal } from '@components/modals/use-modal'

export const useFeeding = (resortDetails: ResortDetails | undefined) => {
  const [showFeedingDetailsModal] = useModal('FeedingDetailsModal', { title: 'Edytuj wyżywienie' })

  return {
    isFeedingAvailable: resortDetails
      ? Object.values(resortDetails.feedings).some(feeding => !!Object.values(feeding).length)
      : false,

    showFeedingDetailsModal,
  }
}
