import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { formatPrice } from '@helpers/price-helper'
import { GastroCardSelectOption } from '@models/reservation'

interface Props {
  gastroCard: GastroCardSelectOption
}

export const CashLeftOnVoucher = ({ gastroCard }: Props): JSX.Element => (
  <IconWithText
    className="text-muted align-items-center"
    textClassName="font-size-xs"
    iconClassName="uil-pricetag-alt me-2 font-size-icon-18"
    text={
      <>
        Do wykorzystania:
        <strong className="ms-1">{formatPrice(gastroCard.available_amount)}</strong>
        <span className="mx-1">z</span>
        {formatPrice(gastroCard.start_amount)}
      </>
    }
  />
)
