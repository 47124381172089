import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import { Feeding, FeedingTypes, ReservationDetails } from '@models/reservation'
import { setReservationDetails } from '@store/slices/reservations-slice'

const createId = (improvement: InitialFeedingImprovement) => {
  const base = `${improvement.code}--${improvement.reservation}`
  return improvement.guest_id ? `${base}--${improvement.guest_id}` : base
}

const createInitialFeedingImprovement = (items: Feeding[], reservationToken: string) =>
  items.map(item => ({
    code: item.code,
    amount: item.amount,
    reservation: reservationToken,
    guest_id: item.guest_id,
  }))

export interface InitialFeedingImprovement {
  reservation: string
  code: FeedingTypes
  guest_id?: number
  amount: number
}

export interface InitialFeedingImprovementsState {
  feedingImprovements: EntityState<InitialFeedingImprovement>
  initializedTokens: string[]
}

const feedingImprovementsAdapter = createEntityAdapter<InitialFeedingImprovement>({
  selectId: createId,
})

const initialState: InitialFeedingImprovementsState = {
  feedingImprovements: feedingImprovementsAdapter.getInitialState(),
  initializedTokens: [],
}

export const initialFeedingsImprovementSlice = createSlice({
  name: 'initialFeedingImprovements',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setReservationDetails, (state, action: PayloadAction<ReservationDetails | undefined>) => {
      if (!action.payload) return

      const isInitialized = state.initializedTokens.some(token => token === action.payload?.token)
      if (isInitialized) return

      feedingImprovementsAdapter.addMany(state.feedingImprovements, [
        ...createInitialFeedingImprovement(action.payload.prices.feeding.items, action.payload.token),
      ])

      state.initializedTokens.push(action.payload.token)
    })
  },
})

export const { selectAll: selectInitialFeedingReservationImprovements } = feedingImprovementsAdapter.getSelectors(
  (state: RootState) => state.initialFeedingsImprovementState.feedingImprovements,
)

export default initialFeedingsImprovementSlice.reducer
