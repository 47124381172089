import * as React from 'react'
import * as clsx from 'clsx'
import { ReservationReceiptModalRow } from '@modules/reservations/modals/reservation-receipt-modal-row'
import { formatPrice } from '@helpers/price-helper'
import { IconWithText } from '@components/icon-with-text'
import { ReservationReceiptModalHeader } from '@modules/reservations/modals/reservation-receipt-modal-header'
import { ReservationReceiptModalBox } from '@modules/reservations/modals/reservation-receipt-modal-box'
import { BillPayment, ReservationBill } from '@models/bill'
import Decimal from 'decimal.js'

interface Props {
  sectionNumber: number
  bill: ReservationBill
  children: React.ReactNode
}

export const ReservationReceiptModalPaymentSummary = ({ sectionNumber, bill, children }: Props): JSX.Element => {
  const isPayback = new Decimal(bill.summary.balance).gte(0)
  const amount = new Decimal(bill.summary.balance).absoluteValue().toString()

  return (
    <div className="receipt-box">
      <div className="font-size-xl fw-bold text-muted mt-3 mb-2 px-2">{sectionNumber}. Podsumowanie płatności</div>
      <ReservationReceiptModalHeader />
      <ReservationReceiptModalBox
        title="Pobyt wpłaty"
        total={bill.payments_without_deposit.total_amount}
        variant="positive"
      >
        {bill.payments_without_deposit.items.map((payment: BillPayment) => (
          <ReservationReceiptModalRow
            key={payment.id}
            titleColumn={`${payment.name} (${payment.source})`}
            dateColumn={payment.date}
            priceColumn={payment.amount}
            indent
          />
        ))}
      </ReservationReceiptModalBox>

      <ReservationReceiptModalRow
        titleColumn="Pobyt należności"
        priceColumn={bill.summary.stay_charge}
        variant="negative"
        className="border-top-0"
      />

      <ReservationReceiptModalBox title="Kaucja wpłaty" total={bill.deposit_payments.total_amount} variant="positive">
        {bill.deposit_payments.items.map((payment: BillPayment) => (
          <ReservationReceiptModalRow
            key={payment.id}
            titleColumn={`${payment.name} (${payment.source})`}
            dateColumn={payment.date}
            priceColumn={payment.amount}
            indent
          />
        ))}
      </ReservationReceiptModalBox>
      <ReservationReceiptModalRow
        titleColumn="Kaucja wykorzystana"
        priceColumn={bill.summary.deposit_charge}
        variant="negative"
      />
      <div className="d-flex justify-content-between border-bottom border-top border-dark p-3 lh-1">
        <div className="font-size-lg fw-bold text-muted">{isPayback ? 'Pozostałe środki' : 'Pozostało do zapłaty'}</div>
        <div className={clsx('font-size-lg fw-bold', isPayback ? 'text-success' : 'text-danger')}>
          {formatPrice(amount)}
        </div>
      </div>
      <div className="text-end">
        <IconWithText
          text="Niewykorzystane środki zostaną zwrócone do 14 dni."
          iconClassName="uil-info-circle font-size-icon-18 ms-2 me-2 me-xl-0 text-primary"
          textClassName="text-lighter-gray"
          className="font-size-sm d-flex flex-xl-row-reverse mt-3"
        />

        {children}

        {/*<IconWithText*/}
        {/*  text={*/}
        {/*    <div className="d-flex flex-column">*/}
        {/*      <div>*/}
        {/*        Kwota końcowa uwzględnia rabaty w kwocie 600 zł, w skład których wchodzą Kod rabatowy{' '}*/}
        {/*        <b>POL-8GIVF3 (-100 zł)</b>,*/}
        {/*      </div>*/}
        {/*      <div>*/}
        {/*        <b>Bon turystyczny (-500 zł)</b> oraz <b>Rabat -8%</b> za płatność całości kwoty (-352 zł)*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  }*/}
        {/*  textClassName="text-lighter-gray"*/}
        {/*  iconClassName="uil-info-circle font-size-icon-18 ms-2 text-primary align-self-start"*/}
        {/*  className="font-size-sm d-flex flex-row-reverse my-3"*/}
        {/*/>*/}
      </div>
    </div>
  )
}
