import * as React from 'react'
import { ClientWalletItem } from '@models/wallet'
import { WalletHistoryListRow } from '@modules/wallet/wallet-history/list-row'
import { LoadMoreList } from '@components/load-more-list'
import { IconWithText } from '@components/icon-with-text'
import * as clsx from 'clsx'

interface WalletHistoryListProps {
  results: ClientWalletItem[]
  isFullList: boolean
  setIsFullList?: (params: boolean) => void
}

export const WalletHistoryList = ({ results, isFullList, setIsFullList }: WalletHistoryListProps): JSX.Element => (
  <LoadMoreList
    limit={isFullList ? 10 : 3}
    options={results}
    className="client-history-list"
    emptyListLabel="Brak transakcji w wybranym zakresie"
    customShowMoreLabel="Pokaż starsze transakcje"
    emptyListLabelClassNames="text-darker-gray font-size-11"
    customLabel={
      !isFullList && (
        <IconWithText
          className="text-darker-gray fw-bold mt-3 user-select-none"
          text="Pokaż starsze transakcje"
          iconClassName={clsx('me-1 font-size-icon-18', {
            'uil-plus': isFullList,
            'uil-invoice': !isFullList,
          })}
          onClick={() => setIsFullList?.(!isFullList)}
        />
      )
    }
  >
    {row => <WalletHistoryListRow {...row} key={row.id} />}
  </LoadMoreList>
)
