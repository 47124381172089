import * as React from 'react'
import { UserRulesContent } from '@modules/user/gdpr-data/user-rules-content'
import { FormProvider, useForm } from 'react-hook-form'
import { GdprData } from '@modules/user/gdpr-data/index'
import { ClientDetails } from '@models/client'
import { getRuleValues } from '@modules/reservations/pending/utils'
import { SkipServiceChargeAlert } from '@modules/user/gdpr-data/alerts/skip-service-charge-alert'

interface Props {
  clientDetails: ClientDetails
}

export const UserRules = ({ clientDetails }: Props): JSX.Element => {
  const methods = useForm({ defaultValues: getRuleValues(clientDetails.profile.rules, 'user-rules') })

  React.useEffect(() => {
    methods.reset(getRuleValues(clientDetails.profile.rules, 'user-rules'))
  }, [clientDetails])

  return (
    <FormProvider {...methods}>
      <GdprData>
        {clientDetails.profile.skip_service_charge && <SkipServiceChargeAlert />}
        <UserRulesContent clientDetails={clientDetails} context="user-rules" />
      </GdprData>
    </FormProvider>
  )
}
