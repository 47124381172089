import * as React from 'react'
import { FormLabelProps } from 'react-bootstrap/FormLabel'
import { FormGroupProps } from 'react-bootstrap/FormGroup'
import { FormControlWithLabel } from '@components/form-controls/form-control-with-label'
import { CustomFormControl } from './form-control'
import { FormControlProps } from 'react-bootstrap'

export interface CustomFormDatePickerProps {
  label?: string
  inputName: string
  formControlProps?: FormControlProps
  formLabelProps?: FormLabelProps
  formGroupProps?: FormGroupProps
  isLoading?: boolean
  isSucceed?: boolean
  isFailed?: boolean
  isDisabled?: boolean
  inputSuffix?: React.ReactNode
  min?: string
  max?: string
}

export const CustomFormDatePicker = ({
  label,
  inputName,
  formControlProps,
  formLabelProps,
  formGroupProps,
  isLoading,
  isSucceed,
  isFailed,
  inputSuffix,
  min,
  max,
  isDisabled,
}: CustomFormDatePickerProps): React.ReactElement => (
  <FormControlWithLabel
    inputName={inputName}
    label={label}
    formLabelProps={formLabelProps}
    formGroupProps={formGroupProps}
    isLoading={isLoading}
    isSucceed={isSucceed}
    isFailed={isFailed}
    inputSuffix={inputSuffix}
  >
    <CustomFormControl
      inputName={inputName}
      formControlProps={{ ...formControlProps, type: 'date', min, max, disabled: isDisabled }}
    />
  </FormControlWithLabel>
)
