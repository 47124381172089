import * as React from 'react'
import { ModalBody } from 'react-bootstrap'
import { ModalFooter } from '@components/modals/modal-footer'
import { TrapezeButtonVariant } from '@components/controls/trapeze-button'
import { PendingReservationPaymentState } from '@modules/reservations/pending/payment/state'
import { usePaymentModal } from '@hooks/use-payment-modal'
import Decimal from 'decimal.js'
import { PaymentTarget } from '@modules/reservations/pending/payment/modal/payment-target'
import { RulesNotAcceptedWarning } from '@modules/reservations/pending/payment/rules-not-accepted-warning'
import { useSelector } from 'react-redux'
import { selectClientProfileRules } from '@store/selectors/client-selectors'
import { isEachRulesRequiredForReservationAccepted } from '@modules/reservations/pending/utils'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'

const getPaymentTarget = (restToPay: Decimal, restToPayDeposit: Decimal) => {
  if (restToPay.gt(0) && restToPayDeposit.gt(0)) {
    return PaymentTarget.stayAndDeposit
  }
  if (restToPay.gt(0)) {
    return PaymentTarget.stay
  }
  if (restToPayDeposit.gt(0)) {
    return PaymentTarget.deposit
  }

  return ''
}

interface Props {
  handleClose: () => void
}

export const PendingReservationNotPaidModal = ({ handleClose }: Props): JSX.Element => {
  const reservationDetails = useSelector(selectReservationDetails)
  const clientProfileRules = useSelector(selectClientProfileRules)

  const isEachRuleAccepted = isEachRulesRequiredForReservationAccepted(
    reservationDetails?.rules || [],
    clientProfileRules,
  )

  const restToPay = new Decimal(reservationDetails?.prices?.payments_summary?.rest?.installment || 0).add(
    reservationDetails?.prices?.payments_summary?.required?.installment || 0,
  )
  const restToPayDeposit = new Decimal(reservationDetails?.prices?.payments_summary?.rest?.deposit || 0).add(
    reservationDetails?.prices?.payments_summary?.required.deposit || 0,
  )

  const target = getPaymentTarget(restToPay, restToPayDeposit)
  const amount = restToPay.plus(restToPayDeposit).toNumber()

  const showPaymentModal = usePaymentModal({
    paymentUrl: reservationDetails?.urls?.payment || '',
    payload: {
      target,
      amount,
      source: 'client-panel-app-reservation',
    },
  })

  const handleSubmit = () => {
    handleClose()
    showPaymentModal()
  }

  const payments = React.useMemo(
    () => ({
      installment: [
        {
          deadline: reservationDetails?.prices?.payments_summary?.rest.required_date || null,
          remaining: reservationDetails?.prices?.payments_summary?.rest.installment || '0',
        },
        {
          deadline: reservationDetails?.prices?.payments_summary?.required?.required_date || null,
          remaining: reservationDetails?.prices?.payments_summary?.required?.installment || '0',
        },
      ],
      deposit: [
        {
          deadline: reservationDetails?.prices?.payments_summary?.rest?.required_date_deposit || null,
          remaining: reservationDetails?.prices?.payments_summary?.rest?.deposit || '0',
        },
        {
          deadline: reservationDetails?.prices?.payments_summary?.rest?.required_date_deposit || null,
          remaining: reservationDetails?.prices?.payments_summary?.required?.deposit || '0',
        },
      ],
    }),
    [reservationDetails?.prices?.payments_summary],
  )

  return (
    <>
      <ModalBody>
        <div className="text-muted">
          <p className="fw-bold">Brak wszystkich niezbędnych wpłat.</p>
          <p>Aby skorzystać z opcji szybszego meldowania on-line musisz mieć uregulowane wszystkie płatności.</p>
          <p className="fw-bold">Brakujące płatności:</p>
          <PendingReservationPaymentState
            title="Opłata za pobyt"
            total={reservationDetails?.prices?.stay_charge_without_climatic || '0'}
            payments={payments.installment}
            paid={reservationDetails?.prices?.payments_summary?.paid?.installment || '0'}
          />
          <PendingReservationPaymentState
            title="Kaucja"
            total={reservationDetails?.prices?.deposit_amount || '0'}
            payments={payments.deposit || 0}
            paid={reservationDetails?.prices?.payments_summary?.paid?.deposit || '0'}
            className="mt-3"
          />
          {!isEachRuleAccepted && <RulesNotAcceptedWarning />}
        </div>
      </ModalBody>
      <ModalFooter
        submitLabel="Opłać teraz"
        onSubmit={handleSubmit}
        withCancelButton={true}
        onClose={handleClose}
        buttonDisabled={!isEachRuleAccepted}
        saveButtonVariant={TrapezeButtonVariant.red}
      />
    </>
  )
}
