import { ReservationDetailsMobileRow } from '@modules/reservations/pending/mobile/reservation-details-mobile-row'
import { FormattedArrivalDate } from '@modules/reservations/pending/mobile/formatted-arrival-date'
import { FormattedDepartureDate } from '@modules/reservations/pending/mobile/formatted-departure-date'
import * as React from 'react'
import { ReservationDetails } from '@models/reservation'
import { Resort } from '@models/app-data'
import { useCopy } from '@hooks/use-copy'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  reservationDetails: ReservationDetails
  resort: Resort
}

export const ReservationDetailsMobileBasicInfo = ({ reservationDetails, resort }: Props): JSX.Element => {
  const { copy, Copiable } = useCopy()

  return (
    <>
      <ReservationDetailsMobileRow icon="uil-arrow-down-right" title="Przyjazd">
        <FormattedArrivalDate reservationDetails={reservationDetails} />
      </ReservationDetailsMobileRow>
      <div className="icon-connector" />
      <ReservationDetailsMobileRow icon="uil-arrow-up-right" title="Wyjazd">
        <FormattedDepartureDate reservationDetails={reservationDetails} />
      </ReservationDetailsMobileRow>
      <ReservationDetailsMobileRow icon="uil-bookmark" title="Numer rezerwacji">
        <Copiable copyText="Numer skopiowany" className="is-reservation-number">
          <IconWithText
            onIconClick={() => copy(reservationDetails.reservation_number)}
            text={reservationDetails.reservation_number}
            iconClassName="uil-copy font-size-icon-18 me-2"
            textClassName="font-size-md fw-bold"
          />
        </Copiable>
      </ReservationDetailsMobileRow>
      <ReservationDetailsMobileRow icon="uil-location-point" title="Wybrana lokalizacja">
        {resort.name}
      </ReservationDetailsMobileRow>
      <ReservationDetailsMobileRow icon="uil-pathfinder-unite" title="Rodzaj lokalu">
        {reservationDetails.accommodation_type_name}
      </ReservationDetailsMobileRow>
      {(reservationDetails.checked_in || reservationDetails.selected_apartment_name) && (
        <ReservationDetailsMobileRow icon="uil-estate" title="Wybrany lokal">
          {reservationDetails.apartment_name}
        </ReservationDetailsMobileRow>
      )}
    </>
  )
}
