import { ReservationDetails } from '@models/reservation'
import Decimal from 'decimal.js'
import { usePaymentModal } from '@hooks/use-payment-modal'
import { useApiRequest } from '@hooks/use-api-request'
import { commonObjectPost } from '@api/basic-requests'
import { NavigationPath } from '@models/routes'
import { useHistory } from 'react-router'
import { useBillData } from '@hooks/use-bill-data'
import { getReservations } from '@store/actions/reservations-action'
import { useDispatch } from 'react-redux'
import { CheckOutFormInputs } from '@modules/reservations/check-out/check-out-modal'

export const useCheckOut = (reservationDetails: ReservationDetails | null) => {
  const { bill, isLoading: isFetchingBillData, refetchBill } = useBillData(reservationDetails)

  const { push } = useHistory()
  const dispatch = useDispatch()

  const isPaid = (bill && new Decimal(bill.summary.balance).gte(0)) || false

  const handlePayment = usePaymentModal({
    paymentUrl: reservationDetails?.urls?.payment || '',
    payload: {
      amount: new Decimal(bill?.summary?.balance ?? '0').absoluteValue().toString(),
      target: 'booking_advance_with_climatic',
      source: 'client-panel-app-check-out',
    },
  })

  const showPaymentModal = async () => {
    handlePayment()
  }

  const { action: finishCheckOut, isLoading: isCheckingOut } = useApiRequest(async (params: CheckOutFormInputs) => {
    if (!reservationDetails) return

    await commonObjectPost<ReservationDetails>(reservationDetails.urls.online_checkout, params)
    dispatch(getReservations())
    push(NavigationPath.Reservations)
  })

  return {
    isPaid,
    showPaymentModal,
    finishCheckOut,
    isLoading: isCheckingOut,
    bill,
    isFetchingBillData,
    refetchBill,
  }
}
