import * as React from 'react'

interface Props {
  email: string
}

export const AccountCreateSuccess = ({ email }: Props): JSX.Element => (
  <>
    <h4 className="text-secondary fw-bold text-center mt-5 mb-2">Twoje konto zostało utworzone</h4>
    <p className="mb-4 mt-3 text-center font-size-12">Wejdź na adres {email} aby je potwierdzić.</p>
  </>
)
