import * as React from 'react'
import { Modal } from 'react-bootstrap'
import { Resort } from '@models/app-data'
import { ContentLoader } from '@components/loaders/content-loader'
import svgPanZoom from 'svg-pan-zoom'
import { useApiRequest } from '@hooks/use-api-request'
import { commonObjectGet } from '@api/basic-requests'

type LoadingState = 'pending' | 'succeed' | 'failed'

interface Props {
  resort: Resort
}

export const ResortMapModal = ({ resort }: Props): JSX.Element => {
  const [loadingState, setLoadingState] = React.useState<LoadingState>('pending')
  const [panZoom, setPanZoom] = React.useState<SvgPanZoom.Instance | null>(null)
  const [map, setMap] = React.useState(null)
  const [mapHeight, setMapHeight] = React.useState<null | number>(null)

  const handleError = () => {
    setLoadingState('failed')
  }

  const handleLoad = (event: React.ChangeEvent<HTMLObjectElement>) => {
    setMapHeight(event.target.scrollHeight)

    function beforePan(oldPan, newPan): SvgPanZoom.PointModifier {
      const clientWidth = event.target.scrollWidth,
        clientHeight = event.target.scrollHeight,
        sizes = this.getSizes(),
        leftLimit = -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) + clientWidth,
        rightLimit = sizes.width - clientWidth - sizes.viewBox.x * sizes.realZoom,
        topLimit = -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) + clientHeight,
        bottomLimit = sizes.height - clientHeight - sizes.viewBox.y * sizes.realZoom

      return {
        x: Math.max(leftLimit, Math.min(rightLimit, newPan.x)),
        y: Math.max(topLimit, Math.min(bottomLimit, Math.min(newPan.y, 0))),
      }
    }

    const initializedPanZoom = svgPanZoom('#panned-map', {
      beforePan,
      zoomScaleSensitivity: 0.4,
      mouseWheelZoomEnabled: false,
      dblClickZoomEnabled: false,
      preventMouseEventsDefault: false,
      minZoom: 1,
    })

    setPanZoom(initializedPanZoom)
    setLoadingState('succeed')
  }

  const handleZoomIn = () => {
    panZoom?.zoomIn()
  }

  const handleZoomOut = () => {
    panZoom?.zoomOut()
  }

  const { action } = useApiRequest(async () => {
    setMap(await commonObjectGet(resort.apartments_map))
  }, handleError)

  React.useEffect(() => {
    action()
  }, [])

  return (
    <Modal.Body className="position-static">
      <div className="d-flex flex-column justify-content-center">
        <ContentLoader isLoading={loadingState === 'pending'} className="position-static">
          {map && (
            <div>
              <object
                data={resort.apartments_map}
                onLoad={handleLoad}
                id="panned-map"
                className="object-fit-cover"
                type="image/svg+xml"
                width="100%"
                height={mapHeight || 'auto'}
                data-testid="resort-map-preview"
              />

              <div className="checked-in-reservation-map-modal__zoom-buttons__wrapper bg-light">
                <button className="btn btn-light shadow-none" onClick={handleZoomIn}>
                  <i className="uil-plus" />
                </button>
                <hr className="checked-in-reservation-map-modal__zoom-buttons__spacer" />
                <button className="btn btn-light shadow-none" onClick={handleZoomOut}>
                  <i className="uil-minus" />
                </button>
              </div>
            </div>
          )}
          {loadingState === 'failed' && (
            <p className="checked-in-reservation-map-modal preview-unavailable">
              Przepraszamy nie udało się pobrać mapy ośrodka.
            </p>
          )}
        </ContentLoader>
      </div>
    </Modal.Body>
  )
}
