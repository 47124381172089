import * as React from 'react'
import { ReservationDataCompletionAddGuestForm } from '@modules/reservations/pending/data-completion-modal/guests/add-guest-form'
import { ReservationDataCompletionAddGuestList } from './add-guest-list'
import { ReservationDetails } from '@models/reservation'
import { FormProvider, useForm } from 'react-hook-form'
import { ClientProfileGuest } from '@models/client'
import { useApiRequest, useFormRequest } from '@hooks/use-api-request'
import { AxiosError } from 'axios'
import { ReactSelectOption } from '@components/custom-react-select'
import { BackdropLoader } from '@components/loaders/backdrop-loader'
import { addReservationGuest, AddReservationGuestPayload } from '@api/reservation'
import { IconWithText } from '@components/icon-with-text'
import { useDispatch } from 'react-redux'
import { setReservationDetails } from '@store/slices/reservations-slice'
import { guestTypeOptions } from '@modules/user/guests/modal'

interface FormInputs {
  name: string
  type: ReactSelectOption | null
  birthday?: string
}

interface Props {
  reservationDetails: ReservationDetails
  onClose: () => void
}
export const ReservationDataCompletionAddGuest = ({ reservationDetails, onClose }: Props): JSX.Element => {
  const [listError, setListError] = React.useState<string | null>(null)
  const methods = useForm<FormInputs>({
    defaultValues: {
      name: '',
      type: guestTypeOptions[0],
      birthday: '',
    },
  })

  const dispatch = useDispatch()

  const addReservationGuestAndUpdate = (onSuccess: () => void) => async (payload: AddReservationGuestPayload) => {
    dispatch(setReservationDetails(await addReservationGuest(reservationDetails.urls.guests, payload)))
    onSuccess()
  }

  const handleListError = (error: AxiosError<{ type: string[] }>) => {
    if (error.response?.status === 400 && error.response.data.type) {
      setListError(error.response.data.type[0])
    } else {
      setListError('Błąd serwera')
    }
  }

  const { isLoading: isSubmitting, action: submitGuest } = useFormRequest(
    addReservationGuestAndUpdate(() => methods.reset()),
    methods.setError,
  )

  const { isLoading: isAdding, action: addGuest } = useApiRequest(
    addReservationGuestAndUpdate(() => setListError(null)),
    handleListError,
  )

  const isLoading = isSubmitting || isAdding

  const handleAddGuest = (guest: ClientProfileGuest) => {
    addGuest({
      name: guest.name,
      birthday: guest.birthday,
      group_id: reservationDetails.groups[0].id,
      type: guest.type,
    })
  }

  const handleSubmitGuest = () => {
    if (isLoading) return
    methods.clearErrors()
    const { name, birthday, type } = methods.getValues()
    submitGuest({
      name,
      birthday,
      type: type?.value,
      group_id: reservationDetails.groups[0].id,
    })
  }

  return (
    <div className="my-3 position-relative">
      <ReservationDataCompletionAddGuestList
        error={listError}
        addedGuests={reservationDetails.guests}
        onAdd={handleAddGuest}
      />
      <div className="text-darker-gray border-top bg-light-blue body-padding py-3">
        <FormProvider {...methods}>
          <label>
            <strong>lub wpisz dane</strong> osoby towarzyszącej:
          </label>
          <ReservationDataCompletionAddGuestForm />
        </FormProvider>
        <div className="mt-3 mb-3 mb-lg-0 d-flex justify-content-between justify-content-lg-start">
          <IconWithText
            text="Anuluj"
            iconClassName="font-size-xxl me-2 uil-times"
            textClassName="fw-bold"
            onClick={onClose}
          />
          <IconWithText
            className="ms-4"
            text="Zapisz"
            iconClassName="font-size-xxl me-2 uil-check"
            textClassName="fw-bold"
            onClick={handleSubmitGuest}
          />
        </div>
      </div>
      <BackdropLoader isLoading={isLoading} />
    </div>
  )
}
