import { createEntityAdapter, createSlice, EntityState, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '@store/index'
import { Guest, ReservationDetails } from '@models/reservation'
import { setReservationDetails } from '@store/slices/reservations-slice'

export interface InitialReservationGuest extends Guest {
  reservation: string
}

export interface GuestsState {
  initialReservationGuests: EntityState<InitialReservationGuest>
}

const initialReservationGuestsAdapter = createEntityAdapter<InitialReservationGuest>()

const initialState: GuestsState = {
  initialReservationGuests: initialReservationGuestsAdapter.getInitialState(),
}

export const guestsSlice = createSlice({
  name: 'guests',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(setReservationDetails, (state, { payload }: PayloadAction<ReservationDetails | undefined>) => {
      if (!payload) return

      const allInitialGuests = initialReservationGuestsAdapter.getSelectors().selectAll(state.initialReservationGuests)

      const initialReservationGuests = allInitialGuests.filter(
        (guest: InitialReservationGuest) => payload.token === guest.reservation,
      )

      if (initialReservationGuests.length) return

      initialReservationGuestsAdapter.addMany(
        state.initialReservationGuests,
        payload.guests.map(guest => ({ ...guest, reservation: payload.token })),
      )
    })
  },
})

export const { selectAll: selectInitialGuests } = initialReservationGuestsAdapter.getSelectors(
  (state: RootState) => state.guestsState.initialReservationGuests,
)

export default guestsSlice.reducer
