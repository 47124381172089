import * as React from 'react'
import { SubscriptionContractAgreements } from '@modules/special-products/subscription-contracts/subscription-contract-agreements'
import { SubscriptionContract } from '@models/client'
import { SpecialProductRulesModal } from '@modules/special-products/special-product-rules-modal'

interface Props {
  handleClose: () => void
  onSubmit: (params) => Promise<void>
  subscriptionContract: SubscriptionContract
}

export const SubscriptionContractPaymentRulesModal = ({
  handleClose,
  onSubmit,
  subscriptionContract,
}: Props): JSX.Element => (
  <SpecialProductRulesModal onSubmit={onSubmit} handleClose={handleClose}>
    {({ onAgreementAccept }) => (
      <SubscriptionContractAgreements
        onProductAgreementsChange={onAgreementAccept}
        subscriptionContract={subscriptionContract}
      />
    )}
  </SpecialProductRulesModal>
)
