import * as clsx from 'clsx'
import * as React from 'react'
import { TrapezeButton, TrapezeButtonVariant } from './controls/trapeze-button'
import { IconWithText } from './icon-with-text'
import { useSignaturePad } from '@components/use-signature-pad'

interface Props {
  className: string
  onSave: (dataUrl: string) => void
}
export const SignaturePadComponent = ({ className, onSave }: Props): JSX.Element => {
  const { save, clear, SignaturePad } = useSignaturePad()

  return (
    <div className={clsx('signature-pad__wrapper', className)}>
      <span className="text-center d-block mt-5 font-size-lg">Miejsce na podpis</span>
      <SignaturePad />
      <div className="position-fixed bottom-0 d-flex justify-content-between w-100">
        <TrapezeButton className="signature-pad__clear-button" variant={TrapezeButtonVariant.default}>
          <IconWithText
            className="text-white"
            onClick={clear}
            text="Wyczyść"
            iconClassName="uil-redo font-size-xxl me-2"
          />
        </TrapezeButton>
        <TrapezeButton
          className="signature-pad__save-button"
          onClick={() => save(onSave)}
          variant={TrapezeButtonVariant.secondary}
        >
          <IconWithText text="Gotowe" iconClassName="uil-check font-size-icon-sm me-2" />
        </TrapezeButton>
      </div>
    </div>
  )
}
