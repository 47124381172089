import * as React from 'react'
import { PasswordReset } from '@modules/account/reset-password/password-reset'
import { AccountSetupLayout } from '@modules/account/account-setup-layout'
import { useHistory } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { DocumentMeta } from '@components/document-meta'

export const ResetPasswordView = (): JSX.Element => {
  const history = useHistory()

  const handleBackToLogin = () => {
    history.push(NavigationPath.Login)
  }

  return (
    <>
      <DocumentMeta title="Resetowanie hasła - Holiday Park & Resort" />
      <AccountSetupLayout>
        <div className="authorization-layout__content-container">
          <PasswordReset onBackToLogin={handleBackToLogin} />
        </div>
      </AccountSetupLayout>
    </>
  )
}
