import * as React from 'react'
import { ClientDetails, ClientDetailsProfileRuleKeyword } from '@models/client'
import { Rule } from '@models/reservation'
import { RuleRow } from '@modules/user/gdpr-data/rule-row'
import { RulesNames } from '@helpers/rules'
import { useDispatch } from 'react-redux'
import { useApiRequest } from '@hooks/use-api-request'
import { commonObjectPatch } from '@api/basic-requests'
import { setClientDetails } from '@store/actions/client-actions'
import { useRulesContent } from '@hooks/use-rules-content'
import { createCircleRadioButtonLoaderId } from '@modules/user/gdpr-data/rule-acceptance-options'
import { AvailableRuleKeyword } from '@models/app-data'

interface Props {
  clientDetails: ClientDetails
  context?: string
}

export const UserRulesContent = ({ clientDetails, context }: Props): JSX.Element => {
  const [updatingRules, setUpdatingRules] = React.useState<string[]>([])

  const dispatch = useDispatch()

  const content = useRulesContent()

  const { action: updateRule } = useApiRequest(async (payload: Record<Partial<AvailableRuleKeyword>, boolean>) => {
    const [key, value] = Object.entries(payload)[0] as [AvailableRuleKeyword, boolean]
    const rule = createCircleRadioButtonLoaderId(key, value)

    setUpdatingRules(state => [...state, rule])
    await dispatch(setClientDetails(await commonObjectPatch(clientDetails.urls.rules, payload)))
    setUpdatingRules(state => state.filter(el => el !== rule))
  })

  const isRuleDisabled = (rule: Rule<ClientDetailsProfileRuleKeyword>) =>
    rule.keyword === 'sms_rules' && !clientDetails.phone

  const getRuleTitle = (rule: Rule<ClientDetailsProfileRuleKeyword>) => {
    if (isRuleDisabled(rule)) {
      return (
        <span>
          {RulesNames[rule.keyword]}
          <p className="font-size-sm col-12 col-xl-9 mt-1 fw-light">
            Aby wyrazić zgodę na marketing drogą telefoniczną musisz uzupełnić swój numer telefonu.
          </p>
        </span>
      )
    }

    return `${RulesNames[rule.keyword]}${rule.required ? '*' : ''}`
  }

  return (
    <div>
      {clientDetails.profile.rules.map((rule: Rule<ClientDetailsProfileRuleKeyword>) => (
        <RuleRow
          key={rule.keyword}
          title={getRuleTitle(rule)}
          rule={rule}
          onUpdate={updateRule}
          context={context}
          disabled={isRuleDisabled(rule)}
          updatingRules={updatingRules}
        >
          <span className="font-size-sm fw-light">{content[rule.keyword]}</span>
        </RuleRow>
      ))}
    </div>
  )
}
