import * as React from 'react'
import { GastroCardAssignment, GastroCardDetails } from '@models/client'
import { ReservationDetailsMobileRow } from '@modules/reservations/pending/mobile/reservation-details-mobile-row'
import { formatDate } from '@helpers/date-helper'
import { IconWithText } from '@components/icon-with-text'
import { useRedirectToReservation } from '@modules/special-products/gastro-vouchers/use-redirect-to-reservation'

interface Props {
  voucherDetails: GastroCardDetails
}

export const GastroVoucherDetailsAssignmentList = ({ voucherDetails }: Props): JSX.Element => {
  const { redirectToReservation } = useRedirectToReservation()
  const handleMoveToReservationDetails = (assignment: GastroCardAssignment) => () => {
    redirectToReservation({ reservation_number: assignment.booking })
  }

  return (
    <div className="my-5 mx-n2">
      <p className="text-primary font-size-xxl text-center fw-bold">Przypisane rezerwacje</p>
      {voucherDetails.assignments.map((assignment: GastroCardAssignment) => (
        <div className="mb-3 border-bottom" key={assignment.id}>
          <p className="font-size-md text-center fw-bold">{assignment.booking}</p>
          <ReservationDetailsMobileRow icon="uil-check" title="Voucher przypisany:">
            <span>{formatDate(assignment.created_at)}</span>
          </ReservationDetailsMobileRow>
          <ReservationDetailsMobileRow icon="uil-bookmark" title="Numer rezerwacji:">
            <IconWithText
              text={assignment.booking}
              onClick={handleMoveToReservationDetails(assignment)}
              className="flex-row-reverse"
              iconClassName="uil-external-link-alt ms-2 font-size-xl"
            />
          </ReservationDetailsMobileRow>
        </div>
      ))}
    </div>
  )
}
