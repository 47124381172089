import * as React from 'react'

export enum StatusVariant {
  confirmed = 'confirmed',
  initial = 'initial',
  initial_resume = 'initial_resume',
  initial_canceled = 'initial_canceled',
  removed = 'removed',
  completed = 'completed',
  cancelling = 'cancelling',
  cancelled = 'cancelled',
  active = 'active',
  active_assign = 'active_assign',
  generate = 'generate',
  used = 'used',
}

interface Props {
  variant: StatusVariant
  text: string
}

export const SpecialProductStatus = ({ text, variant }: Props): JSX.Element => (
  <div>
    <div className={`rounded font-size-xs d-inline-block px-2 status--${variant}`}>
      <strong>{text.toLowerCase()}</strong>
    </div>
  </div>
)
