import * as React from 'react'

export const useSwipeable = (
  minSwipeDistance: number,
  onSwipeEnd: (direction: 'left' | 'right') => void,
  onMove?: (distanceFromStart: number) => void,
  onMoveEnd?: () => void,
) => {
  const [start, setStart] = React.useState<number>(0)

  const onTouchStart: React.TouchEventHandler = React.useCallback(
    event => {
      setStart(event.touches[0].clientX)
    },
    [setStart],
  )

  const onTouchEnd: React.TouchEventHandler = React.useCallback(
    event => {
      if (onMoveEnd) {
        onMoveEnd()
      }
      if (Math.abs(start - event.changedTouches[0].clientX) > minSwipeDistance) {
        onSwipeEnd(start > event.changedTouches[0].clientX ? 'left' : 'right')
      }
    },
    [onMoveEnd, onSwipeEnd],
  )

  const onTouchMove: React.TouchEventHandler = React.useCallback(
    event => {
      if (onMove) {
        onMove(start - event.touches[0].clientX)
      }
    },
    [onMove],
  )

  return React.useMemo(
    () => ({
      onTouchStart,
      onTouchEnd,
      onTouchMove,
    }),
    [onTouchStart, onTouchMove, onTouchEnd],
  )
}
