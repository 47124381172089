import { SpecialProductStatus, StatusVariant } from '@modules/special-products/special-product-status'
import * as React from 'react'
import * as clsx from 'clsx'

interface Props {
  title: string
  productStatus: StatusVariant
  productStatusText: string
  children?: React.ReactNode
  wrapperClassName?: string
}

export const SpecialProductRowStatus = ({
  title,
  productStatus,
  productStatusText,
  children,
  wrapperClassName,
}: Props): JSX.Element => (
  <div className={clsx('px-3 pb-3 pt-0 pt-sm-3', wrapperClassName)}>
    <span className="font-size-sm d-none d-sm-inline">{title}</span>
    <SpecialProductStatus variant={productStatus} text={productStatusText} />
    {children}
  </div>
)
