import {
  Feeding,
  FeedingImprovement,
  FeedingTypes,
  Improvement,
  PriceItem,
  ReservationDetails,
  ReservationImprovement,
  ResortDetails,
  ResortImprovement,
} from '@models/reservation'
import * as R from 'ramda'
import Decimal from 'decimal.js'
import { sumDecimalArray } from '@helpers/utils'

export const isFeedingAvailableInResort = (resortDetails: ResortDetails): boolean =>
  Object.values(resortDetails.feedings).some(feeding => !!Object.values(feeding).length)

export const isFeeding = <T extends { code: string }>(improvement: T | null): boolean =>
  !!improvement &&
  (improvement.code.startsWith('feeding__') ||
    ['dinner', 'breakfast', 'dinner_with_breakfast'].includes(improvement.code))

export const isWinterVip = (improvement: Improvement | null | undefined): boolean =>
  !!improvement && improvement.code === 'package_vip_winter'

export const isVip = (improvement: Improvement | null): boolean =>
  !!(improvement && ['package_vip', 'package_vip_winter'].includes(improvement.code))

export const isParking = (improvement: Improvement): boolean =>
  improvement && ['parking2', 'parking_vip', 'parking_large'].includes(improvement.code)

export const isStayOption = (improvement: Improvement): boolean =>
  ['extend_stay_12', 'extend_stay', 'early_check_in_13'].includes(improvement.code)

export const getSelectedFeedingKey = <T extends { code: FeedingTypes }>(feedings: T[]): FeedingTypes => {
  const { hasBreakfast, hasDinner } = feedings.reduce(
    (prev, feeding) => ({
      hasBreakfast: prev.hasBreakfast || feeding.code === 'breakfast',
      hasDinner: prev.hasDinner || feeding.code === 'dinner',
    }),
    { hasBreakfast: false, hasDinner: false },
  )

  if (hasBreakfast && hasDinner) return 'dinner_with_breakfast'
  if (hasBreakfast) return 'breakfast'
  if (hasDinner) return 'dinner'

  return 'none'
}

export const isPaidByVip = (improvement: Improvement): boolean => improvement.is_paid_by_package_vip

export const getImprovementName = (feedingType: FeedingTypes): string => {
  switch (feedingType) {
    case 'breakfast':
      return 'Śniadanie'
    case 'dinner':
      return 'Obiadokolacja'
    default:
      return 'Śniadanie + obiadokolacja'
  }
}

interface GroupedImprovements<T> {
  feedingImprovements: T[]
  restImprovements: T[]
  stayOptionsImprovements: T[]
  vipImprovements: T[]
}

export const getGroupedImprovements = <T extends ReservationImprovement | ResortImprovement>(
  improvements: T[],
): GroupedImprovements<T> => {
  const createGroupedImprovements = (
    groupedImprovements: GroupedImprovements<T>,
    improvement: T,
    groupName: keyof GroupedImprovements<T>,
  ) => ({ ...groupedImprovements, [groupName]: [...groupedImprovements[groupName], improvement] })

  return improvements.reduce(
    (acc, improvement) => {
      if (isVip(improvement)) return createGroupedImprovements(acc, improvement, 'vipImprovements')
      if (isStayOption(improvement)) return createGroupedImprovements(acc, improvement, 'stayOptionsImprovements')
      return createGroupedImprovements(acc, improvement, 'restImprovements')
    },
    { vipImprovements: [], stayOptionsImprovements: [], feedingImprovements: [], restImprovements: [] },
  )
}

export const createSingleFeedingImprovement = (feedings: PriceItem<Feeding>): FeedingImprovement => {
  const code = getSelectedFeedingKey(feedings.items)

  return {
    ...feedings.items[0],
    code: code,
    name: getImprovementName(code),
    can_remove: true,
    can_update: true,
    position: 1,
    price_brutto: feedings.total_price_brutto,
  }
}

export const sortImprovementByPosition = <T extends { position: number | null }>(improvements: T[]): T[] =>
  improvements.sort((a, b) => (a.position ?? 999) - (b.position ?? 999))

export const getImprovementLimit = (
  resortImprovement: ResortImprovement,
  reservationDetails: ReservationDetails | undefined,
): number => {
  if (!reservationDetails) return 0

  const limitForImprovement = resortImprovement.limits[reservationDetails.accommodation_type_id]

  if (!limitForImprovement) return 0

  const addedImprovements = reservationDetails.prices.improvements.items.filter(
    (reservationImprovement: ReservationImprovement) => reservationImprovement.code === resortImprovement.code,
  )

  if (!addedImprovements.length) return limitForImprovement

  return new Decimal(limitForImprovement)
    .minus(sumDecimalArray(addedImprovements.map(improvement => new Decimal(improvement.amount))))
    .toNumber()
}

export const getSelectableImprovements = (
  improvements: ResortImprovement[],
  reservationDetails: ReservationDetails | undefined,
): ResortImprovement[] =>
  improvements.filter(
    (improvement: ResortImprovement) =>
      improvement.can_add &&
      improvement.sales_channels.includes('client_panel') &&
      getImprovementLimit(improvement, reservationDetails) > 0 &&
      (reservationDetails?.apartment_id
        ? !improvement.excluded_apartments.includes(reservationDetails?.apartment_id)
        : true),
  )

export const getFeedingGuests = (reservationDetails: ReservationDetails | undefined) =>
  R.groupBy(R.prop('guest_id'))(reservationDetails?.feeding_options)

interface ParkingContextUpdatePayload {
  code: string
  amount?: number
  context?: { register_car_numbers: string[] }
}

export const isParkingCarPlatesUpdate = <T extends ParkingContextUpdatePayload>(
  improvement: ReservationImprovement | undefined,
  payload: T,
) => improvement && payload.code === improvement.code && payload.amount === improvement.amount && improvement.amount > 0
