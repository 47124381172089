import * as React from 'react'

export const ReservationDataCompletionParkingHeader = (): JSX.Element => (
  <div className="text-darker-gray d-flex align-items-center justify-content-between flex-wrap">
    <div className="font-size-xxl order-0">
      <strong>Twoje miejsca parkingowe</strong>
    </div>
    <div className="w-100 order-2 order-lg-1 my-1">
      Uzupełnij tablice rejestracyjne swoich pojazdów aby zautomatyzować wyjazdy i przyjazdy przez bramę wjazdową.
      Monitorowany parking na terenie kompleksu.
    </div>
  </div>
)
