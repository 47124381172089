import * as React from 'react'
import * as clsx from 'clsx'
import { useDeviceSize } from '@hooks/use-device-size'

interface Props {
  className: string
}

export const TimeSavedIndicatorText = ({ className }: Props): JSX.Element => {
  const { isMobile } = useDeviceSize()

  return (
    <div
      className={clsx(
        'text-center text-lg-start text-darker-gray flex-column justify-content-center px-3',
        isMobile ? 'text-white' : 'text-darker-gray',
        className,
      )}
    >
      <span className="fw-bold">Średnio tyle czasu zaoszczędzisz</span>
      <span className="fw-light font-size-11">uzupełniając dane do meldowania szybciej</span>
    </div>
  )
}
