import { formatPrice } from '@helpers/price-helper'
import * as React from 'react'
import * as clsx from 'clsx'

export enum PriceVariant {
  negative = 'text-danger',
  positive = 'text-success',
}

interface Props {
  name: string
  price: string
  amount?: number
  date?: string
  pricePrefix?: string
  variant?: PriceVariant | undefined
}

export const CheckInPaymentSummaryBillItem = ({ name, price, amount, date, pricePrefix = '-', variant }: Props) => (
  <div className="ms-4 d-flex justify-content-between font-size-sm mt-1">
    <span className={date ? 'col-6' : 'col-9'}>
      {name} {amount && `x${amount}`}
    </span>
    {date && <span className="text-end col-3 font-size-xs">{date}</span>}
    <span className={clsx('text-end col-3', variant)}>
      {pricePrefix} {formatPrice(price)}
    </span>
  </div>
)
