import * as React from 'react'
import { Checkbox } from '@components/controls/checkbox'
import { ExpandableText, ExpandIcon } from '@components/expandable-text'
import { Collapse } from 'react-bootstrap'
import { StaticRules } from '@helpers/rules'
import { useToggle } from 'rooks'
import { useRulesContent } from '@hooks/use-rules-content'

interface Props {
  showSms: boolean
  showEmail: boolean
}

export const PolicyPrivacyAgreements = ({ showEmail, showSms }: Props): JSX.Element => {
  const [subRulesVisible, toggleSubRules] = useToggle(true)

  const content = useRulesContent()

  const togglePolicyPrivacySubRules = (event: React.MouseEvent) => {
    event.preventDefault()
    toggleSubRules(!subRulesVisible)
  }

  return (
    <>
      <Checkbox
        name="privacy_policy_rules"
        className="checkbox--small"
        label={
          <span className="font-size-sm">
            {content.privacy_policy_rules}
            <ExpandIcon
              wrapperClassName="d-inline-flex"
              onIsExpandedChange={togglePolicyPrivacySubRules}
              isExpanded={subRulesVisible}
            />
          </span>
        }
      />
      <Collapse in={subRulesVisible}>
        <div className="ms-3">
          {showSms && (
            <Checkbox
              name="sms_rules"
              label={
                <span>
                  Zgoda na dostarczanie powiadomień i ofert poprzez SMS.
                  <ExpandableText text={StaticRules.sms_rules} initialHeight={0} />
                </span>
              }
              className="checkbox--small fw-normal font-size-sm is-muted-label align-items-start"
            />
          )}

          {showEmail && (
            <Checkbox
              name="email_rules"
              label={
                <span>
                  Zgoda na dostarczanie powiadomień i ofert poprzez pocztę elektroniczną.
                  <ExpandableText text={StaticRules.email_rules} initialHeight={0} />
                </span>
              }
              className="checkbox--small fw-normal font-size-sm is-muted-label align-items-start"
            />
          )}
        </div>
      </Collapse>
    </>
  )
}
