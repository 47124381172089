import * as React from 'react'
import { ModalFooterDesktop } from '@components/modals/modal-footer-desktop'
import { ModalFooterMobile } from '@components/modals/modal-footer-mobile'
import { TrapezeButtonVariant } from '@components/controls/trapeze-button'
import { useDeviceSize } from '@hooks/use-device-size'

export interface ModalFooterProps {
  onClose?: () => void
  onSubmit?: () => void
  withCancelButton?: boolean
  submitClassName?: string
  submitLabel?: string
  submitIcon?: string
  cancelClassName?: string
  cancelLabel?: string
  savingLabel?: string
  cancelIcon?: string
  buttonDisabled?: boolean
  isLoading?: boolean
  saveButtonVariant?: TrapezeButtonVariant
  withSubmit?: boolean
}

export const ModalFooter = ({ ...props }: ModalFooterProps): JSX.Element => {
  const { isDesktop } = useDeviceSize()
  return isDesktop ? (
    <ModalFooterDesktop {...(props.onSubmit && { withSubmit: true })} {...props} />
  ) : (
    <ModalFooterMobile {...(props.onSubmit && { withSubmit: true })} {...props} />
  )
}
