import * as React from 'react'

interface Props {
  className?: string
}

export const DownloadAppLinks = ({ className }: Props): JSX.Element => (
  <div className={className}>
    <a href="https://holidaypark.pl/aplikacja-mobilna/app-store/">
      <img
        className="download-app-button"
        src={require('@assets/images/app-store.svg')}
        alt="Pobierz Konto Gościa - iOS"
      />
    </a>
    <a href="https://holidaypark.pl/aplikacja-mobilna/google-play/">
      <img
        className="mt-2 mb-5 download-app-button"
        src={require('@assets/images/google-play.svg')}
        alt="Pobierz Konto Gościa - Android"
      />
    </a>
  </div>
)
