import * as clsx from 'clsx'
import { differenceInDays, differenceInHours, differenceInMinutes, differenceInYears } from 'date-fns'
import * as React from 'react'
import { CircleProgress } from '@components/circle-progress'
import { getDaysLabel, getHoursLabel, getMinutesLabel, getYearsLabel } from '@helpers/date-helper'

const getProgress = (yearsLeft: number, daysLeft: number, hoursLeft: number, minutesLeft: number) => {
  if (yearsLeft > 0) return 0.05
  if (daysLeft > 0) return 0.05 + 0.7 * (1 - daysLeft / 365)
  if (hoursLeft >= 1) return 0.75 + 0.15 * (1 - hoursLeft / 24)
  if (minutesLeft >= 1) return 0.9 + 0.1 * (1 - minutesLeft / 60)

  return 1
}

const getCountElement = (value: string | number, label: string, className?: string) => (
  <div className="time-remaining-counter__count">
    <strong className={clsx('time-remaining-counter__count-value', className)}>{value}</strong>
    <span className="time-remaining-counter__count-label">{label}</span>
  </div>
)

const getLabelElement = (label: string) => (
  <div className="time-remaining-counter__label">
    <span>{label}</span>
  </div>
)

const getYearsLabelElement = (yearsLeft: number, daysLeft: number) => {
  const daysRest = daysLeft - yearsLeft * 365
  const isDaysRest = daysRest > 0

  return (
    <div className={clsx('time-remaining-counter__label', isDaysRest && 'time-remaining-counter__label--sm')}>
      <span className="time-remaining-counter__label-years">
        <strong>{yearsLeft}</strong> {getYearsLabel(yearsLeft)}
      </span>
      {isDaysRest && (
        <span className="time-remaining-counter__label-days">
          <strong>{daysRest}</strong> {getDaysLabel(daysRest)}
        </span>
      )}
    </div>
  )
}

const getCountAndLabelElements = (value: string | number, label: string) => ({
  countElement: getCountElement(value, label),
  labelElement: getLabelElement(label),
})

const getCountWithLabel = (yearsLeft: number, daysLeft: number, hoursLeft: number, minutesLeft: number) => {
  if (yearsLeft > 0) {
    return {
      countElement: getCountElement('365+', getDaysLabel(365), 'time-remaining-counter__count-value--sm'),
      labelElement: getYearsLabelElement(yearsLeft, daysLeft),
    }
  }
  if (daysLeft > 0) {
    return getCountAndLabelElements(daysLeft + 1, getDaysLabel(daysLeft + 1))
  }
  if (hoursLeft > 0) {
    return getCountAndLabelElements(hoursLeft, getHoursLabel(hoursLeft))
  }
  if (minutesLeft >= 0) {
    return getCountAndLabelElements(minutesLeft, getMinutesLabel(minutesLeft))
  }

  return getCountAndLabelElements(0, getMinutesLabel(0))
}

interface Props {
  end: Date
  start: Date | number
  size: 'sm' | 'lg'
  pending?: boolean
}

export const TimeRemainingCounter = ({ end, size, pending, start }: Props): JSX.Element => {
  const yearsLeft = differenceInYears(end, start)
  const daysLeft = differenceInDays(end, start)
  const hoursLeft = differenceInHours(end, start)
  const minutesLeft = differenceInMinutes(end, start)
  const progress = getProgress(yearsLeft, daysLeft, hoursLeft, minutesLeft)
  const { countElement, labelElement } = getCountWithLabel(yearsLeft, daysLeft, hoursLeft, minutesLeft)
  return (
    <div
      className={clsx('time-remaining-counter__wrapper', {
        'time-remaining-counter__wrapper--sm': size === 'sm',
        'time-remaining-counter__wrapper--lg': size === 'lg',
      })}
    >
      <div className="position-relative time-remaining-counter__progress">
        <CircleProgress
          className={clsx({
            'text-primary': daysLeft > 0 && pending,
            'text-darker-gray': daysLeft > 0 && !pending,
            'text-danger': daysLeft <= 0,
          })}
          progress={progress}
        />
        {countElement}
      </div>
      {labelElement}
    </div>
  )
}
