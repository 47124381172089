import * as React from 'react'
import { formatPrice } from '@helpers/price-helper'

interface Props {
  title: string
  price?: string
  pricePrefix?: string
  children?: React.ReactNode
  className?: string
}

export const CheckInPaymentSummaryBillSection = ({ title, price, children, className, pricePrefix = '-' }: Props) => (
  <div className={className}>
    <div className="d-flex justify-content-between">
      <strong className="d-block me-3">{title}</strong>
      {price && (
        <div>
          <span className="fw-light me-4 font-size-sm">razem</span>
          <strong className="text-nowrap">
            {pricePrefix} {formatPrice(price)}
          </strong>
        </div>
      )}
    </div>
    {children}
  </div>
)
