import { useSelector } from 'react-redux'
import { selectReservationDetails } from '@store/selectors/reservation-details-selectors'
import { createCombinedDateWithTime, formatDate, parseISODate } from '@helpers/date-helper'
import { addHours, isBefore, isSameDay, subDays } from 'date-fns'

export const IMPROVEMENT_FREEZE_DAYS = 2
export const FEEDING_FREEZE_DAYS = 14

const HOUR_TO_REMOVE_FRESHLY_ADDED = 1

interface Response {
  isInEditAllowedWindow: (dateFrom: Date, daysBeforeCheckinToFreeze?: number) => boolean
  isFreshlyAdded: <T extends { created: string }>(improvement: T) => boolean
}

export const useEditableCheck = (): Response => {
  const reservationDetails = useSelector(selectReservationDetails)

  const isInEditAllowedWindow = (dateFrom: Date, daysBeforeCheckinToFreeze = FEEDING_FREEZE_DAYS) => {
    const now = createCombinedDateWithTime(formatDate(new Date()), reservationDetails?.arrival_time ?? '15:00')
    const lastAvailableChangeDate = subDays(dateFrom, daysBeforeCheckinToFreeze)

    return isBefore(now, lastAvailableChangeDate) || isSameDay(now, lastAvailableChangeDate)
  }

  const isFreshlyAdded = <T extends { created: string }>(improvement: T) =>
    isBefore(new Date(), addHours(parseISODate(improvement.created) as Date, HOUR_TO_REMOVE_FRESHLY_ADDED))

  return {
    isInEditAllowedWindow,
    isFreshlyAdded,
  }
}
