import * as React from 'react'
import * as clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import { FormError } from '@components/form-controls/form-error'

interface Props {
  name: string
  label: string
  rows?: number
  placeholder: string
}

export const CustomTextarea = ({ name, label, rows = 6, placeholder }: Props): JSX.Element => {
  const { register, watch, formState } = useFormContext()
  const value = watch(name)

  return (
    <div className="d-flex flex-column">
      <label htmlFor={name} className="font-size-sm">
        {label}
      </label>
      <textarea
        {...register(name)}
        rows={rows}
        id={name}
        placeholder={placeholder}
        className={clsx('form-control form-control-textarea form-control-with-status font-size-sm', {
          'no-value': !value,
          'form-control-textarea--failed': !!formState.errors[name],
        })}
      />
      <FormError inputName={name} className="mt-1" />
    </div>
  )
}
