import { NavigationPath } from '@models/routes'
import * as clsx from 'clsx'
import * as React from 'react'
import { useHistory, useLocation } from 'react-router'
import { IconWithText } from './icon-with-text'
import { useAppSelector } from '@store/index'
import { selectAppData } from '@store/selectors/app-data-selectors'

export const AppFooter = (): JSX.Element => {
  const { settings } = useAppSelector(selectAppData)
  const { pathname } = useLocation()
  const { push } = useHistory()

  const handleRedirect = React.useCallback(
    (path: NavigationPath) => () => {
      push(path)
    },
    [push],
  )

  const renderNavItem = React.useCallback(
    (icon: string, label: string, path: NavigationPath) => (
      <IconWithText
        className={clsx('footer__nav-item align-items-center justify-content-center', { active: pathname.match(path) })}
        iconClassName={clsx('nav-icon', icon)}
        textClassName="nav-text"
        text={label}
        onClick={handleRedirect(path)}
      />
    ),
    [handleRedirect, pathname],
  )

  return (
    <div className="footer__wrapper">
      {renderNavItem('uil-user', 'Moje dane', NavigationPath.User)}
      {settings.coupons_enabled && renderNavItem('uil-tag-alt', 'Kupony', NavigationPath.Coupons)}
      {renderNavItem('uil-location-point', 'Rezerwacje', NavigationPath.Reservations)}
      {renderNavItem('uil-shopping-bag', 'Specjalne', NavigationPath.SpecialProducts)}
    </div>
  )
}
