import * as React from 'react'
import { Helmet } from 'react-helmet'

interface Props {
  title: string
}

export const DocumentMeta = ({ title }: Props): JSX.Element => (
  <Helmet>
    <title>{title}</title>
  </Helmet>
)
