export enum NavigationPath {
  AccountActivation = '/account-activation/:token/',
  ResetPasswordCredentials = '/reset-password/:token/',
  AccountCreate = '/account-create/',
  ResetPassword = '/reset-password/',
  Login = '/login/',
  Reservations = '/reservations/',
  Root = '/',
  SpecialProducts = '/special-products/',
  SubscriptionDetails = '/subscription-details/:id',
  PackageVipDetails = '/vip-details/:id',
  GastroVoucherDetails = '/gastro-card/:id',
  CashbackVoucherDetails = '/cashback-voucher/:id',
  SubscriptionContractDetails = '/subscription-contract-details/:id',
  User = '/user/',
  Wallet = '/wallet/',
  Coupons = '/coupons/',
  CouponDetails = '/coupons/:id',
  Dashboard = '/dashboard/',
  ReturnSuccess = '/return-success/',
  ReservationDetails = '/reservation-details/:token/',
  Improvements = '/improvements/:token/',
  CheckIn = '/reservation-details/:token/check-in/',
  CheckOut = '/reservation-details/:token/check-out/',
  PaymentSuccess = '/payment-success/',
  PaymentProcessing = '/payment-processing/',
  PaymentFailure = '/payment-failure/',
  Error404 = '/404/',
  Refund = '/refund/:token/',
  DataProcessing = '/data-processing/:token/',
}

declare global {
  interface String {
    replaceId(id: string | number): string
    replaceToken(token: string): string
  }
}

String.prototype.replaceId = function (id: string | number): string {
  return this.replace(':id', id)
}

String.prototype.replaceToken = function (token: string): string {
  return this.replace(':token', token)
}
