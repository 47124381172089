import { createApi } from '@reduxjs/toolkit/query/react'
import { apiQueryFunction } from './base'
import { RootState } from '@store/index'
import { CashbackVoucher, CashbackVoucherDetails } from '@models/client'
import { createApiTag, provideListTags } from '@api/query-helper'
import { noop } from '@hooks/use-payment-modal'

export const CASHBACK_VOUCHERS_API_TAG = 'CASHBACK_VOUCHERS'
export const CASHBACK_VOUCHERS_API_TAG_IDS = {
  LIST: 'LIST',
  DETAILS: 'DETAILS',
}

export const cashbackVouchersApi = createApi({
  baseQuery: noop as any,
  reducerPath: 'cashbackVouchersApi',
  tagTypes: [CASHBACK_VOUCHERS_API_TAG],
  endpoints: builder => ({
    getCashbackVouchers: builder.query<CashbackVoucher, string>({
      queryFn: apiQueryFunction((_, url: string) => ({
        method: 'GET',
        url,
      })),
      providesTags: provideListTags(CASHBACK_VOUCHERS_API_TAG, CASHBACK_VOUCHERS_API_TAG_IDS.LIST),
    }),
    getCashbackVoucherDetails: builder.query<CashbackVoucherDetails, string>({
      queryFn: apiQueryFunction((state: RootState, url: string) => ({
        method: 'GET',
        url,
      })),
      providesTags: createApiTag(CASHBACK_VOUCHERS_API_TAG, CASHBACK_VOUCHERS_API_TAG_IDS.DETAILS),
    }),
  }),
})

export const { useGetCashbackVouchersQuery, useLazyGetCashbackVoucherDetailsQuery } = cashbackVouchersApi
