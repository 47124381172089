import * as React from 'react'
import { Button, ModalBody } from 'react-bootstrap'
import { IconWithText } from '@components/icon-with-text'
import { useApiRequest } from '@hooks/use-api-request'
import { SubscriptionContractDetails } from '@models/client'
import { commonObjectPut } from '@api/basic-requests'
import { ItemChangeConfirmationModalContent } from '@components/item-change-confirmation-modal-content'
import { TextWithSpinner } from '@components/text-with-spinner'
import { useConfirmation } from '@hooks/use-confirmation'
import { updateClientSubscriptionContract } from '@store/actions/client-actions'
import { useDispatch } from 'react-redux'

interface Props {
  handleClose: () => void
  subscriptionContractDetails: SubscriptionContractDetails
  onSubscriptionContractChange: (subscriptionContract: SubscriptionContractDetails) => void
}

export const SubscriptionContractResignationCancelModal = ({
  handleClose,
  subscriptionContractDetails,
  onSubscriptionContractChange,
}: Props): JSX.Element => {
  const { showSuccess } = useConfirmation()
  const dispatch = useDispatch()

  const { action: handleResignationCancel, isLoading } = useApiRequest(async () => {
    const subscriptionContract = await commonObjectPut<SubscriptionContractDetails>(
      subscriptionContractDetails.urls.details,
    )

    onSubscriptionContractChange(subscriptionContract)
    dispatch(updateClientSubscriptionContract(subscriptionContract))

    handleClose()
    showSuccess({
      title: 'Subskrypcja została przywrócona',
      action: ({ onClose }) => (
        <Button
          variant="primary"
          onClick={onClose}
          className="mx-auto text-white px-5 btn-tall mt-5 mb-4 font-weight-semibold"
        >
          Gotowe
        </Button>
      ),
    })
  })

  return (
    <ModalBody className="modal-scrollable">
      <ItemChangeConfirmationModalContent
        text="Czy na pewno chcesz anulować"
        underlineText="rezygnację z subskrypcji?"
        customImage={
          <img src={require('@assets/images/processing.svg')} alt="Zmień dane karty" className="my-2" height={240} />
        }
      >
        <button className="btn btn-outline-secondary mt-3 px-5 py-2" onClick={handleClose}>
          <IconWithText
            text="Pozostaję przy rezygnacji"
            iconClassName="uil-check me-2 font-size-xxl"
            className="fw-medium"
          />
        </button>
        <TextWithSpinner
          onClick={handleResignationCancel}
          isLoading={isLoading}
          text="Przywróć subskrypcję"
          loadingText="Proszę czekać..."
          spinnerColor="danger"
          iconClassName="uil-redo font-size-xxl me-2"
          className="text-danger mt-3 fw-medium"
        />
      </ItemChangeConfirmationModalContent>
    </ModalBody>
  )
}
