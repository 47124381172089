import * as React from 'react'
import { GastroCardAssignment, GastroCardDetails } from '@models/client'
import { DateFormats, formatDate } from '@helpers/date-helper'
import { useRedirectToReservation } from '@modules/special-products/gastro-vouchers/use-redirect-to-reservation'
import { IconWithText } from '@components/icon-with-text'
import { useModal } from '@components/modals/use-modal'

interface Props {
  voucherDetails: GastroCardDetails
}

export const GastroVoucherExpandableRowContent = ({ voucherDetails }: Props): JSX.Element => {
  const { redirectToReservation } = useRedirectToReservation()

  const handleMoveToReservationDetails = (assignment: GastroCardAssignment) => () => {
    redirectToReservation({ reservation_number: assignment.booking })
  }

  const [showPurchaseHistoryModal] = useModal('GastroVoucherPurchaseHistoryModal', {
    gastroCard: voucherDetails,
    title: `Historia zakupów (voucher ${voucherDetails.code})`,
    titleMobile: 'Historia zakupów',
  })

  const activeAssignments = voucherDetails.assignments.filter(
    (assignment: GastroCardAssignment) => assignment.is_active,
  )

  return (
    <div className=" pb-3 ps-sm-4 font-size-sm mb-1">
      Przypisania:
      <ul className="ps-1">
        {activeAssignments.length ? (
          <>
            {activeAssignments.map((assignment: GastroCardAssignment) => (
              <li key={assignment.id}>
                <span className="font-weight-semibold">
                  {formatDate(assignment.created_at, DateFormats.MONTH_WITH_FULL_WORD_MONTH_WITH_YEAR)}{' '}
                </span>
                do rezerwacji Nr. rez.: <span className="font-weight-semibold">{assignment.booking}</span>
                <i
                  className="uil-external-link-alt ms-2 font-size-md cursor-pointer"
                  onClick={handleMoveToReservationDetails(assignment)}
                />
              </li>
            ))}
            <IconWithText
              text="Historia zakupów"
              iconClassName="uil-receipt me-2 font-size-icon-18"
              className="text-muted fw-bold cursor-pointer mt-3"
              onClick={showPurchaseHistoryModal}
            />
          </>
        ) : voucherDetails.kind === 'gastro_coupon' ? (
          <li>Voucher będzie gotowy do użycia po zaksięgowaniu wpłaty.</li>
        ) : (
          <li>Voucher nie został jeszcze przypisany do żadnej rezerwacji.</li>
        )}
      </ul>
    </div>
  )
}
