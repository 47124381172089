import * as React from 'react'
import * as clsx from 'clsx'
import { IconWithText } from '@components/icon-with-text'

interface Props {
  icon: string
  title: string
  titleClassName?: string
  children?: React.ReactNode
  onClick?: (event?: React.MouseEvent<HTMLElement>) => void
}

export const ReservationDetailsMobileRow = ({ icon, title, titleClassName, children, onClick }: Props): JSX.Element => (
  <div className="reservation-details-row border-top d-flex align-items-center text-muted py-3 px-2 fw-bold">
    <IconWithText
      text={title}
      textClassName={titleClassName}
      iconClassName={clsx('me-2 font-size-icon-18 text-muted', icon)}
      className="flex-fill text-light-gray font-size-sm"
      onClick={onClick}
    />
    <div className="me-1">{children}</div>
  </div>
)
